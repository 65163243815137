import { request } from '../../helpers/api/request';
import { GetCertificatesContract } from '../../types/Endpoints/certificates/CertificatesContracts';

export const getCertificates = async () => {
    const { data } = await request<GetCertificatesContract>({
        url: 'admin/certificates',
    });

    return data;
};
