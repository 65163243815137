import { request } from '../../helpers/api/request';
import { UploadFile } from '../../types/Endpoints/files/FilesContract';

export const updatePhotosProduct = async (data: Required<UploadFile>[], id: string) => {
    await request({
        method: 'POST',
        url: `admin/products/${id}/pictures`,
    // data: data.map(item => ({ path: item.path })), // FIXME: need fix from backend
    });

    return data;
};
