import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from '../../styles/images/login/logo.svg';
import { routes } from '../../constants/RouterPath';
import { units } from '../../helpers/styles/units';

const StyledLink = styled(Link)`
  display: flex;

 /*  width: ${units(82)}; */
  padding-left: ${units(12)};

  & img {
    width: ${units(82)};

  }
`;

export const Logo = () => (
    <StyledLink to={`${routes.dashboard}`}>
        <img alt="logo" src={logo} />
    </StyledLink>
);
