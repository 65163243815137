import { request } from '../../helpers/api/request';
import { EndpointContract } from '../../types/Endpoints/EndpointContract';
import { setQuery } from '../../helpers/query/setQuery';

export interface SamplesItem {
  id: number;
  number: string;
  price: number;
}

export interface Calculators {
  id: number;
  isZiko: boolean;
  name: string;
  samples: SamplesItem[];
}

export interface CalculatorContract extends EndpointContract {
  responseBody: Calculators[];
  pathParams: {
    isZiko: boolean;
  }
}

export const getCalculator = async ({
    isZiko,
}: CalculatorContract['pathParams']) => {
    await setQuery({
        isZiko,
    });

    const { data } = await request<CalculatorContract>({
        url: 'calculator/',
        method: 'GET',
        params: {
            isZiko,
        },
    });

    return data;
};
