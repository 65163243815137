import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { applySnackbarColor } from './applyColorByType';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { applySnackbarAnimation } from './applyAnimationByType';
import { removeNotification } from '../../actions/notifications';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
/* import { SnackbarIcon } from '../../constants/TypeiconBySnackbars'; */

export interface SnackbarProps {
  autoHideDuration?: number;
  snackbarTitle: ReactNode;
  snackbarDescription?: ReactNode;
  snackbarId: number;
  snackbarType: TypesSnackbars;
  animationTime?: number | string;
  isUnclosed?: boolean;
  snackbarIcon: keyof typeof TypesIcon,
}

const StyledSnackbarContainer = styled.div<Pick<SnackbarProps, 'animationTime' | 'isUnclosed' | 'snackbarType'>>`
  position: relative;

  display: flex;
  align-items: center;
  width: ${units(148)};  
  padding: ${units(6)};
  margin-bottom: ${units(3)};

  background: ${colors.grayscale05};
  border-radius: ${units(0)}; 
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    flex-shrink: 0;
  }
  
  @media (max-width: ${BreakPoints.SM}) {
    width: 100%;
  }
  
  ${applySnackbarColor};
  ${applySnackbarAnimation};
`;

const StyledButton = styled.button`  
  padding: 0;
  width: ${units(8)};
  height: ${units(8)};
  margin-left: auto;

  text-align: center;

  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const StyledMessage = styled.div`
  padding-left: ${units(2)};
  flex-shrink: 1;
`;

const StyledMessageTitle = styled.p`
  font-weight: ${FontWeight.REGULAR};  

  color: ${colors.greyDark};

  ${typography(10)};
`;

const StyledMessageDescription = styled.p`
  margin: ${units(1)} 0 0;

  font-weight: ${FontWeight.REGULAR};  

  color: ${colors.greyDark};

  ${typography(10)};
`;

export const Snackbar = ({
    autoHideDuration = 7000,
    snackbarTitle,
    snackbarDescription,
    snackbarId,
    snackbarType,
    isUnclosed,
    snackbarIcon,
}: SnackbarProps) => {
    const dispatch = useDispatch();
    const animationTime = `${(autoHideDuration - 450) / 1000}`;

    useEffect(() => {
        if (!isUnclosed) {
            setTimeout(() => dispatch(removeNotification(snackbarId)), autoHideDuration);
        }
    }, [autoHideDuration, dispatch, isUnclosed, snackbarId, snackbarType]);

    const handleClose = () => {
        dispatch(removeNotification(snackbarId));
    };

    return (
        <StyledSnackbarContainer
            animationTime={animationTime}
            snackbarType={snackbarType}
            isUnclosed={isUnclosed}
        >
            <Icon
                type={snackbarIcon}
                fill="none"
                color="none"
                viewBox="0 0 24 24"
                width={units(12)}
                height={units(12)}
            />
            <StyledMessage>
                <StyledMessageTitle>
                    {snackbarTitle}
                </StyledMessageTitle>
                {snackbarDescription && (
                    <StyledMessageDescription>
                        {snackbarDescription}
                    </StyledMessageDescription>
                )}
            </StyledMessage>
            <StyledButton onClick={handleClose}>
                <Icon
                    type={TypesIcon.CLOSE}
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                />
            </StyledButton>
        </StyledSnackbarContainer>
    );
};
