import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const categoryTableBreadcrumbs = () : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Каталог',
        url: '',
    },
    {
        key: '2',
        value: 'Категории',
        url: '',
    },
]);
