export const ProductTitle = ({
    isCertificate,
    price,
    fullName,
}: {
  isCertificate?: boolean;
  price?: number;
  fullName?: string;
}) => (
    <div>
        {`${
            isCertificate
                ? `Подарочный сертификат ${price ? `на сумму ${price} р.` : ''}`
                : fullName || ''
        }`}
    </div>
);
