import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as ordersApi from '../../../api/orders';

export const GET_ORDER = 'orders/getOrder';

export const getOrder = createAsyncThunk(
    GET_ORDER,
    createRequest({
        type: GET_ORDER,
        loader: async (id: string) => ordersApi.getOrder(id),
        showFailNotification: false,
    }),
);
