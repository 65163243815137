import { useMemo, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { createGoodsBreadcrumbs } from '../../../../constants/BreadCrumbs/createGoodsBreadcrumbs';
import { routes } from '../../../../constants/RouterPath';
import { selectProductItem, selectProductItemLoader } from '../../../../selectors/goods';
import { useTypeSelector } from '../../../../store';
import { CreateProductContract } from '../../../../types/Endpoints/goods/ProductContracts';
import { Checkbox } from '../../../../components/Checkbox';
import { Select } from '../../../../components/Select';
import { selectCategories } from '../../../../selectors/catalog';
import { selectBrands } from '../../../../selectors/brands';
import { selectCollections } from '../../../../selectors/collections';
import { SelectOption } from '../../../../types/Select/SelectOption.ts';
import { TypesSelect } from '../../../../types/TypesSelect/index.ts';
import { getIdCategory, modifyDataForSelect } from '../../../../helpers/goods';
import { updateProduct, createProduct } from '../../../../actions/goods';
import { IMainGoodsProps, IFormValuesEditGoods } from './interface.ts';
import {
    LabelsFormEditGoods,
    NamesFieldsFormEditGoods,
    TypesCategories,
    messages,
} from './constants.ts';
import {
    StyledRootWrapper,
    StyledWrapper,
    StyledRoot,
    CheckboxWrapper,
    SelectsWrapper,
    StyledIdInfo,
    StyledInput,
    StyledSubmitButtons,
} from './styles.ts';

export const MainGoodsContent: FC<IMainGoodsProps> = ({ isEditing, brandName }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const productItem = useTypeSelector(selectProductItem);
    const categories = useTypeSelector(selectCategories);
    const brands = useTypeSelector(selectBrands);
    const collections = useTypeSelector(selectCollections);

    const idCategory = useMemo(
        () => getIdCategory(TypesCategories.DEFAULT, categories, productItem?.productCategories),
        [productItem, categories],
    );

    const idBrand = useMemo(
        () => getIdCategory(TypesCategories.BRAND, brands, productItem?.productCategories),
        [productItem, brands],
    );

    const idCollection = useMemo(
        () => getIdCategory(TypesCategories.COLLECTION, collections, productItem?.productCategories),
        [productItem, collections],
    );

    const categoriesForDropdown = useMemo<SelectOption[] | undefined>(
        () => modifyDataForSelect(categories),
        [categories],
    );

    const brandsForDropdown = useMemo<SelectOption[] | undefined>(
        () => modifyDataForSelect(brands),
        [brands],
    );

    const collectionsForDropdown = useMemo<SelectOption[] | undefined>(
        () => modifyDataForSelect(collections),
        [collections],
    );

    const handleSubmit = (values: IFormValuesEditGoods) => {
        const productCategories = [
            values[NamesFieldsFormEditGoods.categoryId],
            values[NamesFieldsFormEditGoods.brandId],
            values[NamesFieldsFormEditGoods.collectionId],
        ]
            .filter(value => value)
            .map(item => ({ categoryId: +item }));

        const requestData: CreateProductContract = {
            id: isEditing ? +values[NamesFieldsFormEditGoods.id] : undefined,
            name: values[NamesFieldsFormEditGoods.name],
            externalId: values[NamesFieldsFormEditGoods.externalId],
            isShowOnSite: values[NamesFieldsFormEditGoods.isShowOnSite],
            isShowOnMobile: values[NamesFieldsFormEditGoods.isShowOnMobile],
            isHit: values[NamesFieldsFormEditGoods.isHit],
            isNew: values[NamesFieldsFormEditGoods.isNew],
            productCategories,
        };

        if (isEditing) {
            dispatch(updateProduct(requestData));
        } else {
            // FIXME: backend не верно обрабатывает name
            dispatch(createProduct(requestData));
        }
    };

    const form = useFormik({
        onSubmit: handleSubmit,
        initialValues: {
            [NamesFieldsFormEditGoods.name]: (isEditing && productItem?.fullName) || '',
            [NamesFieldsFormEditGoods.id]: (isEditing && String(productItem?.id)) || '',
            [NamesFieldsFormEditGoods.externalId]: (isEditing && productItem?.externalId) || '',
            [NamesFieldsFormEditGoods.isShowOnSite]: (isEditing && productItem?.isShowOnSite) || false,
            [NamesFieldsFormEditGoods.isShowOnMobile]: (isEditing && productItem?.isShowOnMobile) || false,
            [NamesFieldsFormEditGoods.isHit]: (isEditing && productItem?.isHit) || false,
            [NamesFieldsFormEditGoods.isNew]: (isEditing && productItem?.isNew) || false,
            [NamesFieldsFormEditGoods.categoryId]: `${(isEditing && idCategory) || ''}`,
            [NamesFieldsFormEditGoods.brandId]: `${(isEditing && idBrand) || ''}`,
            [NamesFieldsFormEditGoods.collectionId]: `${(isEditing && idCollection) || ''}`,
        },
    });

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>{messages.goods}</PageTitle>
                <Breadcrumbs breadcrumbs={createGoodsBreadcrumbs(brandName)} />
                <StyledRoot>
                    <CheckboxWrapper>
                        <StyledInput
                            name={NamesFieldsFormEditGoods.name}
                            onChange={form.handleChange}
                            value={form.values[NamesFieldsFormEditGoods.name]}
                            placeholder={LabelsFormEditGoods.nameProduct}
                            typeInput="text"
                            maxLength={255}
                            title={LabelsFormEditGoods.nameProduct}
                            isTouched={form.touched[NamesFieldsFormEditGoods.name]}
                            error={form.errors[NamesFieldsFormEditGoods.name]}
                        />

                        {isEditing && (
                            <StyledInput
                                disabled
                                name={NamesFieldsFormEditGoods.id}
                                value={form.values[NamesFieldsFormEditGoods.id]}
                                placeholder={LabelsFormEditGoods.id}
                                typeInput="text"
                                maxLength={255}
                                title={LabelsFormEditGoods.id}
                                isTouched={form.touched[NamesFieldsFormEditGoods.id]}
                                error={form.errors[NamesFieldsFormEditGoods.id]}
                            />
                        )}

                        <StyledInput
                            name={NamesFieldsFormEditGoods.externalId}
                            onChange={form.handleChange}
                            value={form.values[NamesFieldsFormEditGoods.externalId]}
                            placeholder={LabelsFormEditGoods.elemFromUpload}
                            typeInput="text"
                            maxLength={255}
                            title={LabelsFormEditGoods.elemFromUpload}
                            isTouched={form.touched[NamesFieldsFormEditGoods.externalId]}
                            error={form.errors[NamesFieldsFormEditGoods.externalId]}
                        />
                    </CheckboxWrapper>

                    <CheckboxWrapper>
                        <StyledIdInfo>{messages.display}</StyledIdInfo>
                        <Checkbox
                            name={NamesFieldsFormEditGoods.isShowOnSite}
                            title={LabelsFormEditGoods.showOnSite}
                            checked={form.values[NamesFieldsFormEditGoods.isShowOnSite]}
                            onChange={form.handleChange}
                        />

                        <Checkbox
                            name={NamesFieldsFormEditGoods.isShowOnMobile}
                            title={LabelsFormEditGoods.showOnMobile}
                            checked={form.values[NamesFieldsFormEditGoods.isShowOnMobile]}
                            onChange={form.handleChange}
                        />
                    </CheckboxWrapper>

                    <CheckboxWrapper>
                        <StyledIdInfo>{messages.characteristic}</StyledIdInfo>
                        <Checkbox
                            name={NamesFieldsFormEditGoods.isHit}
                            title={LabelsFormEditGoods.hit}
                            checked={form.values[NamesFieldsFormEditGoods.isHit]}
                            onChange={form.handleChange}
                        />

                        <Checkbox
                            name={NamesFieldsFormEditGoods.isNew}
                            title={LabelsFormEditGoods.new}
                            checked={form.values[NamesFieldsFormEditGoods.isNew]}
                            onChange={form.handleChange}
                        />
                    </CheckboxWrapper>

                    <SelectsWrapper>
                        {categoriesForDropdown && (
                            <Select
                                name={NamesFieldsFormEditGoods.categoryId}
                                options={categoriesForDropdown}
                                selectType={TypesSelect.DEFAULT}
                                value={form.values[NamesFieldsFormEditGoods.categoryId]}
                                label={LabelsFormEditGoods.category}
                                onClick={form.setFieldValue}
                                title={LabelsFormEditGoods.category}
                                isTouched={form.touched[NamesFieldsFormEditGoods.categoryId]}
                                error={form.errors[NamesFieldsFormEditGoods.categoryId]}
                            />
                        )}

                        {brandsForDropdown && (
                            <Select
                                name={NamesFieldsFormEditGoods.brandId}
                                options={brandsForDropdown}
                                selectType={TypesSelect.DEFAULT}
                                value={form.values[NamesFieldsFormEditGoods.brandId]}
                                label={LabelsFormEditGoods.brand}
                                onClick={form.setFieldValue}
                                title={LabelsFormEditGoods.brand}
                                isTouched={form.touched[NamesFieldsFormEditGoods.brandId]}
                                error={form.errors[NamesFieldsFormEditGoods.brandId]}
                            />
                        )}

                        {collectionsForDropdown && (
                            <Select
                                name={NamesFieldsFormEditGoods.collectionId}
                                options={collectionsForDropdown}
                                selectType={TypesSelect.DEFAULT}
                                value={form.values[NamesFieldsFormEditGoods.collectionId]}
                                label={LabelsFormEditGoods.collection}
                                onClick={form.setFieldValue}
                                title={LabelsFormEditGoods.collection}
                                isTouched={form.touched[NamesFieldsFormEditGoods.collectionId]}
                                error={form.errors[NamesFieldsFormEditGoods.collectionId]}
                            />
                        )}
                    </SelectsWrapper>

                </StyledRoot>
            </StyledWrapper>

            <StyledSubmitButtons
                reset={() => history.push(routes.goods)}
                submit={form.submitForm}
            />
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectProductItemLoader,
});

export const MainGoodsWithLoader = loader(MainGoodsContent as any); // TODO: need fix hoc types
