import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as salesApi from '../../../api/sales';
import { UpdatePromotionsContract } from '../../../types/Endpoints/sales/PromitionsContracts';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';

export const UPDATE_PROMOTION = 'promotions/updatePromotion';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Акция обновлена успешно',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));

    history.push(routes.sales);
};

export const updatePromotionsThink = createAsyncThunk(
    UPDATE_PROMOTION,
    createRequest({
        type: UPDATE_PROMOTION,
        loader: async ({ data, saleId }: { data: UpdatePromotionsContract['requestBody'], saleId: string }) => salesApi.updatePromotion(data, saleId),
        onSuccess,
    }),
);
