import { request } from '../../helpers/api/request';
import { DeleteNotesContract, GetNotesContract } from '../../types/Endpoints/notes/NotesContracts';

export const getNotes = async (params: GetNotesContract['pathParams']) => {
    const { data } = await request<GetNotesContract>({
        url: 'admin/logs',
        params,
    });

    return data;
};

export const deleteNotes = async (params: DeleteNotesContract['pathParams']) => {
    const { data } = await request<DeleteNotesContract>({
        url: 'admin/logs',
        method: 'DELETE',
        params,
    });

    return data;
};
