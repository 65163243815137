import {
    ChangeEvent, ReactNode, useEffect, useRef, useState, FC,
} from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { UploadFile } from '../../types/Endpoints/files/FilesContract';
import { deleteFile, uploadFile } from '../../api/files';
import { config } from '../../config';
import { addModal } from '../../actions/modal';
import { getId } from '../../helpers/generateId';
import { TypesModal } from '../../types/TypesModalComponent';

interface UploadPhotoProps {
    name: string;
    title?: string;
    titleElement?: ReactNode;
    className?: string;
    onChange?: (
        field: string,
        value?: UploadFile,
        shouldValidate?: boolean
    ) => void;
    value?: UploadFile;
    format?: string;
    beforeUpload?: () => void,
    afterUpload?: () => void,
    maxSize?: number,
}

const StyledWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${units(8)};
    width: 100%;

    @media screen and (max-width: ${BreakPoints.MD}) {
        flex-wrap: wrap;
    }
`;

const StyledTitle = styled.span`
    color: ${colors.greyDark};
    font-weight: ${FontWeight.MEDIUM};
    white-space: nowrap;

    ${typography(10)};

    @media (max-width: ${BreakPoints.SM}) {
        white-space: normal;
    }
`;

const StyledChooseFileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

const StyledLabel = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;

    height: ${units(32)};
    width: ${units(32)};

    border: 1px solid ${colors.grey30};

    border-radius: ${units(0)};
    background-color: ${colors.light};

    transition: background 0.4s ease;

    cursor: pointer;

    :hover {
        background-color: ${colors.grey60};
    }

    ${typography(5)}
`;

const StyledIconWrapper = styled.picture`
    display: flex;
`;

const StyledInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
`;

const StyledImageWrapper = styled.div`
    position: relative;
    display: flex;
`;

const StyledImage = styled.img`
    height: ${units(32)};
    width: ${units(32)};

    object-fit: contain;

    border-radius: ${units(3)};
`;

const StyledIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    margin-left: auto;
`;

const StyledWrapperIcon = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;

    cursor: pointer;
`;

const StyledInfoText = styled.p`
    color: ${colors.grey20};
    font-weight: ${FontWeight.REGULAR};

    ${typography(10)}
`;

const StyledInfoTextBlue = styled(StyledInfoText)`
    color: ${colors.blue};
    margin-bottom: ${units(2)};
`;

const StyledErrorMessage = styled.p`
    position: absolute;
    bottom: -${units(12)};
    color: ${colors.red};

    ${typography(0)}
`;

const StyledInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 64px;
`;

const StyledImageName = styled.div`
    ${typography(10)};
`;

const StyledImageSize = styled.div`
    color: ${colors.grey20};
    ${typography(10)}
`;

const StyledFlexDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: ${units(8)};
`;

const getSizeRow = (size: number) => (size > 1 ? `${size} Мб` : `${size * 1000} Кб`);

const DEFAULT_SIZE = 10;

export const UploadPhotoWithMiniature: FC<UploadPhotoProps> = ({
    name,
    title,
    titleElement,
    className,
    value,
    format = '1:1',
    onChange,
    beforeUpload,
    afterUpload,
    maxSize,
}) => {
    const ref = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    const [imgSrc, setImgSrc] = useState(value?.path);
    const [error, setError] = useState('');

    const [imgSize, setImgSize] = useState(0);
    const [imgName, setImgName] = useState('');

    useEffect(() => {
        setImgSrc(value?.path);
    }, [value]);

    const handleSetImgSize = (size: number) => {
        const res = size / 1000;
        setImgSize(res);
    };

    const acceptFileType = 'image/*,.png,.jpg,.svg,.jpeg';

    const maxFileSize = (maxSize ?? DEFAULT_SIZE) * 1000 * 1000;

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const [file] = event.target.files;
            if (!file) return;

            setError('');

            handleSetImgSize(file.size);
            setImgName(file.name);

            if (file.size > maxFileSize) {
                setError(`Ваш файл превысил разрешенный размер в ${getSizeRow(maxSize ?? DEFAULT_SIZE)}`);
                return;
            }

            if (!acceptFileType.includes(file.type.split('/')[1])) {
                setError('Ваш тип файла не может быть загружен');
                return;
            }

            const reader = new FileReader();

            reader.onload = async () => {
                const formDateFile = new FormData();
                formDateFile.append('file', file);

                try {
                    const uploadFileData = await uploadFile(formDateFile);

                    setImgSrc(uploadFileData?.path);

                    if (onChange) {
                        onChange(name, uploadFileData, false);
                    }

                    if (afterUpload) {
                        afterUpload();
                    }
                } catch (e) {
                    if (afterUpload) {
                        afterUpload();
                    }

                    throw e;
                }
            };

            reader.readAsDataURL(file);

            if (beforeUpload) {
                beforeUpload();
            }
        }
    };

    const removeFile = () => {
        if (ref.current) {
            ref.current.value = '';
        }
    };

    const handleClick = (src: string) => {
        dispatch(addModal({
            id: getId()(),
            type: TypesModal.IMAGE,
            imgSrc: src,
        }));
    };

    const handleRemoveImage = async () => {
        if (value?.id) {
            deleteFile(value?.id);
            // await deleteFile(value?.id);

            setImgSrc('');
            handleSetImgSize(0);
            setImgName('');
            removeFile();

            if (onChange) {
                onChange(
                    name,
                    {
                        id: undefined,
                        path: '',
                    },
                    false,
                );
            }
        }
    };

    return (
        <StyledWrapper>
            {title ? <StyledTitle>{title}</StyledTitle> : titleElement}
            <StyledChooseFileWrapper className={className}>
                {imgSrc ? (
                    <StyledFlexDiv>
                        <StyledImageWrapper onClick={() => handleClick(imgSrc)}>
                            <StyledImage
                                className="image"
                                src={`${config.imageUrl}/${imgSrc}`}
                            />
                        </StyledImageWrapper>
                        <StyledInfoWrapper>
                            <StyledImageName>{imgName || value?.path}</StyledImageName>
                            {imgSize ? <StyledImageSize>{`${imgSize} кб, ~ формат ${format}`} </StyledImageSize> : null}
                        </StyledInfoWrapper>
                        <StyledWrapperIcon>
                            <StyledIcon
                                type={TypesIcon.CLOSE}
                                color={colors.white}
                                viewBox="0 0 16 16"
                                width="16px"
                                height="16px"
                                onClick={handleRemoveImage}
                            />
                        </StyledWrapperIcon>
                    </StyledFlexDiv>
                ) : (
                    <StyledFlexDiv>
                        <StyledLabel
                            className="image-upload"
                            htmlFor={`upload-news-input-${name}`}
                        >
                            <StyledIconWrapper>
                                <svg
                                    width="24"
                                    height="21"
                                    viewBox="0 0 26 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        // eslint-disable-next-line max-len
                                        d="M23 22H3C2.46957 22 1.96086 21.7893 1.58579 21.4142C1.21071 21.0391 1 20.5304 1 20V6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4H6.99927L8.99927 1H16.9993L18.9993 4H23C23.5304 4 24.0391 4.21071 24.4142 4.58579C24.7893 4.96086 25 5.46957 25 6V20C25 20.5304 24.7893 21.0391 24.4142 21.4142C24.0391 21.7893 23.5304 22 23 22Z"
                                        stroke="#C7C7C7"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M13 17C15.4853 17 17.5 14.9853 17.5 12.5C17.5 10.0147 15.4853 8 13 8C10.5147 8 8.5 10.0147 8.5 12.5C8.5 14.9853 10.5147 17 13 17Z"
                                        stroke="#C7C7C7"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </StyledIconWrapper>
                        </StyledLabel>
                        {!error && !imgSrc && (
                            <div>
                                <StyledInfoTextBlue>Загрузить изображение</StyledInfoTextBlue>
                                <StyledInfoText>{`до ${getSizeRow(maxSize ?? DEFAULT_SIZE)}, ~ формат ${format}`}</StyledInfoText>
                            </div>
                        )}
                    </StyledFlexDiv>
                )}
                <StyledInput
                    ref={ref}
                    id={`upload-news-input-${name}`}
                    type="file"
                    onChange={onChangeHandler}
                    accept={acceptFileType}
                />
            </StyledChooseFileWrapper>

            {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
        </StyledWrapper>
    );
};
