export enum ShopsServices {
    PURCHASE = 'PURCHASE',
    PURCHASE_EXCHANGE = 'PURCHASE_EXCHANGE',
}

export enum StatusShops {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ALL = 'ALL',
}
