import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CollectionsWithLoader } from './loaders';
import { getCollections } from '../../../actions/collections/thunks';

export const Collections = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCollections());
    }, []);

    return <CollectionsWithLoader />;
};
