import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as searchSettingsApi from '../../../api/landings';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { getSearchSettings } from './getLandings';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';

export const DELETE_SEARCH_SETTINGS = 'searchSettings/deleteSearchSettings';

const onSuccess = (): Thunk => async dispatch => {
    history.push(routes.search_settings);
    await dispatch(addNotification({
        type: TypesSnackbars.ERROR,
        message: {
            title: 'Посадочная страница удалена',
        },
        icon: TypesIcon.SNACK_ERROR,
    }));

    dispatch(getSearchSettings({ page: 1, size: 50 }));
};

export const deleteSearchSettings = createAsyncThunk(
    DELETE_SEARCH_SETTINGS,
    createRequest({
        type: DELETE_SEARCH_SETTINGS,
        loader: async (id: number) => searchSettingsApi.deleteSearchSettings(id),
        onSuccess,
    }),
);
