import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as salesApi from '../../../api/sales';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';

export const DELETE_PROMOTION = 'promotions/createPromotion';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Акция успешно удалена',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));

    history.push(routes.sales);
};

export const deletePromotionsThink = createAsyncThunk(
    DELETE_PROMOTION,
    createRequest({
        type: DELETE_PROMOTION,
        loader: async (id: string) => salesApi.deletePromotion(id),
        onSuccess,
    }),
);
