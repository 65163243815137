import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CertificatesWithLoader } from './loaders';
import { getCertificates } from '../../actions/certificates/thunks';

export const Certificates = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCertificates());
    }, []);

    return <CertificatesWithLoader />;
};
