import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EditUserWithLoader } from './loaders';
import { getUser } from '../../../actions/users/thunks/getUser';

export const EditUser = () => {
    const dispatch = useDispatch();
    const { userId } = useParams<{userId: string}>();

    useEffect(() => {
        dispatch(getUser(userId));
    }, [userId]);

    return <EditUserWithLoader />;
};
