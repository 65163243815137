import { KeyboardEventHandler, PureComponent, ReactNode } from 'react';

const eventNames = ['onKeyDown', 'onKeyUp'];

const nativeEventNames = {
    onKeyDown: 'keydown',
    onKeyUp: 'keyup',
} as Record<string, keyof WindowEventMap>;

interface KeyboardEventsProps {
  [key: string]: KeyboardEventHandler | null;
}

export class KeyboardEvents extends PureComponent<KeyboardEventsProps> {
    events: Record<string, KeyboardEventHandler | null>;

    constructor(props: KeyboardEventsProps) {
        super(props);

        this.events = {};
    }

    componentDidMount() {
        this.updateEvents();
    }

    componentDidUpdate() {
        this.updateEvents();
    }

    componentWillUnmount() {
        eventNames.forEach(eventName => this.removeEvent(eventName));
    }

    updateEvents() {
        const { props } = this;

        eventNames.forEach(eventName => {
            const handler = props[eventName];

            this.addEvent(eventName, handler as unknown as KeyboardEventHandler);
        });
    }

    addEvent(eventName: string, handler: KeyboardEventHandler) {
        const { events } = this;
        const currentHandler = events[eventName];

        if (currentHandler && currentHandler === handler) {
            return;
        }

        this.removeEvent(eventName);

        if (handler) {
            events[eventName] = handler;

            window.addEventListener(
        nativeEventNames[eventName] as keyof WindowEventMap,
        handler as unknown as EventListener,
            );
        }
    }

    removeEvent(eventName: string) {
        const { events } = this;

        const handler = events[eventName];

        if (handler) {
            events[eventName] = null;

            window.removeEventListener(
        nativeEventNames[eventName] as keyof WindowEventMap,
        handler as unknown as EventListener,
            );
        }
    }

    render(): ReactNode {
        return null;
    }
}
