import { request } from '../../helpers/api/request';
import { EditBannerItemContract } from '../../types/Endpoints/mainPage/MainPageContracts';
import { BannerItemForEdit } from '../../types/Models/MainPage/MainPageModel';

export const editBannerItem = async ({ key, newData }:{key: number, newData: BannerItemForEdit}) => {
    const { data } = await request<EditBannerItemContract>({
        url: `admin/banners/items/${key}`,
        method: 'PATCH',
        data: newData,
    });

    return data;
};
