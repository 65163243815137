import styled from 'styled-components';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';
import { selectGetCatalogCategoriesLoader, selectSearchCategories } from '../../../selectors/catalog';
import { SearchBar } from '../../../components/SearchBar';
import { CategoriesTable } from '../../../components/Table/CategoriesTable';
import { units } from '../../../helpers/styles/units';
import { PageTitle } from '../../../components/PageTitle';
import { useTypeSelector } from '../../../store';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { categoryTableBreadcrumbs } from '../../../constants/BreadCrumbs/categoryTableBreadcrumbs';

const StyledRoot = styled.div`
  display: flex;
  padding-top: ${units(20)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

const StyledPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
  padding-top: ${units(19)};
`;

// const StyledButton = styled(Button)`
//   display: flex;
//   align-items: center;
//   flex-direction: row;
//   gap: ${units(3)};
//   max-width: ${units(137)};
//   width: 100%;
//   height: ${units(24)};
// `;

const StyledSearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

const SettingContent = () => {
    const categories = useTypeSelector(selectSearchCategories);

    return (
        <StyledWrapper>
            <PageTitle>Категории</PageTitle>
            <Breadcrumbs breadcrumbs={categoryTableBreadcrumbs()} />
            <StyledPanelWrapper>
                <StyledSearchBarWrapper>
                    <SearchBar />
                </StyledSearchBarWrapper>
                {/* <StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => history.push(routes.create_category)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </StyledButton> */}
            </StyledPanelWrapper>
            <StyledRoot>
                <CategoriesTable categories={categories} />
            </StyledRoot>
        </StyledWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetCatalogCategoriesLoader,
});

export const SettingWithLoader = loader(SettingContent);
