import { FC } from 'react';
import { MainGoods } from './MainGoods';
import { EditGoodContent } from './loaders';
import { IEditGoodProps } from './interface';

export const EditGood: FC<IEditGoodProps> = ({ isEditing }) => {
    if (isEditing) {
        return <EditGoodContent />;
    }

    return <MainGoods brandName="Добавить" />;
};
