import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as salesApi from '../../../api/sales';

export const GET_PROMOTION = 'promotions/getPromotionThink';

export const getPromotionThink = createAsyncThunk(
    GET_PROMOTION,
    createRequest({
        type: GET_PROMOTION,
        loader: async (id: string) => salesApi.getPromotion(id),
    }),
);
