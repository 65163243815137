import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { fontName } from '../GlobalStyle';
import { TypesIcon } from '../../types/TypesIcon';
import { Icon } from '../Icon';
import { EditCatalogModel } from '../../pages/Catalog/Settings/EditCategory/loaders';

enum Cases {
  NOMINATIVE = 'Именительный',
  GENITIVE = 'Родительный',
  DATIVE = 'Дательный',
  ACCUSATIVE = 'Винительный',
  INSTRUMENTAL = 'Творительный',
  PREPOSITIONAL = 'Предложный',
}

const options = [
    {
        id: 1,
        name: Cases.NOMINATIVE,
    },
    {
        id: 2,
        name: Cases.GENITIVE,
    },
    {
        id: 3,
        name: Cases.DATIVE,
    },
    {
        id: 4,
        name: Cases.ACCUSATIVE,
    },
    {
        id: 5,
        name: Cases.INSTRUMENTAL,
    },
    {
        id: 6,
        name: Cases.PREPOSITIONAL,
    },
];

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(8)};
`;

const StyledTooltip = styled.div`
  font-family: 'Nunito', sans-serif;
  background-color: ${colors.grayscale05};
  color: ${colors.greyDark};
  padding: ${units(4)};
  ${typography(1)};
`;

const ToolTipWrapper = styled.div`
  position: absolute;
  bottom: ${units(19)};
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;

  display: none;
  width: max-content;
  max-width: ${units(61)};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ToolTipTriangle = styled.div`
  width: 0;
  height: 0;

  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 6px solid ${colors.grayscale05};
  transform: rotate(180deg);
`;

const DescriptionButton = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  gap: ${units(2)};
  padding: ${units(4)};

  font-family: ${fontName};

  background-color: ${colors.grayscale05};
  color: ${colors.greyDark};

  border: none;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;

  ${typography(5)};

  & > svg {
    stroke: none;
  }

  &:hover {
    color: ${colors.white};
    background-color: ${colors.grey20};
  }

  &:hover ${ToolTipWrapper} {
    display: flex;
  }

  &:hover svg.active,
  &:hover svg.inactive {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7485%)
      hue-rotate(109deg) brightness(103%) contrast(99%);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${units(5)};
  align-items: center;
`;

const StyledTextarea = styled.textarea`
  height: 200px;
  width: 100%;
  padding: ${units(8)} ${units(12)};

  resize: none;
  border: 1px solid ${colors.grey30};
  outline: none;
  background: none;

  ${typography(5)};

  &::-webkit-scrollbar {
    width: ${units(3)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grayscale05};
    border-radius: ${units(2)};
  }
`;

const StyledOptionItem = styled.li`
  padding: ${units(4)};

  list-style: none;

  ${typography(5)};

  &:hover {
    cursor: pointer;

    background-color: ${colors.grayscale05};
  }
`;

const StyledOptionsButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${units(2)};
  width: fit-content;

  border: none;
  outline: none;
  background: none;
  color: ${colors.greyDark};
  -webkit-appearance: none;

  cursor: pointer;

  ${typography(10)};
`;

const StyledArrowIcon = styled(Icon)`
  transform: rotate(90deg);
  transition: transform 0.2s ease;

  filter: brightness(0) saturate(100%) invert(20%) sepia(6%) saturate(275%)
    hue-rotate(2deg) brightness(95%) contrast(91%);

  &.active {
    transform: rotate(-90deg);
  }
`;

const StyledOptionsContainer = styled.ul`
  position: absolute;
  top: 24px;
  z-index: 10;

  height: ${units(124)};
  padding-top: ${units(4)};
  width: ${units(282)};
  overflow-y: auto;

  background-color: ${colors.white};
  box-shadow: 0px 10px 10px 0px #3938360d;

  &::-webkit-scrollbar {
    width: ${units(3)};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grayscale05};
    border-radius: ${units(2)};
  }
`;

const StyledOptionsCase = styled(StyledOptionsContainer)`
  width: ${units(106)};
  height: auto;
  top: ${units(17)};
`;

const StyledGoodsProperties = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${units(2)};
`;

export interface ExternalIdItem {
  id: string;
  name: string;
}

enum TransofrmFuncs {
  TRIM = 'Trim',
  UPPER_CASE = 'UpperCase',
  LOWER_CASE = 'LowerCase',
  TITLE_CASE = 'TitleCase',
  CAMEL_CASE = 'CamelCase',
}

enum IconsData {
  UpperCase = 'upper-case',
  LowerCase = 'lower-case',
  TitleCase = 'title-case',
  CamelCase = 'camel-case',
}

const tooltipData = {
    [TransofrmFuncs.TRIM]: 'Очистка пробелов с начала и конца строки',
    [TransofrmFuncs.UPPER_CASE]: 'Все в верхнем регистре',
    [TransofrmFuncs.LOWER_CASE]: 'Все в нижнем регистре',
    [TransofrmFuncs.TITLE_CASE]: 'Первая буква в верхнем регистре',
    [TransofrmFuncs.CAMEL_CASE]: 'Первая буква в нижнем регистре',
};

const ToolTip = (value: string) => (
    <ToolTipWrapper>
        <StyledTooltip>{value}</StyledTooltip>
        <ToolTipTriangle />
    </ToolTipWrapper>
);

export const GenerateDescription = ({
    form,
    optionsByCategory,
}: {
  form: FormikProps<EditCatalogModel>;
  optionsByCategory: { name: string; id: string }[];
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, []);
    const [isOpenOptions, setIsOpenOptions] = useState(false);
    const [isCaseOpen, setIsCaseOpen] = useState(false);

    const handleInsert = (data: string, props: any) => {
        const textarea = textareaRef.current;
        if (!textarea) return;
        const oldValue = props.values.seoCategoryProductDescription;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;

        const newState = oldValue.substring(0, startPos) + data + oldValue.substring(endPos);

        props.setFieldValue('seoCategoryProductDescription', newState);

        const newDataLength = data.length;
        const newStartPos = startPos + newDataLength;

        textarea.focus();

        timeoutRef.current = setTimeout(() => {
            textarea.selectionStart = newStartPos;
            textarea.selectionEnd = newStartPos;
        }, 0);
    };

    const close = () => {
        setIsCaseOpen(false);
    };

    const buttonsData = Object.keys(tooltipData).map(item => ({
        id: item,
        name: item,
        icon: IconsData[item as keyof typeof IconsData],
        tooltip: tooltipData[item as TransofrmFuncs],
    }));

    return (
        <>
            <StyledGoodsProperties>
                <StyledOptionsButton
                    type="button"
                    onClick={() => {
                        setIsOpenOptions(v => !v);
                        if (isCaseOpen) setIsCaseOpen(false);
                    }}
                >
                    Свойство товара
                    <StyledArrowIcon
                        type={TypesIcon.ARROW_SMALL}
                        color={colors.greyDark}
                        width="10px"
                        height="10px"
                        viewBox="0 0 10 10"
                        className={isOpenOptions ? 'active' : ''}
                    />
                </StyledOptionsButton>
                {isOpenOptions && (
                    <StyledOptionsContainer>
                        {optionsByCategory
                        && optionsByCategory.map(item => (
                            <StyledOptionItem
                                key={item.id}
                                onClick={() => {
                                    handleInsert(`{${item.id}}`, form);
                                    setIsOpenOptions(false);
                                }}
                            >
                                {`${item.id} - ${item.name}`}
                            </StyledOptionItem>
                        ))}
                    </StyledOptionsContainer>
                )}
            </StyledGoodsProperties>

            <StyledDescriptionContainer>
                <ButtonsWrapper>
                    <StyledGoodsProperties>
                        <DescriptionButton
                            type="button"
                            onClick={() => {
                                setIsCaseOpen(v => !v);
                                if (isCaseOpen) setIsOpenOptions(false);
                            }}
                        >
                            *Case
                            <StyledArrowIcon
                                type={TypesIcon.ARROW_SMALL}
                                color={colors.greyDark}
                                width="10px"
                                height="10px"
                                viewBox="0 0 10 10"
                                className={isCaseOpen ? 'active' : 'inactive'}
                            />
                            {ToolTip('Склонение по падежам')}
                        </DescriptionButton>

                        {isCaseOpen && (
                            <StyledOptionsCase onMouseLeave={close}>
                                {options
                                && options.map(item => (
                                    <StyledOptionItem
                                        key={item.id}
                                        onClick={() => {
                                            handleInsert(`*Case(${item.name})`, form);
                                            setIsCaseOpen(false);
                                        }}
                                    >
                                        {item.name}
                                    </StyledOptionItem>
                                ))}
                            </StyledOptionsCase>
                        )}
                    </StyledGoodsProperties>
                    {buttonsData
                    && buttonsData.map(item => (
                        <DescriptionButton
                            key={item.id}
                            type="button"
                            onClick={() => handleInsert(`*${item.id}`, form)}
                        >
                            {item?.icon && (
                                <Icon
                                    type={item.icon}
                                    width="19px"
                                    height="18px"
                                    viewBox="0 0 19 18"
                                />
                            )}
                            {`*${item.name}`}
                            {ToolTip(item.tooltip)}
                        </DescriptionButton>
                    ))}
                </ButtonsWrapper>
                <StyledTextarea
                    name="seoCategoryProductDescription"
                    ref={textareaRef}
                    value={form.values.seoCategoryProductDescription}
                    onChange={e => form.handleChange(e)}
                />
            </StyledDescriptionContainer>
        </>
    );
};
