import { request } from '../../helpers/api/request';

export const deleteFilter = async (filterId: number) => {
    const { data } = await request({
        url: `admin/filters/${filterId}`,
        method: 'DELETE',
    });

    return data;
};
