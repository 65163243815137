import { request } from '../../helpers/api/request';
import { GetBrandsContract } from '../../types/Endpoints/brands/BrandsContracts';

export const getBrands = async () => {
    const { data } = await request<GetBrandsContract>({
        url: 'admin/brands',
    });

    return data;
};
