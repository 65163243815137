import { useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { CharacteristicsGoodsWithLoader } from './loaders';
import { getPropertiesThink } from '../../../../actions/goods/thunks/properties.ts';

export const CharacteristicsGoods: FC = () => {
    const dispatch = useDispatch();
    const { goodsId } = useParams<{ goodsId: string }>();

    useEffect(() => {
        dispatch(getPropertiesThink(goodsId));
    }, []);

    return <CharacteristicsGoodsWithLoader />;
};
