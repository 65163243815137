import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled, { css } from 'styled-components';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { units } from '../../helpers/styles/units';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import {
    BannerItem, MobileBlockKey,
} from '../../types/Models/MainPage/MainPageModel';
import { useTypeSelector } from '../../store';
import { selectMobileBodyBlock } from '../../selectors/mainPage';
import { colors } from '../../constants/Colors';
import { EmptyRow } from '../EmptyRow';
import { PageStatus } from '../../pages/Handbook/loaders';

interface TableProps {
  className?: string;
  bannerKey: MobileBlockKey;
  changePage: (key: PageStatus, data: BannerItem | null) => void;
  searchString: string;
}

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },

        '& .MuiTable-root': {
            backgroundColor: colors.light,
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
    },
};

const StyledCell = styled(TableCell)`
  .status {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};
  }

  & > div {
    ${props => props.className === 'active'
      && css`
        background-color: rgba(87, 198, 118, 0.05);

        & div {
          background-color: #6db180;
        }
      `}

    ${props => props.className === 'inactive'
      && css`
        background-color: rgba(231, 72, 72, 0.05);

        & div {
          background-color: #e74848;
        }
      `}
  }
`;

const StyledStatusInfo = styled.div`
  width: ${units(4)};
  height: ${units(4)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
  width: ${units(42)};

  gap: ${units(4)};
`;

const StyledDiv = styled(ContentWrapper)`
  width: ${units(62)};
`;

export const MobileBannersSearchTable = ({ bannerKey, changePage, searchString }: TableProps) => {
    const mobileBodyBlocks = useTypeSelector(selectMobileBodyBlock(bannerKey));

    const tableData = mobileBodyBlocks?.bannerItems.filter(item => item.title.toLowerCase().includes(searchString.toLowerCase())) || [];

    return (
        <TableContainer component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell align="left">&nbsp;</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Заголовок</TableCell>
                        <TableCell align="left">Статус</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={[styles.root, styles.body]}
                >
                    {tableData && tableData.map(row => (
                        <TableRow
                            key={row.id}
                            sx={{ 'td, th': { border: 0 } }}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ maxWidth: '85px' }}
                            >
                                <ContentWrapper>
                                    <Icon
                                        type={TypesIcon.DRAG_AND_DROP}
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                        color={colors.grey60}
                                    />
                                    <div> {row.order} </div>
                                </ContentWrapper>
                            </TableCell>
                            <TableCell align="left" className="text-grey">{`${
                                row.id || ''
                            }`}
                            </TableCell>
                            <TableCell align="left">{`${
                                row.title || ''
                            }`}
                            </TableCell>

                            <StyledCell
                                align="left"
                                className={`${
                                    row.status === 'ACTIVE' ? 'active' : 'inactive'
                                }`}
                            >
                                {row.status === 'ACTIVE' ? (
                                    <StyledDiv>
                                        <StyledStatusInfo />
                                        <span>Активный</span>
                                    </StyledDiv>
                                ) : (
                                    <StyledDiv>
                                        <StyledStatusInfo />
                                        <span>Неактивный</span>
                                    </StyledDiv>
                                )}
                            </StyledCell>
                            <TableCell align="left" sx={{ width: '5%' }}>
                                <EditButton
                                    typeButton={TypesButton.SECONDARY}
                                    size={SizesButton.M}
                                    onClick={() => changePage(PageStatus.EDIT, row)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}

                    {!tableData.length && <EmptyRow colSpan={5} />}

                </TableBody>
            </Table>
        </TableContainer>
    );
};
