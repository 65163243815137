import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import usePagination from '@mui/material/usePagination';
import { SearchBar } from '../../../components/SearchBar';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { routes } from '../../../constants/RouterPath';
import { units } from '../../../helpers/styles/units';
import { Button } from '../../../components/Button';
import { PageTitle } from '../../../components/PageTitle';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../../store';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { colors } from '../../../constants/Colors';
import { selectFilters } from '../../../selectors/filters';
import { FiltersTable } from '../../../components/Table/FiltersTable';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { FiltersTableBreadcrumbs } from '../../../constants/BreadCrumbs/filtersTableBreadcrumbs';
import { selectSearchData } from '../../../selectors/search';
import { useDebounce } from '../../../hooks/useDebounce';
import { getFilters } from '../../../actions/filters';
import { Pagination } from '../../../components/Pagination';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
  padding-bottom: ${units(20)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

const StyledPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
  padding-top: ${units(14)};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  max-width: ${units(137)};
  width: 100%;
  height: ${units(24)};
`;

const StyledSearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};  
`;

const START_PAGE = 1;
const PAGE_SIZE = 50;

export const FiltersContent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState<{current: number} | null>(null);
    const filters = useTypeSelector(selectFilters);
    const searchString = useTypeSelector(selectSearchData);
    const [searchValue] = useDebounce(300, searchString || '');

    const { items } = usePagination({
        page: page?.current || START_PAGE,
        count: Math.ceil((filters?.count || 0) / PAGE_SIZE),
        boundaryCount: 0,
        siblingCount: 2,
        onChange: (_event: ChangeEvent<unknown>, current: number) => setPage({ current }),
    });

    useEffect(() => {
        setPage({ current: START_PAGE });
    }, [searchValue]);

    useEffect(() => {
        if (page) {
            dispatch(getFilters({
                page: page.current,
                size: PAGE_SIZE,
                search: searchValue,
            }));
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [page]);

    return (
        <StyledWrapper>
            <StyledPageTitle>Фильтры</StyledPageTitle>
            <Breadcrumbs breadcrumbs={FiltersTableBreadcrumbs()} />
            <StyledPanelWrapper>
                <StyledSearchBarWrapper>
                    <SearchBar />
                </StyledSearchBarWrapper>
                <StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => history.push(routes.create_filter)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </StyledButton>
            </StyledPanelWrapper>
            <StyledRoot>
                <FiltersTable filters={filters?.data} />
                {filters && !(filters.isLast && filters.page === START_PAGE) && <Pagination items={items} />}
            </StyledRoot>
        </StyledWrapper>
    );
};
