export enum StatusSales {
    ACTIVE = 'Published',
    PLANING = 'Planned',
    END = 'Ended',
  }

export enum Roles {
    ADMIN = 'ADMIN',
    MARKETING = 'MARKETING',
    ESHOP = 'ESHOP',
    USER = 'USER',
  }

export const START_PAGE = 1;
export const PAGE_SIZE = 50;
