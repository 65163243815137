// import { OrderStatusType } from '../Endpoints/orders/OrdersContracts';
import { TypesIcon } from '../TypesIcon';

export enum OrderStatus {
    NEW = 'Новый',
    PROCESSED = 'Обрабатывается',
    DONE = 'Выполнен',
    REJECT = 'Отменен',
    DELIVERY = 'Доставляется',
  }

// export const OrderFiltersStatus: {[key: string]: OrderStatusType} = {
//     [OrderStatus.NEW]: 'NEW',
//     [OrderStatus.PROCESSED]: 'PROCESSING',
//     [OrderStatus.DONE]: 'COMPLETED',
//     [OrderStatus.REJECT]: 'REFUSED',
// };

export const OrderStatusClasses = {
    [OrderStatus.NEW]: 'NEW',
    [OrderStatus.PROCESSED]: 'PROCESSED',
    [OrderStatus.DONE]: 'DONE',
    [OrderStatus.REJECT]: 'REJECT',
    [OrderStatus.DELIVERY]: 'DELIVERY',
};

// export const OrderStatusTitle = {
//     [OrderStatus.NEW]: 'Новый',
//     [OrderStatus.PROCESSED]: 'Обрабатывается',
//     [OrderStatus.DONE]: 'Выполнен',
//     [OrderStatus.REJECT]: 'Отменен',
// };

export const OrderStatusIcon = {
    [OrderStatus.NEW]: TypesIcon.STATUS_NEW,
    [OrderStatus.PROCESSED]: TypesIcon.STATUS_PROCESS,
    // [OrderStatus.WORK]: TypesIcon.STATUS_WORK,
    // [OrderStatus.READY]: TypesIcon.SNACK_SUCCES,
    [OrderStatus.DONE]: TypesIcon.SNACK_SUCCES,
    [OrderStatus.REJECT]: TypesIcon.STATUS_REJECT,
    [OrderStatus.DELIVERY]: TypesIcon.STATUS_DELIVERY,
};
