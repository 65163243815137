import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setSearchData } from '../actions/search/thunks/setSearchData';

export const useClearSearchData = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSearchData(''));
    }, [pathname, dispatch]);
};
