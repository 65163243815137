import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import { addNotification } from '../../notifications';
import { TypesIcon } from '../../../types/TypesIcon';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Thunk } from '../../../helpers/redux/Thunk';
import { PropertyItemContract } from '../../../types/Endpoints/goods/GoodsContracts';
import * as productApi from '../../../api/goods';

interface IDataUpdateProperties {
    data: PropertyItemContract[];
    id: number;
}

const UPDATE_PPROPERTIES = 'goods/updateProperties';

const MESSAGE = 'Обновлено';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.INFO,
        icon: TypesIcon.SNACK_DIAMOND,
        message: {
            title: MESSAGE,
        },
    }));
};

export const updateProperties = createAsyncThunk<PropertyItemContract[], IDataUpdateProperties>(
    UPDATE_PPROPERTIES,
    createRequest({
        type: UPDATE_PPROPERTIES,
        onSuccess,
        loader: async ({ data, id }) => productApi.updateProperties(data, id),
    }),
);
