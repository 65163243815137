import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as blogApi from '../../../api/blog';
import { GetBlogContract } from '../../../types/Endpoints/blog/BlogCRUDContracts';

export const GET_BLOG = 'blog/getBlog';

export const getBlogsThunk = createAsyncThunk(
    GET_BLOG,
    createRequest({
        type: GET_BLOG,
        loader: async (params: GetBlogContract['pathParams']) => blogApi.getBlogs(params),
    }),
);
