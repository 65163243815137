import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../../../types/Models/Users/User';
import { createRequest } from '../../../helpers/api/createRequest';
import { getSessionFromStorage } from '../../../helpers/auth/session';

export const GET_ACTIVE_USER = 'user/getActiveUser';

export const getActiveUser = createAsyncThunk<User>(
    GET_ACTIVE_USER,
    createRequest({
        type: GET_ACTIVE_USER,
        loader: async () => {
            const activeSession = await getSessionFromStorage();
            const user = activeSession?.user;

            return { ...user };
        },
        showFailNotification: false,
    }),
);
