import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';

interface TitleProps {
    className?: string
}

const StyledTitle = styled.p`
  color: ${colors.grayscale80};
  font-weight: ${FontWeight.BOLD};
  letter-spacing: 0.15px;
  
  ${typography(10)}
`;

export const Title = ({ className, children }: PropsWithChildren<TitleProps>) => (
    <StyledTitle className={className}>
        {children}
    </StyledTitle>
);
