import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as collectionsApi from '../../../api/collections';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';
import { EditCatalogCollectionItem } from '../../../types/Endpoints/collections/GetCollectionsContract';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const EDIT_COLLECTION = 'collections/editCollection';

const onSuccess = (): Thunk => async dispatch => {
    history.push(routes.collections);

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editCatalogCollection = createAsyncThunk(
    EDIT_COLLECTION,
    createRequest({
        type: EDIT_COLLECTION,
        loader: async (requestData: EditCatalogCollectionItem) => collectionsApi.editCollection(requestData),
        onSuccess,
    }),
);
