import styled from 'styled-components';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { units } from '../../helpers/styles/units';
import { TextEditor } from '../TextEditor';
import { colors } from '../../constants/Colors';
import { Input } from '../Input';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { UploadPhotoWithMiniature } from '../UploadPhotoWithMiniature';
import { Block } from '../../types/DinamikPages';
import { DynamicPagesKeys } from '../../types/Models/DinamicPages';
import { CreateBlocksModel, PagesModel } from '../../api/dinamicPages/updatePage';
import { TypesIcon } from '../../types/TypesIcon';
import { isEqualObject } from '../../helpers/isEqual';
import { addNotification } from '../../actions/notifications';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { SubmitButtons } from '../StyledComponents';

interface pageProps {
    pagesData: CreateBlocksModel,
    pageKey: DynamicPagesKeys,
    updatePage: any,
    toggle?: (value: boolean) => void
}

const StyledMainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 75%;
    max-width: ${units(334)};
    padding-bottom: ${units(20)};
    gap: ${units(20)};
`;

const StyledTextEditor = styled(TextEditor)`

    & .ql-toolbar.ql-snow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: ${units(28)};
        padding: ${units(3)} ${units(8)};

        border-radius: 0;
        border-color: #c7c7c7;
    }

    & .ql-snow .ql-editor h3 {
        text-align: center;
    }

    & .ql-container.ql-snow {
        border-radius: 0;
        border-color: #c7c7c7;

        & .ql-editor {
            min-height: ${units(28)};
            padding: ${units(8)} ${units(12)};
        }
    }
`;

const StyledInput = styled(Input)`
    font-weight: ${FontWeight.REGULAR};
    letter-spacing: 0.8px;

    background-color: ${colors.light};

    ${typography(10)};

    & .input-title {
        color: ${colors.grey20};
    }
`;

const StyledUploader = styled(UploadPhotoWithMiniature)`
    width: 100%;
`;

const StyledInputWrapper = styled.div`
`;

const StyledForm = styled(Form)`
    width: 100%;
`;

const StyledSubmitButtons = styled.div`
    margin-left: -${units(12)};
`;

export const FooterForm = ({
    pagesData, pageKey, updatePage,
}: pageProps) => {
    const dispatch = useDispatch();

    const handleSubmit = (values: any) => {
        let body = {
            blocks: values.blocks.map((block: Block) => ({
                isPublished: block.isPublished,
                pattern: {
                    title: block.pattern.title,
                    subtile: block.pattern.subtile,
                    value: block.pattern.value,
                    type: block.pattern.type,
                    image: {
                        id: block.pattern.image?.id || 0,
                        path: block.pattern.image?.path || '',
                    },
                },
                order: block.order,
            })),
        };

        const pageValue: PagesModel = {
            key: pageKey,
            data: body,
        };

        dispatch(updatePage(pageValue));
    };

    const validationSchema = yup.object().shape({
        blocks: yup.array().of(
            yup.object().shape({
                pattern: yup.lazy((_: any, options: any) => {
                    const index = Number(options?.path?.split('[')[1].split(']')[0]);

                    if ((index === 0 || index === 10) && pageKey === DynamicPagesKeys.FOOTER_APP) {
                        return yup.object().shape({
                            value: yup.string().max(50, 'Не более 50 символов'),
                        });
                    }

                    return yup.object().shape({
                        value: yup.string(),
                    });
                }),
            }),
        ),
    });

    const initialValue = pagesData;

    const handleResetForm = (clb: () => void, values: CreateBlocksModel) => {
        clb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <Formik<CreateBlocksModel>
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {props => (
                <StyledForm onSubmit={handleSubmit}>
                    <FieldArray name="blocks">
                        {() => (
                            <StyledMainWrapper>
                                {props.values.blocks?.map(
                                    (block: Block, index: number) => {
                                        const formatImageFooterApp = block.order === 2 ? '1:1' : '5:1.5'; // order:2 - электронный знак

                                        return (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <div key={`block${index}`}>
                                                {block.pattern.type === 'text' && (
                                                    <StyledInputWrapper>
                                                        {(index === 0 || index === 10) && pageKey === DynamicPagesKeys.FOOTER_APP ? (
                                                            <StyledInput
                                                                name={`blocks[${index}].pattern.value`}
                                                                onChange={props.handleChange}
                                                                value={props.values?.blocks[index]?.pattern?.value}
                                                                title={
                                                                    props.values.blocks[index].pattern.title
                                                                        ? props.values.blocks[index].pattern.title
                                                                        : props.values.blocks[index].pattern.subtile
                                                                }
                                                                placeholder={
                                                                    props.values.blocks[index].pattern.title
                                                                        ? props.values.blocks[index].pattern.title
                                                                        : props.values.blocks[index].pattern.subtile
                                                                }
                                                                typeInput="text"
                                                                isTouched={
                                                                    props.touched?.blocks
                                                                    && props.touched.blocks[index]?.pattern?.value
                                                                }
                                                                error={
                                                                    props.errors?.blocks
                                                                    && props.errors?.blocks[index]?.pattern?.value
                                                                }
                                                                count={props.submitCount}
                                                            />
                                                        )
                                                            : (
                                                                <StyledInput
                                                                    name={`blocks[${index}].pattern.value`}
                                                                    onChange={props.handleChange}
                                                                    value={props.values?.blocks[index]?.pattern?.value}
                                                                    title={
                                                                        props.values.blocks[index].pattern.title
                                                                            ? props.values.blocks[index].pattern.title
                                                                            : props.values.blocks[index].pattern.subtile
                                                                    }
                                                                    placeholder={
                                                                        props.values.blocks[index].pattern.title
                                                                            ? props.values.blocks[index].pattern.title
                                                                            : props.values.blocks[index].pattern.subtile
                                                                    }
                                                                    typeInput="text"
                                                                />
                                                            )}
                                                    </StyledInputWrapper>
                                                )}
                                                {block.pattern.type === 'image' && (
                                                    <StyledUploader
                                                        name={`blocks[${index}].pattern.image`}
                                                        title={props.values.blocks[index].pattern.title}
                                                        onChange={props.setFieldValue}
                                                        value={props.values.blocks[index].pattern.image}
                                                        format={pageKey === DynamicPagesKeys.FOOTER_APP ? formatImageFooterApp : '1:1'}
                                                        maxSize={0.05}
                                                    />
                                                )}
                                                {block.pattern.type === 'editor' && (
                                                    <StyledTextEditor
                                                        name={`blocks[${index}].pattern.value`}
                                                        onChange={props.setFieldValue}
                                                        valueEditor={
                                                            props.values.blocks[index].pattern.value
                                                        }
                                                        title={props.values.blocks[index].pattern.title}
                                                        withoutPictures={false}
                                                    />
                                                )}
                                            </div>
                                        );
                                    }
                                    ,
                                )}
                            </StyledMainWrapper>
                        )}
                    </FieldArray>
                    <StyledSubmitButtons>
                        <SubmitButtons
                            reset={() => handleResetForm(props.resetForm, props.values)}
                            submit={props.submitForm}
                        />
                    </StyledSubmitButtons>
                </StyledForm>
            )}
        </Formik>
    );
};
