import { request } from '../../helpers/api/request';

export const deleteSubCategory = async ({ categoryId, subCategoryId }: {categoryId: number, subCategoryId:number}) => {
    const { data } = await request({
        url: `admin/categories/${categoryId}/subcategories/${subCategoryId}`,
        method: 'DELETE',
    });

    return data;
};
