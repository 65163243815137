import { FlattenSimpleInterpolation, css } from 'styled-components';
import { TypesSelect } from '../../types/TypesSelect';
import { colors } from '../../constants/Colors';
import { SelectProps } from '../../types/Select/SelectProps';

const stylesSelect: Record<TypesSelect, FlattenSimpleInterpolation> = {
    [TypesSelect.TRANSPARENT]: css`
    background-color: transparent;
    border: none;
  `,
    [TypesSelect.DEFAULT]: css`
    background-color: ${colors.light};
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${colors.grey30};
  `,
    [TypesSelect.OUTLINE]: css`
    background-color: transparent;
    border-bottom: 1px solid ${colors.grey30};
  `,
};

const stylesOpenSelect: Record<TypesSelect, FlattenSimpleInterpolation> = {
    [TypesSelect.TRANSPARENT]: css`
    background-color: transparent;
    border: none;
  `,
    [TypesSelect.DEFAULT]: css`
    background-color: ${colors.white};
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${colors.grey30};
  `,
    [TypesSelect.OUTLINE]: css`
    background-color: transparent;
    border: 1px solid ${colors.grayscale05};
  `,
};

const styleReadonlySelect: Record<TypesSelect, FlattenSimpleInterpolation> = {
    [TypesSelect.TRANSPARENT]: css`
    background-color: transparent;
  `,
    [TypesSelect.DEFAULT]: css`
    background-color: ${colors.light};
  `,
    [TypesSelect.OUTLINE]: css`
    background-color: transparent;
    border: 1px solid ${colors.grayscale05};
  `,
};

export const applyStyleSelectByType = ({ selectType, readonly, opened }: Pick<SelectProps, 'selectType' | 'readonly' | 'opened'>) => {
    if (readonly) {
        return styleReadonlySelect[selectType];
    }

    if (opened) {
        return stylesOpenSelect[selectType];
    }

    return stylesSelect[selectType];
};
