import { useDispatch } from 'react-redux';
import { FieldArray, Formik } from 'formik';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import {
    selectGetSiteBlocksLoader,
    selectSiteBodyBlock,
} from '../../../../selectors/mainPage';
import {
    BannerItem,
    BannerItemForm,
    BlockItemEdit,
    WebBlockKey,
} from '../../../../types/Models/MainPage/MainPageModel';
import { useTypeSelector } from '../../../../store';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import {
    editBannerItemsWeb,
    editWebBanners,
} from '../../../../actions/mainPage';
import { siteBodyBreadcrumbs } from '../../../../constants/BreadCrumbs/siteBodyBreadcrumbs';
import { getShotPath } from '../../../../helpers/images/getPath';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { isEqualObject } from '../../../../helpers/isEqual';
import { addNotification } from '../../../../actions/notifications';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';
import { TypesIcon } from '../../../../types/TypesIcon';
import * as S from './styles';
import { validationSchema } from './schema';

export const BannerInstagramContent = (): JSX.Element => {
    const dispatch = useDispatch();

    const siteBodyBlock = useTypeSelector(
        selectSiteBodyBlock(WebBlockKey.WEB_INSTAGRAM),
    );

    const handleSubmit = (values: any) => {
        const bannerRequestData = {
            buttonText: values.buttonText || null,
            title: values.title || null,
            url: values.url || null,
        };

        const requestData = {
            bannerItems: values.bannerItems.map(
                (item: BannerItemForm, index: number) => ({
                    buttonText: item.buttonText || null,
                    title: item.title || null,
                    text: item.text || null,
                    url: item.url || null,
                    fileId: item.fileImage?.id || null,
                    status: item.status || 'ACTIVE',
                    order: item.order || index + 1,
                    endDate: item.endDate || null,
                    startDate: item.startDate || null,
                }),
            ),
        };

        dispatch(
            editBannerItemsWeb({
                key: WebBlockKey.WEB_INSTAGRAM,
                newData: requestData.bannerItems,
            }),
        );
        dispatch(
            editWebBanners({
                key: WebBlockKey.WEB_INSTAGRAM,
                newData: bannerRequestData,
            }),
        );
    };

    const items = siteBodyBlock?.bannerItems.map(item => ({
        ...item,
        fileImage: {
            id: item?.fileId || 0,
            path: item.fileURL ? getShotPath(item.fileURL) : '',
        },
    }));

    const initialValue: BlockItemEdit<WebBlockKey> = {
        buttonText: siteBodyBlock?.buttonText || '',
        title: siteBodyBlock?.title || '',
        text: siteBodyBlock?.text || '',
        url: siteBodyBlock?.url || '',
        name: siteBodyBlock?.name || '',
        key: WebBlockKey.WEB_INSTAGRAM,
        platform: 'WEB',
        type: 'SLIDER',
        bannerItems: items || [],
        bannerProducts: siteBodyBlock?.bannerProducts || [],
        fileURL: siteBodyBlock?.fileURL || '',
        id: siteBodyBlock?.id || 0,
        fileImage: {
            id: siteBodyBlock?.fileId || 0,
            path: siteBodyBlock?.fileURL ? getShotPath(siteBodyBlock.fileURL) : '',
        },
    };

    const handleResetForm = (calb: () => void, values: BlockItemEdit<WebBlockKey>) => {
        calb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>Body сайта</PageTitle>
                <Breadcrumbs breadcrumbs={siteBodyBreadcrumbs()} />
                <Formik<BlockItemEdit<WebBlockKey>>
                    initialValues={initialValue as unknown as BlockItemEdit<WebBlockKey>}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {props => (
                        <S.StyledForm onSubmit={handleSubmit}>
                            <S.FieldsWrapper>
                                <S.StyledInput
                                    name="title"
                                    onChange={props.handleChange}
                                    value={props.values?.title}
                                    title="Заголовок*"
                                    placeholder="Заголовок*"
                                    typeInput="text"
                                    maxLength={255}
                                    isTouched={props.touched?.title}
                                    error={props.errors?.title}
                                    count={props.submitCount}
                                />

                                <S.StyledInput
                                    name="buttonText"
                                    onChange={props.handleChange}
                                    value={props.values?.buttonText}
                                    title="Текст кнопки"
                                    placeholder="Текст кнопки"
                                    typeInput="text"
                                    maxLength={255}
                                />

                                <S.StyledInput
                                    name="url"
                                    onChange={props.handleChange}
                                    value={props.values?.url}
                                    title="URL*"
                                    placeholder="URL*"
                                    typeInput="text"
                                    isTouched={props.touched.url}
                                    error={props.errors.url}
                                    count={props.submitCount}
                                />
                            </S.FieldsWrapper>
                            <FieldArray name="bannerItems">
                                {() => (
                                    <S.MainWrapper>
                                        {props.values.bannerItems?.map(
                                            (_block: BannerItem, index: number) => (
                                                <S.Uploader
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`block${index}`}
                                                    name={`bannerItems[${index}].fileImage`}
                                                    onChange={props.setFieldValue}
                                                    value={props.values.bannerItems[index].fileImage}
                                                    maxSize={3}
                                                />
                                            ),
                                        )}
                                    </S.MainWrapper>
                                )}
                            </FieldArray>
                            <S.SubmitButtonsWrapper>
                                <SubmitButtons
                                    reset={() => handleResetForm(props.resetForm, props.values)}
                                    submit={props.submitForm}
                                />
                            </S.SubmitButtonsWrapper>
                        </S.StyledForm>
                    )}
                </Formik>
            </S.Wrapper>
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetSiteBlocksLoader,
});

export const BannerInstagramWithLoader = loader(BannerInstagramContent);
