import { request } from '../../helpers/api/request';
import { GetProductItemContract } from '../../types/Endpoints/goods/ProductContracts';
import {
    GetAllGoodsContract,
    GetPhotosGoodsContract,
    GetPropertiesContract,
    UpdateDisabledGoodsContract,
} from '../../types/Endpoints/goods/GoodsContracts';

export const getGoods = async (params: GetAllGoodsContract['pathParams']) => {
    const { data } = await request<GetAllGoodsContract>({
        url: 'admin/products',
        params,
    });

    return data;
};

export const updatePromotion = async (updateData: UpdateDisabledGoodsContract['requestBody']) => {
    await request<UpdateDisabledGoodsContract>({
        method: 'PATCH',
        url: 'admin/products/statuses',
        data: updateData,
    });

    return updateData;
};

export const getProduct = async (id: number) => {
    const { data } = await request<GetProductItemContract>({
        url: `admin/products/${id}`,
    });

    return data;
};

export const getGoodsPhotos = async (id: string) => {
    const { data } = await request<GetPhotosGoodsContract>({
        url: `admin/products/${id}/pictures`,
    });

    return data;
};

export const getProperties = async (id: string) => {
    const { data } = await request<GetPropertiesContract>({
        url: `admin/products/${id}/properties`,
    });

    return data;
};
