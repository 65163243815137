import { request } from '../../helpers/api/request';
import { GetPropertiesContract } from '../../types/Endpoints/properties/PropertiesContracts';

export const getProperties = async () => {
    const { data } = await request<GetPropertiesContract>({
        url: 'admin/properties',
    });

    return data;
};
