/* eslint-disable @typescript-eslint/no-shadow */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { FilterListItem } from '../../types/Endpoints/filters/FiltersContracts';
import { routes } from '../../constants/RouterPath';
import { EmptyRow } from '../EmptyRow';

interface TableProps {
  className?: string;
  filters?: FilterListItem[];
}

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
        '& .cell-phone': {
            maxWidth: '140px',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },

        '& .cell-services': {
            display: 'flex',
            padding: '16px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '8px',
            flex: '1 0 0',
            alignSelf: 'stretch',
        },
    },
};

const StyledEditButton = styled(EditButton)`
    margin: 0 auto;
`;

export const FiltersTable = ({ className, filters }: TableProps): JSX.Element => {
    const history = useHistory();

    return (
        <TableContainer className={className} component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell />
                        <TableCell align="left">Элемент из выгрузки</TableCell>
                        <TableCell align="left">Название пользовательское</TableCell>
                        <TableCell align="left" />
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={[styles.root, styles.body]}

                >
                    {!!filters?.length && filters.map(row => (
                        <TableRow
                            key={row.id}
                            sx={{ 'td, th': { border: 0, verticalAlign: 'top' } }}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                className="text-grey"
                                align="center"
                                sx={{ width: '5%' }}

                            >
                                {row?.order || ''}
                            </TableCell>
                            <TableCell align="left" className="text-grey" sx={{ width: '45%' }}>
                                {row?.property?.code || ''}
                            </TableCell>
                            <TableCell align="left" sx={{ width: '45%' }}>
                                {row?.name || ''}
                            </TableCell>
                            <TableCell sx={{ width: '5%' }}>
                                <StyledEditButton
                                    onClick={() => history.push(`${routes.edit_filter}/${row.id}`)}
                                    typeButton={TypesButton.SECONDARY}
                                    size={SizesButton.M}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                    {!filters?.length && <EmptyRow colSpan={4} />}

                </TableBody>
            </Table>
        </TableContainer>
    );
};
