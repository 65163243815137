import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ShopsWithLoader } from './loaders';
import { getShops } from '../../actions/shops/thunks';

export const Shops = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getShops());
    }, []);

    return <ShopsWithLoader />;
};
