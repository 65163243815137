export enum NamesFieldsFormEditPhotos {
  photos = 'photos',
}

export const MAX_PHOTOS = 10;

export const messages = {
    goods: 'Товары',
    mainPhoto: 'Заглавное фото товара',
    otherPhotos: 'Дополнительные фото товара',
};
