export interface City {
  id: number;
  name: string;
  region: string;
}

export enum RegionTypes {
  VITEBSK = 'VITEBSK',
  GOMEL = 'GOMEL',
  GRODNO = 'GRODNO',
  BREST = 'BREST',
  MINSK = 'MINSK',
  MOGILEV = 'MOGILEV',
}

export interface CityItem {
  id: number;
  name: string;
  region: string;
  latitude: number;
  longitude: number;
  isDefault: boolean;
}

export interface EditCityItem {
  name: string;
  region: string;
  latitude: number;
  longitude: number;
  isDefault: boolean;
}
