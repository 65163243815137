import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as pagesApi from '../../../api/dinamicPages';
import { DynamicPagesKeys } from '../../../types/Models/DinamicPages';

export const GET_PAGE = 'pages/getPage';

export const getPage = createAsyncThunk(
    GET_PAGE,
    createRequest({
        type: GET_PAGE,
        loader: async (key: DynamicPagesKeys) => pagesApi.getPage(key),
        showFailNotification: false,
    }),
);
