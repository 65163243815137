import { request } from '../../helpers/api/request';
import { CreateProductContract, GetProductItemContract } from '../../types/Endpoints/goods/ProductContracts';

const URL = 'admin/products';

export const createProduct = async (newProduct: CreateProductContract) => {
    const { data } = await request<GetProductItemContract>({
        method: 'POST',
        url: URL,
        data: newProduct,
    });

    return data;
};
