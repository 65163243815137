import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { Checkbox } from '../../../../components/Checkbox';
import { Input } from '../../../../components/Input';
import { colors } from '../../../../constants/Colors';
import { typography } from '../../../../helpers/styles/typography';
import { units } from '../../../../helpers/styles/units';
import { FontWeight } from '../../../../constants/Styles/fontWeight';
import { Button } from '../../../../components/Button';
import { TypesButton } from '../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../constants/SizeButton';
import { PageTitle } from '../../../../components/PageTitle';
import { categoryBreadcrumbs } from '../../../../constants/BreadCrumbs/categoryBreadcrumbs';
import { CreateCategoryItem } from '../../../../types/Endpoints/categories/CategoriesContracts';
import { createCatalogCategory } from '../../../../actions/category';
import { UploadPhotoWithMiniature } from '../../../../components/UploadPhotoWithMiniature';
import { UploadFile } from '../../../../types/Endpoints/files/FilesContract';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { isEqualObject } from '../../../../helpers/isEqual';
import { addNotification } from '../../../../actions/notifications';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';
import { TypesIcon } from '../../../../types/TypesIcon';
import { useTypeSelector } from '../../../../store';
import { selectCategories } from '../../../../selectors/catalog';
import { ExternalIdItem } from '../EditCategory/loaders';
import { DeleteButton } from '../../../../components/Button/deleteButton';
import { SelectOption } from '../../../../types/Select/SelectOption';
import { TypesSelect } from '../../../../types/TypesSelect';
import { Icon } from '../../../../components/Icon';
import { addModal } from '../../../../actions/modal';
import { getId } from '../../../../helpers/generateId';
import { TypesModal } from '../../../../types/TypesModalComponent';
import { Select } from '../../../../components/Select';
import { Textarea } from '../../../../components/TextArea';

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

const StyledOrderWrapper = styled.div`
  width: ${units(75)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledElementsBlockWrapper = styled(StyledMainWrapper)`
    gap: ${units(0)};
`;

const StyledImagesWrapper = styled(StyledMainWrapper)`
  gap: ${units(20)};
`;

const StyledIdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledInfo = styled(StyledIdInfo)`
    margin-bottom: ${units(8)};
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${units(20)};
  gap: ${units(20)};
`;

const StyledAddButton = styled(StyledButton)`
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0;
  gap: ${units(8)};

  font-weight: ${FontWeight.BOLD};
  border: none;
  &:hover {
    border: none;
  }
`;

const SubmitButtonsWrapper = styled.div`
  margin-left: -${units(12)};
`;

const StyledInpurWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${units(8)};
  margin-bottom: ${units(8)};

  &:last-of-type {
    margin-bottom: ${units(12)};

  }


& .delete-icon svg {
    filter: brightness(0) saturate(100%) invert(55%) sepia(0%) saturate(6441%) hue-rotate(168deg) brightness(105%) contrast(108%);
  }

  & .delete-icon:hover svg {
    filter: brightness(0) saturate(100%) invert(76%) sepia(51%) saturate(367%) hue-rotate(358deg) brightness(89%) contrast(88%);
  }  
`;

// const ExternalIdWrapper = styled.div`
//   padding: ${units(8)} 0;
//   margin-bottom: ${units(8)};
// `;

const StyledSelect = styled(Select)`
  background-color: ${colors.light};


  & .label {
    color: ${colors.greyDark};
  }

  :last-of-type {
    margin-bottom: ${units(0)};
  }

  :not(:last-child) {
    margin-bottom: ${units(0)};
  }
`;

const StyledDeleteItemButton = styled(DeleteButton)`
  padding: 0;
`;

// const StyledIdInput = styled(Input)`
//   &input[type="text] {
//     border-bottom-color: transparent !important;
//   }
//
// `;

interface CreateCatalogModel {
    id: number;
    seoH1: string;
    seoDescription: string;
    seoTitle: string;
    name: string;
    urn: string;
    sortNumber: number;
    relatedCategoryIds: ExternalIdItem[];
    isShowOnSite: boolean;
    isShowOnMobile: boolean;
    categoryIconFileId: UploadFile;
    categorySiteFileId: UploadFile;
}

export const CreateCategory = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const categories = useTypeSelector(selectCategories);

    const propertiesOptions = categories?.map(property => ({
        id: property.id,
        name: property.name,
    })) || [];

    const handleSubmit = (values: any) => {
        const relatedCategoryIdsArray = values.relatedCategoryIds.map((item: any) => item.name);

        const requestData: CreateCategoryItem = {
            seoH1: values.seoH1,
            seoDescription: values.seoDescription,
            seoTitle: values.seoTitle,
            name: values.name,
            urn: values.urn,
            sortNumber: Number(values?.sortNumber),
            relatedCategoryIds: relatedCategoryIdsArray,
            isShowOnSite: values.isShowOnSite,
            isShowOnMobile: values.isShowOnMobile,
            categoryIconFileId: values.categoryIconFileId?.id || null,
            categorySiteFileId: values.categorySiteFileId?.id || null,
        };

        dispatch(createCatalogCategory(requestData));
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required('Поле является обязательным'),
        urn: yup.string().required('Поле является обязательным'),
        relatedCategoryIds: yup.array().of(
            yup.object().shape({
                name: yup.string().required('Поле является обязательным'),
            }),
        ),
    });

    const initialValues = {
        isShowOnSite: true,
        isShowOnMobile: true,

    } as CreateCatalogModel;

    const handleResetForm = (calb: () => void, values: CreateCatalogModel) => {
        calb();
        const equal = isEqualObject(initialValues, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Категории</PageTitle>
                <Breadcrumbs breadcrumbs={categoryBreadcrumbs('Добавить')} />
                <Formik<CreateCatalogModel>
                    initialValues={initialValues as unknown as CreateCatalogModel}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {props => (
                        <StyledForm onSubmit={handleSubmit}>
                            <StyledMainWrapper>
                                <StyledIdInfo>SEO</StyledIdInfo>
                                <Textarea
                                    name="seoH1"
                                    onChange={props.handleChange}
                                    value={props.values.seoH1}
                                    placeholder="H1"
                                    maxLength={255}
                                    title="H1"
                                    isTouched={props.touched.seoH1}
                                    error={props.errors.seoH1}
                                />
                                <Textarea
                                    name="seoTitle"
                                    onChange={props.handleChange}
                                    value={props.values.seoTitle}
                                    placeholder="Title"
                                    maxLength={255}
                                    title="Title"
                                    isTouched={props.touched.seoTitle}
                                    error={props.errors.seoTitle}
                                />
                                <Textarea
                                    name="seoDescription"
                                    onChange={props.handleChange}
                                    value={props.values.seoDescription}
                                    placeholder="Description"
                                    maxLength={255}
                                    title="Description"
                                    isTouched={props.touched.seoDescription}
                                    error={props.errors.seoDescription}
                                />
                            </StyledMainWrapper>
                            <StyledMainWrapper>
                                <StyledIdInfo>Основное</StyledIdInfo>
                                <StyledInput
                                    name="name"
                                    onChange={props.handleChange}
                                    value={props.values.name}
                                    placeholder="Название пользовательское"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Название пользовательское"
                                    isTouched={props.touched.name}
                                    error={props.errors.name}
                                    count={props.submitCount}
                                />
                                <StyledInput
                                    name="id"
                                    onChange={props.handleChange}
                                    value={props.values.id}
                                    placeholder="ID"
                                    typeInput="text"
                                    maxLength={255}
                                    disabled
                                    title="ID"
                                    isTouched={props.touched.id}
                                    error={props.errors.id}
                                    count={props.submitCount}
                                />

                                <StyledInput
                                    name="urn"
                                    onChange={props.handleChange}
                                    value={props.values.urn}
                                    placeholder="URN"
                                    typeInput="text"
                                    maxLength={255}
                                    title="URN"
                                    isTouched={props.touched.urn}
                                    error={props.errors.urn}
                                    count={props.submitCount}
                                />

                                <StyledOrderWrapper>
                                    <StyledInput
                                        name="sortNumber"
                                        onChange={props.handleChange}
                                        value={props.values.sortNumber}
                                        placeholder="Сортировка"
                                        typeInput="number"
                                        title="Сортировка"
                                        isTouched={props.touched.sortNumber}
                                        error={props.errors.sortNumber}
                                    />

                                </StyledOrderWrapper>

                            </StyledMainWrapper>
                            <FieldArray name="relatedCategoryIds">
                                {({ push, remove }) => (
                                    <StyledElementsBlockWrapper>
                                        <StyledInfo>Элементы из выгрузки</StyledInfo>

                                        {props.values.relatedCategoryIds?.map(
                                            (_block: any, index: number) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <StyledInpurWrapper key={`block${index}`}>

                                                    <StyledSelect
                                                        name={`relatedCategoryIds[${index}].name`}
                                                        options={propertiesOptions as unknown as SelectOption[]}
                                                        selectType={TypesSelect.DEFAULT}
                                                        value={props.values?.relatedCategoryIds[index]?.name}
                                                        label="Элемент из выгрузки"
                                                        onClick={props.setFieldValue}
                                                        // title="Элемент из выгрузки"
                                                        isTouched={
                                                            props.touched?.relatedCategoryIds
                                                            && props.touched?.relatedCategoryIds[index]?.name
                                                        }
                                                        error={
                                                            props.errors?.relatedCategoryIds
                                                            && props.errors?.relatedCategoryIds[index]?.name
                                                        }

                                                    />

                                                    <StyledDeleteItemButton
                                                        typeButton={TypesButton.SECONDARY}
                                                        size={SizesButton.M}
                                                        className="delete-icon"
                                                        onClick={() => {
                                                            dispatch(
                                                                addModal({
                                                                    id: getId()(),
                                                                    type: TypesModal.CONFIRM,
                                                                    message: {
                                                                        description:
                                                                            'Вы действительно хотите удалить?',
                                                                    },
                                                                    onSuccessMessage: 'Удалить',
                                                                    onSuccess: () => remove(index),
                                                                    withCloseIcon: true,
                                                                }),
                                                            );
                                                        }}
                                                    />
                                                </StyledInpurWrapper>
                                            ),
                                        )}
                                        <StyledAddButton
                                            typeButton={TypesButton.SECONDARY}
                                            size={SizesButton.M}
                                            onClick={() => push({
                                                name: '',
                                                id: '',

                                            })}
                                        >
                                            <Icon
                                                type={TypesIcon.PLUS_BIG}
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                color="#000000"
                                            />{' '}
                                            Добавить эллемент из выгрузки
                                        </StyledAddButton>
                                    </StyledElementsBlockWrapper>
                                )}
                            </FieldArray>
                            <StyledMainWrapper>
                                <StyledIdInfo>Отображение</StyledIdInfo>
                                <Checkbox
                                    name="isShowOnSite"
                                    title="Отображение на сайте"
                                    checked={props.values.isShowOnSite}
                                    onChange={props.handleChange}
                                />

                                <Checkbox
                                    name="isShowOnMobile"
                                    title="Отображение в мобильном приложении"
                                    checked={props.values.isShowOnMobile}
                                    onChange={props.handleChange}
                                />
                            </StyledMainWrapper>

                            <StyledImagesWrapper>
                                <UploadPhotoWithMiniature
                                    name="categoryIconFileId"
                                    title="Иконка для фильтров"
                                    onChange={props.setFieldValue}
                                    value={props.values.categoryIconFileId}
                                    maxSize={0.003}
                                />
                                <UploadPhotoWithMiniature
                                    name="categorySiteFileId"
                                    title="Баннер в каталог на сайте"
                                    onChange={props.setFieldValue}
                                    value={props.values.categorySiteFileId}
                                    format="9:8"
                                    maxSize={1}
                                />
                            </StyledImagesWrapper>

                            <SubmitButtonsWrapper>
                                <SubmitButtons
                                    reset={() => handleResetForm(props.resetForm, props.values)}
                                    submit={props.submitForm}
                                />
                            </SubmitButtonsWrapper>
                        </StyledForm>
                    )}
                </Formik>
            </StyledWrapper>

        </StyledRootWrapper>
    );
};
