import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EditCategoryWithLoader } from './loaders';
import { getCatalogCategories, getCatalogCategory } from '../../../../actions/category';

export const EditSettings = () => {
    const dispatch = useDispatch();
    const { categoryId } = useParams<{categoryId: string}>();

    useEffect(() => {
        dispatch(getCatalogCategory(categoryId));
        dispatch(getCatalogCategories());
    }, [categoryId]);

    return <EditCategoryWithLoader />;
};
