import { createReducer } from '@reduxjs/toolkit';

import { SearchSetting, SearchSettingItem, SearchSettingLiked } from '../../types/Endpoints/SearchSettings/SearchSettingsContracts';
import { getSearchSettings, getSearchSettingsById, getSearchSettingsLiked } from '../../actions/landings';

interface SearchSettingState {
    searchSettings?: SearchSetting;
    searchLikedSettings?: SearchSettingLiked[];
    selectedSearchSettings?: SearchSettingItem;
}

export const searchSettings = createReducer<SearchSettingState>({}, builder => {
    builder.addCase(getSearchSettings.fulfilled, (state, { payload }) => ({
        ...state,
        searchSettings: payload,
    }));
    builder.addCase(getSearchSettingsById.fulfilled, (state, { payload }) => ({
        ...state,
        selectedSearchSettings: payload,
    }));
    builder.addCase(getSearchSettingsLiked.fulfilled, (state, { payload }) => ({
        ...state,
        searchLikedSettings: payload,
    }));
});
