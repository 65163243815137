import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DashBoardContentWithLoader } from './loaders';
import { getOrders } from '../../actions/orders/thunks';
import { getDateMinusOneDay, getCurrentDate } from '../../helpers/date/getDate';
import { DateFilter } from '../../constants/Date/Periods';
import { getNotes } from '../../actions/notes';

export const DashBoard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrders({ dateFrom: getDateMinusOneDay(DateFilter.WEEK), dateTo: getCurrentDate() }));
        dispatch(getNotes({ page: 1, size: 5 }));
    }, []);

    return <DashBoardContentWithLoader />;
};
