import { InputState } from '../../constants/InputState';
import { colors } from '../../constants/Colors';

const colorByType: Record<string, string> = {
    [InputState.IDLE]: colors.grayscale80,
    [InputState.ACTIVE]: colors.grayscale80,
    [InputState.WARNING]: colors.error,
};

export const applyColor = ({ error }: { error?: string }) => (
    error ? `color: ${colorByType[InputState.WARNING]}` : `color: ${colorByType[InputState.WARNING]}`
);
