import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import { UploadFile } from '../../../types/Endpoints/files/FilesContract';
import * as goodsApi from '../../../api/goods';

const UPDATE_PHOTOS_PRODUCT = 'goods/updatePhotosProduct';

interface IUpdatePhotosProductData {
  data: Required<UploadFile>[];
  id: string;
}

export const updatePhotosProduct = createAsyncThunk<Required<UploadFile>[], IUpdatePhotosProductData>(
    UPDATE_PHOTOS_PRODUCT,
    createRequest({
        type: UPDATE_PHOTOS_PRODUCT,
        loader: async ({ id, data }) => goodsApi.updatePhotosProduct(data, id),
    }),
);
