import { request } from '../../helpers/api/request';
import { EndpointContract } from '../../types/Endpoints/EndpointContract';
import { Shop } from '../../types/Models/Shops/shop';

export interface ShopsContract extends EndpointContract {
  responseBody: Shop[];
  pathParams: {
    address?: string;
    region?: string[];
    isActive?: boolean;
    addons?: string[];
    cityId?: string;
  }
}

export const getShops = async () => {
    const { data } = await request<ShopsContract>({
        url: 'shops/',
    });

    return data;
};
