import { request } from '../../helpers/api/request';
import { EndpointContract } from '../../types/Endpoints/EndpointContract';
import { CreateUserModel } from './addUser';

export interface UsersContract extends EndpointContract {
    responseBody: CreateUserModel[];
    /* TODO */
    /* another params */
    pathParams: {
      role?: string;
      status?: string;
      page: number;
      size: number;
    }
  }

export const getUsersWithParams = async (params: UsersContract['pathParams']) => {
    const { data } = await request<UsersContract>({
        url: 'admin/users',
        method: 'GET',
        params,
    });

    return data;
};
