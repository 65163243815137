import { request } from '../../helpers/api/request';
import { GetCatalogBrandContract } from '../../types/Endpoints/brands/BrandsContracts';

export const getBrand = async (id: string) => {
    const { data } = await request<GetCatalogBrandContract>({
        url: `admin/brands/${id}`,
    });

    return data;
};
