import { TypesCategories } from '../../pages/Goods/EditGood/MainGoods/constants';

export const getIdCategory = (
    typeDesiredCategory: keyof typeof TypesCategories,
    allCategories?: Array<{id: number; urn: string}>,
    productCategories?: Array<{category: {urn: string, categoryType: string}}>,
): number | undefined => {
    if (productCategories?.some(item => item.category.categoryType === typeDesiredCategory)) {
        const urn = productCategories.find(item => item.category.categoryType === typeDesiredCategory)?.category.urn;
        const id = allCategories?.find(item => item.urn === urn)?.id;
        return id;
    }
};
