import { createReducer } from '@reduxjs/toolkit';
import { Loader } from '../../types/Loaders/Loader';
import { LoaderState } from '../../types/Loaders/LoaderState';
import { createLoader } from '../../helpers/loaders/createLoader';
import { addLoader, updateLoader } from '../../actions/loaders';

type LoaderReducer = Record<string, Loader>;

const add = (state: LoaderReducer, loader: string) => ({
    ...state,
    [loader]: { ...createLoader(loader) },
});

const update = (state: LoaderReducer, { loaderName, loaderState }: { loaderName: string, loaderState: LoaderState }) => ({
    ...state,
    [loaderName]: {
        ...state[loaderName],
        state: loaderState,
    },
});

export const loaders = createReducer<LoaderReducer>({}, builder => {
    builder.addCase(addLoader, (state, { payload }) => add(state, payload.loader));
    builder.addCase(updateLoader, (state, { payload }) => update(state, payload));
});
