import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FilterCloudContent } from './loaders';
import { getCatalogCategories } from '../../actions/category/thunks';

export const FilterCloud = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCatalogCategories());
    }, []);

    return <FilterCloudContent />;
};
