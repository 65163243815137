import styled from 'styled-components';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import { Select } from '../../../components/Select';

export const RootWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

export const IdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

export const StyledButton = styled(Button)`
  width: 100%;

  font-weight: ${FontWeight.MEDIUM};
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${units(8)};

  padding: ${units(12)};

  max-width: ${units(334)};
`;

export const StyledSelect = styled(Select)`
  background-color: ${colors.light};
  height: ${units(28)};

  & .label {
    color: ${colors.greyDark};
  }

  :not(:last-child) {
    margin-bottom: ${units(3)};
  }
`;

export const InpurWrapper = styled.div`
  display: flex;
  gap: ${units(8)};
  align-items: flex-end;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteButton = styled(StyledButton)`
  width: ${units(102)};

  font-weight: ${FontWeight.MEDIUM};

  margin-top: ${units(10)};
`;

export const MapButton = styled(StyledButton)`
  height: ${units(28)};
  min-width: ${units(102)};
  max-width: ${units(102)};
  padding-top: ${units(12)};
`;
