import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    title: yup
        .string()
        .max(50, 'Не более 50 символов')
        .required('Поле является обязательным'),
    text: yup.string().max(40, 'Не более 40 символов'),
    url: yup.string().required('Поле является обязательным'),
});
