import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as propertiesApi from '../../../api/properties';
import { PropertiesListItem } from '../../../types/Endpoints/properties/PropertiesContracts';

export const GET_PROPERTIES = 'catalog/getProperties';

export const getProperties = createAsyncThunk<PropertiesListItem[]>(
    GET_PROPERTIES,
    createRequest({
        type: GET_PROPERTIES,
        loader: async () => propertiesApi.getProperties(),
        showFailNotification: false,
    }),
);
