import { request } from '../../helpers/api/request';
import { GetDocumentsContract } from '../../types/Endpoints/documents/DocumentsContracts';

export const getDocuments = async () => {
    const { data } = await request<GetDocumentsContract>({
        url: 'admin/documents',
    });

    return data;
};
