import { request } from '../../helpers/api/request';
import { GetDocumentsContract, UpdateDocumnets } from '../../types/Endpoints/documents/DocumentsContracts';

export const updateDocuments = async (requestData: UpdateDocumnets) => {
    const { data } = await request<GetDocumentsContract>({
        url: 'admin/documents',
        method: 'PUT',
        data: requestData,
    });

    return data;
};
