import { createAsyncThunk } from '@reduxjs/toolkit';
import { BannerItemForEdit, MobileBlockKey } from '../../../types/Models/MainPage/MainPageModel';
import { createRequest } from '../../../helpers/api/createRequest';
import * as mainPageApi from '../../../api/mainPage';
import { getMobileBanners } from './getMobileBanners';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const EDIT_BANNER_ITEMS_MOBILE = 'mainPage/editBannerItemsMobile';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(getMobileBanners());

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editBannerItemsMobile = createAsyncThunk(
    EDIT_BANNER_ITEMS_MOBILE,
    createRequest({
        type: EDIT_BANNER_ITEMS_MOBILE,
        loader: async (data: {key: MobileBlockKey, newData: BannerItemForEdit[]}) => mainPageApi.editBannerItems(data),
        onSuccess,
    }),
);
