import { useFormik } from 'formik';
import { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Input } from '../Input';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { setSearchData } from '../../actions/search/thunks/setSearchData';
import close from '../../styles/images/input/close.svg';

const StyledForm = styled.form`
  width: 100%;
`;

const StyledInput = styled(Input)`
  flex: 1 1 auto;  
  padding-left: ${units(20)};
  padding-top: ${units(3)};
  max-width: ${units(322)};    
  height: ${units(24)};

  ::-webkit-input-placeholder {
    color: ${colors.grey20};
  }
  :-ms-input-placeholder {
    color: ${colors.grey20};
  }

  &:hover {
    ::-webkit-input-placeholder {
      color: ${colors.greyDark} !important;
    }
    :-ms-input-placeholder {
      color: ${colors.greyDark} !important;
    }
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: ${units(12)};
    width: ${units(12)};
    
    background: url(${close}) no-repeat 50% 50%;
    background-size: contain;
    opacity: 1;    
  }
`;

export const SearchBar = ({ title = 'Поиск' }) => {
    const dispatch = useDispatch();

    const handleSubmit = useCallback(e => {
        e.preventDefault();
    }, []);

    const form = useFormik<{ search?: string }>({
        initialValues: {},
        onSubmit: handleSubmit,
    });

    return (
        <StyledForm>
            <StyledInput
                name="search"
                onChange={e => {
                    dispatch(setSearchData(e.target.value));
                    form.handleChange(e);
                }}
                value={form.values.search}
                placeholder={title}
                onBlur={form.handleBlur}
                typeInput="search"
                prefix={(
                    <Icon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        color={colors.greyDark}
                        type={TypesIcon.SEARCH}
                    />
                )}
            />
        </StyledForm>
    );
};
