import { Roles } from '../../constants/Roles';

export enum RolesTypes {
  ADMIN = 'Администратор',
  MARKETING = 'Маркетолог',
  ESHOP = 'ESHOP',
  USER = 'Пользователь',
}

export const getRole = (role: keyof typeof Roles) => RolesTypes[role];
