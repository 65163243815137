import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as notificationsApi from '../../../api/notification';
import { NotificationContract } from '../../../types/Endpoints/notification/NotificationContract';

export const GET_NOTIFICATIONS = 'notifications/getNotifications';

export const getNotifications = createAsyncThunk<NotificationContract['responseBody']>(
    GET_NOTIFICATIONS,
    createRequest({
        type: GET_NOTIFICATIONS,
        loader: () => notificationsApi.getNotifications(),
    }),
);
