import { createReducer } from '@reduxjs/toolkit';
import { getProductItemThink } from '../../actions/goods/thunks/getProductItem';
import { GetProductItemContract } from '../../types/Endpoints/goods/ProductContracts';
import { getPhotosThink } from '../../actions/goods/thunks/getPhotos.ts';
import { UploadFile } from '../../types/Endpoints/files/FilesContract.ts';
import { getPropertiesThink } from '../../actions/goods/thunks/properties.ts';
import {
    getGoodsThink,
    updateProduct,
    updatePhotosProduct,
    updateGoodsStatusesThink,
    updateProperties,
} from '../../actions/goods';
import {
    GetAllGoodsContract,
    PhotosGoodsContract,
    PropertyItemContract,
} from '../../types/Endpoints/goods/GoodsContracts';

export interface CollectionsModel {
  allGoods: GetAllGoodsContract['responseBody'] | null;
  productItem: GetProductItemContract['responseBody'] | null;
  photos: Required<UploadFile>[] | null;
  properties: PropertyItemContract[] | null;
}

export const goods = createReducer<CollectionsModel>({
    allGoods: null,
    productItem: null,
    photos: null,
    properties: null,
}, builder => {
    builder.addCase(getGoodsThink.fulfilled, (state, { payload }) => ({
        ...state,
        allGoods: payload,
    }));
    builder.addCase(getProductItemThink.fulfilled, (state, { payload }) => ({
        ...state,
        productItem: payload,
    }));
    builder.addCase(getPhotosThink.fulfilled, (state, { payload }) => ({
        ...state,
        photos: payload.map((item: PhotosGoodsContract) => ({ id: item.id, path: item.pictureURL })),
    }));
    builder.addCase(updatePhotosProduct.fulfilled, (state, { payload }) => ({
        ...state,
        photos: payload,
    }));
    builder.addCase(getPropertiesThink.fulfilled, (state, { payload }) => ({
        ...state,
        properties: payload,
    }));
    builder.addCase(updateProduct.fulfilled, (state, { payload }) => ({
        ...state,
        productItem: payload,
    }));
    builder.addCase(updateGoodsStatusesThink.fulfilled, (state, { payload }) => ({
        ...state,
        allGoods: !state.allGoods ? null : {
            ...state.allGoods,
            data: state.allGoods.data.map(good => {
                const changedGood = payload.find(item => item.id === good.id);

                if (changedGood) {
                    return {
                        ...good,
                        ...changedGood,
                    };
                }

                return good;
            }),
        },
    }));
    builder.addCase(updateProperties.fulfilled, (state, { payload }) => ({
        ...state,
        properties: payload,
    }));
});
