import styled from 'styled-components';
import { useState } from 'react';
import { units } from '../../helpers/styles/units';
import { Tabs } from '../../components/SiteBody/SiteBodyTabs';
import { BrestRegion } from './Brest';
import { VitebskRegion } from './Vitebsk';
import { GomelRegion } from './Gomel';
import { GrodnoRegion } from './Grodno';
import { MogilevRegion } from './Mogilev';
import { MinskRegion } from './Minsk';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

export enum PageStatus {
    MAIN = 'MAIN',
    EDIT = 'EDIT',
    ADD = 'ADD',
}

const tabDdata = [
    { name: 'Минская область', content: <MinskRegion /> },
    { name: 'Брестская область', content: <BrestRegion /> },
    { name: 'Витебская область', content: <VitebskRegion /> },
    { name: 'Гомельская область', content: <GomelRegion /> },
    { name: 'Гродненская область', content: <GrodnoRegion /> },
    { name: 'Могилевская область', content: <MogilevRegion /> },
];

export const HandbookContent = () => {
    const [active, setActive] = useState(0);

    return (
        <StyledWrapper>
            <Tabs data={tabDdata} active={active} setActive={setActive} />
        </StyledWrapper>
    );
};
