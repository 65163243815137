import { request } from '../../helpers/api/request';
import { EditFilterContract, FilterItem } from '../../types/Endpoints/filters/FiltersContracts';

export const editFilter = async (requestData: Partial<FilterItem>, filterId: string) => {
    const { data } = await request<EditFilterContract>({
        url: `admin/filters/${filterId}`,
        method: 'PATCH',
        data: requestData,
    });

    return data;
};
