import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Modals } from '../../components/Modals';
import { createLoadHoc } from '../../helpers/hocs/createLoadHoc';
import { selectUserLoaders } from '../../selectors/users';
import { Hero } from '../../components/Hero';
import { Snackbars } from '../../components/Snackbars';
import { SideBar } from '../../components/SideBar';
import { units } from '../../helpers/styles/units';
import { AuthInfo } from '../../components/AuthInfo';

const StyledWrapper = styled.div`
  display: flex;
  padding: ${units(20)} ${units(12)} 0 0;
  overflow-x: hidden;
`;

const User = ({ children }: PropsWithChildren<unknown>) => (
    <StyledWrapper>
        <AuthInfo />
        <SideBar />
        <Hero>
            {children}
        </Hero>
        <Modals />
        <Snackbars />
    </StyledWrapper>
);

const withAuth = createLoadHoc({
    selectLoader: selectUserLoaders,
});

export const UserContent = withAuth(User);
