import {
    useEffect, useState, Dispatch, SetStateAction,
} from 'react';

export const useDebounce = (
    ms: number,
    value: string,
): [string, Dispatch<SetStateAction<string>>] => {
    const [currentValue, setCurrentValue] = useState<string>(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCurrentValue(value);
        }, ms);

        return () => {
            clearTimeout(timeout);
        };
    }, [value, ms]);

    return [currentValue, setCurrentValue];
};
