import { request } from '../../helpers/api/request';
import { JewelryExchangesContract } from '../../types/Endpoints/jewelryExchanges/GetJawelryExchangesContract';

export const getJewelryExchanges = async () => {
    const { data } = await request<JewelryExchangesContract>({
        url: 'admin/jewelry-exchanges/',
    });

    return data;
};
