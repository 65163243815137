/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    FieldArray, Form, Formik,
} from 'formik';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Checkbox } from '../../../components/Checkbox';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { routes } from '../../../constants/RouterPath';
import { PageTitle } from '../../../components/PageTitle';
import { TypesIcon } from '../../../types/TypesIcon';
import { SubmitButtons } from '../../../components/StyledComponents';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';
import { addNotification } from '../../../actions/notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { filterCloudBreadcrumbs } from '../../../constants/BreadCrumbs/filterCloudBreadcrumbs';
import { selectGetCatalogCategoriesLoader } from '../../../selectors/catalog';

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
  padding-top: ${units(20)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const CheckboxWrapper = styled(StyledMainWrapper)`
  gap: ${units(8)};
`;

const StyledSubtitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};  

  ${typography(10)};
`;

interface FilterItem {
    id: number;
    name: string;
    options: {
        label: string;
        value: boolean;
    }[];
}

const filtersData: FilterItem[] = [
    {
        id: 1,
        name: 'Вид',
        options: [
            { label: 'Обручальные', value: false },
            { label: 'Помолвочные', value: true },
            { label: 'Модельные', value: false },
        ],
    },
    {
        id: 2,
        name: 'Металл',
        options: [
            { label: 'Белое золото', value: false },
            { label: 'Розовое золото', value: true },
            { label: 'Желтое золото', value: true },
            { label: 'Комбинированное золото', value: true },
            { label: 'Серебро', value: false },
        ],
    },
    {
        id: 3,
        name: 'Вставка',
        options: [
            { label: 'Бриллиант', value: false },
            { label: 'Сапфир', value: true },
            { label: 'Изумруд', value: true },
            { label: 'Жемчуг', value: true },
        ],
    },
];

export const EditFilterCloudLoader = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const countTrueValues = (array: FilterItem[]) => {
        let count = array.flatMap(item => item.options).filter(option => option.value === true).length;
        return count <= 10;
    };

    const handleSubmitForm = (values: {filters: FilterItem[]}) => {
        if (countTrueValues(values.filters)) {
            console.log('values', values);
        } else {
            dispatch(addNotification({
                type: TypesSnackbars.ERROR,
                message: {
                    title: 'Максимально можно выбрать 10 вариантов фильтрации',
                    description: '',
                },
                icon: TypesIcon.SNACK_ERROR,
            }));
        }
    };

    const handleResetForm = () => {
        history.push(routes.filter_cloud);
    };

    const data = {
        filters: filtersData.map(filter => ({
            ...filter,
            options: filter.options.map(option => ({
                ...option,
                value: option.value,
            })),
        })),
    };

    const initialValues: {filters: FilterItem[]} = data;

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Облако фильтров</PageTitle>
                <Breadcrumbs breadcrumbs={filterCloudBreadcrumbs('Кольца')} />

                <Formik<{filters: FilterItem[]}>
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                >
                    {({ values, handleSubmit, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                            <FieldArray name="filters">
                                {() => (
                                    <StyledRoot>
                                        <div>Максимально можно выбрать 10 вариантов фильтрации</div>
                                        {values.filters.map((filter, index) => (
                                            <CheckboxWrapper key={index}>
                                                <StyledSubtitle>{filter.name}</StyledSubtitle>
                                                {filter.options.map((option, optionIndex) => (
                                                    <Checkbox
                                                        key={optionIndex}
                                                        name={`filters.${index}.options.${optionIndex}.value`}
                                                        title={option.label}
                                                        checked={option.value}
                                                        onChange={(e: { target: { checked: boolean; }; }) => {
                                                            const newValues = [...values.filters];
                                                            newValues[index].options[optionIndex].value = e.target?.checked;
                                                            setFieldValue('filters', newValues);
                                                        }}
                                                    />
                                                ))}
                                            </CheckboxWrapper>
                                        ))}
                                    </StyledRoot>
                                )}
                            </FieldArray>
                            <SubmitButtons
                                reset={handleResetForm}
                                submit={() => handleSubmit()}
                            />
                        </Form>
                    )}
                </Formik>
            </StyledWrapper>
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetCatalogCategoriesLoader,
});

export const EditFilterCloudWithLoader = loader(EditFilterCloudLoader);
