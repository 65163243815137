import { createReducer } from '@reduxjs/toolkit';
import { CatalogBrandItem, CatalogBrands } from '../../types/Endpoints/brands/BrandsContracts';
import { getCatalogBrand, getCatalogBrands } from '../../actions/brands/thunks';

interface BrandState {
    brands?: CatalogBrands[];
    selectedBrand?: CatalogBrandItem;
}

export const brands = createReducer<BrandState>({}, builder => {
    builder.addCase(getCatalogBrands.fulfilled, (state, { payload }) => ({
        ...state,
        brands: payload,
    }));
    builder.addCase(getCatalogBrand.fulfilled, (state, { payload }) => ({
        ...state,
        selectedBrand: payload,
    }));
});
