import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';

const colorBgByType: Record<string, string> = {
    [TypesButton.SECONDARY]: colors.greyDark,
    [TypesButton.DELETE]: colors.red,
    [TypesButton.TEXT]: colors.greyDark,
};

export const applyBorderColor = ({ typeButton }: { typeButton: TypesButton }) => {
    if (typeButton === TypesButton.TEXT) {
        return `border-bottom: 1px solid ${colorBgByType[typeButton]}`;
    }

    return `border: 1px solid ${colorBgByType[typeButton]}`;
};
