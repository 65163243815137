import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { createGoodsBreadcrumbs } from '../../../../constants/BreadCrumbs/createGoodsBreadcrumbs';
import { routes } from '../../../../constants/RouterPath';
import { selectPhotos, selectPhotosLoader } from '../../../../selectors/goods';
import { useTypeSelector } from '../../../../store';
import { UploadPhotoWithMiniature } from '../../../../components/UploadPhotoWithMiniature';
import { updatePhotosProduct } from '../../../../actions/goods';
import { IFormValuesEditPhotosGoods } from './interface.ts';
import { NamesFieldsFormEditPhotos, MAX_PHOTOS, messages } from './constants.ts';
import {
    StyledRootWrapper,
    StyledWrapper,
    StyledRoot,
    CheckboxWrapper,
    StyledIdInfo,
    StyledSubmitButtons,
} from './styles.ts';

// FIXME: остается проблема с путями(подумать) и id(переделать реализацию на бэке, передавать не path а id)
export const PhotosGoodsContent: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { goodsId } = useParams<{goodsId: string}>();
    const [isUploading, setIsUploading] = useState<boolean[]>([]);
    const photos = useTypeSelector(selectPhotos);

    const handleSubmit = (values: IFormValuesEditPhotosGoods) => {
        dispatch(updatePhotosProduct({
            data: values[NamesFieldsFormEditPhotos.photos].filter(item => item.id),
            id: goodsId,
        }));
    };

    const form = useFormik<IFormValuesEditPhotosGoods>({
        onSubmit: handleSubmit,
        initialValues: {
            [NamesFieldsFormEditPhotos.photos]: [...(photos || [])],
        },
    });

    const beforeStartUploading = () => {
        setIsUploading(prev => [...prev, true]);
    };

    const afterEndUploading = () => {
        setIsUploading(prev => {
            const indexTrue = prev.findIndex(item => item === true);

            if (indexTrue !== -1) {
                return [...prev.slice(0, indexTrue), ...prev.slice(indexTrue + 1)];
            }

            return prev;
        });
    };

    useEffect(() => {
        const values = form.values[NamesFieldsFormEditPhotos.photos];

        if (values.some(item => !item.id && item.path === '')) {
            const filterValues = values.map(item => ({ ...item })).filter(item => item.id);
            form.setFieldValue(NamesFieldsFormEditPhotos.photos, filterValues, false);
        }

        if (values.length < MAX_PHOTOS && values.every(item => item.id)) {
            form.setFieldValue(NamesFieldsFormEditPhotos.photos, [...values, {}], false);
        }
    }, [form.values[NamesFieldsFormEditPhotos.photos]]);

    const renderPhotos = form.values[NamesFieldsFormEditPhotos.photos];

    // console.log('form.values', form.values);
    // console.log('photos', photos);
    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>{messages.goods}</PageTitle>
                <Breadcrumbs breadcrumbs={createGoodsBreadcrumbs()} />
                <StyledRoot>
                    <CheckboxWrapper>
                        <StyledIdInfo>{messages.mainPhoto}</StyledIdInfo>

                        <UploadPhotoWithMiniature
                            name={`${NamesFieldsFormEditPhotos.photos}[${0}]`}
                            format="7:3"
                            onChange={form.setFieldValue}
                            value={renderPhotos[0]}
                            beforeUpload={beforeStartUploading}
                            afterUpload={afterEndUploading}
                        />
                    </CheckboxWrapper>

                    {renderPhotos.length > 1 && (
                        <CheckboxWrapper>
                            <StyledIdInfo>{messages.otherPhotos}</StyledIdInfo>

                            {renderPhotos.slice(1).map((item, i) => (
                                <UploadPhotoWithMiniature
                                    key={item.id || i}
                                    name={`${NamesFieldsFormEditPhotos.photos}[${i + 1}]`}
                                    format="7:3"
                                    onChange={form.setFieldValue}
                                    value={item}
                                    beforeUpload={beforeStartUploading}
                                    afterUpload={afterEndUploading}
                                />
                            ))}
                        </CheckboxWrapper>
                    )}
                </StyledRoot>
            </StyledWrapper>

            <StyledSubmitButtons
                reset={() => history.push(routes.goods)}
                submit={form.submitForm}
                isDisabledSubmit={isUploading.some(value => value)}
            />
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectPhotosLoader,
});

export const PhotosGoodsWithLoader = loader(PhotosGoodsContent);
