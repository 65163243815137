export enum TypesModal {
    MAP = 'MAP',
    CONFIRM = 'CONFIRM',
    NOTIFICATION = 'NOTIFICATION',
    CHOOSE_ADDRESS = 'CHOOSE_ADDRESS',
    IMAGE = 'IMAGE',
    CREATE_TAG = 'CREATE_TAG',
    CHOOSE_CITY = 'CHOOSE_CITY',
    ADD_SEARCH_ITEM = 'ADD_SEARCH_ITEM',
}
