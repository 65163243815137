import { useDispatch } from 'react-redux';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import {
    selectGetSiteBlocksLoader,
    selectSiteBodyBlock,
} from '../../../../selectors/mainPage';
import {
    BannerItemForm,
    BlockItemEdit,
    WebBlockKey,
} from '../../../../types/Models/MainPage/MainPageModel';
import { useTypeSelector } from '../../../../store';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { editBannerItemsWeb } from '../../../../actions/mainPage';
import { siteBodyBreadcrumbs } from '../../../../constants/BreadCrumbs/siteBodyBreadcrumbs';
import { getShotPath } from '../../../../helpers/images/getPath';
import { UploadFile } from '../../../../types/Endpoints/files/FilesContract';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { isEqualObject } from '../../../../helpers/isEqual';
import { addNotification } from '../../../../actions/notifications';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';
import { TypesIcon } from '../../../../types/TypesIcon';
import * as S from './styles';

interface CategoryBanner {
    id: number;
    key: string;
    name: string;
    platform: 'WEB';
    title: string | null;
    type: string;
    url: string | null;
    fileId: number | null;
    fileURL: string | null;
    fileImage: UploadFile;
    bannerItems: BannerItemForm[];
}

export const CategoriesBannerContent = (): JSX.Element => {
    const dispatch = useDispatch();

    const siteBodyBlock = useTypeSelector(
        selectSiteBodyBlock(WebBlockKey.WEB_CATEGORIES),
    );

    const handleSubmit = (props: any) => {
        const requestData = {
            bannerItems: props.bannerItems?.map(
                (item: BannerItemForm, index: number) => ({
                    buttonText: item.buttonText || null,
                    title: item.title || null,
                    text: item.text || null,
                    url: item.url || null,
                    fileId: item.fileImage?.id || null,
                    status: item.status || 'ACTIVE',
                    order: index + 1,
                    endDate: item.endDate || null,
                    startDate: item.startDate || null,
                    mobileFileId: item.mobileFileImage?.id,
                }),
            ),
        };

        dispatch(
            editBannerItemsWeb({
                key: WebBlockKey.WEB_CATEGORIES,
                newData: requestData.bannerItems,
            }),
        );
    };

    const items = siteBodyBlock?.bannerItems.map(item => ({
        ...item,
        fileImage: {
            id: item?.fileId || 0,
            path: item.fileURL ? getShotPath(item.fileURL) : '',
        },
        mobileFileImage: {
            id: item?.mobileFileId || 0,
            path: item?.mobileFileURL ? getShotPath(item.mobileFileURL) : '',
        },
    }));

    const initialValue: BlockItemEdit<WebBlockKey> = {
        buttonText: siteBodyBlock?.buttonText || '',
        title: siteBodyBlock?.title || '',
        text: siteBodyBlock?.text || '',
        url: siteBodyBlock?.url || '',
        name: siteBodyBlock?.name || '',
        key: WebBlockKey.WEB_CATEGORIES,
        platform: 'WEB',
        type: siteBodyBlock?.type || 'TILE',
        bannerItems: items || [],
        bannerProducts: siteBodyBlock?.bannerProducts || [],
        fileURL: siteBodyBlock?.fileURL || '',
        id: siteBodyBlock?.id || 0,
        fileImage: {
            id: siteBodyBlock?.fileId || 0,
            path: siteBodyBlock?.fileURL ? getShotPath(siteBodyBlock.fileURL) : '',
        },
        mobileFileImage: {
            id: siteBodyBlock?.mobileFileId || 0,
            path: siteBodyBlock?.mobileFileURL
                ? getShotPath(siteBodyBlock.mobileFileURL)
                : '',
        },
    };

    const validationSchema = yup.object().shape({
        bannerItems: yup.array().of(
            yup.object().shape({
                title: yup.string().required('Поле является обязательным'),
                url: yup.string().required('Поле является обязательным'),
            }),
        ),
    });

    const handleResetForm = (calb: () => void, values: CategoryBanner) => {
        calb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>Body сайта</PageTitle>
                <Breadcrumbs breadcrumbs={siteBodyBreadcrumbs()} />
                <Formik<CategoryBanner>
                    initialValues={initialValue as unknown as CategoryBanner}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {props => (
                        <S.StyledForm onSubmit={handleSubmit}>
                            <FieldArray name="bannerItems">
                                {() => (
                                    <S.MainWrapper>
                                        {props.values.bannerItems?.map(
                                            (_block: BannerItemForm, index: number) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <S.Div key={`block${index}`}>
                                                    <S.InpurWrapper>
                                                        <S.StyledInput
                                                            name={`bannerItems[${index}].title`}
                                                            onChange={props.handleChange}
                                                            value={props.values?.bannerItems[index]?.title}
                                                            title="Заголовок*"
                                                            placeholder="Заголовок*"
                                                            typeInput="text"
                                                            maxLength={255}
                                                            isTouched={
                                                                props.touched?.bannerItems
                                                                && props.touched?.bannerItems[index]?.title
                                                            }
                                                            error={
                                                                props.errors?.bannerItems
                                                                && props.errors?.bannerItems[index]?.title
                                                            }
                                                            count={props.submitCount}
                                                        />
                                                        <S.StyledInput
                                                            name={`bannerItems[${index}].url`}
                                                            onChange={props.handleChange}
                                                            value={props.values?.bannerItems[index]?.url}
                                                            title="URL*"
                                                            placeholder="URL*"
                                                            typeInput="text"
                                                            isTouched={
                                                                props.touched?.bannerItems
                                                                && props.touched?.bannerItems[index]?.url
                                                            }
                                                            error={
                                                                props.errors?.bannerItems
                                                                && props.errors?.bannerItems[index]?.url
                                                            }
                                                            count={props.submitCount}
                                                        />
                                                    </S.InpurWrapper>

                                                    <S.Uploader
                                                        name={`bannerItems[${index}].fileImage`}
                                                        title="Изображение для desktop версии сайта"
                                                        onChange={props.setFieldValue}
                                                        value={props.values.bannerItems[index].fileImage}
                                                        format={props.values?.bannerItems[index]?.title === 'Обручальные кольца' ? '1:1' : '2:1'}
                                                        maxSize={3}
                                                    />
                                                    <S.Uploader
                                                        name={`bannerItems[${index}].mobileFileImage`}
                                                        title="Изображение для мобильной версии сайта"
                                                        onChange={props.setFieldValue}
                                                        value={
                                                            props.values.bannerItems[index].mobileFileImage
                                                        }
                                                        format={props.values?.bannerItems[index]?.title === 'Обручальные кольца' ? '2.5:1' : '1.2:1'}
                                                        maxSize={2}
                                                    />
                                                </S.Div>
                                            ),
                                        )}
                                    </S.MainWrapper>
                                )}
                            </FieldArray>
                            <S.ButtonsWrapper>
                                <SubmitButtons
                                    reset={() => handleResetForm(props.resetForm, props.values)}
                                    submit={props.submitForm}
                                />
                            </S.ButtonsWrapper>
                        </S.StyledForm>
                    )}
                </Formik>
            </S.Wrapper>
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetSiteBlocksLoader,
});

export const CategoriesBannerWithLoader = loader(CategoriesBannerContent);
