import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { OrderListContent } from './loaders';
import { getOrdersStatus } from '../../actions/orders';

export const OrderList = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrdersStatus());
    }, []);

    return <OrderListContent />;
};
