import { routes } from '../RouterPath';
import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const editShopBreadcrumbs = () : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Магазины',
        url: routes.shops,
    },
    {
        key: '2',
        value: 'Редактировать',
        url: '',
    },
]);
