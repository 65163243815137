import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { Modal } from '../../types/Models/Modal/Modal';
import { removeModal } from '../../actions/modal';
import { Title } from '../Title';
import { TypesIcon } from '../../types/TypesIcon';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Button } from '../Button';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { IconBig } from '../Icon/IconBig';
import { Input } from '../Input';
import { createTag } from '../../api/blog';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: ${units(236)};
  padding: ${units(27)};
  gap: 24px;

  max-height: 95vh;

  background-color: ${colors.light};

  border: none;
  border-radius: ${units(0)};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIcon = styled(IconBig)`
  cursor: pointer;
  transition: stroke .4s ease;

  :hover {
    stroke: ${colors.grayscale80};
  }
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

`;

const StyledButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: ${units(8)};
  
  padding: 0;  
`;

const StyledMainButton = styled(Button)`
  font-weight: ${FontWeight.MEDIUM};
  width: 100%;
`;

const StyledTitle = styled(Title)`  
  font-weight: ${FontWeight.MEDIUM};

  ${typography(20)};
`;

const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
      hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
      hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

export const CreateTagModal = ({
    id,
    onClose,
    onSuccess,
    onSuccessMessage,
}: Modal) => {
    const dispatch = useDispatch();
    const [newTag, setNewTag] = useState<string>('');

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    const addTagButtonHandler = async () => {
        await createTag({ title: newTag });
        setNewTag('');
        // eslint-disable-next-line no-unused-expressions
        onSuccess && onSuccess();
        handleClose();
    };

    return (
        <StyledRoot>
            <StyledTitleWrapper>
                <StyledTitle>
                    Добавить тег
                </StyledTitle>
                <StyledCloseButton>
                    <StyledIcon
                        onClick={handleClose}
                        type={TypesIcon.CLOSE_BIG}
                        color={colors.grey20}
                        height="24px"
                        width="24px"
                        viewBox="0 0 24 24"
                    />
                </StyledCloseButton>
            </StyledTitleWrapper>

            <StyledInput
                name="newTag"
                value={newTag}
                onChange={e => setNewTag(e.target.value)}
                placeholder="Название тега"
                typeInput="text"
                maxLength={255}
                title="Название тега"
            />

            <StyledButtonsWrapper>
                <StyledMainButton
                    onClick={handleClose}
                    typeButton={TypesButton.SECONDARY}
                    size={SizesButton.M}
                >
                    Отменить
                </StyledMainButton>
                <StyledMainButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.M}
                    onClick={addTagButtonHandler}
                >
                    {onSuccessMessage}
                </StyledMainButton>
            </StyledButtonsWrapper>
        </StyledRoot>
    );
};
