import { useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { PriceOffersWithLoader } from './loaders';
import { getProductItemThink } from '../../../../actions/goods/thunks/getProductItem';
import { useTypeSelector } from '../../../../store';
import { selectProductItem } from '../../../../selectors/goods';
import { getShops } from '../../../../actions/shops/thunks/getShops';
import { selectShops } from '../../../../selectors/shops';

export const PriceOffers: FC = () => {
    const dispatch = useDispatch();
    const productItem = useTypeSelector(selectProductItem);
    const shops = useTypeSelector(selectShops);
    const { goodsId } = useParams<{goodsId: string}>();

    useEffect(() => {
        if (!productItem || Number(goodsId) !== productItem.id) {
            dispatch(getProductItemThink(Number(goodsId)));
        }

        if (!shops) {
            dispatch(getShops());
        }
    }, []);

    return <PriceOffersWithLoader />;
};
