/* eslint-disable react/no-array-index-key */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { units } from '../../helpers/styles/units';
import { routes } from '../../constants/RouterPath';
import { TypesIcon } from '../../types/TypesIcon';
import { Icon } from '../Icon';
import { colors } from '../../constants/Colors';
import { getFormatDate } from '../../helpers/date/getDate';
import { phoneCustomize } from '../../helpers/phone';
import { EmptyRow } from '../EmptyRow';
import { selectOrdersLoader } from '../../selectors/orders';
import { LoaderState } from '../../types/Loaders/LoaderState';
import { Preloader } from '../Preloader';
import { OrderStatus, OrderStatusClasses } from '../../types/Orders';
import { Order } from '../../types/Endpoints/orders/OrdersContracts';

interface TableProps {
  className?: string;
  orders?: Order[];
  isDashboard?: boolean;
}

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
        '& .cell-phone': {
            maxWidth: '140px',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
            maxWidth: '164px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },

        '& .cell-services': {
            display: 'flex',
            padding: '16px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '8px',
            flex: '1 0 0',
            alignSelf: 'stretch',
        },
    },
};

const StyledCell = styled(TableCell)`

.status-chip {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};

    background-color: #3938360D;

    & div {
        background-color: #393836;
    }
}
  .${OrderStatusClasses[OrderStatus.DONE]} {
    background-color: rgba(87, 198, 118, 0.05);

    & div {
      background-color: #6db180;
    }
  }

  .${OrderStatusClasses[OrderStatus.REJECT]} {
    background-color: rgba(231, 72, 72, 0.05);

    & div {
      background-color: #e74848;
    }
  }

  .${OrderStatusClasses[OrderStatus.NEW]} {
    background-color: rgba(215, 180, 106, 0.05);

    & div {
      background-color: ${colors.gold};
    }
  }
    
  .${OrderStatusClasses[OrderStatus.PROCESSED]} {
    background-color: rgba(88, 126, 198, 0.05);

    & div {
      background-color: ${colors.blue};
    }
  }
`;

const StyledIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledStatusInfo = styled.div`
  width: ${units(4)};
  height: ${units(4)};
  display: flex;
  flex-shrink: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
  width: ${units(42)};

  gap: ${units(4)};
`;

const StyledDiv = styled(ContentWrapper)`
  width: ${units(78)};
`;

const Status = (key: OrderStatus) => (
    // eslint-disable-next-line react/destructuring-assignment
    <StyledDiv className={`${OrderStatusClasses[key]} status-chip`}>
        <StyledStatusInfo />
        <span>{key}</span>
    </StyledDiv>
);

export const OrderListTable = ({ className, orders, isDashboard }: TableProps): JSX.Element => {
    const history = useHistory();
    const ordersLoader = useSelector(selectOrdersLoader);

    const fullDate = orders?.slice().sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    const sortedOrders = isDashboard
        ? fullDate?.slice(0, 10)
        : fullDate;

    if (ordersLoader?.state === LoaderState.PENDING) {
        return <Preloader />;
    }
    return (
        <TableContainer className={className} component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Номер</TableCell>
                        <TableCell align="left">Дата и время</TableCell>
                        <TableCell align="left">
                            Фамилия, Имя
                        </TableCell>
                        <TableCell align="left">
                            Телефон
                        </TableCell>
                        <TableCell align="left">Статус</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={[styles.root, styles.body]}>
                    {!!orders?.length && !!sortedOrders?.length && sortedOrders.map(row => (
                        <TableRow key={row.id} sx={{ 'td, th': { border: 0, verticalAlign: 'top' } }}>
                            <TableCell component="th" scope="row" className="text-grey">
                                {row.id}
                            </TableCell>
                            <TableCell align="left" className="text-grey">
                                {row.zikoOrderId}
                            </TableCell>
                            <TableCell align="left">{getFormatDate(row.createdAt)}</TableCell>
                            <TableCell align="left" className="cell-medium">
                                {row.client}
                            </TableCell>
                            <TableCell align="left">
                                {row?.clientPhone ? phoneCustomize(row.clientPhone) : ''}
                            </TableCell>
                            <StyledCell align="left" sx={{ width: '10%' }}>
                                {row?.status && Status(row.status as OrderStatus) }
                            </StyledCell>
                            <TableCell sx={{ width: '5%' }}>
                                <StyledIconWrapper>
                                    <Icon
                                        type={TypesIcon.DOTS}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        onClick={() => history.push(`${routes.edit_order}/${row.id}`)}
                                    />
                                </StyledIconWrapper>
                            </TableCell>
                        </TableRow>
                    ))}
                    {orders && orders.length === 0 && <EmptyRow colSpan={6} />}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
