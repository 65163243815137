import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { PageTitle } from '../../components/PageTitle';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { Button } from '../../components/Button';
import { colors } from '../../constants/Colors';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
  height: calc(100vh - 40px);
`;

export const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
  margin-bottom: ${units(23)};
`;

export const TextArea = styled.textarea`
  width: min(644px, 100%);
  min-height: 100%;
  resize: none;
  border: 1px solid #C7C7C7;
  background: none;
  outline: none;
  overflow: auto;
  padding: ${units(8)} ${units(12)};

  &:hover: {
    border: 2px solid #FF0000;
  }

    &:focus: {
    border: 2px solid #0FF00F,
    outline: "none"
  }

  ${typography(10)}
  `;

export const Div = styled.div`
  flex: 1;
`;

export const ButtonWrapper = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${units(8)};

  padding: ${units(12)};
  padding-right: 0;
  margin-left: -${units(12)};

  max-width: ${units(334)};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: calc(100vw - 224px);
    height: 1px;
    background-color: ${colors.grey40};
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
