import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_CERTIFICATES } from '../../actions/certificates/thunks';

const selectRoot = ({ certificates }: RootState) => certificates;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetCertificatesLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_CERTIFICATES],
);

export const selectCertificates = createSelector(
    selectRoot,
    ({ certificates }) => certificates,
);
