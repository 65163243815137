import { request } from '../../helpers/api/request';
import { GetCatalogCollectionsContract } from '../../types/Endpoints/collections/GetCollectionsContract';

export const getCollection = async (id: string) => {
    const { data } = await request<GetCatalogCollectionsContract>({
        url: `admin/collections/${id}`,
    });

    return data;
};
