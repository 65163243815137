import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { SignupSchema } from './SignupSchema';
import { getNativeEvent } from '../../../helpers/event';
import { KeyCodes } from '../../../constants/KeyCodes';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { KeyboardEvents } from '../../../components/KeyboardEvents';
import { Button } from '../../../components/Button';
import { units } from '../../../helpers/styles/units';
import { Input } from '../../../components/Input';
import { colors } from '../../../constants/Colors';
import { LoginValues } from './index';
import { login } from '../../../actions/auth';
import { LoginContractBody } from '../../../types/Endpoints/users';
import { PasswordInput } from '../../../components/PasswordInput';
import { typography } from '../../../helpers/styles/typography';

interface LoginFormProps extends LoginValues {
  username: string;
  password: string;
}

enum ErrorCodes {
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
`;

const StyledInput = styled(Input)`
  background-color: ${colors.white};
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${units(10)};
`;

const StyledLink = styled.div`
  color: ${colors.grey10};
  ${typography(10)};

  text-decoration: none;
`;

const StyledErrorLink = styled(StyledLink)`
  color: ${colors.red};

  ${typography(10)};
`;

export const LoginForm = () => {
    const dispatch = useDispatch();

    const [error, setError] = useState({ statusCode: null });

    const handleSubmit = useCallback(async (values: LoginFormProps) => {
        const loginData = await dispatch(
            login({
                login: values.username,
                password: values.password,
            } as LoginContractBody),
        );

        const data = (await loginData) as any;

        const { statusCode } = data.payload;
        setError({ statusCode });
    }, []);

    const form = useFormik<LoginFormProps>({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: handleSubmit,
        validationSchema: SignupSchema(),
        validateOnBlur: true,
    });

    const isCanLogin = form.values.username && form.values.password;

    const handleKeyDown = useCallback(
        (event: any) => {
            const { values, isValid } = form;

            if (
                isCanLogin
                && isValid
                && getNativeEvent(event).code === KeyCodes.ENTER
            ) {
                handleSubmit(values);
            }
        },
        [form, handleSubmit],
    );

    const hangleResetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.handleChange(e);
        setError({ statusCode: null });
    };

    return (
        <form>
            <StyledFormWrapper>
                <StyledInput
                    name="username"
                    onChange={hangleResetError}
                    value={form.values.username}
                    placeholder="Логин"
                    title="Логин"
                    error={
                        error.statusCode === ErrorCodes.FORBIDDEN
                        || error.statusCode === ErrorCodes.NOT_FOUND
                    }
                    onBlur={form.handleBlur}
                    isTouched={form.touched.username}
                />
                <PasswordInput
                    name="password"
                    placeholder="Пароль"
                    title="Пароль"
                    onChange={hangleResetError}
                    value={form.values.password}
                    error={
                        error.statusCode === ErrorCodes.FORBIDDEN
                        || error.statusCode === ErrorCodes.NOT_FOUND
                    }
                    onBlur={form.handleBlur}
                    isTouched={form.touched.password}
                />

                {error.statusCode !== ErrorCodes.FORBIDDEN && (
                    <StyledLink>Забыли пароль? Обратитесь к администратору</StyledLink>
                )}

                {error.statusCode === ErrorCodes.FORBIDDEN && (
                    <StyledErrorLink>
                        Ваши доступы были удалены, обратитесь к администратору для
                        предоставления доступа
                    </StyledErrorLink>
                )}
            </StyledFormWrapper>
            <StyledButton
                typeButton={TypesButton.PRIMARY}
                size={SizesButton.M}
                onClick={form.submitForm}
                disabled={!isCanLogin}
            >
                Войти
            </StyledButton>
            <KeyboardEvents onKeyDown={handleKeyDown} />
        </form>
    );
};
