import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EditSaleLoaderWithLoader } from './loaders';
import { getPromotionThink } from '../../../actions/sales/thunks/getPromotion';

export const EditSales = () => {
    const dispatch = useDispatch();
    const { saleId } = useParams<{saleId: string}>();

    useEffect(() => {
        dispatch(getPromotionThink(saleId));
    }, []);

    return <EditSaleLoaderWithLoader />;
};
