/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-shadow */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { routes } from '../../constants/RouterPath';
import { CatalogCategories } from '../../types/Endpoints/categories/CategoriesContracts';
import { EmptyRow } from '../EmptyRow';

interface TableProps {
    categories?: CatalogCategories[];
  }

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
            maxWidth: '224px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
    },
};

const StyledEditButton = styled(EditButton)`
  margin: 0 auto;
`;

export const MenuTable = ({ categories }: TableProps): JSX.Element => {
    const history = useHistory();
    const dispatch = useDispatch();
    const parseDate = (date: string) => new Date(date).toLocaleDateString().replace(/\//gi, '.');

    return (
        <TableContainer component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >

                        <TableCell>ID</TableCell>
                        <TableCell align="left">Название пользовательское</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={[styles.root, styles.body]}
                >
                    {categories
                  && categories.map(row => (
                      <TableRow
                          key={row.id}
                          sx={{ 'td, th': { border: 0 } }}
                      >
                          <TableCell
                              component="th"
                              scope="row"
                              className="text-grey"
                              sx={{ width: '25%' }}
                          >
                              <span>{row.id}</span>
                          </TableCell>
                          <TableCell align="left" sx={{ width: '25%' }}>
                              {' '}
                              {row.name}
                          </TableCell>
                          <TableCell align="left" sx={{ width: '5%' }}>
                              <StyledEditButton
                                  typeButton={TypesButton.SECONDARY}
                                  size={SizesButton.M}
                                  onClick={() => history.push(`${routes.edit_menu}/${row.id}`)}
                              />
                          </TableCell>
                      </TableRow>
                  ))}
                    {categories && categories.length === 0 && <EmptyRow colSpan={3} />}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
