/* eslint-disable no-plusplus */
/* eslint-disable max-classes-per-file */
import { IInsertWeight, IWeight } from '../../../../types/Endpoints/goods/ProductContracts';
import { IModifyDataForForm } from './interface';
import { SALT } from './constants';

const createId = (): number => {
    let id = 0;

    for (let i = 0; i < 10; i++) {
        id += Math.floor(Math.random() * 100);
    }

    return id;
};

class InsertWeight implements IInsertWeight {
    id = NaN;

    insert = '';

    price = 0;

    discountPrice = 0;

    discountPriceDC = 0;

    percentDiscount = 0;

    percentDiscountDC = 0;

    amount = 0;

    amountMCode = 0;

    insertShop = [];
}

class Weight implements IWeight {
    id = Number(createId() + SALT);

    weight = 0;

    insert = [new InsertWeight()];
}

export class Size implements IModifyDataForForm {
    id = NaN;

    size = 0;

    weight = new Weight();
}
