import { routes } from '../RouterPath';
import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const orderItemBreadcrumbs = () : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Заказы',
        url: '',
    },
    {
        key: '2',
        value: 'Список заказов',
        url: routes.order_list,
    },
    {
        key: '3',
        value: 'Подробнее',
        url: '',
    },
]);
