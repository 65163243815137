import { useMemo } from 'react';
import { SelectOption } from '../types/Select/SelectOption';

export const useActiveOption = (options: SelectOption[] | undefined, activeOption: unknown, trackName?: boolean) => (
    useMemo(() => options?.find(({ id, name }) => (
        trackName
            ? String(name) === String(activeOption)
            : String(id) === String(activeOption)
    )), [trackName, activeOption, options])
);
