import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as brandsApi from '../../../api/brands';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';

export const DELETE_CATALOG_BRAND = 'brands/deleteBrand';

const onSuccess = (): Thunk => () => {
    history.push(routes.brands);
};

export const deleteCatalogBrand = createAsyncThunk(
    DELETE_CATALOG_BRAND,
    createRequest({
        type: DELETE_CATALOG_BRAND,
        loader: async (id: number) => brandsApi.deleteBrand(id),
        onSuccess,
    }),
);
