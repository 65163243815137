import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { Modal } from '../../types/Models/Modal/Modal';
import { removeModal } from '../../actions/modal';
import { Title } from '../Title';
import { TypesIcon } from '../../types/TypesIcon';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Button } from '../Button';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { IconBig } from '../Icon/IconBig';
import { Input } from '../Input';
import { addSearchSettings, setSearchSettings } from '../../actions/landings/thunks';
import { SearchSettingItem } from '../../types/Endpoints/SearchSettings/SearchSettingsContracts';
import { Textarea } from '../TextArea';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: ${units(382)};
  padding: ${units(27)};
  padding-right:  ${units(17)};
  gap: ${units(12)};

  max-height: 95vh;

  background-color: ${colors.light};

  border: none;
  border-radius: ${units(0)};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${units(10)};
`;

const StyledIcon = styled(IconBig)`
  cursor: pointer;
  transition: stroke .4s ease;

  :hover {
    stroke: ${colors.grayscale80};
  }
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

`;

const StyledButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: ${units(8)};
  
  padding: 0; 
  margin-right: ${units(10)}; 
`;

const StyledMainButton = styled(Button)`
  font-weight: ${FontWeight.MEDIUM};
  width: 100%;
`;

const StyledTitle = styled(Title)`  
  font-weight: ${FontWeight.MEDIUM};

  ${typography(20)};
`;

const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
      hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
      hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

const StyledSubtitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
  padding-right: ${units(10)};
  max-height: 496px;
  overflow: hidden;
  overflow-y: scroll;


  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grey40};
    border-radius: .25rem;
  }
`;

export const AddSearchItemModal = ({
    id,
    onClose,
    onSuccess,
    message,
    onSuccessMessage,
    data,
}: Modal) => {
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    const handleSubmit = async (values: SearchSettingItem) => {
        if (data?.id) {
            const req = {
                order: +values.order, url: values.url, string: values.string, id: data.id, urlMobile: values.urlMobile, // seoH1: values.seoH1, seoTitle: values.seoTitle, seoDescription: values.seoDescription,
            };
            await dispatch(setSearchSettings(req));
        } else {
            const req = {
                order: +values.order, url: values.url, string: values.string, urlMobile: values.urlMobile, // seoH1: values.seoH1, seoTitle: values.seoTitle, seoDescription: values.seoDescription,
            };
            await dispatch(addSearchSettings(req));
        }
        // eslint-disable-next-line no-unused-expressions
        onSuccess && onSuccess();
        handleClose();
    };

    const urlMobileData = () => {
        if (!data?.urlMobile) return 'zikoby://catalog/';
        return data?.urlMobile.includes('zikoby://catalog/') ? data?.urlMobile : `zikoby://catalog/${data?.urlMobile}`;
    };

    const validationSchema = yup.object().shape({
        order: yup.string().required('Поле является обязательным'),
        string: yup.string().required('Поле является обязательным'),
        url: yup.string().required('Поле является обязательным'),
        urlMobile: yup.string().required('Поле является обязательным'),
    });

    const initialValue: SearchSettingItem = {
        id: data?.id || 0,
        order: data?.order || 500,
        string: data?.string || '',
        url: data?.url || '',
        urlMobile: urlMobileData(),
        seoH1: data?.seoH1 || '',
        seoTitle: data?.seoTitle || '',
        seoDescription: data?.seoDescription || '',
    };

    const form = useFormik<SearchSettingItem>({
        initialValues: initialValue,
        onSubmit: handleSubmit,
        validationSchema,
    });

    const handleChangeUrlMobile = (e: { target: { value: string; }; }) => {
        const urlData = e.target.value.split('catalog/');

        if (urlData && urlData[1]) {
            form.setFieldValue('urlMobile', `zikoby://catalog/${urlData[1]}`);
        } else {
            form.setFieldValue('urlMobile', 'zikoby://catalog/');
        }
    };

    return (
        <StyledRoot>
            <StyledTitleWrapper>
                <StyledTitle>
                    {message?.description}
                </StyledTitle>
                <StyledCloseButton>
                    <StyledIcon
                        onClick={handleClose}
                        type={TypesIcon.CLOSE_BIG}
                        color={colors.grey20}
                        height="24px"
                        width="24px"
                        viewBox="0 0 24 24"
                    />
                </StyledCloseButton>
            </StyledTitleWrapper>

            <ContentWrapper>

                <StyledSubtitle>Основное</StyledSubtitle>

                <StyledInput
                    name="order"
                    value={form.values.order}
                    onChange={form.handleChange}
                    placeholder="Сортировка*"
                    typeInput="text"
                    maxLength={255}
                    title="Сортировка*"
                    isTouched={form.touched.order}
                    error={form.errors.order}
                    count={form.submitCount}
                />

                <StyledInput
                    name="url"
                    value={form.values.url}
                    onChange={form.handleChange}
                    placeholder="URL для сайта*"
                    typeInput="text"
                    title="URL для сайта*"
                    isTouched={form.touched.url}
                    error={form.errors.url}
                    count={form.submitCount}
                />

                <StyledInput
                    name="urlMobile"
                    value={form.values.urlMobile}
                    onChange={handleChangeUrlMobile}
                    placeholder="URL для мобильного приложения*"
                    typeInput="text"
                    title="URL для мобильного приложения*"
                    isTouched={form.touched.urlMobile}
                    error={form.errors.urlMobile}
                    count={form.submitCount}
                />

                <StyledInput
                    name="string"
                    value={form.values.string}
                    onChange={form.handleChange}
                    placeholder="Название пользовательское*"
                    typeInput="text"
                    maxLength={255}
                    title="Название пользовательское*"
                    isTouched={form.touched.string}
                    error={form.errors.string}
                    count={form.submitCount}
                />

                <StyledSubtitle>SEO</StyledSubtitle>
                <Textarea
                    name="seoH1"
                    value={form.values.seoH1}
                    onChange={form.handleChange}
                    placeholder="H1"
                    maxLength={255}
                    title="H1"
                    isTouched={form.touched.seoH1}
                    error={form.errors.seoH1}
                />
                <Textarea
                    name="seoTitle"
                    value={form.values.seoTitle}
                    onChange={form.handleChange}
                    placeholder="Title"
                    maxLength={255}
                    title="Title"
                    isTouched={form.touched.seoTitle}
                    error={form.errors.seoTitle}
                />
                <Textarea
                    name="seoDescription"
                    value={form.values.seoDescription}
                    onChange={form.handleChange}
                    placeholder="Description"
                    maxLength={255}
                    title="Description"
                    isTouched={form.touched.seoDescription}
                    error={form.errors.seoDescription}
                />
            </ContentWrapper>

            <StyledButtonsWrapper>
                <StyledMainButton
                    onClick={handleClose}
                    typeButton={TypesButton.SECONDARY}
                    size={SizesButton.M}
                >
                    Отменить
                </StyledMainButton>
                <StyledMainButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.M}
                    onClick={form.submitForm}
                >
                    {onSuccessMessage}
                </StyledMainButton>
            </StyledButtonsWrapper>
        </StyledRoot>
    );
};
