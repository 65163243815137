import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as searchSettingsApi from '../../../api/landings';
import { Thunk } from '../../../helpers/redux/Thunk';
import { getSearchSettings } from './getLandings';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { AddSearchSettingModel } from '../../../types/Endpoints/SearchSettings/SearchSettingsContracts';

export const ADD_SEARCH_SETTINGS = 'searchSettings/addSearchSettings';
const onSuccess = (): Thunk => async dispatch => {
    dispatch(getSearchSettings({ page: 1, size: 50 }));

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};
export const addSearchSettings = createAsyncThunk(
    ADD_SEARCH_SETTINGS,
    createRequest({
        type: ADD_SEARCH_SETTINGS,
        loader: async (requestData: AddSearchSettingModel) => searchSettingsApi.addSearchSettings(requestData),
        onSuccess,
    }),
);
