import { request } from '../../helpers/api/request';
import { ShopsContract } from './getShops';

export const getShopsWithParams = async (params: ShopsContract['pathParams']) => {
    const { data } = await request<ShopsContract>({
        url: 'shops/',
        method: 'GET',
        params,
    });

    return data;
};
