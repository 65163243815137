import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrandsgWithLoader } from './loaders';
import { getCatalogBrands } from '../../../actions/brands/thunks';

export const Brands = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCatalogBrands());
    }, []);

    return <BrandsgWithLoader />;
};
