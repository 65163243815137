import { SubCategoriesItem } from '../../types/Endpoints/categories/CategoriesContracts';

export const getEmptySubcategory = (length = 0) => ({
    name: `Подкатегория ${length ? length + 1 : ''}`,
    status: 'ACTIVE',
    isMain: false,
    sortOrder: length ? length + 1 : 1,
    subcategories: [
        {
            name: '',
            udl: '',
            mobileUrl: '',
            sortOrder: 1,
            bannerFileId: null,
            catalogBannerFileId: null,
            catalogBannerLogoFileId: null,
            catalogBannerTitle: '',
            bannerFileURL: '',
            catalogBannerFileURL: '',
            catalogBannerLogoFileURL: '',
        },
    ],
} as unknown as SubCategoriesItem);
