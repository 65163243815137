import styled from 'styled-components';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { units } from '../../../../helpers/styles/units';
import { Input } from '../../../../components/Input';
import { colors } from '../../../../constants/Colors';
import { FontWeight } from '../../../../constants/Styles/fontWeight.ts';
import { typography } from '../../../../helpers/styles/typography.ts';

export const StyledRootWrapper = styled.div`
  position: relative;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};
  padding-top: ${units(20)};
`;

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

export const CheckboxWrapper = styled(StyledMainWrapper)`
  gap: ${units(8)};
`;

export const SelectsWrapper = styled(StyledMainWrapper)`
  gap: ${units(12)};
`;

export const StyledIdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
`;

export const StyledSubmitButtons = styled(SubmitButtons)`
  left: ${units(124)};
`;
