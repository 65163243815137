import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { useDispatch } from 'react-redux';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { CalculatorPurchaseGold } from '../CalculatorModal/CalculatorPurchaseGold';
import { getCalculatorAll } from '../../actions/calculator/thunks';
import { selectCalculatorExchange, selectCalculatorPurchase } from '../../selectors/calculator';
import { useTypeSelector } from '../../store';
import { Calculators } from '../../api/calculator/getCalculator';

export interface ChildrenProps {
  initialValue?: object;
  className?: string;
  onClose?: () => void;
  title?: string;
  modalData: Calculators | null;
}

/* enum ChildrenDrawer {
  PURCHASE_GOLD ='PURCHASE_GOLD',
  PURCHASE_PUREGOLD = 'PURCHASE_PUREGOLD',
  PURCHASE_DENTURES = 'PURCHASE_DENTURES',
  EXCHANGE_GOLD = 'EXCHANGE_GOLD',
  EXCHANGE_PUREGOLD = 'EXCHANGE_PUREGOLD',
  EXCHANGE_SILVER_CERT = 'EXCHANGE_SILVER_CERT',
  EXCHANGE_SILVER = 'EXCHANGE_SILVER',
  EXCHANGE_PURESILVER = 'EXCHANGE_PURESILVER',
} */

/* const childrenTitles = {
    PURCHASE_GOLD: 'Золото в изделиях и ломе (скупка)',
    PURCHASE_PUREGOLD: '1 грамм металла в чистоте (скупка) ',
    PURCHASE_DENTURES: 'Зубные протезы, элементы зубных протезов (скупка)',
    EXCHANGE_GOLD: 'Золото в изделиях и ломе (обмен)',
    EXCHANGE_PUREGOLD: 'Цена золота в чистоте (обмен)',
    EXCHANGE_SILVER_CERT: 'Серебро в изделиях и ломе при реализации сертификата (обмен)',
    EXCHANGE_SILVER: 'Серебро в изделиях и ломе (обмен)',
    EXCHANGE_PURESILVER: 'Цена серебра в чистоте (обмен)',
}; */

/* const modalChildren: Record<string, React.ComponentType<ChildrenProps>> = {
    [ChildrenDrawer.PURCHASE_GOLD]: CalculatorPurchaseGold,
    [ChildrenDrawer.PURCHASE_PUREGOLD]: CalculatorPurchaseGold,
    [ChildrenDrawer.PURCHASE_DENTURES]: CalculatorPurchaseGold,
    [ChildrenDrawer.EXCHANGE_GOLD]: CalculatorPurchaseGold,
    [ChildrenDrawer.EXCHANGE_PUREGOLD]: CalculatorPurchaseGold,
    [ChildrenDrawer.EXCHANGE_SILVER_CERT]: CalculatorPurchaseGold,
    [ChildrenDrawer.EXCHANGE_SILVER]: CalculatorPurchaseGold,
    [ChildrenDrawer.EXCHANGE_PURESILVER]: CalculatorPurchaseGold,
}; */

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${units(12)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${units(30)};
`;

const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: ${units(337)};
    box-shadow: none;

    background-color: ${colors.light}
  }

  & .MuiBackdrop-root.MuiModal-backdrop {
    background: ${colors.grey10};
    opacity: 0.4 !important;
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  letter-spacing: 0.7px;

  ${typography(10)};

  cursor: pointer;
`;

const StyledSectionTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 1px;

  color: ${colors.greyDark};

  ${typography(15)};
`;

export const EditCalculator = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState(false);
    const [modalData, setModalData] = useState<Calculators | null>();

    const toggleDrawer = (open: boolean, data: Calculators| null = null) => () => {
        setState(open);
        setModalData(data);
    };

    const calculatorPurchase = useTypeSelector(selectCalculatorPurchase);
    const calculatorExchange = useTypeSelector(selectCalculatorExchange);

    /* const ChildDrawer = modalChildren[drawerChild]; */

    useEffect(() => {
        dispatch(getCalculatorAll());
    }, []);

    return (
        <StyledWrapper>
            <StyledMainWrapper>
                <StyledSectionTitle>Скупка</StyledSectionTitle>
                {calculatorPurchase && calculatorPurchase.map(item => (
                    <StyledItem onClick={toggleDrawer(true, item)} key={item.id}>
                        {item.name}
                        <Icon
                            type={TypesIcon.ARROW_RIGHT_BIG}
                            color={colors.greyDark}
                            width="25px"
                            height="25px"
                            viewBox="0 0 25 25"
                        />
                    </StyledItem>
                ))}

            </StyledMainWrapper>
            <StyledMainWrapper>
                <StyledSectionTitle>Обмен</StyledSectionTitle>
                {calculatorExchange && calculatorExchange.map(item => (
                    <StyledItem onClick={toggleDrawer(true, item)} key={item.id}>
                        {item.name}
                        <Icon
                            type={TypesIcon.ARROW_RIGHT_BIG}
                            color={colors.greyDark}
                            width="25px"
                            height="25px"
                            viewBox="0 0 25 25"
                        />
                    </StyledItem>
                ))}

            </StyledMainWrapper>

            <StyledDrawer
                anchor="right"
                open={state}
                onClose={toggleDrawer(false)}
            >
                {state && (
                    <CalculatorPurchaseGold
                        onClose={toggleDrawer(false)}
                        modalData={modalData || null}
                    />
                )}

            </StyledDrawer>
        </StyledWrapper>
    );
};
