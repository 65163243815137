import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SettingWithLoader } from './loaders';
import { getCatalogCategories } from '../../../actions/category';

export const Settings = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCatalogCategories());
    }, []);

    return <SettingWithLoader />;
};
