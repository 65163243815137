import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as ordersApi from '../../../api/orders';

export const GET_ORDER_STATUS = 'orders/getOrderStatus';

export const getOrdersStatus = createAsyncThunk<string[]>(
    GET_ORDER_STATUS,
    createRequest({
        type: GET_ORDER_STATUS,
        loader: async () => ordersApi.getOrdersStatus(),
    }),
);
