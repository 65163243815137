import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as shopsApi from '../../../api/shops';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';
import { EditShopItem } from '../../../types/Endpoints/shops/ShopsContracts';

export const EDIT_SHOP = 'shops/editShops';

const onSuccess = (): Thunk => () => {
    history.push(routes.shops);
};

export const editShop = createAsyncThunk(
    EDIT_SHOP,
    createRequest({
        type: EDIT_SHOP,
        loader: async (requestData: EditShopItem) => shopsApi.editShop(requestData),
        onSuccess,
    }),
);
