import { useState } from 'react';
import { Tabs } from '../../../components/SiteBody/SiteBodyTabs';
import * as S from '../styles';
import { TAB_DATA } from './constants';

export const PaymentAndDeliveryContent = () => {
    const [active, setActive] = useState(0);

    return (
        <S.Wrapper>
            <Tabs data={TAB_DATA} active={active} setActive={setActive} />
        </S.Wrapper>
    );
};
