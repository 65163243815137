import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { createLoadHoc } from '../../helpers/hocs/createLoadHoc';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { useTypeSelector } from '../../store';
import { selectGetRobotsLoader, selectRobots } from '../../selectors/robots';
import { editRobots } from '../../actions/robots';
import { addNotification } from '../../actions/notifications';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { TypesIcon } from '../../types/TypesIcon';
import * as S from './styles';
import { PageTitles } from '../../constants/PageTitles';

export const RobotsContent = () => {
    const dispatch = useDispatch();
    const robots = useTypeSelector(selectRobots);
    const handleSubmit = async (values: any) => {
        const res = await dispatch(editRobots(values)) as any;
        if (res && res.error) {
            let errorMessage;
            if (res?.payload && res.payload?.data) {
                const { message } = res.payload.data;
                errorMessage = message.includes('500 KB') ? 'Превышен лимит в 500 КБ' : 'Ошибка';
            } else {
                errorMessage = 'Ошибка';
            }

            dispatch(addNotification({
                type: TypesSnackbars.ERROR,
                message: {
                    title: errorMessage,
                    description: '',
                },
                icon: TypesIcon.SNACK_ERROR,
            }));
        }
    };

    const initialValue = robots || { content: '' };

    const form = useFormik<any>({
        initialValues: initialValue,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    return (
        <S.Wrapper>
            <S.StyledPageTitle>{PageTitles.ROBOTS}</S.StyledPageTitle>

            <S.Root>
                <S.Div>
                    <S.TextArea
                        name="content"
                        value={form.values.content}
                        onChange={form.handleChange}
                    />
                </S.Div>
                <S.ButtonWrapper>
                    <S.StyledButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={() => form.resetForm()}
                    >
                        Отменить
                    </S.StyledButton>
                    <S.StyledButton
                        typeButton={TypesButton.PRIMARY}
                        size={SizesButton.M}
                        onClick={form.submitForm}
                    >
                        Сохранить изменения
                    </S.StyledButton>
                </S.ButtonWrapper>
            </S.Root>
        </S.Wrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetRobotsLoader,
});

export const RobotsWithLoader = loader(RobotsContent);
