import { request } from '../../helpers/api/request';
import { GetCitiesRegionContract } from '../../types/Endpoints/cities/GetCitiesContract';

export const getCitiesByRegion = async (id: string) => {
    const { data } = await request<GetCitiesRegionContract>({
        url: `cities?region=${id}`,
    });

    return data;
};
