import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as blogApi from '../../../api/blog';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';

export const CREATE_BLOG = 'blog/createBlog';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Новость удалена успешно',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));

    history.push(routes.blog);
};

export const deleteBlogThunk = createAsyncThunk(
    CREATE_BLOG,
    createRequest({
        type: CREATE_BLOG,
        loader: async (id: string) => blogApi.deleteBlog(id),
        onSuccess,
    }),
);
