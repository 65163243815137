import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { AddProductsPromotions } from '../AddProductsToPromotions';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { GetPromotionContract } from '../../types/Endpoints/sales/PromitionsContracts';

const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: ${units(337)};
    padding: ${units(27)};
    box-shadow: none;
  }

  & .MuiBackdrop-root.MuiModal-backdrop {
    background: ${colors.grey10};
    opacity: 0.4 !important;
  }
`;

const StyledHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${units(8)};
`;

const StyledModalTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 1px;

  ${typography(20)};
`;

const StyledModalDescription = styled.div`
  letter-spacing: 1px;
  color: #939393;
  margin-top: ${units(8)};
  ${typography(14)};
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${units(8)};
  padding: ${units(12)} ${units(27)};
  width: ${units(324)};

  background-color: ${colors.light};
  border-top: 1px solid ${colors.grey40};

  position: fixed;
  bottom: 0;
  right: 25px;
  z-index: 1000;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
      hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
      hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

const StyledDrawerRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ComponentProps {
    isFirstModalOpen: boolean,
    offDrawer: () => void,
    saveAndOffDrawer: () => void,
    selectedProductsInModal: GetPromotionContract['responseBody']['products'],
    setSelectedProductsInModal: (newProducts: GetPromotionContract['responseBody']['products']) => void,
}

export const AddProductsDrawer = ({
    isFirstModalOpen,
    offDrawer,
    saveAndOffDrawer,
    selectedProductsInModal,
    setSelectedProductsInModal,
}: ComponentProps) => (
    <StyledDrawer
        anchor="right"
        open={isFirstModalOpen}
        onClose={offDrawer}
    >
        <StyledDrawerRoot>
            <StyledHeading>
                <StyledModalTitle>Добавить товары</StyledModalTitle>
                <StyledCloseButton onClick={offDrawer}>
                    <Icon
                        type={TypesIcon.CLOSE_BIG}
                        color={colors.grey20}
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                    />
                </StyledCloseButton>
            </StyledHeading>
            <StyledModalDescription>{`Добавлено ${selectedProductsInModal.length} товаров`}</StyledModalDescription>
            <AddProductsPromotions
                selectedProducts={selectedProductsInModal}
                setSelectedProducts={setSelectedProductsInModal}
            />

            <ButtonWrapper>
                <StyledButton
                    typeButton={TypesButton.SECONDARY}
                    size={SizesButton.M}
                    onClick={offDrawer}
                >
                    Отменить
                </StyledButton>
                <StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.M}
                    onClick={saveAndOffDrawer}
                >
                    Сохранить изменения
                </StyledButton>
            </ButtonWrapper>
        </StyledDrawerRoot>
    </StyledDrawer>
);
