import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../../components/PageTitle';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { InstallmentAndCreditBreadcrumbs } from '../../../constants/BreadCrumbs/installmentAndCreditBreadcrumbs';
import { useTypeSelector } from '../../../store';
import { selectDinamicPage } from '../../../selectors/dinamicPages';
import { DynamicPagesKeys } from '../../../types/Models/DinamicPages';
import { getPage } from '../../../actions/dinamicPages';
import { DynamicPageForm } from '../../../components/DynamicPageForm';
import { updateCreditPage } from '../../../actions/dinamicPages/thunks/updateCreditPage';
import * as S from '../styles';
import { PageTitles } from '../../../constants/PageTitles';

const FORMATS_INSTALLMENT_CREDIT = [
    {
        format: '9:2',
        index: 3,
    },
    {
        format: '4:3',
        index: 6,
    },
    {
        format: '4:3',
        index: 8,
    },
];

export const InstallmentAndCredit = () => {
    const dispatch = useDispatch();
    const dinamicPage = useTypeSelector(selectDinamicPage);
    const [state, setState] = useState(false);

    useLayoutEffect(() => {
        dispatch(getPage(DynamicPagesKeys.INSTALLMENT_AND_CREDIT));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setState(true);
        }, 300);
    });

    if (!state) {
        return <S.StyledPreloader />;
    }

    return (
        <S.Wrapper>
            <PageTitle>{PageTitles.INSTALLMENT_AND_CREDIT}</PageTitle>
            <Breadcrumbs breadcrumbs={InstallmentAndCreditBreadcrumbs()} />
            <S.Root>
                {dinamicPage ? (
                    <DynamicPageForm
                        pagesData={dinamicPage}
                        pageKey={DynamicPagesKeys.INSTALLMENT_AND_CREDIT}
                        updatePage={updateCreditPage}
                        formats={FORMATS_INSTALLMENT_CREDIT}
                    />
                ) : null}
            </S.Root>
        </S.Wrapper>
    );
};
