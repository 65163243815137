import { createReducer } from '@reduxjs/toolkit';

import { CertificatesItem } from '../../types/Endpoints/certificates/CertificatesContracts';
import { getCertificates } from '../../actions/certificates/thunks';

interface CertificatesState {
    certificates?: CertificatesItem[];
}

export const certificates = createReducer<CertificatesState>({}, builder => {
    builder.addCase(getCertificates.fulfilled, (state, { payload }) => ({
        ...state,
        certificates: payload,
    }));
});
