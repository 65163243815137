import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import * as usersApi from '../../../api/users';
import { loadSystemData } from './loadSystemData';
import { createRequest } from '../../../helpers/api/createRequest';
import { setSessionToStorage } from '../../../helpers/auth/session';
import { LoginContractBody } from '../../../types/Endpoints/users';
import { getDataFromStorage } from '../../../helpers/storage/getDataFromStorage';
import { setDataToStorage } from '../../../helpers/storage/setDataToStorage';

export const LOGIN = 'auth/login';
export const FINGER_PRINT = 'fingerprint';

export const login = createAsyncThunk(
    LOGIN,
    createRequest({
        type: LOGIN,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        loader: async ({ login, password }: LoginContractBody) => {
            let fingerprint = getDataFromStorage(FINGER_PRINT);
            if (!fingerprint) {
                fingerprint = uuidv4();
                setDataToStorage(FINGER_PRINT, fingerprint);
            }

            const session = await usersApi.login({
                login,
                password,
                fingerprint,
            });

            setSessionToStorage(session);
        },
        onSuccess: () => loadSystemData(),
        showFailNotification: false,
    }),
);
