import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MenuWithLoader } from './loaders';
import { getCatalogCategories } from '../../../actions/category/thunks';

export const Menu = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCatalogCategories());
    }, []);

    return <MenuWithLoader />;
};
