export enum DateFilter {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    QUARTER = 'quarter',
  }

export const dateFilters = [
    { name: 'День', id: DateFilter.DAY },
    { name: 'Неделя', id: DateFilter.WEEK },
    { name: 'Месяц', id: DateFilter.MONTH },
    { name: 'Квартал', id: DateFilter.QUARTER },
];
