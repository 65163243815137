import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { MinskRegionWithLoader } from './loaders';
import { getCitiesByRegion } from '../../../actions/city/thunks';
import { RegionTypes } from '../../../types/Models/City/City';

export const MinskRegion = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCitiesByRegion(RegionTypes.MINSK));
    }, []);

    return <MinskRegionWithLoader />;
};
