import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as filtersApi from '../../../api/filters';
import { GetFiltersContract } from '../../../types/Endpoints/filters/FiltersContracts';

export const GET_FILTERS = 'catalog/getFilters';

export const getFilters = createAsyncThunk(
    GET_FILTERS,
    createRequest({
        type: GET_FILTERS,
        loader: async (params: GetFiltersContract['pathParams']) => filtersApi.getFilters(params),
    }),
);
