import { request } from '../../helpers/api/request';
import { OrdersContract } from '../../types/Endpoints/orders/OrdersContracts';

export const getOrders = async (params: OrdersContract['pathParams']) => {
    const { data } = await request<OrdersContract>({
        url: 'admin/orders/',
        method: 'GET',
        params,
    });

    return data;
};
