import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { PageTitle } from '../../components/PageTitle';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../store';
import { selectOrders } from '../../selectors/orders';
import { typography } from '../../helpers/styles/typography';
import { OrderListTable } from '../../components/Table/OrderListTable';
import { NotesTable } from '../../components/NotesTable';
import { selectGetNotesLoader, selectNotes } from '../../selectors/notes';
import { createLoadHoc } from '../../helpers/hocs/createLoadHoc';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(36)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

const TableWrrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  &:last-child {
    margin-bottom: ${units(20)};
  }
`;

const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
  margin-bottom: ${units(28)};
`;

const TableTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  ${typography(10)};
`;

const DashBoardContent = () => {
    const orders = useTypeSelector(selectOrders);
    const notes = useTypeSelector(selectNotes);
    return (
        <StyledWrapper>
            <StyledPageTitle>Дашборд</StyledPageTitle>
            <StyledRoot>
                <TableWrrapper>
                    <TableTitle>Заказы</TableTitle>
                    {orders && <OrderListTable orders={orders} isDashboard />}
                </TableWrrapper>
                <TableWrrapper>
                    <TableTitle>Журналирование</TableTitle>
                    <NotesTable notes={notes?.data || []} />
                </TableWrrapper>
            </StyledRoot>
        </StyledWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetNotesLoader,
});

export const DashBoardContentWithLoader = loader(DashBoardContent);
