import {
    ChangeEvent, ReactNode, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { FilePreloader } from '../FilePreloader';
import { uploadDocsFile } from '../../api/files/uploadDocsFile';

export interface UploadFile {
  id?: number;
  path?: string;
  size?: number;
}

interface UploadPhotoProps {
  name: string;
  title?: string;
  titleElement?: ReactNode;
  className?: string;
  onChange?: (field: string, value?: UploadFile, shouldValidate?: boolean) => void;
  afterUpload?: () => void;
  value?: UploadFile;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  alig-items: center;
  gap: ${units(8)}
  width: 100%;

  @media screen and (max-width: ${BreakPoints.MD}) {
    flex-wrap: wrap;
  }
`;

const StyledTitle = styled.span`
  margin-bottom: ${units(6)};

  color: ${colors.greyDark};
  font-weight: ${FontWeight.MEDIUM};
  white-space: nowrap;

  ${typography(10)};

  @media (min-width: ${BreakPoints.SM}) and (max-width: ${BreakPoints.LG}) {
    font-size: 4.5vw;
  }

  @media (max-width: ${BreakPoints.SM}) {
    white-space: normal;
  }
`;

const StyledChooseFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${units(25)};

  background-color: transparent;

  transition: background 0.4s ease;

  cursor: pointer;

  ${typography(5)}
`;

const StyledIconWrapper = styled.picture`
  display: flex;
`;

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
`;

const StyledImageWrapper = styled.div`
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;

`;

const StyledWrapperIcon = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
      hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
      hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

const StyledInfoText = styled.p`
  color: ${colors.blue};

  ${typography(10)}
`;

const StyledErrorMessage = styled.p`
  color: ${colors.red};

  ${typography(0)}
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const StyledImageName = styled.div`
  ${typography(10)};
`;

const StyledImageSize = styled.div`
  color: ${colors.grey20};
  ${typography(10)}
`;

const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${units(8)};
`;

const StyledInfoContaner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${units(1)};
`;

export const UploadFiles = ({
    name,
    title,
    titleElement,
    className,
    value,
    onChange,
}: UploadPhotoProps) => {
    const ref = useRef<HTMLInputElement>(null);
    const [imgSrc, setImgSrc] = useState(value?.path);
    const [error, setError] = useState('');

    const [imgSize, setImgSize] = useState(value?.size ? value.size / 1000000 : 0);
    const [imgName, setImgName] = useState(value?.path);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setImgSrc(value?.path);
        setImgSize(value?.size ? value.size / 1000000 : 0);
        setImgName(value?.path);
    }, [value]);

    const handleSetImgSize = (size: number) => {
        const res = size / 1000000;
        setImgSize(res);
    };

    const acceptFileType = 'image/*,.png,.jpg,.jpeg,.pdf';

    const maxFileSize = 20 * 1024 * 1024;

    const removeFile = () => {
        if (ref.current) {
            ref.current.value = '';
        }
    };

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const [file] = event.target.files;

            setError('');

            handleSetImgSize((file?.size));
            setImgName(file?.name);

            if (file?.size > maxFileSize) {
                setError('Ваш файл превысил разрешенный размер в 20MB');
                return;
            }

            if (file?.type && !acceptFileType.includes(file?.type.split('/')[1])) {
                setError('Ваш тип файла не может быть загружен');
                return;
            }

            const reader = new FileReader();

            reader.onload = async (ev: ProgressEvent<FileReader>) => {
                const formDateFile = new FormData();
                formDateFile.append('file', file);

                try {
                    setLoading(true);
                    const uploadFileData = await uploadDocsFile(formDateFile);

                    setImgSrc(uploadFileData?.path);

                    const uploadFileDataWithSize = {
                        id: uploadFileData?.id,
                        path: uploadFileData?.path,
                        size: file?.size,
                    };

                    if (onChange) {
                        onChange(name, uploadFileDataWithSize, false);
                    }
                    setLoading(false);
                } catch (e) {
                    console.log('e', e);
                    setError('Ошибка загрузки файла');
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = async () => {
        if (value?.id) {
            // TODO добавить удаление файла
            // deleteFile(value?.id);

            setImgSrc('');
            handleSetImgSize(0);
            setImgName('');
            removeFile();

            if (onChange) {
                onChange(
                    name,
                    {
                        id: undefined,
                        path: '',
                        size: 0,
                    },
                    false,
                );
            }
        }
    };

    return (
        <StyledWrapper>
            {title ? <StyledTitle>{title}</StyledTitle> : titleElement}
            <StyledChooseFileWrapper className={className}>
                {imgSrc ? (
                    <StyledFlexDiv>
                        <StyledImageWrapper>
                            <Icon
                                type={TypesIcon.PDF}
                                color={colors.greyDark}
                                viewBox="0 0 24 24"
                                width="24px"
                                height="24px"
                            />
                        </StyledImageWrapper>
                        <StyledInfoWrapper>
                            <StyledImageName>{imgName}</StyledImageName>
                            <StyledImageSize>{`${imgSize ? imgSize.toFixed(2) : 0} МБ`} </StyledImageSize>
                        </StyledInfoWrapper>
                        <StyledWrapperIcon>
                            <Icon
                                type={TypesIcon.CLOSE}
                                color={colors.white}
                                viewBox="0 0 16 16"
                                width="16px"
                                height="16px"
                                onClick={handleRemoveImage}
                            />
                        </StyledWrapperIcon>
                    </StyledFlexDiv>
                ) : (
                    <StyledFlexDiv>
                        <StyledLabel className="image-upload" htmlFor={`upload-${name}`}>
                            <StyledIconWrapper>
                                {loading ? <FilePreloader /> : (
                                    <Icon
                                        type={TypesIcon.PDF}
                                        color={colors.blue}
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                    />
                                )}
                            </StyledIconWrapper>
                        </StyledLabel>
                        {!error && !imgSrc && (
                            <StyledInfoContaner>
                                <StyledInfoText>Загрузить файл</StyledInfoText>
                                <StyledImageSize>До 20 МБ</StyledImageSize>
                            </StyledInfoContaner>

                        )}
                    </StyledFlexDiv>
                )}
                <StyledInput
                    id={`upload-${name}`}
                    type="file"
                    onChange={onChangeHandler}
                    accept={acceptFileType}
                />
            </StyledChooseFileWrapper>
            <StyledErrorMessage>{error}</StyledErrorMessage>
        </StyledWrapper>
    );
};
