import { request } from '../../helpers/api/request';
import { CreateFilterContract, FilterItem } from '../../types/Endpoints/filters/FiltersContracts';

export const createFilter = async (requestData: FilterItem) => {
    const { data } = await request<CreateFilterContract>({
        url: 'admin/filters',
        method: 'POST',
        data: requestData,
    });

    return data;
};
