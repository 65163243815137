import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as brandsApi from '../../../api/brands';
import { CatalogBrands } from '../../../types/Endpoints/brands/BrandsContracts';

export const GET_BRANDS = 'brands/getBrands';

export const getCatalogBrands = createAsyncThunk<CatalogBrands[]>(
    GET_BRANDS,
    createRequest({
        type: GET_BRANDS,
        loader: async () => brandsApi.getBrands(),
    }),
);
