import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { Preloader } from '../../components/Preloader';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

export const StyledPreloader = styled(Preloader)`
  position: absolute;
  left: 50%;
  top: ${units(150)};
`;
