import { useDispatch } from 'react-redux';
import { OrderSettingsWithLoader } from './loaders';
import { getOrderSettings } from '../../actions/orderSettings/thunks';
import { getPaymentMethods } from '../../actions/paymentMethods/thunks';

export const OrderSettings = () => {
    const dispatch = useDispatch();

    dispatch(getOrderSettings());
    dispatch(getPaymentMethods());

    return <OrderSettingsWithLoader />;
};
