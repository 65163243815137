import { createReducer } from '@reduxjs/toolkit';
import { IPrice } from '../../types/Endpoints/prices/PricesContracts';
import { getPrices, updatePrice } from '../../actions/prices';

export interface IPricesModel {
  prices: IPrice[] | null;
}

export const prices = createReducer<IPricesModel>({
    prices: null,
}, builder => {
    builder.addCase(getPrices.fulfilled, (state, { payload }) => ({
        ...state,
        prices: payload.map(el => ({ key: el.key, isShow: el.isShow })),
    }));
    builder.addCase(updatePrice.fulfilled, (state, { payload }) => ({
        ...state,
        prices: payload,
    }));
});
