import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SearchSettingsContent } from './loaders';
import { getSearchSettings, getSearchSettingsLiked } from '../../actions/landings/thunks';
import { useTypeSelector } from '../../store';
import { selectSearchSettings } from '../../selectors/landings';
import { getCatalogCategories } from '../../actions/category';

export const SearchSettings = () => {
    const dispatch = useDispatch();
    const { searchSettings } = useTypeSelector(selectSearchSettings);

    useEffect(() => {
        if (!searchSettings) dispatch(getSearchSettings({ page: 1, size: 50 }));
        dispatch(getSearchSettingsLiked());
        dispatch(getCatalogCategories());
    }, []);

    return <SearchSettingsContent />;
};
