import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as categoriesApi from '../../../api/categories';
import { CatalogCategories } from '../../../types/Endpoints/categories/CategoriesContracts';

export const GET_CATALOG_CATEGORIES = 'catalog/getCatalogCategories';

export const getCatalogCategories = createAsyncThunk<CatalogCategories[]>(
    GET_CATALOG_CATEGORIES,
    createRequest({
        type: GET_CATALOG_CATEGORIES,
        loader: async () => categoriesApi.getCategories(),
    }),
);
