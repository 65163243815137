import { createReducer } from '@reduxjs/toolkit';
import { BlockItem, MobileBlockKey, WebBlockKey } from '../../types/Models/MainPage/MainPageModel';
import { getMobileBanners, getWebBanners } from '../../actions/mainPage';

interface SiteBodyState {
    siteBodyBlocks?: BlockItem<WebBlockKey>[];
    mobileBodyBlocks?: BlockItem<MobileBlockKey>[];
}

export const mainPage = createReducer<SiteBodyState>({}, builder => {
    builder.addCase(getWebBanners.fulfilled, (state, { payload }) => ({
        ...state,
        siteBodyBlocks: payload,
    }));
    builder.addCase(getMobileBanners.fulfilled, (state, { payload }) => ({
        ...state,
        mobileBodyBlocks: payload,
    }));
});
