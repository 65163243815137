import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../../components/PageTitle';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { PurchaseAndExchangeBreadcrumbs } from '../../../constants/BreadCrumbs/purchaseAandExchangeBreadcrumbs';
import { colors } from '../../../constants/Colors';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { EditCalculator } from '../../../components/EditCalculator';
import { useTypeSelector } from '../../../store';
import { selectDinamicPage } from '../../../selectors/dinamicPages';
import { DynamicPagesKeys } from '../../../types/Models/DinamicPages';
import { getPage } from '../../../actions/dinamicPages';
import { DynamicPageForm } from '../../../components/DynamicPageForm';
import { updateExchangePage } from '../../../actions/dinamicPages/thunks/updateExchangePage';
import { EditTable } from '../../../components/EditTable';
import * as S from './styles';
import { Drawers, DrawersTitile, FORMATS_PURCHASE_EXCHANGE } from './constants';
import { PageTitles } from '../../../constants/PageTitles';

export const PurchaseAndExchange = () => {
    const [state, setState] = useState(false);

    const [drawer, setDrawer] = useState(Drawers.CALCULATOR);

    const toggleDrawer = useCallback(
        (open: boolean, key: Drawers = Drawers.CALCULATOR) => {
            setDrawer(Drawers[key]);
            setState(open);
        },
        [setState, setDrawer],
    );

    const DrawersTypes = {
        [Drawers.CALCULATOR]: <EditCalculator />,
        [Drawers.TABLE]: <EditTable onClose={() => toggleDrawer(false)} />,
    };

    const dispatch = useDispatch();
    const dinamicPage = useTypeSelector(selectDinamicPage);
    const [showLoader, setShowLoader] = useState(false);

    useLayoutEffect(() => {
        dispatch(getPage(DynamicPagesKeys.PURCHASE_AND_EXCHANGE));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(true);
        }, 300);
    });

    if (!showLoader) {
        return <S.StyledPreloader />;
    }

    return (
        <S.Wrapper>
            <PageTitle>{PageTitles.PURCHASE_AND_EXCHANGE}</PageTitle>
            <Breadcrumbs breadcrumbs={PurchaseAndExchangeBreadcrumbs()} />
            <S.Root>
                {dinamicPage ? (
                    <DynamicPageForm
                        pagesData={dinamicPage}
                        pageKey={DynamicPagesKeys.PURCHASE_AND_EXCHANGE}
                        toggle={toggleDrawer}
                        updatePage={updateExchangePage}
                        formats={FORMATS_PURCHASE_EXCHANGE}
                    />
                ) : null}
            </S.Root>
            <S.StyledDrawer
                anchor="right"
                open={state}
                onClose={() => toggleDrawer(false)}
            >
                <S.DrawerRoot className={drawer}>
                    <S.Heading>
                        <S.ModalTitle>{DrawersTitile[drawer]}</S.ModalTitle>
                        <S.CloseButton onClick={() => toggleDrawer(false)}>
                            <Icon
                                type={TypesIcon.CLOSE_BIG}
                                color={colors.grey20}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                            />
                        </S.CloseButton>
                    </S.Heading>
                    {DrawersTypes[drawer]}
                </S.DrawerRoot>
            </S.StyledDrawer>
        </S.Wrapper>
    );
};
