import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { Modal } from '../../types/Models/Modal/Modal';
import { removeModal } from '../../actions/modal';
import { TypesIcon } from '../../types/TypesIcon';
import { ComponentWrapper } from '../Wrapper/ComponentWrapper';
import { IconBig } from '../Icon/IconBig';
import { config } from '../../config';

const StyledRoot = styled.div`
  position: relative;
  
  display: flex;
  flex-direction: column;  
  width: ${units(219)};
  padding: ${units(20)};
  
  max-height: 95vh;

  background-color: ${colors.light};

  border: none;
  border-radius: ${units(0)};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIcon = styled(IconBig)`
  cursor: pointer;
  transition: stroke .4s ease;

  :hover {
    stroke: ${colors.grayscale80};
  }
`;

const StyledComponentWrapper = styled(ComponentWrapper)`
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${units(0)};
  overflow: auto;
`;

const StyledCloseButton = styled.div`
  position: absolute;
  top: 40px;
  right: 8px;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
      hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
      hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

const StyledImageWrapper = styled.div`
  position: relative;
  display: flex;
`;

const StyledImage = styled.img`
  width: ${units(179)};
  height: ${units(136)};

  object-fit: contain;
`;

export const ImageModal = ({
    id,
    onClose,
    imgSrc,
}: Modal) => {
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    return (
        <StyledRoot>
            <StyledTitleWrapper>
                <StyledCloseButton>
                    <StyledIcon
                        onClick={handleClose}
                        type={TypesIcon.CLOSE_BIG}
                        color={colors.grey20}
                        height="24px"
                        width="24px"
                        viewBox="0 0 24 24"
                    />
                </StyledCloseButton>

            </StyledTitleWrapper>
            <StyledComponentWrapper>
                <StyledImageWrapper>
                    <StyledImage
                        className="image"
                        src={`${config.imageUrl}/${imgSrc}`}
                    />
                </StyledImageWrapper>
            </StyledComponentWrapper>
        </StyledRoot>
    );
};
