import { request } from '../../helpers/api/request';
import { EndpointContract } from '../../types/Endpoints/EndpointContract';
import {
    GetSearchSettingsContract,
    GetSearchSettingsItemContract,
    GetSearchSettingsLikedContract,
} from '../../types/Endpoints/SearchSettings/SearchSettingsContracts';

export interface SearchSettingsContract extends EndpointContract {
    responseBody: GetSearchSettingsContract;
    pathParams: {
      query?: string;
      page: number;
      size: number;
      isShowForSearch?: boolean;
    }
  }

export const getSearchSettings = async (params: SearchSettingsContract['pathParams']) => {
    const { data } = await request<SearchSettingsContract>({
        url: 'admin/search/often-searched',
        params,
    });

    return data;
};

export const getSearchSettingsById = async (id: string) => {
    const { data } = await request<GetSearchSettingsItemContract>({
        url: `admin/search/often-searched/${id}`,
    });

    return data;
};

export const getSearchSettingsLiked = async () => {
    const { data } = await request<GetSearchSettingsLikedContract>({
        url: 'search/liked',
    });

    return data;
};

export const getLikedList = async (ids: number[]) => {
    const { data } = await request({
        url: 'products/list',
        params: {
            fingerprint: 'abc',
            productIds: ids,
        },
    });

    return data;
};
