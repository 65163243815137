import { createAsyncThunk } from '@reduxjs/toolkit';
import * as usersApi from '../../../api/users';
import { createRequest } from '../../../helpers/api/createRequest';
import { Thunk } from '../../../helpers/redux/Thunk';
import { history } from '../../../store/history';
import { CreateUserModel } from '../../../api/users/addUser';
import { routes } from '../../../constants/RouterPath';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { addNotification } from '../../notifications/addNotification';

export const ADD_USER = 'users/addUser';

const onSuccess = (): Thunk => async dispatch => {
    history.push(routes.users);

    dispatch(addNotification({
        type: TypesSnackbars.INFO,
        message: {
            title: 'Пользователь добавлен',
            description: '',
        },
        icon: TypesIcon.SNACK_DIAMOND,
    }));
};

const onFail = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.ERROR,
        message: {
            title: 'Ошибка',
        },
        icon: TypesIcon.SNACK_ERROR,
    }));
};

export const addUser = createAsyncThunk(
    ADD_USER,
    createRequest({
        type: ADD_USER,
        loader: async (data: CreateUserModel) => usersApi.addUser(data),
        showFailNotification: false,
        onSuccess,
        onFail,
    }),
);
