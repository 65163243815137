import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import {
    selectGetMobileBlocksLoader,
    selectMobileBodyBlock,
} from '../../../../selectors/mainPage';
import {
    BannerItemEdit,
    BannerItemForm,
    BlockItemEdit,
    MobileBlockKey,
} from '../../../../types/Models/MainPage/MainPageModel';
import { units } from '../../../../helpers/styles/units';
import { FontWeight } from '../../../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../../../store';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { UploadPhotoWithMiniature } from '../../../../components/UploadPhotoWithMiniature';
import { TypesButton } from '../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../constants/SizeButton';
import { typography } from '../../../../helpers/styles/typography';
import { colors } from '../../../../constants/Colors';
import { Input } from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import {
    editBannerItemsMobile,
    editMobileBanners,
} from '../../../../actions/mainPage';
import { Icon } from '../../../../components/Icon';
import { TypesIcon } from '../../../../types/TypesIcon';
import { getShotPath } from '../../../../helpers/images/getPath';
import { mobileBodyBreadcrumbs } from '../../../../constants/BreadCrumbs/mobileBodybreadcrumbs';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { addModal } from '../../../../actions/modal';
import { getId } from '../../../../helpers/generateId';
import { TypesModal } from '../../../../types/TypesModalComponent';
import { isEqualObject } from '../../../../helpers/isEqual';
import { addNotification } from '../../../../actions/notifications';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  /* padding-left: ${units(12)}; */
  margin-bottom: ${units(63)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0;
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledInpurWrapper = styled.div`
  margin-bottom: ${units(12)};
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${units(20)};
  gap: ${units(40)};
`;

const StyledUploader = styled(UploadPhotoWithMiniature)`
  width: 100%;
`;

const StyledDeleteButton = styled(StyledButton)`
  width: fit-content;
  margin-top: ${units(20)};
`;

const StyledAddButton = styled(StyledButton)`
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0;
  gap: ${units(8)};

  font-weight: ${FontWeight.BOLD};
  border: none;
  &:hover {
    border: none;
  }
`;

const StyledDateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(16)};
  margin-bottom: ${units(12)};
`;

export const SaleBannerContent = (): JSX.Element => {
    const dispatch = useDispatch();

    const siteBodyBlock = useTypeSelector(
        selectMobileBodyBlock(MobileBlockKey.MOBILE_PROMOTIONS),
    );

    const handleSubmit = (values: any) => {
        const bannerRequestData = {
            buttonText: values.buttonText || null,
            title: values.title || null,
            url: values.url || null,
        };

        const requestData = {
            bannerItems: values.bannerItems.map(
                (item: BannerItemForm, index: number) => ({
                    buttonText: item.buttonText || null,
                    title: item.title || null,
                    text: item.text || null,
                    url: item.url || null,
                    fileId: item.fileImage?.id || null,
                    status: item.status || 'ACTIVE',
                    order: item.order || index + 1,
                    endDate: item.endDate || null,
                    startDate: item.startDate || null,
                }),
            ),
        };

        dispatch(
            editMobileBanners({
                key: MobileBlockKey.MOBILE_PROMOTIONS,
                newData: bannerRequestData,
            }),
        );
        dispatch(
            editBannerItemsMobile({
                key: MobileBlockKey.MOBILE_PROMOTIONS,
                newData: requestData.bannerItems,
            }),
        );
    };

    const items = siteBodyBlock?.bannerItems.map(item => ({
        ...item,
        fileImage: {
            id: item?.fileId || 0,
            path: item.fileURL ? getShotPath(item.fileURL) : '',
        },
    }));

    const validationSchema = yup.object().shape({
        bannerItems: yup.array().of(
            yup.object().shape({
                title: yup
                    .string()
                    .max(50, 'Не более 50 символов')
                    .required('Поле является обязательным'),
                text: yup.string().max(60, 'Не более 60 символов'),
                url: yup.string().required('Поле является обязательным'),
            }),
        ),
    });

    const initialValue: BlockItemEdit<MobileBlockKey> = {
        buttonText: siteBodyBlock?.buttonText || '',
        title: siteBodyBlock?.title || '',
        text: siteBodyBlock?.text || '',
        url: siteBodyBlock?.url || '',
        name: siteBodyBlock?.name || '',
        key: MobileBlockKey.MOBILE_PROMOTIONS,
        platform: 'MOBILE',
        type: 'SLIDER',
        bannerItems: items || [],
        bannerProducts: siteBodyBlock?.bannerProducts || [],
        fileURL: siteBodyBlock?.fileURL || '',
        id: siteBodyBlock?.id || 0,
        fileImage: {
            id: siteBodyBlock?.fileId || 0,
            path: siteBodyBlock?.fileURL ? getShotPath(siteBodyBlock.fileURL) : '',
        },
    };

    const handleResetForm = (calb: () => void, values: BannerItemEdit) => {
        calb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Body мобильного приложения</PageTitle>
                <Breadcrumbs breadcrumbs={mobileBodyBreadcrumbs()} />
                <Formik<BannerItemEdit>
                    initialValues={initialValue as unknown as BannerItemEdit}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {props => {
                        console.log('props', props);
                        return (
                            <StyledForm onSubmit={handleSubmit}>
                                <FieldArray name="bannerItems">
                                    {({ push, remove }) => (
                                        <StyledMainWrapper>
                                            {props.values.bannerItems?.map(
                                                (_block: BannerItemForm, index: number) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <div key={`block${index}`}>
                                                        <StyledInpurWrapper>
                                                            <StyledInput
                                                                name={`bannerItems[${index}].title`}
                                                                onChange={props.handleChange}
                                                                value={props.values?.bannerItems[index]?.title}
                                                                title="Заголовок (не более 50 символов)*"
                                                                placeholder="Заголовок (не более 50 символов)*"
                                                                typeInput="text"
                                                                maxLength={255}
                                                                isTouched={
                                                                    props.touched?.bannerItems
                                                                    && props.touched?.bannerItems[index]?.title
                                                                }
                                                                error={
                                                                    props.errors?.bannerItems
                                                                    && props.errors?.bannerItems[index]?.title
                                                                }
                                                                count={props.submitCount}
                                                            />
                                                        </StyledInpurWrapper>
                                                        <StyledDateWrapper>
                                                            <StyledInput
                                                                name={`bannerItems[${index}].startDate`}
                                                                onChange={props.handleChange}
                                                                value={props.values?.bannerItems[
                                                                    index
                                                                ]?.startDate
                                                                    .toString()
                                                                    .slice(0, 10)}
                                                                placeholder="Дата начала"
                                                                typeInput="date"
                                                                title="Дата начала"
                                                                isTouched={props.touched.startDate}
                                                                error={props.errors.startDate}
                                                            />

                                                            <StyledInput
                                                                name={`bannerItems[${index}].endDate`}
                                                                onChange={props.handleChange}
                                                                value={props.values?.bannerItems[index]?.endDate
                                                                    .toString()
                                                                    .slice(0, 10)}
                                                                placeholder="Дата окончания"
                                                                typeInput="date"
                                                                title="Дата окончания"
                                                                isTouched={props.touched.endDate}
                                                                error={props.errors.endDate}
                                                            />
                                                        </StyledDateWrapper>
                                                        <StyledInpurWrapper>
                                                            <StyledInput
                                                                name={`bannerItems[${index}].text`}
                                                                onChange={props.handleChange}
                                                                value={props.values?.bannerItems[index]?.text}
                                                                title="Краткое описание (не более 60 символов)"
                                                                placeholder="Краткое описание (не более 60 символов)"
                                                                typeInput="text"
                                                                maxLength={255}
                                                                isTouched={
                                                                    props.touched?.bannerItems
                                                                    && props.touched?.bannerItems[index]?.text
                                                                }
                                                                error={
                                                                    props.errors?.bannerItems
                                                                    && props.errors?.bannerItems[index]?.text
                                                                }
                                                                count={props.submitCount}
                                                            />
                                                        </StyledInpurWrapper>
                                                        <StyledInpurWrapper>
                                                            <StyledInput
                                                                name={`bannerItems[${index}].url`}
                                                                onChange={props.handleChange}
                                                                value={props.values?.bannerItems[index]?.url}
                                                                title="URL*"
                                                                placeholder="URL*"
                                                                typeInput="text"
                                                                isTouched={
                                                                    props.touched?.bannerItems
                                                                    && props.touched?.bannerItems[index]?.url
                                                                }
                                                                error={
                                                                    props.errors?.bannerItems
                                                                    && props.errors?.bannerItems[index]?.url
                                                                }
                                                                count={props.submitCount}
                                                            />
                                                        </StyledInpurWrapper>

                                                        <StyledUploader
                                                            name={`bannerItems[${index}].fileImage`}
                                                            onChange={props.setFieldValue}
                                                            value={props.values.bannerItems[index].fileImage}
                                                            format="3:5.5"
                                                            maxSize={3}
                                                        />

                                                        <StyledDeleteButton
                                                            onClick={() => {
                                                                dispatch(
                                                                    addModal({
                                                                        id: getId()(),
                                                                        type: TypesModal.CONFIRM,
                                                                        message: {
                                                                            description:
                                                                                'Вы действительно хотите удалить?',
                                                                        },
                                                                        onSuccessMessage: 'Удалить',
                                                                        onSuccess: () => remove(index),
                                                                        withCloseIcon: true,
                                                                    }),
                                                                );
                                                            }}
                                                            typeButton={TypesButton.DELETE}
                                                            size={SizesButton.M}
                                                        >
                                                            <Icon
                                                                type={TypesIcon.TRASH_BIG}
                                                                width="24px"
                                                                height="24px"
                                                                viewBox="0 0 24 24"
                                                            />
                                                            Удалить акцию
                                                        </StyledDeleteButton>
                                                    </div>
                                                ),
                                            )}
                                            <StyledAddButton
                                                typeButton={TypesButton.SECONDARY}
                                                size={SizesButton.M}
                                                onClick={() => push({
                                                    title: '',
                                                    url: '',
                                                    text: '',
                                                    startDate: new Date().toISOString().slice(0, 10),
                                                    endDate: new Date().toISOString().slice(0, 10),
                                                    fileId: {
                                                        id: 0,
                                                        path: '',
                                                    },
                                                })}
                                            >
                                                <Icon
                                                    type={TypesIcon.PLUS_BIG}
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    color="#000000"
                                                />{' '}
                                                Добавить акцию
                                            </StyledAddButton>
                                        </StyledMainWrapper>
                                    )}
                                </FieldArray>
                                <SubmitButtons
                                    reset={() => handleResetForm(props.resetForm, props.values)}
                                    submit={props.submitForm}
                                />
                            </StyledForm>
                        );
                    }}
                </Formik>
            </StyledWrapper>
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetMobileBlocksLoader,
});

export const SaleBannerWithLoader = loader(SaleBannerContent);
