import { request } from '../../helpers/api/request';
import { EditShopContract, EditShopItem } from '../../types/Endpoints/shops/ShopsContracts';

export const editShop = async ({ id, ...requestData }: EditShopItem) => {
    const { data } = await request<EditShopContract>({
        url: `admin/shops/${id}`,
        method: 'PATCH',
        data: requestData,
    });

    return data;
};
