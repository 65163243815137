/* eslint-disable jsx-a11y/no-static-element-interactions */

import { useState } from 'react';
import styled from 'styled-components';
import { navigationOptions } from './navigationOptions';
import { Navbar } from './Navbar';
import { NavItem } from './NavItem';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { config } from '../../config';

interface NavigationProps {
  className?: string;
}

const StyledButton = styled.a`
    display: flex;
    align-items: center;
    gap: ${units(2)};
    padding: ${units(8)} ${units(5)} ${units(8)} ${units(5)};
    width: 100%;

    color: ${colors.gold};
    background-color: ${colors.light};;
    text-decoration: none;
    cursor: pointer;
    font-weight: ${FontWeight.MEDIUM};
    border-left: ${units(3)} solid transparent;

    transition: color 0.4s ease;

    ${typography(10)}
    
    margin-top: ${units(4)};
    
`;

const FILE_NAME = 'Руководство пользователя.pdf';

export const Navigation = ({ className }: NavigationProps) => {
    const [openItem, setOpenItem] = useState<string | null>(null);

    const handleItemClick = (key: string) => {
        if (openItem !== key) {
            setOpenItem(key);
        } else {
            setOpenItem('');
        }
    };

    return (
        <Navbar.Items className={className}>
            {navigationOptions.map(option => (
                <NavItem
                    option={{ ...option, open: openItem === option.path }}
                    key={option.key}
                    onItemClick={handleItemClick}
                />
            ))}

            <StyledButton href={`${config.imageUrl}/${FILE_NAME}`} target="_blank">
                <Icon
                    type={TypesIcon.INFO}
                    color={colors.gold}
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                />
                <Navbar.LinkText>Инструкция</Navbar.LinkText>
            </StyledButton>
        </Navbar.Items>
    );
};
