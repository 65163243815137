import styled from 'styled-components';

import { Logo } from '../Logo';
import { colors } from '../../constants/Colors';
import { Navigation } from '../Navigation';
import { units } from '../../helpers/styles/units';
import { BreakPoints } from '../../constants/Styles/breakPoints';

const StyledHero = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: ${units(124)};

  background-color: ${colors.light};

  z-index: 100;

  padding: 0 ${units(0)};
`;

const StyledSideBar = styled.div`
  position: fixed;
  
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${units(13)};

  align-self: flex-start;

  max-width: ${units(124)};
  width: 100%;
  height: calc(100% - 40px);


  padding: 0;

  @media (min-width: ${units(400)}) {
    margin: 0 auto;
  }

  @media (max-width: ${BreakPoints.XL}) {
    padding: 0;
  }
`;

export const SideBar = () => (
    <StyledHero>
        <StyledSideBar>
            <Logo />
            <Navigation />
        </StyledSideBar>
    </StyledHero>
);
