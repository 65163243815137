import { createReducer } from '@reduxjs/toolkit';
import { addNotification, removeNotification } from '../../actions/notifications';
import { getId } from '../../helpers/generateId';
import { NotificationMessage } from '../../types/Models/Notifications/Notification';
import { TypesIcon } from '../../types/TypesIcon';

interface SnackbarsValues {
  message: NotificationMessage;
  type: string;
  id: number;
  autoHideDuration?: number;
  isUnclosed?: boolean;
  icon: keyof typeof TypesIcon,
}

type SnackbarsState = Record<string, SnackbarsValues>;

const notificationId = getId();

export const snackbars = createReducer<SnackbarsState>({}, builder => {
    builder.addCase(addNotification, (state, { payload }) => {
        const id = notificationId();
        return {
            ...state,
            [id]: {
                ...payload,
                id,
            },
        };
    });
    builder.addCase(removeNotification, (state, { payload }) => {
        const { [payload]: closedNotification, ...notifications } = state;
        return notifications;
    });
});
