import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as goodsApi from '../../../api/goods';
import { UpdateDisabledGoodsContract } from '../../../types/Endpoints/goods/GoodsContracts';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const UPDATE_GOODS_STATUSES = 'goods/updateGoodsStatuses';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Статусы обновлены успешно',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const updateGoodsStatusesThink = createAsyncThunk<
    UpdateDisabledGoodsContract['requestBody'],
    UpdateDisabledGoodsContract['requestBody']
>(
    UPDATE_GOODS_STATUSES,
    createRequest({
        type: UPDATE_GOODS_STATUSES,
        loader: async (updateData: UpdateDisabledGoodsContract['requestBody']) => goodsApi.updatePromotion(updateData),
        onSuccess,
    }),
);
