import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_ITEM_BLOG } from '../../actions/blog/thunks/getBlog';

const selectRoot = ({ blog }: RootState) => blog;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetBlogLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_ITEM_BLOG],
);

export const selectTagsList = createSelector(
    selectRoot,
    ({ tags }) => tags,
);

export const selectBlogs = createSelector(
    selectRoot,
    ({ blogs }) => blogs,
);

export const selectBlog = createSelector(
    selectRoot,
    ({ currentBlog }) => currentBlog,
);
