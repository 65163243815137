import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { jewelryExchanges } from './jewelryExchanges/index';
import { LOGOUT } from '../actions/auth';
import { modals } from './modal';
import { loaders } from './loaders';
import { users } from './users';
import { systems } from './systems';
import { snackbars } from './snackbars';
import { shops } from './shops';
import { search } from './search';
import { notification } from './notification';
import { calculator } from './calculator';
import { dinamicPages } from './dinamicPages';
import { cities } from './cities';
import { catalog } from './catalog';
import { collections } from './collections';
import { brands } from './brands';
import { mainPage } from './mainPage';
import { sales } from './sales';
import { blog } from './blog';
import { searchSettings } from './landings';
import { goods } from './goods';
import { prices } from './prices';
import { certificates } from './certificates';
import { orders } from './orders';
import { orderSettings } from './orderSettings';
import { paymentMethods } from './paymentMethods';
import { documents } from './documents';
import { robots } from './robots';
import { logs } from './logs';
import { notes } from './notes';

// тут any потому-что не знаем какой еще стор и не ломаем динамическую типизацию
const getClearState = (state: any, notClearSystemData?: boolean) => pick(state, notClearSystemData ? ['router', 'systems'] : ['router']);

export const createRootReducer = (history: History) => {
    const reducers = combineReducers({
        router: connectRouter(history),
        users,
        modals,
        loaders,
        systems,
        snackbars,
        search,
        notification,
        shops,
        calculator,
        dinamicPages,
        cities,
        catalog,
        collections,
        brands,
        mainPage,
        sales,
        blog,
        searchSettings,
        goods,
        prices,
        certificates,
        orders,
        orderSettings,
        paymentMethods,
        jewelryExchanges,
        documents,
        robots,
        logs,
        notes,
    });

    return (state: any, action: AnyAction) => {
        const realState = action.type === LOGOUT ? getClearState(state, action.payload) : state;

        return reducers(realState, action);
    };
};
