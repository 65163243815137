import { Redirect, Route } from 'react-router-dom';
import { RedirectProps } from 'react-router';

interface RedirectWithQueryProps extends RedirectProps {
    to: string;
}

export const RedirectWithQuery = ({ to, ...otherProps }: RedirectWithQueryProps) => (
    <Route
        render={() => (
            <Redirect
                {...otherProps}
                to={{
                    pathname: to,
                }}
                push
            />
        )}
    />
);
