import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as logsApi from '../../../api/logs';
import { GetLogsContract } from '../../../types/Endpoints/logs/LogsContracts';

export const GET_LOGS = 'logs/getLogs';

export const getLogs = createAsyncThunk(
    GET_LOGS,
    createRequest({
        type: GET_LOGS,
        loader: async (params: GetLogsContract['pathParams']) => logsApi.getLogs(params),
    }),
);
