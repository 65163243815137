import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { EmptyRow } from '../EmptyRow';
import { ComponentProps, styles } from './constants';
import { Note } from '../../types/Endpoints/notes/NotesContracts';
import { getTimeAndDate } from '../../helpers/getTimeAndDate';

export const NotesTable = ({ notes } : ComponentProps): JSX.Element => (
    <TableContainer component={Paper} sx={styles.root}>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
            <TableHead sx={styles.root}>
                <TableRow sx={{ 'td, th': { border: 0, fontWeight: 600 } }}>
                    <TableCell align="left">Дата</TableCell>
                    <TableCell align="left">Время</TableCell>
                    <TableCell align="left">Фамилия, Имя</TableCell>
                    <TableCell align="left">Событие</TableCell>
                </TableRow>
            </TableHead>
            <TableBody sx={[styles.root, styles.body]}>
                {notes?.map((row: Note) => (
                    <TableRow key={row.id} sx={{ 'td, th': { border: 0 } }}>
                        <TableCell component="th" scope="row" sx={{ width: '12.5%' }}>
                            {getTimeAndDate({
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }, new Date(row.createdAt))}
                        </TableCell>

                        <TableCell align="left" sx={{ width: '12.5%' }}>
                            {getTimeAndDate({
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            }, new Date(row.createdAt))}
                        </TableCell>

                        <TableCell align="left">
                            {`${row.user.lastName ? `${row.user.lastName} ` : ''}${row.user.firstName || ''}`}
                            {`${(!row.user.firstName && !row.user.lastName) ? '-' : ''}`}
                        </TableCell>

                        <TableCell align="left" sx={{ width: '50%' }}>
                            {row.message}
                        </TableCell>
                    </TableRow>
                ))}

                {!notes?.length && <EmptyRow colSpan={5} />}
            </TableBody>
        </Table>
    </TableContainer>
);
