import { request } from '../../helpers/api/request';

export const deleteShop = async (id: number) => {
    const { data } = await request({
        url: `admin/shops/${id}`,
        method: 'DELETE',
    });

    return data;
};
