import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Wrapper } from '../index';

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  flex-direction: column;
  
  background-color: transparent;
  
  border: 0;
  border-radius: 0;
`;

export const ComponentWrapper = ({ className, children }: PropsWithChildren<{className?: string}>) => (
    <StyledWrapper className={className}>
        {children}
    </StyledWrapper>
);
