import styled from 'styled-components';
import { useState, useEffect, FC } from 'react';
import { Tabs } from '../../../components/SiteBody/SiteBodyTabs';
import { MainGoods } from './MainGoods';
import { PhotosGoods } from './PhotosGoods';
import { CharacteristicsGoods } from './CharacteristicsGoods';
import { PriceOffers } from './PriceOffers';
// import { ProductSelection } from './ProductSelection';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// TODO: await backend!
const tabDdata = [
    { name: 'Основное', content: <MainGoods isEditing /> },
    { name: 'Фото', content: <PhotosGoods /> },
    { name: 'Характеристики', content: <CharacteristicsGoods /> },
    { name: 'Ценовые предложения', content: <PriceOffers /> },
    /* { name: 'Подборка товаров 1', content: <ProductSelection /> },
    { name: 'Подборка товаров 2', content: <ProductSelection /> }, */
];

export const EditGoodContent: FC = () => {
    const [active, setActive] = useState(0);

    useEffect(() => {
        window.scroll({ top: 0, left: 0 });
    }, [active]);

    return (
        <StyledWrapper>
            <Tabs data={tabDdata} active={active} setActive={setActive} />
        </StyledWrapper>
    );
};
