import { createReducer } from '@reduxjs/toolkit';
import { getOrders, getOrdersStatus } from '../../actions/orders';
import { setOrdersFilter } from '../../actions/orders/thunks/setOrdersFilter';
import { DateFilter } from '../../constants/Date/Periods';
import { Order } from '../../types/Endpoints/orders/OrdersContracts';
import { getOrder } from '../../actions/orders/thunks/getOrder';

export interface OrdersModel {
  orders?: Order[];
  ordersFilter?: DateFilter | null;
  order?: Order;
  ordersStatus?: string[];
}

export const orders = createReducer<OrdersModel>({}, builder => {
    builder.addCase(getOrders.fulfilled, (state, { payload }) => ({
        ...state,
        orders: payload,
    }));

    builder.addCase(setOrdersFilter, (state, { payload }) => ({
        ...state,
        ordersFilter: payload,
    }));

    builder.addCase(getOrder.fulfilled, (state, { payload }) => ({
        ...state,
        order: payload,
    }));
    builder.addCase(getOrdersStatus.fulfilled, (state, { payload }) => ({
        ...state,
        ordersStatus: payload,
    }));
});
