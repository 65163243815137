import { request } from '../../helpers/api/request';
import { NotificationContract } from '../../types/Endpoints/notification/NotificationContract';

export const setReadNotifications = async () => {
    const { data } = await request<NotificationContract>({
        url: 'notifications/read/',
    });

    return data;
};
