import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_COLLECTIONS } from '../../actions/collections/thunks';
import { GET_COLLECTION } from '../../actions/collections/thunks/getCollection';

const selectRoot = ({ collections }: RootState) => collections;
const selectSearch = ({ search }: RootState) => search;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetCollectionsLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_COLLECTIONS],
);

export const selectGetCollectionLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_COLLECTION],
);

export const selectCollections = createSelector(
    selectRoot,
    collections => collections?.collections,
);

export const selectSelectedCollection = createSelector(
    selectRoot,
    ({ selectedCollection }) => selectedCollection,
);

export const selectSearchCollections = createSelector(
    selectRoot,
    selectSearch,
    (collections, search) => (
        collections?.collections
      && collections.collections.filter(collection => collection.name.toLowerCase().includes(search?.searchString?.toLowerCase() || '')))
    || collections.collections,
);
