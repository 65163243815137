import { createAsyncThunk } from '@reduxjs/toolkit';
import * as usersApi from '../../../api/users';
import { createRequest } from '../../../helpers/api/createRequest';
import { Thunk } from '../../../helpers/redux/Thunk';
import { User } from '../../../types/Models/Users/User';
import { Session } from '../../../types/Session';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { addNotification } from '../../notifications';
import { getActiveUser } from './getActiveUser';

export const UPDATE_USER = 'user/updateUser';

const onSuccessStatus = (): Thunk => async dispatch => {
    await dispatch(getActiveUser());

    await dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Сотрудник отредактирован успешно',
            description: 'Благодарим  за редактирование',
        },
    }));
};

export const updateUser = createAsyncThunk(
    UPDATE_USER,
    createRequest({
        type: UPDATE_USER,
        loader: async ({ data, id }: {data: User, id?: Session['user']['id']}) => usersApi.updateUserInfo(data, id),
        onSuccess: () => onSuccessStatus(),
        showFailNotification: false,
    }),
);
