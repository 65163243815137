import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as categoriesApi from '../../../api/categories';

export const GET_CATALOG_CATEGORY = 'catalog/getCatalogCategory';

export const getCatalogCategory = createAsyncThunk(
    GET_CATALOG_CATEGORY,
    createRequest({
        type: GET_CATALOG_CATEGORY,
        loader: async (id: string) => categoriesApi.getCategory(id),
    }),
);
