import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as citiesApi from '../../../api/cities';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { EditCityItem } from '../../../types/Models/City/City';

export const CREATE_CITY_ITEM = 'cities/createCity';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.INFO,
        message: {
            title: 'Город добавлен',
            description: '',
        },
        icon: TypesIcon.SNACK_STORE,
    }));
};

export const createCity = createAsyncThunk(
    CREATE_CITY_ITEM,
    createRequest({
        type: CREATE_CITY_ITEM,
        loader: async (requestData: EditCityItem) => citiesApi.createCity(requestData),
        onSuccess,
    }),
);
