import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as paymentMethodsApi from '../../../api/paymentMethods';
import { PaymentMethodItem } from '../../../types/Models/OrderSettings/OrderSettings';

export const GET_PAYMENT_METHODS = 'orderSettings/getPaymentMethods';

export const getPaymentMethods = createAsyncThunk<PaymentMethodItem[]>(
    GET_PAYMENT_METHODS,
    createRequest({
        type: GET_PAYMENT_METHODS,
        loader: async () => paymentMethodsApi.getPaymentMethods(),
        showFailNotification: false,
    }),
);
