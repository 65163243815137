import { loadSystemData } from './loadSystemData';
import { logout } from './logout';
import { Thunk } from '../../../helpers/redux/Thunk';
import { getSessionFromStorage, handleSessionInStorageChanged } from '../../../helpers/auth/session';
import { routes } from '../../../constants/RouterPath';
import { selectLocation } from '../../../selectors/router';
import { history } from '../../../store/history';
import { setInitialPath } from '../../systems';

export const initAuth = (): Thunk => async (dispatch, getState) => {
    const { accessToken } = getSessionFromStorage() || {};
    const { pathname } = selectLocation(getState());

    if (pathname !== routes.login /* && pathname !== `${routes.profile}/${userId}` */) {
        dispatch(setInitialPath(pathname));
    }

    if (accessToken) {
        try {
            dispatch(loadSystemData());
            history.push(routes.site_body);
        } catch {
            history.push(routes.login);
        }
    }

    handleSessionInStorageChanged(async newSession => {
        if (!newSession) {
            dispatch(logout(true));
            return;
        }

        // пытаемся залогиниться с новой сессией
        await dispatch(loadSystemData());
    });
};
