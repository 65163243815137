import styled from 'styled-components';
import { useState } from 'react';

import { FooterApp } from './FooterApp';
import { FooterPaySystem } from './FooterPaySystem';
import { units } from '../../../helpers/styles/units';
import { Tabs } from '../../../components/SiteBody/SiteBodyTabs';
import { FooterContacts } from './FooterContacts';
import { FooterInfo } from './FooterInfo';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

const tabDdata = [
    { name: 'Приложения', content: <FooterApp /> },
    { name: 'Платежные системы', content: <FooterPaySystem /> },
    { name: 'Контакты', content: <FooterContacts /> },
    { name: 'Юридическая информация', content: <FooterInfo /> },
];

export const FooterContent = () => {
    const [active, setActive] = useState(0);

    return (
        <StyledWrapper>
            <Tabs data={tabDdata} active={active} setActive={setActive} />
        </StyledWrapper>
    );
};
