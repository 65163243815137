import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_PROMOTION } from '../../actions/sales/thunks/getPromotion';

const selectRoot = ({ sales }: RootState) => sales;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetPromotionLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_PROMOTION],
);

export const selectPromotionsList = createSelector(
    selectRoot,
    ({ promotions }) => promotions,
);

export const selectAllProducts = createSelector(
    selectRoot,
    ({ productsAll }) => productsAll,
);

export const selectCurrentPromotion = createSelector(
    selectRoot,
    ({ currentPromotion }) => currentPromotion,
);
