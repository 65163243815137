import styled from 'styled-components';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { units } from '../../helpers/styles/units';
import { TextEditor } from '../TextEditor';
import { colors } from '../../constants/Colors';
import { Input } from '../Input';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { Button } from '../Button';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { UploadPhotoWithMiniature } from '../UploadPhotoWithMiniature';
import { Block } from '../../types/DinamikPages';
import { DynamicPagesKeys } from '../../types/Models/DinamicPages';
import { CreateBlocksModel, PagesModel } from '../../api/dinamicPages/updatePage';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { isEqualObject } from '../../helpers/isEqual';
import { addNotification } from '../../actions/notifications';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { SubmitButtons } from '../StyledComponents';
import { Drawers } from '../../pages/DynamicPages/PurchaseAndExchange/constants';

interface pageProps {
    pagesData: CreateBlocksModel,
    pageKey: DynamicPagesKeys,
    updatePage: any,
    toggle?: (value: boolean, key: Drawers) => void
    formats?: {
        index: number;
        format: string;
    }[];
}

const StyledMainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 75%;
    max-width: ${units(334)};
    padding-bottom: ${units(70)};
    gap: ${units(20)};
`;

const StyledTextEditor = styled(TextEditor)`

    & .ql-toolbar.ql-snow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: ${units(28)};
        padding: ${units(3)} ${units(8)};

        border-radius: 0;
        border-color: ${colors.grey30};
    }

    & .ql-snow .ql-editor h3 {
        text-align: center;
    }

    & .ql-container.ql-snow {
        border-radius: 0;
        border-color: ${colors.grey30};

        & .ql-editor {
            min-height: ${units(28)};
            padding: ${units(8)} ${units(12)};
        }
    }
`;

const StyledInput = styled(Input)`
    font-weight: ${FontWeight.REGULAR};
    letter-spacing: 0.8px;

    background-color: ${colors.light};

    ${typography(10)};

    & .input-title {
        color: ${colors.grey20};
    }
`;

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: ${units(3)};
    width: 100%;
`;

const StyledButtonMargin = styled(StyledButton)`
    margin-top: ${units(20)};
`;

const StyledUploader = styled(UploadPhotoWithMiniature)`
    width: 100%;
`;

const StyledInputWrapper = styled.div`
`;

const StyledForm = styled(Form)`
    width: 100%;
`;

const StyledSubmitButtons = styled.div`
    margin-left: -${units(12)};
`;

export const DynamicPageForm = ({
    pagesData, pageKey, updatePage, toggle, formats,
}: pageProps) => {
    const dispatch = useDispatch();

    const handleSubmit = (props: any) => {
        let body = {
            blocks: props.values.blocks.map((block: Block) => ({
                isPublished: block.isPublished,
                pattern: {
                    title: block.pattern.title,
                    subtile: block.pattern.subtile,
                    value: block.pattern.value,
                    type: block.pattern.type,
                    image: {
                        id: block.pattern.image?.id || 0,
                        path: block.pattern.image?.path || '',
                    },
                },
                order: block.order,
            })),
        };

        const pageValue: PagesModel = {
            key: pageKey,
            data: body,
        };

        dispatch(updatePage(pageValue));
    };

    const validationSchema = yup.object().shape({
        blocks: yup.array().of(
            yup.object().shape({
                isPublished: yup.boolean(),
                pattern: yup.object().shape({
                    title: yup.string(),
                    subtile: yup.string(),
                    value: yup.string(),
                    type: yup.string(),
                    image: yup.string(),
                }),
                order: yup.number(),
            }),
        ),
    });

    const initialValue = pagesData;

    const handleResetForm = (clb: () => void, values: CreateBlocksModel) => {
        clb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <Formik<CreateBlocksModel>
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {props => (
                <StyledForm onSubmit={handleSubmit}>
                    <FieldArray name="blocks">
                        {() => (
                            <StyledMainWrapper>
                                {props.values.blocks?.map(
                                    (block: Block, index: number) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div key={`block${index}`}>
                                            {block.pattern.type === 'text' && (
                                                <StyledInputWrapper>
                                                    <StyledInput
                                                        name={`blocks[${index}].pattern.value`}
                                                        onChange={props.handleChange}
                                                        value={props.values?.blocks[index]?.pattern?.value}
                                                        title={
                                                            props.values.blocks[index].pattern.title
                                                                ? props.values.blocks[index].pattern.title
                                                                : props.values.blocks[index].pattern.subtile
                                                        }
                                                        placeholder={
                                                            props.values.blocks[index].pattern.title
                                                                ? props.values.blocks[index].pattern.title
                                                                : props.values.blocks[index].pattern.subtile
                                                        }
                                                        typeInput="text"
                                                        maxLength={255}
                                                    />
                                                </StyledInputWrapper>
                                            )}
                                            {block.pattern.type === 'image' && (
                                                <StyledUploader
                                                    name={`blocks[${index}].pattern.image`}
                                                    title={props.values.blocks[index].pattern.title}
                                                    onChange={props.setFieldValue}
                                                    value={props.values.blocks[index].pattern.image}
                                                    maxSize={2}
                                                    format={formats ? formats.find(el => el.index === index)?.format : undefined}
                                                />
                                            )}
                                            {block.pattern.type === 'editor' && (
                                                <StyledTextEditor
                                                    name={`blocks[${index}].pattern.value`}
                                                    onChange={props.setFieldValue}
                                                    valueEditor={
                                                        props.values.blocks[index].pattern.value
                                                    }
                                                    title={props.values.blocks[index].pattern.title}
                                                    withoutPictures={false}
                                                />
                                            )}

                                            {index === 3 && pageKey === DynamicPagesKeys.PURCHASE_AND_EXCHANGE && (
                                                <StyledButtonMargin
                                                    typeButton={TypesButton.SECONDARY}
                                                    size={SizesButton.M}
                                                    onClick={() => {
                                                        if (toggle) toggle(true, Drawers.TABLE);
                                                    }}
                                                >
                                                    <Icon
                                                        type={TypesIcon.EDIT}
                                                        color={colors.grey20}
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                    />{' '}
                                                    Редактировать таблицу
                                                </StyledButtonMargin>
                                            )}
                                            {index === 12 && pageKey === DynamicPagesKeys.PURCHASE_AND_EXCHANGE && (
                                                <StyledButtonMargin
                                                    typeButton={TypesButton.SECONDARY}
                                                    size={SizesButton.M}
                                                    onClick={() => {
                                                        if (toggle) toggle(true, Drawers.CALCULATOR);
                                                    }}
                                                >
                                                    <Icon
                                                        type={TypesIcon.EDIT}
                                                        color={colors.grey20}
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                    />{' '}
                                                    Редактировать калькулятор
                                                </StyledButtonMargin>
                                            )}
                                        </div>
                                    ),
                                )}
                            </StyledMainWrapper>
                        )}
                    </FieldArray>
                    <StyledSubmitButtons>
                        <SubmitButtons
                            reset={() => handleResetForm(props.resetForm, props.values)}
                            submit={() => handleSubmit(props)}
                        />
                    </StyledSubmitButtons>
                </StyledForm>
            )}
        </Formik>
    );
};
