import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const handbookBreadcrumbs = (name = '') : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Справочник',
        url: '',
    },
    {
        key: '2',
        value: name,
        url: '',
    },
]);
