/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-shadow */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { useTypeSelector } from '../../store';
import { colors } from '../../constants/Colors';
import { PageStatus } from '../../pages/MainSettings/SiteBody/MainBanner/constants';
import { selectCitiesByRegion, selectSearch } from '../../selectors/cities';

import { CityItem } from '../../types/Models/City/City';
import { selectSearchData } from '../../selectors/search';

interface TableProps {
  className?: string;
  changePage: (key: PageStatus, data: CityItem | null) => void;
}

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },

        '& .MuiTable-root': {
            backgroundColor: colors.light,
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
    },
};

export const RegionTable = ({ changePage }: TableProps) => {
    const cities = useTypeSelector(selectCitiesByRegion);
    const searchString = useTypeSelector(selectSearchData);
    const searchCities = useTypeSelector(selectSearch);

    const data = searchString ? searchCities : cities;

    return (
        <TableContainer component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell>Город / населенный пункт</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={[styles.root, styles.body]}
                >
                    {data && data.map(row => (
                        <TableRow
                            key={row.id}
                            sx={{ 'td, th': { border: 0 } }}
                        >

                            <TableCell align="left" className="text-grey">{`${
                                row.name || ''
                            }`}
                            </TableCell>

                            <TableCell align="left" sx={{ width: '5%' }}>
                                <EditButton
                                    typeButton={TypesButton.SECONDARY}
                                    size={SizesButton.M}
                                    onClick={() => { changePage(PageStatus.EDIT, row); }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </TableContainer>
    );
};
