import { request } from '../../helpers/api/request';

export const deleteFile = async (id: number) => {
    try {
        const { data } = await request({
            url: `files/${id}`,
            method: 'DELETE',
        });
        return data;
    } catch (error) {
        console.error(error);
    }
};
