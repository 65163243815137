import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as calculatorApi from '../../../api/calculator';
import { Calculators } from '../../../api/calculator/getCalculator';

export const GET_CALCULATOR_ALL = 'calculator/getCalculatorAll';

export const getCalculatorAll = createAsyncThunk<Calculators[]>(
    GET_CALCULATOR_ALL,
    createRequest({
        type: GET_CALCULATOR_ALL,
        loader: async () => calculatorApi.getCalculatorAll(),
        showFailNotification: false,
    }),
);
