import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EditSearchContentWithLoader } from './loaders';
import { getSearchSettingsById } from '../../../actions/landings';

export const EditSearch = () => {
    const dispatch = useDispatch();
    const { searchId } = useParams<{searchId: string}>();

    useEffect(() => {
        dispatch(getSearchSettingsById(searchId));
    }, []);

    return <EditSearchContentWithLoader />;
};
