import { request } from '../../helpers/api/request';
import { CreateCatalogBrandContract, EditCatalogBrandItem } from '../../types/Endpoints/brands/BrandsContracts';

export const createBrand = async (requestData: EditCatalogBrandItem) => {
    const { data } = await request<CreateCatalogBrandContract>({
        url: 'admin/brands',
        method: 'POST',
        data: requestData,
    });

    return data;
};
