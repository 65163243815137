import { request } from '../../helpers/api/request';
import { SetCertificatesContract, SetCertificatesItem } from '../../types/Endpoints/certificates/CertificatesContracts';

export const setCertificates = async (requestData: SetCertificatesItem[]) => {
    const { data } = await request<SetCertificatesContract>({
        url: 'admin/certificates',
        method: 'PATCH',
        data: requestData,
    });

    return data;
};
