import styled from 'styled-components';
import { Form } from 'formik';
import { units } from '../../../../helpers/styles/units';
import { Input } from '../../../../components/Input';
import { FontWeight } from '../../../../constants/Styles/fontWeight';
import { colors } from '../../../../constants/Colors';
import { typography } from '../../../../helpers/styles/typography';
import { UploadPhotoWithMiniature } from '../../../../components/UploadPhotoWithMiniature';

export const RootWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${units(63)};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};

  max-width: ${units(322)};
  width: 100%;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 0;
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

export const InpurWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
`;

export const Div = styled(InpurWrapper)`
  gap: ${units(20)};
`;

export const StyledForm = styled(Form)`
  width: 100%;
  padding-top: ${units(20)};
`;

export const Uploader = styled(UploadPhotoWithMiniature)`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  margin-left: -${units(12)};
`;
