import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as searchSettingsApi from '../../../api/landings';

export const GET_SEARCH_SETTINGS_BY_ID = 'searchSettings/getSearchSettingsById';

export const getSearchSettingsById = createAsyncThunk(
    GET_SEARCH_SETTINGS_BY_ID,
    createRequest({
        type: GET_SEARCH_SETTINGS_BY_ID,
        loader: async (id: string) => searchSettingsApi.getSearchSettingsById(id),
    }),
);
