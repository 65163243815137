/* eslint-disable import/no-unresolved */
import { useDispatch } from 'react-redux';
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { YMaps, Map } from '@pbe/react-yandex-maps';
import ymaps from 'yandex-maps';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { removeModal } from '../../actions/modal';
import { Modal } from '../../types/Models/Modal/Modal';
import { config } from '../../config';
import { units } from '../../helpers/styles/units';
import { Input } from '../Input';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import iconMap from '../../assets/iconMap.png';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  
  padding: 54px;
  
  background: ${colors.light};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const StyledTitle = styled.p`
  font-weight: ${FontWeight.MEDIUM};
  ${typography(20)};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  display: flex;
  gap: 32px;
  
  width: ${units(530)};
`;

const StyledMapWrapper = styled.div`
  width: ${units(330)};
  height: 100%;

  div {
    width: 100%;
  }

  .ymaps-2-1-79-balloon-pane {
    display: none;
  }
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  
  max-width: 369px;
  width: 100%;
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${units(12)};
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  width: 100%;
`;

export interface MapModel {
    name?: string;
    latitude?: string;
    longitude?: string;
  }

export const ChooseCityModal = ({
    id, onClose, onSuccess, data,
}: Modal) => {
    const dispatch = useDispatch();
    const map = useRef<ymaps.Map>();
    const [ymapsBuildRoute, setYmapsBuildRoute] = useState<typeof ymaps>();
    const [coordinates, setCoordinates] = useState<number[]>([]);

    const [prevPlacemark, setPrevPlacemark] = useState<ymaps.Placemark>();

    const [mapData, setMapData] = useState(data);
    const [markerCoordinates, setMarkerCoordinates] = useState<[number, number]>(
        mapData
            ? [mapData.latitude || 53.9, mapData.longitude || 27.56]
            : [53.9, 27.56],
    );

    useEffect(() => {
        setMapData(data);
    }, [data]);

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    const initialValue: MapModel = {
        name: mapData?.name || '',
        latitude: mapData?.latitude || undefined,
        longitude: mapData?.longitude || undefined,
    };

    const validationSchema = yup.object().shape({
        name: yup.string()
            .required('Поле является обязательным'),
        latitude: yup.string().required('Поле является обязательным'),
        longitude: yup.number().required('Поле является обязательным'),
    });

    const handleSubmit = (values: MapModel) => {
        const mapDatat = {
            name: values.name,
            latitude: values.latitude,
            longitude: values.longitude,
        };

        if (onSuccess) {
            onSuccess(mapDatat);
        }

        handleClose();
    };

    const form = useFormik<MapModel>({
        initialValues: initialValue,
        onSubmit: handleSubmit,
        validationSchema,
    });

    useEffect(() => {
        if (map?.current) {
            map?.current?.events.add('click', async (e: { get: (arg0: string) => any; }) => {
                let coords = e.get('coords');

                map?.current?.setCenter(coords, 12);
                setCoordinates(coords);
                await form.setFieldValue('latitude', coords[0]);
                await form.setFieldValue('longitude', coords[1]);
            });
        }
    }, [map?.current]);

    useEffect(() => {
        if (ymapsBuildRoute && coordinates[0]) {
            const placemark = new ymapsBuildRoute.Placemark([...coordinates], {}, {
                iconLayout: 'default#image',
                iconImageHref: iconMap,
                iconImageSize: [59, 42],
            });
            if (prevPlacemark) {
                map.current?.geoObjects.removeAll();
            }
            map.current?.geoObjects.add(placemark);
            setPrevPlacemark(placemark);
        }
    }, [coordinates]);

    return (
        <StyledRoot>
            <StyledTitleWrapper>
                <StyledTitle>
                    Показать город на карте
                </StyledTitle>
                <StyledIcon
                    onClick={handleClose}
                    type={TypesIcon.CLOSE}
                    color={colors.grayscale80}
                />
            </StyledTitleWrapper>
            <StyledWrapper>
                <StyledMapWrapper>
                    <YMaps
                        version="2.1.79"
                        query={{ apikey: config.mapKey, suggest_apikey: config.geoMapKey }}
                    >
                        <Map
                            instanceRef={map}
                            width="100%"
                            height="304px"
                            options={{
                                suppressMapOpenBlock: true,
                                suppressObsoleteBrowserNotifier: true,
                            }}
                            onLoad={maps => {
                                setYmapsBuildRoute(maps);
                                const placemark = new maps.Placemark(markerCoordinates, {}, {
                                    iconLayout: 'default#image',
                                    iconImageHref: iconMap,
                                    iconImageSize: [59, 42],
                                });
                                map?.current?.geoObjects.add(placemark);
                                setPrevPlacemark(placemark);
                            }}
                            noSuggestPanel={false}
                            defaultState={{
                                center: markerCoordinates,
                                zoom: 12,
                            }}
                            controls={[]}
                            modules={[
                                'templateLayoutFactory',
                                'layout.ImageWithContent',
                                'multiRouter.MultiRoute',
                                'geocode',
                                'suggest',
                                'Placemark',
                            ]}
                        />
                    </YMaps>
                </StyledMapWrapper>
                <StyledForm>
                    <StyledMainWrapper>
                        <StyledInput
                            name="name"
                            onChange={form.handleChange}
                            value={form.values.name}
                            placeholder="Название города, населенного пункта*"
                            typeInput="text"
                            maxLength={255}
                            title="Название города, населенного пункта*"
                            isTouched={form.touched.name}
                            error={form.errors.name}
                            count={form.submitCount}
                        />

                        <StyledInput
                            name="latitude"
                            onChange={form.handleChange}
                            value={form.values.latitude}
                            placeholder="Долгота*"
                            typeInput="number"
                            maxLength={255}
                            title="Долгота*"
                            isTouched={form.touched.latitude}
                            error={form.errors.latitude}
                            count={form.submitCount}
                        />

                        <StyledInput
                            name="longitude"
                            onChange={form.handleChange}
                            value={form.values.longitude}
                            placeholder="Широта*"
                            typeInput="number"
                            maxLength={255}
                            title="Широта*"
                            isTouched={form.touched.longitude}
                            error={form.errors.longitude}
                            count={form.submitCount}
                        />
                    </StyledMainWrapper>
                    <StyledButton
                        typeButton={TypesButton.PRIMARY}
                        size={SizesButton.M}
                        onClick={form.submitForm}
                    >
                        Сохранить изменения
                    </StyledButton>
                </StyledForm>
            </StyledWrapper>
        </StyledRoot>
    );
};
