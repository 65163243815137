import { LoginContract, LoginContractBody } from '../../types/Endpoints/users';
import { request } from '../../helpers/api/request';

// eslint-disable-next-line @typescript-eslint/no-shadow
export const login = async ({ login, password, fingerprint }: LoginContractBody) => {
    const { data } = await request<LoginContract>({
        url: 'auth/login',
        method: 'POST',
        data: {
            login,
            password,
            fingerprint,
        },
    });

    return data;
};
