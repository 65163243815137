import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { useDispatch } from 'react-redux';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../store';
import {
    getAllProductsThink,
    getAllProductsThinkLoadMore,
} from '../../actions/sales/thunks/getAllProducts';
import { selectAllProducts } from '../../selectors/sales';
import { AllProductsList } from './allProducts';
import { getCatalogCategories } from '../../actions/category/thunks';
import { selectSearchData } from '../../selectors/search';
import { GetPromotionContract } from '../../types/Endpoints/sales/PromitionsContracts';
import { DeleteButton } from '../Button/deleteButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { config } from '../../config';
import { setSearchData } from '../../actions/search/thunks/setSearchData';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${units(75)};
`;

const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: ${units(337)};
    box-shadow: none;

    background-color: ${colors.light};
  }

  & .MuiBackdrop-root.MuiModal-backdrop {
    background: ${colors.grey10};
    opacity: 0.4 !important;
  }
`;

const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(8)};
  margin-bottom: ${units(8)};
`;

const StyledImage = styled.img`
  width: ${units(24)};
  height: ${units(24)};
`;

const StyledText = styled.div`
  letter-spacing: 0.7px;
  width: 100%;

  ${typography(5)};
`;

const StyledOpenCatalog = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  ${typography(10)};  
  color: ${colors.blue};
  margin: ${units(20)} 0;
  cursor: pointer;
`;

const StyledDeleteButton = styled(DeleteButton)`
  max-width: 24px;
  svg {
    filter: brightness(0) saturate(100%) invert(61%) sepia(10%) saturate(2%) hue-rotate(334deg) brightness(95%) contrast(86%);
  }
`;

export const AddProductsPromotions = ({
    selectedProducts,
    setSelectedProducts,
}: {
    setSelectedProducts: (updatedProducts: GetPromotionContract['responseBody']['products']) => void
    selectedProducts: GetPromotionContract['responseBody']['products']
}) => {
    const dispatch = useDispatch();

    const searchString = useTypeSelector(selectSearchData);

    const [state, setState] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const toggleDrawer = (open: boolean) => () => {
        setState(open);
    };

    const allProducts = useTypeSelector(selectAllProducts);

    useEffect(() => {
        dispatch(
            getAllProductsThinkLoadMore({
                categories: `[${selectedCategories}]`,
                page: page.toString(),
                size: '30',
                search: searchString,
            }),
        );
    }, [page]);

    useEffect(() => {
        dispatch(
            getAllProductsThink({
                categories: `[${selectedCategories}]`,
                page: page.toString(),
                size: '30',
                search: searchString,
            }),
        );
    }, [selectedCategories, searchString]);

    useEffect(() => {
        dispatch(getCatalogCategories());
    }, []);

    return (
        <StyledWrapper>
            <StyledOpenCatalog onClick={toggleDrawer(true)}>
                Загрузить товары из каталога
            </StyledOpenCatalog>

            <div>
                {!!selectedProducts?.length && selectedProducts.map(item => (
                    <StyledFlexRow key={item.id}>
                        {item?.pictures && item.pictures[0] && <StyledImage src={`${config.imageUrlProd}${item.pictures[0].pictureURL}`} alt="" />}
                        <StyledText>{item.fullName}</StyledText>
                        <StyledDeleteButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={() => { setSelectedProducts(selectedProducts.filter(itemDelete => itemDelete.id !== item.id)); }}
                        />

                    </StyledFlexRow>
                ))}
            </div>

            <StyledDrawer anchor="right" open={state} onClose={toggleDrawer(false)}>
                {state && (
                    <AllProductsList
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        allProducts={allProducts.data}
                        saveAndClose={toggleDrawer(false)}
                        onClose={() => {
                            toggleDrawer(false)();
                            dispatch(setSearchData(''));
                        }}
                        loadMore={() => setPage(prev => prev + 1)}
                    />
                )}
            </StyledDrawer>
        </StyledWrapper>
    );
};
