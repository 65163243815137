import { request } from '../../helpers/api/request';
import { GetRobotsContract } from '../../types/Endpoints/robots/RobotsContract';

export const getRobots = async () => {
    const { data } = await request<GetRobotsContract>({
        url: 'admin/seo/robots',
    });

    return data;
};
