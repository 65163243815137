import { css, keyframes } from 'styled-components';
import { units } from '../../helpers/styles/units';

interface ApplySnackbarAnimationProps {
    isUnclosed?: boolean,
    animationTime?: number | string
}

export const applySnackbarAnimation = ({
    isUnclosed, animationTime,
}: ApplySnackbarAnimationProps) => {
    const fadein = keyframes`
      from {
        bottom: -${units(10)};
        opacity: 0;
      }
      to {
        bottom: 0;
        opacity: 1;
      }
    `;

    const fadeout = keyframes`
      from {
        bottom: 0;
        opacity: 1;
      }
      to {
        bottom: -${units(10)};
        opacity: 0;
      }
    `;

    if (isUnclosed) {
        return (
            css`
              animation: ${fadein} 0.5s;
            `
        );
    }

    return (
        css`
          animation: ${fadein} 0.5s, ${fadeout} 0.5s ${animationTime}s;
        `
    );
};
