import { request } from '../../helpers/api/request';
import {
    AddSearchSettingModel,
    AddSearchSettingsContract,
    SearchSettingItem,
    SetSearchSettingLikedContract,
} from '../../types/Endpoints/SearchSettings/SearchSettingsContracts';

export const setSearchSettings = async ({ id, ...requestData }: SearchSettingItem) => {
    const { data } = await request({
        url: `admin/search/often-searched/${id}`,
        method: 'PATCH',
        data: requestData,
    });

    return data;
};

export const addSearchSettings = async (requestData: AddSearchSettingModel) => {
    const { data } = await request<AddSearchSettingsContract>({
        url: 'admin/search/often-searched',
        method: 'POST',
        data: requestData,
    });

    return data;
};

export const setSearchSettingsLiked = async (requestData: number[]) => {
    const { data } = await request<SetSearchSettingLikedContract>({
        url: 'admin/search/liked',
        method: 'PUT',
        data: requestData,
    });

    return data;
};
