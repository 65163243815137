import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../../components/PageTitle';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { LoyaltyProgramBreadcrumbs } from '../../../constants/BreadCrumbs/loyatyProgramBreadcrumbs';
import { getPage, updatePage } from '../../../actions/dinamicPages';
import { DynamicPagesKeys } from '../../../types/Models/DinamicPages';
import { selectDinamicPage } from '../../../selectors/dinamicPages';
import { useTypeSelector } from '../../../store';
import { DynamicPageForm } from '../../../components/DynamicPageForm';
import * as S from '../styles';
import { PageTitles } from '../../../constants/PageTitles';

const FORMATS_LOYALTY = [
    {
        format: '7:2',
        index: 11,
    },
    {
        format: '7:3',
        index: 13,
    },
    {
        format: '7:3',
        index: 15,
    },
];

export const LoyaltyProgram = () => {
    const dispatch = useDispatch();
    const dinamicPage = useTypeSelector(selectDinamicPage);
    const [state, setState] = useState(false);

    useLayoutEffect(() => {
        dispatch(getPage(DynamicPagesKeys.LOYALTY_PROGRAM));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setState(true);
        }, 300);
    });

    if (!state) {
        return <S.StyledPreloader />;
    }

    return (
        <S.Wrapper>
            <PageTitle>{PageTitles.LOYALTY_PROGRAM}</PageTitle>
            <Breadcrumbs breadcrumbs={LoyaltyProgramBreadcrumbs()} />
            <S.Root>
                {dinamicPage ? (
                    <DynamicPageForm
                        pagesData={dinamicPage}
                        pageKey={DynamicPagesKeys.LOYALTY_PROGRAM}
                        updatePage={updatePage}
                        formats={FORMATS_LOYALTY}
                    />
                ) : null}
            </S.Root>
        </S.Wrapper>
    );
};
