import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/auth';

const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
];

const AppLogout = ({ children }:PropsWithChildren<any>) => {
    const dispatch = useDispatch();
    let timer:any;

    const resetTimer = useCallback(() => {
        if (timer) clearTimeout(timer);
    }, [timer]);

    const handleLogoutTimer = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = setTimeout(() => {
            resetTimer();
            Object.values(events).forEach(item => {
                window.removeEventListener(item, resetTimer);
            });
            dispatch(logout());
        }, 3600000); // 10000ms = 10secs. You can change the time.
    }, []);

    useEffect(() => {
        Object.values(events).forEach(item => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
    }, [handleLogoutTimer, resetTimer]);

    return children;
};

export default AppLogout;
