import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import {
    selectGetSiteBlocksLoader,
    selectSiteBodyBlock,
} from '../../../../selectors/mainPage';
import {
    BannerItemEdit,
    BlockItemEdit,
    WebBlockKey,
} from '../../../../types/Models/MainPage/MainPageModel';
import { useTypeSelector } from '../../../../store';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { UploadPhotoWithMiniature } from '../../../../components/UploadPhotoWithMiniature';
import { editWebBanners } from '../../../../actions/mainPage';
import { siteBodyBreadcrumbs } from '../../../../constants/BreadCrumbs/siteBodyBreadcrumbs';
import { getShotPath } from '../../../../helpers/images/getPath';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { TypesIcon } from '../../../../types/TypesIcon';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';
import { addNotification } from '../../../../actions/notifications';
import { isEqualObject } from '../../../../helpers/isEqual';
import { validationSchema } from './schema';
import * as S from './styles';

export const BannerRidgepoleContent = (): JSX.Element => {
    const dispatch = useDispatch();

    const siteBodyBlock = useTypeSelector(
        selectSiteBodyBlock(WebBlockKey.WEB_STRATCHED),
    );

    const handleSubmit = (values: BannerItemEdit) => {
        const requestData = {
            title: values.title || null,
            text: values.text || null,
            url: values.url || null,
            fileId: values.fileImage?.id,
            mobileFileId: values.mobileFileImage?.id,
        };

        dispatch(
            editWebBanners({ key: WebBlockKey.WEB_STRATCHED, newData: requestData }),
        );
    };

    const initialValue: BlockItemEdit<WebBlockKey> = {
        buttonText: siteBodyBlock?.buttonText || '',
        title: siteBodyBlock?.title || '',
        text: siteBodyBlock?.text || '',
        url: siteBodyBlock?.url || '',
        name: siteBodyBlock?.name || '',
        key: WebBlockKey.WEB_STRATCHED,
        platform: 'WEB',
        type: 'SLIDER',
        bannerItems: siteBodyBlock?.bannerItems || [],
        bannerProducts: siteBodyBlock?.bannerProducts || [],
        fileURL: siteBodyBlock?.fileURL || '',
        id: siteBodyBlock?.id || 0,
        mobileFileURL: siteBodyBlock?.mobileFileURL || '',

        fileImage: {
            id: siteBodyBlock?.fileId || 0,
            path: siteBodyBlock?.fileURL ? getShotPath(siteBodyBlock.fileURL) : '',
        },
        mobileFileImage: {
            id: siteBodyBlock?.mobileFileId || 0,
            path: siteBodyBlock?.mobileFileURL
                ? getShotPath(siteBodyBlock.mobileFileURL)
                : '',
        },
    };

    const form = useFormik<BannerItemEdit>({
        enableReinitialize: true,
        onSubmit: handleSubmit,
        initialValues: initialValue as unknown as BannerItemEdit,
        validationSchema,
    });

    const handleResetForm = () => {
        form.setErrors({});
        form.resetForm();
        const equal = isEqualObject(initialValue, form.values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>Body сайта</PageTitle>
                <Breadcrumbs breadcrumbs={siteBodyBreadcrumbs()} />
                <S.Root>
                    <S.MainWrapper>
                        <S.StyledInput
                            name="title"
                            onChange={form.handleChange}
                            value={form.values.title}
                            placeholder="Заголовок (не более 60 символов)*"
                            typeInput="text"
                            maxLength={255}
                            title="Заголовок (не более 60 символов)*"
                            isTouched={form.touched.title}
                            error={form.errors.title}
                            count={form.submitCount}
                        />

                        <S.StyledInput
                            name="url"
                            onChange={form.handleChange}
                            value={form.values.url}
                            placeholder="URL*"
                            typeInput="text"
                            title="URL*"
                            isTouched={form.touched.url}
                            error={form.errors.url}
                            count={form.submitCount}
                        />
                    </S.MainWrapper>

                    <S.FieldsWrapper>
                        <UploadPhotoWithMiniature
                            name="fileImage"
                            title="Изображение для desktop версии сайта"
                            onChange={form.setFieldValue}
                            value={form.values.fileImage}
                            format="8:1"
                            maxSize={3}
                        />
                        <UploadPhotoWithMiniature
                            name="mobileFileImage"
                            title="Изображение для мобильной версии сайта"
                            onChange={form.setFieldValue}
                            value={form.values.mobileFileImage}
                            format="2:1.5"
                            maxSize={2}
                        />
                    </S.FieldsWrapper>
                </S.Root>
            </S.Wrapper>
            <S.SubmitButtonsWrapper>
                <SubmitButtons reset={handleResetForm} submit={form.submitForm} />
            </S.SubmitButtonsWrapper>
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetSiteBlocksLoader,
});

export const BannerRidgepoleWithLoader = loader(BannerRidgepoleContent);
