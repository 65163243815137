import styled from 'styled-components';
import { useState } from 'react';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';
import { selectCitiesByRegionLoader } from '../../../selectors/cities';
import { CityItem, RegionTypes } from '../../../types/Models/City/City';
import { PageStatus } from '../loaders';
import { CreateCity } from '../CreateCity';
import { EditCity } from '../EditCity';
import { RegionTableContent } from '../CitiesTable';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BrestRegionContent = (): JSX.Element => {
    const [activeStatus, setActiveStatus] = useState(PageStatus.MAIN);

    const [selectedItem, setSelectedItem] = useState<CityItem | null>(null);

    const handleChangeContent = (
        key: PageStatus,
        data: CityItem | null = null,
    ) => {
        setActiveStatus(key);
        if (data) setSelectedItem(data);
    };

    const getPageContent = () => {
        if (activeStatus === PageStatus.MAIN) {
            return <RegionTableContent changePage={handleChangeContent} />;
        }
        if (activeStatus === PageStatus.ADD) {
            return <CreateCity changePage={handleChangeContent} region={RegionTypes.BREST} />;
        }
        return activeStatus === PageStatus.EDIT && selectedItem ? (
            <EditCity data={selectedItem} changePage={handleChangeContent} region={RegionTypes.BREST} />
        ) : null;
    };

    return <StyledWrapper>{getPageContent()}</StyledWrapper>;
};

const loader = createLoadHoc({
    selectLoader: selectCitiesByRegionLoader,
});

export const BrestRegionWithLoader = loader(BrestRegionContent);
