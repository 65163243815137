import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TypesIcon } from '../../../types/TypesIcon';
import { useTypeSelector } from '../../../store';
import { routes } from '../../../constants/RouterPath';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';
import { PageTitle } from '../../../components/PageTitle';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { selectSelectedUser, selectSelectedUserLoaders } from '../../../selectors/users';
import { roleOptions } from '../../../constants/Roles';
import { TypesSelect } from '../../../types/TypesSelect';
import Switch from '../../../components/sw';
import { CopyToClipboard } from '../../../components/CopyToClipboard';
/* import { deleteUser } from '../../../actions/users/thunks'; */
import { editUserBreadcrumbs } from '../../../constants/BreadCrumbs/editUserBreadcrumbs';
import { SubmitButtons } from '../../../components/StyledComponents';
import { editUser } from '../../../actions/users/thunks/editUser';
import { EditUserItem } from '../../../api/users/editUser';
import { isEqualObject } from '../../../helpers/isEqual';
import { addNotification } from '../../../actions/notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { addModal } from '../../../actions/modal/addModal';
import { getId } from '../../../helpers/generateId';
import { TypesModal } from '../../../types/TypesModalComponent';
import * as S from './styles';
import { UserItem } from './constants';
import { PageTitles } from '../../../constants/PageTitles';

const EditUser = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedUser = useTypeSelector(selectSelectedUser);

    const handleSubmit = (values: any) => {
        const data: EditUserItem = {
            id: selectedUser?.id || 0,
            login: values.login,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
            status: values.active ? 'ACTIVE' : 'DISABLED',
        };
        if (values.password) data.password = values.password;

        dispatch(editUser(data));
    };

    /*     const handleDeleteUser = () => {
        if (selectedUser?.id) dispatch(deleteUser(selectedUser.id));
    }; */

    const loginRegex = /^[a-z0-9]+$/;
    const passwordRegex = /^[a-z0-9]{5,}$/;

    const validationSchema = yup.object().shape({
        login: yup.string()
            .matches(loginRegex, 'Буквы от a до z (нижний регистр), цифры от 0 до 9')
            .required('Поле является обязательным'),
        password: yup.string().matches(passwordRegex, 'Буквы от a до z (нижний регистр), цифры от 0 до 9'),

    });

    const initialValue = {
        login: selectedUser?.login || '',
        firstName: selectedUser?.firstName || '',
        lastName: selectedUser?.lastName || '',
        role: selectedUser?.role || '',
        password: '',
        active: selectedUser?.status === 'ACTIVE' || false,
    };

    const form = useFormik<UserItem>({
        initialValues: initialValue,
        onSubmit: handleSubmit,
        validationSchema,
    });

    const handleResetForm = (values: any) => {
        history.push(routes.users);
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>{PageTitles.USERS}</PageTitle>
                <Breadcrumbs breadcrumbs={editUserBreadcrumbs('Редактировать')} />
                <S.Root>
                    <S.MainWrapper>
                        <S.IdInfo>Общая информация</S.IdInfo>
                        <S.FieldDiv>
                            <S.InpurWrapper>
                                <S.StyledInput
                                    name="firstName"
                                    onChange={form.handleChange}
                                    value={form.values.firstName}
                                    placeholder="Имя"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Имя"
                                />
                                <S.StyledInput
                                    name="lastName"
                                    onChange={form.handleChange}
                                    value={form.values.lastName}
                                    placeholder="Фамилия"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Фамилия"
                                />
                            </S.InpurWrapper>

                            <S.StyledSelect
                                name="role"
                                options={roleOptions}
                                selectType={TypesSelect.DEFAULT}
                                value={form.values.role}
                                label="Роль"
                                onClick={form.setFieldValue}
                                title="Роль"
                            />

                        </S.FieldDiv>

                        <S.SwitchWrapper>
                            <Switch
                                name="active"
                                value={form.values.active}
                                onChange={(value: any) => {
                                    dispatch(addModal({
                                        id: getId()(),
                                        type: TypesModal.CONFIRM,
                                        message: { description: 'Вы уверены, что хотите изменить статус?' },
                                        onSuccessMessage: 'Изменить',
                                        onSuccess: () => form.setFieldValue('active', value),
                                    }));
                                }}
                                type="checkbox"
                                title
                            />
                        </S.SwitchWrapper>
                    </S.MainWrapper>
                    <S.MainWrapper>
                        <S.IdInfo>Данные для входа</S.IdInfo>
                        <S.FieldDiv>
                            <S.Div>
                                <S.RowWrapper>
                                    <S.StyledInput
                                        name="login"
                                        onChange={form.handleChange}
                                        value={form.values.login}
                                        placeholder="Логин"
                                        typeInput="text"
                                        maxLength={255}
                                        title="Логин"
                                        isTouched={form.touched.login}
                                        error={form.errors.login}
                                        count={form.submitCount}
                                    />
                                    <S.ClipBoardWrapper>
                                        <CopyToClipboard copyText={form.values.login} />
                                    </S.ClipBoardWrapper>
                                </S.RowWrapper>

                                {!form.errors.login && (
                                    <S.FieldInfo>
                                        Буквы от a до z (нижний регистр), цифры от 0 до 9
                                    </S.FieldInfo>
                                )}
                            </S.Div>

                            <div>
                                <S.RowWrapper>
                                    <S.StyledInput
                                        name="password"
                                        onChange={form.handleChange}
                                        value={form.values.password}
                                        placeholder="Пароль"
                                        typeInput="text"
                                        maxLength={255}
                                        title="Пароль"
                                        isTouched={form.touched.password}
                                        error={form.errors.password}
                                        count={form.submitCount}
                                    />
                                    <S.ClipBoardWrapper>
                                        <CopyToClipboard copyText={form.values.password} />
                                    </S.ClipBoardWrapper>
                                </S.RowWrapper>

                                {!form.errors.password && (
                                    <S.FieldInfo>
                                        Буквы от a до z (нижний регистр), цифры от 0 до 9, не менее 5
                                        символов
                                    </S.FieldInfo>
                                )}
                            </div>
                        </S.FieldDiv>
                    </S.MainWrapper>
                    {/*                     <StyledDeleteButton
                        onClick={() => {
                            dispatch(
                                addModal({
                                    id: getId()(),
                                    type: TypesModal.CONFIRM,
                                    message: {
                                        description:
                                        'Вы действительно хотите удалить?',
                                    },
                                    onSuccessMessage: 'Удалить',
                                    onSuccess: handleDeleteUser,
                                    withCloseIcon: true,
                                }),
                            );
                        }}
                        typeButton={TypesButton.DELETE}
                        size={SizesButton.M}
                    >
                        <Icon
                            type={TypesIcon.TRASH_BIG}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                        />
                        Удалить
                    </StyledDeleteButton> */}

                </S.Root>
            </S.Wrapper>
            <SubmitButtons reset={() => handleResetForm(form.values)} submit={form.submitForm} />
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectSelectedUserLoaders,
});

export const EditUserWithLoader = loader(EditUser);
