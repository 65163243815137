import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as categoriesApi from '../../../api/categories';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';

export const DELETE_CATALOG_CATEGORY = 'catalog/deleteCatalogCategory';

const onSuccess = (): Thunk => () => {
    history.push(routes.settings);
};

export const deleteCatalogCategory = createAsyncThunk(
    DELETE_CATALOG_CATEGORY,
    createRequest({
        type: DELETE_CATALOG_CATEGORY,
        loader: async (id: number) => categoriesApi.deleteCategory(id),
        onSuccess,
    }),
);
