import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_BRANDS, GET_CATALOG_BRAND } from '../../actions/brands/thunks';

const selectRoot = ({ brands }: RootState) => brands;

const selectRootLoader = ({ loaders }: RootState) => loaders;

const selectSearch = ({ search }: RootState) => search;

export const selectGetCatalogBrandsLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_BRANDS],
);

export const selectGetCatalogBrandLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_CATALOG_BRAND],
);

export const selectBrands = createSelector(
    selectRoot,
    selectSearch,
    ({ brands }, search) => (brands
      && brands?.length
      && brands?.filter(item => item.name.toLowerCase().includes(search?.searchString?.toLowerCase() || '')))
    || brands,
);

export const selectSelectedBrand = createSelector(
    selectRoot,
    ({ selectedBrand }) => selectedBrand,
);
