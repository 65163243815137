import { request } from '../../helpers/api/request';
import { EditCatalogCollectionItem, EditCollectionContract } from '../../types/Endpoints/collections/GetCollectionsContract';

export const editCollection = async ({ id, ...requestData }: EditCatalogCollectionItem) => {
    const { data } = await request<EditCollectionContract>({
        url: `admin/collections/${id}`,
        method: 'PATCH',
        data: requestData,
    });

    return data;
};
