import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLayoutEffect } from 'react';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Checkbox } from '../../../components/Checkbox';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { useTypeSelector } from '../../../store';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { routes } from '../../../constants/RouterPath';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';
import { selectCities } from '../../../selectors/cities';
import { getCities } from '../../../actions/city';
import { SelectOption } from '../../../types/Select/SelectOption';
import { TypesSelect } from '../../../types/TypesSelect';
import { addModal } from '../../../actions/modal';
import { TypesModal } from '../../../types/TypesModalComponent';
import Switch from '../../../components/sw';
import { selectSelectedShop, selectShopLoader } from '../../../selectors/shops';
import { editShop } from '../../../actions/shops/thunks';
import { PageTitle } from '../../../components/PageTitle';
import { editShopBreadcrumbs } from '../../../constants/BreadCrumbs/editShopBreadcrumbs';
import { EditShopItem } from '../../../types/Endpoints/shops/ShopsContracts';
import { ButtonRoot } from '../../../components/StyledComponents';
import { getId } from '../../../helpers/generateId';
import * as S from './styles';
import { ShopModel } from './constants';
import { MapModel } from '../CreateShop/constants';
import { PageTitles } from '../../../constants/PageTitles';

const EditShopContent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedShop = useTypeSelector(selectSelectedShop);

    const cities = useTypeSelector(selectCities);

    const citiesOptions = cities?.map(city => ({
        id: city.id,
        name: city.name,
    })) || [];

    useLayoutEffect(() => {
        dispatch(getCities());
    }, []);

    const handleSubmit = (values: ShopModel) => {
        const requestData: EditShopItem = {
            id: selectedShop?.id || 0,
            externalId: values.externalId || '',
            address: values.address || '',
            workingHours: values.workingHours || '',
            phoneNumber: [],
            isActive: values.isActive,
            addons: [''],
            latitude: values.latitude || 0,
            longitude: values.longitude || 0,
            cityId: values?.cityId,
            comment: values.comment || '',
        };

        const addon: Array<'PURCHASE' | 'PURCHASE_EXCHANGE'> = [];

        if (values.phoneNumber1) requestData.phoneNumber.push(values.phoneNumber1);
        if (values.phoneNumber2) requestData.phoneNumber.push(values.phoneNumber2);

        if (values.PURCHASE) addon.push('PURCHASE');
        if (values.PURCHASE_EXCHANGE) addon.push('PURCHASE_EXCHANGE');

        requestData.addons = addon;

        dispatch(editShop(requestData));
    };

    /* const handleDeleteShop = () => {
        if (selectedShop?.id) {
            dispatch(deleteShop(selectedShop.id));
        }
    }; */

    const phoneRegExp = /^[+]?[(]?[375]{1}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{8}$/;

    const validationSchema = yup.object().shape({
        phoneNumber1: yup
            .string()
            .matches(phoneRegExp, 'Неправильный формат номера')
            .required('Поле является обязательным'),
        phoneNumber2: yup
            .string()
            .matches(phoneRegExp, 'Неправильный формат номера'),
        externalId: yup.string().required('Поле является обязательным'),
        cityId: yup.string().required('Поле является обязательным'),
        latitude: yup.string().required('Обязательное поле'),
        longitude: yup.string().required('Обязательное поле'),
        address: yup.string().required('Поле является обязательным'),
        workingHours: yup.string().required('Поле является обязательным'),
    });

    const initialValues: ShopModel = {
        id: selectedShop?.id || 0,
        externalId: selectedShop?.externalId || '',
        address: selectedShop?.address || '',
        workingHours: selectedShop?.workingHours || '',
        phoneNumber: selectedShop?.phoneNumber || [],
        phoneNumber1: selectedShop?.phoneNumber[0] || '',
        phoneNumber2: selectedShop?.phoneNumber[1] || '',
        cityId: selectedShop?.cityId || 0,
        addons: selectedShop?.addons || [],
        latitude: selectedShop?.latitude || 0,
        longitude: selectedShop?.longitude || 0,
        comment: selectedShop?.comment || '',
        PURCHASE: selectedShop?.addons.includes('PURCHASE') || false,
        PURCHASE_EXCHANGE: selectedShop?.addons.includes('PURCHASE_EXCHANGE') || false,
        isActive: selectedShop?.isActive || false,
    };

    const form = useFormik<ShopModel>({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema,
    });

    const handleSetMapData = async (mapData: MapModel) => {
        await form.setFieldValue('address', mapData.address);
        await form.setFieldValue('latitude', mapData.latitude);
        await form.setFieldValue('longitude', mapData.longitude);
        await form.setFieldValue('cityId', mapData.cityId);
    };

    const handleOpenModal = () => {
        dispatch(
            addModal({
                id: 1,
                data: {
                    address: form.values.address,
                    cityId: form.values.cityId,
                    latitude: form.values.latitude,
                    longitude: form.values.longitude,
                },
                type: TypesModal.CHOOSE_ADDRESS,
                onSuccess: handleSetMapData,
            }),
        );
    };

    const handleToggle = (value: boolean) => {
        form.setFieldValue('isActive', value);
    };

    const handleExit = () => {
        dispatch(addModal({
            id: getId()(),
            type: TypesModal.CONFIRM,
            message: { description: 'Вы действительно хотите выйти?' },
            onSuccessMessage: 'Выйти',
            onSuccess: () => history.push(routes.shops),
        }));
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>{PageTitles.SHOPS}</PageTitle>
                <Breadcrumbs breadcrumbs={editShopBreadcrumbs()} />
                <S.Root>
                    <S.MainWrapper>
                        <S.StyledInput
                            name="externalId"
                            onChange={form.handleChange}
                            value={form.values.externalId}
                            placeholder="ID магазина в 1С*"
                            typeInput="text"
                            maxLength={255}
                            title="ID магазина в 1С*"
                            isTouched={form.touched.externalId}
                            error={form.errors.externalId}
                            count={form.submitCount}
                        />

                        <S.StyledSelect
                            name="cityId"
                            options={citiesOptions as unknown as SelectOption[]}
                            selectType={TypesSelect.DEFAULT}
                            value={form.values.cityId}
                            label="Город*"
                            onClick={form.setFieldValue}
                            title="Город*"
                            isTouched={form.touched.cityId}
                            error={form.errors.cityId}
                        />

                        <S.InpurWrapper>
                            <S.StyledInput
                                name="longitude"
                                onChange={form.handleChange}
                                value={form.values.longitude}
                                placeholder="Долгота*"
                                typeInput="number"
                                maxLength={255}
                                title="Долгота*"
                                isTouched={form.touched.longitude}
                                error={form.errors.longitude}
                                count={form.submitCount}
                            />

                            <S.StyledInput
                                name="latitude"
                                onChange={form.handleChange}
                                value={form.values.latitude}
                                placeholder="Широта*"
                                typeInput="number"
                                maxLength={255}
                                title="Широта*"
                                isTouched={form.touched.latitude}
                                error={form.errors.latitude}
                                count={form.submitCount}
                            />

                            <S.MapButton
                                onClick={handleOpenModal}
                                typeButton={TypesButton.TEXT}
                            >
                                Карта
                            </S.MapButton>
                        </S.InpurWrapper>

                        <S.StyledInput
                            name="address"
                            onChange={form.handleChange}
                            value={form.values.address}
                            placeholder="Адрес*"
                            typeInput="text"
                            maxLength={255}
                            title="Адрес*"
                            isTouched={form.touched.address}
                            error={form.errors.address}
                            count={form.submitCount}
                        />
                    </S.MainWrapper>
                    <S.MainWrapper>
                        <S.IdInfo>Статус</S.IdInfo>
                        <S.SwitchWrapper>
                            <Switch
                                name="status"
                                value={form.values.isActive}
                                onChange={handleToggle}
                                type="checkbox"
                                title
                            />
                        </S.SwitchWrapper>
                    </S.MainWrapper>
                    <S.MainWrapper>
                        <S.StyledInput
                            name="workingHours"
                            onChange={form.handleChange}
                            value={form.values.workingHours}
                            placeholder="Время работы*"
                            typeInput="text"
                            maxLength={255}
                            title="Время работы*"
                            isTouched={form.touched.workingHours}
                            error={form.errors.workingHours}
                            count={form.submitCount}
                        />

                        <S.StyledInput
                            name="phoneNumber1"
                            onChange={form.handleChange}
                            value={form.values.phoneNumber1}
                            placeholder="Номер телефона*"
                            typeInput="text"
                            maxLength={255}
                            title="Номер телефона*"
                            isTouched={form.touched.phoneNumber1}
                            error={form.errors.phoneNumber1}
                            count={form.submitCount}
                        />

                        <S.StyledInput
                            name="phoneNumber2"
                            onChange={form.handleChange}
                            value={form.values.phoneNumber2}
                            placeholder="Номер телефона"
                            typeInput="text"
                            maxLength={255}
                            title="Номер телефона"
                            isTouched={form.touched.phoneNumber2}
                            error={form.errors.phoneNumber2}
                        />
                        <S.StyledInput
                            name="comment"
                            onChange={form.handleChange}
                            value={form.values.comment}
                            placeholder="Комментарий"
                            typeInput="text"
                            maxLength={255}
                            title="Комментарий"
                        />
                    </S.MainWrapper>
                    <S.MainWrapper>
                        <S.IdInfo>Услуги</S.IdInfo>
                        <Checkbox
                            name="PURCHASE"
                            title="Скупка"
                            checked={form.values.PURCHASE}
                            onChange={form.handleChange}
                        />

                        <Checkbox
                            name="PURCHASE_EXCHANGE"
                            title="Скупка по принципу обмена"
                            checked={form.values.PURCHASE_EXCHANGE}
                            onChange={form.handleChange}
                        />
                    </S.MainWrapper>

                    <S.DeleteButton
                        /* onClick={handleDeleteShop} */
                        typeButton={TypesButton.DELETE}
                        size={SizesButton.M}
                    >
                        <Icon
                            type={TypesIcon.TRASH_BIG}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                        />
                        Удалить
                    </S.DeleteButton>
                </S.Root>
            </S.Wrapper>
            <ButtonRoot>
                <S.ButtonWrapper>
                    <S.StyledButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleExit}
                    >
                        Отменить
                    </S.StyledButton>
                    <S.StyledButton
                        typeButton={TypesButton.PRIMARY}
                        size={SizesButton.M}
                        onClick={form.submitForm}

                    >
                        Сохранить изменения
                    </S.StyledButton>
                </S.ButtonWrapper>
            </ButtonRoot>
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectShopLoader,
});

export const EditShopWithLoader = loader(EditShopContent);
