import styled from 'styled-components';
import { useState } from 'react';
import { units } from '../../../helpers/styles/units';
import { MainBanner } from './MainBanner';
import { BannerCategories } from './CategoriesBanner';
import { BannerCollections } from './BannerCollection';
import { Tabs } from '../../../components/SiteBody/SiteBodyTabs';
import { SalesCollections } from './BannerSales';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

const tabDdata = [
    { name: 'Заглавный баннер', content: <MainBanner /> },
    { name: 'Баннеры категорий', content: <BannerCategories /> },
    { name: 'Коллекции', content: <BannerCollections /> },
    { name: 'Акции', content: <SalesCollections /> },
];

export const SiteBodyContent = () => {
    const [active, setActive] = useState(0);

    return (
        <StyledWrapper>
            <Tabs data={tabDdata} active={active} setActive={setActive} />
        </StyledWrapper>
    );
};
