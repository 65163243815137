import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as filtersApi from '../../../api/filters';
import { Thunk } from '../../../helpers/redux/Thunk';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';

export const DELETE_FILTER = 'catalog/deleteFilter';

const onSuccess = (): Thunk => () => {
    history.push(routes.filters);
};

export const deleteFilter = createAsyncThunk(
    DELETE_FILTER,
    createRequest({
        type: DELETE_FILTER,
        loader: async (filterId: number) => filtersApi.deleteFilter(filterId),
        onSuccess,
    }),
);
