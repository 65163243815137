export enum Drawers {
    CALCULATOR = 'CALCULATOR',
    TABLE = 'TABLE',
  }

export const DrawersTitile = {
    [Drawers.CALCULATOR]: 'Редактировать калькулятор',
    [Drawers.TABLE]: 'Редактировать таблицу',
};

export const FORMATS_PURCHASE_EXCHANGE = [
    {
        format: '3:2',
        index: 6,
    },
    {
        format: '3:2',
        index: 8,
    },
    {
        format: '3:2',
        index: 10,
    },
    {
        format: '3:2',
        index: 14,
    },
    {
        format: '3:2',
        index: 16,
    },
    {
        format: '3:2',
        index: 18,
    },
];
