/* eslint-disable no-use-before-define */
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Input } from '../../../components/Input';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { Button } from '../../../components/Button';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { PageTitle } from '../../../components/PageTitle';
import { PageStatus } from '../loaders';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { SubmitButtons } from '../../../components/StyledComponents';
import { TypesModal } from '../../../types/TypesModalComponent';
import { addModal } from '../../../actions/modal/addModal';
import { getId } from '../../../helpers/generateId';
import { CityItem, RegionTypes } from '../../../types/Models/City/City';
import { deleteCity, editCity, getCitiesByRegion } from '../../../actions/city/thunks';
import { handbookBreadcrumbs } from '../../../constants/BreadCrumbs/handbookBreadcrumbs';

interface CreateProps {
  changePage: (key: PageStatus) => void;
  data: CityItem;
  region: RegionTypes;
}

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${units(63)};
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
  max-width: ${units(322)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledDeleteButton = styled(StyledButton)`
  width: ${units(102)};
  padding: ${units(0)} ${units(16)} ${units(0)} ${units(14)};
`;

const StyledSubmitButtons = styled.div`
  margin-left: -${units(12)};
`;

const StyledInpurWrapper = styled.div`
  display: flex;
  gap: ${units(8)};
  align-items: flex-end;
`;

const StyledMapButton = styled(StyledButton)`
  height: ${units(28)};
  min-width: ${units(102)};
  max-width: ${units(102)};
  padding-top: ${units(12)};
`;

export const EditCity = ({ changePage, data, region }: CreateProps) => {
    const dispatch = useDispatch();

    const handleSubmit = async (values: CityItem) => {
        const requestData: CityItem = {
            id: values.id,
            name: values.name,
            region: values.region,
            latitude: values.latitude,
            longitude: values.longitude,
            isDefault: values.isDefault,
        };

        const res = await dispatch(editCity(requestData)) as any;

        if (!res.error) {
            changePage(PageStatus.MAIN);
            dispatch(getCitiesByRegion(region));
        }
    };

    const addressData = {
        name: data?.name || '',
        latitude: data?.latitude || 0,
        longitude: data?.longitude || 0,
        id: data?.id || 0,
    };

    const handleOpenModal = () => {
        dispatch(
            addModal({
                id: 1,
                data: addressData,
                type: TypesModal.CHOOSE_CITY,
                onSuccess: handleSetMapData,
            }),
        );
    };

    const handleDeleteItem = async () => {
        const res = await dispatch(deleteCity(data.id)) as any;
        if (!res.error) {
            changePage(PageStatus.MAIN);
            dispatch(getCitiesByRegion(region));
        }
    };

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required('Поле является обязательным'),
        latitude: yup.string().required('Обязательное поле'),
        longitude: yup.string().required('Обязательное поле'),
    });

    const initialValue: CityItem = {
        id: data.id,
        name: data.name,
        region: data.region,
        latitude: data.latitude,
        longitude: data.longitude,
        isDefault: data.isDefault,
    };

    const form = useFormik<CityItem>({
        enableReinitialize: true,
        onSubmit: handleSubmit,
        initialValues: initialValue as unknown as CityItem,
        validationSchema,
    });

    const handleSetMapData = async (mapData: any) => {
        await form.setFieldValue('name', mapData.name);
        await form.setFieldValue('latitude', mapData.latitude);
        await form.setFieldValue('longitude', mapData.longitude);
    };

    const handleDelete = () => {
        dispatch(
            addModal({
                id: getId()(),
                type: TypesModal.CONFIRM,
                message: { description: 'Вы действительно хотите удалить?' },
                onSuccessMessage: 'Удалить',
                onSuccess: handleDeleteItem,
                withCloseIcon: true,
            }),
        );
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Справочник</PageTitle>
                <Breadcrumbs
                    breadcrumbs={handbookBreadcrumbs('Редактировать')}
                />
                <StyledRoot>
                    <StyledMainWrapper>
                        <StyledInput
                            name="name"
                            onChange={form.handleChange}
                            value={form.values.name}
                            placeholder="Название города, населенного пункта*"
                            typeInput="text"
                            maxLength={255}
                            title="Название города, населенного пункта*"
                            isTouched={form.touched.name}
                            error={form.errors.name}
                            count={form.submitCount}
                        />

                        <StyledInpurWrapper>
                            <StyledInput
                                name="latitude"
                                onChange={form.handleChange}
                                value={form.values.latitude}
                                placeholder="Долгота*"
                                typeInput="number"
                                maxLength={255}
                                title="Долгота*"
                                isTouched={form.touched.latitude}
                                error={form.errors.latitude}
                                count={form.submitCount}
                            />

                            <StyledInput
                                name="longitude"
                                onChange={form.handleChange}
                                value={form.values.longitude}
                                placeholder="Широта*"
                                typeInput="number"
                                maxLength={255}
                                title="Широта*"
                                isTouched={form.touched.longitude}
                                error={form.errors.longitude}
                                count={form.submitCount}
                            />

                            <StyledMapButton
                                onClick={handleOpenModal}
                                typeButton={TypesButton.TEXT}
                            >
                                Карта
                            </StyledMapButton>
                        </StyledInpurWrapper>

                    </StyledMainWrapper>

                    <StyledDeleteButton
                        onClick={handleDelete}
                        typeButton={TypesButton.DELETE}
                        size={SizesButton.M}
                    >
                        <Icon
                            type={TypesIcon.TRASH_BIG}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                        />
                        Удалить
                    </StyledDeleteButton>
                </StyledRoot>
            </StyledWrapper>
            <StyledSubmitButtons>
                <SubmitButtons
                    reset={() => changePage(PageStatus.MAIN)}
                    submit={form.submitForm}
                />
            </StyledSubmitButtons>
        </StyledRootWrapper>
    );
};
