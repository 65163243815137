import axios, { AxiosError, AxiosProgressEvent } from 'axios';
import qs from 'qs';
import { config } from '../../config';
import { EndpointContract } from '../../types/Endpoints/EndpointContract';
import { ServerRequestError, throwNetworkError } from './errors';
import { StatusCode } from '../../types/Errors/StatusCode';
import { getFingerPrint, getSessionFromStorage, setSessionToStorage } from '../auth/session';
import { Session } from '../../types/Session';
import { logout } from '../../actions/auth';

const axiosInstance = axios.create({
    withCredentials: false,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});

let isRefreshing = false;
let refreshQueue: any = [];

const processQueue = (token: string | null) => {
    refreshQueue.forEach((item: any) => {
        if (token) {
            item.resolve(token);
        } else {
            item.reject();
        }
    });

    refreshQueue = [];
};

export const getApiPath = () => {
    const { baseUrl } = config;

    return baseUrl;
};

// refresh token logic
axiosInstance.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
        const originalRequest = error.config;

        const { refreshToken, ...session } = getSessionFromStorage() || {};

        const fingerprint = getFingerPrint();

        if (error.response?.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    refreshQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    return axios(originalRequest);
                }).catch(() => logout());
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const { data } = await axios.post<Session>(
                    `${getApiPath()}/auth/refresh`,
                    { fingerprint },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${refreshToken}`,
                        },
                    },
                );

                if (data.accessToken) {
                    isRefreshing = false;
                    axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
                    originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
                    setSessionToStorage({ ...session, ...data });
                    processQueue(data.accessToken);
                    return axios(originalRequest);
                }

                throw new Error('update token error');
            } catch (err) {
                console.error(err);
                processQueue(null);
                return logout();
            }
        }

        if (error.response?.status === 401 && originalRequest._retry) {
            return logout();
        }

        throw error;
    },
);

interface RequestOptions<T extends EndpointContract> {
  headers?: Record<string, string>;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  data?: T['requestBody'];
  params?: T['pathParams'] | null;
  responseType?: 'json' | 'blob';
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export interface RequestResult<T extends EndpointContract> {
  data: T['responseBody'];
  headers: T['responseHeader'];
}

export const request = async <T extends EndpointContract>({
    headers = {},
    method = 'GET',
    url,
    data,
    params,
    responseType,
    onUploadProgress,
}: RequestOptions<T>): Promise<RequestResult<T>> => {
    const { accessToken } = getSessionFromStorage() || {};

    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }

    const options = {
        headers,
        method,
        params,
        responseType,
        data,
        onUploadProgress,
        url: `${getApiPath()}/${url}`,
    };

    try {
        return await axiosInstance(options);
    } catch (error) {
        const typedError = <AxiosError>error;

        throwNetworkError(typedError);

        const {
            message,
            response: { status = StatusCode.INTERNAL_SERVER_ERROR } = {},
        } = typedError || {};

        throw new ServerRequestError(message, status, typedError?.response?.data);
    }
};
