import { DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import * as S from './styles';

interface CalendarProps {
  values: DateObject | null;
  minDate?: DateObject;
  maxDate?: DateObject;
  year: number;
  setValues: (data: DateObject | null) => void;
  setYear: (year: number) => void;
}

const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const NoteCalendar = ({
    values, year, setValues, setYear, minDate, maxDate,
}: CalendarProps) => {
    const handlePrevYear = () => {
        setYear(year - 1);
        if (values) setValues(new DateObject(values).subtract(1, 'years'));
    };

    const handleNextYear = () => {
        setYear(year + 1);
        if (values) setValues(new DateObject(values).add(1, 'years'));
    };

    const handleSetDate = (data: any) => {
        if (data) {
            data.year = year;
        }

        setValues(data ? new DateObject(data) : data);
    };

    const onMonthChange = (data: any) => {
        if (data?.year !== year) {
            setYear(data.year);
        }
    };

    return (
        <S.Div>
            <S.Picker
                value={values}
                onChange={handleSetDate}
                onOpenPickNewDate={false}
                numberOfMonths={3}
                monthYearSeparator=" "
                months={months}
                showOtherDays
                displayWeekNumbers={false}
                onMonthChange={onMonthChange}
                arrowClassName="custom-arrow"
                format="DD.MM.YYYY"
                weekStartDayIndex={1}
                inputClass="my-input"
                minDate={minDate}
                maxDate={maxDate || new DateObject()}
                placeholder="__.__.____"
                render={<InputIcon className="my-input" placeholder="__.__.____" />}
            >
                <S.YearWrapper>
                    <S.YearButton type="button" onClick={handlePrevYear}>
                        <Icon
                            type={TypesIcon.ARROW_LEFT}
                            viewBox="0 0 16 16"
                            width="16px"
                            height="16px"
                            color={colors.greyDark}
                        />
                    </S.YearButton>
                    <span>{year}</span>
                    <S.YearButtonRotate type="button" onClick={handleNextYear}>
                        <Icon
                            type={TypesIcon.ARROW_LEFT}
                            viewBox="0 0 16 16"
                            width="16px"
                            height="16px"
                            color={colors.greyDark}
                        />
                    </S.YearButtonRotate>
                </S.YearWrapper>
            </S.Picker>

            <S.CalendarIcon>
                <Icon
                    type={TypesIcon.NOTE_CALENDAR}
                    viewBox="0 0 16 16"
                    width="16px"
                    height="16px"
                />
            </S.CalendarIcon>
        </S.Div>
    );
};
