import { css } from 'styled-components';
import { colors } from '../../constants/Colors';
import { TypesButton } from '../../constants/ButtonTypes';

const colorByType: Record<string, string> = {
    [TypesButton.SECONDARY]: colors.grey20,
    [TypesButton.DELETE]: colors.grey20,
};

export const applyDisabledBorderColor = ({ typeButton, disabled }: { typeButton: TypesButton, disabled?: boolean }) => (
    disabled && (css`
          border-color: ${colorByType[typeButton]};
    `)
);
