interface IOptionsGetTimeAndDate {
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
  locale?: string;
}

export const getTimeAndDate = ({ locale, ...options }: IOptionsGetTimeAndDate, date?: Date): string => {
    const formatter = new Intl.DateTimeFormat(locale || undefined, {
        ...options,
        timeZone: 'Europe/Moscow',
        hour12: false,
    });
    return formatter.format(date || new Date());
};
