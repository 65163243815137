import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as shopsApi from '../../../api/shops';

export const GET_SHOP = 'shops/getShop';

export const getShop = createAsyncThunk(
    GET_SHOP,
    createRequest({
        type: GET_SHOP,
        loader: async (id: string) => shopsApi.getShop(id),
    }),
);
