import styled from 'styled-components';
import { Option } from '../navigationOptions';
import { Navbar } from '../Navbar';
import { Icon } from '../../Icon';
import { colors } from '../../../constants/Colors';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { typography } from '../../../helpers/styles/typography';
import { TypesIcon } from '../../../types/TypesIcon';

interface NavigationProps {
  option: Option;
  onItemClick: (key: string) => void;
}

const StyledGroup = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: ${units(2)};
  padding: ${units(4)} ${units(5)} ${units(4)} ${units(5)};
  width: 100%;

  color: ${colors.greyDark};
  text-decoration: none;
  cursor: pointer;
  font-weight: ${FontWeight.REGULAR};
  border-left: ${units(3)} solid ${colors.grey60};

  transition: color 0.4s ease;

  :hover {
    background-color: ${colors.white};
  }

  &.active {
    font-weight: ${FontWeight.MEDIUM};
    border-left: ${units(3)} solid ${colors.gold};
    background-color: ${colors.white};
  }

  ${typography(10)}
`;

const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled(Icon)`
  min-width: ${units(8)};
`;

const StyledArrowIcon = styled(Icon)`
  position: absolute;
  left: ${units(107)};

  transition: transform 0.2s ease;

  & > svg {
    stroke: ${colors.grey60};
  }

  &.active {
    transform: rotate(90deg);
  }
`;

export const NavItem = ({
    option,
    onItemClick,
}: NavigationProps): JSX.Element => (
    <StyledItemWrapper key={option.key}>
        {option.childs ? (
            <Navbar.Item onClick={() => onItemClick(option.path)}>
                <StyledGroup>
                    <Icon
                        type={option.icon}
                        color={colors.grey60}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />
                    <Navbar.LinkText>{option.title}</Navbar.LinkText>
                    <StyledArrowIcon
                        type={TypesIcon.ARROW_SMALL}
                        color={colors.grey60}
                        width="10px"
                        height="10px"
                        viewBox="0 0 10 10"
                        className={option.open ? 'active' : ''}
                    />
                </StyledGroup>
            </Navbar.Item>
        ) : (
            <Navbar.Item>
                <Navbar.LinkButton to={option.path}>
                    <Icon
                        type={option.icon}
                        color={colors.grey60}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />
                    <Navbar.LinkText>{option.title}</Navbar.LinkText>
                </Navbar.LinkButton>
            </Navbar.Item>
        )}

        {option.open
      && option?.childs?.map(item => (
          <Navbar.Item key={item.key}>
              <Navbar.LinkButton to={item.path}>
                  <StyledIcon
                      type={item.icon}
                      color={colors.grey20}
                      width="16px"
                      height="16px"
                      viewBox="0 0 16 16"
                  />
                  <Navbar.LinkText>{item.title}</Navbar.LinkText>
              </Navbar.LinkButton>
          </Navbar.Item>
      ))}
    </StyledItemWrapper>
);
