/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import styled from 'styled-components';
import {
    Formik, Form, FieldArray,
} from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { units } from '../../../helpers/styles/units';
import { Input } from '../../Input';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { Button } from '../../Button';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { DeleteButton } from '../../Button/deleteButton';
import { Icon } from '../../Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { DataEntity, JewelryExchangesType } from '../../../types/Endpoints/jewelryExchanges/GetJawelryExchangesContract';
import { updateJewelryExchanges } from '../../../actions/jewelryExchanges';

export interface ChildrenProps {
    modalData: JewelryExchangesType;
    onClose?: () => void;
  }

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)}; 
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;   
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.white};

  ${typography(10)};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  width: 100%;
`;

const StyledAddButton = styled(StyledButton)`
  width: fit-content;  
  padding: 0;
  gap: ${units(8)};
  height: ${units(12)};

  font-weight: ${FontWeight.MEDIUM};

  border: none;
  background: none;

  &:hover {
    border: none;
  }
`;

const StyledInpurWrapper = styled.div`  
  max-width: ${units(71)};
  flex: 1;
`;

const StyledSamleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${units(16)};
`;

const StyledDeleteButton = styled(DeleteButton)`
  min-width: ${units(12)};
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: ${units(20)};
`;

const StyledDeleteTitleButton = styled(Button)`
  border: none;
  background: none;
  width: fit-content;
  padding: 0;
  height: ${units(12)};

  &:hover {
    border: none;
    background: none;
  }

  &:active {
    border: none;
    background: none;
  }
`;

const StyledAddTitleButton = styled(Button)`
display: flex;
align-items: center;
flex-direction: row;
gap: ${units(3)};
  width: fit-content;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${units(8)};
  padding: ${units(12)} ${units(27)};
  width: ${units(324)};

  background-color: ${colors.light};
  border-top: 1px solid ${colors.grey40};

  position: fixed;
  bottom: 0;
  right: 25px;
  z-index: 1000;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
`;
export interface SamplesItem {
    id: number;
    number: number;
    exchangePrice: number | null;
    purchasePrice: number | null;
}

export const EditTableItems = ({
    modalData,
    onClose,
}: ChildrenProps) => {
    const dispatch = useDispatch();
    const handleSubmit = (props: any) => {
        const data = props?.samples.map((item: { id: number; name: string; samples: SamplesItem[] }) => ({
            id: item.id,
            name: item.name,
            samples: item.samples.map((sample: SamplesItem) => ({
                id: sample.id,
                number: sample.number,
                exchangePrice: sample.exchangePrice || null,
                purchasePrice: sample.purchasePrice || null,
            })),
        }));
        const formData = {
            id: modalData[0].id,
            data: { data },
        };

        dispatch(updateJewelryExchanges(formData));
        if (onClose) onClose();
    };

    const initialValue = {
        id: modalData[0].id,
        samples: modalData[0].data || ([] as DataEntity[]),
    };

    const validationSchema = yup.object().shape({
        samples: yup.array().of(
            yup.object().shape({
                id: yup.string(),
                name: yup.string().required('Обязательное поле').max(255, 'Максимальное количество символов 255'),
                samples: yup.array().of(
                    yup.object().shape({
                        id: yup.string(),
                        number: yup.string().required('Обязательное поле').max(10, 'Макс. 10 символов'),
                        exchangePrice: yup.number().nullable()
                            .test('maxDigits', 'Макс. 10 символов', value => {
                                if (value) {
                                    const stringValue = value.toString();
                                    return stringValue.length <= 10;
                                }
                                return true;
                            })
                            .test('requiredIfNoPurchasePrice', 'Поле является обязательным', function (value) {
                                const { purchasePrice } = this.parent;
                                return purchasePrice === undefined && !value?.toString().length ? this.createError({ message: 'Поле является обязательным' }) : true;
                            }),
                        purchasePrice: yup.number().nullable()
                            .test('maxDigits', 'Макс. 10 символов', value => {
                                if (value) {
                                    const stringValue = value.toString();
                                    return stringValue.length <= 10;
                                }
                                return true;
                            })
                            .test('requiredIfNoExchangePrice', 'Поле является обязательным', function (value) {
                                const { exchangePrice } = this.parent;
                                return exchangePrice === undefined && !value?.toString().length ? this.createError({ message: 'Поле является обязательным' }) : true;
                            }),

                    }),
                ),
            }),
        ),
    });

    return (
        <StyledRoot>
            <StyledMainWrapper>
                <Formik<any>
                    initialValues={initialValue}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                    validateOnBlur
                >
                    {props => (
                        <StyledForm>
                            <FieldArray name="samples">
                                {({ push, remove }) => (
                                    <>
                                        {props.values?.samples?.map(
                                            (sample: DataEntity, index: number) => (
                                                <ItemsWrapper key={sample.id}>
                                                    <StyledMainWrapper>

                                                        <StyledInput
                                                            name={`samples[${index}].name`}
                                                            onChange={props.handleChange}
                                                            value={props.values.samples[index].name}
                                                            title="Название"
                                                            placeholder="Название"
                                                            typeInput="text"
                                                            isTouched={
                                                                props.touched?.samples
                                && props.touched?.samples[index]?.name
                                                            }
                                                            error={
                                                                props.errors?.samples
                                && props.errors?.samples[index]?.name
                                                            }
                                                            count={props.submitCount}

                                                        />

                                                    </StyledMainWrapper>
                                                    {sample.samples?.length && sample.samples.map((item: SamplesItem, ind: number) => (
                                                        <StyledSamleWrapper key={item.id}>
                                                            <StyledInpurWrapper>
                                                                <StyledInput
                                                                    name={`samples[${index}].samples[${ind}].number`}
                                                                    onChange={e => {
                                                                        const regex = /[^0-9,./\\-]/g;
                                                                        const value = e.target.value.replace(regex, '');
                                                                        props.setFieldValue(`samples[${index}].samples[${ind}].number`, value);
                                                                    }}
                                                                    value={props.values.samples[index].samples[ind].number}
                                                                    title="Проба"
                                                                    placeholder="Проба"
                                                                    typeInput="text"
                                                                    maxLength={10}
                                                                    isTouched={
                                                                        props.touched?.samples
                                    && props.touched.samples[index]
                                    && props.touched?.samples[index]?.samples
                                    && props.touched?.samples[index]?.samples[ind]
                                    && props.touched?.samples[index]?.samples[ind]?.number
                                                                    }
                                                                    error={
                                                                        props.errors?.samples
                                    && props.errors.samples[index]
                                    && props.errors.samples[index]?.samples
                                    && props.errors.samples[index]?.samples[ind]
                                    && props.errors.samples[index]?.samples[ind]?.number
                                                                    }
                                                                    count={props.submitCount}
                                                                />
                                                            </StyledInpurWrapper>
                                                            <StyledInpurWrapper>
                                                                <StyledInput
                                                                    name={`samples[${index}].samples[${ind}].purchasePrice`}
                                                                    onChange={props.handleChange}
                                                                    value={props.values.samples[index].samples[ind].purchasePrice}
                                                                    title="Цена при скупке"
                                                                    placeholder="Цена при скупке"
                                                                    typeInput="number"
                                                                    isTouched={
                                                                        props.touched?.samples
                                    && props.touched?.samples[index]
                                    && props.touched?.samples[index]?.samples
                                    && props.touched?.samples[index]?.samples[ind]
                                    && props.touched?.samples[index]?.samples[ind]?.purchasePrice
                                                                    }
                                                                    error={
                                                                        props.errors?.samples
                                    && props.errors.samples[index]
                                    && props.errors.samples[index]?.samples
                                    && props.errors.samples[index].samples[ind]
                                    && props.errors.samples[index]?.samples[ind]?.purchasePrice
                                                                    }
                                                                    count={props.submitCount}
                                                                />
                                                            </StyledInpurWrapper>
                                                            <StyledInpurWrapper>
                                                                <StyledInput
                                                                    name={`samples[${index}].samples[${ind}].exchangePrice`}
                                                                    onChange={props.handleChange}
                                                                    value={props.values.samples[index].samples[ind].exchangePrice}
                                                                    title="Цена при обмене"
                                                                    placeholder="Цена при обмене"
                                                                    typeInput="number"
                                                                    isTouched={
                                                                        props.touched?.samples
                                    && props.touched?.samples[index]
                                    && props.touched?.samples[index]?.samples
                                    && props.touched?.samples[index]?.samples[ind]
                                    && props.touched?.samples[index]?.samples[ind]?.exchangePrice
                                                                    }
                                                                    error={
                                                                        props.errors?.samples
                                    && props.errors.samples[index]
                                    && props.errors.samples[index]?.samples
                                    && props.errors.samples[index].samples[ind]
                                    && props.errors.samples[index]?.samples[ind]?.exchangePrice
                                                                    }
                                                                    count={props.submitCount}
                                                                />
                                                            </StyledInpurWrapper>
                                                            {props?.values?.samples[index]?.samples?.length > 1 && (
                                                                <StyledDeleteButton
                                                                    typeButton={TypesButton.SECONDARY}
                                                                    size={SizesButton.M}
                                                                    onClick={() => {
                                                                        props.setFieldValue(`samples[${index}].samples`, [...props.values.samples[index].samples.slice(0, ind), ...props.values.samples[index].samples.slice(ind + 1)]);
                                                                    }}
                                                                />
                                                            )}
                                                        </StyledSamleWrapper>
                                                    ))}
                                                    <StyledAddButton
                                                        typeButton={TypesButton.SECONDARY}
                                                        size={SizesButton.M}
                                                        onClick={() => {
                                                            props.setFieldValue(`samples[${index}].samples`, [...props.values.samples[index].samples, {
                                                                id: props.values.samples[index].samples.length + 1,
                                                                number: 0,
                                                                exchangePrice: 0,
                                                                purchasePrice: 0,
                                                            }]);
                                                        }}
                                                    >
                                                        <Icon
                                                            type={TypesIcon.PLUS_BIG}
                                                            viewBox="0 0 24 24"
                                                            width="24px"
                                                            height="24px"
                                                        /> Добавить пробу
                                                    </StyledAddButton>
                                                    <StyledDeleteTitleButton
                                                        typeButton={TypesButton.DELETE}
                                                        size={SizesButton.M}
                                                        onClick={() => {
                                                            remove(index);
                                                        }}
                                                    >
                                                        <Icon
                                                            type={TypesIcon.TRASH}
                                                            color={colors.grey20}
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 25"
                                                        />
                                                        Удалить название
                                                    </StyledDeleteTitleButton>

                                                </ItemsWrapper>

                                            ),
                                        )}
                                        <StyledAddTitleButton
                                            typeButton={TypesButton.SECONDARY}
                                            size={SizesButton.M}
                                            onClick={() => {
                                                push({
                                                    id: props.values.samples.length + 1,
                                                    name: '',
                                                    samples: [{
                                                        id: 1,
                                                        number: 0,
                                                        exchangePrice: 0,
                                                        purchasePrice: 0,
                                                    }],

                                                });
                                            }}
                                        >
                                            Добавить название
                                        </StyledAddTitleButton>

                                    </>
                                )}
                            </FieldArray>
                            <ButtonWrapper>
                                <StyledButton
                                    typeButton={TypesButton.SECONDARY}
                                    size={SizesButton.M}
                                    onClick={onClose}
                                >
                                    Отменить
                                </StyledButton>
                                <StyledButton
                                    typeButton={TypesButton.PRIMARY}
                                    size={SizesButton.M}
                                    onClick={() => props.submitForm()}
                                >
                                    Сохранить изменения
                                </StyledButton>
                            </ButtonWrapper>
                        </StyledForm>
                    )}
                </Formik>
            </StyledMainWrapper>
        </StyledRoot>
    );
};
