import { ReactNode } from 'react';
import styled from 'styled-components';
import check from '../../styles/images/checkbox/Check.svg';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { typography } from '../../helpers/styles/typography';
import { applyColor } from '../Input/applyColor';
import { FontWeight } from '../../constants/Styles/fontWeight';

export interface CheckboxProps {
  name: string;
  title?: ReactNode;
  disabled?: boolean;
  checked?: boolean;
  onChange?: any;
  className?: string;
  error?: string;
  titleColor?:string,
  id?:string
}

const StyledInput = styled.input`
  appearance: none;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);

  &:checked + span::before {
    background-color: ${colors.gold};
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${units(8)};

    border: none;
  }
`;

const StyledTitle = styled.span<Pick<CheckboxProps, 'disabled' | 'error'> & { hasTitle: boolean }>`
  display: flex;

  letter-spacing: 1px;  

  ${typography(10)};

  &:before {
    content: "";

    position: relative;
    top: ${units(2)};

    vertical-align: middle;

    display: inline-block;
    box-sizing: border-box;

    min-width: ${units(9)};
    min-height: ${units(9)};
    max-height: ${units(9)};

    margin-right: ${({ hasTitle }) => hasTitle && units(5)};

    background-color: ${colors.white};
    border: 2px solid ${({ error }) => (error ? colors.error : colors.gold)};    
  }

  ${({ disabled }) => disabled && 'opacity: 0.5'};
`;

const StyledLabel = styled.label<Pick<CheckboxProps, 'disabled'>>`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  font-weight: ${FontWeight.REGULAR};
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${typography(10)};
`;

const StyledError = styled.div`
  margin-top: ${units(4)};
  
  position: relative;
  z-index: 1;
  
  display: flex;
  
  color: ${colors.error};

  min-height: ${units(6)};
  min-width: ${units(6)};

  ${applyColor};
`;

export const Checkbox = ({
    name,
    title,
    disabled,
    checked = false,
    onChange,
    className,
    error,
    id,
}: CheckboxProps) => (
    <>
        <StyledLabel
            disabled={disabled}
            className={className}
        >
            <StyledInput
                id={id}
                className="checkbox"
                type="checkbox"
                name={name}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <StyledTitle
                className="titleCheckbox"
                disabled={disabled}
                hasTitle={Boolean(title)}
                error={error}
            >
                <span className="titleSpan">{title}</span>
            </StyledTitle>
        </StyledLabel>
        {error && (
            <StyledError error={error}>
                {error}
            </StyledError>
        )}
    </>
);
