import { createAction } from '@reduxjs/toolkit';
import { removeSessionFromStorage } from '../../../helpers/auth/session';
import { routes } from '../../../constants/RouterPath';
import { history } from '../../../store/history';

export const LOGOUT = 'user/logout';

export const logout = createAction(
    LOGOUT,
    (notClearSystemData?: boolean) => {
        removeSessionFromStorage();

        history.push(routes.login);

        return { payload: notClearSystemData || null };
    },
);
