import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as collectionsApi from '../../../api/collections';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';

export const DELETE_COLLECTION = 'collections/deleteCollection';

const onSuccess = (): Thunk => () => {
    history.push(routes.collections);
};

export const deleteCatalogCollection = createAsyncThunk(
    DELETE_COLLECTION,
    createRequest({
        type: DELETE_COLLECTION,
        loader: async (id: number) => collectionsApi.deleteCollection(id),
        onSuccess,
    }),
);
