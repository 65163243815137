import { PricesKeys } from '../../types/Endpoints/prices/PricesContracts';

export const PRICES = [
    {
        key: PricesKeys.BASE,
        name: 'isShowOnSiteMainPrice',
        title: 'Основная цена',
    },
    {
        key: PricesKeys.DISCOUNT,
        name: 'isShowOnSiteDiscountPrice',
        title: 'Цена со скидкой',
    },
    {
        key: PricesKeys.DISCOUNT_DC,
        name: 'isShowOnSiteClientCardPrice',
        title: 'Цена по карте клиента',
    },
];
