import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as filtersApi from '../../../api/filters';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';
import { FilterItem } from '../../../types/Endpoints/filters/FiltersContracts';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const CREATE_FILTER = 'catalog/createFilter';

const onSuccess = (): Thunk => async dispatch => {
    history.push(routes.filters);

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Фильтр добавлен',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const createFilter = createAsyncThunk(
    CREATE_FILTER,
    createRequest({
        type: CREATE_FILTER,
        loader: async (requestData: FilterItem) => filtersApi.createFilter(requestData),
        onSuccess,
    }),
);
