import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DateObject } from 'react-multi-date-picker';
import dayjs, { ManipulateType } from 'dayjs';
import { SearchBar } from '../../components/SearchBar';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { useTypeSelector } from '../../store';
import { FilterButton } from '../../components/Button/filterButton';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { OrdersBreadcrumbs } from '../../constants/BreadCrumbs/ordersBreadcrumbs';
import { OrderListTable } from '../../components/Table/OrderListTable';
import { CalendarButton } from '../../components/Button/calendarButtom';
import { CalendarContent } from '../../components/Calendar';
import { Checkbox } from '../../components/Checkbox';
import { selectOrders, selectOrdersFilter, selectOrdersStatus } from '../../selectors/orders';
import { getOrders } from '../../actions/orders';
import { getCurrentDate, getDateMinusOneDay } from '../../helpers/date/getDate';
import { DateFilter, dateFilters } from '../../constants/Date/Periods';
import { setOrdersFilter } from '../../actions/orders/thunks/setOrdersFilter';
import { selectSearchData } from '../../selectors/search';
import { useDebounce } from '../../hooks/useDebounce';
import * as S from './styles';

export const OrderListContent = () => {
    const dispatch = useDispatch();
    const orders = useTypeSelector(selectOrders);
    const ordersStatus = useTypeSelector(selectOrdersStatus);

    const ordersFilter = useTypeSelector(selectOrdersFilter);
    const searchString = useTypeSelector(selectSearchData);
    const [searchValue] = useDebounce(500, searchString || '');

    const [activeFilter, setActiveFilter] = useState<DateFilter | null>(
        ordersFilter || DateFilter.DAY,
    );

    const handleSetActiveFilter = (filter: DateFilter | null) => {
        setActiveFilter(filter);
        dispatch(setOrdersFilter(filter));
    };

    useEffect(() => {
        if (ordersFilter) {
            setActiveFilter(ordersFilter);
        }
    }, [ordersFilter]);

    const handleFilterDate = (filter: DateFilter) => {
        handleSetActiveFilter(filter);
    };

    const filterRef = useRef<HTMLDivElement | null>(null);

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
        [],
    );

    const [dateValue, setDateValue] = useState<DateObject[]>([]);

    const [values, setValues] = useState<DateObject>(new DateObject());
    console.log('values', values);
    console.log('dateValue', dateValue);

    const [rangeValues, setRangeValues] = useState<DateObject[]>();

    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const dateFrom = dateValue && dateValue[0] ? dayjs(dateValue[0].toString()).format(
            'YYYY-MM-DD HH:mm:ss',
        ) : getDateMinusOneDay(activeFilter as ManipulateType);

        const dateTo = dateValue && dateValue[1] ? dayjs(dateValue[1].toString())
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss') : getCurrentDate();

        dispatch(
            getOrders({
                dateFrom,
                dateTo,
                statuses: selectedStatuses,
                search: searchValue,
            }),
        );
        if (dateValue && dateValue[0] && dateValue[1]) handleSetActiveFilter(null);
    }, [activeFilter, selectedStatuses, searchValue, dateValue]);

    const handleOpenFilter = () => {
        setIsFilterOpen(v => !v);
        if (isCalendarOpen) setIsCalendarOpen(false);
    };

    const handleOpenPicker = () => {
        setIsCalendarOpen(v => !v);
        if (isFilterOpen) setIsFilterOpen(false);
    };

    const handleSetDateValues = (data: DateObject[]) => {
        setIsCalendarOpen(false);
        setDateValue(data);
    };

    const handleStatusChange = (status: string) => {
        const isSelected = selectedStatuses.includes(status);

        const data = isSelected
            ? selectedStatuses.filter(prevStatus => prevStatus !== status)
            : [...selectedStatuses, status];

        setSelectedStatuses(data);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            filterRef.current
      && !filterRef.current.contains(event.target as Node)
        ) {
            setIsFilterOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <S.Wrapper>
            <S.StyledPageTitle>Список заказов</S.StyledPageTitle>
            <Breadcrumbs breadcrumbs={OrdersBreadcrumbs()} />
            <S.PanelWrapper>
                <S.SearchBarWrapper>
                    <SearchBar />
                </S.SearchBarWrapper>

                <S.FilterButtonsWrapper>
                    {dateFilters.map(item => (
                        <S.FilterButton
                            key={item.id}
                            onClick={() => {
                                handleFilterDate(item.id);
                                setValues(new DateObject());
                                setDateValue([]);
                            }}
                            className={activeFilter === item.id ? 'isActive' : ''}
                        >
                            {item.name}
                        </S.FilterButton>
                    ))}
                </S.FilterButtonsWrapper>

                <S.CalendarWrapper>
                    <CalendarButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOpenPicker}
                    />
                    {isCalendarOpen && (
                        <CalendarContent
                            setDate={handleSetDateValues}
                            values={values}
                            rangeValues={rangeValues}
                            year={year}
                            setValues={setValues}
                            setRangeValues={setRangeValues}
                            setYear={setYear}
                        />
                    )}
                </S.CalendarWrapper>

                <S.FilterWrapper>
                    <FilterButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOpenFilter}
                    />
                    {isFilterOpen && (
                        <S.FilterWindow ref={filterRef}>
                            <S.CheckBoxWrapper>
                                <S.IdInfo>Статус</S.IdInfo>
                                {ordersStatus && ordersStatus?.length > 0 && ordersStatus.map(status => (
                                    <Checkbox
                                        key={status}
                                        name="orderFilter"
                                        title={status}
                                        checked={selectedStatuses.includes(
                                            status,
                                        )}
                                        onChange={() => handleStatusChange(status)}
                                    />
                                ))}
                            </S.CheckBoxWrapper>

                            <S.ButtonClear
                                onClick={() => setSelectedStatuses([])}
                                typeButton={TypesButton.TEXT}
                            >
                                Очистить фильтр
                            </S.ButtonClear>
                        </S.FilterWindow>
                    )}
                </S.FilterWrapper>
            </S.PanelWrapper>
            <S.Root>
                <OrderListTable orders={orders} />
            </S.Root>
        </S.Wrapper>
    );
};
