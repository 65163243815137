import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import { SelectOption } from '../../types/Select/SelectOption';
import { units } from '../../helpers/styles/units';
import { applyStyleCursor } from './applyStyleCursor';
import { SelectProps } from '../../types/Select/SelectProps';
import { TypesSelect } from '../../types/TypesSelect';
import { applyStyleSelectByType } from './applyStyleSelectByType';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { applyStyleOptionNameByType } from './applyStyleOptionNameByType';
import { Icon } from '../Icon';
import { numberUnits } from '../../helpers/styles/numberUnits';
import { useOnScreen } from '../../hooks/useOnScreen';
import { useActiveOption } from '../../hooks/useActiveOption';
import { TypesIcon } from '../../types/TypesIcon';
import { useOutSideClick } from '../../hooks/useOutSideClick';
import { Options } from './Options';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { applyColor } from '../Input/applyColor';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  background-color: ${colors.light};

  @media screen and (max-width: ${BreakPoints.MD}) {
    flex-wrap: wrap;
  }
`;

const StyledTitleInput = styled.span`
  color: ${colors.grey20};
  font-weight: ${FontWeight.REGULAR};
  white-space: nowrap;

  ${typography(5)};

  @media (max-width: ${BreakPoints.SM}) {
    white-space: normal;
  }
`;

const StyledRoot = styled.div<Pick<SelectOption, 'disabled' | 'readonly'>>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: none;

  ${({ disabled }) => disabled && `background-color: ${colors.light}`} !important;

  box-sizing: border-box;
  width: 100%;

  :not(:last-child) {
    margin-bottom: ${units(4)};
  }

  ${applyStyleCursor};
`;

const StyledSelect = styled.div <Pick<SelectProps, 'readonly' | 'selectType' | 'opened' | 'disabled'>>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: ${({ opened }) => (opened ? 'relative' : 'static')};
  align-items: ${({ selectType }) => (selectType === TypesSelect.TRANSPARENT ? 'flex-start' : 'stretch')};

  box-sizing: border-box;

  width: 100%;
  height: ${({ opened, selectType }) => (opened && selectType !== TypesSelect.OUTLINE ? 'max-content' : '100%')};

  z-index: ${({ opened }) => (opened ? 140 : 99)};

  ${applyStyleSelectByType};
`;

const StyledSelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  box-sizing: border-box;
  padding: ${units(4)} ${units(3)}  ${units(4)} ${units(0)};

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledTitle = styled.div`
  width: 100%;
  min-width: fit-content;
`;

const StyledLabel = styled.p`
  color: ${colors.greyDark};
  white-space: nowrap;

  ${typography(10)};
`;

const StyledName = styled.p<Pick<SelectProps, 'disabled' | 'selectType'>>`
  display: -webkit-box;
  width: 100%;

  color: ${colors.greyDark};

  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${applyStyleOptionNameByType};
  ${typography(10)}
`;

const StyledDescription = styled.p<Pick<SelectProps, 'disabled'>>`
  letter-spacing: 0.02em;

  color: ${colors.greyDark};

  margin: 0;

  color: ${({ disabled }) => (disabled ? colors.grayscale40 : colors.grayscale80)};
  font-weight: 500;
  ${typography(0)};
`;

const StyledPrefix = styled.div<Pick<SelectProps, 'disabled'>>`
  margin-right: ${units(4)};

  & svg {
    ${({ disabled }) => disabled && `fill: ${colors.light}`};
  }
`;

const StyledIcon = styled(Icon)<Pick<SelectProps, 'disabled'>>`


  ${({ disabled }) => disabled && `
    fill: ${colors.light};
    stroke: ${colors.light};
  `};
`;

const StyledError = styled.div`
  margin-top: ${units(2)};

  position: relative;
  z-index: 1;

  display: flex;
  font-size: ${units(7)};

  color: ${colors.red};

  min-height: ${units(6)};
  min-width: ${units(6)};

  /* ${applyColor}; */
`;

export const Select = ({
    title,
    titleElement,
    options,
    prefix,
    label,
    onClick,
    className,
    value,
    name,
    selectType,
    disabled,
    description,
    readonly,
    trackName,
    error,
    isTouched,
    clientCustomHeight = 0,
    maxHeightDifference = 200,
}: PropsWithChildren<SelectProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const onScreen = useOnScreen(ref, numberUnits(1));
    const isTypeTransparent = selectType === TypesSelect.TRANSPARENT;
    const isVisibleTransparentIconArrow = !readonly && isTypeTransparent;
    const isError = isTouched && Boolean(error);

    const { name: selectTitle } = useActiveOption(options, value, trackName) || {};

    const currentSelectRef = ref.current?.getBoundingClientRect().bottom;
    const clientHeight = clientCustomHeight || window.document.body.offsetHeight;

    useEffect(() => {
        if (!onScreen) {
            setIsOpen(false);
        }
    }, [onScreen]);

    let isOpenDown = true;

    if (Number(clientHeight) - Number(currentSelectRef) < maxHeightDifference) {
        isOpenDown = false;
    }

    const isArrowDown = (isOpen && !isOpenDown) || (!isOpen && isOpenDown);

    const typeIcon = isArrowDown ? TypesIcon.ARROW_DOWN_BIG : TypesIcon.ARROW_UP_BIG;

    const currentTitle = title ? <StyledTitleInput>{title}</StyledTitleInput> : titleElement;

    const handleClick = useCallback(() => {
        if (!disabled && !readonly) {
            setIsOpen(!isOpen);
        }
    }, [disabled, isOpen, readonly]);

    const handleOutSideClick = useCallback((event: Event) => {
        if (!ref.current?.contains(event.target as Node)) {
            setIsOpen(false);
        }
    }, []);

    useOutSideClick(ref, handleOutSideClick);

    return (
        <StyledWrapper className={className}>
            { !value
                ? ''
                : currentTitle}
            <StyledRoot
                disabled={disabled}
                readonly={readonly}
                ref={ref}
                onClick={handleClick}
            >
                <StyledSelect
                    opened={isOpen}
                    selectType={selectType}
                    readonly={readonly}
                    disabled={disabled}
                >
                    <StyledSelectHeader className="selectHeader">
                        {prefix && (
                            <StyledPrefix disabled={disabled}>
                                {prefix}
                            </StyledPrefix>
                        )}
                        <StyledTitle>
                            {!selectTitle && label && <StyledLabel className="label">{label}</StyledLabel>}
                            <StyledName selectType={selectType} disabled={disabled}>{selectTitle}</StyledName>
                            {description && <StyledDescription disabled={disabled}>{description}</StyledDescription>}
                        </StyledTitle>
                        {isVisibleTransparentIconArrow && <StyledIcon type={typeIcon} color={colors.white} fill={colors.white} />}
                        {!disabled && !isVisibleTransparentIconArrow && (
                            <StyledIcon
                                type={typeIcon}
                                disabled={disabled}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                            />
                        )}
                    </StyledSelectHeader>
                    {onScreen && isOpen
                        && (
                            <Options
                                options={options}
                                onClick={onClick}
                                field={name}
                                opened={isOpen}
                                openedDown={isOpenDown}
                                selectType={selectType}
                                trackName={trackName}
                            />
                        )}
                </StyledSelect>
                {isError && (
                    <StyledError>
                        {error}
                    </StyledError>
                )}
            </StyledRoot>
        </StyledWrapper>
    );
};
