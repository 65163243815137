import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import { addNotification } from '../../notifications';
import { TypesIcon } from '../../../types/TypesIcon';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Thunk } from '../../../helpers/redux/Thunk';
import { IModifyDataForUpdateProduct } from '../../../pages/Goods/EditGood/PriceOffers/interface';
import * as productApi from '../../../api/goods';

interface IDataUpdateProductGoods {
    data: {
      size: IModifyDataForUpdateProduct[];
    };
    id: number;
}

const UPDATE_PRODUCT_GOODS = 'goods/updateProductGoods';

const MESSAGE = 'Успешно обновлено';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        icon: TypesIcon.SNACK_SUCCES,
        message: {
            title: MESSAGE,
        },
    }));
};
// FIXME: fix first type
export const updateProductGoods = createAsyncThunk<void, IDataUpdateProductGoods>(
    UPDATE_PRODUCT_GOODS,
    createRequest({
        type: UPDATE_PRODUCT_GOODS,
        onSuccess,
        loader: async ({ data, id }) => productApi.updateProductGoods(data, id),
    }),
);
