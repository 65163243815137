import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';
import { routes } from '../RouterPath';

export const createBlogBreadcrumbs = (brandName = '') : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Блог',
        url: routes.blog,
    },
    {
        key: '2',
        value: brandName,
        url: '',
    },
]);
