import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { GlobalStyle } from './components/GlobalStyle';
import { GlobalFontStyle } from './components/GlobalStyle/GlobalFontStyle';
import { Router } from './pages';
import { history } from './store/history';
import { store } from './store';
import { initAuth } from './actions/auth';
import AppLogout from './components/Logout';

store.dispatch(initAuth());

export const App = () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <AppLogout>
                {/* шрифты приложения */}
                <GlobalFontStyle />
                {/* стили приложения */}
                <GlobalStyle />
                <Router />
            </AppLogout>
        </ConnectedRouter>
    </Provider>
);
