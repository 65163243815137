import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { EditShopWithLoader } from './loaders';
import { getShop } from '../../../actions/shops/thunks/getShop';

export const EditShop = () => {
    const dispatch = useDispatch();
    const { shopId } = useParams<{shopId: string}>();

    useEffect(() => {
        dispatch(getShop(shopId));
    }, [shopId]);

    return <EditShopWithLoader />;
};
