import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_ROBOTS } from '../../actions/robots/thunks/getRobots';

const selectRoot = ({ robots }: RootState) => robots;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetRobotsLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_ROBOTS],
);

export const selectRobots = createSelector(
    selectRoot,
    ({ robots }) => robots,
);
