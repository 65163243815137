import { useLayoutEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../../../components/PageTitle';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import Switch from '../../../components/sw';
import { routes } from '../../../constants/RouterPath';
import { TypesSelect } from '../../../types/TypesSelect';
import { createShopBreadcrumbs } from '../../../constants/BreadCrumbs/createShopBreadcrumbs';
import { Checkbox } from '../../../components/Checkbox';
import { getCities } from '../../../actions/city';
import { selectCities } from '../../../selectors/cities';
import { useTypeSelector } from '../../../store';
import { SelectOption } from '../../../types/Select/SelectOption';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { createShop } from '../../../actions/shops/thunks/createShop';
import { CreateShopModel } from '../../../api/shops/addShop';
import { addModal } from '../../../actions/modal';
import { TypesModal } from '../../../types/TypesModalComponent';
import { getId } from '../../../helpers/generateId';
import * as S from './styles';
import { MapModel, ShopModel } from './constants';
import { PageTitles } from '../../../constants/PageTitles';

export const CreateShop = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const cities = useTypeSelector(selectCities);

    const citiesOptions = cities?.map(city => ({
        id: city.id,
        name: city.name,
    })) || [];

    useLayoutEffect(() => {
        dispatch(getCities());
    }, []);

    const handleSubmit = (values: ShopModel) => {
        const requestData: CreateShopModel = {
            externalId: values.externalId,
            address: values.address,
            workingHours: values.workingHours,
            phoneNumber: [],
            addons: [''],
            latitude: values?.latitude,
            longitude: values?.longitude,
            cityId: values?.cityId,
            comment: values.comment,
            isActive: values.status,
        };

        const addon: Array<'PURCHASE' | 'PURCHASE_EXCHANGE'> = [];

        if (values.PURCHASE) addon.push('PURCHASE');
        if (values.PURCHASE_EXCHANGE) addon.push('PURCHASE_EXCHANGE');

        if (values.phoneNumber1) requestData.phoneNumber.push(values.phoneNumber1);
        if (values.phoneNumber2) requestData.phoneNumber.push(values.phoneNumber2);

        requestData.addons = addon;

        dispatch(createShop(requestData as CreateShopModel));

        setTimeout(() => {
            history.goBack();
        }, 1000);
    };

    const initialValue: ShopModel = {
        externalId: undefined,
        address: '',
        workingHours: '',
        phoneNumber: [],
        phoneNumber1: '',
        phoneNumber2: '',
        addons: [],
        latitude: undefined,
        longitude: undefined,
        cityId: undefined,
        status: true,
        PURCHASE: true,
        PURCHASE_EXCHANGE: false,
        comment: '',
    };

    const phoneRegExp = /^[+]?[(]?[375]{1}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{8}$/;

    const validationSchema = yup.object().shape({
        phoneNumber1: yup
            .string()
            .matches(phoneRegExp, 'Неправильный формат номера')
            .required('Поле является обязательным'),
        phoneNumber2: yup
            .string()
            .matches(phoneRegExp, 'Неправильный формат номера'),
        externalId: yup.string().required('Поле является обязательным'),
        cityId: yup.string().required('Поле является обязательным'),
        latitude: yup.string().required('Обязательное поле'),
        longitude: yup.string().required('Обязательное поле'),
        address: yup.string().required('Поле является обязательным'),
        workingHours: yup.string().required('Поле является обязательным'),
    });

    const form = useFormik<ShopModel>({
        initialValues: initialValue,
        onSubmit: handleSubmit,
        validationSchema,
    });

    const handleSetMapData = async (mapData: MapModel) => {
        await form.setFieldValue('address', mapData.address);
        await form.setFieldValue('latitude', mapData.latitude);
        await form.setFieldValue('longitude', mapData.longitude);
        await form.setFieldValue('cityId', mapData.cityId);
    };

    const handleOpenModal = () => {
        dispatch(
            addModal({
                id: 1,
                type: TypesModal.CHOOSE_ADDRESS,
                onSuccess: handleSetMapData,
                data: {
                    address: form.values?.address,
                    cityId: form.values?.cityId,
                    latitude: form.values?.latitude,
                    longitude: form.values?.longitude,
                },
            }),
        );
    };

    const handleToggle = (value: boolean) => {
        form.setFieldValue('status', value);
    };

    const handleExit = () => {
        dispatch(addModal({
            id: getId()(),
            type: TypesModal.CONFIRM,
            message: { description: 'Вы действительно хотите выйти?' },
            onSuccessMessage: 'Выйти',
            onSuccess: () => history.push(routes.shops),
        }));
    };

    return (
        <S.Wrapper>
            <PageTitle>{PageTitles.SHOPS}</PageTitle>
            <Breadcrumbs breadcrumbs={createShopBreadcrumbs()} />
            <S.Root>
                <S.MainWrapper>
                    <S.StyledInput
                        name="externalId"
                        onChange={form.handleChange}
                        value={form.values.externalId}
                        placeholder="ID магазина в 1С*"
                        typeInput="text"
                        maxLength={255}
                        title="ID магазина в 1С*"
                        isTouched={form.touched.externalId}
                        error={form.errors.externalId}
                        count={form.submitCount}
                    />

                    <S.StyledSelect
                        name="cityId"
                        options={citiesOptions as unknown as SelectOption[]}
                        selectType={TypesSelect.DEFAULT}
                        value={form.values.cityId}
                        label="Город*"
                        onClick={form.setFieldValue}
                        title="Город*"
                        isTouched={form.touched.cityId}
                        error={form.errors.cityId}
                    />

                    <S.InpurWrapper>
                        <S.StyledInput
                            name="longitude"
                            onChange={form.handleChange}
                            value={form.values.longitude}
                            placeholder="Долгота*"
                            typeInput="number"
                            maxLength={255}
                            title="Долгота*"
                            isTouched={form.touched.longitude}
                            error={form.errors.longitude}
                            count={form.submitCount}
                        />

                        <S.StyledInput
                            name="latitude"
                            onChange={form.handleChange}
                            value={form.values.latitude}
                            placeholder="Широта*"
                            typeInput="number"
                            maxLength={255}
                            title="Широта*"
                            isTouched={form.touched.latitude}
                            error={form.errors.latitude}
                            count={form.submitCount}
                        />

                        <S.MapButton
                            onClick={handleOpenModal}
                            typeButton={TypesButton.TEXT}
                        >
                            Карта
                        </S.MapButton>
                    </S.InpurWrapper>

                    <S.StyledInput
                        name="address"
                        onChange={form.handleChange}
                        value={form.values.address}
                        placeholder="Адрес*"
                        typeInput="text"
                        maxLength={255}
                        title="Адрес*"
                        isTouched={form.touched.address}
                        error={form.errors.address}
                        count={form.submitCount}
                    />
                </S.MainWrapper>
                <S.SwitchBlock>
                    <S.IdInfo>Статус</S.IdInfo>
                    <S.SwitchWrapper>
                        <Switch
                            name="status"
                            value={form.values.status}
                            onChange={handleToggle}
                            type="checkbox"
                            title
                        />
                    </S.SwitchWrapper>
                </S.SwitchBlock>
                <S.MainWrapper>
                    <S.StyledInput
                        name="workingHours"
                        onChange={form.handleChange}
                        value={form.values.workingHours}
                        placeholder="Время работы*"
                        typeInput="text"
                        maxLength={255}
                        title="Время работы*"
                        isTouched={form.touched.workingHours}
                        error={form.errors.workingHours}
                        count={form.submitCount}
                    />

                    <S.StyledInput
                        name="phoneNumber1"
                        onChange={form.handleChange}
                        value={form.values.phoneNumber1}
                        placeholder="Номер телефона*"
                        typeInput="text"
                        maxLength={255}
                        title="Номер телефона*"
                        isTouched={form.touched.phoneNumber1}
                        error={form.errors.phoneNumber1}
                        count={form.submitCount}
                    />

                    <S.StyledInput
                        name="phoneNumber2"
                        onChange={form.handleChange}
                        value={form.values.phoneNumber2}
                        placeholder="Номер телефона"
                        typeInput="text"
                        maxLength={255}
                        title="Номер телефона"
                        isTouched={form.touched.phoneNumber2}
                        error={form.errors.phoneNumber2}
                    />
                    <S.StyledInput
                        name="comment"
                        onChange={form.handleChange}
                        value={form.values.comment}
                        placeholder="Комментарий"
                        typeInput="text"
                        maxLength={255}
                        title="Комментарий"
                    />
                </S.MainWrapper>
                <S.MainWrapper>
                    <S.IdInfo>Услуги</S.IdInfo>
                    <Checkbox
                        name="PURCHASE"
                        title="Скупка"
                        checked={form.values.PURCHASE}
                        onChange={form.handleChange}
                    />

                    <Checkbox
                        name="PURCHASE_EXCHANGE"
                        title="Скупка по принципу обмена"
                        checked={form.values.PURCHASE_EXCHANGE}
                        onChange={form.handleChange}
                    />
                </S.MainWrapper>

                {false && (
                    <S.ButtonWrapper>
                        <S.StyledButton typeButton={TypesButton.DELETE} size={SizesButton.M}>
                            <Icon
                                type={TypesIcon.TRASH_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                            />
                            Удалить
                        </S.StyledButton>
                        <div />
                    </S.ButtonWrapper>
                )}
                <S.ButtonRoot>
                    <S.ButtonWrapper>
                        <S.StyledButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={handleExit}
                        >
                            Отменить
                        </S.StyledButton>
                        <S.StyledButton
                            typeButton={TypesButton.PRIMARY}
                            size={SizesButton.M}
                            onClick={form.submitForm}
                        >
                            Сохранить изменения
                        </S.StyledButton>
                    </S.ButtonWrapper>
                </S.ButtonRoot>
            </S.Root>
        </S.Wrapper>
    );
};
