import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as searchSettingsApi from '../../../api/landings';
import { SearchSettingsContract } from '../../../api/landings/getLandings';

export const GET_SEARCH_SETTINGS = 'searchSettings/getsearchSettings';

export const getSearchSettings = createAsyncThunk(
    GET_SEARCH_SETTINGS,
    createRequest({
        type: GET_SEARCH_SETTINGS,
        loader: async (data: SearchSettingsContract['pathParams']) => searchSettingsApi.getSearchSettings(data),
    }),
);
