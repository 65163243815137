import { css } from 'styled-components';
import { colors } from '../../constants/Colors';
import { TypesButton } from '../../constants/ButtonTypes';

const colorByType: Record<string, string> = {
    [TypesButton.PRIMARY]: colors.white,
    [TypesButton.SECONDARY]: colors.grey20,
    [TypesButton.DELETE]: colors.grey20,
};

export const applyDisabledColor = ({
    typeButton,
    disabled,
}: {
  typeButton: TypesButton;
  disabled?: boolean;
}) => disabled
  && css`
    color: ${colorByType[typeButton]};
  `;
