import styled from 'styled-components';
import { ChangeEvent, useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { useDispatch } from 'react-redux';
import usePagination from '@mui/material/usePagination';
import { useHistory } from 'react-router';
import { SearchBar } from '../../components/SearchBar';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { units } from '../../helpers/styles/units';
import { Button } from '../../components/Button';
import { PageTitle } from '../../components/PageTitle';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../store';
import { Icon } from '../../components/Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { selectSearchData } from '../../selectors/search';
import { SearchTable } from '../../components/SearchTable';
import { AddProductsPromotions } from '../../components/AddProductsToPromotions';
import { selectSearchSettings } from '../../selectors/landings';
import { getLikedList } from '../../api/landings/getLandings';
import { getSearchSettings, setSearchSettingsLiked } from '../../actions/landings/thunks';
import { addNotification } from '../../actions/notifications';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { GetPromotionContract } from '../../types/Endpoints/sales/PromitionsContracts';
import { useDebounce } from '../../hooks/useDebounce';
import { Pagination } from '../../components/Pagination';
import { routes } from '../../constants/RouterPath';
import { FilterButton } from '../../components/Button/filterButton';
import { RadioButton } from '../../components/RadioButton';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
  padding-bottom: ${units(30)};

  & ul.MuiPagination-ul {
    justify-content: center;

    & .Mui-selected {
        background: none;
        border-radius: 0;
        border-bottom: 1px solid black;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

const StyledPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  max-width: ${units(137)};
  width: 100%;
  height: ${units(24)};
`;

const StyledSearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
  margin-bottom: ${units(28)};
`;

const StyledCalendarWrapper = styled.div`
  position: relative;
`;

const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: ${units(337)};
    padding: ${units(27)};
    box-shadow: none;
  }

  & .MuiBackdrop-root.MuiModal-backdrop {
    background: ${colors.grey10};
    opacity: 0.4 !important;
  }
`;

const StyledDrawerRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${units(8)};
`;

const StyledModalTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 1px;

  ${typography(20)};
`;

const StyledModalDescription = styled.div`
  letter-spacing: 1px;
  color: #939393;
  margin-top: ${units(8)};
  ${typography(5)};
`;

const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
      hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
      hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${units(8)};
  padding: ${units(12)} ${units(27)};
  width: ${units(324)};

  background-color: ${colors.light};
  border-top: 1px solid ${colors.grey40};

  position: fixed;
  bottom: 0;
  right: 25px;
  z-index: 1000;
`;

const StyledFilterWrapper = styled.div`
    position: relative;
    margin-left: auto;
`;

const StyledFilterWindow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: absolute;
    top: 65px;
    right: 0;
    box-shadow: 0 0 20px 0 rgba(57, 56, 54, 0.20) !important;
    border-radius: 0;
    background-color: #F7F7F7;
    padding: 32px 40px;
    z-index: 1000;
`;

const StyledCheckBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const StyledIdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};

  ${typography(10)};
`;

export enum StatusSales {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

const START_PAGE = 1;
const PAGE_SIZE = 50;

export const SearchSettingsContent = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const searchString = useTypeSelector(selectSearchData);
    const [searchValue] = useDebounce(300, searchString || '');
    const [page, setPage] = useState<{current: number} | null>(null);
    const [state, setState] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState<string | undefined>();

    const [selectedProducts, setSelectedProducts] = useState<GetPromotionContract['responseBody']['products']>([]);

    const [selectedProductsInModal, setSelectedProductsInModal] = useState<GetPromotionContract['responseBody']['products']>([]);
    // const [products, setProducts] = useState<LikedList[]>([]);

    const { searchSettings, searchLikedSettings } = useTypeSelector(selectSearchSettings);

    const handleOpenFilter = () => {
        setIsFilterOpen(v => !v);
    };

    // const handleOpenModal = (item?: SearchSettingItem) => {
    //     dispatch(addModal({
    //         id: getId()(),
    //         type: TypesModal.ADD_SEARCH_ITEM,
    //         message: { description: item ? 'Редактировать' : 'Добавить' },
    //         data: item,
    //         onSuccessMessage: 'Сохранить изменения',
    //         onSuccess: () => {
    //             dispatch(getSearchSettings({ page: 1, size: 50 }));

    //             dispatch(addNotification({
    //                 type: TypesSnackbars.SUCCESS,
    //                 message: {
    //                     title: 'Изменения сохранены',
    //                     description: '',
    //                 },
    //                 icon: TypesIcon.SNACK_SUCCES,
    //             }));
    //         },
    //     }));
    // };

    const { items } = usePagination({
        page: page?.current || START_PAGE,
        count: Math.ceil((searchSettings?.count || 0) / PAGE_SIZE),
        boundaryCount: 0,
        siblingCount: 2,
        onChange: (_event: ChangeEvent<unknown>, current: number) => setPage({ current }),
    });

    useEffect(() => {
        if (page) {
            const status = !statusFilter ? undefined : statusFilter === StatusSales.ACTIVE;
            dispatch(getSearchSettings({
                page: page.current, size: PAGE_SIZE, query: searchValue, isShowForSearch: status,
            }));

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [page]);

    const saveAndOffDrawer = () => {
        if (selectedProductsInModal.length <= 12) {
            setState(false);
            setSelectedProducts(selectedProductsInModal);
            dispatch(setSearchSettingsLiked(selectedProductsInModal.map(el => el.id)));
        } else {
            setState(false);
            dispatch(addNotification({
                type: TypesSnackbars.ERROR,
                message: {
                    title: 'Можно добавить до двенадцати товаров',
                    description: '',
                },
                icon: TypesIcon.SNACK_ERROR,
            }));
        }
    };

    const onDrawer = () => {
        setState(true);
        setSelectedProductsInModal(selectedProducts);
    };

    const offDrawer = () => {
        setState(false);
        setSelectedProductsInModal([]);
    };

    useEffect(() => {
        const reqData = searchLikedSettings?.map(el => +el.productId) || [];

        if (searchLikedSettings && reqData.length > 0) {
            getLikedList(reqData).then(res => {
                setSelectedProducts(res as GetPromotionContract['responseBody']['products']);
            }).catch(() => setSelectedProducts([]));
        }
    }, [searchLikedSettings]);

    useEffect(() => {
        setPage({ current: START_PAGE });
    }, [searchValue, statusFilter]);

    return (
        <StyledWrapper>
            <StyledPageTitle>Посадочные страницы</StyledPageTitle>
            <StyledPanelWrapper>
                <StyledSearchBarWrapper>
                    <SearchBar />
                </StyledSearchBarWrapper>
                <StyledFilterWrapper>
                    <FilterButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOpenFilter}
                    />
                    {isFilterOpen && (
                        <StyledFilterWindow>
                            <StyledCheckBoxWrapper>
                                <StyledIdInfo>Статус</StyledIdInfo>
                                <RadioButton
                                    name="isShowOnSite"
                                    title="Все"
                                    isChecked={statusFilter === undefined}
                                    onChange={() => setStatusFilter(undefined)}
                                />
                                <RadioButton
                                    name="isShowOnSite"
                                    title="Активные"
                                    isChecked={statusFilter === StatusSales.ACTIVE}
                                    onChange={() => setStatusFilter(StatusSales.ACTIVE)}
                                />
                                <RadioButton
                                    name="isShowOnSite"
                                    title="Неактивные"
                                    isChecked={statusFilter === StatusSales.INACTIVE}
                                    onChange={() => setStatusFilter(StatusSales.INACTIVE)}
                                />
                            </StyledCheckBoxWrapper>
                        </StyledFilterWindow>
                    )}
                </StyledFilterWrapper>
                <StyledCalendarWrapper>
                    <StyledButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.S}
                        onClick={onDrawer}
                    >
                        Загрузить товары
                    </StyledButton>
                </StyledCalendarWrapper>

                <StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => history.push(routes.search_settings_create)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </StyledButton>
            </StyledPanelWrapper>
            <StyledRoot>
                <SearchTable items={searchSettings?.data || []} />
                {searchSettings && !(searchSettings.isLast && searchSettings.page === START_PAGE) && <Pagination items={items} />}
            </StyledRoot>
            <StyledDrawer
                anchor="right"
                open={state}
                onClose={offDrawer}
            >
                <StyledDrawerRoot>
                    <StyledHeading>
                        <StyledModalTitle>Загрузить товары</StyledModalTitle>
                        <StyledCloseButton onClick={offDrawer}>
                            <Icon
                                type={TypesIcon.CLOSE_BIG}
                                color={colors.grey20}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                            />
                        </StyledCloseButton>
                    </StyledHeading>
                    <StyledModalDescription>{`Добавлено ${selectedProductsInModal.length} товаров из 12`}</StyledModalDescription>
                    <AddProductsPromotions
                        selectedProducts={selectedProductsInModal}
                        setSelectedProducts={setSelectedProductsInModal}
                    />

                    <ButtonWrapper>
                        <StyledButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={offDrawer}
                        >
                            Отменить
                        </StyledButton>
                        <StyledButton
                            typeButton={TypesButton.PRIMARY}
                            size={SizesButton.M}
                            onClick={saveAndOffDrawer}
                        >
                            Сохранить изменения
                        </StyledButton>
                    </ButtonWrapper>
                </StyledDrawerRoot>
            </StyledDrawer>
        </StyledWrapper>
    );
};
