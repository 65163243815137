import { request } from '../../helpers/api/request';
import { IPrice } from '../../types/Endpoints/prices/PricesContracts';

const URL = 'admin/products/prices';

export const updatePrice = async (dataUpdated: IPrice[]) => {
    await request({
        url: URL,
        method: 'PATCH',
        data: dataUpdated,
    });

    return dataUpdated;
};
