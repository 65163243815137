import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { Button } from '../../components/Button';
import { PageTitle } from '../../components/PageTitle';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { colors } from '../../constants/Colors';

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${units(20)} 0;
  gap: ${units(20)};
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

export const StyledPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
`;

export const StyledPanelSecondWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${units(8)};
`;

export const StyledButtonClear = styled(Button)`
  width: fit-content;
  height: fit-content;
  padding: 0;
`;

export const StyledSearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

export const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
  margin-bottom: ${units(28)};
`;

export const StyledFilterWrapper = styled.div`
  position: relative;
`;

export const StyledFilterWindow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(16)};
  position: absolute;
  top: ${units(30)};
  right: 0;
  box-shadow: 0 0 ${units(10)} 0 rgba(57, 56, 54, 0.20) !important;
  border-radius: 0;
  background-color: ${colors.grey60};
  padding: ${units(16)} ${units(20)};
  width: max-content;
  max-width: ${units(165)};
`;

export const StyledCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(8)};
`;

export const StyledIdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};

  ${typography(10)};
`;

export const StyledButton = styled(Button)<{color?: string}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  max-width: ${units(137)};
  width: 100%;
  height: ${units(24)};
  color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
`;
