import { request } from '../../helpers/api/request';
import { EditCityItem } from '../../types/Models/City/City';
import { EditCityItemContract } from '../../types/Endpoints/cities/GetCitiesContract';

export const createCity = async (requestData: EditCityItem) => {
    const { data } = await request<EditCityItemContract>({
        url: 'admin/cities',
        method: 'POST',
        data: requestData,
    });

    return data;
};
