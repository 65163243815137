import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';
import { ButtonProps } from './index';

const hoverByType: Record<string, string> = {
    [TypesButton.SECONDARY]: colors.gold,
    [TypesButton.DELETE]: colors.vine,
};

export const applyHoverColor = ({ typeButton, disabled }: Pick<ButtonProps, 'typeButton' | 'disabled'>) => (
    !disabled
        ? `
          &:hover {
            color: ${hoverByType[typeButton]}};
          }

          &:hover svg {
            filter: ${typeButton === TypesButton.SECONDARY ? 'brightness(0) saturate(100%) invert(74%) sepia(32%) saturate(544%) hue-rotate(3deg) brightness(95%) contrast(87%);' : ''};
          }

          &:hover svg {
            filter: ${typeButton === TypesButton.DELETE ? 'brightness(0) saturate(100%) invert(27%) sepia(16%) saturate(7412%) hue-rotate(340deg) brightness(95%) contrast(84%);' : ''};
          }
        `
        : '');
