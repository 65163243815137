import { useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import { GoodsContentWithLoader } from './loaders';
import { getCatalogCategories } from '../../actions/category';
import { getPrices } from '../../actions/prices';
import { useTypeSelector } from '../../store';
import { selectCategories } from '../../selectors/catalog';
import { selectPrices } from '../../selectors/prices';

export const Goods: FC = () => {
    const dispatch = useDispatch();
    const categories = useTypeSelector(selectCategories);
    const prices = useTypeSelector(selectPrices);

    useEffect(() => {
        if (!categories) {
            dispatch(getCatalogCategories());
        }

        if (!prices) {
            dispatch(getPrices());
        }
    }, []);

    return <GoodsContentWithLoader />;
};
