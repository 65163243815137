import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as shopsApi from '../../../api/shops';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';

export const DELETE_SHOP = 'shops/deleteShop';

const onSuccess = (): Thunk => () => {
    history.push(routes.shops);
};

export const deleteShop = createAsyncThunk(
    DELETE_SHOP,
    createRequest({
        type: DELETE_SHOP,
        loader: async (id: number) => shopsApi.deleteShop(id),
        onSuccess,
    }),
);
