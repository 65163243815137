import { createAsyncThunk } from '@reduxjs/toolkit';
import { BannerItemForEdit } from '../../../types/Models/MainPage/MainPageModel';
import { createRequest } from '../../../helpers/api/createRequest';
import * as mainPageApi from '../../../api/mainPage';
import { getWebBanners } from './getWebBanners';
import { Thunk } from '../../../helpers/redux/Thunk';
import { getMobileBanners } from './getMobileBanners';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const EDIT_BANNER_ITEM = 'mainPage/editBannerItem';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(getWebBanners());
    dispatch(getMobileBanners());

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editBannerItem = createAsyncThunk(
    EDIT_BANNER_ITEM,
    createRequest({
        type: EDIT_BANNER_ITEM,
        loader: async (data: {key: number, newData: BannerItemForEdit}) => mainPageApi.editBannerItem(data),
        onSuccess,
    }),
);
