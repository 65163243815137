import { routes } from '../RouterPath';
import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const filterCloudBreadcrumbs = (filterName = '') : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Каталог',
        url: '',
    },
    {
        key: '2',
        value: 'Облако фильтров',
        url: routes.filter_cloud,
    },
    {
        key: '3',
        value: filterName,
        url: '',
    },
]);
