import { request } from '../../helpers/api/request';
import { GetOrdersStatusContract } from '../../types/Endpoints/orders/OrdersContracts';

export const getOrdersStatus = async () => {
    const { data } = await request<GetOrdersStatusContract>({
        url: 'admin/orders/statuses',
    });

    return data;
};
