import { createReducer } from '@reduxjs/toolkit';
import { JewelryExchangesType } from '../../types/Endpoints/jewelryExchanges/GetJawelryExchangesContract';
import { getJewelryExchanges } from '../../actions/jewelryExchanges';

export interface JewelryExchangesModel {
    jewelryExchanges?: JewelryExchangesType;
}

export const jewelryExchanges = createReducer<JewelryExchangesModel>({}, builder => {
    builder.addCase(getJewelryExchanges.fulfilled, (state, { payload }) => ({
        ...state,
        jewelryExchanges: payload,
    }));
});
