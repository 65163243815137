/* eslint-disable react/no-array-index-key */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { units } from '../../helpers/styles/units';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Shop } from '../../types/Models/Shops/shop';
import { getShopRegion } from '../../helpers/shops/getShopRegion';
import { Addons, getAddon } from '../../helpers/shops/getAddon';
import { routes } from '../../constants/RouterPath';

interface TableProps {
  className?: string;
  shops?: Shop[];
}

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
        '& .cell-phone': {
            maxWidth: '140px',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },

        '& .cell-services': {
            display: 'flex',
            padding: '16px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '8px',
            flex: '1 0 0',
            alignSelf: 'stretch',
        },
    },
};

const StyledCell = styled(TableCell)`
  .active {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};

    background-color: rgba(87, 198, 118, 0.05);

    & div {
      background-color: #6db180;
    }
  }

  .inactive {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};

    background-color: rgba(231, 72, 72, 0.05);

    & div {
      background-color: #e74848;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
`;

const StyledStatusInfo = styled.div`
  width: ${units(4)};
  height: ${units(4)};
`;

const StyledEditButton = styled(EditButton)`
  margin: 0 auto;
`;

export const ShopsTable = ({ className, shops }: TableProps): JSX.Element => {
    const history = useHistory();
    return (
        <TableContainer className={className} component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell>ID из 1C</TableCell>
                        <TableCell align="left">Область</TableCell>
                        <TableCell align="left">Адрес</TableCell>
                        <TableCell align="left">Время работы</TableCell>
                        <TableCell align="left">Телефон</TableCell>
                        <TableCell align="left">Услуги</TableCell>
                        <TableCell align="left">Статус</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={[styles.root, styles.body]}>
                    {shops
            && shops.map(row => (
                <TableRow
                    key={row.id}
                    sx={{ 'td, th': { border: 0, verticalAlign: 'top' } }}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        className="text-grey"
                        sx={{ width: '10%' }}
                    >
                        {row.externalId}
                    </TableCell>
                    <TableCell
                        align="left"
                        className="cell-medium"
                        sx={{ width: '10%' }}
                    >
                        {row.city ? getShopRegion(row.city.region) : row.cityId}
                    </TableCell>
                    <TableCell align="left">{row.address}</TableCell>
                    <TableCell align="left">{row.workingHours}</TableCell>
                    <TableCell align="left">
                        {row.phoneNumber.map((number: string, index: number) => (
                            <div key={`${number}-${index}`}>{number}</div>
                        ))}
                    </TableCell>
                    <TableCell align="left" className="text-grey cell-services">
                        {row.addons.map((el: string) => (
                            <div key={el}>
                                {getAddon(el as unknown as keyof typeof Addons)}
                            </div>
                        ))}
                    </TableCell>
                    <StyledCell align="left" sx={{ width: '10%' }}>
                        {!row.isActive ? (
                            <StyledDiv className="inactive">
                                <StyledStatusInfo />
                                <span>Отключен</span>
                            </StyledDiv>
                        ) : (
                            <StyledDiv className="active">
                                <StyledStatusInfo /> <span>Активен</span>
                            </StyledDiv>
                        )}
                    </StyledCell>
                    <TableCell sx={{ width: '5%' }}>
                        <StyledEditButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={() => history.push(`${routes.edit_shop}/${row.id}`)}
                        />
                    </TableCell>
                </TableRow>
            ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
