import { request } from '../../helpers/api/request';
import { IGetPrices } from '../../types/Endpoints/prices/PricesContracts';

const URL = 'admin/products/prices';

export const getPrices = async () => {
    const { data } = await request<IGetPrices>({
        url: URL,
    });

    return data;
};
