import { request } from '../../helpers/api/request';
import { CalculatorContract } from './getCalculator';

export const getCalculatorAll = async () => {
    const { data } = await request<CalculatorContract>({
        url: 'calculator/',
    });

    return data;
};
