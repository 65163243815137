import { routes } from '../RouterPath';
import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const createUserBreadcrumbs = () : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Пользователи',
        url: routes.users,
    },
    {
        key: '2',
        value: 'Добавить',
        url: '',
    },
]);
