import { CreateCatalogSubCategoryContract, EditCatalogSubCategoryItem } from '../../types/Endpoints/categories/CategoriesContracts';
import { request } from '../../helpers/api/request';

export const createSubCategory = async ({ categoryId, ...requestData }: EditCatalogSubCategoryItem) => {
    const { data } = await request<CreateCatalogSubCategoryContract>({
        url: `admin/categories/${categoryId}/subcategories`,
        method: 'POST',
        data: requestData,
    });

    return data;
};
