import { css, FlattenSimpleInterpolation } from 'styled-components';

const fontSize: Record<number, FlattenSimpleInterpolation> = {
    0: css`
      font-size: 12px;
      line-height: 24px;
    `,
    1: css`
    font-size: 12px;
    line-height: 16px;
    `,
    5: css`
      font-size: 14px;
      line-height: 18px;
    `,
    10: css`
      font-size: 16px;
      line-height: 24px;
    `,
    15: css`
      font-size: 18px;
      line-height: 24px;
    `,
    20: css`
      font-size: 20px;
      line-height: 24px;
    `,
    25: css`
      font-size: 24px;
      line-height: 32px;
    `,
    30: css`
      font-size: 32px;
      line-height: 40px;
    `,
    35: css`
      font-size: 48px;
      line-height: 56px;
    `,
    40: css`
      font-size: 92px;
      line-height: 123px;
    `,
};

export const typography = (step: number) => fontSize[step];
