import { useSelector } from 'react-redux';
import { LoginDialog } from './LoginDialog';
import { RedirectWithQuery } from '../../components/RedirectWithQuery';
import { routes } from '../../constants/RouterPath';
import { selectIsLogin, selectUserLoaders } from '../../selectors/users';
import { Preloader } from '../../components/Preloader';
import { LoaderState } from '../../types/Loaders/LoaderState';

export const LoginPage = () => {
    const isLogin = useSelector(selectIsLogin);
    const userAuthorizedLoader = useSelector(selectUserLoaders);

    if ([userAuthorizedLoader?.state].includes(LoaderState.PENDING)) {
        return <Preloader />;
    }

    if (isLogin) {
        return <RedirectWithQuery to={`${routes.site_body}`} />;
    }

    return <LoginDialog />;
};
