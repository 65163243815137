import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as ordersApi from '../../../api/orders';
import { OrdersContract } from '../../../types/Endpoints/orders/OrdersContracts';

export const GET_ORDERS = 'orders/getOrders';

export const getOrders = createAsyncThunk(
    GET_ORDERS,
    createRequest({
        type: GET_ORDERS,
        loader: async (data: OrdersContract['pathParams']) => ordersApi.getOrders(data),
        showFailNotification: false,
    }),
);
