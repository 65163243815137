import { EditCatalogSubCategoryContract, EditCatalogSubCategoryItem } from '../../types/Endpoints/categories/CategoriesContracts';
import { request } from '../../helpers/api/request';

export const editSubCategory = async ({ categoryId, ...requestData }: EditCatalogSubCategoryItem) => {
    const { data } = await request<EditCatalogSubCategoryContract>({
        url: `admin/categories/${categoryId}/subcategories/${requestData.id}`,
        method: 'PUT',
        data: requestData,
    });

    return data;
};
