import { useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { PhotosGoodsWithLoader } from './loaders';
import { getPhotosThink } from '../../../../actions/goods/thunks/getPhotos.ts';

export const PhotosGoods: FC = () => {
    const dispatch = useDispatch();
    const { goodsId } = useParams<{goodsId: string}>();

    useEffect(() => {
        dispatch(getPhotosThink(goodsId));
    }, []);

    return <PhotosGoodsWithLoader />;
};
