import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as citiesApi from '../../../api/cities';

export const GET_CITIES_REGION = 'cities/getCitiesByRegion';

export const getCitiesByRegion = createAsyncThunk(
    GET_CITIES_REGION,
    createRequest({
        type: GET_CITIES_REGION,
        loader: async (id: string) => citiesApi.getCitiesByRegion(id),
    }),
);
