import { request } from '../../helpers/api/request';
import { GetMainPageWebContract } from '../../types/Endpoints/mainPage/MainPageContracts';

export const getWebBanners = async () => {
    const { data } = await request<GetMainPageWebContract>({
        url: 'admin/banners/web/full',
    });

    return data.data;
};
