import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../../../constants/Colors';
import { units } from '../../../helpers/styles/units';
import { Modal } from '../../../types/Models/Modal/Modal';
import { removeModal } from '../../../actions/modal';
import { TypesIcon } from '../../../types/TypesIcon';
import { MapViewer } from './mapViewer';
import { Title } from '../../../components/Title';
import { Divider } from '../../../components/Divider';
import { IconBig } from '../../../components/Icon/IconBig';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 648px;
  min-height: 360px;

  background-color: ${colors.white};

  border: 1px solid ${colors.grayscale05};
  border-radius: 16px;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${units(6)};
`;

const StyledIcon = styled(IconBig)`
  cursor: pointer;
  transition: stroke .4s ease;
  
  :hover {
    stroke: ${colors.grayscale80};
  }
`;

export const MapModal = ({ id, onClose, data }: Modal) => {
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    return (
        <StyledRoot>
            <StyledTitleWrapper>
                <Title>
                    Просмотр карты
                </Title>
                <StyledIcon
                    onClick={handleClose}
                    type={TypesIcon.CLOSE_BIG}
                    color={colors.grayscale40}
                />
            </StyledTitleWrapper>
            <Divider />
            <MapViewer image={data?.image as string} />
        </StyledRoot>
    );
};
