import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as certificatesApi from '../../../api/certificates';
import { CertificatesItem } from '../../../types/Endpoints/certificates/CertificatesContracts';

export const GET_CERTIFICATES = 'certificates/getCertificates';

export const getCertificates = createAsyncThunk<CertificatesItem[]>(
    GET_CERTIFICATES,
    createRequest({
        type: GET_CERTIFICATES,
        loader: async () => certificatesApi.getCertificates(),
    }),
);
