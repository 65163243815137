import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { units } from '../../../helpers/styles/units';
import { TextEditor } from '../../../components/TextEditor';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { Button } from '../../../components/Button';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TextImageBlockContract } from '../../../types/Endpoints/blog/BlogContracts';
import { UploadPhotoWithMiniature } from '../../../components/UploadPhotoWithMiniature';
import { UploadFile } from '../../../types/Endpoints/files/FilesContract';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../../components/Icon';
import { TextAndImageContract } from '../../../types/Endpoints/blog/BlogCRUDContracts';
import { addModal } from '../../../actions/modal';
import { getId } from '../../../helpers/generateId';
import { TypesModal } from '../../../types/TypesModalComponent';

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledTextEditor = styled(TextEditor)`
  & > p {
    margin-bottom: ${units(8)};
    
    font-weight: ${FontWeight.MEDIUM};
  }

  & .ql-toolbar.ql-snow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: ${units(28)};
    padding: 6px 16px;

    border-radius: 0px;
    border-color: #c7c7c7;
  }

  & .ql-snow .ql-editor h3 {
    text-align: center;
  }

  & .ql-container.ql-snow {
    border-radius: 0px;
    border-color: #c7c7c7;

    & .ql-editor {
      min-height: ${units(28)};
      padding: 16px 24px;
    }
  }
`;

const StyledDeleteButton = styled(Button)`
  width: fit-content;
`;

export const TextImageBlock = ({
    updateTextImageBlock,
    deleteTextImageBlock,
    item,
}: {
    updateTextImageBlock: (text: string, image: UploadFile, order: number, id: number) => void
    deleteTextImageBlock: (order: number) => void
    item: TextAndImageContract<UploadFile>,
}): JSX.Element => {
    const dispatch = useDispatch();
    const [valueText, setValueText] = useState(item?.text || '');
    const [valueImage, setValueImage] = useState<UploadFile>(item?.blogTextAndImageFileId || {});

    const onChangeValue = (_field: string, value?: string) => {
    // eslint-disable-next-line no-unused-expressions
        value && setValueText(value);
    };
    const onChangeImage = (_field: string, value?: UploadFile): void => {
    // eslint-disable-next-line no-unused-expressions
        value && setValueImage(value);
    };

    useEffect(() => {
        updateTextImageBlock(valueText, valueImage, item.order, item.id || 100);
    }, [valueText, valueImage]);

    return (
        <StyledMainWrapper>
            <StyledTextEditor
                onChange={onChangeValue}
                valueEditor={valueText}
                name="blogTextAndImageItem"
                title="Текст и изображение"
                withoutPictures={false}
            />
            <UploadPhotoWithMiniature
                name={`headerImageMobileFileTextAndImageItem${Math.random()}`}
                format="3:1"
                onChange={onChangeImage}
                value={valueImage}
                maxSize={2}
            />
            <StyledDeleteButton
                typeButton={TypesButton.DELETE}
                size={SizesButton.M}
                onClick={() => {
                    dispatch(
                        addModal({
                            id: getId()(),
                            type: TypesModal.CONFIRM,
                            message: {
                                description:
                                'Вы действительно хотите удалить?',
                            },
                            onSuccessMessage: 'Удалить',
                            onSuccess: () => deleteTextImageBlock(item.order),
                            withCloseIcon: true,
                        }),
                    );
                }}
            >
                <Icon
                    type={TypesIcon.TRASH_BIG}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                />
                Удалить раздел
            </StyledDeleteButton>
        </StyledMainWrapper>
    );
};
