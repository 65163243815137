import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { fontName } from '../GlobalStyle';
import { units } from '../../helpers/styles/units';

interface TableProps {
  colSpan: number;
}

const styles = {
    row: {
        '& .not-found': {
            fontSize: `${units(8)}`,
            lineHeight: `${units(12)}`,
            fontFamily: fontName,
        },
    },
};

export const EmptyRow = ({ colSpan }: TableProps) => (
    <TableRow sx={{ 'td, th': { border: 0 }, ...styles.row }}>
        <TableCell align="left" className="not-found" colSpan={colSpan}>
            По вашему запросу ничего не найдено
        </TableCell>
    </TableRow>
);
