import { createReducer } from '@reduxjs/toolkit';
import { getShops } from '../../actions/shops/thunks/getShops';
import { Shop } from '../../types/Models/Shops/shop';
import { getShop } from '../../actions/shops/thunks/getShop';
import { getShopsWithParams } from '../../actions/shops/thunks';

export interface ShopsModel {
  shops?: Shop[];
  selectedShop?: Shop;
}

export const shops = createReducer<ShopsModel>({ }, builder => {
    builder.addCase(getShops.fulfilled, (state, { payload }) => ({
        ...state,
        shops: payload,
    }));
    builder.addCase(getShop.fulfilled, (state, { payload }) => ({
        ...state,
        selectedShop: payload,
    }));
    builder.addCase(getShopsWithParams.fulfilled, (state, { payload }) => ({
        ...state,
        shops: payload,
    }));
});
