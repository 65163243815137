import { request } from '../../helpers/api/request';
import { DataEntity } from '../../types/Endpoints/jewelryExchanges/GetJawelryExchangesContract';

export interface EditJewelryExchanges {
    id: number;
    data: {
        data: DataEntity[];
    }
}

export const updateJewelryExchanges = async ({ id, ...requestData }: EditJewelryExchanges) => {
    const { data } = await request({
        url: `admin/jewelry-exchanges/${id}`,
        method: 'PUT',
        data: requestData.data,
    });

    return data;
};
