import styled, { css } from 'styled-components';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { typography } from '../../../helpers/styles/typography';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';

const StyledText = styled.div`
  font-weight: ${FontWeight.REGULAR};
  ${typography(1)};

  display: flex;
  flex-direction: column;
  gap: ${units(4)};

  ${props => props.className === 'inactive'
    && css`
      color: ${colors.grey20};
    `}
`;

const StyledProperties = styled(StyledText)`
  color: ${colors.grey20};
  flex-direction: row;
  gap: ${units(4)};
  flex-wrap: wrap;
`;

const StyledSeparator = styled.span`
  color: ${colors.grey40};
`;

export const ProductProperties = ({
    isActive,
    size,
    weight,
    insert,
}: {
  isActive: boolean;
  size?: string;
  weight?: string;
  insert?: string;
}) => (
    <StyledProperties className={isActive ? '' : 'inactive'}>
        {size && !!Number(size) ? `Размер: ${size}` : ''}

        {size
            && !!Number(size)
            && ((weight && !!Number(weight)) || insert) && (
            <StyledSeparator>
                |
            </StyledSeparator>
        )}

        {weight && !!Number(weight) ? `Вес: ${weight}` : ''}

        {weight && !!Number(weight) && insert && (
            <StyledSeparator>
                |
            </StyledSeparator>
        )}

        {insert ? `Вставка: ${insert}` : ''}
    </StyledProperties>
);
