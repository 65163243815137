import { CreateCatalogCategoryContract, CreateCategoryItem } from '../../types/Endpoints/categories/CategoriesContracts';
import { request } from '../../helpers/api/request';

export const createCategory = async (requestData: CreateCategoryItem) => {
    const { data } = await request<CreateCatalogCategoryContract>({
        url: 'admin/categories',
        method: 'POST',
        data: requestData,
    });

    return data;
};
