/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { typography } from '../../helpers/styles/typography';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { TypesIcon } from '../../types/TypesIcon';
import { Icon } from '../Icon';
import { MenuTabsContent } from '../MenuTabsContent';
import { SubCategoriesItem } from '../../types/Endpoints/categories/CategoriesContracts';
import { getEmptySubcategory } from '../../helpers/category';

export interface TabData {
    name: string,
    content: JSX.Element,
}

export interface TabsProps {
    data: SubCategoriesItem[],
    active: number,
    setActive: (i: number) => void,
    categoryName: string,
}

const StyledPageData = styled.div`
    width: 100%;
    height: 1000px;
    display: flex;
    justify-content: space-between;
    gap: ${units(108)};
 
`;

const StyledTabs = styled.div`
    position: fixed;
    top: ${units(64)};
    right: ${units(49)};
    display: flex;
    flex-direction: column;
    gap: ${units(16)};
`;

const StyledTab = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: ${units(4)};
    padding-left: ${units(9)};
    min-width: ${units(122)};

    font-weight: ${props => (props.className === 'active' ? FontWeight.BOLD : FontWeight.REGULAR)};

    background: none;    
    -webkit-apperace: none;
    outline: none;
    cursor: pointer;

    ${typography(10)};  

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0px;
      transform: translate(-50%, -50%);
      width: ${units(4)};
      height: ${units(4)};
      background: ${props => (props.className === 'active' ? colors.gold : '#E7E6E6')};
    }

    &:not(:last-child):after {
      position: absolute;
      content: '';
      bottom: -15px;
      left: 0px;
      transform: translate(-50%, -50%);
      width: 1px;
      height: ${units(8)};
      background: ${colors.grey40};
    }
`;

const AddTabButton = styled.button`    
    display: flex;
    align-items: center;
    gap: ${units(8)};     
    min-width: ${units(122)};
    border: none;    

    font-weight: ${FontWeight.BOLD};

    background: none;    
    -webkit-apperace: none;
    outline: none;
    cursor: pointer;

    ${typography(10)}; 
`;

const TabsWrapper = styled.div`
    padding-left: ${units(4)};
`;

const StyledFlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: ${units(350)};
`;

export const MenuTabs = ({
    data, active, setActive, categoryName,
}: TabsProps) => {
    const [tabs, setTabs] = useState<SubCategoriesItem[]>(data);

    const handleAddSubcategory = (value: SubCategoriesItem) => {
        setTabs(prev => [...prev, value]);
    };

    const handleGoResetTabs = () => {
        setActive(0);
        setTabs(data);
    };

    useEffect(() => {
        setTabs(data);
    }, [data]);
    return (
        <StyledPageData>
            <StyledFlexDiv>
                <MenuTabsContent data={tabs[active]} tabsCount={tabs?.length} handleGoResetTabs={handleGoResetTabs} categoryName={categoryName} />
            </StyledFlexDiv>
            <StyledTabs className="tabs">
                <TabsWrapper>
                    {tabs?.map((item: any, i: number) => (
                        <StyledTab
                            key={`${item}-${i}`}
                            className={`${i === active ? 'active' : ''}`}
                            onClick={() => setActive(i)}
                        >
                            {item.name}
                        </StyledTab>
                    ))}
                </TabsWrapper>
                <div>
                    {tabs && tabs?.length <= 6 && (
                        <AddTabButton
                            type="button"
                            className="active"
                            onClick={() => {
                                handleAddSubcategory(getEmptySubcategory(tabs.length));
                                setActive(tabs?.length);
                            }}
                        >
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                viewBox="0 0 24 24"
                                width="24px"
                                height="24px"
                            /> Добавить подкатегорию
                        </AddTabButton>
                    )}

                </div>
            </StyledTabs>
        </StyledPageData>
    );
};
