import { request } from '../../helpers/api/request';

export interface CreateShopModel {
  externalId: number | undefined,
  address: string,
  workingHours: string,
  phoneNumber: string[],
  addons: string[] | null,
  latitude: number | undefined,
  longitude: number | undefined,
  cityId?: number,
  status?: boolean,
  comment: string,
  isActive: boolean,
}

export const addShop = async (shopsData: CreateShopModel) => {
    const { data } = await request({
        url: 'admin/shops',
        method: 'POST',
        data: shopsData,
    });

    return data;
};
