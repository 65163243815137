/* eslint-disable react/jsx-key */
/* eslint-disable no-unsafe-optional-chaining */
import styled from 'styled-components';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { units } from '../../helpers/styles/units';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import LeftIcon from '../../styles/images/calendar/cal-l.png';
import RightIcon from '../../styles/images/calendar/cal-r.png';

interface CalendarProps {
    setDate: (data: DateObject[]) => void;
    values: DateObject;
    rangeValues?: DateObject[];
    year: number;
    setValues: (data: DateObject) => void;
    setRangeValues: (data: DateObject[] | undefined) => void;
    setYear: (year: number) => void;
}

const StyledCalendar = styled(Calendar)`
  position: relative;
  box-shadow: none !important;
  border-radius: 0;
  background-color: #F7F7F7;

  & .rmdp-header-values {
    font-weight: ${FontWeight.MEDIUM};

    ${typography(10)};
  }

  & .rmdp-header {    
    padding-bottom: ${units(0)};
}

  & .rmdp-header > div {    
      position: static !important;    
  }
  
  & .rmdp-arrow-container {
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    width: 24px;
    height: 24px;
    border-radius: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    & i {
      opacity: 0;
    }

    &:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  & .rmdp-arrow-container.rmdp-left {     
    left: -24px;    
    background: url(${LeftIcon});    
  }
  & .rmdp-arrow-container.rmdp-right {    
    right: -24px;    
    background: url(${RightIcon});    
  }

  & .rmdp-day {
    background-color: #F7F7F7;
  }

  & .rmdp-day span {
    font-weight: ${FontWeight.REGULAR};    

    ${typography(10)};    
  }

  & .rmdp-range {
    background-color: ${colors.white};
    color: ${colors.greyDark};
    box-shadow: none;
  }

  & .end {
    background-color: ${colors.grey20};
    color: ${colors.white};
    
  }

  & .start {
    background-color: ${colors.grey20};
    color: ${colors.white};    
  }

  & .rmdp-range.start:not(.force) {
    border-radius: 0;
  }

  & .rmdp-range.end:not(.force) {
    border-radius: 0;
  }

  & .rmdp-today span {
    background-color: ${colors.grey20};
  }

  & .rmdp-week:first-child {
    display: none;
  }

  & .rmdp-header-values {
    span:not(:first-child) {
      display: none;
  }

  & .rmdp-calendar. rmdp-day-picker {
    gap: ${units(20)} !important;
 }

  
`;

const CalendarWrapper = styled.div`
  padding: ${units(16)} ${units(24)};
  position: absolute;
  z-index: 100;
  top: ${units(32)};
  right: 0;

  display: flex;
  flex-direction: column;
  gap: ${units(8)};

  box-shadow: 0px 0px 20px 0px rgba(57, 56, 54, 0.2) !important;
  border-radius: 0;
  background-color: #f7f7f7;

  @media (max-width: 1240px) {
    right: -${units(120)};
  }
`;

const YearButton = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 0;
  outline: none;
  border: none;
  background: none;
  -webkit-appearance: none;
  &:hover {
    cursor: pointer;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(16)};

  padding-left: 24px 
`;

const YearWrapper = styled(StyledDiv)`
  gap: ${units(8)};

  padding-left: 0;
`;

const TodayButton = styled.button`
  padding: ${units(2)} 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid ${colors.greyDark};
  outline: none;
  -webkit-appearance: none;

  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};

  ${typography(10)};

  &:hover {
    cursor: pointer;
  }
`;

const ShowButton = styled(TodayButton)`
  padding: ${units(5)} ${units(16)};
  border: 1px solid black;
`;

const StyledDateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(8)};
  padding-left: ${units(5)};
`;

const StyledDate = styled.div`
  display: flex;
  width: ${units(63)};
  align-items: center;
  justify-content: center;
  padding:  ${units(4)} 0;

  font-weight: ${FontWeight.REGULAR};

  color: ${colors.greyDark};

  ${typography(10)};

  border-bottom: 1px solid ${colors.grey40};
`;

export const CalendarContent = ({
    setDate, values, rangeValues = [], year, setValues, setRangeValues, setYear,
}: CalendarProps) => {
    const handlePrevYear = () => {
        setRangeValues([]);
        setYear(year - 1);

        setValues(new DateObject(values).subtract(1, 'years'));
    };

    const handleNextYear = () => {
        setRangeValues([]);
        setYear(year + 1);

        setValues(new DateObject(values).add(1, 'years'));
    };

    const goToday = () => {
        setRangeValues([]);
        setValues(new DateObject());
        setYear(new Date().getFullYear());
    };

    const handleSetDate = (data: any) => {
        setRangeValues(data);
    };
    const months = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];

    const onMonthChange = (data: any) => {
        if (data.year !== year) {
            setYear(data.year);
        }
    };

    const handleSetDates = () => {
        setDate(rangeValues);
    };
    return (
        <CalendarWrapper>
            <StyledDiv>
                <TodayButton type="button" onClick={goToday}>
                    Сегодня
                </TodayButton>
                <YearWrapper>
                    <YearButton type="button" onClick={handlePrevYear}>
                        <Icon
                            type={TypesIcon.ARROW_LEFT}
                            viewBox="0 0 16 16"
                            width="16px"
                            height="16px"
                        />
                    </YearButton>
                    <span>{year}</span>
                    <YearButton type="button" onClick={handleNextYear}>
                        <Icon
                            type={TypesIcon.ARROW_RIGHT}
                            viewBox="0 0 16 16"
                            width="16px"
                            height="16px"
                        />
                    </YearButton>
                </YearWrapper>
            </StyledDiv>
            <StyledCalendar
                value={rangeValues?.length ? rangeValues : values}
                onChange={handleSetDate}
                range
                numberOfMonths={3}
                monthYearSeparator=" "
                months={months}
                showOtherDays
                displayWeekNumbers={false}
                onMonthChange={onMonthChange}
            />
            <StyledDateWrapper>
                <StyledDate>{rangeValues?.length
                    ? rangeValues[0]?.format('DD.MM.YYYY')
                    : '__.__.____'}
                </StyledDate>

                <span>-</span>{' '}

                <StyledDate>
                    {(rangeValues && rangeValues[1]?.format('DD.MM.YYYY'))
                                || '__.__.____'}
                </StyledDate>
                <ShowButton onClick={handleSetDates}>Показать</ShowButton>
            </StyledDateWrapper>
        </CalendarWrapper>
    );
};
