import { createGlobalStyle } from 'styled-components';
import { colors } from '../../constants/Colors';

export const fontName = 'Gotham Pro';

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    min-height: 100vh;
    height: fit-content;
    display: flex;
    flex-direction: column;

    font-family: ${fontName};

    color: ${colors.greyDark};
    background-color: ${colors.light};
  }
  
  input, textarea {
    font-family: ${fontName} !important;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-webkit-autofill~label {
    transform: translate(0px,0px) scale(1);
  }

  /* Убираем внешние и внутренние отступы */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;
