import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as goodsApi from '../../../api/goods';
import { GetAllGoodsContract } from '../../../types/Endpoints/goods/GoodsContracts';

export const GET_ALL_GOODS = 'goods/getAllGoods';

export const getGoodsThink = createAsyncThunk<
    GetAllGoodsContract['responseBody'],
    GetAllGoodsContract['pathParams']
>(
    GET_ALL_GOODS,
    createRequest({
        type: GET_ALL_GOODS,
        loader: async (params: GetAllGoodsContract['pathParams']) => goodsApi.getGoods(params),
    }),
);
