import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';

const colorBgByType: Record<string, string> = {
    [TypesButton.PRIMARY]: colors.greyDark,
    [TypesButton.SECONDARY]: colors.light,
    [TypesButton.DELETE]: colors.light,
    [TypesButton.LIGHT]: colors.light,
    [TypesButton.TEXT]: 'transparent',
    [TypesButton.ICON]: colors.grayscale02,
    [TypesButton.GRAY]: colors.grayscale05,
};

export const applyBgColor = ({ typeButton }: {typeButton: TypesButton}) => `background: ${colorBgByType[typeButton]}`;
