import styled from 'styled-components';
import { PageTitle } from '../../../components/PageTitle';
import { units } from '../../../helpers/styles/units';
import { SearchBar } from '../../../components/SearchBar';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../../store';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { collectionsBreadcrumbs } from '../../../constants/BreadCrumbs/collectionsBreadcrumbs';
import { selectGetCollectionsLoader, selectSearchCollections } from '../../../selectors/collections';
import { CollectionsTable } from '../../../components/CollectionsTable';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};  
`;

const StyledPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(8)};
  width: 100%;
  padding-top: ${units(11)};
`;

// const StyledButton = styled(Button)`
//   display: flex;
//   align-items: center;
//   flex-direction: row;
//   gap: ${units(3)};
//   width: ${units(107)};
//   height: ${units(24)};
//   margin-left: auto;
// `;

const StyledSearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};  
`;

export const CollectionsContent = () => {
    const collections = useTypeSelector(selectSearchCollections);

    return (
        <StyledWrapper>
            <StyledPageTitle>Коллекции</StyledPageTitle>
            <Breadcrumbs breadcrumbs={collectionsBreadcrumbs()} />
            <StyledPanelWrapper>
                <StyledSearchBarWrapper>
                    <SearchBar />
                </StyledSearchBarWrapper>

                {/* <StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => history.push(routes.create_collection)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </StyledButton> */}
            </StyledPanelWrapper>
            <StyledRoot>
                {collections && <CollectionsTable collections={collections} />}
            </StyledRoot>
        </StyledWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetCollectionsLoader,
});

export const CollectionsWithLoader = loader(CollectionsContent);
