import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import { DeleteNotesContract, GetNotesContract } from '../../../types/Endpoints/notes/NotesContracts';
import { addNotification } from '../../notifications';
import { Thunk } from '../../../helpers/redux/Thunk';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import * as notesApi from '../../../api/notes';

export const GET_NOTES = 'notes/getNotes';
export const GET_NOTES_WITHOUT_LOADER = 'notes/getNotesWithoutLoader';

const DELETE_NOTES = 'notes/deleteNotes';
const MESSAGE = 'Данные удалены';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.ERROR,
        icon: TypesIcon.TRASH,
        message: {
            title: MESSAGE,
        },
    }));
};

export const getNotes = createAsyncThunk(
    GET_NOTES,
    createRequest({
        type: GET_NOTES,
        loader: async (params: GetNotesContract['pathParams']) => notesApi.getNotes(params),
    }),
);

export const getNotesWithoutLoader = createAsyncThunk(
    GET_NOTES_WITHOUT_LOADER,
    createRequest({
        type: GET_NOTES_WITHOUT_LOADER,
        loader: async (params: GetNotesContract['pathParams']) => notesApi.getNotes(params),
    }),
);

export const deleteNotes = createAsyncThunk(
    DELETE_NOTES,
    createRequest({
        type: DELETE_NOTES,
        onSuccess,
        loader: async (data: DeleteNotesContract['pathParams']) => notesApi.deleteNotes(data),
    }),
);
