import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_MOBILE_BANNER, GET_WEB_BANNER } from '../../actions/mainPage';
import { MobileBlockKey, WebBlockKey } from '../../types/Models/MainPage/MainPageModel';

const selectRoot = ({ mainPage }: RootState) => mainPage;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetSiteBlocksLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_WEB_BANNER],
);

export const selectGetMobileBlocksLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_MOBILE_BANNER],
);

export const selectSiteBodyBlocks = createSelector(
    selectRoot,
    ({ siteBodyBlocks }) => siteBodyBlocks,
);

export const selectMobileBodyBlocks = createSelector(
    selectRoot,
    ({ mobileBodyBlocks }) => mobileBodyBlocks,
);

export const selectMobileBodyBlock = (key: MobileBlockKey) => createSelector(
    selectMobileBodyBlocks,
    mobileBodyBlocks => mobileBodyBlocks?.find(block => block.key === key),
);

export const selectSiteBodyBlock = (key: WebBlockKey) => createSelector(
    selectSiteBodyBlocks,
    siteBodyBlocks => siteBodyBlocks?.find(block => block.key === key),
);
