import { EndpointContract } from '../EndpointContract';
import {
    BannerItemForEdit,
    BlockItem,
    MobileBlockKey,
    WebBlockKey,
} from '../../Models/MainPage/MainPageModel';

export interface GetMainPageMobileContract extends EndpointContract {
    responseBody: { data: BlockItem<MobileBlockKey>[] };
}

export interface GetMainPageWebContract extends EndpointContract {
    responseBody: { data: BlockItem<WebBlockKey>[] };
}

export interface EditMainPageMobileContract extends EndpointContract {
    requestBody: Partial<Omit<BlockItem<MobileBlockKey>, 'bannerItems' | 'bannerProducts'>>
}

export interface EditMainPageWebContract extends EndpointContract {
    requestBody: Partial<Omit<BlockItem<WebBlockKey>, 'bannerItems' | 'bannerProducts'>>
}

export interface EditBannerItemsContract extends EndpointContract {
    requestBody: {
        bannerItems: BannerItemForEdit[]
    }
}

export interface EditBannerItemContract extends EndpointContract {
    requestBody: BannerItemForEdit,
}

export enum MobileMainBannerTypes {
    APP_LINK = 'APP',
    SITE_LINK = 'SITE',
}
