import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const editUserBreadcrumbs = (categoryName = '') : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Пользователи',
        url: '',
    },
    {
        key: '2',
        value: categoryName,
        url: '',
    },
]);
