import styled from 'styled-components';
import { Form } from 'formik';
import { Input } from '../../components/Input';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { units } from '../../helpers/styles/units';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { TextEditor } from '../../components/TextEditor';

export const RootWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};

  max-width: ${units(322)};
  width: 100%;
`;

export const TitlesWrapper = styled(MainWrapper)`
  gap: ${units(12)};
`;

export const CheckboxesWrapper = styled(MainWrapper)`
  gap: ${units(8)};
`;

export const TitleInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

export const StyledTextEditor = styled(TextEditor)`
  margin-bottom: 0;

  & > p {
    font-weight: ${FontWeight.MEDIUM};

    margin-bottom: 0;
  }

  & .ql-toolbar.ql-snow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: ${units(28)};
    padding: 6px 16px;

    border-radius: 0px;
    border-color: #c7c7c7;
  }

  & .ql-snow .ql-editor h3 {
    text-align: center;
  }

  & .ql-container.ql-snow {
    border-radius: 0px;
    border-color: #c7c7c7;

    & .ql-editor {
      min-height: ${units(28)};
      padding: 16px 24px;
    }
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
  padding-top: ${units(20)};
`;

export const ButtonsWrapper = styled.div`
  margin-left: -${units(12)};
`;
