import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as blogApi from '../../../api/blog';

export const GET_ITEM_BLOG = 'blog/createBlog';

export const getBlogThunk = createAsyncThunk(
    GET_ITEM_BLOG,
    createRequest({
        type: GET_ITEM_BLOG,
        loader: async (id: string) => blogApi.getBlog(id),
    }),
);
