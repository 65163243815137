import { OrderItemAddress } from '../../types/Endpoints/orders/OrdersContracts';

export const generateAddress = (addressInfo: OrderItemAddress): string => {
    const {
        address,
        apartment,
        entrance,
        floor,
        doorCode,
        postalCode,
    } = addressInfo;

    const addressParts = [address];

    if (apartment) addressParts.push(`кв. ${apartment}`);
    if (entrance) addressParts.push(`${entrance} подъезд`);
    if (floor) addressParts.push(`${floor} этаж`);
    if (doorCode) addressParts.push(`домофон ${doorCode}`);
    if (postalCode) addressParts.push(`индекс ${postalCode}`);

    return addressParts.join(', ');
};
