import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UsersContent } from './loaders';
import { getUsersWithParams } from '../../actions/users/thunks';
import { selectAllUsers } from '../../selectors/users';
import { useTypeSelector } from '../../store';
import { PAGE_SIZE, START_PAGE } from './constants';

export const Users = () => {
    const dispatch = useDispatch();
    const users = useTypeSelector(selectAllUsers);

    useEffect(() => {
        if (!users) dispatch(getUsersWithParams({ page: START_PAGE, size: PAGE_SIZE }));
    }, []);

    return <UsersContent />;
};
