import { request } from '../../helpers/api/request';
import { GetPaymentMethodsContract } from '../../types/Endpoints/orderSettings/GetOrderSettingsContract';

export const getPaymentMethods = async () => {
    const { data } = await request<GetPaymentMethodsContract>({
        url: 'admin/payment-methods/',
    });

    return data;
};
