import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as searchSettingsApi from '../../../api/landings';
import { Thunk } from '../../../helpers/redux/Thunk';
import { getSearchSettings } from './getLandings';
import { getSearchSettingsLiked } from './getLandingsLiked';

export const SET_SEARCH_LIKED = 'searchSettings/setSearchSettingsLiked';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(getSearchSettings({ page: 1, size: 50 }));
    dispatch(getSearchSettingsLiked());
};
export const setSearchSettingsLiked = createAsyncThunk(
    SET_SEARCH_LIKED,
    createRequest({
        type: SET_SEARCH_LIKED,
        loader: async (requestData: number[]) => searchSettingsApi.setSearchSettingsLiked(requestData),
        onSuccess,
    }),
);
