import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as citiesApi from '../../../api/cities';
import { Thunk } from '../../../helpers/redux/Thunk';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { addNotification } from '../../notifications';

export const DELETE_CITY = 'cities/deleteCity';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.ERROR,
        message: {
            title: 'Город удален',
            description: '',
        },
        icon: TypesIcon.SNACK_TRASH,
    }));
};

export const deleteCity = createAsyncThunk(
    DELETE_CITY,
    createRequest({
        type: DELETE_CITY,
        loader: async (id: number) => citiesApi.deleteCity(id),
        onSuccess,
    }),
);
