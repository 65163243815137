import { request } from '../../helpers/api/request';
import { GetFilterContract } from '../../types/Endpoints/filters/FiltersContracts';

export const getFilter = async (filterId: string) => {
    const { data } = await request<GetFilterContract>({
        url: `admin/filters/${filterId}`,
    });

    return data;
};
