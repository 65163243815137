import { createSelector } from '@reduxjs/toolkit';
import { GET_ACTIVE_USER } from '../../actions/user';
import { Roles } from '../../constants/Roles';
import { RootState } from '../../store';
import { GET_USERS_WITH_PARAMS } from '../../actions/users';
import { GET_USER } from '../../actions/users/thunks/getUser';

const selectRoot = ({ users }: RootState) => users;

const selectRootLoaders = ({ loaders }: RootState) => loaders;

export const selectUserLoaders = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_ACTIVE_USER],
);

export const selectSelectedUserLoaders = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_USER],
);

export const selectActiveUser = createSelector(
    selectRoot,
    users => users?.activeUser,
);

export const selectAllUsersLoaders = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_USERS_WITH_PARAMS],
);

export const selectAllUsers = createSelector(
    selectRoot,
    users => users?.allUsers,
);

export const selectSelectedUser = createSelector(
    selectRoot,
    users => users?.selectedUser,
);

export const selectUserId = createSelector(
    selectActiveUser,
    activeUser => activeUser?.id,
);

export const selectIsLogin = createSelector(
    selectActiveUser,
    activeUser => !!activeUser,
);

export const selectRoles = createSelector(
    selectActiveUser,
    activeUser => activeUser?.role && activeUser.role,
);

export const selectHasRolesFactory = (accessRoles: Roles[]) => createSelector(
    selectRoles,
    roles => accessRoles.includes(roles as Roles),
);

export const selectFirstName = createSelector(
    selectActiveUser,
    activeUser => activeUser?.name,
);

export const selectEmail = createSelector(
    selectActiveUser,
    activeUser => activeUser?.email,
);
