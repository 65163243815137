import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { typography } from '../../helpers/styles/typography';
import { units } from '../../helpers/styles/units';
import { FontWeight } from '../../constants/Styles/fontWeight';

const StyledTitle = styled.p`
  margin: 0 0 ${units(8)};

  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 1.2px;

  ${typography(20)}
`;

export const PageTitle = ({ children, className }: PropsWithChildren<{className?: string}>) => (
    <StyledTitle className={className}>
        {children}
    </StyledTitle>
);
