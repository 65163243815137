import { request } from '../../helpers/api/request';
import { Block } from '../../types/DinamikPages';
import { DynamicPagesKeys } from '../../types/Models/DinamicPages';

export interface CreateBlocksModel {
  blocks: Block[],
}

export interface PagesModel {
  key: DynamicPagesKeys,
  data: CreateBlocksModel
}

export const updatePage = async (blocksData: PagesModel) => {
    const { data } = await request({
        url: `admin/dynamic-pages/${blocksData.key}/blocks`,
        method: 'PUT',
        data: blocksData.data,
    });

    return data;
};
