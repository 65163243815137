import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Input } from '../../../../../components/Input';
import { colors } from '../../../../../constants/Colors';
import { typography } from '../../../../../helpers/styles/typography';
import { units } from '../../../../../helpers/styles/units';
import { FontWeight } from '../../../../../constants/Styles/fontWeight';
import { PageTitle } from '../../../../../components/PageTitle';
import { UploadPhotoWithMiniature } from '../../../../../components/UploadPhotoWithMiniature';
import { PageStatus } from '../loaders';
import { useTypeSelector } from '../../../../../store';
import { selectMobileBodyBlock } from '../../../../../selectors/mainPage';
import {
    BannerItemForEdit,
    MobileBlockKey,
} from '../../../../../types/Models/MainPage/MainPageModel';
import { editBannerItemsMobile } from '../../../../../actions/mainPage';
import { createMobileBannerBreadcrumbs } from '../../../../../constants/BreadCrumbs/createMobileBannerItem';
import { SubmitButtons } from '../../../../../components/StyledComponents';
import { RadioGroup } from '../../../../../components/RadioButtonGroup';
import { RadioOption } from '../../../../../types/form/RadioOption';
import { MobileMainBannerTypes } from '../../../../../types/Endpoints/mainPage/MainPageContracts';
import Switch from '../../../../../components/sw';
import { isEqualObject } from '../../../../../helpers/isEqual';
import { addNotification } from '../../../../../actions/notifications';
import { TypesSnackbars } from '../../../../../types/TypesSnackbars';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { BreadcrumbsMainBanners } from '../../../../../components/BreadcrumbsMainBanners';

interface CreateProps {
    changePage: (key: PageStatus) => void;
}

const typeControls: RadioOption[] = [
    {
        value: MobileMainBannerTypes.APP_LINK,
        title: 'Ссылка на приложение',
    },
    {
        value: MobileMainBannerTypes.SITE_LINK,
        title: 'Ссылка на сайт',
    },
];

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${units(63)};
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledOrderInputWrapper = styled.div`
  width: ${units(76)};
`;

const StyledSubtitle = styled.span`
  font-weight: ${FontWeight.MEDIUM};

  ${typography(10)};
`;

const StyledSwitchWrapper = styled(StyledMainWrapper)`
  gap: ${units(8)};
`;

export const CreateMainBanner = ({ changePage }: CreateProps) => {
    const dispatch = useDispatch();

    const siteBodyBlock = useTypeSelector(
        selectMobileBodyBlock(MobileBlockKey.MOBILE_MAIN),
    );

    const handleSubmit = (values: BannerItemForEdit) => {
        const requestData: BannerItemForEdit = {
            id: values.id,
            title: values.title || '',
            buttonText: values.buttonText,
            text: values.text || '',
            url: values.url,
            order: siteBodyBlock?.bannerItems.length
                ? siteBodyBlock.bannerItems.length + 1
                : 1,
            status: values.status || 'ACTIVE',
            endDate: values.startDate || null,
            startDate: values.endDate || null,
            fileId: values.fileImage?.id,
            linkType: values.linkType || null,
        };

        const fullData = siteBodyBlock?.bannerItems
            ? [...siteBodyBlock.bannerItems, requestData]
            : [requestData];

        dispatch(
            editBannerItemsMobile({
                key: MobileBlockKey.MOBILE_MAIN,
                newData: fullData,
            }),
        );
    };

    const validationSchema = yup.object().shape({
        title: yup
            .string()
            .max(50, 'Не более 50 символов')
            .required('Поле является обязательным'),
        text: yup.string().max(40, 'Не более 40 символов'),
        url: yup.string().required('Поле является обязательным'),
    });

    const form = useFormik<BannerItemForEdit>({
        enableReinitialize: true,
        onSubmit: handleSubmit,
        initialValues: { linkType: 'APP', status: 'INACTIVE' } as BannerItemForEdit,
        validationSchema,
    });

    const handleToggle = (value: boolean) => {
        const newStatus = value ? 'ACTIVE' : 'INACTIVE';
        form.setFieldValue('status', newStatus);
    };

    const handleResetForm = (values: BannerItemForEdit) => {
        changePage(PageStatus.MAIN);

        const data = { linkType: 'APP', status: 'INACTIVE' } as BannerItemForEdit;
        const equal = isEqualObject(data, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Body мобильного приложения</PageTitle>
                <BreadcrumbsMainBanners
                    breadcrumbs={createMobileBannerBreadcrumbs('Добавить')}
                    onClick={() => changePage(PageStatus.MAIN)}
                />
                <StyledRoot>
                    <StyledMainWrapper>
                        <StyledInput
                            name="title"
                            onChange={form.handleChange}
                            value={form.values.title}
                            placeholder="Заголовок (не более 50 символов)*"
                            typeInput="text"
                            maxLength={255}
                            title="Заголовок (не более 50 символов)*"
                            isTouched={form.touched.title}
                            error={form.errors.title}
                            count={form.submitCount}
                        />
                        <StyledInput
                            name="text"
                            onChange={form.handleChange}
                            value={form.values.text}
                            placeholder="Описание (не более 40 символов)"
                            typeInput="text"
                            maxLength={255}
                            title="Описание (не более 40 символов)"
                            isTouched={form.touched.text}
                            error={form.errors.text}
                            count={form.submitCount}
                        />
                        <StyledOrderInputWrapper>
                            <StyledInput
                                name="order"
                                onChange={form.handleChange}
                                value={form.values.order}
                                placeholder="Номер при показе"
                                typeInput="number"
                                title="Номер при показе"
                                isTouched={form.touched.order}
                                error={form.errors.order}
                            />
                        </StyledOrderInputWrapper>
                    </StyledMainWrapper>
                    <StyledSwitchWrapper>
                        <RadioGroup
                            name="linkType"
                            controls={typeControls}
                            onChange={form.setFieldValue}
                            fieldValue={form.values.linkType}
                            blockTitle="Вариант ссылки"
                        />
                        <StyledInput
                            name="url"
                            onChange={form.handleChange}
                            value={form.values.url}
                            placeholder="Ссылка*"
                            typeInput="text"
                            title="Ссылка*"
                            isTouched={form.touched.url}
                            error={form.errors.url}
                            count={form.submitCount}
                        />
                    </StyledSwitchWrapper>

                    <StyledSwitchWrapper>
                        <StyledSubtitle>Статус</StyledSubtitle>
                        <Switch
                            name="status"
                            value={form.values.status === 'ACTIVE'}
                            onChange={handleToggle}
                            type="checkbox"
                            title
                        />
                    </StyledSwitchWrapper>
                    <StyledMainWrapper>
                        <UploadPhotoWithMiniature
                            name="fileImage"
                            title="Изображение"
                            onChange={form.setFieldValue}
                            value={form.values.fileImage}
                            maxSize={3}
                            format="3.6:3"
                        />
                    </StyledMainWrapper>
                </StyledRoot>
            </StyledWrapper>
            <SubmitButtons
                reset={() => handleResetForm(form.values)}
                submit={form.submitForm}
            />
        </StyledRootWrapper>
    );
};
