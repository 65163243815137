import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { RobotsContent } from './loaders';
import { getRobots } from '../../actions/robots';

export const Robots = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRobots());
    }, []);

    return <RobotsContent />;
};
