import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';
import { routes } from '../RouterPath';

export const createSaleBreadcrumbs = (brandName = '') : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Акции',
        url: routes.sales,
    },
    {
        key: '2',
        value: brandName,
        url: '',
    },
]);
