import { cloneDeep } from 'lodash';
import { IModifyDataForForm, IModifyDataForUpdateProduct, IModifyWeight } from '../../pages/Goods/EditGood/PriceOffers/interface';
import { SALT } from '../../pages/Goods/EditGood/PriceOffers/constants';
import { IWeight } from '../../types/Endpoints/goods/ProductContracts';

const getValue = (value: string): number => (Number.isNaN(Number(value)) ? 0 : Number(value));

const getWeight = (weight: IWeight): IModifyWeight => {
    const valuesInsert = {
        insert: weight.insert[0].insert.trim(),
        price: getValue(String(weight.insert[0].price).trim()),
        discountPrice: getValue(String(weight.insert[0].discountPrice).trim()),
        discountPriceDC: getValue(String(weight.insert[0].discountPriceDC).trim()),
        percentDiscount: getValue(String(weight.insert[0].percentDiscount).trim()),
        percentDiscountDC: getValue(String(weight.insert[0].percentDiscountDC).trim()),
        amount: weight.insert[0].amount,
        amountMCode: weight.insert[0].amountMCode,
        insertShop: cloneDeep(weight.insert[0].insertShop),
    };

    return {
        id: String(weight.id).includes(SALT) ? undefined : weight.id,
        weight: getValue(String(weight.weight).trim()),
        insert: [{
            id: Number.isNaN(weight.insert[0].id) ? undefined : weight.insert[0].id,
            ...valuesInsert,
        }],
    };
};

export const modifyDataForUpdateProduct = (data: IModifyDataForForm[]): IModifyDataForUpdateProduct[] => {
    const result: IModifyDataForUpdateProduct[] = [];

    data.forEach(size => {
        const currentSize = cloneDeep(size);

        if (result.some(item => String(item.size).trim() === String(currentSize.size).trim())) {
            const objSize = result.find(item => String(item.size).trim() === String(currentSize.size).trim());
            objSize?.weight.push(getWeight(currentSize.weight));
        } else {
            let id = Number.isNaN(currentSize.id) ? undefined : currentSize.id;

            if (id && result.find(item => item.id === id)) {
                id = undefined;
                currentSize.weight.id = Number(SALT);
                currentSize.weight.insert[0].id = NaN;
            }

            result.push({
                id,
                size: getValue(String(currentSize.size).trim()),
                weight: [getWeight(currentSize.weight)],
            });
        }
    });

    return result;
};
