import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Checkbox } from '../../../components/Checkbox';
import { colors } from '../../../constants/Colors';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { routes } from '../../../constants/RouterPath';
import { PageTitle } from '../../../components/PageTitle';
import { UploadPhotoWithMiniature } from '../../../components/UploadPhotoWithMiniature';
import Switch from '../../../components/sw';
import { CopyToClipboard } from '../../../components/CopyToClipboard';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../../components/Icon';
import { SubmitButtons } from '../../../components/StyledComponents';
import { UploadFile } from '../../../types/Endpoints/files/FilesContract';
import { AddProductsPromotions } from '../../../components/AddProductsToPromotions';
import { config } from '../../../config';
import { createBlogBreadcrumbs } from '../../../constants/BreadCrumbs/createBlogBreadcrumbs';
import {
    CreateBlogContract,
    GetBlogItemContract,
    TextAndImageContract,
} from '../../../types/Endpoints/blog/BlogCRUDContracts';
import { DeleteButton } from '../../../components/Button/deleteButton';
import { useTypeSelector } from '../../../store';
import { selectBlog, selectGetBlogLoader, selectTagsList } from '../../../selectors/blog';
import { GetTags } from '../../../types/Endpoints/blog/BlogContracts';
import { getTagsThunk } from '../../../actions/blog/thunks/getTags';
import * as blogApi from '../../../api/blog';
import { deleteTagTag } from '../../../api/blog';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';
import { TextImageBlock } from '../CreateBlog/TextImageBlock';
import { updateBlogThunk } from '../../../actions/blog/thunks/updateBlog';
import { deleteBlogThunk } from '../../../actions/blog/thunks/deleteBlog';
import { addModal } from '../../../actions/modal';
import { getId } from '../../../helpers/generateId';
import { TypesModal } from '../../../types/TypesModalComponent';
import { isEqualObject } from '../../../helpers/isEqual';
import { addNotification } from '../../../actions/notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Textarea } from '../../../components/TextArea';
import * as S from '../styles';

const PATH = `${config.frontStageUrl}blog/`;

export const EditBlogLoader = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const tags: GetTags['responseBody'] = useTypeSelector(selectTagsList);
    const blog: GetBlogItemContract['responseBody'] | undefined = useTypeSelector(selectBlog);
    const { blogId } = useParams<{ blogId: string }>();

    const [state, setState] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<{ id: number }[]>([]);

    const [selectedTags, setSelectedTags] = useState<{ id: number }[]>([]);
    const [selectedProductsInModal, setSelectedProductsInModal] = useState<{ id: number }[]>([]);
    const [_, rerender] = useState<boolean>(false);
    const [isTagAddOpen, setIsTagAddOpen] = useState<boolean>(false);
    const [newTag, setNewTag] = useState<string>('');

    const handleCheckTags = (newItemID: number) => {
        if (selectedTags.find(item => item.id === newItemID)) {
            setSelectedTags(selectedTags.filter(item => item.id !== newItemID));
        } else if (selectedTags.length < 3) {
            setSelectedTags([...selectedTags, { id: newItemID }]);
        }
    };

    const handleClickOpenModal = () => {
        dispatch(addModal({
            id: getId()(),
            type: TypesModal.CREATE_TAG,
            message: { description: 'Добавить тег' },
            onSuccessMessage: 'Добавить',
            onSuccess: () => {
                rerender(prev => !prev);
                dispatch(getTagsThunk({}));
            },
        }));
    };

    const deleteTag = async (id: number) => {
        await deleteTagTag(id.toString());
        dispatch(getTagsThunk({}));
    };

    const saveAndOffDrawer = () => {
        setState(false);
        setSelectedProducts(selectedProductsInModal);
    };

    const onDrawer = () => {
        setState(true);
        setSelectedProductsInModal(selectedProducts);
    };

    const offDrawer = () => {
        setState(false);
        setSelectedProductsInModal([]);
    };

    // const offSecondDrawer = () => {
    //     setSelectedProductsInModal(selectedProducts);
    // };

    const handleSubmit = (values: CreateBlogContract<UploadFile>['requestBody']) => {
        const prooducts = selectedProducts.map(item => ({ id: item.id }));
        const requestData: CreateBlogContract<number>['requestBody'] = {
            ...values,
            urn: values.urn.replace(PATH, ''),
            seoH1: values.seoH1,
            seoTitle: values.seoTitle,
            seoDescription: values.seoDescription,
            headerImageFileId: values.headerImageFileId.id || 0,
            headerImageMobileFileId: values.headerImageMobileFileId.id || 0,
            publishDate: values.publishDate ? new Date(values.publishDate) : new Date(),
            bannerStatus: {
                status: values.bannerStatus.status,
                buttonText: values.bannerStatus.buttonText,
            },
            products: prooducts,
            tags: selectedTags,
            blogTextAndImageBlock: values.blogTextAndImageBlock.map(item => {
                const data: {
                    text: string,
                    order: number,
                    blogTextAndImageFileId?: number
                } = {
                    text: item.text,
                    order: item.order,
                };
                if (item.blogTextAndImageFileId?.id) {
                    data.blogTextAndImageFileId = item.blogTextAndImageFileId?.id;
                }
                return data;
            }),
        };
        if (values.bannerStatus?.mobileImageFileId?.id) {
            requestData.bannerStatus.mobileImageFileId = values.bannerStatus.mobileImageFileId.id;
        }
        if (values.bannerStatus?.desktopImageFileId?.id) {
            requestData.bannerStatus.desktopImageFileId = values.bannerStatus.desktopImageFileId.id;
        }
        if (values.bannerStatus?.order) {
            requestData.bannerStatus.order = +values.bannerStatus.order;
        }
        dispatch(updateBlogThunk({ data: requestData, blogId }));
    };

    const validationSchema = yup.object().shape({
        title: yup
            .string()
            .max(50, 'Не более 50 символов')
            .required('Поле является обязательным'),
        urn: yup
            .string()
            .test(
                'exception',
                'Поле является обязательным',
                value => !PATH.includes(value || ''),
            ),
    });

    const form = useFormik<CreateBlogContract<UploadFile>['requestBody']>({
        onSubmit: handleSubmit,
        initialValues: {
            title: blog?.title,
            seoH1: blog?.seoH1,
            seoTitle: blog?.seoTitle,
            seoDescription: blog?.seoDescription,
            urn: PATH + (blog?.urn || ''),
            publishDate: blog?.publishDate.slice(0, 10),
            headerImageFileId: blog?.headerImageFile,
            headerImageMobileFileId: blog?.headerImageMobileFile,
            bannerStatus: {
                status: blog?.bannerStatus.status,
                buttonText: blog?.bannerStatus.buttonText,
                order: blog?.bannerStatus.order,
                desktopImageFileId: blog?.bannerStatus.desktopImageFile,
                mobileImageFileId: blog?.bannerStatus.mobileImageFile,
            },
            tags: [],
            blogTextAndImageBlock: [],
            products: [],
        } as CreateBlogContract<UploadFile>['requestBody'],
        validationSchema,
    });

    const addTextImageBlock = () => {
        // setTextImageBlocks([...textImageBlocks, 'item']);
        form.values.blogTextAndImageBlock.push({
            id: Math.random(),
            text: '',
            blogTextAndImageFileId: {},
            order: form.values.blogTextAndImageBlock.length + 1,
        });
        rerender(prev => !prev);
    };

    const updateTextImageBlock = (text: string, image: UploadFile, order: number, id: number) => {
        // setTextImageBlocks([...textImageBlocks, 'item']);
        form.values.blogTextAndImageBlock.splice(order - 1, 1, {
            text,
            order,
            id,
            blogTextAndImageFileId: image,
        });
        rerender(prev => !prev);
    };

    const deleteTextImageBlock = (order: number) => {
        const listAfterDelete: Array<TextAndImageContract<UploadFile> | undefined> = form.values.blogTextAndImageBlock.map((item, index) => {
            if (item.order === order) return;
            if (item.order > order) {
                return {
                    ...item,
                    order: index,
                };
            }
            return item;
        }).filter(item => !!item);

        form.values.blogTextAndImageBlock = listAfterDelete as TextAndImageContract<UploadFile>[];
        rerender(prev => !prev);
    };

    const handleToggle1 = (value: 'ACTIVE' | 'INACTIVE') => {
        form.values.bannerStatus.status = value;
        rerender(prev => !prev);
    };
    /* const handleToggle2 = (value: 'ACTIVE' | 'INACTIVE') => {
        form.values.bannerStatusInCatalogue.status = value;
    }; */

    useEffect(() => {
        dispatch(getTagsThunk({}));
    }, [isTagAddOpen]);

    useEffect(() => {
        if (blog) {
            const sourceTags = blog.tags.map(item => ({ id: +item.id }));
            const sourceTextImage = blog.blogTextAndImageBlock.map((item, index) => {
                const data: {
                    id?: number,
                    text: string,
                    order: number,
                    blogTextAndImageFileId?: UploadFile
                } = {
                    id: Math.random(),
                    text: item.text,
                    order: index + 1,
                };
                if (item.blogTextAndImageFile) {
                    data.blogTextAndImageFileId = item.blogTextAndImageFile;
                }
                return data;
            });

            form.values.blogTextAndImageBlock = sourceTextImage;

            setSelectedTags(sourceTags);

            const getProducts = async () => {
                const data = await blogApi.getBlogProducts(blogId);
                // const reducedProducts = data.map(product => ({ id: product.id }));
                // setBlogProducts(data);
                setSelectedProducts(data);
                setSelectedProductsInModal(data);
            };
            getProducts();
        }
    }, []);

    const handleResetForm = (values: CreateBlogContract<UploadFile>['requestBody']) => {
        history.push(routes.blog);
        const equal = isEqualObject(form.initialValues, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <S.RootWrapper>
            <S.BlogWrapper>
                <PageTitle>Блог</PageTitle>
                <Breadcrumbs breadcrumbs={createBlogBreadcrumbs('Редактировать')} />
                <S.StyledRoot>
                    <S.CheckboxWrapper>
                        <S.IdInfoText>SEO</S.IdInfoText>
                        <Textarea
                            name="seoH1"
                            onChange={form.handleChange}
                            value={form.values.seoH1}
                            placeholder="H1"
                            maxLength={255}
                            title="H1"
                            isTouched={form.touched.seoH1}
                            error={form.errors.seoH1}
                        />
                        <Textarea
                            name="seoTitle"
                            onChange={form.handleChange}
                            value={form.values.seoTitle}
                            placeholder="Title"
                            maxLength={255}
                            title="Title"
                            isTouched={form.touched.seoTitle}
                            error={form.errors.seoTitle}
                        />
                        <Textarea
                            name="seoDescription"
                            onChange={form.handleChange}
                            value={form.values.seoDescription}
                            placeholder="Description"
                            maxLength={255}
                            title="Description"
                            isTouched={form.touched.seoDescription}
                            error={form.errors.seoDescription}
                        />
                    </S.CheckboxWrapper>
                    <S.TagsWrapper>
                        <S.IdInfoText>Основное</S.IdInfoText>
                        <S.StyledInput
                            name="title"
                            onChange={form.handleChange}
                            value={form.values.title}
                            placeholder="Заголовок (не более 50 символов)*"
                            typeInput="text"
                            maxLength={255}
                            title="Заголовок (не более 50 символов)*"
                            isTouched={form.touched.title}
                            error={form.errors.title}
                            count={form.submitCount}
                        />

                        <S.StyledInput
                            name="id"
                            value={blog?.id}
                            typeInput="text"
                            title="ID"
                            readonly
                        />

                        <S.UrlWrapper>
                            <S.InputWithPlaceholder
                                name="urn"
                                onChange={form.handleChange}
                                value={form.values.urn.length < PATH.length ? PATH : form.values.urn}
                                typeInput="text"
                                title="URL*"
                                isTouched={form.touched.urn}
                                error={form.errors.urn}
                                count={form.submitCount}
                            />
                            <S.CopyWrapper>
                                <CopyToClipboard copyText={form.values.urn.length < PATH.length ? PATH : form.values.urn} />
                            </S.CopyWrapper>
                        </S.UrlWrapper>

                        <S.DateWrapper>
                            <S.StyledInput
                                name="publishDate"
                                onChange={form.handleChange}
                                value={typeof form.values.publishDate === 'string' ? form.values.publishDate : ''}
                                placeholder="Дата публикации"
                                typeInput="date"
                                title="Дата публикации"
                                isTouched={form.touched.publishDate}
                                error={form.errors.publishDate}
                            />
                        </S.DateWrapper>
                    </S.TagsWrapper>
                    <S.CheckboxWrapper>
                        <S.ImageSubtitle>Заглавное изображение</S.ImageSubtitle>
                        <UploadPhotoWithMiniature
                            name="headerImageFileId"
                            title="Изображение для desktop версии сайта"
                            format="7:2.6"
                            onChange={form.setFieldValue}
                            value={form.values.headerImageFileId}
                            maxSize={4}
                        />
                        <UploadPhotoWithMiniature
                            name="headerImageMobileFileId"
                            title="Изображение для мобильной версии сайта"
                            format="3.5:2"
                            onChange={form.setFieldValue}
                            value={form.values.headerImageMobileFileId}
                            maxSize={3}
                        />
                    </S.CheckboxWrapper>

                    <S.TagsWrapper>
                        <S.Subtitle>Статус отображения в баннере</S.Subtitle>
                        <Switch
                            name="isShowOnBanner"
                            value={form.values.bannerStatus.status === 'ACTIVE'}
                            onChange={
                                () => handleToggle1(form.values.bannerStatus.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')
                            }
                            type="checkbox"
                            title
                            inactiveTitle="Выключено"
                            activeTitle="Активный"
                        />

                        {form.values.bannerStatus.status === 'ACTIVE' && (
                            <>
                                <S.StyledInput
                                    name="bannerStatus.buttonText"
                                    onChange={form.handleChange}
                                    value={form.values.bannerStatus.buttonText}
                                    placeholder="Текст кнопки"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Текст кнопки"
                                    isTouched={form?.touched?.bannerStatus?.buttonText}
                                    error={form?.errors?.bannerStatus?.buttonText}
                                />

                                <S.InputWrapper>
                                    <S.StyledInput
                                        name="bannerStatus.order"
                                        onChange={form.handleChange}
                                        value={form.values.bannerStatus.order}
                                        placeholder="Сортировка"
                                        typeInput="text"
                                        maxLength={255}
                                        title="Сортировка"
                                        isTouched={form?.touched?.bannerStatus?.order}
                                        error={form?.errors?.bannerStatus?.order}
                                    />
                                </S.InputWrapper>

                                <UploadPhotoWithMiniature
                                    name="bannerStatus.desktopImageFileId"
                                    title="Изображение для desktop версии"
                                    format="9.5:4"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatus.desktopImageFileId}
                                    maxSize={4}
                                />
                                <UploadPhotoWithMiniature
                                    name="bannerStatus.mobileImageFileId"
                                    title="Изображение для мобильной версии"
                                    format="2:1"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatus.mobileImageFileId}
                                    maxSize={3}
                                />
                            </>
                        )}
                    </S.TagsWrapper>

                    <S.TagsWrapper>
                        <S.SubtitleWithDescription>
                            <S.TagSubtitle>Теги</S.TagSubtitle>

                            <S.CountInfo>Максимально 3  тега</S.CountInfo>
                        </S.SubtitleWithDescription>

                        <S.TagsWrapper>
                            {tags && tags.map(item => (
                                <S.FlexRow key={item.id}>
                                    <Checkbox
                                        name="checked"
                                        checked={!!selectedTags.find(selectedItem => item.id === selectedItem.id)}
                                        onChange={() => handleCheckTags(item.id)}
                                    />

                                    <S.StyledInput
                                        name="tags"
                                        disabled
                                        value={item.title}
                                        placeholder="Название тега"
                                        typeInput="text"
                                        maxLength={255}
                                        title="Название тега"
                                    />

                                    <DeleteButton
                                        typeButton={TypesButton.SECONDARY}
                                        size={SizesButton.M}
                                        onClick={() => { deleteTag(item.id); }}
                                    />

                                </S.FlexRow>
                            ))}

                            {isTagAddOpen && (
                                <S.FlexRow>
                                    <S.StyledInput
                                        name="newTag"
                                        value={newTag}
                                        onChange={e => setNewTag(e.target.value)}
                                        placeholder="Название тега"
                                        typeInput="text"
                                        maxLength={255}
                                        title="Название тега"
                                    />

                                    <DeleteButton
                                        typeButton={TypesButton.SECONDARY}
                                        size={SizesButton.M}
                                        onClick={() => {
                                            setNewTag('');
                                            setIsTagAddOpen(false);
                                        }}
                                    />

                                </S.FlexRow>
                            )}
                        </S.TagsWrapper>

                        <S.AddButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={handleClickOpenModal}
                        >
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                color={colors.greyDark}
                            />
                            Добавить тег
                        </S.AddButton>
                    </S.TagsWrapper>

                    <S.CheckboxWrapper>
                        {!!form.values.blogTextAndImageBlock.length && form.values.blogTextAndImageBlock.map(item => (
                            <TextImageBlock
                                key={item.id}
                                item={item}
                                updateTextImageBlock={updateTextImageBlock}
                                deleteTextImageBlock={deleteTextImageBlock}
                            />
                        ))}

                        <S.AddButtonInline onClick={addTextImageBlock}>
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                color={colors.greyDark}
                            />
                            Добавить текст и изображение
                        </S.AddButtonInline>
                    </S.CheckboxWrapper>

                    <S.AddButtonWrapper>
                        <S.AddButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={onDrawer}
                        >
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                color={colors.greyDark}
                            />
                            Добавить товары
                        </S.AddButton>
                        <S.CountInfo>{`Добавлено ${selectedProducts.length} товаров в акцию`}</S.CountInfo>
                    </S.AddButtonWrapper>

                    <S.DeleteButton
                        typeButton={TypesButton.DELETE}
                        size={SizesButton.M}
                        onClick={() => {
                            dispatch(
                                addModal({
                                    id: getId()(),
                                    type: TypesModal.CONFIRM,
                                    message: {
                                        description:
                                            'Вы действительно хотите удалить?',
                                    },
                                    onSuccessMessage: 'Удалить',
                                    onSuccess: () => { dispatch(deleteBlogThunk(blogId)); },
                                    withCloseIcon: true,
                                }),
                            );
                        }}
                    >
                        <Icon
                            type={TypesIcon.TRASH_BIG}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                        />
                        Удалить блог
                    </S.DeleteButton>
                </S.StyledRoot>

                <S.StyledDrawer
                    anchor="right"
                    open={state}
                    onClose={offDrawer}
                >
                    <S.DrawerRoot>
                        <S.Heading>
                            <S.ModalTitle>Добавить товары</S.ModalTitle>
                            <S.CloseButton onClick={offDrawer}>
                                <Icon
                                    type={TypesIcon.CLOSE_BIG}
                                    color={colors.grey20}
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                />
                            </S.CloseButton>
                        </S.Heading>
                        <S.ModalDescription>{`Добавлено ${selectedProductsInModal.length} товаров в блог`}</S.ModalDescription>
                        <AddProductsPromotions
                            // products={blogProducts}
                            selectedProducts={selectedProductsInModal}
                            setSelectedProducts={setSelectedProductsInModal}
                        // offSecondDrawer={offSecondDrawer}
                        />

                        <S.ButtonWrapper>
                            <S.StyledButton
                                typeButton={TypesButton.SECONDARY}
                                size={SizesButton.M}
                                onClick={offDrawer}
                            >
                                Отменить
                            </S.StyledButton>
                            <S.StyledButton
                                typeButton={TypesButton.PRIMARY}
                                size={SizesButton.M}
                                onClick={saveAndOffDrawer}
                            >
                                Сохранить изменения
                            </S.StyledButton>
                        </S.ButtonWrapper>
                    </S.DrawerRoot>
                </S.StyledDrawer>
            </S.BlogWrapper>
            <SubmitButtons reset={() => handleResetForm(form.values)} submit={form.submitForm} />
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetBlogLoader,
});

export const EditBlogWithLoader = loader(EditBlogLoader);
