import { request } from '../../helpers/api/request';
import { GetCollectionsContract } from '../../types/Endpoints/collections/GetCollectionsContract';

export const getCollections = async () => {
    const { data } = await request<GetCollectionsContract>({
        url: 'admin/collections/',
    });

    return data;
};
