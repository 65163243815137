import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { PaymentAndDeliveryBreadcrumbs } from '../../../../constants/BreadCrumbs/paymentAndDeliveryBreadcrumbs';
import { useTypeSelector } from '../../../../store';
import { selectDinamicPage } from '../../../../selectors/dinamicPages';
import { DynamicPagesKeys } from '../../../../types/Models/DinamicPages';
import { getPage } from '../../../../actions/dinamicPages';
import { DynamicPageForm } from '../../../../components/DynamicPageForm';
import { updatePaymentPage } from '../../../../actions/dinamicPages/thunks/updatePaymentPageYuv';
import { PageTitles } from '../../../../constants/PageTitles';
import { FORMATS_DELIVERY } from '../constants';
import * as S from '../../styles';

export const PaymentAndDeliveryYuv = () => {
    const dispatch = useDispatch();
    const dinamicPage = useTypeSelector(selectDinamicPage);
    const [state, setState] = useState(false);

    useLayoutEffect(() => {
        dispatch(getPage(DynamicPagesKeys.PAYMENT_AND_DELIVERY_YUV));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setState(true);
        }, 300);
    });

    if (!state) {
        return <S.StyledPreloader />;
    }

    return (
        <S.Wrapper>
            <PageTitle>{PageTitles.PAYMENT_AND_DELIVERY}</PageTitle>
            <Breadcrumbs breadcrumbs={PaymentAndDeliveryBreadcrumbs()} />
            <S.Root>
                {dinamicPage ? (
                    <DynamicPageForm
                        pagesData={dinamicPage}
                        pageKey={DynamicPagesKeys.PAYMENT_AND_DELIVERY_YUV}
                        updatePage={updatePaymentPage}
                        formats={FORMATS_DELIVERY}
                    />
                ) : null}
            </S.Root>
        </S.Wrapper>
    );
};
