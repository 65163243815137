import styled from 'styled-components';
import { Input } from '../../../components/Input';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { Select } from '../../../components/Select';

export const RootWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

export const IdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

/* export const StyledButton = styled(Button)`
  width: 100%;
` */

/* export const StyledDeleteButton = styled(StyledButton)`
  width: fit-content;
`; */

export const FieldDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
  margin-bottom: ${units(20)};
`;

export const InpurWrapper = styled.div`
  display: flex;
  gap: ${units(8)};
  align-items: flex-end;
`;

export const StyledSelect = styled(Select)`
  background-color: ${colors.light};

  & .label {
    color: ${colors.greyDark};
  }

  :not(:last-child) {
    margin-bottom: ${units(3)};
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Div = styled.div`
  margin-bottom: ${units(12)};
`;

export const ClipBoardWrapper = styled.div`
  position: absolute;
  bottom: ${units(2)};
  right: 0;
  z-index: 10;
`;

export const FieldInfo = styled.div`
  // padding-top: ${units(2)};
  font-weight: ${FontWeight.REGULAR};

  color: ${colors.grey20};
  background-color: ${colors.light};

  ${typography(5)};
`;
