import { createReducer } from '@reduxjs/toolkit';

import { Robots } from '../../types/Endpoints/robots/RobotsContract';
import { getRobots } from '../../actions/robots/thunks/getRobots';

interface RobotsState {
    robots?: Robots;
}

export const robots = createReducer<RobotsState>({}, builder => {
    builder.addCase(getRobots.fulfilled, (state, { payload }) => ({
        ...state,
        robots: payload,
    }));
});
