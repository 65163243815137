import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as salesApi from '../../../api/sales';
import { CreatePromotionsContract } from '../../../types/Endpoints/sales/PromitionsContracts';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';

export const CREATE_PROMOTION = 'promotions/createPromotion';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Акция создана успешно',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));

    history.push(routes.sales);
};

export const createPromotionsThink = createAsyncThunk(
    CREATE_PROMOTION,
    createRequest({
        type: CREATE_PROMOTION,
        loader: async (data: CreatePromotionsContract<number>['requestBody']) => salesApi.createPromotion(data),
        onSuccess,
    }),
);
