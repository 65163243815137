import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as robotsApi from '../../../api/robots';
import { Robots } from '../../../types/Endpoints/robots/RobotsContract';

export const GET_ROBOTS = 'robots/getRobots';

export const getRobots = createAsyncThunk<Robots>(
    GET_ROBOTS,
    createRequest({
        type: GET_ROBOTS,
        loader: async () => robotsApi.getRobots(),
    }),
);
