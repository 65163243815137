import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { units } from '../../../../helpers/styles/units';
import { MenuTabs } from '../../../../components/MenuTabs';
import { useTypeSelector } from '../../../../store';
import { selectSelectedCategory } from '../../../../selectors/catalog';
import { SubCategoriesItem } from '../../../../types/Endpoints/categories/CategoriesContracts';
import { getEmptySubcategory } from '../../../../helpers/category';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

export interface MenuCategoryData {
  id: number;
  name: string;
  subcategories: SubCategoriesItem[]
}

export const MenuEditContent = () => {
    const [active, setActive] = useState(0);

    const [state, setState] = useState<SubCategoriesItem[] | []>([]);

    const category = useTypeSelector(selectSelectedCategory);

    useEffect(() => {
        setState(category?.subcategories || []);
    }, [category]);

    return (
        <StyledWrapper>
            <MenuTabs
                data={state && state.length ? state : [getEmptySubcategory()]}
                active={active}
                setActive={setActive}
                categoryName={category?.name || ''}
            />

        </StyledWrapper>
    );
};
