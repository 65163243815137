import { request } from '../../helpers/api/request';
import { OrderContract } from '../../types/Endpoints/orders/OrdersContracts';

export const getOrder = async (id: string) => {
    const { data } = await request<OrderContract>({
        url: `admin/orders/${id}`,
    });

    return data;
};
