import { request } from '../../helpers/api/request';
import { GetFiltersContract } from '../../types/Endpoints/filters/FiltersContracts';

export const getFilters = async (params: GetFiltersContract['pathParams']) => {
    const { data } = await request<GetFiltersContract>({
        url: 'admin/filters',
        params,
    });

    return data;
};
