import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EditFilterWithLoader } from './loaders';
import { getFilter } from '../../../../actions/filters';
import { getProperties } from '../../../../actions/properties';
import { getCatalogCategories } from '../../../../actions/category/thunks';

export const EditFilter = () => {
    const dispatch = useDispatch();
    const { filterId } = useParams<{filterId: string}>();

    useEffect(() => {
        dispatch(getFilter(filterId));
        dispatch(getProperties());
        dispatch(getCatalogCategories());
    }, [filterId]);

    return <EditFilterWithLoader />;
};
