import { ButtonProps } from '.';
import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';

const hoverBgByType: Record<string, string> = {
    [TypesButton.PRIMARY]: colors.gold,
    [TypesButton.SECONDARY]: colors.light,
    [TypesButton.DELETE]: colors.light,
};

export const applyHoverBgColor = ({ typeButton, disabled }: Pick<ButtonProps, 'typeButton' | 'disabled'>) => (
    !disabled
        ? `
          &:hover {
            background-color: ${hoverBgByType[typeButton]}};
          }
        `
        : '');
