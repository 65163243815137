import { createSelector } from '@reduxjs/toolkit';
import { GET_SHOP, GET_SHOPS } from '../../actions/shops';
import { RootState } from '../../store';

const selectRoot = ({ shops }: RootState) => shops;

const selectRootLoaders = ({ loaders }: RootState) => loaders;

export const selectShopsLoader = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_SHOPS],
);

export const selectShops = createSelector(
    selectRoot,
    shops => shops?.shops,
);

export const selectShopLoader = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_SHOP],
);

export const selectSelectedShop = createSelector(
    selectRoot,
    shops => shops?.selectedShop,
);
