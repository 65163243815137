import { EndpointContract } from '../EndpointContract';

export enum PricesKeys {
  BASE = 'BASE',
  DISCOUNT = 'DISCOUNT',
  DISCOUNT_DC = 'DISCOUNT_DC'
}

export interface IPrice {
  key: keyof typeof PricesKeys;
  isShow: boolean;
}

export interface IPriceResponse extends IPrice {
  id: number;
}

export interface IGetPrices extends EndpointContract {
  responseBody: IPrice[];
}
