import styled from 'styled-components';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { units } from '../../../helpers/styles/units';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../../components/Icon';
import { SearchBar } from '../../../components/SearchBar';
import { Button } from '../../../components/Button';
import { PageTitle } from '../../../components/PageTitle';
import { colors } from '../../../constants/Colors';

import { RegionTable } from '../../../components/Table/RegionTable';
import { PageStatus } from '../loaders';

interface ComponentProps {
  changePage: (key: PageStatus) => void;
}

const StyledRoot = styled.div`
  display: flex;
  padding-top: ${units(20)};
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
  padding-top: ${units(11)};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  max-width: ${units(107)};
  width: 100%;
  height: ${units(24)};
  /* margin-right: ${units(200)}; */
`;

const StyledSearchBarWrapper = styled.div`
  max-width: ${units(239)};
  flex: 1 1 auto;
`;

const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
  margin-bottom: ${units(20)};
`;

export const RegionTableContent = ({ changePage }: ComponentProps): JSX.Element => (
    <StyledWrapper>
        <StyledPageTitle>Справочник</StyledPageTitle>
        <StyledPanelWrapper>
            <StyledSearchBarWrapper>
                <SearchBar />
            </StyledSearchBarWrapper>
            <StyledButton
                typeButton={TypesButton.PRIMARY}
                size={SizesButton.S}
                onClick={() => changePage(PageStatus.ADD)}
            >
                <Icon
                    type={TypesIcon.PLUS}
                    color={colors.white}
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                />{' '}
                Добавить
            </StyledButton>
        </StyledPanelWrapper>
        <StyledRoot>
            <RegionTable changePage={changePage} />
        </StyledRoot>
    </StyledWrapper>
);
