import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_PAGE } from '../../actions/dinamicPages/thunks';

const selectRoot = ({ dinamicPages }: RootState) => dinamicPages;

const selectRootLoaders = ({ loaders }: RootState) => loaders;

export const selectDinamicPageLoaders = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_PAGE],
);

export const selectDinamicPage = createSelector(
    selectRoot,
    dinamicPages => dinamicPages?.dinamicPage,
);
