import { AxiosProgressEvent } from 'axios';
import { request } from '../../helpers/api/request';
import { User } from '../../types/Models/Users/User';
import { Session } from '../../types/Session';

export const updateUserInfo = async (
    newData: User,
    id?: Session['user']['id'],
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
    const { data } = await request({
        url: `profile/${id}/`,
        method: 'PATCH',
        data: newData,
        onUploadProgress,
    });

    return data;
};
