import { NamesFieldsFormPriceOffers } from '../../pages/Goods/EditGood/PriceOffers/constants';

export const getNamesFieldsPriceOffers = (
    name: string,
    indexSize: number,
    isWeight?: boolean,
    isInsert?: boolean,
): string => {
    if (isInsert) {
        return `${NamesFieldsFormPriceOffers.size}[${indexSize}].weight.insert[0].${name}`;
    }

    if (isWeight && !isInsert) {
        return `${NamesFieldsFormPriceOffers.size}[${indexSize}].weight.${name}`;
    }

    return `${NamesFieldsFormPriceOffers.size}[${indexSize}].${name}`;
};
