import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_CITIES_REGION } from '../../actions/city/thunks';

const selectRoot = ({ cities }: RootState) => cities;
const selectSearchCities = ({ search }: RootState) => search;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectCitiesByRegionLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_CITIES_REGION],
);

export const selectActiveCity = createSelector(
    selectRoot,
    cities => cities?.activeCity,
);

export const selectCities = createSelector(
    selectRoot,
    cities => cities?.cities,
);

export const selectCitiesByRegion = createSelector(
    selectRoot,
    cities => cities?.citiesByRegion,
);

export const selectSearch = createSelector(
    selectRoot,
    selectSearchCities,
    (cities, search) => (cities && cities?.citiesByRegion && cities.citiesByRegion.filter(city => city.name.toLowerCase().includes(search?.searchString || ''))) || cities?.citiesByRegion,
);
