export const naruchnyeChasy = [
    {
        name: 'Категория',
        id: 'type',
    },
    {
        name: 'Название товара',
        id: 'name',
    },
    {
        name: 'Артикул',
        id: 'article',
    },
    {
        name: 'Цена товара',
        id: 'price',
    },
    {
        name: 'Для кого',
        id: 'TargetAudience',
    },
    {
        name: 'Бренд',
        id: 'Brend',
    },
    {
        name: 'Тип часов',
        id: 'Dlyakogo',
    },
    {
        name: 'Механизм',
        id: 'Movement',
    },
    {
        name: 'Стекло',
        id: 'Steklo',
    },
    {
        name: 'Диаметр циферблата (мм)',
        id: 'Diametr',
    },
    {
        name: 'Цвет вставки',
        id: 'TsvetVstavki',
    },
    {
        name: 'Вид вставки',
        id: 'KindOfInsertsName',
    },
    {
        name: 'Материал ремня/браслета',
        id: 'Materialremnyabrasleta',
    },
    {
        name: 'Страна Бренда',
        id: 'Stranabrenda',
    },
    {
        name: 'Гарантия',
        id: 'Garantiya',
    },
    {
        name: 'Форма корпуса',
        id: 'Formakorpusa',
    },
    {
        name: 'Материал корпуса',
        id: 'Materialkorpusa',
    },
    {
        name: 'Водозащита',
        id: 'Vodozashchita',
    },
    {
        name: 'Стиль',
        id: 'Style',
    },
    {
        name: 'Индикация времени',
        id: 'Indikatsiya',
    },
    {
        name: 'Цвет корпуса',
        id: 'Tsvetkorpusa',
    },
    {
        name: 'Цвет циферблата',
        id: 'Tsvettsiferblata',
    },
    {
        name: 'Цвет ремня/браслета',
        id: 'Tsvetremnyabrasleta',
    },
    {
        name: 'Камни на циферблате',
        id: 'Incrust',
    },
    {
        name: 'Вид металла',
        id: 'Metal',
    },
    {
        name: 'Проба',
        id: 'Probe',
    },
];
