import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { units } from '../../helpers/styles/units';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Collection } from '../../types/Models/Collections/Collection';
import { config } from '../../config';
import { routes } from '../../constants/RouterPath';
import { EmptyRow } from '../EmptyRow';

interface TableProps {
  className?: string;
  collections: Collection[];
}

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
    },
};

const StyledCell = styled(TableCell)`
  .status {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};
  }

  & > div {
    ${props => props.className === 'active'
      && css`
        background-color: rgba(87, 198, 118, 0.05);

        & div {
          background-color: #6db180;
        }
      `}

    ${props => props.className === 'inactive'
      && css`
        background-color: rgba(231, 72, 72, 0.05);

        & div {
          background-color: #e74848;
        }
      `}
  }
`;

const StyledStatusInfo = styled.div`
  width: ${units(4)};
  height: ${units(4)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
  width: ${units(42)};

  gap: ${units(4)};
`;

const StyledDiv = styled(ContentWrapper)`
  width: ${units(62)};
`;

const StyledImage = styled.img`
  height: ${units(24)};
  width: ${units(40)};
  object-fit: cover;

  border-radius: ${units(0)};
`;

export const CollectionsTable = ({ collections }: TableProps): JSX.Element => {
    const history = useHistory();

    return (
        <TableContainer component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell align="left">&nbsp;</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Заголовок</TableCell>
                        <TableCell align="left">Статус отображения в баннере</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={[styles.root, styles.body]}>
                    {collections
                        && collections.map((row, index) => (
                            <TableRow key={row.id} sx={{ 'td, th': { borderBottom: '0px' } }}>
                                <TableCell component="th" scope="row" align="center" sx={{ width: '5%' }}>
                                    {index + 1}
                                </TableCell>
                                <TableCell align="left" sx={{ width: '20%' }}>
                                    <StyledImage
                                        className="image"
                                        src={`${config.imageUrl}/${row.collectionSiteFile?.path}`}
                                    />
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className="text-grey"
                                    sx={{ width: '20%' }}
                                >
                                    {`${row.id || ''}`}
                                </TableCell>
                                <TableCell align="left" sx={{ width: '25%' }}>
                                    {`${row.name || ''}`}
                                </TableCell>

                                <StyledCell
                                    align="left"
                                    sx={{ width: '20%' }}
                                    className={`${
                                        row.isShowCollectionBanner ? 'active' : 'inactive'
                                    }`}
                                >
                                    {row && row.isShowCollectionBanner ? (
                                        <StyledDiv>
                                            <StyledStatusInfo />
                                            <span>Активный</span>
                                        </StyledDiv>
                                    ) : (
                                        <StyledDiv>
                                            <StyledStatusInfo />
                                            <span>Выключено</span>
                                        </StyledDiv>
                                    )}
                                </StyledCell>
                                <TableCell align="left" sx={{ width: '5%' }}>
                                    <EditButton
                                        typeButton={TypesButton.SECONDARY}
                                        size={SizesButton.M}
                                        onClick={() => history.push(`${routes.edit_collection}/${row.id}`)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    {!collections?.length && <EmptyRow colSpan={6} />}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
