import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { units } from '../../helpers/styles/units';
import {
    getJewelryExchanges,
} from '../../actions/jewelryExchanges';
import { selectJewelryExchanges } from '../../selectors/jewelryExchanges';
import { useTypeSelector } from '../../store';
import { EditTableItems } from '../CalculatorModal/EditTableItem';

export interface ChildrenProps {
  onClose?: () => void;
}

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${units(20)};
  padding-bottom: ${units(30)};
`;

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${units(20)};
`;

export const EditTable = ({ onClose }: ChildrenProps) => {
    const dispatch = useDispatch();
    const jewelryExchangesData = useTypeSelector(selectJewelryExchanges);

    useEffect(() => {
        dispatch(getJewelryExchanges());
    }, []);

    return (
        <StyledWrapper>
            <StyledMainWrapper>
                {jewelryExchangesData && <EditTableItems modalData={jewelryExchangesData} onClose={onClose} />}
            </StyledMainWrapper>
        </StyledWrapper>
    );
};
