import { cloneDeep } from 'lodash';
import { ISize } from '../../types/Endpoints/goods/ProductContracts';
import { IModifyDataForForm } from '../../pages/Goods/EditGood/PriceOffers/interface';

export const modifyDataForFormPriceOffers = (sizes: ISize[] | undefined): IModifyDataForForm[] => {
    const result: IModifyDataForForm[] = [];

    sizes?.forEach(size => {
        if (size.weight.length > 1) {
            size.weight.forEach(weight => {
                result.push({
                    id: size.id,
                    size: size.size,
                    weight: cloneDeep(weight),
                });
            });
        } else if (size.weight.length) {
            result.push({
                id: size.id,
                size: size.size,
                weight: cloneDeep(size.weight[0]),
            });
        }
    });

    return result;
};
