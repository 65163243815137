/* eslint-disable no-unsafe-optional-chaining */
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { SearchBar } from '../../components/SearchBar';
import { units } from '../../helpers/styles/units';
import { PageTitle } from '../../components/PageTitle';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../store';
import { FilterCloudTable } from '../../components/FilterCloudTable';
import { selectCategories } from '../../selectors/catalog';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { filterCloudTableBreadcrumbs } from '../../constants/BreadCrumbs/filterCloudTableBreadcrumb';

const StyledRoot = styled.div`
  display: flex;
  padding-top: ${units(20)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

const StyledPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
  padding-top: ${units(14)};
`;

const StyledSearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM}; 
`;

export const FilterCloudContent = () => {
    const dispatch = useDispatch();

    const categories = useTypeSelector(selectCategories);
    /* const searchString = useTypeSelector(selectSearchData); */

    /*     useEffect(() => {
        dispatch(
            getCatalogCategories({
                search: searchString,
            }),
        );
    }, [searchString]); */

    return (
        <StyledWrapper>
            <StyledPageTitle>Облако фильтров</StyledPageTitle>
            <Breadcrumbs breadcrumbs={filterCloudTableBreadcrumbs()} />
            <StyledPanelWrapper>
                <StyledSearchBarWrapper>
                    <SearchBar />
                </StyledSearchBarWrapper>

            </StyledPanelWrapper>
            <StyledRoot>

                {categories && <FilterCloudTable categories={categories} />}
            </StyledRoot>
        </StyledWrapper>
    );
};

/* const loader = createLoadHoc({
    selectLoader: selectGetPromotionsLoader,
});

export const SalesWithLoader = loader(SalesContent); */
