import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as goodsApi from '../../../api/goods';

export const GET_PRODUCT_ITEM = 'goods/getProductItem';

export const getProductItemThink = createAsyncThunk(
    GET_PRODUCT_ITEM,
    createRequest({
        type: GET_PRODUCT_ITEM,
        loader: async (id: number) => goodsApi.getProduct(id),
    }),
);
