import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const createMainBannerBreadcrumbs = (categoryName = '') : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Настройка главной',
        url: '',
    },
    {
        key: '2',
        value: 'Body сайта',
        url: '',
    },
    {
        key: '3',
        value: categoryName,
        url: '',
    },
]);
