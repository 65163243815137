import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as brandsApi from '../../../api/brands';

export const GET_CATALOG_BRAND = 'brands/getBrand';

export const getCatalogBrand = createAsyncThunk(
    GET_CATALOG_BRAND,
    createRequest({
        type: GET_CATALOG_BRAND,
        loader: async (id: string) => brandsApi.getBrand(id),
    }),
);
