import styled from 'styled-components';
import { FC } from 'react';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Button } from '../Button';

interface ComponentProps {
  reset: () => void;
  submit: () => void;
  isDisabledSubmit?: boolean;
}

export const ButtonRoot = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 150;
  
  display: flex;
  flex-direction: column;

  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  
  background-color: ${colors.light};
  border-top: 1px solid ${colors.grey40};
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${units(8)};

  padding: ${units(12)};
  padding-right: 0;

  max-width: ${units(334)};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export const SubmitButtons: FC<ComponentProps> = ({
    reset, submit, isDisabledSubmit, ...props
}) => (
    <ButtonRoot {...props}>
        <ButtonWrapper>
            <StyledButton
                typeButton={TypesButton.SECONDARY}
                size={SizesButton.M}
                onClick={reset}
            >
                Отменить
            </StyledButton>
            <StyledButton
                typeButton={TypesButton.PRIMARY}
                size={SizesButton.M}
                onClick={submit}
                disabled={isDisabledSubmit}
            >
                Сохранить изменения
            </StyledButton>
        </ButtonWrapper>
    </ButtonRoot>
);
