import * as Yup from 'yup';
import { PASSWORD_RGX, whiteSpaceValidation } from '../../../helpers/validation';

export const SignupSchema = () => Yup.object().shape({
    username: Yup.string()
        .test(whiteSpaceValidation('Логин должен быть без пробелов'))
        .required('Введите имя пользователя'),
    password: Yup.string()
        .nullable(false)
        .min(4, 'Пароль слишком короткий')
        .max(30, 'Пароль слишком длинный')
        .test(whiteSpaceValidation('Пароль должен быть без пробелов'))
        .matches(PASSWORD_RGX, 'Неверные символы')
        .required('Введите пароль'),
});
