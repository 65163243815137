import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as blogApi from '../../../api/blog';

export const GET_TAGS = 'blog/getTags';

export const getTagsThunk = createAsyncThunk(
    GET_TAGS,
    createRequest({
        type: GET_TAGS,
        loader: async () => blogApi.getTags(),
    }),
);
