import { createReducer } from '@reduxjs/toolkit';
import { Calculators } from '../../api/calculator/getCalculator';
import { getCalculatorAll } from '../../actions/calculator';

export interface CalculatorModel {
  calculator?: Calculators[];
}

export const calculator = createReducer<CalculatorModel>({}, builder => {
    builder.addCase(getCalculatorAll.fulfilled, (state, { payload }) => ({
        ...state,
        calculator: payload,
    }));
});
