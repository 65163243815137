/* eslint-disable @typescript-eslint/no-shadow */
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useEffect, useState } from 'react';
import { units } from '../../helpers/styles/units';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { PageTitle } from '../PageTitle';
import { Breadcrumbs } from '../Breadcrumbs';
import { UploadPhotoWithMiniature } from '../UploadPhotoWithMiniature';
import { typography } from '../../helpers/styles/typography';
import { colors } from '../../constants/Colors';
import { Input } from '../Input';
import { SubmitButtons } from '../StyledComponents';
import { isEqualObject } from '../../helpers/isEqual';
import { addNotification } from '../../actions/notifications';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { TypesIcon } from '../../types/TypesIcon';
import { Icon } from '../Icon';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { getId } from '../../helpers/generateId';
import { TypesModal } from '../../types/TypesModalComponent';
import { addModal } from '../../actions/modal';
import { Button } from '../Button';
import Switch from '../sw';
import { menuEditBreadcrumbs } from '../../constants/BreadCrumbs/menuEditBreadcrumbs';
import { EditSubSubcategoryItem, SubCategoriesItem, SubSubcategoryItem } from '../../types/Endpoints/categories/CategoriesContracts';
import { useTypeSelector } from '../../store';
import { selectSelectedCategory } from '../../selectors/catalog';
import { createCatalogSubCategory, editCatalogSubCategory, getCatalogCategory } from '../../actions/category/thunks';
import { deleteCatalogSubCategory } from '../../actions/category';
import { getShotPath } from '../../helpers/images/getPath';

interface CompProps {
  data: SubCategoriesItem;
  tabsCount: number;
  handleGoResetTabs: () => void;
  categoryName: string;
}

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${units(63)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0;
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledInpurWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
`;

const StyledDiv = styled(StyledInpurWrapper)`
  gap: ${units(20)};
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: ${units(322)};
  padding-top: ${units(20)};
`;

const StyledUploader = styled(UploadPhotoWithMiniature)`
  width: 100%;
`;

const StyledButtonsWrapper = styled.div`
  margin-left: -${units(12)};
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  top: ${units(17)};
  right: -${units(18)};
  z-index: 100;
`;

const StyledDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};
`;

const StyledDeleteButton = styled(Button)`
  width: fit-content;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  width: 100%;
`;

const StyledAddButton = styled(StyledButton)`
  width: ${units(144)};
  padding: 0;
  margin-top: ${units(20)};

  border: none;

  &:hover {
    border: none;
  }
`;

const StyledIdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(8)};
`;

const StyledInputsBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
`;

const StyledToggleButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${units(4)};
  color: ${colors.gold};
  width: fit-content;

  border: none;
  background: none;
  outline: none;
  -webkit-appearance: none;

  ${typography(10)};

  &:hover {
    cursor: pointer;
  }
`;

enum SubcategoriesActionsTypes {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}

const SubcategoryActions = {
    [SubcategoriesActionsTypes.CREATE]: createCatalogSubCategory,
    [SubcategoriesActionsTypes.EDIT]: editCatalogSubCategory,
};

export const MenuTabsContent = ({
    data, tabsCount, handleGoResetTabs, categoryName,
}: CompProps): JSX.Element => {
    const dispatch = useDispatch();

    const [rows, setRows] = useState(data?.subcategories);
    const [formValue, setFormValue] = useState({
        name: data?.name,
        isMain: data?.isMain,
        status: data?.status,
        sortOrder: data?.sortOrder,
    });
    const category = useTypeSelector(selectSelectedCategory);
    const [isMain, setIsMain] = useState(data?.isMain);

    useEffect(() => {
        setFormValue({
            name: data?.name,
            isMain: data?.isMain,
            status: data?.status,
            sortOrder: data?.sortOrder,
        });
        if (data?.subcategories) {
            setRows(data?.subcategories);
        }
        setIsMain(data?.isMain);
    }, [data]);

    const handleGetNewRows = (props: SubSubcategoryItem[]) => props.map((item: SubSubcategoryItem) => ({
        ...item,
        bannerFileId: item.bannerFileId || item.bannerFile?.id,
        // catalogBannerLogoFileId: item.catalogBannerLogoFileId || item.catalogBannerLogoFile?.id,
        bannerFileURL: item.bannerFileURL || item.bannerFile?.path,
        // catalogBannerLogoFileURL: item.catalogBannerLogoFileURL
        //         || item.catalogBannerLogoFile?.path,
        bannerFile: {
            id: item.bannerFile?.id,
            path: item.bannerFile?.path,
        },
        // catalogBannerLogoFile: {
        //     id: item.catalogBannerLogoFile?.id,
        //     path: item.catalogBannerLogoFile?.path,
        // },
    // catalogBannerFileId: item.catalogBannerFileId || item.catalogBannerFile?.id,
    // catalogBannerFileURL: item.catalogBannerFileURL || item.catalogBannerFile?.path,
    // catalogBannerFile: {
    //     id: item.catalogBannerFile?.id,
    //     path: item.catalogBannerFile?.path,
    // },
    }));

    const handleDragEnd = (e: any, props: any) => {
        if (!e.destination) return;
        let tempData = Array.from(props.values.subcategories as SubSubcategoryItem[]);
        // eslint-disable-next-line camelcase
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        tempData = handleGetNewRows(tempData).map((item, index) => ({
            ...item,
            sortOrder: index + 1,
        }));
        setRows(tempData);
        setFormValue({
            name: props.values?.name,
            isMain: props.values?.isMain,
            status: props.values?.status,
            sortOrder: props.values?.sortOrder,
        });
    };

    const handleSubmit = async (values: any) => {
        const action = category?.subcategories && category?.subcategories?.length < tabsCount ? 'CREATE' : 'EDIT';

        const subcategoriesdata = values.subcategories.map((subItem: EditSubSubcategoryItem) => ({
            name: subItem.name,
            udl: subItem.udl,
            mobileUrl: subItem.mobileUrl,
            sortOrder: +subItem.sortOrder,
            bannerFileId: subItem.bannerFile?.id,
            // catalogBannerLogoFileId: subItem.catalogBannerLogoFile?.id,
            // catalogBannerFileId: subItem.catalogBannerFile?.id,
            // catalogBannerTitle: subItem.catalogBannerTitle,

        }));

        const requestData = {
            categoryId: category?.id,
            id: data?.id || 0,
            name: values.name,
            status: values.status,
            isMain: values.isMain,
            sortOrder: +values.sortOrder,
            subcategories: subcategoriesdata,
        };

        await dispatch(SubcategoryActions[action](requestData));
        if (category && category.id) {
            dispatch(getCatalogCategory(`${category.id}`));
        }
    };

    const items = rows.map(item => ({
        ...item,
        mobileUrl: item.mobileUrl || '',
        bannerFile: {
            id: item?.bannerFileId || 0,
            path: item?.bannerFileURL ? getShotPath(item?.bannerFileURL) : '',
        },
        // catalogBannerFile: {
        //     id: item?.catalogBannerFileId || 0,
        //     path: item?.catalogBannerFileURL ? getShotPath(item?.catalogBannerFileURL) : '',
        // },
        // catalogBannerLogoFile: {
        //     id: item?.catalogBannerLogoFileId || 0,
        //     path: item?.catalogBannerLogoFileURL ? getShotPath(item?.catalogBannerLogoFileURL) : '',
        // },
    }));

    const initialValue = {
        ...formValue,
        subcategories: items || [],
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required('Поле обязательно для заполнения'),
        sortOrder: yup.number().required('Поле обязательно для заполнения'),
        isMain: yup.boolean(),
        subcategories: yup.array().of(
            yup.object().shape({
                name: yup.string().when([], {
                    is: () => !isMain,
                    then: yup.string().required('Поле является обязательным'),
                    otherwise: yup.string(),
                }),
                udl: yup.string().required('Поле является обязательным'),
                mobileUrl: yup.string().required('Поле является обязательным'),
            }),
        ),
    });

    const handleResetForm = (calb: () => void, values: any) => {
        calb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }

        handleGoResetTabs();
    };

    const [visibleFields, setVisibleFields] = useState<{
    [key: number]: boolean;
  }>(() => {
      const initialVisibility: { [key: number]: boolean } = {};
      rows?.forEach((_row: any, index: number) => {
          initialVisibility[index] = true;
      });
      return initialVisibility;
  });

    const toggleVisibility = (index: number) => {
        setVisibleFields(prevVisibleFields => ({
            ...prevVisibleFields,
            [index]: !prevVisibleFields[index],
        }));
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Меню</PageTitle>
                <Breadcrumbs breadcrumbs={menuEditBreadcrumbs(categoryName)} />
                <Formik<SubCategoriesItem>
                    initialValues={initialValue as unknown as SubCategoriesItem}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {props => (
                        <DragDropContext
                            onDragEnd={e => {
                                handleDragEnd(e, props);
                            }}
                        >
                            <StyledForm onSubmit={handleSubmit}>
                                <Droppable droppableId="droppable-1">
                                    {provider => (
                                        <StyledDataWrapper
                                            ref={provider.innerRef}
                                            {...provider.droppableProps}
                                        >
                                            <StyledInput
                                                name="name"
                                                onChange={props.handleChange}
                                                value={props.values.name}
                                                title="Название пользовательское"
                                                placeholder="Название пользовательское"
                                                typeInput="text"
                                                maxLength={255}
                                                isTouched={props.touched?.name}
                                                error={props.errors?.name}
                                                count={props.submitCount}
                                            />

                                            <StyledInputsBlockWrapper>
                                                <StyledSwitchWrapper>
                                                    <StyledIdInfo>Статус</StyledIdInfo>
                                                    <Switch
                                                        name="isMain"
                                                        value={props.values.isMain}
                                                        onChange={() => {
                                                            props.setFieldValue(
                                                                'isMain',
                                                                !props.values?.isMain,
                                                            );
                                                            setIsMain(value => !value);
                                                        }}
                                                        type="checkbox"
                                                        title
                                                        inactiveTitle="Второстепенная подкатегория"
                                                        activeTitle="Главная подкатегория"
                                                    />
                                                </StyledSwitchWrapper>

                                                <StyledInput
                                                    name="sortOrder"
                                                    onChange={props.handleChange}
                                                    value={props.values.sortOrder}
                                                    title="Сортировка"
                                                    placeholder="Сортировка"
                                                    typeInput="number"
                                                    isTouched={props.touched?.sortOrder}
                                                    error={props.errors?.sortOrder}
                                                    count={props.submitCount}
                                                />
                                            </StyledInputsBlockWrapper>

                                            <FieldArray name="subcategories">
                                                {({ remove }) => (
                                                    <StyledInputsBlockWrapper>
                                                        <StyledIdInfo>Варианты фильтрации</StyledIdInfo>
                                                        <StyledMainWrapper>
                                                            {rows?.map(
                                                                (_row, index) => (
                                                                    <Draggable
                                                                        key={_row.name}
                                                                        draggableId={`${getId()()}-${
                                                                            _row.name || index
                                                                        }`}
                                                                        index={index}
                                                                    >
                                                                        {provider => (
                                                                            <StyledDiv
                                                                                // eslint-disable-next-line react/no-array-index-key
                                                                                key={`block${index}`}
                                                                                {...provider.draggableProps}
                                                                                ref={provider.innerRef}
                                                                            >
                                                                                <StyledInpurWrapper>
                                                                                    <StyledIconWrapper
                                                                                        {...provider.dragHandleProps}
                                                                                    >
                                                                                        {' '}
                                                                                        <Icon
                                                                                            type={TypesIcon.DRAG_AND_DROP}
                                                                                            viewBox="0 0 24 24"
                                                                                            width="24px"
                                                                                            height="24px"
                                                                                            color={colors.grey20}
                                                                                        />
                                                                                    </StyledIconWrapper>

                                                                                    <StyledInput
                                                                                        name={`subcategories[${index}].name`}
                                                                                        onChange={props.handleChange}
                                                                                        value={
                                                                                            props.values?.subcategories[index]?.name
                                                                                        }
                                                                                        title={`Название пользовательское${props.values.isMain ? '' : '*'}`}
                                                                                        placeholder={`Название пользовательское${props.values.isMain ? '' : '*'}`}
                                                                                        typeInput="text"
                                                                                        maxLength={255}
                                                                                        isTouched={
                                                                                            props.touched?.subcategories
                                                                                            && props.touched?.subcategories[index]?.name
                                                                                        }
                                                                                        error={
                                                                                            props.errors?.subcategories
                                                                                            && props.errors?.subcategories[index]?.name
                                                                                        }
                                                                                        count={props.submitCount}
                                                                                    />
                                                                                    <StyledInput
                                                                                        name={`subcategories[${index}].udl`}
                                                                                        onChange={props.handleChange}
                                                                                        value={
                                                                                            props.values?.subcategories[index]?.udl
                                                                                        }
                                                                                        title="UDL*"
                                                                                        placeholder="UDL*"
                                                                                        typeInput="text"
                                                                                        isTouched={
                                                                                            props.touched?.subcategories
                                                                                            && props.touched?.subcategories[index]?.udl
                                                                                        }
                                                                                        error={
                                                                                            props.errors?.subcategories
                                                                                            && props.errors?.subcategories[index]?.udl
                                                                                        }
                                                                                        count={props.submitCount}
                                                                                    />

                                                                                    <StyledInput
                                                                                        name={`subcategories[${index}].mobileUrl`}
                                                                                        onChange={props.handleChange}
                                                                                        value={
                                                                                            props.values?.subcategories[index]?.mobileUrl
                                                                                        }
                                                                                        title="URL для мобильного приложения*"
                                                                                        placeholder="URL для мобильного приложения*"
                                                                                        typeInput="text"
                                                                                        isTouched={
                                                                                            props.touched?.subcategories
                                                                                            && props.touched?.subcategories[index]?.mobileUrl
                                                                                        }
                                                                                        error={
                                                                                            props.errors?.subcategories
                                                                                            && props.errors?.subcategories[index]?.mobileUrl
                                                                                        }
                                                                                        count={props.submitCount}
                                                                                    />
                                                                                </StyledInpurWrapper>

                                                                                {props.values.isMain && (
                                                                                    <>
                                                                                        <StyledToggleButton
                                                                                            type="button"
                                                                                            onClick={() => toggleVisibility(index)}
                                                                                        >
                                                                                            {visibleFields[index]
                                                                                                ? 'Свернуть'
                                                                                                : 'Показать больше'}
                                                                                            <Icon
                                                                                                type={
                                                                                                    visibleFields[index]
                                                                                                        ? TypesIcon.ARROW_UP
                                                                                                        : TypesIcon.ARROW_DOWN
                                                                                                }
                                                                                                width="16px"
                                                                                                height="16px"
                                                                                                viewBox="0 0 16 16"
                                                                                                color={colors.gold}
                                                                                            />
                                                                                        </StyledToggleButton>

                                                                                        {visibleFields[index] && (
                                                                                            <StyledInpurWrapper>
                                                                                                <StyledUploader
                                                                                                    name={`subcategories[${index}].bannerFile`}
                                                                                                    title="Баннер в меню"
                                                                                                    onChange={props.setFieldValue}
                                                                                                    value={
                                                                                                        props.values?.subcategories[index]
                                                                                                            ?.bannerFile
                                                                                                    }
                                                                                                    format="2:1 (центрировать)"
                                                                                                    maxSize={0.1}
                                                                                                />
                                                                                                {/* <StyledUploader
                                                                                                    name={`subcategories[${index}].catalogBannerFile`}
                                                                                                    title="Баннер в каталог"
                                                                                                    onChange={props.setFieldValue}
                                                                                                    value={
                                                                                                        props.values?.subcategories[index]
                                                                                                            ?.catalogBannerFile
                                                                                                    }
                                                                                                    format="8:1"
                                                                                                />
                                                                                                <StyledInput
                                                                                                    name={`subcategories[${index}].catalogBannerTitle`}
                                                                                                    onChange={props.handleChange}
                                                                                                    value={
                                                                                                        props.values?.subcategories[index]
                                                                                                            ?.catalogBannerTitle
                                                                                                    }
                                                                                                    title="Заголовок к баннеру в каталог"
                                                                                                    placeholder="Заголовок к баннеру в каталог"
                                                                                                    typeInput="text"
                                                                                                    maxLength={255}
                                                                                                /> */}
                                                                                                {/* <StyledUploader */}
                                                                                                {/*    name={`subcategories[${index}].catalogBannerLogoFile`} */}
                                                                                                {/*    onChange={props.setFieldValue} */}
                                                                                                {/*    value={ */}
                                                                                                {/*        props.values?.subcategories[index] */}
                                                                                                {/*            ?.catalogBannerLogoFile */}
                                                                                                {/*    } */}
                                                                                                {/*    format="2:1" */}
                                                                                                {/* /> */}
                                                                                            </StyledInpurWrapper>
                                                                                        )}
                                                                                    </>
                                                                                )}

                                                                                {rows.length > 1 && (
                                                                                    <StyledDeleteButton
                                                                                        typeButton={TypesButton.DELETE}
                                                                                        size={SizesButton.M}
                                                                                        onClick={() => {
                                                                                            dispatch(
                                                                                                addModal({
                                                                                                    id: getId()(),
                                                                                                    type: TypesModal.CONFIRM,
                                                                                                    message: {
                                                                                                        description:
                                                                                                        'Вы действительно хотите удалить?',
                                                                                                    },
                                                                                                    onSuccessMessage: 'Удалить',
                                                                                                    onSuccess: () => {
                                                                                                        remove(index);
                                                                                                        const newRows = handleGetNewRows(props.values.subcategories).filter(
                                                                                                            (_item, ind) => ind !== index,
                                                                                                        );
                                                                                                        setRows(newRows);
                                                                                                    },
                                                                                                    withCloseIcon: true,
                                                                                                }),
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <Icon
                                                                                            type={TypesIcon.TRASH_BIG}
                                                                                            width="24px"
                                                                                            height="24px"
                                                                                            viewBox="0 0 24 24"
                                                                                        />
                                                                                        Удалить
                                                                                    </StyledDeleteButton>
                                                                                )}
                                                                            </StyledDiv>
                                                                        )}
                                                                    </Draggable>
                                                                ),
                                                            )}
                                                            {provider.placeholder}
                                                        </StyledMainWrapper>
                                                        <StyledAddButton
                                                            typeButton={TypesButton.SECONDARY}
                                                            size={SizesButton.M}
                                                            onClick={() => {
                                                                const newRows = [
                                                                    ...handleGetNewRows(props.values.subcategories),
                                                                    {
                                                                        sortOrder: rows.length + 1,
                                                                        bannerFileId: null,
                                                                        // catalogBannerLogoFileId: null,
                                                                        bannerFile: {
                                                                            id: 0,
                                                                            path: '',
                                                                        },
                                                                        // catalogBannerFileId: null,
                                                                        // catalogBannerFile: {
                                                                        //     id: 0,
                                                                        //     path: '',
                                                                        // },
                                                                        // catalogBannerLogoFile: {
                                                                        //     id: 0,
                                                                        //     path: '',
                                                                        // },
                                                                    },
                                                                ];

                                                                setRows(newRows);
                                                                setFormValue({
                                                                    name: props.values?.name,
                                                                    isMain: props.values?.isMain,
                                                                    status: props.values?.status,
                                                                    sortOrder: props.values?.sortOrder,
                                                                });
                                                            }}
                                                        >
                                                            <Icon
                                                                type={TypesIcon.PLUS_BIG}
                                                                width="24px"
                                                                height="24px"
                                                                viewBox="0 0 24 24"
                                                            />
                                                            Добавить вариант фильтрации
                                                        </StyledAddButton>
                                                    </StyledInputsBlockWrapper>
                                                )}
                                            </FieldArray>
                                            <StyledDeleteButton
                                                typeButton={TypesButton.DELETE}
                                                size={SizesButton.M}
                                                onClick={() => {
                                                    dispatch(
                                                        addModal({
                                                            id: getId()(),
                                                            type: TypesModal.CONFIRM,
                                                            message: {
                                                                description: 'Вы действительно хотите удалить?',
                                                            },
                                                            onSuccessMessage: 'Удалить',
                                                            onSuccess: async () => {
                                                                if (category && category.id) {
                                                                    await dispatch(deleteCatalogSubCategory({ categoryId: category.id, subCategoryId: data.id }));
                                                                    dispatch(getCatalogCategory(`${category.id}`));
                                                                }
                                                            },
                                                            withCloseIcon: true,
                                                        }),
                                                    );
                                                }}
                                            >
                                                <Icon
                                                    type={TypesIcon.TRASH_BIG}
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                />
                                                Удалить подкатегорию
                                            </StyledDeleteButton>
                                        </StyledDataWrapper>
                                    )}
                                </Droppable>

                                <StyledButtonsWrapper>
                                    <SubmitButtons
                                        reset={() => handleResetForm(props.resetForm, props.values)}
                                        submit={props.submitForm}
                                    />
                                </StyledButtonsWrapper>
                            </StyledForm>
                        </DragDropContext>
                    )}
                </Formik>
            </StyledWrapper>
        </StyledRootWrapper>
    );
};

/* const loader = createLoadHoc({
    selectLoader: selectGetSiteBlocksLoader,
});

export const CategoriesBannerWithLoader = loader(CategoriesBannerContent); */
