import { request } from '../../helpers/api/request';
import { GetMainPageMobileContract } from '../../types/Endpoints/mainPage/MainPageContracts';

export const getMobileBanners = async () => {
    const { data } = await request<GetMainPageMobileContract>({
        url: 'banners/mobile/full',
    });

    return data.data;
};
