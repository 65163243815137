import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as brandsApi from '../../../api/brands';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';
import { EditCatalogBrandItemModel } from '../../../types/Endpoints/brands/BrandsContracts';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const EDIT_CATALOG_BRAND = 'brands/editBrands';

const onSuccess = (): Thunk => async dispatch => {
    history.push(routes.brands);

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editCatalogBrand = createAsyncThunk(
    EDIT_CATALOG_BRAND,
    createRequest({
        type: EDIT_CATALOG_BRAND,
        loader: async (requestData: EditCatalogBrandItemModel) => brandsApi.editBrand(requestData),
        onSuccess,
    }),
);
