import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { typography } from '../../helpers/styles/typography';
import { applyColor } from './applyColor';
import { applyBgColor } from './applyBgColor';
import { applyHoverBgColor } from './applyHoverBgColor';
import { applyHoverColor } from './applyHoverColor';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { applyDisabledColor } from './applyDisabledColor';
import { units } from '../../helpers/styles/units';
import { applyHoverBorderColor } from './applyHoverBorderColor';
import { applyBorderColor } from './applyBorderColor';
import { applyDisabledBgColor } from './applyDisabledBgColor';
import { applyDisabledBorderColor } from './applyDisabledBorderColor';
import { fontName } from '../GlobalStyle';

export interface ButtonProps {
    onClick?: (event?: unknown) => void;
    typeButton: TypesButton;
    size?: SizesButton;
    disabled?: boolean;
    className?: string;
    subTitle?: string
}

const StyledButton = styled.button <Pick<ButtonProps, 'typeButton' | 'size' | 'disabled'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${units(4)};
  box-sizing: border-box;
  width: max-content;
  height: ${units(24)};
  
  padding: 0 ${units(16)};

  border-radius: ${units(0)};
  border: 0;

  font-family: ${fontName};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-appearance: none;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  /* transition: background .4s ease, color .4s ease; */


  ${typography(10)};
  ${applyColor};
  ${applyBgColor};
  ${applyBorderColor};  
  ${applyHoverColor};
  ${applyDisabledColor};
  ${applyDisabledBgColor};
  ${applyDisabledBorderColor};
  ${applyHoverBorderColor};
  ${applyHoverBgColor};
`;

const StyledSubTitle = styled.p<Pick<ButtonProps, 'typeButton'>>`
  font-size: 12px;
  line-height: 16px;

  ${applyColor};
  ${applyHoverColor};
`;

export const Button = ({
    children,
    onClick,
    typeButton,
    size = SizesButton.S,
    disabled,
    subTitle,
    ...props
}: PropsWithChildren<ButtonProps>) => (
    <StyledButton
        disabled={disabled}
        onClick={onClick}
        typeButton={typeButton}
        size={size}
        type="button"
        {...props}
    >
        {children}
        {subTitle && <StyledSubTitle typeButton={typeButton}>{subTitle}</StyledSubTitle>}
    </StyledButton>
);
