import styled from 'styled-components';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { PageTitle } from '../../../components/PageTitle';

export const RootWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

export const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 80px;
  padding-top: ${units(20)};    
`;

export const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(16)};
`;

export const InfoItemsWrapper = styled.div`
  display: flex;
  gap: ${units(8)};

  & svg {
    stroke: none;
  }
`;

export const SummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${units(8)};
    padding: ${units(12)} ${units(24)};

    background: ${colors.grey60};
`;

export const SummaryItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${units(4)};
`;

export const Separator = styled.div`
    align-self: flex-end;
    flex-grow: 1;
    margin-bottom: ${units(2)};
    
    border: none;
    border-bottom: 1px dotted ${colors.grey30};
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(4)};
`;

export const InfoTitle = styled.div`
  color: ${colors.greyDark};

  ${typography(5)};
`;

export const InfoDescription = styled(InfoTitle)`
   color: ${colors.greyDark};
`;

export const Price = styled.div`
   font-weight: ${FontWeight.MEDIUM};
    ${typography(5)};
`;
