import { createAsyncThunk } from '@reduxjs/toolkit';
import { JewelryExchangesType } from '../../../types/Endpoints/jewelryExchanges/GetJawelryExchangesContract';
import { createRequest } from '../../../helpers/api/createRequest';
import * as gjewelryExchanges from '../../../api/jewelryЕxchanges';

export const GET_JEWELRY_EXCHANGES = 'jewelryExchanges/getJewelryExchanges';

export const getJewelryExchanges = createAsyncThunk<JewelryExchangesType>(
    GET_JEWELRY_EXCHANGES,
    createRequest({
        type: GET_JEWELRY_EXCHANGES,
        loader: async () => gjewelryExchanges.getJewelryExchanges(),
        showFailNotification: false,
    }),
);
