import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EditCollectionWithLoader } from './loaders';
import { getCatalogCollection } from '../../../../actions/collections/thunks/getCollection';

export const EditCollections = () => {
    const dispatch = useDispatch();
    const { collectionId } = useParams<{collectionId: string}>();

    useEffect(() => {
        dispatch(getCatalogCollection(collectionId));
    }, [collectionId]);

    return <EditCollectionWithLoader />;
};
