import { useDispatch } from 'react-redux';
import { FieldArray, Formik } from 'formik';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import {
    selectGetSiteBlocksLoader,
    selectSiteBodyBlock,
} from '../../../../selectors/mainPage';
import {
    BannerItem,
    BannerItemForm,
    BlockItemEdit,
    WebBlockKey,
} from '../../../../types/Models/MainPage/MainPageModel';
import { useTypeSelector } from '../../../../store';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { TypesButton } from '../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../constants/SizeButton';
import {
    editBannerItemsWeb,
    editWebBanners,
} from '../../../../actions/mainPage';
import { siteBodyBreadcrumbs } from '../../../../constants/BreadCrumbs/siteBodyBreadcrumbs';
import { Icon } from '../../../../components/Icon';
import { TypesIcon } from '../../../../types/TypesIcon';
import { getShotPath } from '../../../../helpers/images/getPath';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { addModal } from '../../../../actions/modal';
import { getId } from '../../../../helpers/generateId';
import { TypesModal } from '../../../../types/TypesModalComponent';
import { isEqualObject } from '../../../../helpers/isEqual';
import { addNotification } from '../../../../actions/notifications';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';
import * as S from './styles';
import { validationSchema } from './schema';

export const CollectionBannerContent = (): JSX.Element => {
    const dispatch = useDispatch();

    const siteBodyBlock = useTypeSelector(
        selectSiteBodyBlock(WebBlockKey.WEB_COLLECTIONS),
    );

    const handleSubmit = (values: any) => {
        const bannerRequestData = {
            buttonText: values.buttonText || null,
            title: values.title || null,
            url: values.url || null,
        };

        const requestData = {
            bannerItems: values.bannerItems.map(
                (item: BannerItemForm, index: number) => ({
                    buttonText: item.buttonText || null,
                    title: item.title || null,
                    text: item.text || null,
                    url: item.url || null,
                    fileId: item.fileImage?.id || null,
                    status: item.status || 'ACTIVE',
                    order: item.order || index + 1,
                    endDate: item.endDate || null,
                    startDate: item.startDate || null,
                    mobileFileId: item.mobileFileImage?.id,
                }),
            ),
        };

        dispatch(
            editWebBanners({
                key: WebBlockKey.WEB_COLLECTIONS,
                newData: bannerRequestData,
            }),
        );
        dispatch(
            editBannerItemsWeb({
                key: WebBlockKey.WEB_COLLECTIONS,
                newData: requestData.bannerItems,
            }),
        );
    };

    const items = siteBodyBlock?.bannerItems.map(item => ({
        ...item,
        fileImage: {
            id: item?.fileId || 0,
            path: item.fileURL ? getShotPath(item.fileURL) : '',
        },
        mobileFileImage: {
            id: item?.mobileFileId || 0,
            path: item?.mobileFileURL ? getShotPath(item.mobileFileURL) : '',
        },
    }));

    const initialValue: BlockItemEdit<WebBlockKey> = {
        buttonText: siteBodyBlock?.buttonText || '',
        title: siteBodyBlock?.title || '',
        text: siteBodyBlock?.text || '',
        url: siteBodyBlock?.url || '',
        name: siteBodyBlock?.name || '',
        key: WebBlockKey.WEB_COLLECTIONS,
        platform: 'WEB',
        type: 'SLIDER',
        bannerItems: items || [],
        bannerProducts: siteBodyBlock?.bannerProducts || [],
        fileURL: siteBodyBlock?.fileURL || '',
        id: siteBodyBlock?.id || 0,
        mobileFileURL: siteBodyBlock?.mobileFileURL || '',
        fileImage: {
            id: siteBodyBlock?.fileId || 0,
            path: siteBodyBlock?.fileURL ? getShotPath(siteBodyBlock.fileURL) : '',
        },
        mobileFileImage: {
            id: siteBodyBlock?.mobileFileId || 0,
            path: siteBodyBlock?.mobileFileURL
                ? getShotPath(siteBodyBlock.mobileFileURL)
                : '',
        },
    };

    const handleResetForm = (calb: () => void, values: BlockItemEdit<WebBlockKey>) => {
        calb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>Body сайта</PageTitle>
                <Breadcrumbs breadcrumbs={siteBodyBreadcrumbs()} />
                <Formik<BlockItemEdit<WebBlockKey>>
                    initialValues={initialValue as unknown as BlockItemEdit<WebBlockKey>}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {props => (
                        <S.StyledForm onSubmit={handleSubmit}>
                            <S.FieldsWrapper>
                                <S.StyledInput
                                    name="title"
                                    onChange={props.handleChange}
                                    value={props.values.title}
                                    title="Заголовок*"
                                    placeholder="Заголовок*"
                                    typeInput="text"
                                    maxLength={255}
                                    isTouched={props.touched?.title}
                                    error={props.errors?.title}
                                    count={props.submitCount}
                                />

                                <S.StyledInput
                                    name="buttonText"
                                    onChange={props.handleChange}
                                    value={props.values.buttonText}
                                    title="Текст кнопки"
                                    placeholder="Текст кнопки"
                                    typeInput="text"
                                    maxLength={255}
                                />

                                <S.StyledInput
                                    name="url"
                                    onChange={props.handleChange}
                                    value={props.values.url}
                                    title="URL*"
                                    placeholder="URL*"
                                    typeInput="text"
                                    isTouched={props.touched.url}
                                    error={props.errors.url}
                                    count={props.submitCount}
                                />
                            </S.FieldsWrapper>
                            <FieldArray name="bannerItems">
                                {({ push, remove }) => (
                                    <S.MainWrapper>
                                        {props.values.bannerItems?.map(
                                            (_block: BannerItem, index: number) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <S.FlexWrapper key={`block${index}`}>
                                                    <S.InpurWrapper>
                                                        <S.StyledInput
                                                            name={`bannerItems[${index}].title`}
                                                            onChange={props.handleChange}
                                                            value={props.values?.bannerItems[index]?.title}
                                                            title="Название коллекции*"
                                                            placeholder="Название коллекции*"
                                                            typeInput="text"
                                                            maxLength={255}
                                                            isTouched={
                                                                props.touched?.bannerItems
                                                                && props.touched?.bannerItems[index]?.title
                                                            }
                                                            error={
                                                                props.errors?.bannerItems
                                                                && props.errors?.bannerItems[index]?.title
                                                            }
                                                            count={props.submitCount}
                                                        />

                                                        <S.StyledInput
                                                            name={`bannerItems[${index}].url`}
                                                            onChange={props.handleChange}
                                                            value={props.values?.bannerItems[index]?.url}
                                                            title="URL*"
                                                            placeholder="URL*"
                                                            typeInput="text"
                                                            isTouched={
                                                                props.touched?.bannerItems
                                                                && props.touched?.bannerItems[index]?.url
                                                            }
                                                            error={
                                                                props.errors?.bannerItems
                                                                && props.errors?.bannerItems[index]?.url
                                                            }
                                                            count={props.submitCount}
                                                        />
                                                    </S.InpurWrapper>

                                                    <S.Uploader
                                                        name={`bannerItems[${index}].fileImage`}
                                                        title="Изображение для desktop версии сайта"
                                                        onChange={props.setFieldValue}
                                                        value={props.values.bannerItems[index].fileImage}
                                                        format="4:4.5"
                                                        maxSize={4}
                                                    />
                                                    <S.Uploader
                                                        name={`bannerItems[${index}].mobileFileImage`}
                                                        title="Изображение для мобильной версии сайта"
                                                        onChange={props.setFieldValue}
                                                        value={
                                                            props.values.bannerItems[index].mobileFileImage
                                                        }
                                                        format="4:4.5"
                                                        maxSize={4}
                                                    />
                                                    <S.DeleteButton
                                                        onClick={() => {
                                                            dispatch(
                                                                addModal({
                                                                    id: getId()(),
                                                                    type: TypesModal.CONFIRM,
                                                                    message: {
                                                                        description:
                                                                            'Вы действительно хотите удалить?',
                                                                    },
                                                                    onSuccessMessage: 'Удалить',
                                                                    onSuccess: () => remove(index),
                                                                    withCloseIcon: true,
                                                                }),
                                                            );
                                                        }}
                                                        typeButton={TypesButton.DELETE}
                                                        size={SizesButton.M}
                                                    >
                                                        <Icon
                                                            type={TypesIcon.TRASH_BIG}
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                        />
                                                        Удалить коллекцию
                                                    </S.DeleteButton>
                                                </S.FlexWrapper>
                                            ),
                                        )}
                                        <S.AddButton
                                            typeButton={TypesButton.SECONDARY}
                                            size={SizesButton.M}
                                            onClick={() => push({
                                                title: '',
                                                url: '',
                                                fileId: {
                                                    id: 0,
                                                    path: '',
                                                },
                                                mobileFileId: {
                                                    id: 0,
                                                    path: '',
                                                },
                                            })}
                                        >
                                            <Icon
                                                type={TypesIcon.PLUS_BIG}
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                color="#000000"
                                            />{' '}
                                            Добавить коллекцию
                                        </S.AddButton>
                                    </S.MainWrapper>
                                )}
                            </FieldArray>
                            <SubmitButtons
                                reset={() => handleResetForm(props.resetForm, props.values)}
                                submit={props.submitForm}
                            />
                        </S.StyledForm>
                    )}
                </Formik>
            </S.Wrapper>
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetSiteBlocksLoader,
});

export const CollectionBannerWithLoader = loader(CollectionBannerContent);
