import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { Checkbox } from '../../../../components/Checkbox';
import { Input } from '../../../../components/Input';
import { colors } from '../../../../constants/Colors';
import { typography } from '../../../../helpers/styles/typography';
import { units } from '../../../../helpers/styles/units';
import { FontWeight } from '../../../../constants/Styles/fontWeight';
import { Button } from '../../../../components/Button';
import { useTypeSelector } from '../../../../store';
import { TypesButton } from '../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../constants/SizeButton';
import { routes } from '../../../../constants/RouterPath';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import { PageTitle } from '../../../../components/PageTitle';
import { UploadPhotoWithMiniature } from '../../../../components/UploadPhotoWithMiniature';
import { UploadFile } from '../../../../types/Endpoints/files/FilesContract';
import { selectGetCatalogBrandLoader, selectSelectedBrand } from '../../../../selectors/brands';
import { EditCatalogBrandItemModel } from '../../../../types/Endpoints/brands/BrandsContracts';
import Switch from '../../../../components/sw';
import { editCatalogBrand, deleteCatalogBrand } from '../../../../actions/brands';
import { Icon } from '../../../../components/Icon';
import { TypesIcon } from '../../../../types/TypesIcon';
import { editBrandBreadcrumbs } from '../../../../constants/BreadCrumbs/editBrandBreadcrumbs';
import { ButtonRoot } from '../../../../components/StyledComponents';
import { addModal } from '../../../../actions/modal';
import { getId } from '../../../../helpers/generateId';
import { TypesModal } from '../../../../types/TypesModalComponent';
import { isEqualObject } from '../../../../helpers/isEqual';
import { addNotification } from '../../../../actions/notifications';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';
import { Textarea } from '../../../../components/TextArea';

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(40)};
  padding-top: ${units(20)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledIdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};

  &:disabled {
    color: ${colors.grey20};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${units(8)};

  padding: ${units(12)};

  max-width: ${units(322)};
`;

const DeleteWrapper = styled(ButtonWrapper)`
  padding: ${units(0)};
  padding-right: ${units(24)};
`;

const BlockWrapper = styled(StyledMainWrapper)`
  gap: ${units(20)};
`;

const StyledOrderWrapper = styled.div`
  width: ${units(88)};
`;

const StyledSubtitle = styled.span`    
    font-weight: ${FontWeight.MEDIUM};

    ${typography(10)};
`;

const SeoFieldsWrapper = styled(StyledMainWrapper)`
    margin-bottom: ${units(10)};
`;

interface CreateBrandModel {
    name: string;
    seoH1: string;
    seoDescription: string;
    seoTitle: string;
    externalId: string;
    urn: string;
    sortNumber: number;
    isShowOnSite: boolean;
    isShowOnMobile: boolean;
    isShowInCollaboration: boolean;
    brandLogoFileId: UploadFile;
    brandSiteFileId: UploadFile;
    brandMobileFileId: UploadFile;
}

const EditBrandContent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedBrand = useTypeSelector(selectSelectedBrand);

    const handleSubmit = (values: CreateBrandModel) => {
        const requestData: EditCatalogBrandItemModel = {
            id: selectedBrand?.id || 0,
            seoH1: values.seoH1,
            seoDescription: values.seoDescription,
            seoTitle: values.seoTitle,
            name: values.name,
            urn: values.urn,
            sortNumber: values.sortNumber,
            externalId: values.externalId,
            isShowOnSite: values.isShowOnSite,
            isShowOnMobile: values.isShowOnMobile,
            isShowInCollaboration: values.isShowInCollaboration,
            brandLogoFileId: values.brandLogoFileId?.id || null,
            brandSiteFileId: values.brandSiteFileId?.id || null,
            brandMobileFileId: values.brandMobileFileId?.id || null,
        };

        dispatch(editCatalogBrand(requestData));
    };

    const handleDeleteBrand = () => {
        if (selectedBrand?.id) dispatch(deleteCatalogBrand(selectedBrand.id));
    };

    const initialValues: CreateBrandModel = {
        name: selectedBrand?.name || '',
        seoH1: selectedBrand?.seoH1 || '',
        seoDescription: selectedBrand?.seoDescription || '',
        seoTitle: selectedBrand?.seoTitle || '',
        urn: selectedBrand?.urn || '',
        externalId: selectedBrand?.externalId || '',
        sortNumber: selectedBrand?.sortNumber || 0,
        isShowOnSite: selectedBrand?.isShowOnSite || false,
        isShowOnMobile: selectedBrand?.isShowOnMobile || false,
        isShowInCollaboration: selectedBrand?.isShowInCollaboration || false,
        brandLogoFileId: {
            id: selectedBrand?.brandLogoFileId || 0,
            path: selectedBrand?.brandLogoFile?.path || '',
        },
        brandSiteFileId: {
            id: selectedBrand?.brandSiteFileId || 0,
            path: selectedBrand?.brandSiteFile?.path || '',
        },
        brandMobileFileId: {
            id: selectedBrand?.brandMobileFileId || 0,
            path: selectedBrand?.brandMobileFile?.path || '',
        },
    };

    const form = useFormik<CreateBrandModel>({
        initialValues,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    const handleResetForm = (values: CreateBrandModel) => {
        history.push(routes.brands);
        const equal = isEqualObject(initialValues, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Бренды</PageTitle>
                <Breadcrumbs breadcrumbs={editBrandBreadcrumbs(selectedBrand?.name)} />
                <StyledRoot>
                    <BlockWrapper>
                        <SeoFieldsWrapper>
                            <StyledIdInfo>SEO</StyledIdInfo>
                            <Textarea
                                name="seoH1"
                                onChange={form.handleChange}
                                value={form.values.seoH1}
                                placeholder="H1"
                                maxLength={255}
                                title="H1"
                                isTouched={form.touched.seoH1}
                                error={form.errors.seoH1}
                            />
                            <Textarea
                                name="seoTitle"
                                onChange={form.handleChange}
                                value={form.values.seoTitle}
                                placeholder="Title"
                                maxLength={255}
                                title="Title"
                                isTouched={form.touched.seoTitle}
                                error={form.errors.seoTitle}
                            />
                            <Textarea
                                name="seoDescription"
                                onChange={form.handleChange}
                                value={form.values.seoDescription}
                                placeholder="Description"
                                maxLength={255}
                                title="Description"
                                isTouched={form.touched.seoDescription}
                                error={form.errors.seoDescription}
                            />
                        </SeoFieldsWrapper>
                        <StyledMainWrapper>
                            <StyledIdInfo>Основное</StyledIdInfo>
                            <StyledInput
                                name="name"
                                onChange={form.handleChange}
                                value={form.values.name}
                                placeholder="Название"
                                typeInput="text"
                                maxLength={255}
                                title="Название"
                                isTouched={form.touched.name}
                                error={form.errors.name}
                            />

                            <StyledInput
                                name="id"
                                onChange={form.handleChange}
                                value={selectedBrand?.id || ''}
                                placeholder="ID"
                                typeInput="text"
                                maxLength={255}
                                title="ID"
                                disabled
                            />

                            <StyledInput
                                name="urn"
                                onChange={form.handleChange}
                                value={form.values.urn}
                                placeholder="URL"
                                typeInput="text"
                                title="URL"
                                isTouched={form.touched.urn}
                                error={form.errors.urn}
                            />

                            <StyledInput
                                name="externalId"
                                onChange={form.handleChange}
                                value={form.values.externalId}
                                placeholder="Элемент выгрузки"
                                typeInput="text"
                                maxLength={255}
                                title="Элемент выгрузки"
                                isTouched={form.touched.externalId}
                                error={form.errors.externalId}
                            />

                            <StyledOrderWrapper>
                                <StyledInput
                                    name="sortNumber"
                                    onChange={form.handleChange}
                                    value={form.values.sortNumber}
                                    placeholder="Номер при показе"
                                    typeInput="number"
                                    maxLength={255}
                                    title="Номер при показе"
                                    isTouched={form.touched.sortNumber}
                                    error={form.errors.sortNumber}
                                />
                            </StyledOrderWrapper>
                        </StyledMainWrapper>
                        <StyledMainWrapper>
                            <StyledIdInfo>Отображение</StyledIdInfo>
                            <Checkbox
                                name="isShowOnSite"
                                title="Отображение на сайте"
                                checked={form.values.isShowOnSite}
                                onChange={form.handleChange}
                            />

                            <Checkbox
                                name="isShowOnMobile"
                                title="Отображение в мобильном приложении"
                                checked={form.values.isShowOnMobile}
                                onChange={form.handleChange}
                            />
                        </StyledMainWrapper>
                        <StyledMainWrapper>
                            <StyledSubtitle>Сотрудничество</StyledSubtitle>
                            <Switch
                                name="isShowInCollaboration"
                                value={form.values.isShowInCollaboration}
                                onChange={() => form.setFieldValue(
                                    'isShowInCollaboration',
                                    !form.values?.isShowInCollaboration,
                                )}
                                type="checkbox"
                                title
                                inactiveTitle="Выключено"
                                activeTitle="Включено"
                            />
                        </StyledMainWrapper>
                    </BlockWrapper>
                    <BlockWrapper>
                        <UploadPhotoWithMiniature
                            name="brandLogoFileId"
                            title="Логотип бренда"
                            onChange={form.setFieldValue}
                            value={form.values.brandLogoFileId}
                            format="1:1"
                            maxSize={0.1}
                        />
                        <UploadPhotoWithMiniature
                            name="brandSiteFileId"
                            title="Баннер в каталог для сайта"
                            onChange={form.setFieldValue}
                            value={form.values.brandSiteFileId}
                            format="7:1"
                            maxSize={3}
                        />
                    </BlockWrapper>
                    <DeleteWrapper>
                        <StyledButton
                            typeButton={TypesButton.DELETE}
                            size={SizesButton.M}
                            onClick={() => {
                                dispatch(
                                    addModal({
                                        id: getId()(),
                                        type: TypesModal.CONFIRM,
                                        message: {
                                            description:
                                                'Вы действительно хотите удалить?',
                                        },
                                        onSuccessMessage: 'Удалить',
                                        onSuccess: handleDeleteBrand,
                                        withCloseIcon: true,
                                    }),
                                );
                            }}
                        >
                            <Icon
                                type={TypesIcon.TRASH_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                            />
                            Удалить
                        </StyledButton>
                        <div />
                    </DeleteWrapper>
                </StyledRoot>
            </StyledWrapper>
            <ButtonRoot>
                <ButtonWrapper>
                    <StyledButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={() => handleResetForm(form.values)}
                    >
                        Отменить
                    </StyledButton>
                    <StyledButton
                        typeButton={TypesButton.PRIMARY}
                        size={SizesButton.M}
                        onClick={form.submitForm}
                    >
                        Сохранить изменения
                    </StyledButton>
                </ButtonWrapper>
            </ButtonRoot>
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetCatalogBrandLoader,
});

export const EditBrandWithLoader = loader(EditBrandContent);
