export const ruchkParker = [
    {
        id: 'GoodsName',
        name: 'Категория',
    },
    {
        id: 'name',
        name: 'Название товара',
    },
    {
        id: 'article',
        name: 'Артикул',
    },
    {
        id: 'price',
        name: 'Цена товара',
    },
    {
        id: 'Brend',
        name: 'Бренд',
    },
    {
        id: 'type_parker',
        name: 'Тип ручки',
    },
    {
        id: 'Stranabrenda',
        name: 'Страна бренда',
    },
    {
        id: 'Garantiya',
        name: 'Гарантия',
    },
    {
        id: 'Materialkorpusa',
        name: 'Материал корпуса',
    },
    {
        id: 'Tsvetkorpusa',
        name: 'Цвет корпуса',
    },
    {
        id: 'Nib',
        name: 'Материал пера',
    },
    {
        id: 'Liniyapisma',
        name: 'Толщина линии',
    },
    {
        id: 'clip',
        name: 'Цвет зажима',
    },
];
