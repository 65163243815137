export enum NamesButtonsPriceOffers {
    addShop = 'Добавить магазины',
    deleteSection = 'Удалить раздел',
    addSection = 'Добавить новый раздел цены и способа покупки',
    cancel = 'Отмена',
    save = 'Сохранить изменения',
    clearFilter = 'Очистить фильтр',
}

export enum NamesFieldsFormPriceOffers {
    size = 'size',
}

export enum LabelsFormPriceOffers {
    size = 'Размер',
    weight = 'Вес',
    insert = 'Вставка',
    mainPrice = 'Основная цена',
    discountPrice = 'Цена со скидкой',
    cardClientPrice = 'Цена по карте клиента',
}

export enum TitlesSwitchPriceOffers {
    on = 'Включено',
    off = 'Отключено',
}

export const messages = {
    addedShops: (num: number) => `Добавлено ${num} магазинов для этого товара`,
    opportunityBuyProduct: 'Возможность покупки товара online',
    products: 'Товары',
    addShops: 'Добавить магазины',
    loadShopsFromList: 'Загрузить магазины из списка',
    region: 'Область',
};

export const SALT = '50005';

export const VALUE_SALE_ONLINE_ON = 1;

export const VALUE_SALE_ONLINE_OFF = 0;
