import styled from 'styled-components';
import { ReactNode, memo } from 'react';
import { Load } from './Load';
import { units } from '../../helpers/styles/units';

// flex-basis - на случай, когда прелоадер окажется в flex-контейнере, чтобы он показывался по центру
const Root = styled.div<{withoutPaddings?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
  
  height: 100%;
  width: 100%;

  padding: ${({ withoutPaddings }) => (withoutPaddings ? `${units(1)} 0` : `${units(25)} 0`)};
`;

const Title = styled.span`
  margin-top: ${units(4)};
  padding-left: ${units(3)};
  font-weight: 700;
`;

export interface PreloaderProps {
    title?: ReactNode;
    withoutPaddings?: boolean;
}

// eslint-disable-next-line react/display-name
export const Preloader = memo(
    ({ title = 'Пожалуйста подождите!', withoutPaddings }: PreloaderProps) => (
        <Root withoutPaddings={withoutPaddings}>
            <Load />
            {title && <Title>{title}</Title>}
        </Root>
    ),
);
