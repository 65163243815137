import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_PROPERTIES } from '../../actions/properties';

const selectRoot = ({ catalog }: RootState) => catalog;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetPropertiesLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_PROPERTIES],
);

export const selectProperties = createSelector(
    selectRoot,
    ({ properties }) => properties,
);
