import styled from 'styled-components';
import { PropsWithChildren } from 'react';
import { typography } from '../../helpers/styles/typography';
import { applyColor } from './applyColor';
import { applyHoverBgColor } from './applyHoverBgColor';
import { applyHoverColor } from './applyHoverColor';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { applyDisabledColor } from './applyDisabledColor';
import { applyDisabledBorderColor } from './applyDisabledBorderColor';
import { TypesIcon } from '../../types/TypesIcon';
import { Icon } from '../Icon';
import { colors } from '../../constants/Colors';

export interface ButtonProps {
  onClick?: (event?: unknown) => void;
  typeButton: TypesButton;
  size?: SizesButton;
  disabled?: boolean;
  className?: string;
}

const StyledButton = styled.button<
  Pick<ButtonProps, 'typeButton' | 'size' | 'disabled'>
>`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: white;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-appearance: none;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${typography(10)};
  ${applyColor};


  ${applyHoverColor};

  ${applyDisabledColor};

  ${applyDisabledBorderColor};

  ${applyHoverBgColor};
`;

export const DeleteButton = ({
    onClick,
    typeButton,
    size = SizesButton.S,
    disabled,
    ...props
}: PropsWithChildren<ButtonProps>) => (
    <StyledButton
        disabled={disabled}
        onClick={onClick}
        typeButton={typeButton}
        size={size}
        type="button"
        {...props}
    >
        <Icon
            type={TypesIcon.TRASH}
            color={colors.grey20}
            width="24px"
            height="24px"
            viewBox="0 0 24 25"
        />
    </StyledButton>
);
