import { request } from '../../helpers/api/request';
import { EditMainPageMobileContract } from '../../types/Endpoints/mainPage/MainPageContracts';
import { MobileBlockKey } from '../../types/Models/MainPage/MainPageModel';

export const editMobileBanners = async ({ key, newData }:{key: MobileBlockKey, newData: EditMainPageMobileContract['requestBody']}) => {
    const { data } = await request<EditMainPageMobileContract>({
        url: `admin/banners/${key}`,
        method: 'PATCH',
        data: newData,
    });

    return data;
};
