import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { PageTitle } from '../../components/PageTitle';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { Button } from '../../components/Button';

export const Root = styled.div`
  display: flex;
  padding-top: ${units(20)};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

export const PanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
  padding-top: ${units(14)};
`;

export const SearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

export const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
`;

export const FilterWindow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: absolute;
  top: 65px;
  right: 0;
  box-shadow: 0px 0px 20px 0px rgba(57, 56, 54, 0.2) !important;
  border-radius: 0;
  background-color: #f7f7f7;
  padding: 32px 40px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const IdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};

  ${typography(10)};
`;

export const FilterWrapper = styled.div`
  position: relative;
`;

export const FilterButtonsWrapper = styled.div`
  position: relative;
  margin-left: auto;
`;

export const FilterButton = styled.button`
  padding: 0 ${units(8)};

  border: none;
  background: none;
  /* color: ${colors.greyDark}; */
  -webkit-appearance: none;

  ${typography(1)};

  &:hover {
    color: ${colors.gold};
    cursor: pointer;
  }
  color: ${props => (props.className === 'isActive' ? colors.gold : colors.greyDark)};
`;

export const CalendarWrapper = styled.div`
  position: relative;
`;

export const ButtonClear = styled(Button)`
  width: fit-content;
  height: fit-content;
  padding: 0;
`;
