import { request } from '../../helpers/api/request';
import { EditBannerItemsContract } from '../../types/Endpoints/mainPage/MainPageContracts';
import { BannerItemForEdit, MobileBlockKey, WebBlockKey } from '../../types/Models/MainPage/MainPageModel';

export const editBannerItems = async ({ key, newData }:{key: MobileBlockKey | WebBlockKey, newData: BannerItemForEdit[]}) => {
    const { data } = await request<EditBannerItemsContract>({
        url: `admin/banners/${key}/items`,
        method: 'PUT',
        data: {
            bannerItems: newData,
        },
    });

    return data;
};
