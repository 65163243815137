import { createReducer } from '@reduxjs/toolkit';
import { CreateBlocksModel } from '../../api/dinamicPages/updatePage';
import { getPage } from '../../actions/dinamicPages/thunks';

export interface PagesState {
  dinamicPage?: CreateBlocksModel | null;
}

export const dinamicPages = createReducer<PagesState>({}, builder => {
    builder.addCase(getPage.fulfilled, (state, { payload }) => ({
        ...state,
        dinamicPage: payload,
    }));
});
