import { TypesButton } from '../../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../../constants/SizeButton';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { Icon } from '../../../../../components/Icon';
import { SearchBar } from '../../../../../components/SearchBar';
import { siteBodyBreadcrumbs } from '../../../../../constants/BreadCrumbs/siteBodyBreadcrumbs';
import { Breadcrumbs } from '../../../../../components/Breadcrumbs';
import { colors } from '../../../../../constants/Colors';
import { WebBlockKey } from '../../../../../types/Models/MainPage/MainPageModel';
import { SiteBannersTable } from '../../../../../components/Table/SiteBannersTable';
import { PageStatus } from '../constants';
import { selectSearchData } from '../../../../../selectors/search';
import { useTypeSelector } from '../../../../../store';
import { SiteBannersSearchTable } from '../../../../../components/Table/SiteBannerSearchTable';
import * as S from './styles';

interface ComponentProps {
  changePage: (key: PageStatus) => void;
}

export const MainBannerTablePageContent = ({
    changePage,
}: ComponentProps): JSX.Element => {
    const searchString = useTypeSelector(selectSearchData);

    return (
        <S.Wrapper>
            <S.StyledPageTitle>Body сайта</S.StyledPageTitle>
            <Breadcrumbs breadcrumbs={siteBodyBreadcrumbs()} />
            <S.PanelWrapper>
                <S.SearchBarWrapper>
                    <SearchBar />
                </S.SearchBarWrapper>
                <S.StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => changePage(PageStatus.ADD)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </S.StyledButton>
            </S.PanelWrapper>
            <S.Root>
                {!searchString
                    ? (
                        <SiteBannersTable
                            bannerKey={WebBlockKey.WEB_MAIN}
                            changePage={changePage}
                        />
                    ) : (
                        <SiteBannersSearchTable
                            bannerKey={WebBlockKey.WEB_MAIN}
                            changePage={changePage}
                            searchString={searchString}
                        />
                    )}
            </S.Root>
        </S.Wrapper>
    );
};
