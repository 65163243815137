import { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface HeroProps {
    className?: string
}

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledWrapper = styled.div`
  width: 100%;
  min-height: 100dvh;
  padding: 0;
`;

export const Hero = ({ className, children }: PropsWithChildren<HeroProps>) => (
    <StyledRoot className={className}>
        <StyledWrapper>
            {children}
        </StyledWrapper>
    </StyledRoot>
);
