import { EditCatalogCategoryContract, EditCatalogCategoryItem } from '../../types/Endpoints/categories/CategoriesContracts';
import { request } from '../../helpers/api/request';

export const editCategory = async ({ id, ...requestData }: EditCatalogCategoryItem) => {
    const { data } = await request<EditCatalogCategoryContract>({
        url: `admin/categories/${id}`,
        method: 'PATCH',
        data: requestData,
    });

    return data;
};
