import { EndpointContract } from '../EndpointContract';

export enum FilterTypes {
  RADIO = 'RADIO',
  CHECK_BOX = 'CHECK_BOX',
  RANGE = 'RANGE',
}

export interface FilterItem {
  propertyId: number;
  name: string;
  isMain: boolean;
  urn: string;
  order: number;
  type: FilterTypes;
  isShowOnSite: boolean;
  isShowOnMobile: boolean;
  filterCategories: {
    categoryId: number;
  }[];
  filterPromotions?: {
    promotionId: number;
  }[];
}

export interface GetFilterItem {
  id: number;
  propertyId: number;
  isEnabled: boolean;
  name: string;
  isMain: boolean;
  urn: string;
  order: string | null;
  type: FilterTypes;
  isShowOnSite: boolean;
  isShowOnMobile: boolean;
  property: {
    id: number;
    name: string;
    type: string;
    code: string;
  };
  filterCategories: {
    id: number;
    name: string;
  }[];
}
export interface FilterItemProperty {
  id: number;
  name: string;
  type: string;
  code: string;
}

export interface FilterListItem {
  id: number;
  name: string;
  order: number;
  property: FilterItemProperty;
}

export interface AddFilterContract extends EndpointContract {
  responseBody: FilterItem;
}

export interface GetFilterContract extends EndpointContract {
  responseBody: GetFilterItem;
}

export interface GetFiltersContract extends EndpointContract {
  pathParams: {
    page: number;
    size: number;
    search?: string;
  };

  responseBody: {
    isLast: true;
    page: number;
    size: number;
    count: number;
    data: FilterListItem[];
  };
}

export interface EditFilterContract extends EndpointContract {
  requestBody: Partial<FilterItem>;
}

export interface CreateFilterContract extends EndpointContract {
  requestBody: FilterItem;
}
