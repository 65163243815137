import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { units } from '../../helpers/styles/units';
import { selectActiveUser } from '../../selectors/users';
import { useTypeSelector } from '../../store';
import { typography } from '../../helpers/styles/typography';
import { colors } from '../../constants/Colors';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { Roles } from '../../constants/Roles';
import { getRole } from '../../helpers/auth/roles';
import { TypesModal } from '../../types/TypesModalComponent';
import { addModal } from '../../actions/modal/addModal';
import { getId } from '../../helpers/generateId';
import { logout } from '../../actions/auth/thunks';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  position: absolute;
  top: ${units(20)};
  right: ${units(12)};
  z-index: 100;

  gap: ${units(10)};
  height: ${units(22)};
`;

const StyledWrapper = styled.div`
  color: ${colors.greyDark};
  display: flex;

  padding: ${units(0)} 0;

  font-weight: ${FontWeight.REGULAR};

  ${typography(5)};
`;

const StyledWrapperRole = styled(StyledWrapper)`
  color: ${colors.grey20};
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${units(4)};

  padding: ${units(5)} ${units(5)} ${units(5)} ${units(10)};
  max-width: ${units(50)};
  height: ${units(12)};

  font-weight: ${FontWeight.BOLD};

  color: ${colors.greyDark};
  background-color: ${colors.light};
  border: none;
  border-left: 1px solid ${colors.grey40};
  -webkit-appearance: none;
  outline: none;

  cursor: pointer;

  ${typography(5)};

  &:hover {
    color: ${colors.red};    
  }

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%) hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active {
    color: ${colors.vine};    
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%) hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

const StyledDiv = styled.div`
  display: flex;
  width: ${units(100)};
  flex-direction: column;
  align-items: flex-start;
  gap: ${units(4)};
  flex-shrink: 0;
`;

const StyledIcon = styled(Icon)`


`;

export const AuthInfo = () => {
    const dispatch = useDispatch();
    const user = useTypeSelector(selectActiveUser);

    const handleClick = () => {
        dispatch(addModal({
            id: getId()(),
            type: TypesModal.CONFIRM,
            message: { description: 'Вы действительно хотите выйти?' },
            onSuccessMessage: 'Выйти',
            onSuccess: () => dispatch(logout()),
        }));
    };

    return (
        <StyledRoot>
            <StyledDiv>
                <StyledWrapper>{user?.login}</StyledWrapper>
                <StyledWrapperRole>{getRole(user?.role as Roles)}</StyledWrapperRole>
            </StyledDiv>
            <StyledButton type="button" onClick={handleClick}>
                Выйти
                <StyledIcon
                    type={TypesIcon.LOGOUT}
                    color={colors.grey20}
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                />
            </StyledButton>
        </StyledRoot>
    );
};
