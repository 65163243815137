import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as documentsApi from '../../../api/docs';
import { DocumentItem } from '../../../types/Endpoints/documents/DocumentsContracts';

export const GET_DOCUMENTS = 'documents/getDocuments';

export const getDocuments = createAsyncThunk<DocumentItem[]>(
    GET_DOCUMENTS,
    createRequest({
        type: GET_DOCUMENTS,
        loader: async () => documentsApi.getDocuments(),
    }),
);
