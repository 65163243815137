import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BlogsContent } from './loaders';
import { getBlogsThunk } from '../../actions/blog/thunks/getBlogs';

export const Blog = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBlogsThunk({}));
    }, []);

    return <BlogsContent />;
};
