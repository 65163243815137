import styled from 'styled-components';
import { UsePaginationItem } from '@mui/material/usePagination';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { Icon } from '../Icon';
import { typography } from '../../helpers/styles/typography';

interface PaginationProps {
    items: UsePaginationItem[];
}

const List = styled('ul')`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: center;
`;

const ListItem = styled('li')`
    list-style: none;
    height: fit-content;
    margin: 0 8px;
`;

const StyledPage = styled('button')<{selected: boolean}>`
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    cursor: pointer;
    ${typography(10)};
    color: ${colors.greyDark};
    border-bottom: ${props => (props.selected ? `1px solid ${colors.greyDark};` : 'none')};
`;

const StyledControlPrev = styled('button')`
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  transform: rotate(180deg);
`;

const StyledControl = styled('button')`
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Pagination = ({
    items,
}: PaginationProps) => (
    <nav>
        <List>
            {items.filter(item => item.type === 'previous' || item.type === 'next' || item.type === 'page').map(({
                page, type, selected, ...item
            }, index) => {
                let children = null;

                if (type === 'page') {
                    children = (
                        <StyledPage
                            selected={selected}
                            type="button"
                            {...item}
                        >
                            {page}
                        </StyledPage>
                    );
                }

                if (type === 'previous') {
                    children = (
                        <StyledControlPrev type="button" {...item}>
                            <Icon
                                type={TypesIcon.ARROW_DEFAULT}
                                color={item.disabled ? colors.grey20 : colors.greyDark}
                                width="16px"
                                height="16px"
                                viewBox="0 0 16 16"
                            />
                        </StyledControlPrev>
                    );
                }

                if (type === 'next') {
                    children = (
                        <StyledControl type="button" {...item}>
                            <Icon
                                type={TypesIcon.ARROW_DEFAULT}
                                color={item.disabled ? colors.grey20 : colors.greyDark}
                                width="16px"
                                height="16px"
                                viewBox="0 0 16 16"
                            />
                        </StyledControl>
                    );
                }

                // eslint-disable-next-line react/no-array-index-key
                return <ListItem key={index}>{children}</ListItem>;
            })}
        </List>
    </nav>
);
