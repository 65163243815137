import { createSelector } from '@reduxjs/toolkit';
import { GET_CALCULATOR_ALL } from '../../actions/calculator/thunks/getCalculatorAll';
import { RootState } from '../../store';

const selectRoot = ({ calculator }: RootState) => calculator;

const selectRootLoaders = ({ loaders }: RootState) => loaders;

export const selectCalculatorLoader = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_CALCULATOR_ALL],
);

export const selectCalculator = createSelector(
    selectRoot,
    calculator => calculator?.calculator,
);

export const selectCalculatorExchange = createSelector(
    selectRoot,
    calculator => calculator?.calculator?.filter(el => el.isZiko),
);

export const selectCalculatorPurchase = createSelector(
    selectRoot,
    calculator => calculator?.calculator?.filter(el => !el.isZiko),
);
