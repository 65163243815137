import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as orderSettingsApi from '../../../api/orderSettings';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { EditOrderSettingsItem } from '../../../types/Endpoints/orderSettings/GetOrderSettingsContract';
import { getOrderSettings } from './getOrderSettings';

export const EDIT_ORDER_SETTINGS = 'orderSettings/editOrderSettings';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(getOrderSettings());

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editOrderSettings = createAsyncThunk(
    EDIT_ORDER_SETTINGS,
    createRequest({
        type: EDIT_ORDER_SETTINGS,
        loader: async (requestData: EditOrderSettingsItem[]) => orderSettingsApi.editOrderSettings(requestData),
        onSuccess,
    }),
);
