import {
    ComponentType,
    NamedExoticComponent,
    useEffect,
    useState,
} from 'react';
import { Selector, connect } from 'react-redux';
import { isEqual } from 'lodash';
import { PRELOADER_SHOW_TIME } from '../../constants/PreloaderShowTime';
import { Preloader, PreloaderProps } from '../../components/Preloader';
import { ErrorStubProps } from '../../components/ErrorStub';
import { Thunk } from '../redux/Thunk';
import { Loader as LoaderType } from '../../types/Loaders/Loader';
import { LoaderState } from '../../types/Loaders/LoaderState';
import { AppDispatch, RootState } from '../../store';

interface CreateLoadHoc<OwnProps> {
  loadAction?: (props: OwnProps) => Thunk;
  selectLoader: Selector<RootState, LoaderType>;
  Loader?: NamedExoticComponent<PreloaderProps>;
  ErrorPlaceholder?: NamedExoticComponent<ErrorStubProps>;
  needRerenderAlways?: boolean;
  withPreloader?: boolean;
  withError?: boolean
  skipPreloader?: boolean;
  loaderWithoutPadding?: boolean;
  loaderTitle?: string;
}

interface WrapperProps {
  dataLoader: LoaderType;
  dispatchLoadAction?: () => Thunk;
}

export const createLoadHoc = <OwnProps extends Record<string, any>>({
    loadAction,
    selectLoader,
    Loader = Preloader,
    needRerenderAlways,
    withPreloader = true,
    skipPreloader = false,
    loaderWithoutPadding = false,
    loaderTitle,
}: CreateLoadHoc<OwnProps>) => {
    let cachedProps = {};

    const connectHoc = connect<Partial<Pick<WrapperProps, 'dataLoader'>>, unknown, OwnProps, RootState>(
        state => ({ dataLoader: selectLoader(state) }),
        (dispatch: AppDispatch, props) => ({
            dispatchLoadAction: () => loadAction && dispatch(loadAction(props)),
        }),

    );

    return (
        Wrapped: ComponentType<OwnProps>,
        {
            Loading: LoadingComponent = Loader,
            // LoadError: LoadErrorComponent = ErrorPlaceholder,
        } = {},
    ) => {
        const Wrapper = (props: Partial<WrapperProps>) => {
            const { dataLoader, dispatchLoadAction, ...restProps } = props;
            const [shouldShowContent, setShouldShowContent] = useState(skipPreloader);
            const isPropsChanged = !isEqual(cachedProps, restProps);
            const isReloadWhenLoaded = dataLoader?.state === LoaderState.FULFILLED && needRerenderAlways;

            useEffect(() => {
                if (dataLoader?.state === LoaderState.PENDING) {
                    setShouldShowContent(false);
                    return;
                }

                setTimeout(() => {
                    setShouldShowContent([LoaderState.FULFILLED, LoaderState.REJECT].includes(dataLoader?.state || LoaderState.PENDING));
                }, PRELOADER_SHOW_TIME);
            }, [dataLoader]);

            useEffect(() => {
                if (isPropsChanged) {
                    cachedProps = { ...cachedProps, ...restProps };
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [isPropsChanged, cachedProps]);

            useEffect(() => {
                if ((!dataLoader?.state || isReloadWhenLoaded) && dispatchLoadAction) {
                    dispatchLoadAction();
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [isPropsChanged]);

            if (withPreloader && !shouldShowContent) {
                return <LoadingComponent title={loaderTitle} withoutPaddings={loaderWithoutPadding} />;
            }
            return <Wrapped {...restProps as OwnProps} />;
        };

        return connectHoc(Wrapper);
    };
};
