import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { MainBunnerWithLoader } from './loaders';
import { getMobileBanners } from '../../../../actions/mainPage/thunks';

export const MainBanner = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMobileBanners());
    }, []);

    return <MainBunnerWithLoader />;
};
