import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { typography } from '../../helpers/styles/typography';
import { units } from '../../helpers/styles/units';
import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';
import { TypesIcon } from '../../types/TypesIcon';
import { Icon } from '../Icon';
import { colors } from '../../constants/Colors';
import { FontWeight } from '../../constants/Styles/fontWeight';

const RootStyle = styled.nav`
  max-width: ${units(640)};
  width: 100%;

  display: flex;
  align-items: center;

  @media (max-width: ${BreakPoints.XL}) and (min-width: ${BreakPoints.LG}) {
    max-width: ${units(490)};
  }
`;

const BreadcrumbLink = styled(NavLink)`
  cursor: pointer;
  color: ${colors.grey20};
  letter-spacing: 0px;

  text-decoration: none;

  ${typography(5)};

  :hover {
    font-weight: ${FontWeight.MEDIUM};
  }
`;

const BreadcrumbText = styled.p`
  cursor: pointer;
  color: ${colors.grey20};
  letter-spacing: 0.7px;

  text-decoration: none;

  ${typography(5)};
`;

const BreadcrumbsLastItem = styled.div`
  color: ${colors.greyDark};

  ${typography(5)};
`;

const StyledSlashIcon = styled(Icon)`
  margin: 0 ${units(8)};
`;

interface BreadcrumbsProps {
    breadcrumbs: BreadcrumbsModel[];
    className?: string;
}

export const Breadcrumbs = ({ breadcrumbs, className }: BreadcrumbsProps) => (
    <RootStyle>
        {
            breadcrumbs.map(breadcrumbItem => (
                breadcrumbs[breadcrumbs.length - 1] !== breadcrumbItem
                    ? (
                        <Fragment key={breadcrumbItem.key}>
                            {breadcrumbItem?.url ? (
                                <BreadcrumbLink
                                    className={className}
                                    to={breadcrumbItem?.url}
                                    rel="nofollow noopener noreferrer"
                                >
                                    {breadcrumbItem.value}
                                </BreadcrumbLink>
                            ) : (
                                <BreadcrumbText className={className}>
                                    {breadcrumbItem.value}
                                </BreadcrumbText>
                            )}
                            <StyledSlashIcon
                                type={TypesIcon.ARROW_RIGHT}
                                className={className}
                                width="16px"
                                height="16px"
                                viewBox="0 0 16 16"
                            />
                        </Fragment>
                    ) : (
                        <BreadcrumbsLastItem className={className} key={breadcrumbItem.key}>
                            {breadcrumbItem.value}
                        </BreadcrumbsLastItem>
                    )
            ))
        }
    </RootStyle>
);
