import styled from 'styled-components';
import DatePicker from 'react-multi-date-picker';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { units } from '../../helpers/styles/units';
import LeftIcon from '../../styles/images/calendar/cal-l.png';
import RightIcon from '../../styles/images/calendar/cal-r.png';
import { colors } from '../../constants/Colors';
import { fontName } from '../GlobalStyle';

export const Picker = styled(DatePicker)`
  position: relative;
    right: ${units(12)};
  box-shadow: 0 0 20px 0 #39383633;
  border-radius: 0;
  background-color: #F7F7F7;
  padding: 32px 72px;
  display: flex;
  flex-direction: column-reverse;  

    .custom-calendar.rmdp-wrapper,
    .rmdp-container .custom-calendar.ep-arrow::after {
        background-color: bisque;
    }

    .rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
        border-top: 1px solid bisque;
    }

    .rmdp-container .custom-calendar.ep-arrow[direction="top"] {
        border-bottom: 1px solid bisque;
    }

& .rmdp-header-values {
    font-weight: ${FontWeight.MEDIUM};

    ${typography(10)};
  }

  & .rmdp-header {    
    padding-bottom: ${units(4)};
}

  & .rmdp-header > div {    
      position: static !important;    
  }
  
  & .rmdp-arrow-container {
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    width: 24px;
    height: 24px;
    border-radius: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
      

    & i {
      opacity: 0;
    }

    &:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  & .rmdp-arrow-container.rmdp-left {
    background: url(${LeftIcon});
    margin-left: 32px;  
  }
  & .rmdp-arrow-container.rmdp-right {
    background: url(${RightIcon});
    margin-right: 32px;  
  }

  & .rmdp-day {
    background-color: #F7F7F7;
  }

  & .rmdp-day span {
    font-weight: ${FontWeight.REGULAR};    

    ${typography(10)};    
  }

  & .rmdp-range {
    background-color: ${colors.white};
    color: ${colors.greyDark};
    box-shadow: none;
  }

  & .end {
    background-color: ${colors.grey20};
    color: ${colors.white};
    
  }

  & .start {
    background-color: ${colors.grey20};
    color: ${colors.white};    
  }

  & .rmdp-range.start:not(.force) {
    border-radius: 0;
  }

  & .rmdp-range.end:not(.force) {
    border-radius: 0;
  }

  & .rmdp-today span {
    background-color: ${colors.grey20};
  }

  & .rmdp-week:first-child {
    display: none;
  }

  & .rmdp-header-values {
    span:not(:first-child) {
      display: none;
  }

  & .rmdp-calendar. rmdp-day-picker {
    gap: ${units(20)} !important;
 }

  
`;

export const YearButton = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 0;
  outline: none;
  border: none;
  background: none;
  -webkit-appearance: none;
  &:hover {
    cursor: pointer;
  }
`;

export const YearButtonRotate = styled(YearButton)`
    transform: rotate(180deg);
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(16)};

  padding-left: 24px 
`;

export const YearWrapper = styled(StyledDiv)`
  gap: ${units(8)};

  padding-left: 0;
`;

export const Div = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: ${units(8)};

    .rmdp-container .ep-arrow.custom-arrow::after {
        display: none;
    }
    .rmdp-container .ep-arrow.custom-arrow {
        display: none;
    }
    
    input.my-input {
        max-width: ${units(74)};
        
        border: none;
        border-radius: 0;
        appearance: none;
        outline: none;
        padding: 10px;
        background: ${colors.light};
        
        border-bottom: 1px solid ${colors.grey30};
        font-family: ${fontName};
        
        ${typography(10)};
    }
    
    .icon {
        opacity: 0;
    }
`;

export const CalendarIcon = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    right: 8px;
    height: 24px;
    padding-left: 8px;
    border-left: 1px solid ${colors.grey30};
    
    color: ${colors.grey30};
    cursor: pointer;
    pointer-events: none;
`;
