import { Note } from '../../types/Endpoints/notes/NotesContracts';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { colors } from '../../constants/Colors';

export interface ComponentProps {
  notes: Note[];
}

export const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: colors.grey60,
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
        '& .MuiTableCell-root': {
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '0.8px',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: colors.white,
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
        '& .MuiTableCell-root': {
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '0.8px',
        },
        '& .MuiTableCell-root:nth-child(3)': {
            color: colors.grey20,
        },
    },
};
