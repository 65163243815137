import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';
import { routes } from '../RouterPath';

export const createGoodsBreadcrumbs = (brandName = 'Редактировать') : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Товары',
        url: routes.goods,
    },
    {
        key: '2',
        value: brandName,
    },
]);
