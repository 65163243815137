import { css } from 'styled-components';
import { SelectProps } from '../../types/Select/SelectProps';

export const applyStyleCursor = ({ disabled, readonly }: Pick<SelectProps, 'disabled' | 'readonly'>) => {
    switch (true) {
        case disabled:
            return css`
              cursor: not-allowed;
            `;
        case readonly:
            return 'cursor: default';
        default:
            return 'cursor: pointer';
    }
};
