import { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectModals } from '../../selectors/modals';
import { Modal } from '../Modal';
import { colors } from '../../constants/Colors';

const StyledModalsContainer = styled.div<{ height: number, width: number }>`
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9999;

  width: 100%;
  height: 100%;

  background: ${colors.grey10_40};
`;

export const Modals = () => {
    const modals = useSelector(selectModals);
    const ref = useRef<HTMLDivElement>(null);
    const modalHeight = ref.current?.offsetHeight || 0;
    const modalWidth = ref.current?.offsetWidth || 0;

    if (modals?.length === 0) {
        return null;
    }

    return (
        <StyledModalsContainer
            ref={ref}
            height={modalHeight}
            width={modalWidth}
        >
            {modals.map(({
                id,
                ...modal
            }) => (
                <Modal
                    key={id}
                    id={id}
                    {...modal}
                />
            ))}
        </StyledModalsContainer>
    );
};
