import { request } from '../../helpers/api/request';
import { GetCitiesContract } from '../../types/Endpoints/cities/GetCitiesContract';

export const getCities = async () => {
    const { data } = await request<GetCitiesContract>({
        url: 'cities/',
    });

    return data;
};
