import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EditBlogWithLoader } from './loaders';
import { getBlogThunk } from '../../../actions/blog/thunks/getBlog';

export const EditBlog = () => {
    const dispatch = useDispatch();
    const { blogId } = useParams<{blogId: string}>();

    useEffect(() => {
        dispatch(getBlogThunk(blogId));
    }, []);

    return <EditBlogWithLoader />;
};
