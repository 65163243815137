import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import { Thunk } from '../../../helpers/redux/Thunk';
import * as certificatesApi from '../../../api/certificates';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { getCertificates } from './getCertificates';
import { SetCertificatesItem } from '../../../types/Endpoints/certificates/CertificatesContracts';

export const SET_CERTIFICATES = 'certificates/setCertificates';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(getCertificates());

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};
export const setCertificates = createAsyncThunk(
    SET_CERTIFICATES,
    createRequest({
        type: SET_CERTIFICATES,
        loader: async (requestData: SetCertificatesItem[]) => certificatesApi.setCertificates(requestData),
        onSuccess,
    }),
);
