import { createReducer } from '@reduxjs/toolkit';
import { getTagsThunk } from '../../actions/blog/thunks/getTags';
import { GetTags } from '../../types/Endpoints/blog/BlogContracts';
import { getBlogsThunk } from '../../actions/blog/thunks/getBlogs';
import { GetBlogContract, GetBlogItemContract } from '../../types/Endpoints/blog/BlogCRUDContracts';
import { getBlogThunk } from '../../actions/blog/thunks/getBlog';

export interface CollectionsModel {
  tags: GetTags['responseBody'];
  blogs: GetBlogContract['responseBody'];
  currentBlog?: GetBlogItemContract['responseBody'];
}

export const blog = createReducer<CollectionsModel>({
    tags: [],
    blogs: [],
}, builder => {
    builder.addCase(getTagsThunk.fulfilled, (state, { payload }) => ({
        ...state,
        tags: payload,
    }));
    builder.addCase(getBlogsThunk.fulfilled, (state, { payload }) => ({
        ...state,
        blogs: payload,
    }));
    builder.addCase(getBlogThunk.fulfilled, (state, { payload }) => ({
        ...state,
        currentBlog: payload,
    }));
});
