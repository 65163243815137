import ScrollContainer from 'react-indiana-drag-scroll';
import { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { TypesButton } from '../../../constants/ButtonTypes';
import { MapSizeTypes } from '../../../types/ResizeMap';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';

interface ScaleProps {
    scale?:number
}

const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  
  & .mapImage {
     max-width: 90%;
  }
`;

const StyledImage = styled.img<ScaleProps>`
  width: 100%;
  max-height: 85vh;

  transform-origin: top left;
  
  transform: scale(${({ scale }) => scale});
`;

const StyledImageController = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${units(23)};
  
  & button {
    width: ${units(11)};
    height: ${units(11)};
    color:${colors.gray}
  }
`;

const StyledButton = styled(Button)`
  :first-child {
    border-radius: ${units(3)} ${units(3)} 0 0;
  }

  :last-child {
    border-radius: 0 0 ${units(3)} ${units(3)};
  }
`;

const StyledMapViewerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${units(10)} ${units(6)};
  width: 100%;
`;

export const MapViewer = ({ image }: {image?: string}) => {
    const [imageScale, setImageScale] = useState(1);

    const handleResize = (resizeType:MapSizeTypes) => {
        if (resizeType === MapSizeTypes.ENLARGE) {
            setImageScale(prevState => prevState + 0.2);
        } else if (resizeType === MapSizeTypes.REDUCE) {
            setImageScale(prevState => {
                if (prevState <= 1) {
                    return prevState;
                }
                return prevState - 0.2;
            });
        }
    };

    return (
        <StyledMapViewerWrapper>
            <StyledImageWrapper>
                <ScrollContainer className="mapImage">
                    <StyledImage
                        src={image}
                        scale={imageScale}
                    />
                </ScrollContainer>
            </StyledImageWrapper>
            <StyledImageController>
                <StyledButton
                    typeButton={TypesButton.GRAY}
                    onClick={() => handleResize(MapSizeTypes.ENLARGE)}
                >
                    <Icon type={TypesIcon.PLUS} />
                </StyledButton>
                <StyledButton
                    typeButton={TypesButton.GRAY}
                    onClick={() => handleResize(MapSizeTypes.REDUCE)}
                >
                    <Icon type={TypesIcon.MINUS} />
                </StyledButton>
            </StyledImageController>
        </StyledMapViewerWrapper>
    );
};
