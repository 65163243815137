import styled from 'styled-components';
import { units } from '../../../../../helpers/styles/units';
import { Button } from '../../../../../components/Button';
import { PageTitle } from '../../../../../components/PageTitle';
import { FontWeight } from '../../../../../constants/Styles/fontWeight';

export const Root = styled.div`
  display: flex;
  padding-top: ${units(20)};
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
  padding-top: ${units(11)};
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  max-width: ${units(107)};
  width: 100%;
  height: ${units(24)};
`;

export const SearchBarWrapper = styled.div`
  max-width: ${units(239)};
  flex: 1 1 auto;
`;

export const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
  margin-bottom: ${units(8)};
`;
