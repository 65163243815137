import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SalesContent } from './loaders';
import { getPromotionsThink } from '../../actions/sales/thunks/getPromotions';

export const Sales = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPromotionsThink({}));
    }, []);

    return <SalesContent />;
};
