import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as citiesApi from '../../../api/cities';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { CityItem } from '../../../types/Models/City/City';

export const EDIT_CITY_ITEM = 'cities/editCity';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editCity = createAsyncThunk(
    EDIT_CITY_ITEM,
    createRequest({
        type: EDIT_CITY_ITEM,
        loader: async (requestData: CityItem) => citiesApi.editCity(requestData),
        onSuccess,
    }),
);
