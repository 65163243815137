import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as pricesApi from '../../../api/prices';
import { IPriceResponse } from '../../../types/Endpoints/prices/PricesContracts';

const GET_PRICES = 'prices/getPrices';

export const getPrices = createAsyncThunk<IPriceResponse[]>(
    GET_PRICES,
    createRequest({
        type: GET_PRICES,
        loader: async () => pricesApi.getPrices(),
    }),
);
