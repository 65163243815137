import styled from 'styled-components';
import { Input } from '../../../../components/Input';
import { units } from '../../../../helpers/styles/units';
import { colors } from '../../../../constants/Colors';
import { typography } from '../../../../helpers/styles/typography.ts';
import { FontWeight } from '../../../../constants/Styles/fontWeight.ts';

export const StyledRootWrapper = styled.div`
  position: relative;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
  margin-bottom: ${units(68)};
`;

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
  padding-top: ${units(20)};
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;
  background-color: ${colors.light};
  ${typography(10)};

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
`;
