import { UploadFile } from '../../Endpoints/files/FilesContract';

export enum MobileBlockKey {
    MOBILE_MAIN = 'mobile-mobile-main',
    MOBILE_CATEGORIES = 'mobile-categories',
    MOBILE_PROMOTIONS = 'mobile-promotions',
    MOBILE_COLLECTIONS = 'mobile-collections'
}

export enum WebBlockKey {
    WEB_MAIN = 'web-main',
    WEB_BLOCk_ONE = 'web-block-one',
    WEB_BLOCk_TWO = 'web-block-two',
    WEB_CATEGORIES = 'web-categories',
    WEB_COLLECTIONS = 'web-collections',
    WEB_SERVICES = 'web-services',
    WEB_INSTAGRAM = 'web-instagram',
    WEB_STRATCHED = 'web-stratched'
}

export interface BannerProduct {
    fileURLs: string[];
    id: number;
    price: number;
    isOnline: boolean;
    isHit: boolean;
    isNew: boolean;
    fullName: string;
    discountPrice: number;
    discountPriceDC: number;
}

export interface BannerItem {
    fileId?: number;
    mobileFileId?: number;
    mobileFileURL?: string;
    fileURL: string;
    id: number;
    status: 'ACTIVE' | 'INACTIVE';
    title: string;
    buttonText: string;
    text: string;
    url: string;
    order: number;
    startDate: string | null;
    endDate: string | null;
    linkType: 'SITE' | 'APP';
    fileImage?: UploadFile;
}

export interface BannerItemForEdit {
    fileId?: number;
    mobileFileId?: number;
    mobileFileURL?: string;
    id: number;
    status: 'ACTIVE' | 'INACTIVE';
    title: string;
    buttonText: string;
    text: string;
    url: string;
    order: number;
    startDate: string | null;
    endDate: string | null;
    fileURL?: string;
    fileImage?: UploadFile;
    mobileFileImage?: UploadFile;
    linkType: 'SITE' | 'APP';
}

export interface BlockItem<T> {
    fileURL: string;
    id: number;
    name: string;
    key: T;
    platform: 'WEB' | 'MOBILE';
    text: string | null;
    buttonText: string | null;
    title: string | null;
    type: 'SLIDER' | 'TILE';
    url: string | null;
    bannerItems: BannerItem[];
    bannerProducts: BannerProduct[];
    fileId: number;
    mobileFileId: number;
    mobileFileURL: string;
}

export interface BlockItemEdit<T> {
    fileURL: string;
    order?: number;
    id: number;
    name: string;
    key: T;
    platform: 'WEB' | 'MOBILE';
    text: string;
    buttonText: string;
    title: string;
    type: 'SLIDER' | 'TILE';
    url: string;
    bannerItems: BannerItem[];
    bannerProducts: BannerProduct[];
    fileId?: number;
    fileImage: UploadFile;
    mobileFileImage?: UploadFile;
    mobileFileId?: number;
    mobileFileURL?: string;
}

export interface BannerItemForm {
    fileURL: string;
    id: number;
    status: 'ACTIVE' | 'INACTIVE';
    title: string;
    buttonText: string;
    text: string;
    url: string;
    order: number;
    startDate: string;
    endDate: string;
    fileImage: UploadFile;
    mobileFileImage: UploadFile;
}

export interface BannerItemEdit {
    fileId?: number;
    mobileFileId?: number;
    mobileFileURL?: string;
    id: number;
    status: 'ACTIVE' | 'INACTIVE';
    title: string;
    buttonText: string;
    text: string;
    url: string;
    order: number;
    startDate: string | null;
    endDate: string | null;
    fileURL?: string;
    fileImage: UploadFile;
    mobileFileImage: UploadFile;
    linkType: 'SITE'| 'APP';
}
