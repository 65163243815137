import { request } from '../../helpers/api/request';
import { EndpointContract } from '../../types/Endpoints/EndpointContract';
import { GetActiveUserContract } from '../../types/Endpoints/users/GetActiveUserContract';
import { DynamicPagesKeys } from '../../types/Models/DinamicPages';
import { CreateBlocksModel } from './updatePage';

export interface DinamocPagesContract extends EndpointContract {
    responseBody: CreateBlocksModel;
  }

export const getPage = async (key: DynamicPagesKeys) => {
    const { data } = await request<GetActiveUserContract>({
        url: `dynamic-pages/${key}/blocks`,
    });

    return data;
};
