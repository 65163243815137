import { request } from '../../helpers/api/request';

export const deleteCollection = async (id: number) => {
    const { data } = await request({
        url: `admin/collections/${id}`,
        method: 'DELETE',
    });

    return data;
};
