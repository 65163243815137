import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NotesWithLoader } from './loaders';
import { getNotes } from '../../actions/notes';

export const Notes: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getNotes({
            page: 1,
            size: 50,
        }));
    }, []);

    return <NotesWithLoader />;
};
