import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_PAYMENT_METHODS } from '../../actions/paymentMethods';

const selectRoot = ({ paymentMethods }: RootState) => paymentMethods;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetPaymentMethodsLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_PAYMENT_METHODS],
);

export const selectPaymentMethods = createSelector(
    selectRoot,
    paymentMethods => paymentMethods?.paymentMethods,
);
