/**
 * Сохраняет сессию в localStorage
 */
import { v4 as uuidv4 } from 'uuid';
import { Session } from '../../types/Session';
import { STORAGE_SESSION_KEY } from '../../constants/Session';
import { setDataToStorage } from '../storage/setDataToStorage';
import { FINGER_PRINT } from '../../actions/auth/thunks/login';
import { getDataFromStorage } from '../storage/getDataFromStorage';

export const setSessionToStorage = (session: Session) => localStorage.setItem(
    STORAGE_SESSION_KEY,
    JSON.stringify(session),
);

/**
 * Удаляет сессию из localStorage
 */
export const removeSessionFromStorage = () => localStorage.removeItem(STORAGE_SESSION_KEY);

const getSessionFromString = (value?: string | null) => {
    if (!value) {
        return null;
    }
    try {
        return JSON.parse(value) as Session;
    } catch (error) {
        return null;
    }
};

/**
 * Достает сессию из localStorage
 */
export const getSessionFromStorage = () => {
    const sessionString = localStorage.getItem(STORAGE_SESSION_KEY);

    return getSessionFromString(sessionString);
};

/**
 * Реагирует на изменение сессии в localStorage
 * @param callback
 */
export const handleSessionInStorageChanged = (
    callback: (session: Session | null) => void,
) => {
    // Срабатывает при сохранении в localStorage в другом табе
    window.addEventListener('storage', event => {
        if (event.key !== STORAGE_SESSION_KEY) {
            return;
        }

        const newSession = getSessionFromString(event.newValue);

        callback(newSession);
    });
};

/**
 * Достаёт фингерпринт или в случае неудачи, сетит новый
 */
export const getFingerPrint = () => {
    let fingerprint = getDataFromStorage(FINGER_PRINT);
    if (!fingerprint) {
        fingerprint = uuidv4();
        setDataToStorage(FINGER_PRINT, fingerprint);
    }

    return fingerprint;
};
