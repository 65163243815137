import styled from 'styled-components';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { units } from '../../../../helpers/styles/units';
import { colors } from '../../../../constants/Colors';
import { typography } from '../../../../helpers/styles/typography.ts';
import { FontWeight } from '../../../../constants/Styles/fontWeight.ts';

export const StyledRootWrapper = styled.div`
  position: relative;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};
  padding-top: ${units(20)};
`;

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

export const CheckboxWrapper = styled(StyledMainWrapper)`
  gap: ${units(8)};
`;

export const StyledIdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

export const StyledSubmitButtons = styled(SubmitButtons)`
  left: ${units(124)};
`;
