import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { Drawers } from './constants';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { Preloader } from '../../../components/Preloader';

export const StyledDrawer = styled(Drawer)`
    & .MuiPaper-root {
      width: ${units(337)};
      padding: ${units(27)};
      box-shadow: none;
    }
  
    & .MuiBackdrop-root.MuiModal-backdrop {
      background: ${colors.grey10};
      opacity: 0.4 !important;
      pointer-events: none;
    }
  `;

export const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${units(8)};
  `;

export const ModalTitle = styled.div`
    font-weight: ${FontWeight.MEDIUM};
    letter-spacing: 1px;
  
    ${typography(20)};
  `;

export const CloseButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  
    cursor: pointer;
  
    &:hover svg {
      filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
        hue-rotate(332deg) brightness(91%) contrast(98%);
    }
  
    &:active svg {
      filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
        hue-rotate(333deg) brightness(96%) contrast(89%);
    }
  `;

export const DrawerRoot = styled.div`
    display: flex;
    flex-direction: column;
    
    gap:${props => (props.className === Drawers.CALCULATOR ? units(30) : units(20))};
  `;
export const Root = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${units(20)};
    padding-top: ${units(20)};
  `;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${units(12)};
  `;

export const StyledPreloader = styled(Preloader)`
    position: absolute;
    left: 50%;
    top: ${units(150)};
  `;
