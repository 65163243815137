import { createSelector } from '@reduxjs/toolkit';
import { matchPath } from 'react-router';
import { RootState } from '../../store';
import { Roles } from '../../constants/Roles';
import { routes } from '../../constants/RouterPath';

const selectRoot = (state: RootState) => state.systems;

export const selectBreakingPath = createSelector(
    selectRoot,
    ({ breakingPath }) => breakingPath,
);

export const selectInitialPath = (roles?: Roles) => createSelector(
    selectRoot,
    ({ initialPath }) => {
        const pathes = Object.values(routes);

        const excludesUserPath = routes.dashboard as string;

        const match = pathes.filter(path => !!matchPath(initialPath || '', {
            path,
            exact: true,
        })).length;

        switch (true) {
            case match && roles?.includes(Roles.ADMIN):
                return !excludesUserPath.includes(initialPath || '') ? initialPath : null;
            case match && roles?.includes(Roles.USER):
                return !excludesUserPath.includes(initialPath || '') ? initialPath : null;
            default:
                return match ? initialPath : null;
        }
    },
);
