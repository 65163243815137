import { createAsyncThunk } from '@reduxjs/toolkit';
import * as calculatorApi from '../../../api/calculator';
import { createRequest } from '../../../helpers/api/createRequest';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { getCalculatorAll } from './getCalculatorAll';
import { Calculators } from '../../../api/calculator/getCalculator';
import { TypesIcon } from '../../../types/TypesIcon';

export const UPDATE_SAMPLES = 'calculator/updateSamples';

const onSuccess = (): Thunk => async dispatch => {
    await dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Проба успешно добавлена',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));

    dispatch(getCalculatorAll());
};

const onFail = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.ERROR,
        message: {
            title: 'Ошибка, попробуйте позже',
        },
        icon: TypesIcon.SNACK_ERROR,
    }));
};

export const updateSamples = createAsyncThunk(
    UPDATE_SAMPLES,
    createRequest({
        type: UPDATE_SAMPLES,
        loader: async (data: Calculators) => calculatorApi.updateSamples(data),
        showFailNotification: false,
        onSuccess,
        onFail,
    }),
);
