import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { createGoodsBreadcrumbs } from '../../../../constants/BreadCrumbs/createGoodsBreadcrumbs';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { routes } from '../../../../constants/RouterPath';
import { selectProperties, selectPropertiesLoader } from '../../../../selectors/goods';
import { useTypeSelector } from '../../../../store';
import { updateProperties } from '../../../../actions/goods/thunks';
import { NamesFieldsFormProperties } from './constants.ts';
import { IFormValuesProperties } from './interface.ts';
import {
    StyledRootWrapper,
    StyledWrapper,
    StyledRoot,
    StyledInput,
} from './styles.ts';

export const CharacteristicsGoodsContent: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { goodsId } = useParams<{ goodsId: string }>();
    const properties = useTypeSelector(selectProperties);

    const handleSubmit = (values: IFormValuesProperties) => {
        dispatch(updateProperties({
            data: values[NamesFieldsFormProperties.properties],
            id: Number(goodsId),
        }));
    };

    const form = useFormik<IFormValuesProperties>({
        onSubmit: handleSubmit,
        initialValues: {
            [NamesFieldsFormProperties.properties]: properties || [],
        },
    });

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Товары</PageTitle>
                <Breadcrumbs breadcrumbs={createGoodsBreadcrumbs()} />
                <StyledRoot>
                    {form.values[NamesFieldsFormProperties.properties]?.map((item, i) => (
                        <StyledInput
                            key={item.property.id}
                            name={`properties[${i}].value`}
                            onChange={form.handleChange}
                            value={item.value || item.valueNumber || ''}
                            placeholder={item.property.name}
                            typeInput="text"
                            maxLength={255}
                            title={item.property.name}
                        />
                    ))}
                </StyledRoot>
            </StyledWrapper>

            <SubmitButtons
                reset={() => history.push(routes.goods)}
                submit={form.submitForm}
            />
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectPropertiesLoader,
});

export const CharacteristicsGoodsWithLoader = loader(CharacteristicsGoodsContent);
