import { request } from '../../helpers/api/request';
import {
    CreateTag,
    GetBlogProductsContract,
    GetTags,
    TextImageBlockContract,
} from '../../types/Endpoints/blog/BlogContracts';
import {
    CreateBlogContract,
    GetBlogContract,
    GetBlogItemContract,
    UpdateBlog,
} from '../../types/Endpoints/blog/BlogCRUDContracts';

export const getBlog = async (id: string) => {
    const { data } = await request<GetBlogItemContract>({
        url: `admin/blog/${id}`,
    });

    return data;
};

export const deleteBlog = async (id: string) => {
    const { data } = await request({
        method: 'DELETE',
        url: `admin/blog/${id}`,
    });

    return data;
};

export const getBlogs = async (params: GetBlogContract['pathParams']) => {
    const { data } = await request<GetBlogContract>({
        url: 'admin/blog',
        params,
    });

    return data;
};

export const createBlog = async (requestBody: CreateBlogContract<number>['requestBody']) => {
    const { data } = await request<CreateBlogContract<number>>({
        method: 'POST',
        url: 'admin/blog',
        data: requestBody,
    });

    return data;
};

export const updateBlog = async (dataForUpdate: UpdateBlog['requestBody'], blogId: string) => {
    const { data } = await request<UpdateBlog>({
        method: 'PATCH',
        url: `admin/blog/${blogId}`,
        data: dataForUpdate,
    });

    return data;
};

export const getTags = async () => {
    const { data } = await request<GetTags>({
        url: 'admin/blog/tags/',
    });

    return data;
};

export const createTag = async (tag: CreateTag['requestBody']) => {
    const { data } = await request<CreateTag>({
        method: 'POST',
        url: 'admin/blog/tag/',
        data: tag,
    });

    return data;
};

export const deleteTagTag = async (tagID: string) => {
    const { data } = await request({
        method: 'DELETE',
        url: `admin/blog/tag/${tagID}`,
    });

    return data;
};

export const createTextImageBlock = async (block: TextImageBlockContract['requestBody']) => {
    const { data } = await request<TextImageBlockContract>({
        method: 'POST',
        url: 'admin/blog/text-and-image-block/',
        data: block,
    });

    return data;
};

export const getBlogProducts = async (id: string) => {
    const { data } = await request<GetBlogProductsContract>({
        url: `admin/blog/${id}/products`,
    });

    return data;
};
