import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { selectSnackbars } from '../../selectors/snackbars';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { Snackbar } from '../Snackbar';

const StyledSnackbarsContainer = styled.div`
  position: fixed;
  bottom: ${units(4)};
  right: ${units(4)};
  z-index: 1000;

  display: flex;
  flex-direction: column;

  @media (max-width: ${BreakPoints.SM}) {
    bottom: 0;
    right: 0;
    margin: ${units(2)};
  }
`;

export const Snackbars = () => {
    const snackbars = useSelector(selectSnackbars);

    return (
        <StyledSnackbarsContainer>
            {snackbars.map(({
                type,
                id, message,
                autoHideDuration,
                isUnclosed, icon,
            }) => {
                const [enumKey] = Object.entries(TypesSnackbars).find(([, value]) => value === type)!;
                const currentType = TypesSnackbars[enumKey as keyof typeof TypesSnackbars];

                return (
                    <Snackbar
                        snackbarType={currentType}
                        snackbarId={id}
                        key={id}
                        snackbarTitle={message.title}
                        snackbarDescription={message.description}
                        autoHideDuration={autoHideDuration}
                        isUnclosed={isUnclosed}
                        snackbarIcon={icon}
                    />
                );
            })}
        </StyledSnackbarsContainer>
    );
};
