import { useDispatch } from 'react-redux';
import {
    Formik,
} from 'formik';
import { useEffect, useState } from 'react';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import {
    selectGetSiteBlocksLoader,
    selectSiteBodyBlock,
} from '../../../../selectors/mainPage';
import {
    BannerItemEdit,
    BlockItemEdit,
    WebBlockKey,
} from '../../../../types/Models/MainPage/MainPageModel';
import { useTypeSelector } from '../../../../store';
import { PageTitle } from '../../../../components/PageTitle';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { TypesButton } from '../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../constants/SizeButton';
import { colors } from '../../../../constants/Colors';
import { editWebBanners } from '../../../../actions/mainPage';
import { siteBodyBreadcrumbs } from '../../../../constants/BreadCrumbs/siteBodyBreadcrumbs';
import { Icon } from '../../../../components/Icon';
import { TypesIcon } from '../../../../types/TypesIcon';
import { getShotPath } from '../../../../helpers/images/getPath';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { AddProductsDrawer } from '../../../../components/AddProductsDrawer';
import { GetPromotionContract } from '../../../../types/Endpoints/sales/PromitionsContracts';
import { isEqualObject } from '../../../../helpers/isEqual';
import { addNotification } from '../../../../actions/notifications';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';
import * as S from './styles';
import { validationSchema } from './schema';

export const FirstBlockContent = (): JSX.Element => {
    const dispatch = useDispatch();
    const [state, setState] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<{ id: number }[]>([]);
    const [selectedProductsInModal, setSelectedProductsInModal] = useState<GetPromotionContract['responseBody']['products']>([]);

    const siteBodyBlock = useTypeSelector(selectSiteBodyBlock(WebBlockKey.WEB_BLOCk_ONE));

    const saveAndOffDrawer = () => {
        setState(false);
        setSelectedProducts(selectedProductsInModal.map(item => ({ id: item.id })));
    };

    const onDrawer = () => {
        setState(true);
    };

    const offDrawer = () => {
        setState(false);
        setSelectedProductsInModal(siteBodyBlock?.bannerProducts || []);
    };

    const handleSubmit = (values: any) => {
        const bannerProducts = selectedProducts.map(item => item.id);
        const bannerRequestData = {
            buttonText: values.buttonText || null,
            title: values.title || null,
            url: values.url || null,
            text: values.text || null,
            fileId: values.fileImage?.id || null,
            mobileFileId: values.mobileFileImage?.id || null,
            bannerProducts,
        };

        dispatch(
            editWebBanners({
                key: WebBlockKey.WEB_BLOCk_ONE,
                newData: bannerRequestData,
            }),
        );
    };

    const initialValue: BlockItemEdit<WebBlockKey> = {
        buttonText: siteBodyBlock?.buttonText || '',
        title: siteBodyBlock?.title || '',
        text: siteBodyBlock?.text || '',
        url: siteBodyBlock?.url || '',
        name: siteBodyBlock?.name || '',
        key: WebBlockKey.WEB_BLOCk_ONE,
        platform: 'WEB',
        type: siteBodyBlock?.type || 'SLIDER',
        bannerItems: siteBodyBlock?.bannerItems || [],
        bannerProducts: siteBodyBlock?.bannerProducts || [],
        fileURL: siteBodyBlock?.fileURL || '',
        id: siteBodyBlock?.id || 0,
        fileImage: {
            id: siteBodyBlock?.fileId || 0,
            path: siteBodyBlock?.fileURL ? getShotPath(siteBodyBlock.fileURL) : '',
        },
        mobileFileImage: {
            id: siteBodyBlock?.mobileFileId || 0,
            path: siteBodyBlock?.mobileFileURL ? getShotPath(siteBodyBlock.mobileFileURL) : '',
        },
    };

    useEffect(() => {
        const sourceProducts = siteBodyBlock?.bannerProducts.map(item => ({ id: +item.id })) || [];
        setSelectedProducts(sourceProducts);
        setSelectedProductsInModal(siteBodyBlock?.bannerProducts || []);
    }, []);

    const handleResetForm = (calb: () => void, values: BannerItemEdit) => {
        calb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>Body сайта</PageTitle>
                <Breadcrumbs breadcrumbs={siteBodyBreadcrumbs()} />
                <Formik<BannerItemEdit>
                    initialValues={initialValue as unknown as BannerItemEdit}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {props => (
                        <S.StyledForm onSubmit={handleSubmit}>
                            <S.FieldsWrapper>
                                <S.StyledInput
                                    name="title"
                                    onChange={props.handleChange}
                                    value={props.values?.title}
                                    title="Заголовок (не более 50 символов)*"
                                    placeholder="Заголовок (не более 50 символов)*"
                                    typeInput="text"
                                    maxLength={255}
                                    isTouched={props.touched.title}
                                    error={props.errors.title}
                                    count={props.submitCount}
                                />

                                <S.StyledInput
                                    name="text"
                                    onChange={props.handleChange}
                                    value={props.values?.text}
                                    title="Описание (не более 40 символов)"
                                    placeholder="Описание (не более 40 символов)"
                                    typeInput="text"
                                    maxLength={255}
                                    isTouched={props.touched.text}
                                    error={props.errors.text}
                                    count={props.submitCount}
                                />

                                <S.StyledInput
                                    name="buttonText"
                                    onChange={props.handleChange}
                                    value={props.values?.buttonText}
                                    title="Текст кнопки"
                                    placeholder="Текст кнопки"
                                    typeInput="text"
                                    maxLength={255}
                                />

                                <S.StyledInput
                                    name="id"
                                    onChange={props.handleChange}
                                    value={siteBodyBlock?.id}
                                    title="ID"
                                    placeholder="ID"
                                    typeInput="text"
                                    maxLength={255}
                                    disabled
                                />

                                <S.StyledInput
                                    name="url"
                                    onChange={props.handleChange}
                                    value={props.values?.url}
                                    title="URL*"
                                    placeholder="URL*"
                                    typeInput="text"
                                    isTouched={props.touched.url}
                                    error={props.errors.url}
                                    count={props.submitCount}
                                />
                            </S.FieldsWrapper>
                            <S.FieldsWrapper>
                                <S.Uploader
                                    name="fileImage"
                                    title="Изображение для desktop версии сайта"
                                    onChange={props.setFieldValue}
                                    value={props.values.fileImage}
                                    format="3:1"
                                    maxSize={3}
                                />
                            </S.FieldsWrapper>
                            <S.FieldsWrapper>
                                <S.Uploader
                                    name="mobileFileImage"
                                    title="Изображение для мобильной версии сайта"
                                    onChange={props.setFieldValue}
                                    value={props.values.mobileFileImage}
                                    format="2:1"
                                    maxSize={3}
                                />
                            </S.FieldsWrapper>

                            <S.AddButtonWrapper>
                                <S.AddButton
                                    typeButton={TypesButton.SECONDARY}
                                    size={SizesButton.M}
                                    onClick={onDrawer}
                                >
                                    <Icon
                                        type={TypesIcon.PLUS_BIG}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        color={colors.greyDark}
                                    />
                                    Добавить товары
                                </S.AddButton>
                                <S.CountInfo>{`Добавлено ${selectedProducts.length} товаров в акцию`}</S.CountInfo>
                                <AddProductsDrawer
                                    isFirstModalOpen={state}
                                    saveAndOffDrawer={saveAndOffDrawer}
                                    offDrawer={offDrawer}
                                    selectedProductsInModal={selectedProductsInModal}
                                    setSelectedProductsInModal={setSelectedProductsInModal}
                                />
                            </S.AddButtonWrapper>
                            <S.SubmitButtonsWrapper>
                                <SubmitButtons
                                    reset={() => handleResetForm(props.resetForm, props.values)}
                                    submit={props.submitForm}
                                />
                            </S.SubmitButtonsWrapper>
                        </S.StyledForm>
                    )}
                </Formik>
            </S.Wrapper>
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetSiteBlocksLoader,
});

export const FirstBlockWithLoader = loader(FirstBlockContent);
