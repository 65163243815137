import { request } from '../../helpers/api/request';
import { CityItem } from '../../types/Models/City/City';
import { EditCityItemContract } from '../../types/Endpoints/cities/GetCitiesContract';

export const editCity = async ({ id, ...requestData }: CityItem) => {
    const { data } = await request<EditCityItemContract>({
        url: `admin/cities/${id}`,
        method: 'PATCH',
        data: requestData,
    });

    return data;
};
