import { createReducer } from '@reduxjs/toolkit';
import { Collection } from '../../types/Models/Collections/Collection';
import { getCollections } from '../../actions/collections';
import { getCatalogCollection } from '../../actions/collections/thunks/getCollection';
import { GetCollectionsItem } from '../../types/Endpoints/collections/GetCollectionsContract';

export interface CollectionsModel {
  collections?: Collection[];
  selectedCollection?: GetCollectionsItem;
}

export const collections = createReducer<CollectionsModel>({ }, builder => {
    builder.addCase(getCollections.fulfilled, (state, { payload }) => ({
        ...state,
        collections: payload,
    }));
    builder.addCase(getCatalogCollection.fulfilled, (state, { payload }) => ({
        ...state,
        selectedCollection: payload,
    }));
});
