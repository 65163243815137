import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { units } from '../../helpers/styles/units';
import { Button } from '../../components/Button';
import { PageTitle } from '../../components/PageTitle';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { Input } from '../../components/Input';

export const Root = styled.div`
  display: flex;
  padding-top: ${units(20)};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

export const PanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  max-width: ${units(137)};
  width: 100%;
  height: ${units(24)};
`;

export const SearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

export const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
  margin-bottom: ${units(28)};
`;

export const FilterWrapper = styled.div`
  position: relative;
`;

export const FilterWindow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: absolute;
  top: 65px;
  right: 0;
  box-shadow: 0px 0px 20px 0px rgba(57, 56, 54, 0.2) !important;
  border-radius: 0;
  background-color: #f7f7f7;
  padding: 32px 40px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const IdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};

  ${typography(10)};
`;

export const CalendarWrapper = styled.div`
  position: relative;
  margin-left: auto;
`;

export const RootWrapper = styled.div`
  position: relative;
`;

export const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};
  padding-top: ${units(20)};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

export const CheckboxWrapper = styled(MainWrapper)`
  gap: ${units(12)};
`;

export const TagsWrapper = styled(MainWrapper)`
  gap: ${units(12)};
`;

export const AddButtonWrapper = styled(MainWrapper)`
  gap: ${units(8)};
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

`;

export const InputWrapper = styled.div`
    width: ${units(50)};
`;

export const WidthButton = styled(Button)`
  width: 100%;
`;

export const Subtitle = styled.div`

  font-weight: ${FontWeight.MEDIUM};

  ${typography(10)};
`;

export const TagSubtitle = styled(Subtitle)`
    margin-bottom: ${units(0)};
`;

export const ImageSubtitle = styled(Subtitle)`
  margin-bottom: ${units(0)};
`;

export const SubtitleWithDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const UrlWrapper = styled.div`
  position: relative;
`;

export const CopyWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 10;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(16)};
`;

export const AddButton = styled(StyledButton)`
  width: fit-content;
  display: flex;
  align-items: center;

  gap: ${units(8)};

  font-weight: ${FontWeight.BOLD};
`;

export const AddButtonInline = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  gap: ${units(8)};

  font-weight: ${FontWeight.BOLD};
`;

export const CountInfo = styled.div`
  color: ${colors.grey20};

  ${typography(10)};
`;

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: ${units(337)};
    padding: ${units(27)};
    box-shadow: none;
  }

  & .MuiBackdrop-root.MuiModal-backdrop {
    background: ${colors.grey10};
    opacity: 0.4 !important;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${units(8)};
`;

export const ModalTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 1px;

  ${typography(20)};
`;

export const ModalDescription = styled.div`
  letter-spacing: 1px;
  color: #939393;
  margin-top: ${units(8)};
  ${typography(14)};
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${units(8)};
  padding: ${units(12)} ${units(27)};
  width: ${units(324)};

  background-color: ${colors.light};
  border-top: 1px solid ${colors.grey40};

  position: fixed;
  bottom: 0;
  right: 25px;
  z-index: 1000;
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
    hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
    hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

export const DrawerRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWithPlaceholder = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};

  ::placeholder {
    top: 31px;
  }

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
`;

export const Placeholder = styled.span`
  position: absolute;
  ${typography(10)};
  color: #393836;
  top: 27px;
  z-index: 2;
`;

export const DeleteButton = styled(Button)`
  width: fit-content;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(8)};
`;

export const IdInfoText = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

export const TagName = styled.div`
  width: 100%;
`;

// const StyledRootWrapper = styled.div`
//   position: relative;
// `;

// const StyledWrapper = styled.div`
//   display: flex;
//   flex-direction: column;

//   padding-left: ${units(12)};
//   margin-bottom: ${units(63)};
// `;

// const StyledMainWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: ${units(12)};

//   max-width: ${units(322)};
//   width: 100%;
// `;

// const CheckboxWrapper = styled(StyledMainWrapper)`
//   gap: ${units(30)};
// `;

// const TagsWrapper = styled(StyledMainWrapper)`
//   gap: ${units(12)};
// `;

// const AddButtonWrapper = styled(StyledMainWrapper)`
//   gap: ${units(8)};
// `;

// const StyledInput = styled(Input)`
//   font-weight: ${FontWeight.REGULAR};
//   letter-spacing: 0.8px;

//   background-color: ${colors.light};

//   ${typography(10)};

//   & ::-webkit-calendar-picker-indicator {
//     color: transparent;
//     background: none;
//     z-index: 1;
//   }
// `;

// const InputWrapper = styled.div`
//     width: ${units(50)};
// `;

// const StyledButton = styled(Button)`
//   width: 100%;
// `;

// const StyledSubtitle = styled.div`
//   font-weight: ${FontWeight.MEDIUM};

//   ${typography(10)};
// `;

// const StyledSubtitleWithDescription = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;
// `;

// const StyledUrlWrapper = styled.div`
//   position: relative;
// `;

// const StyledCopyWrapper = styled.div`
//   position: absolute;
//   right: 0;
//   top: 16px;
//   z-index: 10;
// `;

// const StyledDateWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   gap: ${units(16)};
// `;

// const StyledAddButton = styled(StyledButton)`
//   width: fit-content;
//   display: flex;
//   align-items: center;

//   gap: ${units(8)};

//   font-weight: ${FontWeight.BOLD};
// `;

// const StyledAddButtonInline = styled.button`
//   width: fit-content;
//   display: flex;
//   align-items: center;
//   border: 0;
//   background-color: transparent;
//   cursor: pointer;
//   ${typography(10)};

//   gap: ${units(8)};

//   font-weight: ${FontWeight.BOLD};
// `;

// const StyledCountInfo = styled.div`
//   color: ${colors.grey20};

//   ${typography(10)};
// `;

// const StyledDrawer = styled(Drawer)`
//   & .MuiPaper-root {
//     width: ${units(337)};
//     padding: ${units(27)};
//     box-shadow: none;
//   }

//   & .MuiBackdrop-root.MuiModal-backdrop {
//     background: ${colors.grey10};
//     opacity: 0.4 !important;
//   }
// `;

// const StyledHeading = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: ${units(8)};
// `;

// const StyledModalTitle = styled.div`
//   font-weight: ${FontWeight.MEDIUM};
//   letter-spacing: 1px;

//   ${typography(20)};
// `;

// const StyledModalDescription = styled.div`
//   letter-spacing: 1px;
//   color: #939393;
//   margin-top: ${units(8)};
//   ${typography(14)};
// `;

// const ButtonWrapper = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 3fr;
//   gap: ${units(8)};
//   padding: ${units(12)} ${units(27)};
//   width: ${units(324)};

//   background-color: ${colors.light};
//   border-top: 1px solid ${colors.grey40};

//   position: fixed;
//   bottom: 0;
//   right: 25px;
//   z-index: 1000;
// `;

// const StyledCloseButton = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   cursor: pointer;

//   &:hover svg {
//     filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
//       hue-rotate(332deg) brightness(91%) contrast(98%);
//   }

//   &:active svg {
//     filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
//       hue-rotate(333deg) brightness(96%) contrast(89%);
//   }
// `;

// const StyledDrawerRoot = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const StyledInputWithPlaceholder = styled(Input)`
//   font-weight: ${FontWeight.REGULAR};
//   letter-spacing: 0.8px;
//   padding-left: 263px;

//   background-color: ${colors.light};

//   ${typography(10)};

//   ::placeholder {
//     top: 31px;
//   }

//   & ::-webkit-calendar-picker-indicator {
//     color: transparent;
//     background: none;
//     z-index: 1;
//   }
// `;

// const StyledPlaceholder = styled.span`
//   position: absolute;
//   ${typography(10)};
//   color: #393836;
//   top: 27px;
//   z-index: 2;
// `;

// const StyledFlexRow = styled.div`
//   display: flex;
//   align-items: center;
//   gap: ${units(8)};
//   border-bottom: 1px solid ${colors.grey30};
//   padding-bottom: ${units(8)};
// `;

// const StyledIdInfo = styled.div`
//   font-weight: ${FontWeight.MEDIUM};

//   color: ${colors.greyDark};
//   background-color: ${colors.light};

//   ${typography(10)};
// `;
