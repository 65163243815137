import { useState } from 'react';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import { selectGetSiteBlocksLoader } from '../../../../selectors/mainPage';
import { MainBannerTablePageContent } from './MainBannerTablePage';
import { CreateMainBanner } from './CreateMainBannerItem';
import { BlockItemEdit, WebBlockKey } from '../../../../types/Models/MainPage/MainPageModel';
import { EditMainBanner } from './EditMainBannerItem';
import { Wrapper } from './styles';
import { PageStatus } from './constants';

export const MainBannerContent = (): JSX.Element => {
    const [activeStatus, setActiveStatus] = useState(PageStatus.MAIN);

    const [selectedItem, setSelectedItem] = useState<BlockItemEdit<WebBlockKey> | null>(null);

    const handleChangeContent = (
        key: PageStatus,
        data: BlockItemEdit<WebBlockKey> | null = null,
    ) => {
        setActiveStatus(key);
        if (data) setSelectedItem(data);
    };

    const getPageContent = () => {
        if (activeStatus === PageStatus.MAIN) { return <MainBannerTablePageContent changePage={handleChangeContent} />; }
        if (activeStatus === PageStatus.ADD) { return <CreateMainBanner changePage={handleChangeContent} />; }
        return activeStatus === PageStatus.EDIT && selectedItem ? (
            <EditMainBanner data={selectedItem} changePage={handleChangeContent} />
        ) : null;
    };

    return <Wrapper>{getPageContent()}</Wrapper>;
};

const loader = createLoadHoc({
    selectLoader: selectGetSiteBlocksLoader,
});

export const MainBunnerWithLoader = loader(MainBannerContent);
