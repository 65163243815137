import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as categoriesApi from '../../../api/categories';
import { CreateCategoryItem } from '../../../types/Endpoints/categories/CategoriesContracts';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const CREATE_CATALOG_CATEGORY = 'catalog/createCatalogCategory';

const onSuccess = (): Thunk => async dispatch => {
    history.push(routes.settings);

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const createCatalogCategory = createAsyncThunk(
    CREATE_CATALOG_CATEGORY,
    createRequest({
        type: CREATE_CATALOG_CATEGORY,
        loader: async (requestData: CreateCategoryItem) => categoriesApi.createCategory(requestData),
        onSuccess,
    }),
);
