/* eslint-disable no-unsafe-optional-chaining */

import styled from 'styled-components';
import {
    Formik, Form, FieldArray, ErrorMessage,
} from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { units } from '../../../helpers/styles/units';
import { Input } from '../../Input';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { Button } from '../../Button';
import { TypesButton } from '../../../constants/ButtonTypes';
import { TypesIcon } from '../../../types/TypesIcon';
import { SizesButton } from '../../../constants/SizeButton';
import { Icon } from '../../Icon';
import { ChildrenProps } from '../../EditCalculator';
import {
    Calculators,
    SamplesItem,
} from '../../../api/calculator/getCalculator';
import { updateSamples } from '../../../actions/calculator/thunks/updateSamples';
import { DeleteButton } from '../../Button/deleteButton';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding: ${units(20)} ${units(27)} ${units(0)} ${units(27)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 75%;
  margin-right: ${units(3)};
  margin-bottom: ${units(12)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${units(324)};
  /* padding: ${units(27)}; */
  height: calc(100vh + 100px);
  position: relative;
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  width: 100%;
`;

const StyledAddButton = styled(StyledButton)`
  width: ${units(84)};
  margin-bottom: ${units(62)};
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${units(8)};
  padding: ${units(12)} ${units(27)};
  width: ${units(324)};

  background-color: ${colors.light};
  border-top: 1px solid ${colors.grey40};

  position: fixed;
  bottom: 0;
  right: 25px;
  z-index: 1000;
`;

const StyledInpurWrapper = styled.div`
  margin-bottom: ${units(20)};
  flex: 1;
`;

const StyledHeading = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-stert;
  gap: ${units(8)};
  padding: ${units(27)};
  padding-bottom: ${units(0)};
`;

const StyledModalTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 1px;

  ${typography(20)};
`;

const StyledCloseButton = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%) hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%) hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

const StyledClose = styled(StyledCloseButton)`
  margin-left: auto;
`;

const StyledSamleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(20)};
`;

const StyledError = styled.div`
  margin-top: ${units(2)};
  color: ${colors.red};

  ${typography(5)};
`;

export const CalculatorPurchaseGold = ({
    onClose,
    modalData,
}: ChildrenProps) => {
    const dispatch = useDispatch();

    const handleSubmit = (props: any) => {
        const data = {
            id: props.values.id,
            name: props.values.name,
            samples: props.values.samples.map((sample: SamplesItem) => ({
                id: sample.id,
                number: sample.number,
                price: +sample.price,
            })),
            isZiko: props.values.isZiko,
        };

        dispatch(updateSamples(data));

        if (onClose) {
            onClose();
        }
    };

    const initialValue = {
        id: modalData?.id || 0,
        name: modalData?.name || '',
        samples: modalData?.samples || ([] as SamplesItem[]),
        isZiko: modalData?.isZiko || false,
    };

    const validationSchema = yup.object().shape({
        name: yup.string()
            .min(5, 'Ошибка! Длинна должна быть минимум 5 символов.')
            .max(70, 'Ошибка! Текст сильно длинный.')
            .required('Обязательное поле'),
        samples: yup.array().of(
            yup.object().shape({
                id: yup.string(),
                number: yup.string().required(),
                price: yup.number().required(),
            }),
        ),
    });

    return (
        <StyledWrapper>
            <StyledHeading>
                <StyledCloseButton onClick={onClose}>
                    <Icon
                        type={TypesIcon.ARROW_LEFT_BIG}
                        color={colors.grey20}
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                    />
                </StyledCloseButton>
                <StyledModalTitle>{`${modalData?.name} (${
                    modalData?.isZiko ? 'обмен' : 'скупка'
                })`}
                </StyledModalTitle>
                <StyledClose onClick={onClose}>
                    <Icon
                        type={TypesIcon.CLOSE_BIG}
                        color={colors.grey20}
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                    />
                </StyledClose>
            </StyledHeading>
            <StyledRoot>
                <StyledMainWrapper>
                    <Formik<Calculators>
                        initialValues={initialValue}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {props => (
                            <Form onSubmit={handleSubmit}>
                                <StyledMainWrapper>
                                    <StyledInput
                                        name="name"
                                        onChange={props.handleChange}
                                        value={props.values.name}
                                        title="Заголовок"
                                        placeholder="Введите текст"
                                        typeInput="text"
                                        maxLength={70}
                                    />
                                    {props.errors.name ? (
                                        <StyledError>{props.errors.name}</StyledError>
                                    ) : null}
                                </StyledMainWrapper>
                                <FieldArray name="samples">
                                    {({ push, remove }) => (
                                        <div>
                                            {props.values?.samples?.map(
                                                (sample: SamplesItem, index: number) => (
                                                    <div key={sample.id}>
                                                        <StyledSamleWrapper>
                                                            <StyledInpurWrapper>
                                                                <StyledInput
                                                                    name={`samples[${index}].number`}
                                                                    onChange={e => {
                                                                        const regex = /[^0-9,./\\-]/g;
                                                                        const value = e.target.value.replace(regex, '');
                                                                        props.setFieldValue(`samples[${index}].number`, value);
                                                                    }}
                                                                    value={props.values.samples[index].number}
                                                                    title="Проба"
                                                                    placeholder="Введите текст"
                                                                    typeInput="text"
                                                                    maxLength={10}
                                                                />
                                                                <ErrorMessage name={`samples[${index}].number`} />
                                                            </StyledInpurWrapper>
                                                            <StyledInpurWrapper>
                                                                <StyledInput
                                                                    name={`samples[${index}].price`}
                                                                    onChange={props.handleChange}
                                                                    value={props.values.samples[index].price}
                                                                    title="Цена за 1 гр."
                                                                    placeholder="Введите текст"
                                                                    typeInput="text"
                                                                    maxLength={255}
                                                                />

                                                                <ErrorMessage name={`samples[${index}].price`} />
                                                            </StyledInpurWrapper>
                                                            <DeleteButton
                                                                typeButton={TypesButton.SECONDARY}
                                                                size={SizesButton.M}
                                                                onClick={() => remove(index)}
                                                            />
                                                        </StyledSamleWrapper>

                                                    </div>
                                                ),
                                            )}
                                            <StyledAddButton
                                                typeButton={TypesButton.SECONDARY}
                                                size={SizesButton.M}
                                                onClick={() => push({
                                                    id: Math.ceil(Math.random() * 1000),
                                                    number: 0,
                                                    price: 0,
                                                })}
                                            >
                                                + Добавить пробу
                                            </StyledAddButton>
                                        </div>
                                    )}
                                </FieldArray>
                                <ButtonWrapper>
                                    <StyledButton
                                        typeButton={TypesButton.SECONDARY}
                                        size={SizesButton.M}
                                        onClick={onClose}
                                    >
                                        Отменить
                                    </StyledButton>
                                    <StyledButton
                                        typeButton={TypesButton.PRIMARY}
                                        size={SizesButton.M}
                                        onClick={() => handleSubmit(props)}
                                        disabled={!props.isValid}
                                    >
                                        Сохранить изменения
                                    </StyledButton>
                                </ButtonWrapper>
                            </Form>
                        )}
                    </Formik>
                </StyledMainWrapper>
            </StyledRoot>
        </StyledWrapper>
    );
};
