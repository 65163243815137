import styled from 'styled-components';
import { PageTitle } from '../../components/PageTitle';
import { units } from '../../helpers/styles/units';
import { DocsForm } from '../../components/DocsForm';
import { useTypeSelector } from '../../store';
import { selectDocuments, selectGetDocumentsLoader } from '../../selectors/documents';
import { createLoadHoc } from '../../helpers/hocs/createLoadHoc';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};
  padding-top: ${units(20)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
  padding-bottom: ${units(20)};
  max-width: ${units(334)};
`;

const StyledPageTitle = styled(PageTitle)`
  margin-bottom: ${units(0)};
`;

export const DocumentsContent = () => {
    const data = useTypeSelector(selectDocuments);

    return (
        <StyledWrapper>
            <StyledDiv>
                <StyledPageTitle>Доп. настройки</StyledPageTitle>
                <StyledRoot>
                    {data && data?.length > 0 && <DocsForm pagesData={data} />}
                </StyledRoot>
            </StyledDiv>
        </StyledWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetDocumentsLoader,
});

export const DocumentsContentWithLoader = loader(DocumentsContent);
