export const PageTitles = {
    PAYMENT_AND_DELIVERY: 'Оплата и доставка',
    PURCHASE_AND_EXCHANGE: 'Скупка и обмен',
    INSTALLMENT_AND_CREDIT: 'Рассрочка и кредит',
    LOYALTY_PROGRAM: 'Программа лояльности',
    USERS: 'Пользователи',
    SHOPS: 'Магазины',
    SALES: 'Акции',
    ROBOTS: 'robots.txt',
};
