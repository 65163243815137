import { request } from '../../helpers/api/request';
import { GetShopsContract } from '../../types/Endpoints/shops/ShopsContracts';

export const getShop = async (id: string) => {
    const { data } = await request<GetShopsContract>({
        url: `admin/shops/${id}`,
    });

    return data;
};
