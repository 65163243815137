import styled from 'styled-components';
import { units } from '../../../../helpers/styles/units';
import { Input } from '../../../../components/Input';
import { FontWeight } from '../../../../constants/Styles/fontWeight';
import { colors } from '../../../../constants/Colors';
import { typography } from '../../../../helpers/styles/typography';

export const RootWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units(63)};
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};
  padding-top: ${units(20)};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

export const FieldsWrapper = styled(MainWrapper)`
  gap: ${units(30)};
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

export const SubmitButtonsWrapper = styled.div`
  margin-left: -${units(12)};
`;
