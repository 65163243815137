import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    title: yup.string().required('Поле является обязательным'),
    url: yup.string().required('Поле является обязательным'),
    bannerItems: yup.array().of(
        yup.object().shape({
            title: yup.string().required('Поле является обязательным'),
            url: yup.string().required('Поле является обязательным'),
        }),
    ),
});
