import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const selectRoot = ({ loaders }: RootState) => loaders;

export const selectLoaders = createSelector(
    selectRoot,
    loaders => Object.values(loaders),
);

export const selectLoadersByName = (nameLoader: string) => createSelector(
    selectLoaders,
    loaders => loaders.find(({ name }) => name === nameLoader),
);
