import { request } from '../../helpers/api/request';
import { PropertyItemContract } from '../../types/Endpoints/goods/GoodsContracts';

const getUrl = (id: number): string => `admin/products/${id}/properties`;

export const updateProperties = async (properties: PropertyItemContract[], id: number) => {
    await request({
        method: 'PUT',
        url: getUrl(id),
        data: properties.map(item => ({
            propertyId: item.property.id,
            value: item.value,
            valueNumber: item.valueNumber,
        })),
    });

    return properties;
};
