/* eslint-disable max-classes-per-file */
import { AxiosError } from 'axios';
import { StatusCode } from '../../types/Errors/StatusCode';

/**
 * Потенциально опасный момент: при наследовании от нативных классов (таких, как Error),
 * транспайлинг бабелем работает не так, как нужно и может получиться, что
 * класс-наследник class MyError extends Error при проверке (new MyError()) instanceof Error возвращает false
 * Подробно описано здесь:
 * https://stackoverflow.com/questions/33870684/why-doesnt-instanceof-work-on-instances-of-error-subclasses-under-babel-node/33877501#33877501
 * Для правильного транспайлинга следует либо использовать @babel/plugin-transform-classes (для babel 7) либо вообще не
 * транспайлить наследование классов (сейчас именно так и происходит)
 */

// Ошибка при запросе
export class RequestError extends Error {
    constructor(message?: string) {
        super(message || 'Request error');
    }
}

// Ошибка, которую отдал сервер, с соответствующим кодом
export class ServerRequestError extends RequestError {
    statusCode: number | undefined;

    data: any;

    constructor(
        message?: string,
        statusCode?: number,
        data?:any,
    ) {
        super(message);
        this.statusCode = statusCode;
        this.data = data;
    }
}

// Ошибка с сетью, не достучались до сервера, нет кода ответа
export class NetworkError extends RequestError {
    constructor(message?: string) {
        super(message || 'Network error');
    }
}

// Получает на вход ошибку от axios и в случае, если это сетевая ошибка,
// бросает NetworkError
export const throwNetworkError = (error: AxiosError & { statusCode?: StatusCode }): void => {
    const status = error?.statusCode;
    const isNotNetworkError = error.response?.status;

    if (!isNotNetworkError && !status) {
        throw new NetworkError();
    }
};
