import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { FieldArray, Form, Formik } from 'formik';
import { createLoadHoc } from '../../helpers/hocs/createLoadHoc';
import { units } from '../../helpers/styles/units';
import { PageTitle } from '../../components/PageTitle';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../store';
import { SubmitButtons } from '../../components/StyledComponents';
import { typography } from '../../helpers/styles/typography';
import { colors } from '../../constants/Colors';
import { Input } from '../../components/Input';
import { SearchSetting } from '../../types/Endpoints/SearchSettings/SearchSettingsContracts';
import { TypesIcon } from '../../types/TypesIcon';
import { addNotification } from '../../actions/notifications';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { isEqualObject } from '../../helpers/isEqual';
import { Switch } from '../../components/sw';
import {
    selectCertificates,
    selectGetCertificatesLoader,
} from '../../selectors/certificates';
import { CertificatesItem } from '../../types/Endpoints/certificates/CertificatesContracts';
import { setCertificates } from '../../actions/certificates/thunks';

const StyledRootWrapper = styled.div`
  position: relative;
  padding-left: ${units(12)};
`;
const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${units(40)};
`;
const StyledPageTitle = styled(PageTitle)`
  margin-bottom: ${units(20)};
`;
const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 75%;
  gap: ${units(20)};
  padding-bottom: ${units(70)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${units(334)};
  padding-top: ${units(9)};
`;

const StyledInput = styled(Input)`
  width: 100%;

  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  margin-bottom: 0px;

  background-color: ${colors.light};

  ${typography(10)};

  & .input-title {
    color: ${colors.grey20};
  }
`;

const StyledSumWrapper = styled.div`
  width: ${units(235)};
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
`;

const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
  
  gap: ${units(16)};
`;
const SubmitButtonsWrapper = styled.div`
  margin-left: -${units(12)};
`;

const CertificatesContent = () => {
    const dispatch = useDispatch();
    const certificates = useTypeSelector(selectCertificates);

    const handleSubmit = (props: any) => {
        const data = props.values.certificates.map((item: any) => ({
            ...item,
            sum: +item.sum,
        }));

        dispatch(setCertificates(data));
    };

    const initialValue: { certificates: CertificatesItem[] } = {
        certificates: certificates || [],
    };

    const handleResetForm = (clb: () => void, values: any) => {
        clb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <StyledPageTitle>Сертификаты</StyledPageTitle>
                <Formik<any>
                    initialValues={initialValue}
                    onSubmit={handleSubmit}
                    /* validationSchema={validationSchema} */
                    enableReinitialize
                >
                    {props => (
                        <StyledForm onSubmit={handleSubmit}>
                            <FieldArray name="certificates">
                                {() => (
                                    <StyledMainWrapper>
                                        {props.values.certificates?.map(
                                            (_block: SearchSetting, index: number) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <StyledDiv key={`block${index}`}>
                                                    <StyledFlexDiv>
                                                        <StyledSumWrapper>
                                                            <StyledInput
                                                                name={`certificates[${index}].sum`}
                                                                onChange={props.handleChange}
                                                                value={props.values?.certificates[index]?.sum}
                                                                title={`Номинал ${index + 1}`}
                                                                placeholder={`Номинал ${index + 1}`}
                                                                typeInput="text"
                                                                maxLength={255}
                                                            />
                                                        </StyledSumWrapper>

                                                        <Switch
                                                            name={`certificates[${index}].isActive`}
                                                            value={
                                                                props.values?.certificates[index]?.isActive
                                                            }
                                                            onChange={() => props.setFieldValue(
                                                                `certificates[${index}].isActive`,
                                                                !props.values?.certificates[index]?.isActive,
                                                            )}
                                                            type="checkbox"
                                                            title
                                                            inactiveTitle="Неактивный"
                                                            activeTitle="Активный"
                                                        />
                                                    </StyledFlexDiv>

                                                    <StyledInput
                                                        name={`certificates[${index}].guid`}
                                                        onChange={props.handleChange}
                                                        value={props.values?.certificates[index]?.guid}
                                                        title={`ID 1C (Номинал ${index + 1})`}
                                                        placeholder={`ID 1C (Номинал ${index + 1})`}
                                                        typeInput="text"
                                                        maxLength={255}
                                                    />
                                                </StyledDiv>
                                            ),
                                        )}
                                    </StyledMainWrapper>
                                )}
                            </FieldArray>
                            <SubmitButtonsWrapper>
                                <SubmitButtons
                                    reset={() => handleResetForm(props.resetForm, props.values)}
                                    submit={() => handleSubmit(props)}
                                />
                            </SubmitButtonsWrapper>
                        </StyledForm>
                    )}
                </Formik>
            </StyledWrapper>
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetCertificatesLoader,
});

export const CertificatesWithLoader = loader(CertificatesContent);
