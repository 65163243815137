import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as robotsApi from '../../../api/robots';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { Robots } from '../../../types/Endpoints/robots/RobotsContract';
import { getRobots } from './getRobots';

export const EDIT_ROBOTS = 'robots/editRobots';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(getRobots());
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editRobots = createAsyncThunk(
    EDIT_ROBOTS,
    createRequest({
        type: EDIT_ROBOTS,
        loader: async (requestData: Robots) => robotsApi.editRobots(requestData),
        onSuccess,
        showFailNotification: false,
    }),
);
