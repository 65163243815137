import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as usersApi from '../../../api/users';
import { UsersContract } from '../../../api/users/getUsersWithParams';

export const GET_USERS_WITH_PARAMS = 'users/getUsersWithParams';

export const getUsersWithParams = createAsyncThunk(
    GET_USERS_WITH_PARAMS,
    createRequest({
        type: GET_USERS_WITH_PARAMS,
        loader: async (data: UsersContract['pathParams']) => usersApi.getUsersWithParams(data),
        showFailNotification: false,
    }),
);
