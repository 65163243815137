import { useDispatch } from 'react-redux';
import { useEffect, FC } from 'react';
import { useParams } from 'react-router';
import { MainGoodsWithLoader, MainGoodsContent } from './loaders';
import { getProductItemThink } from '../../../../actions/goods/thunks/getProductItem';
import { useTypeSelector } from '../../../../store';
import { selectCategories } from '../../../../selectors/catalog';
import { getCatalogCategories } from '../../../../actions/category';
import { selectCollections } from '../../../../selectors/collections';
import { getCollections } from '../../../../actions/collections/thunks/getCollections';
import { selectBrands } from '../../../../selectors/brands';
import { getCatalogBrands } from '../../../../actions/brands/thunks/getCatalogBrands';
import { IMainGoodsProps } from './interface';

export const MainGoods: FC<IMainGoodsProps> = ({ isEditing, brandName }) => {
    const dispatch = useDispatch();
    const categories = useTypeSelector(selectCategories);
    const collections = useTypeSelector(selectCollections);
    const brands = useTypeSelector(selectBrands);
    const { goodsId } = useParams<{goodsId: string}>();

    useEffect(() => {
        if (goodsId) {
            dispatch(getProductItemThink(+goodsId));
        }

        if (!categories) {
            dispatch(getCatalogCategories());
        }

        if (!collections) {
            dispatch(getCollections());
        }

        if (!brands) {
            dispatch(getCatalogBrands());
        }
    }, []);

    if (isEditing) {
        return (
            <MainGoodsWithLoader
                isEditing={isEditing}
                brandName={brandName}
            />
        );
    }

    return <MainGoodsContent brandName={brandName} />;
};
