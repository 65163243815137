import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
    GET_CATALOG_CATEGORIES,
    GET_CATALOG_CATEGORY,
} from '../../actions/category/thunks';

const selectRoot = ({ catalog }: RootState) => catalog;

const selectRootLoader = ({ loaders }: RootState) => loaders;

const selectSearch = ({ search }: RootState) => search;

export const selectGetCatalogCategoriesLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_CATALOG_CATEGORIES],
);

export const selectGetCatalogCategoryLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_CATALOG_CATEGORY],
);

export const selectCategories = createSelector(
    selectRoot,
    ({ categories }) => categories,
);

export const selectSelectedCategory = createSelector(
    selectRoot,
    ({ selectedCategory }) => selectedCategory,
);

export const selectSearchCategories = createSelector(
    selectRoot,
    selectSearch,
    (categories, search) => (categories?.categories
      && categories?.categories.length
      && categories.categories?.filter(city => city.name.toLowerCase().includes(search?.searchString?.toLowerCase() || '')))
    || categories?.categories,
);
