import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { units } from '../../helpers/styles/units';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { User } from '../../types/Models/Users/User';
import { StatusTypes } from '../../types/Models/Users/UsersStatus';
import { routes } from '../../constants/RouterPath';

interface TableProps {
  className?: string;
  users?: User[];
}

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
    },
};

const StyledCell = styled(TableCell)`
  .status {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};
  }
  .active {
    background-color: rgba(87, 198, 118, 0.05);

    & div {
      background-color: #6db180;
    }
  }

  .inactive {
    background-color: rgba(231, 72, 72, 0.05);

    & div {
      background-color: #e74848;
    }
  }

  .blocked {
    background-color: rgba(215, 180, 106, 0.05);

    & div {
      background-color: #d7b46a;
    }
  }

  .deleted {
    background-color: rgba(187, 52, 52, 0.05);

    & div {
      background-color: #bb3434;
    }
  }
`;

const StyledStatusInfo = styled.div`
  width: ${units(4)};
  height: ${units(4)};
`;

const StyledDiv = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
  width: ${units(62)};
`;

export const StatusActiveComponent = () => (
    <StyledDiv className="status active">
        <StyledStatusInfo />
        <span>Активный</span>
    </StyledDiv>
);

export const StatusDisabledComponent = () => (
    <StyledDiv className="status inactive">
        <StyledStatusInfo />
        <span>Отключен</span>
    </StyledDiv>
);

export const StatusBlockedComponent = () => (
    <StyledDiv className="status blocked">
        <StyledStatusInfo />
        <span>Блокирован</span>
    </StyledDiv>
);

export const StatusDeletedComponent = () => (
    <StyledDiv className="status deleted">
        <StyledStatusInfo />
        <span>Удален</span>
    </StyledDiv>
);

const StatusList = {
    [StatusTypes.ACTIVE]: <StatusActiveComponent />,
    [StatusTypes.DISABLED]: <StatusDisabledComponent />,
    [StatusTypes.BLOCKED]: <StatusBlockedComponent />,
    [StatusTypes.DELETED]: <StatusDeletedComponent />,
};

const getStatusComponent = (status: StatusTypes) => StatusList[status];

export const UsersTable = ({ users }: TableProps): JSX.Element => {
    const history = useHistory();
    return (
        <TableContainer component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Фамилия, Имя</TableCell>
                        <TableCell align="left">Роль</TableCell>
                        <TableCell align="left">Статус</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={[styles.root, styles.body]}>
                    {users
          && users.map(row => (
              <TableRow key={row.id} sx={{ 'td, th': { border: 0 } }}>
                  <TableCell
                      component="th"
                      scope="row"
                      className="text-grey"
                      sx={{ width: '10%' }}
                  >
                      {row.id}
                  </TableCell>
                  <TableCell align="left">
                      {`${row.lastName || ''} ${row.firstName || ''}`}
                  </TableCell>

                  <TableCell align="left">{row.role}</TableCell>

                  <StyledCell align="left" sx={{ width: '20%' }}>
                      {getStatusComponent(row.status as keyof typeof StatusList)}
                  </StyledCell>
                  <TableCell align="left" sx={{ width: '10%' }}>
                      <EditButton
                          typeButton={TypesButton.SECONDARY}
                          size={SizesButton.M}
                          onClick={() => history.push(`${routes.edit_user}/${row.id}`)}
                      />
                  </TableCell>
              </TableRow>
          ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
