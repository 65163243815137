import { createReducer } from '@reduxjs/toolkit';
import { setBreakingPath, setInitialPath } from '../../actions/systems';

export interface Systems {
  breakingPath?: string;
  initialPath?: string;
}

export const systems = createReducer<Systems>({}, builder => {
    builder.addCase(setBreakingPath, (state, { payload }) => ({
        ...state,
        breakingPath: payload,
    }));
    builder.addCase(setInitialPath, (state, { payload }) => ({
        ...state,
        initialPath: payload,
    }));
});
