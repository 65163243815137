import { Route, Switch } from 'react-router';
import { routes } from '../constants/RouterPath';
import { LoginPage } from './Login';
import { RootRouter } from './RootRouter';
import { UserContent } from '../hocs/users/UserContent';
import { Snackbars } from '../components/Snackbars';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { InstallmentAndCredit } from './DynamicPages/InstallmentAndCredit';
import { PurchaseAndExchange } from './DynamicPages/PurchaseAndExchange';
import { PaymentAndDelivery } from './DynamicPages/PaymentAndDeliveryBlock';
import { LoyaltyProgram } from './DynamicPages/LoyaltyProgram';
import { CheckRoles } from '../components/CheckRoles';
import { Roles } from '../constants/Roles';
import { Certificates } from './Certificates';
import { Shops } from './Shops';
import { useClearSearchData } from '../hooks/useClearSearchData';
import { Documents } from './Docs';
import { Users } from './Users';
import { CreateUser } from './Users/CreateUser';
import { Notes } from './Notes';
// import { Logs } from './Logs';

import { CreateShop } from './Shops/CreateShop';
import { Settings } from './Catalog/Settings';
import { EditSettings } from './Catalog/Settings/EditCategory';
import { CreateCategory } from './Catalog/Settings/CreateCategory';
import { Filters } from './Catalog/Filters';
import { Brands } from './Catalog/Brands';
import { CreateBrand } from './Catalog/Brands/CreateBrand';
import { EditBrand } from './Catalog/Brands/EditBrand';
// import { CreateCollection } from './Catalog/Сollections/CreateCollection';
import { EditCollections } from './Catalog/Сollections/EditCollection';
import { Collections } from './Catalog/Сollections';
import { CreateFilter } from './Catalog/Filters/CreateFilter';
import { EditFilter } from './Catalog/Filters/EditFilter';
import { EditShop } from './Shops/EditShop';
import { EditUser } from './Users/EditUser';
import { SiteBody } from './MainSettings/SiteBody';
import { MobileBody } from './MainSettings/MobBody';
import { CreateSale } from './Sales/CreateSales';

import { Sales } from './Sales';
import { EditSales } from './Sales/EditSales';
import { Blog } from './Blog';
import { EditBlog } from './Blog/EditBlog';
import { CreateBlog } from './Blog/CreateBlog';
import { SearchSettings } from './Landings';
import { Goods } from './Goods';
import { EditGood } from './Goods/EditGood';
import { Footer } from './MainSettings/Footer';
import { Handbook } from './Handbook';
import { OrderList } from './OrderList';
import { EditOrder } from './OrderList/EditOrder';
import { OrderSettings } from './OrderSettings';
// import { Push } from './Push';
// import { EditPush } from './Push/EditPush';
// import { CreatePush } from './Push/CreatePush';
import { FilterCloud } from './FilterCloud';
import { EditFilterCloud } from './FilterCloud/EditFilterCloud';
import { Menu } from './Catalog/Menu';
import { MenuEdit } from './Catalog/Menu/MenuEdit';
import { DashBoard } from './Dashboard';
import { Robots } from './Robots';
import { EditSearch } from './Landings/EditLanding';
import { CreateLanding } from './Landings/CreateLanding';

export const Router = () => {
    useScrollToTop();
    useClearSearchData();

    return (
        <Switch>

            <Route exact path={routes.installment_and_credit}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <InstallmentAndCredit />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.purchase_and_exchange}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <PurchaseAndExchange />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.payment_and_delivery}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <PaymentAndDelivery />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.loyalty_program}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <LoyaltyProgram />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.settings}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Settings />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_category}/:categoryId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditSettings />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.brands}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Brands />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.create_brand}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <CreateBrand />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_brand}/:brandId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditBrand />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.create_category}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <CreateCategory />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_shop}/:shopId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditShop />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.filters}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Filters />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_filter}/:filterId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditFilter />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.create_filter}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <CreateFilter />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.certificates}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Certificates />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.shops}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Shops />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.docs}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Documents />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.users}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Users />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.create_user}>
                <CheckRoles accessRoles={[Roles.ADMIN]}>
                    <UserContent>
                        <CreateUser />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.notes}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Notes />
                    </UserContent>
                </CheckRoles>
            </Route>

            {/* <Route exact path={routes.logs}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Logs />
                    </UserContent>
                </CheckRoles>
            </Route> */}

            <Route exact path={routes.sales}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Sales />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.blog}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Blog />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_blog}/:blogId`}>
                <CheckRoles accessRoles={[Roles.ADMIN]}>
                    <UserContent>
                        <EditBlog />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.create_blog}`}>
                <CheckRoles accessRoles={[Roles.ADMIN]}>
                    <UserContent>
                        <CreateBlog />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.create_sale}>
                <CheckRoles accessRoles={[Roles.ADMIN]}>
                    <UserContent>
                        <CreateSale />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_sale}/:saleId`}>
                <CheckRoles accessRoles={[Roles.ADMIN]}>
                    <UserContent>
                        <EditSales />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.create_shop}>
                <CheckRoles accessRoles={[Roles.ADMIN]}>
                    <UserContent>
                        <CreateShop />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.collections}>
                <CheckRoles accessRoles={[Roles.ADMIN]}>
                    <UserContent>
                        <Collections />
                    </UserContent>
                </CheckRoles>
            </Route>

            {/* <Route exact path={routes.create_collection}>
                <CheckRoles accessRoles={[Roles.ADMIN]}>
                    <UserContent>
                        <CreateCollection />
                    </UserContent>
                </CheckRoles>
            </Route> */}

            <Route exact path={`${routes.edit_collection}/:collectionId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditCollections />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_user}/:userId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditUser />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.site_body}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <SiteBody />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.mobile_body}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <MobileBody />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.search_settings}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <SearchSettings />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.search_settings_edit}/:searchId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditSearch />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.search_settings_create}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <CreateLanding />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.goods}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Goods />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.goods_edit}/:goodsId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditGood isEditing />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.goods_add}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditGood />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.footer}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Footer />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.handbook}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Handbook />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.order_list}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <OrderList />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_order}/:orderId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditOrder />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.order_settings}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <OrderSettings />
                    </UserContent>
                </CheckRoles>
            </Route>

            {/* <Route exact path={routes.notifications}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Push />
                    </UserContent>
                </CheckRoles>
            </Route> */}

            {/* <Route exact path={`${routes.edit_notifications}/:pushId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditPush />
                    </UserContent>
                </CheckRoles>
            </Route> */}

            {/* <Route exact path={routes.create_notifications}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <CreatePush />
                    </UserContent>
                </CheckRoles>
            </Route> */}

            <Route exact path={routes.filter_cloud}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <FilterCloud />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_filter_cloud}/:filterCloudId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <EditFilterCloud />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.menu}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Menu />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={`${routes.edit_menu}/:menuId`}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <MenuEdit />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.dashboard}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <DashBoard />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.robots}>
                <CheckRoles accessRoles={[Roles.ADMIN, Roles.USER]}>
                    <UserContent>
                        <Robots />
                    </UserContent>
                </CheckRoles>
            </Route>

            <Route exact path={routes.login}>
                <LoginPage />
                <Snackbars />
            </Route>

            <Route>
                <RootRouter />
            </Route>
        </Switch>
    );
};
