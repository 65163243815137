import {
    ComponentType,
    useCallback,
    useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { TypesModal } from '../../types/TypesModalComponent';
import { DefaultModal } from './DefaultModal';
import { selectModals } from '../../selectors/modals';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import { Modal as ModalProps } from '../../types/Models/Modal/Modal';
import { useOutSideClick } from '../../hooks/useOutSideClick';
import { removeModal } from '../../actions/modal';
import { MapModal } from '../../pages/Map/sections/mapModal';
import { ConfirmModal } from '../ConfirmModal';
import { NotificationModal } from '../NotificationModal';
import { ChooseAddressModal } from '../ChooseAddressModal';
import { ImageModal } from '../ImageModal';
import { CreateTagModal } from '../CreateTagModal';
import { ChooseCityModal } from '../ChooseCityModal';
import { AddSearchItemModal } from '../AddSearchItemModal';

const StyledModal = styled.div`
  padding-left: 0;
  box-sizing: border-box;
  margin-top: 0;
  
  min-width: max-content;
  min-height: max-content;
`;

const components: Record<string, ComponentType<Omit<ModalProps, 'type'>>> = {
    [TypesModal.MAP]: MapModal,
    [TypesModal.CONFIRM]: ConfirmModal,
    [TypesModal.NOTIFICATION]: NotificationModal,
    [TypesModal.CHOOSE_ADDRESS]: ChooseAddressModal,
    [TypesModal.IMAGE]: ImageModal,
    [TypesModal.CREATE_TAG]: CreateTagModal,
    [TypesModal.CHOOSE_CITY]: ChooseCityModal,
    [TypesModal.ADD_SEARCH_ITEM]: AddSearchItemModal,

};

export const Modal = ({
    id,
    data,
    message,
    type,
    icon,
    imgSrc,
    onClose,
    onSuccess,
    onSuccessMessage,
    withCloseIcon,
    withCloseAction,
}: ModalProps) => {
    const ModalComponent = components[type as string] || DefaultModal;
    const modals = useSelector(selectModals);
    const ref = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useLockBodyScroll();

    const handleOutSideClick = useCallback((event: Event) => {
        if (!ref.current?.contains(event.target as Node)) {
            if (onClose) {
                onClose();
            }
            modals.forEach(({ id: modalId }) => dispatch(removeModal(modalId)));
        }
    }, [dispatch, modals, onClose]);

    useOutSideClick(ref, handleOutSideClick);

    return (
        <StyledModal ref={ref}>
            <ModalComponent
                id={id}
                data={data as never}
                message={message}
                icon={icon}
                onClose={onClose}
                onSuccess={onSuccess}
                onSuccessMessage={onSuccessMessage}
                withCloseIcon={withCloseIcon}
                withCloseAction={withCloseAction}
                imgSrc={imgSrc}
            />
        </StyledModal>
    );
};
