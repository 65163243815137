import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as pricesApi from '../../../api/prices';
import { IPrice } from '../../../types/Endpoints/prices/PricesContracts';

const UPDATE_PRICE = 'prices/updatePrice';

export const updatePrice = createAsyncThunk<IPrice[], IPrice[]>(
    UPDATE_PRICE,
    createRequest({
        type: UPDATE_PRICE,
        loader: async (data: IPrice[]) => pricesApi.updatePrice(data),
    }),
);
