import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { FontWeight } from '../../../constants/Styles/fontWeight';

export const Navbar = {
    Items: styled.ul`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: ${units(4)} ${units(0)} ${units(0)} ${units(2)};
    // height: 100%;

    width: ${units(124)};

    background-color: ${colors.grey60};

    list-style: none;

    :hover {
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      width: .5rem;
      background-color: transparent;
      opacity: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.grey40};
      border-radius: .25rem;
    }
  `,
    Item: styled.li``,
    LinkButton: styled(NavLink)`
    display: flex;
    align-items: center;
    gap: ${units(2)};
    padding: ${units(4)} ${units(5)} ${units(4)} ${units(5)};
    width: 100%;

    color: ${colors.greyDark};
    text-decoration: none;
    cursor: pointer;
    font-weight: ${FontWeight.REGULAR};
    border-left: ${units(3)} solid ${colors.grey60};

    transition: color 0.4s ease;

    :hover {

      background-color: ${colors.white};
    }

    &.active {
      font-weight: ${FontWeight.MEDIUM};
      border-left: ${units(3)} solid ${colors.gold};
      background-color: ${colors.white};            
    }

    &.active svg {
      filter: brightness(0) saturate(100%) invert(14%) sepia(0%) saturate(3257%) hue-rotate(171deg) brightness(86%) contrast(74%);
    }

    ${typography(10)}
  `,
    LinkText: styled.p`
      
    `,
};
