export const titlesTable = [
    'ID',
    'Название товара, SKU',
    'Категория',
    'Статус',
    null,
];

export enum StatusesProduct {
    activated = 'Активирован',
    deactivated = 'Деактивирован'
}
