import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { EditOrderWithLoader } from './loaders';
import { getOrder } from '../../../actions/orders/thunks/getOrder';
import { getOrderSettings } from '../../../actions/orderSettings';

export const EditOrder = () => {
    const dispatch = useDispatch();

    const { orderId } = useParams<{orderId: string}>();

    useEffect(() => {
        dispatch(getOrder(orderId));
        dispatch(getOrderSettings());
    }, []);
    return <EditOrderWithLoader />;
};
