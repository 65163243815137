import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_DOCUMENTS } from '../../actions/docs';

const selectRoot = ({ documents }: RootState) => documents;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetDocumentsLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_DOCUMENTS],
);

export const selectDocuments = createSelector(
    selectRoot,
    ({ documents }) => (documents?.length ? [...documents].sort((a, b) => a.id - b.id) : documents),
);
