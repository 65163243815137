import { brasleti } from './brasleti';
import { drugoe } from './drugoe';
import { koltsa } from './koltsa';
import { naruchnyeChasy } from './naruchnyeChasy';
import { podveskiKolye } from './podveskiKolye';
import { ruchkParker } from './ruchkParker';
import { sergi } from './sergi';
import { tsepi } from './tsepi';

export enum Categorykeys {
    NARUCHYE_CHASY = 'ba29f6dd-c407-11e5-8123-0050569f2b64',
    RUCHKI_PARKER = 'ba29f6e0-c407-11e5-8123-0050569f2b64',
    KOLTSA = 'a153d050-690a-11e5-8107-0050569f2b64',
    SERGI = 'a153d057-690a-11e5-8107-0050569f2b64',
    TSEPI = 'cdb27197-6c30-11e5-810d-0050569f2b64',
    BRASLETI = 'a153d064-690a-11e5-8107-0050569f2b64',
    PODVESKI_KOLYE = '0832687d-1892-11e7-817f-0050569f2b64',
    DRUGOE = 'a153d065-690a-11e5-8107-0050569f2b64',
}

export const descriptionsToCategories = {
    [Categorykeys.NARUCHYE_CHASY]: naruchnyeChasy,
    [Categorykeys.RUCHKI_PARKER]: ruchkParker,
    [Categorykeys.KOLTSA]: koltsa,
    [Categorykeys.SERGI]: sergi,
    [Categorykeys.TSEPI]: tsepi,
    [Categorykeys.BRASLETI]: brasleti,
    [Categorykeys.PODVESKI_KOLYE]: podveskiKolye,
    [Categorykeys.DRUGOE]: drugoe,
};
