import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { colors } from '../../constants/Colors';
import { Input, InputProps } from '../Input';
import { TypesIcon } from '../../types/TypesIcon';
import { IconBig } from '../Icon/IconBig';

const StyledInput = styled(Input)`
  background-color: ${colors.white}; 
`;

const StyledPostfix = styled(IconBig)`
  cursor: pointer;

  opacity: 0.57;
  
  :hover {
    opacity: 1;
  }
`;

export const PasswordInput = ({
    name,
    onChange,
    onBlur,
    isTouched,
    value = '',
    error,
    ...props
}: InputProps) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    /* const FORGET_PASSWORD_LINK = 'https://sd.softtech.by/'; */

    const handleViewPassword = useCallback(event => {
        event.preventDefault();
        event.stopPropagation();

        setPasswordVisible(!passwordVisible);
    }, [passwordVisible]);

    const inputType = passwordVisible ? 'text' : 'password';
    const postfixType = passwordVisible ? TypesIcon.EYE_BIG : TypesIcon.EYE_SLASH_BIG;

    /*     const titleInputElement = () => (
        <StyledTitleInputWrapper>
            <StyledTitleInput>Пароль</StyledTitleInput>
            <StyledLink href={FORGET_PASSWORD_LINK} target="_blank">
                Забыли пароль?
            </StyledLink>
        </StyledTitleInputWrapper>
    ); */

    return (
        <StyledInput
            name={name}
            onChange={onChange}
            value={value || ''}
            /* titleElement={titleInputElement()} */
            placeholder="Пароль"
            typeInput={inputType}
            error={error}
            onBlur={onBlur}
            isTouched={isTouched}
            postfix={(
                <StyledPostfix
                    type={postfixType}
                    onMouseDown={handleViewPassword}
                />
            )}
            {...props}
        />
    );
};
