export enum LabelsFormEditGoods {
  nameProduct = 'Название товара',
  id = 'ID',
  elemFromUpload = 'Элемент из выгрузки',
  showOnSite = 'Отображать на сайте',
  showOnMobile = 'Отображать в мобильном приложении',
  hit = 'ХИТ',
  new = 'NEW',
  category = 'Категория',
  brand = 'Бренд',
  collection = 'Коллекция',
}

export enum NamesFieldsFormEditGoods {
  name = 'name',
  id = 'id',
  isShowOnSite = 'isShowOnSite',
  isShowOnMobile = 'isShowOnMobile',
  isHit = 'isHit',
  isNew = 'isNew',
  categoryId = 'categoryId',
  brandId = 'brandId',
  collectionId = 'collectionId',
  externalId = 'externalId'
}

export const TypesCategories = {
    BRAND: 'BRAND',
    DEFAULT: 'DEFAULT',
    COLLECTION: 'COLLECTION',
} as const;

export const messages = {
    goods: 'Товары',
    display: 'Отображение',
    characteristic: 'Характеристика',
};
