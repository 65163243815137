import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as salesApi from '../../../api/sales';
import { GetAllProductsContract } from '../../../types/Endpoints/sales/PromitionsContracts';

export const GET_ALL_PRODUCTS = 'promotions/getAllProducts';
export const GET_ALL_PRODUCTS_LOAD_MORE = 'promotions/getAllProductsLoadMore';

export const getAllProductsThink = createAsyncThunk(
    GET_ALL_PRODUCTS,
    createRequest({
        type: GET_ALL_PRODUCTS,
        loader: async (params: GetAllProductsContract['pathParams']) => salesApi.getAllProducts(params),
    }),
);

export const getAllProductsThinkLoadMore = createAsyncThunk(
    GET_ALL_PRODUCTS_LOAD_MORE,
    createRequest({
        type: GET_ALL_PRODUCTS,
        loader: async (params: GetAllProductsContract['pathParams']) => salesApi.getAllProducts(params),
    }),
);
