import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';

const StyledRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${units(4)};
  
  background-color: ${colors.white};
  
  border: 1px solid ${colors.grayscale05};
  border-radius: ${units(4)};
`;

export const Wrapper = ({ className, children }: PropsWithChildren<{className?: string}>) => (
    <StyledRoot className={className}>
        {children}
    </StyledRoot>
);
