import { createAsyncThunk } from '@reduxjs/toolkit';
import { MobileBlockKey } from '../../../types/Models/MainPage/MainPageModel';
import { createRequest } from '../../../helpers/api/createRequest';
import * as mainPageApi from '../../../api/mainPage';
import { EditMainPageMobileContract } from '../../../types/Endpoints/mainPage/MainPageContracts';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const EDIT_MOBILE_BANNER = 'mainPage/getWebBanners';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editMobileBanners = createAsyncThunk(
    EDIT_MOBILE_BANNER,
    createRequest({
        type: EDIT_MOBILE_BANNER,
        loader: async (data: {key: MobileBlockKey, newData: EditMainPageMobileContract['requestBody']}) => mainPageApi.editMobileBanners(data),
        onSuccess,
    }),
);
