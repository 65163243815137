import styled from 'styled-components';
import { useState } from 'react';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import { selectGetMobileBlocksLoader } from '../../../../selectors/mainPage';
import { MainBannerTablePageContent } from './MainBannerTablePage';
import { CreateMainBanner } from './CreateMainBannerItem';
import {
    BlockItemEdit, MobileBlockKey,
} from '../../../../types/Models/MainPage/MainPageModel';
import { EditMainBanner } from './EditMainBannerItem';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export enum PageStatus {
  MAIN = 'MAIN',
  EDIT = 'EDIT',
  ADD = 'ADD',
}

export const MainBannerContent = (): JSX.Element => {
    const [activeStatus, setActiveStatus] = useState(PageStatus.MAIN);

    const [selectedItem, setSelectedItem] = useState<BlockItemEdit<MobileBlockKey> | null>(null);

    const handleChangeContent = (
        key: PageStatus,
        data: BlockItemEdit<MobileBlockKey> | null = null,
    ) => {
        setActiveStatus(key);
        if (data) setSelectedItem(data);
    };

    const getPageContent = () => {
        if (activeStatus === PageStatus.MAIN) { return <MainBannerTablePageContent changePage={handleChangeContent} />; }
        if (activeStatus === PageStatus.ADD) { return <CreateMainBanner changePage={handleChangeContent} />; }
        return activeStatus === PageStatus.EDIT && selectedItem ? (
            <EditMainBanner data={selectedItem} changePage={handleChangeContent} />
        ) : null;
    };

    return <StyledWrapper>{getPageContent()}</StyledWrapper>;
};

const loader = createLoadHoc({
    selectLoader: selectGetMobileBlocksLoader,
});

export const MainBunnerWithLoader = loader(MainBannerContent);
