import { request } from '../../helpers/api/request';
import { EndpointContract } from '../../types/Endpoints/EndpointContract';

export interface EditUserItem {
    id?: number;
    role: string;
    status: string;
    firstName: string;
    lastName: string;
    login: string;
    password?: string;
  }

export interface EditUserContract extends EndpointContract {
    requestBody: Omit<EditUserItem, 'id'>;
}

export const editUser = async ({ id, ...requestData }: EditUserItem) => {
    const { data } = await request<EditUserContract>({
        url: `admin/users/${id}`,
        method: 'PATCH',
        data: requestData,
    });

    return data;
};
