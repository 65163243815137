import { request } from '../../helpers/api/request';
import { GetLogsContract } from '../../types/Endpoints/logs/LogsContracts';

export const getLogs = async (params: GetLogsContract['pathParams']) => {
    const { data } = await request<GetLogsContract>({
        url: 'admin/logs',
        params,
    });

    return data;
};
