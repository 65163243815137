import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as collectionsApi from '../../../api/collections';
import { Collection } from '../../../types/Models/Collections/Collection';

export const GET_COLLECTIONS = 'collections/getCollections';

export const getCollections = createAsyncThunk<Collection[]>(
    GET_COLLECTIONS,
    createRequest({
        type: GET_COLLECTIONS,
        loader: async () => collectionsApi.getCollections(),
        showFailNotification: false,
    }),
);
