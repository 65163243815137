import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Roles } from '../../constants/Roles';
import { routes } from '../../constants/RouterPath';
import {
    selectActiveUser,
    selectHasRolesFactory,
    selectRoles,
    selectUserLoaders,
} from '../../selectors/users';
import { LoaderState } from '../../types/Loaders/LoaderState';
import { RedirectWithQuery } from '../RedirectWithQuery';
import { Preloader } from '../Preloader';

export interface CheckRolesProps {
  accessRoles: Roles[];
  children?: ReactElement
}

const defaultPathByRole = (roles?: Roles) => {
    switch (true) {
        case roles?.includes(Roles.ADMIN):
            return `${routes.dashboard}`;
        case roles?.includes(Roles.USER):
            return `${routes.dashboard}`;
        default:
            return routes.login;
    }
};

export const CheckRoles = ({
    children,
    accessRoles,
}: CheckRolesProps) => {
    const hasAccess = useSelector(selectHasRolesFactory(accessRoles));

    const userAuthorizedLoader = useSelector(selectUserLoaders);
    const activeUser = useSelector(selectActiveUser);

    const roles = useSelector(selectRoles) as Roles;

    if (userAuthorizedLoader?.state === LoaderState.PENDING || (userAuthorizedLoader?.state === LoaderState.FULFILLED && !activeUser)) {
        return <Preloader />;
    }

    if (hasAccess && children) {
        return children;
    }

    return <RedirectWithQuery to={defaultPathByRole(roles)} />;
};
