import { createReducer } from '@reduxjs/toolkit';
import { PaymentMethodItem } from '../../types/Models/OrderSettings/OrderSettings';
import { getPaymentMethods } from '../../actions/paymentMethods';

export interface PaymentMethodsModel {
  paymentMethods?: PaymentMethodItem[];
}

export const paymentMethods = createReducer<PaymentMethodsModel>({ }, builder => {
    builder.addCase(getPaymentMethods.fulfilled, (state, { payload }) => ({
        ...state,
        paymentMethods: payload,
    }));
});
