import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createLoadHoc } from '../../helpers/hocs/createLoadHoc';
import { SearchBar } from '../../components/SearchBar';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { routes } from '../../constants/RouterPath';
import { useTypeSelector } from '../../store';
import { Icon } from '../../components/Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { selectShops, selectShopsLoader } from '../../selectors/shops';
import { selectSearchData } from '../../selectors/search';
import { getShopsWithParams } from '../../actions/shops/thunks';
import { FilterButton } from '../../components/Button/filterButton';
import { ShopsTable } from '../../components/Table/ShopsTable';
import { RadioButton } from '../../components/RadioButton';
import * as S from './styles';
import { ShopsServices, StatusShops } from './constants';
import { PageTitles } from '../../constants/PageTitles';

const ShopsContent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const shops = useTypeSelector(selectShops);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState<string | undefined>();
    const [servicesFilter, setServicesFilter] = useState<string | undefined>();
    const filterRef = useRef<HTMLDivElement | null>(null);

    const searchString = useTypeSelector(selectSearchData);

    const handleOpenFilter = () => {
        setIsFilterOpen(v => !v);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
            setIsFilterOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            let isActive;
            if (statusFilter === StatusShops.ACTIVE) {
                isActive = true;
            } else if (statusFilter === StatusShops.INACTIVE) {
                isActive = false;
            }

            dispatch(getShopsWithParams({ address: searchString, addons: servicesFilter ? [servicesFilter] : undefined, isActive }));
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [searchString, servicesFilter, statusFilter]);

    return (
        <S.Wrapper>
            <S.StyledPageTitle>{PageTitles.SHOPS}</S.StyledPageTitle>
            <S.PanelWrapper>
                <S.SearchBarWrapper>
                    <SearchBar />
                </S.SearchBarWrapper>

                <S.FilterWrapper>
                    <FilterButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOpenFilter}
                    />
                    {isFilterOpen && (
                        <S.FilterWindow ref={filterRef}>
                            <S.CheckBoxWrapper>
                                <S.IdInfo>Услуги</S.IdInfo>
                                <RadioButton
                                    name="services"
                                    title="Все"
                                    isChecked={servicesFilter === undefined}
                                    onChange={() => setServicesFilter(undefined)}
                                />
                                <RadioButton
                                    name="services"
                                    title="Скупка"
                                    isChecked={servicesFilter === ShopsServices.PURCHASE}
                                    onChange={() => setServicesFilter(ShopsServices.PURCHASE)}
                                />
                                <RadioButton
                                    name="services"
                                    title="Скупка по принципу обмена"
                                    isChecked={servicesFilter === ShopsServices.PURCHASE_EXCHANGE}
                                    onChange={() => setServicesFilter(ShopsServices.PURCHASE_EXCHANGE)}
                                />
                            </S.CheckBoxWrapper>
                            <S.CheckBoxWrapper>
                                <S.IdInfo>Статус</S.IdInfo>
                                <RadioButton
                                    name="status"
                                    title="Все"
                                    isChecked={statusFilter === undefined}
                                    onChange={() => setStatusFilter(undefined)}
                                />
                                <RadioButton
                                    name="status"
                                    title="Активный"
                                    isChecked={statusFilter === StatusShops.ACTIVE}
                                    onChange={() => setStatusFilter(StatusShops.ACTIVE)}
                                />
                                <RadioButton
                                    name="status"
                                    title="Отключен"
                                    isChecked={statusFilter === StatusShops.INACTIVE}
                                    onChange={() => setStatusFilter(StatusShops.INACTIVE)}
                                />
                            </S.CheckBoxWrapper>
                        </S.FilterWindow>
                    )}
                </S.FilterWrapper>

                <S.StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => history.push(routes.create_shop)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </S.StyledButton>
            </S.PanelWrapper>
            <S.Root>
                <ShopsTable shops={shops} />
            </S.Root>
        </S.Wrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectShopsLoader,
});

export const ShopsWithLoader = loader(ShopsContent);
