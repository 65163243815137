import styled from 'styled-components';
import { units } from '../../../../../helpers/styles/units';
import { Input } from '../../../../../components/Input';
import { FontWeight } from '../../../../../constants/Styles/fontWeight';
import { colors } from '../../../../../constants/Colors';
import { typography } from '../../../../../helpers/styles/typography';
import { Button } from '../../../../../components/Button';

export const RootWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${units(63)};
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
  max-width: ${units(322)};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const DeleteButton = styled(StyledButton)`
  width: ${units(102)};
  padding: ${units(0)} ${units(16)} ${units(0)} ${units(14)};
`;

export const SubmitButtons = styled.div`
  margin-left: -${units(12)};
`;

export const Subtitle = styled.span`
  font-weight: ${FontWeight.MEDIUM};

  ${typography(10)};
`;

export const SwitchWrapper = styled(MainWrapper)`
  gap: ${units(8)};
`;

export const NumberInputWrapper = styled.div`
  width: ${units(83)};
`;
