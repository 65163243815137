import React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FieldArray, Formik } from 'formik';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Checkbox } from '../../components/Checkbox';
import { PageTitle } from '../../components/PageTitle';
import { SubmitButtons } from '../../components/StyledComponents';
import { OrderSettingsBreadcrumbs } from '../../constants/BreadCrumbs/orderSettingsBreadcrumbs';
import { isEqualObject } from '../../helpers/isEqual';
import { addNotification } from '../../actions/notifications';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { TypesIcon } from '../../types/TypesIcon';
import {
    selectGetOrderSettingsLoader,
    selectOrderSettings,
} from '../../selectors/orderSettings';
import { useTypeSelector } from '../../store';
import { OrderSettings, PaymentMethod } from '../../types/Models/OrderSettings/OrderSettings';
import { createLoadHoc } from '../../helpers/hocs/createLoadHoc';
import { selectPaymentMethods } from '../../selectors/paymentMethods';
import { editOrderSettings } from '../../actions/orderSettings/thunks/editOrderSettings';
import * as S from './styles';
import { FormData } from './constants';

export const OrderSettingsContent = () => {
    const dispatch = useDispatch();

    const settings = useTypeSelector(selectOrderSettings);
    const payments = useTypeSelector(selectPaymentMethods);

    const handleSubmit = (props: any) => {
        const requestmethods = [...props.settings];
        const req = requestmethods.map(method => {
            const newMethods = method.paymentMethods.map((el: { id: number }) => ({
                id: el?.id,
            }));

            return {
                ...method,
                paymentMethods: newMethods,
            };
        });
        dispatch(editOrderSettings(req));
    };

    const handleResetForm = (calb: () => void, values: any) => {
        calb();
        const equal = isEqualObject({ settings }, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    const handlePaymentsChange = (
        paymentMethod: any,
        methods: PaymentMethod[],
        fieldName: string,
        callback: (name: string, value: any) => void,
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const isChecked = event.target?.checked;
        const currentPayments = methods || [];

        let updatedPayments = [...currentPayments];

        const currentMethod = updatedPayments.find(
            method => method?.type === paymentMethod.type,
        );

        if (isChecked && !currentMethod) {
            updatedPayments = [
                ...currentPayments,
                { type: paymentMethod.type, id: paymentMethod.id },
            ];
        } else if (!isChecked && currentMethod) {
            updatedPayments = currentPayments.filter(
                method => method?.type !== paymentMethod.type,
            );
        }

        callback(fieldName, updatedPayments);
    };

    const initialValue: FormData = {
        settings: settings || [],
    };

    const validationSchema = yup.object().shape({
        settings: yup.array().of(
            yup.object().shape({
                name: yup.string().required('Поле является обязательным'),
                description: yup.string().required('Поле является обязательным'),
            }),
        ),
    });

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>Настройки</PageTitle>
                <Breadcrumbs breadcrumbs={OrderSettingsBreadcrumbs()} />
                <Formik<FormData>
                    initialValues={initialValue}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {props => (
                        <S.StyledForm onSubmit={handleSubmit}>
                            <FieldArray name="settings">
                                {() => (
                                    <S.MainWrapper>
                                        {props.values.settings?.map(
                                            (block: OrderSettings, index: number) => (

                                                <S.MainWrapper key={block.id}>
                                                    <S.TitlesWrapper>
                                                        <S.StyledInput
                                                            name={`settings[${index}].name`}
                                                            onChange={props.handleChange}
                                                            value={props.values?.settings[index]?.name}
                                                            title="Название*"
                                                            placeholder="Название*"
                                                            typeInput="text"
                                                            maxLength={255}
                                                            isTouched={
                                                                props.touched?.settings && props.touched.settings[index].name
                                                            }
                                                            error={
                                                                props.errors?.settings && props.errors?.settings[index]?.name
                                                            }
                                                            count={props.submitCount}
                                                        />
                                                        <S.StyledInput
                                                            name={`settings[${index}].description`}
                                                            onChange={props.handleChange}
                                                            value={props.values?.settings[index]?.description}
                                                            title="Описание*"
                                                            placeholder="Описание*"
                                                            typeInput="text"
                                                            maxLength={255}
                                                            isTouched={
                                                                props.touched?.settings && !!props.touched?.settings[index]?.description
                                                            }
                                                            error={
                                                                props.errors?.settings && props.errors?.settings[index]?.description
                                                            }
                                                            count={props.submitCount}
                                                        />
                                                    </S.TitlesWrapper>

                                                    {props.values?.settings[index]
                                                        ?.additionalDescription ? (
                                                            <S.StyledTextEditor
                                                                name={`settings[${index}].additionalDescription`}
                                                                onChange={props.setFieldValue}
                                                                valueEditor={
                                                                    props.values.settings[index]
                                                                        .additionalDescription
                                                                }
                                                                withoutPictures={false}
                                                            />
                                                        ) : null}

                                                    <S.CheckboxesWrapper>
                                                        <S.TitleInfo>Способы оплаты</S.TitleInfo>
                                                        {payments && payments.map(paymentMethod => (
                                                            <Checkbox
                                                                key={paymentMethod.id}
                                                                name="deliveryPayments"
                                                                title={paymentMethod.name}
                                                                checked={props.values.settings[
                                                                    index
                                                                ].paymentMethods.some(
                                                                    method => method.type === paymentMethod.type,
                                                                )}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePaymentsChange(
                                                                    paymentMethod,
                                                                    props.values.settings[index].paymentMethods,
                                                                    `settings[${index}].paymentMethods`,
                                                                    props.setFieldValue,
                                                                    e,
                                                                )}
                                                            />
                                                        ))}
                                                    </S.CheckboxesWrapper>
                                                    <S.CheckboxesWrapper>
                                                        <S.TitleInfo>Отображение</S.TitleInfo>
                                                        <Checkbox
                                                            name={`settings[${index}].isShowOnSite`}
                                                            title="Отображение на сайте"
                                                            checked={
                                                                props.values?.settings[index]?.isShowOnSite
                                                            }
                                                            onChange={props.handleChange}
                                                        />

                                                        <Checkbox
                                                            name={`settings[${index}].isShowOnMobile`}
                                                            title="Отображать в мобильном приложении"
                                                            checked={
                                                                props.values?.settings[index]?.isShowOnMobile
                                                            }
                                                            onChange={props.handleChange}
                                                        />
                                                    </S.CheckboxesWrapper>
                                                </S.MainWrapper>
                                            ),
                                        )}
                                    </S.MainWrapper>
                                )}
                            </FieldArray>
                            <S.ButtonsWrapper>
                                <SubmitButtons
                                    reset={() => handleResetForm(props.resetForm, props.values)}
                                    submit={props.submitForm}
                                />
                            </S.ButtonsWrapper>
                        </S.StyledForm>
                    )}
                </Formik>
            </S.Wrapper>
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetOrderSettingsLoader,
});

export const OrderSettingsWithLoader = loader(OrderSettingsContent);
