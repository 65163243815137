import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as categoriesApi from '../../../api/categories';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const DELETE_CATALOG_SUB_CATEGORY = 'catalog/deleteCatalogSubCategory';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.ERROR,
        message: {
            title: 'Подкатегория удалена',
            description: '',
        },
        icon: TypesIcon.SNACK_ERROR,
    }));
};

export const deleteCatalogSubCategory = createAsyncThunk(
    DELETE_CATALOG_SUB_CATEGORY,
    createRequest({
        type: DELETE_CATALOG_SUB_CATEGORY,
        loader: async (id: {categoryId: number, subCategoryId:number}) => categoriesApi.deleteSubCategory(id),
        onSuccess,
    }),
);
