import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as documentsApi from '../../../api/docs';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { UpdateDocumnets } from '../../../types/Endpoints/documents/DocumentsContracts';
import { getDocuments } from './getDocuments';

export const UPDATE_DOCUMENTS = 'documents/updateDocuments';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(getDocuments());

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const updateDocuments = createAsyncThunk(
    UPDATE_DOCUMENTS,
    createRequest({
        type: UPDATE_DOCUMENTS,
        loader: async (requestData: UpdateDocumnets) => documentsApi.updateDocuments(requestData),
        onSuccess,
    }),
);
