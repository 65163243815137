/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import { typography } from '../../../helpers/styles/typography';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { BreakPoints } from '../../../constants/Styles/breakPoints';

export interface TabData {
    name: string,
    content: JSX.Element,
}

export interface TabsProps {
    data: TabData[],
    active: number,
    setActive: (i: number) => void,
}

const StyledPageData = styled.div`
    width: 100%;
    min-height: 100dvh;
    display: flex;
    justify-content: space-between;
    gap: ${units(108)};
 
`;

const StyledTabs = styled.div`
    position: fixed;
    top: ${units(64)};
    right: ${units(81)};
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${BreakPoints.XL}) {
        right: ${units(4)};
    }
`;

const StyledTab = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: ${units(4)};
    padding-left: ${units(9)};
    min-width: ${units(105)};

    font-weight: ${props => (props.className === 'active' ? FontWeight.BOLD : FontWeight.REGULAR)};

    background: none;    
    -webkit-apperace: none;
    outline: none;
    cursor: pointer;

    ${typography(10)};  

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0px;
      transform: translate(-50%, -50%);
      width: ${units(4)};
      height: ${units(4)};
      background: ${props => (props.className === 'active' ? colors.gold : '#E7E6E6')};
    }

    &:not(:last-child):after {
      position: absolute;
      content: '';
      bottom: -15px;
      left: 0px;
      transform: translate(-50%, -50%);
      width: 1px;
      height: ${units(8)};
      background: ${colors.grey40};
    }

    @media screen and (max-width: ${BreakPoints.XL}) {
        min-width: ${units(64)}; 
    }
`;

const StyledFlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: ${units(350)};
`;

export const Tabs = ({ data, active, setActive }: TabsProps) => (
    <StyledPageData>
        <StyledFlexDiv>
            {data[active].content}
        </StyledFlexDiv>
        <StyledTabs className="tabs">
            {data.map((item: TabData, i: number) => (
                <StyledTab
                    key={`${item}-${i}`}
                    className={`${i === active ? 'active' : ''}`}
                    onClick={() => setActive(i)}
                >
                    {item.name}
                </StyledTab>
            ))}
        </StyledTabs>
    </StyledPageData>
);
