import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { Modal } from '../../types/Models/Modal/Modal';
import { removeModal } from '../../actions/modal';
import { Title } from '../Title';
import { TypesIcon } from '../../types/TypesIcon';
import { ComponentWrapper } from '../Wrapper/ComponentWrapper';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Button } from '../Button';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { IconBig } from '../Icon/IconBig';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;  
  width: ${units(236)};
  padding: ${units(27)};
  
  max-height: 95vh;

  background-color: ${colors.light};

  border: none;
  border-radius: ${units(0)};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIcon = styled(IconBig)`
  cursor: pointer;
  transition: stroke .4s ease;

  :hover {
    stroke: ${colors.grayscale80};
  }
`;

const StyledComponentWrapper = styled(ComponentWrapper)`
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${units(14)} ${units(0)};
  overflow: auto;
`;

const StyledButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: ${units(8)};
  
  padding: 0;  
`;

const StyledMainButton = styled(Button)`
  font-weight: ${FontWeight.MEDIUM};
  width: 100%;

  & svg {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
      hue-rotate(156deg) brightness(100%) contrast(103%);
  }
`;

const StyledConfirmTitle = styled.p`
  font-weight: ${FontWeight.MEDIUM};
  
  ${typography(25)};
`;

const StyledConfirmDescription = styled.p`
  
  ${typography(10)};
`;

const StyledTitle = styled(Title)`  
  font-weight: ${FontWeight.MEDIUM};

  ${typography(20)};
`;

const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%)
      hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%)
      hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

export const ConfirmModal = ({
    id,
    onClose,
    message,
    onSuccess,
    onSuccessMessage,
    withCloseIcon,
}: Modal) => {
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    const handleOnSuccess = useCallback(() => {
        if (onSuccess) {
            onSuccess();
            handleClose();
        }
    }, []);

    return (
        <StyledRoot>
            <StyledTitleWrapper>
                <StyledTitle>
                    Подтверждение
                </StyledTitle>
                <StyledCloseButton>
                    <StyledIcon
                        onClick={handleClose}
                        type={TypesIcon.CLOSE_BIG}
                        color={colors.grey20}
                        height="24px"
                        width="24px"
                        viewBox="0 0 24 24"
                    />
                </StyledCloseButton>

            </StyledTitleWrapper>
            <StyledComponentWrapper>
                <StyledConfirmTitle>
                    {message?.title}
                </StyledConfirmTitle>
                {message?.description && (
                    <StyledConfirmDescription>
                        {message?.description}
                    </StyledConfirmDescription>
                )}
            </StyledComponentWrapper>
            <StyledButtonsWrapper>
                <StyledMainButton
                    onClick={handleClose}
                    typeButton={TypesButton.SECONDARY}
                    size={SizesButton.M}
                >
                    Отменить
                </StyledMainButton>
                <StyledMainButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.M}
                    onClick={handleOnSuccess}
                >
                    {withCloseIcon && (
                        <StyledIcon
                            type={TypesIcon.TRASH_BIG}
                            height="24px"
                            width="24px"
                            viewBox="0 0 24 24"
                        />
                    )}
                    {onSuccessMessage}
                </StyledMainButton>
            </StyledButtonsWrapper>
        </StyledRoot>
    );
};
