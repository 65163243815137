import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_PRODUCT_ITEM } from '../../actions/goods/thunks/getProductItem';
import { GET_PHOTOS_GOODS } from '../../actions/goods/thunks/getPhotos.ts';
import { GET_PROPERTIES } from '../../actions/goods/thunks/properties.ts';

const selectRoot = ({ goods }: RootState) => goods;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectProductItemLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_PRODUCT_ITEM],
);

export const selectPhotosLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_PHOTOS_GOODS],
);

export const selectPropertiesLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_PROPERTIES],
);

export const selectAllGoods = createSelector(
    selectRoot,
    ({ allGoods }) => allGoods,
);

export const selectProductItem = createSelector(
    selectRoot,
    ({ productItem }) => productItem,
);

export const selectPhotos = createSelector(
    selectRoot,
    ({ photos }) => photos,
);

export const selectProperties = createSelector(
    selectRoot,
    ({ properties }) => properties,
);
