import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as salesApi from '../../../api/sales';
import { GetPromotionsContract } from '../../../types/Endpoints/sales/PromitionsContracts';

export const GET_PROMOTIONS = 'promotions/getPromotionsThink';

export const getPromotionsThink = createAsyncThunk(
    GET_PROMOTIONS,
    createRequest({
        type: GET_PROMOTIONS,
        loader: async (params: GetPromotionsContract['pathParams']) => salesApi.getPromotions(params),
    }),
);
