import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_ORDERS } from '../../actions/orders';
import { GET_ORDER } from '../../actions/orders/thunks/getOrder';

const selectRoot = ({ orders }: RootState) => orders;

const selectRootLoaders = ({ loaders }: RootState) => loaders;

export const selectOrdersLoader = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_ORDERS],
);

export const selectOrders = createSelector(
    selectRoot,
    orders => orders?.orders,
);

export const selectOrdersFilter = createSelector(
    selectRoot,
    orders => orders?.ordersFilter,
);

export const selectOrderLoader = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_ORDER],
);

export const selectOrder = createSelector(
    selectRoot,
    orders => orders?.order,
);

export const selectOrdersStatus = createSelector(
    selectRoot,
    orders => orders?.ordersStatus,
);
