import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { TypesButton } from '../../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../../constants/SizeButton';
import { PageTitle } from '../../../../../components/PageTitle';
import { UploadPhotoWithMiniature } from '../../../../../components/UploadPhotoWithMiniature';
import { createMainBannerBreadcrumbs } from '../../../../../constants/BreadCrumbs/createMainBannerItem';
import { useTypeSelector } from '../../../../../store';
import { selectSiteBodyBlock } from '../../../../../selectors/mainPage';
import {
    BannerItemEdit,
    BannerItemForEdit,
    WebBlockKey,
} from '../../../../../types/Models/MainPage/MainPageModel';
import {
    editBannerItem,
    editBannerItemsWeb,
} from '../../../../../actions/mainPage';
import { Icon } from '../../../../../components/Icon';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { getShotPath } from '../../../../../helpers/images/getPath';
import { SubmitButtons } from '../../../../../components/StyledComponents';
import Switch from '../../../../../components/sw';
import { TypesModal } from '../../../../../types/TypesModalComponent';
import { addModal } from '../../../../../actions/modal';
import { getId } from '../../../../../helpers/generateId';
import { BreadcrumbsMainBanners } from '../../../../../components/BreadcrumbsMainBanners';
import * as S from './styles';
import { CreateProps } from './constants';
import { validationSchema } from './schema';
import { PageStatus } from '../constants';

export const EditMainBanner = ({ changePage, data }: CreateProps) => {
    const dispatch = useDispatch();

    const siteBodyBlock = useTypeSelector(
        selectSiteBodyBlock(WebBlockKey.WEB_MAIN),
    );

    const handleSubmit = (values: BannerItemEdit) => {
        const requestData = {
            id: values.id,
            title: values.title || null,
            buttonText: values.buttonText || null,
            text: values.text || null,
            url: values.url || null,
            order: +values.order,
            status: values.status,
            endDate: values.startDate || null,
            startDate: values.endDate || null,
            fileId: values.fileImage?.id || null,
            mobileFileId: values.mobileFileImage?.id || null,
            linkType: values.linkType,
        };
        const fullData = requestData as BannerItemForEdit;
        console.log('requestData', requestData);

        dispatch(editBannerItem({ key: values.id, newData: fullData }));
    };

    const handleDeleteItem = () => {
        const items = siteBodyBlock?.bannerItems
            ? [...siteBodyBlock.bannerItems].filter(el => el.id !== data.id)
            : [];
        dispatch(editBannerItemsWeb({ key: WebBlockKey.WEB_MAIN, newData: items }));
    };

    const initialValue: BannerItemEdit = {
        buttonText: data?.buttonText || '',
        title: data?.title || '',
        text: data?.text || '',
        url: data?.url || '',
        startDate: data.startDate,
        endDate: data.endDate,
        order: data?.order || 0,
        linkType: data?.linkType,
        status: data?.status,
        fileURL: data?.fileURL || '',
        id: data?.id || 0,
        fileImage: {
            id: data?.fileId || 0,
            path: data?.fileURL ? getShotPath(data.fileURL) : '',
        },
        mobileFileImage: {
            id: data?.mobileFileId || 0,
            path: data?.mobileFileURL ? getShotPath(data.mobileFileURL) : '',
        },
    };

    const form = useFormik<BannerItemEdit>({
        enableReinitialize: true,
        onSubmit: handleSubmit,
        initialValues: initialValue as unknown as BannerItemEdit,
        validationSchema,
    });

    const handleToggle = (value: boolean) => {
        const newStatus = value ? 'ACTIVE' : 'INACTIVE';
        form.setFieldValue('status', newStatus);
    };

    const handleDelete = () => {
        dispatch(
            addModal({
                id: getId()(),
                type: TypesModal.CONFIRM,
                message: { description: 'Вы действительно хотите удалить?' },
                onSuccessMessage: 'Удалить',
                onSuccess: handleDeleteItem,
                withCloseIcon: true,
            }),
        );
    };

    const handleChangePage = () => {
        changePage(PageStatus.MAIN);
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>Body сайта</PageTitle>
                <BreadcrumbsMainBanners
                    breadcrumbs={createMainBannerBreadcrumbs('Редактировать')}
                    onClick={handleChangePage}
                />
                <S.Root>
                    <S.MainWrapper>
                        <S.StyledInput
                            name="title"
                            onChange={form.handleChange}
                            value={form.values.title}
                            placeholder="Заголовок (не более 50 символов)*"
                            typeInput="text"
                            maxLength={255}
                            title="Заголовок (не более 50 символов)*"
                            isTouched={form.touched.title}
                            error={form.errors.title}
                            count={form.submitCount}
                        />
                        <S.StyledInput
                            name="text"
                            onChange={form.handleChange}
                            value={form.values.text}
                            placeholder="Описание (не более 40 символов)"
                            typeInput="text"
                            maxLength={255}
                            title="Описание (не более 40 символов)"
                            isTouched={form.touched.text}
                            error={form.errors.text}
                            count={form.submitCount}
                        />
                        <S.StyledInput
                            name="buttonText"
                            onChange={form.handleChange}
                            value={form.values.buttonText}
                            placeholder="Текст кнопки"
                            typeInput="text"
                            maxLength={255}
                            title="Текст кнопки"
                            isTouched={form.touched.buttonText}
                            error={form.errors.buttonText}
                        />
                        <S.StyledInput
                            name="url"
                            onChange={form.handleChange}
                            value={form.values.url}
                            placeholder="URL*"
                            typeInput="text"
                            maxLength={255}
                            title="URL*"
                            isTouched={form.touched.url}
                            error={form.errors.url}
                            count={form.submitCount}
                        />

                        <S.StyledInput
                            name="id"
                            onChange={form.handleChange}
                            value={form.values.id}
                            placeholder="ID"
                            typeInput="text"
                            maxLength={255}
                            title="ID"
                            isTouched={form.touched.id}
                            error={form.errors.id}
                            disabled
                        />

                        <S.NumberInputWrapper>
                            <S.StyledInput
                                name="order"
                                onChange={form.handleChange}
                                value={form.values.order}
                                placeholder="Сортировка"
                                typeInput="number"
                                title="Сортировка"
                                isTouched={form.touched.order}
                                error={form.errors.order}
                            />
                        </S.NumberInputWrapper>
                    </S.MainWrapper>

                    <S.SwitchWrapper>
                        <S.Subtitle>Статус</S.Subtitle>
                        <Switch
                            name="status"
                            value={form.values.status === 'ACTIVE'}
                            onChange={handleToggle}
                            type="checkbox"
                            title
                        />
                    </S.SwitchWrapper>

                    <S.MainWrapper>
                        <UploadPhotoWithMiniature
                            name="fileImage"
                            title="Изображение для desktop версии сайта"
                            onChange={form.setFieldValue}
                            value={form.values.fileImage}
                            format="9.5:4"
                            maxSize={4}
                        />

                        <UploadPhotoWithMiniature
                            name="mobileFileImage"
                            title="Изображение для мобильной версии сайта"
                            onChange={form.setFieldValue}
                            value={form.values.mobileFileImage}
                            format="2:1"
                            maxSize={3}
                        />
                    </S.MainWrapper>

                    <S.DeleteButton
                        onClick={handleDelete}
                        typeButton={TypesButton.DELETE}
                        size={SizesButton.M}
                    >
                        <Icon
                            type={TypesIcon.TRASH_BIG}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                        />
                        Удалить
                    </S.DeleteButton>
                </S.Root>
            </S.Wrapper>
            <S.SubmitButtons>
                <SubmitButtons
                    reset={() => changePage(PageStatus.MAIN)}
                    submit={form.submitForm}
                />
            </S.SubmitButtons>
        </S.RootWrapper>
    );
};
