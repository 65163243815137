import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as collectionsApi from '../../../api/collections';

export const GET_COLLECTION = 'collections/getCollection';

export const getCatalogCollection = createAsyncThunk(
    GET_COLLECTION,
    createRequest({
        type: GET_COLLECTION,
        loader: async (id: string) => collectionsApi.getCollection(id),
    }),
);
