import {
    useState, useEffect, FC, ChangeEvent,
} from 'react';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import { ShopRegions } from '../../../../helpers/shops/getShopRegion.ts';
import { PageTitle } from '../../../../components/PageTitle';
import { Checkbox } from '../../../../components/Checkbox';
import { SearchBar } from '../../../../components/SearchBar';
import { FilterButton } from '../../../../components/Button/filterButton';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { createGoodsBreadcrumbs } from '../../../../constants/BreadCrumbs/createGoodsBreadcrumbs';
import { routes } from '../../../../constants/RouterPath';
import { selectProductItem, selectProductItemLoader } from '../../../../selectors/goods';
import { selectShops } from '../../../../selectors/shops/index.ts';
import { selectSearchData, selectSearchResults } from '../../../../selectors/search/index.ts';
import { useTypeSelector } from '../../../../store';
import { colors } from '../../../../constants/Colors';
import Switch from '../../../../components/sw';
import { Icon } from '../../../../components/Icon';
import { TypesIcon } from '../../../../types/TypesIcon';
import { TypesButton } from '../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../constants/SizeButton';
import { setSearchData } from '../../../../actions/search/thunks/setSearchData.ts';
import { updateProductGoods } from '../../../../actions/goods';
import { getShopsWithParams } from '../../../../actions/shops/thunks/getShopsWithParams.ts';
import { useDebounce } from '../../../../hooks/useDebounce.ts';
import { IShop } from '../../../../types/Endpoints/goods/ProductContracts.ts';
import { IFormValuesPriceOffers } from './interface.ts';
import { Size } from './classes.ts';
import {
    getNamesFieldsPriceOffers,
    modifyDataForFormPriceOffers,
    modifyDataForUpdateProduct,
} from '../../../../helpers/goods';
import {
    NamesButtonsPriceOffers,
    NamesFieldsFormPriceOffers,
    LabelsFormPriceOffers,
    TitlesSwitchPriceOffers,
    messages,
    VALUE_SALE_ONLINE_ON,
    VALUE_SALE_ONLINE_OFF,
} from './constants.ts';
import {
    StyledRootWrapper,
    StyledWrapper,
    StyledRoot,
    CheckboxWrapper,
    StyledSection,
    StyledAddButtonInline,
    RowInputWrapper,
    StyledInput,
    StyledButton,
    StyledDrawer,
    StyledHeading,
    StyledModalTitle,
    ButtonWrapper,
    StyledSubtitle,
    StyledCloseButton,
    StyledDrawerRoot,
    StyledAddButton,
    StyledCountInfo,
    StyledDeleteButton,
    SubSectionWrapper,
    StyledBoxShops,
    StyledBoxShop,
    StyledLoadShops,
    StyledShopName,
    StyledFilter,
    StyledFilterWrapper,
    StyledFilterWindow,
    StyledCheckBoxWrapper,
    StyledFilterTitle,
    StyledSubmitButtons,
    StyledButtonClear,
} from './styles.ts';

export const PriceOffersContent: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
    const [isOpenFilterRegions, setIsOpenFilterRegions] = useState<boolean>(false);
    const [addedShops, setAddedShops] = useState<IShop[]>([]);
    const [idSelectedWeight, setIdSelectedWeight] = useState<number | null>(null);
    const [isModeAllShops, setIsModeAllShops] = useState<boolean>(false);
    const [selectedRegions, setSelectedRegions] = useState<string[] | null>(null);
    const productItem = useTypeSelector(selectProductItem);
    const allShops = useTypeSelector(selectShops);
    const searchString = useTypeSelector(selectSearchData);
    const searchResult = useTypeSelector(selectSearchResults);
    const [searchValue, setSearchValue] = useDebounce(300, searchString || '');
    const foundShops = (searchValue || selectedRegions) ? searchResult as unknown as IShop[] : null;
    const renderShops = isModeAllShops ? (foundShops || allShops || []) : addedShops;

    const openDrawer = (productShops: IShop[], idWeight: number) => () => {
        setIsOpenDrawer(true);
        setAddedShops(productShops);
        setIdSelectedWeight(idWeight);
    };

    const closeDrawer = () => {
        setIsOpenDrawer(false);
        setTimeout(() => {
            setAddedShops([]);
            setSelectedRegions(null);
            setIsModeAllShops(false);
            setIsOpenFilterRegions(false);
            setSearchValue('');
            setIdSelectedWeight(null);
            dispatch(setSearchData(''));
        }, 200);
    };

    const deleteShop = (id: number) => {
        setAddedShops(addedShops.filter(item => item.id !== id));
    };

    const changeShops = (shop: IShop) => (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setAddedShops([...addedShops, cloneDeep(shop)]);
        } else {
            deleteShop(shop.id);
        }
    };

    const changeRegions = (region: string) => (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedRegions([...(selectedRegions || []), region]);
        } else {
            setSelectedRegions(selectedRegions?.filter(item => item !== region) || []);
        }
    };

    const clearFilterRegions = () => {
        setSelectedRegions(null);
    };

    const toggleModeAllShops = () => {
        setIsModeAllShops(prev => !prev);

        if (isModeAllShops) {
            setSearchValue('');
            setSelectedRegions(null);
            setIsOpenFilterRegions(false);
            dispatch(setSearchData(''));
        }
    };

    const toggleFilterRegions = () => {
        setIsOpenFilterRegions(prev => !prev);
    };

    const handleSubmit = (values: IFormValuesPriceOffers) => {
        if (productItem) {
            const requestData = modifyDataForUpdateProduct(values[NamesFieldsFormPriceOffers.size]);
            // FIXME: не работает backend
            dispatch(updateProductGoods({
                data: { size: requestData },
                id: productItem.id,
            }));
        }
    };

    const form = useFormik<IFormValuesPriceOffers>({
        onSubmit: handleSubmit,
        initialValues: {
            [NamesFieldsFormPriceOffers.size]: modifyDataForFormPriceOffers(productItem?.size),
        },
    });

    const addSection = () => {
        form.setFieldValue(
            NamesFieldsFormPriceOffers.size,
            [...form.values[NamesFieldsFormPriceOffers.size], new Size()],
            false,
        );
    };

    const deleteSection = (idWeight: number) => {
        const values = form.values[NamesFieldsFormPriceOffers.size];
        form.setFieldValue(
            NamesFieldsFormPriceOffers.size,
            values.filter(item => item.weight.id !== idWeight),
            false,
        );
    };

    const updateShopsInProduct = () => {
        const size = cloneDeep(form.values[NamesFieldsFormPriceOffers.size]);
        const weight = size.find(item => item.weight.id === idSelectedWeight)?.weight;

        if (weight) {
            weight.insert[0].insertShop = cloneDeep(addedShops).map(item => ({ shop: item }));
        }

        handleSubmit({ size });
    };

    const changeStatusSaleOnline = (fieldName: string) => (isChecked: boolean) => {
        const isSaleOnline = isChecked ? VALUE_SALE_ONLINE_ON : VALUE_SALE_ONLINE_OFF;
        form.setFieldValue(fieldName, isSaleOnline, false);
    };

    useEffect(() => {
        const values = modifyDataForFormPriceOffers(productItem?.size);
        form.resetForm({ values: { [NamesFieldsFormPriceOffers.size]: values } });
    }, [productItem]);

    useEffect(() => {
        if (searchValue || selectedRegions) {
            dispatch(getShopsWithParams({
                address: searchValue,
                region: selectedRegions || [],
            }));
        }
    }, [searchValue, selectedRegions]);

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>{messages.products}</PageTitle>
                <Breadcrumbs breadcrumbs={createGoodsBreadcrumbs()} />
                <StyledRoot>
                    {form.values[NamesFieldsFormPriceOffers.size]?.map((size, iSize) => (
                        <StyledSection key={size.weight.id}>
                            <CheckboxWrapper>
                                <RowInputWrapper>
                                    <StyledInput
                                        name={getNamesFieldsPriceOffers('size', iSize)}
                                        onChange={form.handleChange}
                                        value={String(size.size)}
                                        placeholder={LabelsFormPriceOffers.size}
                                        typeInput="text"
                                        maxLength={255}
                                        title={LabelsFormPriceOffers.size}
                                    />

                                    <StyledInput
                                        name={getNamesFieldsPriceOffers('weight', iSize, true)}
                                        onChange={form.handleChange}
                                        value={String(size.weight.weight)}
                                        placeholder={LabelsFormPriceOffers.weight}
                                        typeInput="text"
                                        maxLength={255}
                                        title={LabelsFormPriceOffers.weight}
                                    />
                                </RowInputWrapper>

                                <StyledInput
                                    name={getNamesFieldsPriceOffers('insert', iSize, false, true)}
                                    onChange={form.handleChange}
                                    value={size.weight.insert[0].insert}
                                    placeholder={LabelsFormPriceOffers.insert}
                                    typeInput="text"
                                    maxLength={255}
                                    title={LabelsFormPriceOffers.insert}
                                />

                                <RowInputWrapper>
                                    <StyledInput
                                        name={getNamesFieldsPriceOffers('price', iSize, false, true)}
                                        onChange={form.handleChange}
                                        value={String(size.weight.insert[0].price)}
                                        placeholder={LabelsFormPriceOffers.mainPrice}
                                        typeInput="text"
                                        maxLength={255}
                                        title={LabelsFormPriceOffers.mainPrice}
                                    />

                                    <StyledInput
                                        name={getNamesFieldsPriceOffers('discountPrice', iSize, false, true)}
                                        onChange={form.handleChange}
                                        value={String(size.weight.insert[0].discountPrice)}
                                        placeholder={LabelsFormPriceOffers.discountPrice}
                                        typeInput="text"
                                        maxLength={255}
                                        title={LabelsFormPriceOffers.discountPrice}
                                    />

                                    <StyledInput
                                        name={getNamesFieldsPriceOffers('discountPriceDC', iSize, false, true)}
                                        onChange={form.handleChange}
                                        value={String(size.weight.insert[0].discountPriceDC)}
                                        placeholder={LabelsFormPriceOffers.cardClientPrice}
                                        typeInput="text"
                                        maxLength={255}
                                        title={LabelsFormPriceOffers.cardClientPrice}
                                    />
                                </RowInputWrapper>

                                <SubSectionWrapper>
                                    <StyledSubtitle>
                                        {messages.opportunityBuyProduct}
                                    </StyledSubtitle>

                                    <Switch
                                        title
                                        type="checkbox"
                                        name={getNamesFieldsPriceOffers('amountMCode', iSize, false, true)}
                                        value={size.weight.insert[0].amountMCode !== 0}
                                        activeTitle={TitlesSwitchPriceOffers.on}
                                        inactiveTitle={TitlesSwitchPriceOffers.off}
                                        onChange={changeStatusSaleOnline(
                                            getNamesFieldsPriceOffers('amountMCode', iSize, false, true),
                                        )}
                                    />

                                </SubSectionWrapper>

                                <SubSectionWrapper>
                                    <StyledAddButton
                                        typeButton={TypesButton.SECONDARY}
                                        size={SizesButton.M}
                                        onClick={openDrawer(
                                            size.weight.insert[0].insertShop.map(item => cloneDeep(item.shop)),
                                            size.weight.id,
                                        )}
                                    >
                                        <Icon
                                            type={TypesIcon.PLUS_BIG}
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            color={colors.greyDark}
                                        />
                                        {NamesButtonsPriceOffers.addShop}
                                    </StyledAddButton>

                                    <StyledCountInfo>
                                        {messages.addedShops(size.weight.insert[0].insertShop.length)}
                                    </StyledCountInfo>
                                </SubSectionWrapper>

                                <StyledDeleteButton
                                    typeButton={TypesButton.DELETE}
                                    size={SizesButton.M}
                                    onClick={() => deleteSection(size.weight.id)}
                                >
                                    <Icon
                                        type={TypesIcon.TRASH_BIG}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                    {NamesButtonsPriceOffers.deleteSection}
                                </StyledDeleteButton>
                            </CheckboxWrapper>
                        </StyledSection>
                    ))}

                    <StyledAddButtonInline onClick={addSection}>
                        <Icon
                            type={TypesIcon.PLUS_BIG}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            color={colors.greyDark}
                        />
                        {NamesButtonsPriceOffers.addSection}
                    </StyledAddButtonInline>

                    <StyledDrawer
                        anchor="right"
                        open={isOpenDrawer}
                        onClose={closeDrawer}
                    >
                        <StyledDrawerRoot>
                            <StyledHeading>
                                <StyledModalTitle>
                                    {isModeAllShops && (
                                        <StyledCloseButton onClick={toggleModeAllShops}>
                                            <Icon
                                                type={TypesIcon.ARROW_LEFT_BIG}
                                                color={colors.greyDark}
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                            />
                                        </StyledCloseButton>
                                    )}
                                    {isModeAllShops ? messages.loadShopsFromList : messages.addShops}
                                </StyledModalTitle>

                                <StyledCloseButton onClick={closeDrawer}>
                                    <Icon
                                        type={TypesIcon.CLOSE_BIG}
                                        color={colors.grey20}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                </StyledCloseButton>
                            </StyledHeading>

                            {isModeAllShops ? (
                                <StyledFilter>
                                    <SearchBar />
                                    <StyledFilterWrapper>
                                        <FilterButton
                                            typeButton={TypesButton.SECONDARY}
                                            size={SizesButton.S}
                                            onClick={toggleFilterRegions}
                                        />
                                        {isOpenFilterRegions && (
                                            <StyledFilterWindow>
                                                <StyledCheckBoxWrapper>
                                                    <StyledFilterTitle>{messages.region}</StyledFilterTitle>

                                                    {Object.entries(ShopRegions).map(region => (
                                                        <Checkbox
                                                            key={region[0]}
                                                            name={region[1]}
                                                            title={`${region[1]} ${messages.region.toLowerCase()}`}
                                                            checked={selectedRegions?.includes(region[0])}
                                                            onChange={changeRegions(region[0])}
                                                        />
                                                    ))}

                                                    <StyledButtonClear
                                                        typeButton={TypesButton.TEXT}
                                                        onClick={clearFilterRegions}
                                                    >
                                                        {NamesButtonsPriceOffers.clearFilter}
                                                    </StyledButtonClear>
                                                </StyledCheckBoxWrapper>
                                            </StyledFilterWindow>
                                        )}
                                    </StyledFilterWrapper>
                                </StyledFilter>
                            ) : (
                                <StyledLoadShops onClick={toggleModeAllShops}>
                                    {messages.loadShopsFromList}
                                </StyledLoadShops>
                            )}

                            <StyledBoxShops>
                                {renderShops.map(item => (
                                    <StyledBoxShop key={item.id}>
                                        <StyledShopName>{item.address}</StyledShopName>
                                        {isModeAllShops ? (
                                            <Checkbox
                                                name={item.address}
                                                checked={addedShops.some(shop => shop.id === item.id)}
                                                onChange={changeShops(item as IShop)}
                                            />
                                        ) : (
                                            <StyledCloseButton onClick={() => deleteShop(item.id)}>
                                                <Icon
                                                    type={TypesIcon.TRASH_BIG}
                                                    color={colors.greyDark}
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                />
                                            </StyledCloseButton>
                                        )}
                                    </StyledBoxShop>
                                ))}
                            </StyledBoxShops>

                            <ButtonWrapper>
                                <StyledButton
                                    typeButton={TypesButton.SECONDARY}
                                    size={SizesButton.M}
                                    onClick={closeDrawer}
                                >
                                    {NamesButtonsPriceOffers.cancel}
                                </StyledButton>

                                <StyledButton
                                    typeButton={TypesButton.PRIMARY}
                                    size={SizesButton.M}
                                    onClick={updateShopsInProduct}
                                >
                                    {NamesButtonsPriceOffers.save}
                                </StyledButton>
                            </ButtonWrapper>
                        </StyledDrawerRoot>
                    </StyledDrawer>
                </StyledRoot>
            </StyledWrapper>

            {!isOpenDrawer && (
                <StyledSubmitButtons
                    reset={() => history.push(routes.goods)}
                    submit={form.submitForm}
                />
            )}
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectProductItemLoader,
});

export const PriceOffersWithLoader = loader(PriceOffersContent);
