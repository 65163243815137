import { request } from '../../helpers/api/request';

export interface CreateUserModel {
  login: string,
  firstName: string,
  lastName: string,
  role: string,
  password: string,
  status: string,
}

export const addUser = async (userData: CreateUserModel) => {
    const { data } = await request({
        url: 'admin/users',
        method: 'POST',
        data: userData,
    });

    return data;
};
