import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_JEWELRY_EXCHANGES } from '../../actions/jewelryExchanges';

const selectRoot = ({ jewelryExchanges }: RootState) => jewelryExchanges;

const selectRootLoaders = ({ loaders }: RootState) => loaders;

export const selectJewelryExchangesLoader = createSelector(
    selectRootLoaders,
    loaders => loaders[GET_JEWELRY_EXCHANGES],
);

export const selectJewelryExchanges = createSelector(
    selectRoot,
    ({ jewelryExchanges }) => jewelryExchanges,
);
