import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as citiesApi from '../../../api/cities/getCities';
import { City } from '../../../types/Models/City/City';

export const GET_CITIES = 'cities/getCities';

export const getCities = createAsyncThunk<City[]>(
    GET_CITIES,
    createRequest({
        type: GET_CITIES,
        loader: async () => citiesApi.getCities(),
        showFailNotification: false,
    }),
);
