import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';
import { routes } from '../RouterPath';

export const createLandingBreadcrumbs = (brandName = '') : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Посадочные страницы',
        url: routes.search_settings,
    },
    {
        key: '2',
        value: brandName,
        url: '',
    },
]);
