/* eslint-disable no-use-before-define */
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Input } from '../../../components/Input';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { PageTitle } from '../../../components/PageTitle';
import { SubmitButtons } from '../../../components/StyledComponents';
import { EditCityItem, RegionTypes } from '../../../types/Models/City/City';
import { createCity, getCitiesByRegion } from '../../../actions/city/thunks';
import { addModal } from '../../../actions/modal';
import { TypesModal } from '../../../types/TypesModalComponent';
import { TypesButton } from '../../../constants/ButtonTypes';
import { Button } from '../../../components/Button';
import { PageStatus } from '../loaders';
import { handbookBreadcrumbs } from '../../../constants/BreadCrumbs/handbookBreadcrumbs';

interface CreateProps {
  changePage: (key: PageStatus) => void;
  region: RegionTypes,
}

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${units(63)};
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledSubmitButtons = styled.div`
  margin-left: -${units(12)};
`;

const StyledInpurWrapper = styled.div`
  display: flex;
  gap: ${units(8)};
  align-items: flex-end;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledMapButton = styled(StyledButton)`

  height: ${units(28)};
  min-width: ${units(102)};
  max-width: ${units(102)};
  padding-top: ${units(12)};
`;

export const CreateCity = ({ changePage, region }: CreateProps) => {
    const dispatch = useDispatch();

    const handleSubmit = (values: EditCityItem) => {
        const requestData: EditCityItem = {
            name: values.name,
            region,
            latitude: values.latitude,
            longitude: values.longitude,
            isDefault: false,
        };

        const res = dispatch(createCity(requestData)) as any;

        if (!res.error) {
            changePage(PageStatus.MAIN);
            dispatch(getCitiesByRegion(region));
        }
    };

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required('Поле является обязательным'),
        latitude: yup.string().required('Обязательное поле'),
        longitude: yup.string().required('Обязательное поле'),
    });

    const form = useFormik<EditCityItem>({
        enableReinitialize: true,
        onSubmit: handleSubmit,
        initialValues: {} as EditCityItem,
        validationSchema,
    });

    const addressData = {
        name: form.values?.name || '',
        latitude: form.values?.latitude || 0,
        longitude: form.values?.longitude || 0,
    };

    const handleOpenModal = () => {
        dispatch(
            addModal({
                data: addressData,
                id: 1,
                type: TypesModal.CHOOSE_CITY,
                onSuccess: handleSetMapData,
            }),
        );
    };

    const handleSetMapData = async (mapData: any) => {
        await form.setFieldValue('name', mapData.name);
        await form.setFieldValue('latitude', mapData.latitude);
        await form.setFieldValue('longitude', mapData.longitude);
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Справочник</PageTitle>
                <Breadcrumbs
                    breadcrumbs={handbookBreadcrumbs('Добавить')}
                />
                <StyledRoot>
                    <StyledMainWrapper>
                        <StyledInput
                            name="name"
                            onChange={form.handleChange}
                            value={form.values.name}
                            placeholder="Название города, населенного пункта"
                            typeInput="text"
                            maxLength={255}
                            title="Название города, населенного пункта"
                            isTouched={form.touched.name}
                            error={form.errors.name}
                            count={form.submitCount}
                        />

                        <StyledInpurWrapper>
                            <StyledInput
                                name="latitude"
                                onChange={form.handleChange}
                                value={form.values.latitude}
                                placeholder="Долгота*"
                                typeInput="number"
                                maxLength={255}
                                title="Долгота*"
                                isTouched={form.touched.latitude}
                                error={form.errors.latitude}
                                count={form.submitCount}
                            />

                            <StyledInput
                                name="longitude"
                                onChange={form.handleChange}
                                value={form.values.longitude}
                                placeholder="Широта*"
                                typeInput="number"
                                maxLength={255}
                                title="Широта*"
                                isTouched={form.touched.longitude}
                                error={form.errors.longitude}
                                count={form.submitCount}
                            />

                            <StyledMapButton
                                onClick={handleOpenModal}
                                typeButton={TypesButton.TEXT}
                            >
                                Карта
                            </StyledMapButton>
                        </StyledInpurWrapper>

                    </StyledMainWrapper>

                </StyledRoot>
            </StyledWrapper>
            <StyledSubmitButtons>
                <SubmitButtons
                    reset={() => changePage(PageStatus.MAIN)}
                    submit={form.submitForm}
                />
            </StyledSubmitButtons>
        </StyledRootWrapper>
    );
};
