import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as calculatorApi from '../../../api/calculator';
import { CalculatorContract } from '../../../api/calculator/getCalculator';

export const GET_CALCULATOR = 'calculator/getCalculator';

export const getCalculator = createAsyncThunk(
    GET_CALCULATOR,
    createRequest({
        type: GET_CALCULATOR,
        loader: async (data: CalculatorContract['pathParams']) => calculatorApi.getCalculator(data),
        showFailNotification: false,
    }),
);
