import { Fragment } from 'react';
import styled from 'styled-components';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { typography } from '../../helpers/styles/typography';
import { units } from '../../helpers/styles/units';
import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';
import { TypesIcon } from '../../types/TypesIcon';
import { Icon } from '../Icon';
import { colors } from '../../constants/Colors';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { fontName } from '../GlobalStyle';

const RootStyle = styled.nav`
  max-width: ${units(640)};
  width: 100%;

  display: flex;
  align-items: center;

  @media (max-width: ${BreakPoints.XL}) and (min-width: ${BreakPoints.LG}) {
    max-width: ${units(490)};
  }
`;

const BreadcrumbLink = styled.button`
  font-family: ${fontName};
  color: ${colors.grey20};
  letter-spacing: 0.7px;
  font-weight: ${FontWeight.REGULAR};

  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  outline: none;

  ${typography(5)};

  &:hover {
    font-weight: ${FontWeight.MEDIUM};
    cursor: pointer;
  }
`;

const BreadcrumbText = styled.p`
  font-family: ${fontName};
  color: ${colors.grey20};
  letter-spacing: 0.7px;

  text-decoration: none;

  ${typography(5)};
`;

const BreadcrumbsLastItem = styled.div`
  color: ${colors.greyDark};

  ${typography(5)};
`;

const StyledSlashIcon = styled(Icon)`
  margin: 0 ${units(8)};
`;

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbsModel[];
  onClick?: () => void;
}

export const BreadcrumbsMainBanners = ({
    breadcrumbs,
    onClick,
}: BreadcrumbsProps) => (
    <RootStyle>
        {breadcrumbs.map(breadcrumbItem => (breadcrumbs[breadcrumbs.length - 1] !== breadcrumbItem ? (
            <Fragment key={breadcrumbItem.key}>
                {breadcrumbItem?.key !== '1' ? (
                    <BreadcrumbLink onClick={onClick}>
                        {breadcrumbItem.value}
                    </BreadcrumbLink>
                ) : (
                    <BreadcrumbText>{breadcrumbItem.value}</BreadcrumbText>
                )}
                <StyledSlashIcon
                    type={TypesIcon.ARROW_RIGHT}
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                />
            </Fragment>
        ) : (
            <BreadcrumbsLastItem key={breadcrumbItem.key}>
                {breadcrumbItem.value}
            </BreadcrumbsLastItem>
        )))}
    </RootStyle>
);
