import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as usersApi from '../../../api/users';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';
import { EditUserItem } from '../../../api/users/editUser';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const EDIT_USER = 'users/editUser';

const onSuccess = (): Thunk => async dispatch => {
    history.push(routes.users);

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editUser = createAsyncThunk(
    EDIT_USER,
    createRequest({
        type: EDIT_USER,
        loader: async (requestData: EditUserItem) => usersApi.editUser(requestData),
        onSuccess,
    }),
);
