import { useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import { DocumentsContentWithLoader } from './loaders';
import { getDocuments } from '../../actions/docs';

export const Documents: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDocuments());
    }, []);

    return <DocumentsContentWithLoader />;
};
