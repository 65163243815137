import { useDispatch } from 'react-redux';
import { EditFilterCloudWithLoader } from './loaders';

export const EditFilterCloud = () => {
    const dispatch = useDispatch();
    /* const { filterCloudId } = useParams<{filterCloudId: string}>(); */

    /*     useEffect(() => {
        dispatch(getPromotionThink(filterCloudId));
    }, []); */

    return <EditFilterCloudWithLoader />;
};
