import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { PageTitle } from '../../components/PageTitle';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { Button } from '../../components/Button';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

export const PanelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(8)}
`;

export const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
  margin-bottom: ${units(28)};
`;

export const FilterWrapper = styled.div`
  display: flex;
  gap: ${units(12)};  
  margin-left: auto;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  width: ${units(63)};
  font-weight: ${FontWeight.MEDIUM};
`;

export const StyledButtonBox = styled.div`
  display: flex;
  gap: ${units(8)};
  align-items: center;
`;

export const StyledBoxPaginator = styled.div`
  margin-bottom: ${units(30)};
`;
