import { createAsyncThunk } from '@reduxjs/toolkit';
import { BlockItem, WebBlockKey } from '../../../types/Models/MainPage/MainPageModel';
import { createRequest } from '../../../helpers/api/createRequest';
import * as mainPageApi from '../../../api/mainPage';

export const GET_WEB_BANNER = 'mainPage/getWebBanners';

export const getWebBanners = createAsyncThunk<BlockItem<WebBlockKey>[]>(
    GET_WEB_BANNER,
    createRequest({
        type: GET_WEB_BANNER,
        loader: async () => mainPageApi.getWebBanners(),
    }),
);
