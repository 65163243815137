import { createReducer } from '@reduxjs/toolkit';
import { getNotifications, setReadNotifications } from '../../actions/notifications/thunks';
import { MainNotification } from '../../types/Models/Notifications/MainNotification';

interface Notification {
    notification?: MainNotification[]
}

export const notification = createReducer<Notification>({}, builder => {
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => ({
        ...state,
        notification: payload.results,
    }));
    builder.addCase(setReadNotifications.fulfilled, (state, { payload }) => ({
        ...state,
        notification: payload.results,
    }));
});
