import { createReducer } from '@reduxjs/toolkit';
import { getPromotionsThink } from '../../actions/sales/thunks/getPromotions';
import {
    GetAllProductsContract,
    GetPromotionContract,
    GetPromotionsContract,
} from '../../types/Endpoints/sales/PromitionsContracts';
import { getAllProductsThink, getAllProductsThinkLoadMore } from '../../actions/sales/thunks/getAllProducts';
import { getPromotionThink } from '../../actions/sales/thunks/getPromotion';

export interface CollectionsModel {
  promotions?: GetPromotionsContract['requestBody'];
  productsAll: GetAllProductsContract['responseBody'];
  currentPromotion: GetPromotionContract['responseBody'];
}

export const sales = createReducer<CollectionsModel>({
    productsAll: {
        data: [],
        isLast: false,
        page: 1,
        size: 30,
    },
    currentPromotion: {} as GetPromotionContract['responseBody'],
}, builder => {
    builder.addCase(getPromotionsThink.fulfilled, (state, { payload }) => ({
        ...state,
        promotions: payload,
    }));
    builder.addCase(getAllProductsThinkLoadMore.fulfilled, (state, { payload }) => ({
        ...state,
        productsAll: {
            ...payload,
            data: state?.productsAll?.data.concat(payload.data),
        },
    }));
    builder.addCase(getAllProductsThink.fulfilled, (state, { payload }) => ({
        ...state,
        productsAll: payload,
    }));
    builder.addCase(getPromotionThink.fulfilled, (state, { payload }) => ({
        ...state,
        currentPromotion: payload,
    }));
});
