import styled from 'styled-components';

import {
    ChangeEvent,
    ReactNode,
    useCallback,
    useRef,
    useState,
    FormEvent,
    useEffect,
} from 'react';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { applyColor } from './applyColor';
import { InputState } from '../../constants/InputState';
import { useOutSideClick } from '../../hooks/useOutSideClick';
import { Title } from '../Title';
import calendar from '../../styles/images/input/calendar.svg';

export interface InputProps {
    id?: string,
    name?: string,
    title?: string,
    titleElement?: ReactNode,
    placeholder?: string,
    className?: string,
    disabled?: boolean,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: FormEvent<HTMLInputElement>) => void;
    onBlur?: (e: string | ChangeEvent) => void;
    onOpen?: () => void;
    isTouched?: boolean;
    value?: string | number
    typeInput?: string
    error?: string,
    postfix?: ReactNode;
    prefix?: ReactNode;
    onPostfixClick?:React.MouseEventHandler<HTMLDivElement>;
    maxLength?: number;
    readonly?: boolean;
    count?: number;
}

const HoveredTitle = styled(Title)`
  position: absolute;
  top: 0;
  z-index: 10;
  color: transparent;
  font-weight: ${FontWeight.REGULAR};  

  &:hover {
    color: transparent !important;
  }

  ${typography(5)};
`;

const StyledWrapper = styled.div<{withoutTitle?: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: ${({ withoutTitle }) => (withoutTitle ? units(28) : 'auto')};

  @media screen and (max-width: ${BreakPoints.MD}) {
    flex-wrap: wrap;
  }

  &:hover ${HoveredTitle} {
    color: ${colors.grey20};
  }
`;

const StyledTitle = styled(Title)`
  position: absolute;
  top: 0;
  z-index: 10;
  color: ${colors.grey20};
  font-weight: ${FontWeight.REGULAR};

  ${typography(5)};
`;

const StyledPrefix = styled.div<Pick<InputProps, 'error'> & { inputState: InputState | null }>`
  position: absolute;
  top: 50%;
  left: ${units(3)};
  transform: translate(0, -50%);
  z-index: 6;

  display: flex;

  ${applyColor};
`;

const StyledPostfix = styled.div<Pick<InputProps, 'error'> & { inputState: InputState | null }>`
  position: absolute;
  top: ${units(14)};
  right: ${units(0)};
  z-index: 6;

  display: flex;

  filter: ${({ error }) => (error ? 'brightness(0) saturate(100%) invert(40%) sepia(33%) saturate(4413%) hue-rotate(338deg) brightness(99%) contrast(82%);' : '')}; 

  ${applyColor};
`;

const StyledInput = styled.input<{error?: boolean, withPrefix?: ReactNode, type: string}>`
  /* padding: 8px 0;  */
  padding-top: 22px;
  
  height: 56px;

  ${({ withPrefix }) => (withPrefix ? `padding-left: ${units(8)}` : '')};

  background-color: ${colors.light};
  border: 0;
  border-bottom: 1px solid ${({ error }) => (error ? colors.red : colors.grey30)};
  border-radius: ${units(0)};
  outline: 0;
  color: ${({ error }) => (error ? colors.red : colors.grayscale80)};
  
  z-index: 2;

  ${typography(10)};
  font-size: ${({ type }) => (type === 'password' ? '30px' : null)};

  ::placeholder {
    position: absolute;
    top: 18px;        
    letter-spacing: 0px;
    color: ${colors.greyDark};
    ${typography(10)};
  }

  :placeholder-shown ~ .form__label {
    cursor: text;
    top: 30px;
    left: 10px;
  }

  :hover {
    ::placeholder {
      color: transparent;
    }
  }
  
  :focus {
    ::placeholder {
      color: transparent;
    }
  }      
    
  ~ ${HoveredTitle} {
    color: transparent;
  }

  ${StyledWrapper}:hover & ~ ${HoveredTitle} {
    color: ${colors.grey20};
  }

  :focus ~ .form__label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;

    transition: 0.2s;
    color: ${colors.greyDark};
    font-weight: ${FontWeight.REGULAR};

    ${typography(5)}
  }

  :focus {
    border-color: ${colors.grayscale10};
  }

  :required, :invalid {
    box-shadow: none;
  }
  
  ::-webkit-calendar-picker-indicator {      
    z-index: 1;
    width: ${units(12)};
    height: ${units(12)};

    color: transparent;  
    background: url(${calendar});
    background-repeat: no-repeat;
    background-position: center;
    
    cursor: pointer;
  }
`;

const StyledFieldContainer = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: ${BreakPoints.MD}) {
   margin-top: ${units(3)};
  }

  :hover {
    ${StyledInput}::placeholder {
      color: transparent;
    }
  }
`;

const StyledError = styled.div`
  position: absolute;
  top: 58px;
  z-index: 100;

  display: flex;

  font-size: ${units(7)};

  color: ${colors.red};

  min-height: ${units(6)};
  min-width: ${units(6)};
}
`;

export const Input = ({
    id,
    name,
    title,
    titleElement,
    placeholder,
    className,
    onChange,
    onBlur,
    onOpen,
    onClick,
    isTouched,
    count,
    value = '',
    typeInput = 'text',
    error,
    prefix,
    postfix,
    onPostfixClick,
    maxLength,
    disabled,
    ...props
}: InputProps) => {
    const [inputState, setInputState] = useState<InputState | null>(null);
    const hasValue = value ? InputState.IDLE : null;
    const [isError, setError] = useState(isTouched && Boolean(error));
    /* const isError = count ? Boolean(error) : isTouched && Boolean(error); */

    useEffect(() => {
        if (count) {
            setError(Boolean(error));
        }
    }, [count, error]);

    const ref = useRef<HTMLInputElement>(null);
    const refRoot = useRef<HTMLDivElement>(null);

    const handleBlur = useCallback((event: ChangeEvent) => {
        if (value) {
            setInputState(InputState.IDLE);

            if (onBlur) {
                onBlur(event);
            }
        }
    }, [onBlur, value]);

    const handleFocus = useCallback(() => {
        ref.current?.focus();

        setInputState(InputState.ACTIVE);
    }, []);

    const handleClick = useCallback((event: never) => {
        if (onOpen) {
            onOpen();
        }

        if (onClick) {
            onClick(event);
        }

        handleFocus();
    }, [handleFocus, onClick, onOpen]);

    const handleOutsideClick = useCallback((event: Event) => {
        if (!refRoot.current?.contains(event.target as Node) || value) {
            setInputState(null);
        }
    }, [value]);

    useOutSideClick(refRoot, handleOutsideClick);

    const currentTitle = title ? <StyledTitle>{title}</StyledTitle> : titleElement;

    const hoverTitle = title ? <HoveredTitle>{title}</HoveredTitle> : null;

    return (
        <StyledWrapper
            ref={refRoot}
            onClick={handleClick}
            withoutTitle={!!title}
            className="custom-wrapper"
        >
            {!value && value !== 0
                ? hoverTitle
                : currentTitle}
            <StyledFieldContainer>
                {prefix && (
                    <StyledPrefix inputState={hasValue && inputState} error={error}>
                        {prefix}
                    </StyledPrefix>
                )}
                <StyledInput
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    className={className}
                    onChange={onChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={value}
                    type={typeInput}
                    error={isError}
                    withPrefix={prefix}
                    maxLength={maxLength}
                    disabled={disabled}
                    {...props}
                />
                {postfix && (
                    <StyledPostfix inputState={hasValue && inputState} error={error} onClick={onPostfixClick}>
                        {postfix}
                    </StyledPostfix>
                )}
                {isError && (
                    <StyledError>
                        {error}
                    </StyledError>
                )}
            </StyledFieldContainer>

        </StyledWrapper>
    );
};
