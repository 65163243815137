export const sergi = [
    {
        id: 'GoodsName',
        name: 'Категория',
    },
    {
        id: 'name',
        name: 'Название товара',
    },
    {
        id: 'article',
        name: 'Артикул',
    },
    {
        id: 'price',
        name: 'Цена товара без скидок',
    },
    {
        id: 'discountPriceDC',
        name: 'Цена для авторизованного',
    },
    {
        id: 'discountPrice',
        name: 'Цена для неавторизованного',
    },
    {
        id: 'Podkategoriya',
        name: 'Вид украшения',
    },
    {
        id: 'TargetAudience',
        name: 'Для кого',
    },
    {
        id: 'KonstruktsiyaIzdeliya',
        name: 'Конструкция',
    },
    {
        id: 'TsvetVstavki',
        name: 'Цвет вставки',
    },
    {
        id: 'KindOfInsertsName',
        name: 'Вид вставки',
    },
    {
        id: 'MetalName',
        name: 'Вид металла',
    },
    {
        id: 'Probe',
        name: 'Проба',
    },
    {
        id: 'BrandName',
        name: 'Коллекция',
    },

];
