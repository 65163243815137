import { createReducer } from '@reduxjs/toolkit';
import { getActiveUser } from '../../actions/user';
import { User, UsersResponceModel } from '../../types/Models/Users/User';
import { logout } from '../../actions/auth';
import { getSessionFromStorage } from '../../helpers/auth/session';
import { Roles } from '../../constants/Roles';
import { getUsersWithParams } from '../../actions/users';
import { getUser } from '../../actions/users/thunks/getUser';

export interface UsersState {
  activeUser?: User | null;
  subscriptionCode?: string;
  allUsers?: UsersResponceModel | null;
  selectedUser?: User;

}
export const users = createReducer<UsersState>({ }, builder => {
    builder.addCase(getActiveUser.fulfilled, (state, { payload }) => {
        const session = getSessionFromStorage();

        const roles = session?.user.role ? session?.user.role : Roles.USER;

        return ({
            ...state,
            activeUser: {
                ...payload,
                roles,
            },
        });
    });
    builder.addCase(getUsersWithParams.fulfilled, (state, { payload }) => ({
        ...state,
        allUsers: payload,
    }));
    builder.addCase(getUser.fulfilled, (state, { payload }) => ({
        ...state,
        selectedUser: payload,
    }));
    builder.addCase(logout, state => ({
        ...state,
        activeUser: null,
        guestUser: null,
    }));
});
