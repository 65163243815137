import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const mobileBodyBreadcrumbs = () : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Настройка главной',
        url: '',
    },
    {
        key: '2',
        value: 'Body мобильного приложения',
        url: '',
    },
]);
