import { routes } from '../RouterPath';
import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const FooterBreadcrumbs = () : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Настройка главной',
        url: routes.pages,
    },
    {
        key: '2',
        value: 'Footer',
        url: '',
    },
]);
