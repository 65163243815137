import styled from 'styled-components';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';
import { LoginContractBody } from '../../../types/Endpoints/users';
import logo from '../../../styles/images/login/logo.svg';
import { LoginForm } from './LoginForm';
import background from '../../../styles/images/login/login-image.png';

const StyledHero = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;  

  @media (min-width: ${units(400)}) {
    margin: 0 auto;
  }
  
  background: ${colors.light};
`;

const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  
  width: 50%;
  min-width: ${units(299)};
  height: ${units(188)};
  flex-shrink: 1;  
  gap: ${units(12)};
  box-sizing: border-box;
  padding: ${units(0)} ${units(60)};

  background-color: ${colors.white};
  border-radius: ${units(6)};  
`;

const StyledLogo = styled.img` 
  width: ${units(113)};
  height: 80px;
`;

const StyledDialog = styled.div`
  display: flex;
  flex-direction: column;
  
  width: min(486px, 100%);
`;

const StyledDiv = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  
  background-image: url(${background});
  background-size: cover;
  background-position: right;
`;
export type LoginValues = Partial<LoginContractBody>;

export const LoginDialog = () => (
    <StyledHero>
        <StyledLoginContainer>
            <StyledLogo src={logo} />
            <StyledDialog>
                <LoginForm />
            </StyledDialog>
        </StyledLoginContainer>
        <StyledDiv />

    </StyledHero>
);
