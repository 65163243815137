import {
    ChangeEvent, useEffect, useState, FC,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import usePagination from '@mui/material/usePagination';
import { SearchBar } from '../../components/SearchBar';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { routes } from '../../constants/RouterPath';
import { useTypeSelector } from '../../store';
import { Icon } from '../../components/Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { FilterButton } from '../../components/Button/filterButton';
import { Checkbox } from '../../components/Checkbox';
import { selectSearchData } from '../../selectors/search';
import { getGoodsThink } from '../../actions/goods';
import { selectAllGoods } from '../../selectors/goods';
import { createLoadHoc } from '../../helpers/hocs/createLoadHoc';
import { GoodsTable } from './GoodsTable';
import { Pagination } from '../../components/Pagination';
import { selectCategories, selectGetCatalogCategoriesLoader } from '../../selectors/catalog';
import { RadioButton } from '../../components/RadioButton';
import { CatalogCategories } from '../../types/Endpoints/categories/CategoriesContracts';
import { GetAllGoodsContract } from '../../types/Endpoints/goods/GoodsContracts';
import { updateGoodsStatusesThink } from '../../actions/goods/thunks/updateGoodsStatuses';
import { PRICES } from '../../constants/Prices';
import { updatePrice } from '../../actions/prices';
import { PricesKeys } from '../../types/Endpoints/prices/PricesContracts';
import { useDebounce } from '../../hooks/useDebounce';
import { selectPrices } from '../../selectors/prices';
import {
    StyledRoot,
    StyledWrapper,
    StyledPanelWrapper,
    StyledPanelSecondWrapper,
    StyledButtonClear,
    StyledSearchBarWrapper,
    StyledPageTitle,
    StyledFilterWrapper,
    StyledFilterWindow,
    StyledCheckBoxWrapper,
    StyledIdInfo,
    StyledButton,
} from './styles';

const START_PAGE = 1;

export const GoodsContent: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState<{current: number} | null>(null);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isDeactiveOpen, setIsDeactiveOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [selectedGoodsForDisabled, setSelectedGoodsForDisabled] = useState<number[]>([]);
    const [statusInFilter, setStatusInFilter] = useState<null | boolean>(null);
    const searchString = useTypeSelector(selectSearchData);
    const [searchValue] = useDebounce(300, searchString || '');

    const allGoods = useTypeSelector(selectAllGoods);
    const categories = useTypeSelector(selectCategories);
    const prices = useTypeSelector(selectPrices);

    const { items } = usePagination({
        page: page?.current || START_PAGE,
        count: Math.ceil(allGoods?.count || 0 / 50),
        boundaryCount: 0,
        siblingCount: 2,
        onChange: (_event: ChangeEvent<unknown>, current: number) => setPage({ current }),
    });

    const handleOpenFilter = () => {
        setIsFilterOpen(v => !v);
        setIsDeactiveOpen(false);
        setIsSettingsOpen(false);
    };

    const handleOpenDeactivate = () => {
        setIsDeactiveOpen(v => !v);
        setIsFilterOpen(false);
        setIsSettingsOpen(false);
    };

    const handleOpenSettings = () => {
        setIsSettingsOpen(v => !v);
        setIsDeactiveOpen(false);
        setIsFilterOpen(false);
    };

    const onChangeCategories = (item: CatalogCategories) => {
        setSelectedCategories(selectedCategories.includes(item.id)
            ? selectedCategories.filter(oldItem => oldItem !== item.id)
            : [...selectedCategories, item.id]);
    };

    const activateStatuses = (activate: boolean) => {
        const dataForUpdate = selectedGoodsForDisabled.map(item => ({
            isActive: activate,
            id: item,
        }));
        dispatch(updateGoodsStatusesThink(dataForUpdate || []));
    };

    const getIsChecked = (key: string): boolean => Boolean(prices?.find(price => price.key === key)?.isShow);

    const handleChangePrice = (key: keyof typeof PricesKeys): void => {
        const dataPrices = prices ?? [];
        dispatch(updatePrice([
            ...dataPrices.filter(el => el.key !== key),
            {
                key,
                isShow: !getIsChecked(key),
            },
        ]));
    };

    const clearFilter = () => {
        setStatusInFilter(null);

        if (selectedCategories.length) {
            setSelectedCategories([]);
        }
    };

    useEffect(() => {
        setPage({ current: START_PAGE });
    }, [searchValue, selectedCategories, statusInFilter]);

    useEffect(() => {
        if (page) {
            const requestParams: GetAllGoodsContract['pathParams'] = {
                page: String(page.current),
                size: '50',
                name: searchValue,
                categoryIds: selectedCategories,
                isActive: statusInFilter !== null ? statusInFilter : undefined,
            };

            dispatch(getGoodsThink(requestParams));
            setSelectedGoodsForDisabled([]);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [page]);

    return (
        <StyledWrapper>
            <StyledPageTitle>Товары</StyledPageTitle>
            <StyledPanelWrapper>
                <StyledSearchBarWrapper>
                    <SearchBar />
                </StyledSearchBarWrapper>

                <StyledPanelSecondWrapper>
                    <StyledFilterWrapper>
                        <FilterButton
                            typeButton={TypesButton.SECONDARY}
                            iconWithType={TypesIcon.SETTINGS}
                            size={SizesButton.M}
                            onClick={handleOpenSettings}
                        />

                        {isSettingsOpen && Boolean(prices) && (
                            <StyledFilterWindow>
                                <StyledCheckBoxWrapper>
                                    <StyledIdInfo>Для неавторизованных пользователей отображать:</StyledIdInfo>

                                    {PRICES.map(price => (
                                        <Checkbox
                                            key={price.name}
                                            name={price.name}
                                            title={price.title}
                                            checked={getIsChecked(price.key)}
                                            onChange={() => handleChangePrice(price.key)}
                                        />
                                    ))}
                                </StyledCheckBoxWrapper>
                            </StyledFilterWindow>
                        )}
                    </StyledFilterWrapper>

                    <StyledFilterWrapper>
                        <FilterButton
                            typeButton={TypesButton.SECONDARY}
                            iconWithType={TypesIcon.DOUBLE_CHECK}
                            size={SizesButton.M}
                            onClick={handleOpenDeactivate}
                        />

                        {isDeactiveOpen && (
                            <StyledFilterWindow>
                                <StyledCheckBoxWrapper>
                                    <StyledIdInfo>Быстрые действия</StyledIdInfo>

                                    <StyledButton
                                        typeButton={TypesButton.SECONDARY}
                                        size={SizesButton.S}
                                        onClick={() => activateStatuses(true)}
                                        color={colors.green}
                                    >
                                        Активировать товары
                                    </StyledButton>
                                    <StyledButton
                                        typeButton={TypesButton.SECONDARY}
                                        size={SizesButton.S}
                                        onClick={() => activateStatuses(false)}
                                        color={colors.red}
                                    >
                                        Деактивировать товары
                                    </StyledButton>
                                </StyledCheckBoxWrapper>
                            </StyledFilterWindow>
                        )}
                    </StyledFilterWrapper>

                    <StyledFilterWrapper>
                        <FilterButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={handleOpenFilter}
                        />

                        {isFilterOpen && (
                            <StyledFilterWindow>
                                <StyledCheckBoxWrapper>
                                    <StyledIdInfo>Категория</StyledIdInfo>

                                    {categories && categories.map(item => (
                                        <Checkbox
                                            key={item.id}
                                            name={`isShowOnSite${item.externalId}`}
                                            title={item.name}
                                            checked={selectedCategories.includes(item.id)}
                                            onChange={() => onChangeCategories(item)}
                                        />
                                    ))}

                                    <StyledIdInfo>Статус</StyledIdInfo>

                                    <RadioButton
                                        title="Все статусы"
                                        name="allStatuses"
                                        value="allStatuses"
                                        onChange={() => setStatusInFilter(null)}
                                        isChecked={statusInFilter === null}
                                    />

                                    <RadioButton
                                        title="Деактивирован"
                                        name="deactivate"
                                        value="deactivate"
                                        onChange={() => setStatusInFilter(false)}
                                        isChecked={statusInFilter === false}
                                    />

                                    <RadioButton
                                        title="Активирован"
                                        name="active"
                                        value="active"
                                        onChange={() => setStatusInFilter(true)}
                                        isChecked={statusInFilter === true}
                                    />
                                </StyledCheckBoxWrapper>

                                <StyledButtonClear
                                    onClick={clearFilter}
                                    typeButton={TypesButton.TEXT}
                                >
                                    Очистить фильтр
                                </StyledButtonClear>
                            </StyledFilterWindow>
                        )}
                    </StyledFilterWrapper>

                    <StyledButton
                        typeButton={TypesButton.PRIMARY}
                        size={SizesButton.S}
                        onClick={() => history.push(routes.goods_add)}
                    >
                        <Icon
                            type={TypesIcon.PLUS}
                            color={colors.white}
                            width="16px"
                            height="16px"
                            viewBox="0 0 16 16"
                        />
                        Добавить
                    </StyledButton>
                </StyledPanelSecondWrapper>
            </StyledPanelWrapper>

            <StyledRoot>
                {!!allGoods?.data.length && (
                    <GoodsTable
                        goodsList={allGoods.data}
                        selectedGoodsForDisabled={selectedGoodsForDisabled}
                        setSelectedGoodsForDisabled={setSelectedGoodsForDisabled}
                    />
                )}

                {allGoods && !(allGoods.isLast && allGoods.page === START_PAGE) && <Pagination items={items} />}
            </StyledRoot>
        </StyledWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetCatalogCategoriesLoader,
});

export const GoodsContentWithLoader = loader(GoodsContent);
