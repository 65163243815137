/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';
import usePagination from '@mui/material/usePagination';
import { DateObject } from 'react-multi-date-picker';
import { NotesTable } from '../../components/NotesTable';
import { Pagination } from '../../components/Pagination';
import { createLoadHoc } from '../../helpers/hocs/createLoadHoc';
import { selectGetNotesLoader, selectNotes } from '../../selectors/notes';
import { deleteNotes, getNotesWithoutLoader } from '../../actions/notes';
import { NoteCalendar } from '../../components/NotesCalendar';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { addModal } from '../../actions/modal';
import { getId } from '../../helpers/generateId';
import { TypesModal } from '../../types/TypesModalComponent';
import { useTypeSelector } from '../../store';
import { getIsoDate } from '../../helpers/date/getIsoDate';
import * as S from './styles';

const PAGE_SIZE = 50;
const START_PAGE = 1;

const NotesContent = () => {
    const dispatch = useDispatch();
    const notes = useTypeSelector(selectNotes);

    const [page, setPage] = useState<{current: number} | null>(null);
    const [startDate, setStartDate] = useState<DateObject | null>(null);
    const [endDate, setEndDate] = useState<DateObject | null>(null);
    const [startYear, setStartYear] = useState(new Date().getFullYear());
    const [endYear, setEndYear] = useState(new Date().getFullYear());

    const { items } = usePagination({
        page: page?.current || START_PAGE,
        count: Math.ceil((notes?.count || 0) / PAGE_SIZE),
        boundaryCount: 0,
        siblingCount: 2,
        onChange: (_event: ChangeEvent<unknown>, current: number) => setPage({ current }),
    });

    const getNotesWithDate = () => {
        setPage({ current: START_PAGE });
    };

    const getStartDate = () => (startDate
        ? getIsoDate(true, startDate?.toDate())
        : (endDate ? getIsoDate(true, endDate?.toDate()) : undefined));

    const getEndDate = () => (endDate
        ? getIsoDate(false, endDate?.toDate())
        : (startDate ? getIsoDate(false, startDate?.toDate()) : undefined));

    const handleRemove = async () => {
        await dispatch(deleteNotes({
            dateFrom: getStartDate() as string,
            dateTo: getEndDate() as string,
        }));

        setStartDate(null);
        setEndDate(null);
        getNotesWithDate();
    };

    const openDeleteModal = () => {
        dispatch(
            addModal({
                id: getId()(),
                type: TypesModal.CONFIRM,
                onSuccessMessage: 'Удалить',
                onSuccess: handleRemove,
                withCloseIcon: true,
                message: {
                    description: 'Вы действительно хотите удалить?',
                },
            }),
        );
    };

    useEffect(() => {
        if (page) {
            dispatch(getNotesWithoutLoader({
                page: page.current,
                size: PAGE_SIZE,
                dateFrom: getStartDate(),
                dateTo: getEndDate(),
            }));

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [page]);

    return (
        <S.Wrapper>
            <S.StyledPageTitle>Журналирование</S.StyledPageTitle>
            <S.PanelWrapper>
                <S.FilterWrapper>
                    <S.StyledButtonBox>
                        <NoteCalendar
                            values={startDate}
                            year={startYear}
                            setValues={setStartDate}
                            setYear={setStartYear}
                            maxDate={endDate || undefined}
                        />
                        -
                        <NoteCalendar
                            values={endDate}
                            year={endYear}
                            setValues={setEndDate}
                            setYear={setEndYear}
                            minDate={startDate || undefined}
                        />
                    </S.StyledButtonBox>

                    <S.StyledButtonBox>
                        <S.StyledButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={getNotesWithDate}
                        >
                            Показать
                        </S.StyledButton>
                        <S.StyledButton
                            typeButton={TypesButton.DELETE}
                            size={SizesButton.M}
                            onClick={openDeleteModal}
                            disabled={(!startDate && !endDate) || !notes?.data.length}
                        >
                            Удалить
                        </S.StyledButton>
                    </S.StyledButtonBox>
                </S.FilterWrapper>
            </S.PanelWrapper>

            <S.Root>
                <NotesTable notes={notes?.data || []} />

                {notes && !(notes.isLast && notes.page === START_PAGE) && (
                    <S.StyledBoxPaginator>
                        <Pagination items={items} />
                    </S.StyledBoxPaginator>
                )}
            </S.Root>
        </S.Wrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetNotesLoader,
});

export const NotesWithLoader = loader(NotesContent);
