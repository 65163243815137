import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_ORDER_SETTINGS } from '../../actions/orderSettings';

const selectRoot = ({ orderSettings }: RootState) => orderSettings;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetOrderSettingsLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_ORDER_SETTINGS],
);

export const selectOrderSettings = createSelector(
    selectRoot,
    orderSettings => orderSettings?.orderSettings,
);
