import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { useTypeSelector } from '../../../store';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';
import { ButtonRoot } from '../../../components/StyledComponents';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { OrderProductsTable } from '../../../components/Table/OrderProductsTable';
import { orderItemBreadcrumbs } from '../../../constants/BreadCrumbs/orderItemBreadcrumbs';
import { selectOrder, selectOrderLoader } from '../../../selectors/orders';
import { phoneCustomize } from '../../../helpers/phone';
import { getFormatDate } from '../../../helpers/date/getDate';
import { OrderStatus, OrderStatusIcon } from '../../../types/Orders';
import { OrderPlatform, OrderTypes } from '../../../types/Endpoints/orders/OrdersContracts';
import { OrderSettings } from '../../../types/Models/OrderSettings/OrderSettings';
import { selectOrderSettings } from '../../../selectors/orderSettings';
import { generateAddress } from '../../../helpers/generateOrderAddress';
import * as S from './styles';
import { totalOrderCount } from '../../../helpers/totalOrderCount';

const OrderPlatformType = {
    [OrderPlatform.DESKTOP_WEBSITE]: 'Desktop версия сайта',
    [OrderPlatform.MOBILE_APP]: 'Мобильное приложение',
    [OrderPlatform.MOBILE_WEBSITE]: 'Mobile версия сайта',
};

const EditOrderContent = () => {
    const orderData = useTypeSelector(selectOrder);
    const settings = useTypeSelector(selectOrderSettings);

    const orderAddress = orderData?.addressId && orderData?.userAddress
        ? generateAddress(orderData.userAddress)
        : orderData?.address;

    const isStatusValid = Object.values(OrderStatus).includes(orderData?.status as OrderStatus);

    const method = settings?.find((payment: OrderSettings) => payment.type === orderData?.delivery);

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <S.StyledPageTitle>Список заказов</S.StyledPageTitle>
                <Breadcrumbs breadcrumbs={orderItemBreadcrumbs()} />
                <S.Root>
                    <S.InfoWrapper>
                        {orderData?.status && (
                            <S.InfoItemsWrapper>
                                <div>
                                    <Icon
                                        type={OrderStatusIcon[isStatusValid ? (orderData?.status as OrderStatus) : 'Доставляется']}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                </div>
                                <S.InfoItem>
                                    <S.InfoTitle>Статус</S.InfoTitle>
                                    <S.InfoDescription>{orderData?.status}</S.InfoDescription>
                                </S.InfoItem>
                            </S.InfoItemsWrapper>
                        )}
                        {orderData?.client && (
                            <S.InfoItemsWrapper>
                                <div>
                                    <Icon
                                        type={TypesIcon.ORDER_USER}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                </div>
                                <S.InfoItem>
                                    <S.InfoTitle>Покупатель:</S.InfoTitle>
                                    <S.InfoDescription>{orderData?.client}</S.InfoDescription>
                                </S.InfoItem>
                            </S.InfoItemsWrapper>
                        )}
                        {orderData?.clientPhone && (
                            <S.InfoItemsWrapper>
                                <div>
                                    <Icon
                                        type={TypesIcon.ORDER_PHONE}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                </div>
                                <S.InfoItem>
                                    <S.InfoTitle>Номер телефона:</S.InfoTitle>
                                    <S.InfoDescription>{phoneCustomize(orderData.clientPhone)}</S.InfoDescription>
                                </S.InfoItem>
                            </S.InfoItemsWrapper>
                        )}
                        {orderData?.createdAt && (
                            <S.InfoItemsWrapper>
                                <div>
                                    <Icon
                                        type={TypesIcon.ORDER_TIME}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                </div>
                                <S.InfoItem>
                                    <S.InfoTitle>Дата и время:</S.InfoTitle>
                                    <S.InfoDescription>{getFormatDate(orderData.createdAt)}</S.InfoDescription>
                                </S.InfoItem>
                            </S.InfoItemsWrapper>
                        )}
                        {orderData?.delivery && (
                            <S.InfoItemsWrapper>
                                <div>
                                    <Icon
                                        type={TypesIcon.ORDER_DELIVERY}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                </div>

                                <S.InfoItem>
                                    <S.InfoTitle>Способ получение:</S.InfoTitle>
                                    <S.InfoDescription>{method?.name}</S.InfoDescription>
                                </S.InfoItem>

                            </S.InfoItemsWrapper>
                        )}
                        {orderAddress && (
                            <S.InfoItemsWrapper>
                                <div>
                                    <Icon
                                        type={TypesIcon.ORDER_MARKER}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                </div>

                                <S.InfoItem>
                                    <S.InfoTitle>Адрес доставки:</S.InfoTitle>
                                    <S.InfoDescription>{orderAddress}</S.InfoDescription>
                                </S.InfoItem>

                            </S.InfoItemsWrapper>
                        )}
                        {orderData?.comment && (
                            <S.InfoItemsWrapper>
                                <div>
                                    <Icon
                                        type={TypesIcon.ORDER_COMMENTES}
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                    />
                                </div>
                                <S.InfoItem>
                                    <S.InfoTitle>Комментарий к заказу:</S.InfoTitle>
                                    <S.InfoDescription>{orderData?.comment}</S.InfoDescription>
                                </S.InfoItem>
                            </S.InfoItemsWrapper>
                        )}
                        <S.InfoItemsWrapper>
                            <div>
                                <Icon
                                    type={TypesIcon[orderData?.platform as OrderPlatform] || TypesIcon.ORDER_DEVICE}
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                />
                            </div>
                            <S.InfoItem>
                                <S.InfoTitle>Устройство:</S.InfoTitle>
                                <S.InfoDescription>{orderData && orderData?.platform ? OrderPlatformType[orderData?.platform as OrderPlatform] : 'Нет данных'}</S.InfoDescription>
                            </S.InfoItem>
                        </S.InfoItemsWrapper>

                    </S.InfoWrapper>
                    <S.InfoWrapper>
                        <OrderProductsTable goods={orderData?.goods || []} isCertificate={orderData?.type === OrderTypes.CERTIFICATE} />
                        <S.SummaryWrapper>
                            {!!orderData?.goods?.length && (
                                <S.SummaryItem>
                                    <S.InfoTitle>Количество товаров:</S.InfoTitle>
                                    <S.Separator />
                                    <S.Price>{totalOrderCount(orderData.goods)}</S.Price>
                                </S.SummaryItem>
                            )}

                            {!!orderData?.promocode && (
                                <S.SummaryItem>
                                    <S.InfoTitle>Применен промокод:</S.InfoTitle>
                                    <S.Separator />
                                    <S.Price>{orderData.promocode}</S.Price>
                                </S.SummaryItem>
                            )}

                            {!!orderData?.totalSum && (
                                <S.SummaryItem>
                                    <S.InfoTitle>Сумма заказа:</S.InfoTitle>
                                    <S.Separator />
                                    <S.Price>{orderData.totalSum} р</S.Price>
                                </S.SummaryItem>
                            )}

                        </S.SummaryWrapper>
                    </S.InfoWrapper>

                </S.Root>
            </S.Wrapper>
            <ButtonRoot />
        </S.RootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectOrderLoader,
});

export const EditOrderWithLoader = loader(EditOrderContent);
