import { request } from '../../helpers/api/request';
import { GetOrderSettingsContract } from '../../types/Endpoints/orderSettings/GetOrderSettingsContract';

export const getOrderSettings = async () => {
    const { data } = await request<GetOrderSettingsContract>({
        url: 'admin/delivery-methods/',
    });

    return data;
};
