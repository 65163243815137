import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { Modal } from '../../types/Models/Modal/Modal';
import { removeModal } from '../../actions/modal';
import { Title } from '../Title';
import { TypesIcon } from '../../types/TypesIcon';
import { Divider } from '../Divider';
import { ComponentWrapper } from '../Wrapper/ComponentWrapper';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Button } from '../Button';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { IconBig } from '../Icon/IconBig';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${units(162)};
  max-width: 75vw;
  
  max-height: 65vh;

  background-color: ${colors.white};

  border: 1px solid ${colors.grayscale05};
  border-radius: ${units(4)};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${units(6)};
`;

const StyledIcon = styled(IconBig)`
  cursor: pointer;
  transition: stroke .4s ease;

  :hover {
    stroke: ${colors.grayscale80};
  }
`;

const StyledComponentWrapper = styled(ComponentWrapper)`
  align-items: flex-start;
  
  padding: ${units(6)};
  
  overflow: auto;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  padding: ${units(4)} ${units(6)};

  box-shadow: 0 4px 64px rgba(0, 0, 0, 0.08);
`;

const StyledMainButton = styled(Button)`
  :not(:last-child) {
    margin-right: ${units(4)};
  }
`;

const StyledConfirmTitle = styled.p`
  font-weight: ${FontWeight.MEDIUM};
  
  ${typography(15)};
`;

const StyledConfirmDescription = styled.p`
  position: relative;
  margin-top: ${units(3)};
  
  ${typography(10)};
  
  img {
    position: relative !important;
    width: 100%;
    height: 100%;
  }
`;

export const NotificationModal = ({
    id,
    onClose,
    message,
    onSuccess,
    onSuccessMessage,
}: Modal) => {
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    const handleOnSuccess = useCallback(() => {
        if (onSuccess) {
            onSuccess();
            handleClose();
        }
    }, []);

    return (
        <StyledRoot>
            <StyledTitleWrapper>
                <Title>
                    Оповещение
                </Title>
                <StyledIcon
                    onClick={handleClose}
                    type={TypesIcon.CLOSE_BIG}
                    color={colors.grayscale40}
                />
            </StyledTitleWrapper>
            <Divider />
            <StyledComponentWrapper>
                <StyledConfirmTitle>
                    {message?.title}
                </StyledConfirmTitle>
                {message?.description && (
                    <StyledConfirmDescription>
                        {parse(`${message?.description}`, {})}
                    </StyledConfirmDescription>
                )}
            </StyledComponentWrapper>
            <StyledButtonsWrapper>
                <StyledMainButton
                    onClick={handleClose}
                    typeButton={TypesButton.GRAY}
                    size={SizesButton.M}
                >
                    Закрыть
                </StyledMainButton>
                {onSuccess && (
                    <StyledMainButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOnSuccess}
                    >
                        {onSuccessMessage}
                    </StyledMainButton>
                )}
            </StyledButtonsWrapper>
        </StyledRoot>
    );
};
