import styled from 'styled-components';
import { Radio } from '@mui/material';
import { units } from '../../helpers/styles/units';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';

export interface RadioButtonProps {
  color?: 'default' | 'outline';
  name: string;
  title: string;
  subtitle?: string;
  value?: string;
  isChecked?: boolean;
  onChange?: (field: string, value?: string, shouldValidate?: boolean) => void;
}

const StyledLabel = styled.label<Pick<RadioButtonProps, 'isChecked' | 'color'>>`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  width: fit-content;

  color: ${colors.grayscale80};

  overflow: hidden;

  cursor: pointer;

  & .MuiRadio-root {
    padding: 0;
    margin-right: ${units(4)};
    min-width: ${units(10)};
    
    color: ${colors.gold};    
  }

  & .MuiSvgIcon-root {    
    stroke: none;
    
    width: ${units(11)};
  }

  & .MuiRadio-root.Mui-checked {
    color: ${colors.gold};
    min-width: ${units(10)};  

    & .MuiSvgIcon-root {
      stroke:  ${colors.gold};
      stroke-width: ${units(2)};
      width: ${units(9)};
    }  
  } 
  
  & .Mui-checked .MuiSvgIcon-root:last-child {
    fill: transparent;
    stroke: transparent;
  }
`;

const StyledLabelName = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100%;

  pointer-events: none;
`;

const StyledSubtitle = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  opacity: 0.5;
`;

const StyledTitle = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  max-width: ${units(148)};
  
  ${typography(10)};
`;

export const RadioButton = ({
    value,
    title,
    subtitle,
    isChecked,
    onChange,
    name,
    color = 'outline',
}: RadioButtonProps) => {
    const handleChange = () => {
        if (onChange) {
            onChange(name, value, false);
        }
    };

    return (
        <StyledLabel onClick={handleChange} isChecked={isChecked} color={color}>
            <Radio
                name={name}
                value={value}
                checked={isChecked}
            />
            <StyledLabelName>
                <StyledTitle>{title}</StyledTitle>
                {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
            </StyledLabelName>
        </StyledLabel>
    );
};
