import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiltersContent } from './loaders';
import { getFilters } from '../../../actions/filters';
import { getCatalogCategories } from '../../../actions/category/thunks';
import { selectFilters } from '../../../selectors/filters';
import { useTypeSelector } from '../../../store';

export const Filters = () => {
    const dispatch = useDispatch();
    const filters = useTypeSelector(selectFilters);

    useEffect(() => {
        dispatch(getCatalogCategories());
        if (!filters) {
            dispatch(getFilters({
                page: 1,
                size: 50,
            }));
        }
    }, []);

    return <FiltersContent />;
};
