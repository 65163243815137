import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as shopsApi from '../../../api/shops';
import { ShopsContract } from '../../../api/shops/getShops';

export const GET_SHOPS_WITH_PARAMS = 'shops/getShopsWithParams';

export const getShopsWithParams = createAsyncThunk(
    GET_SHOPS_WITH_PARAMS,
    createRequest({
        type: GET_SHOPS_WITH_PARAMS,
        loader: async (data: ShopsContract['pathParams']) => shopsApi.getShopsWithParams(data),
        showFailNotification: false,
    }),
);
