import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useDispatch } from 'react-redux';
import { SearchBar } from '../../components/SearchBar';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { routes } from '../../constants/RouterPath';
import { useTypeSelector } from '../../store';
import { Icon } from '../../components/Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { FilterButton } from '../../components/Button/filterButton';
import { CalendarButton } from '../../components/Button/calendarButtom';
import { StatusSales } from '../../components/SalesTable';
import { selectSearchData } from '../../selectors/search';
import { selectBlogs } from '../../selectors/blog';
import { BlogTable } from '../../components/BlogTable';
import { getBlogsThunk } from '../../actions/blog/thunks/getBlogs';
import { RadioButton } from '../../components/RadioButton';
import { CalendarContent } from '../../components/Calendar';
import { getFormattedDateWithTime } from '../../helpers/date/getDate';
import * as S from './styles';

export const BlogsContent = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const blogs = useTypeSelector(selectBlogs);
    const searchString = useTypeSelector(selectSearchData);

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState<string | undefined>();

    const [dateValue, setDateValue] = useState<DateObject[]>([]);

    const [values, setValues] = useState<DateObject>(new DateObject());

    const [rangeValues, setRangeValues] = useState<DateObject[]>();

    const [year, setYear] = useState(new Date().getFullYear());

    const handleOpenFilter = () => {
        setIsFilterOpen(v => !v);
    };

    const handleOpenPicker = () => {
        setIsCalendarOpen(v => !v);
    };

    const handleSetDateValues = (data: DateObject[]) => {
        setIsCalendarOpen(false);
        setDateValue(data);
    };

    useEffect(() => {
        dispatch(getBlogsThunk({
            fromDate: getFormattedDateWithTime(dateValue && dateValue[0], { hours: 0, minutes: 0 }),
            toDate: getFormattedDateWithTime(dateValue && dateValue[1], { hours: 23, minutes: 59 }),
            status: statusFilter,
            search: searchString,
        }));
    }, [dateValue, statusFilter, searchString]);

    return (
        <S.Wrapper>
            <S.StyledPageTitle>Блог</S.StyledPageTitle>
            <S.PanelWrapper>
                <S.SearchBarWrapper>
                    <SearchBar />
                </S.SearchBarWrapper>
                <S.CalendarWrapper>
                    <CalendarButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOpenPicker}
                    />
                    {isCalendarOpen && (
                        <CalendarContent
                            setDate={handleSetDateValues}
                            values={values}
                            rangeValues={rangeValues}
                            year={year}
                            setValues={setValues}
                            setRangeValues={setRangeValues}
                            setYear={setYear}
                        />
                    )}
                </S.CalendarWrapper>

                <S.FilterWrapper>
                    <FilterButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOpenFilter}
                    />
                    {isFilterOpen && (
                        <S.FilterWindow>
                            <S.CheckBoxWrapper>
                                <S.IdInfo>Статус</S.IdInfo>
                                <RadioButton
                                    name="isShowOnSite"
                                    title="Все"
                                    isChecked={statusFilter === undefined}
                                    onChange={() => setStatusFilter(undefined)}
                                />
                                <RadioButton
                                    name="isShowOnSite"
                                    title="Запланирована"
                                    isChecked={statusFilter === StatusSales.PLANING}
                                    onChange={() => setStatusFilter(StatusSales.PLANING)}
                                />
                                <RadioButton
                                    name="isShowOnSite"
                                    title="Опубликована"
                                    isChecked={statusFilter === StatusSales.ACTIVE}
                                    onChange={() => setStatusFilter(StatusSales.ACTIVE)}
                                />
                            </S.CheckBoxWrapper>
                        </S.FilterWindow>
                    )}
                </S.FilterWrapper>
                <S.StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => history.push(routes.create_blog)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </S.StyledButton>
            </S.PanelWrapper>
            <S.Root>
                {blogs && <BlogTable blogs={blogs} />}
            </S.Root>
        </S.Wrapper>
    );
};
