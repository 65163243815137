import { createAsyncThunk } from '@reduxjs/toolkit';
import * as shopsApi from '../../../api/shops';
import { createRequest } from '../../../helpers/api/createRequest';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Thunk } from '../../../helpers/redux/Thunk';
import { CreateShopModel } from '../../../api/shops/addShop';
import { getShops } from './getShops';
import { TypesIcon } from '../../../types/TypesIcon';

export const CREATE_SHOP = 'shops/createShop';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Ваш магазин создан',
        },
        icon: TypesIcon.SNACK_STORE,
    }));
    await dispatch(getShops());
};

const onFail = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.ERROR,
        message: {
            title: 'Ошибка, попробуйте позже',
        },
        icon: TypesIcon.SNACK_ERROR,
    }));
};

export const createShop = createAsyncThunk(
    CREATE_SHOP,
    createRequest({
        type: CREATE_SHOP,
        loader: async (data: CreateShopModel) => shopsApi.addShop(data),
        onSuccess,
        showFailNotification: false,
        onFail,
    }),
);
