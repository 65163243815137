import dayjs, { ManipulateType } from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import { DateObject } from 'react-multi-date-picker';
import { Month } from '../../constants/Date/Month';
import { capitalizeFirstLetter } from '../capitalizeFirstLetter';
import 'dayjs/locale/ru';
import { DayName } from '../../constants/Date/DayName';
import { DateFilter } from '../../constants/Date/Periods';

dayjs.extend(localeData);
dayjs.extend(arraySupport);
dayjs.extend(utc);

const currentYear = dayjs().year();
const currentMonth = dayjs().month();
const currentDay = dayjs().date();

const getNextMonth = () => {
    if (currentMonth === Month.DECEMBER) {
        return new Date(currentYear + 1, Month.JANUARY, 1);
    }

    return new Date(currentYear, currentMonth + 1, 30);
};

const getFormatDate = (date: string) => dayjs(date).locale('ru').format('DD.MM.YYYY, HH:mm');

const getNextDay = () => new Date(currentYear, currentMonth, currentDay + 1);

const getDateSinceStartMonth = () => dayjs([currentYear, currentMonth]).local().format('YYYY-MM-DD');

/* const getCurrentDate = () => dayjs([currentYear, currentMonth, currentDay]).local().format('YYYY-MM-DD'); */

const getMonthName = (month: number) => (
    capitalizeFirstLetter(dayjs().month(month).locale('ru').format('MMMM'))
);

const getMonthsList = () => dayjs.months().map((yearMonth, index) => ({
    id: `${index.toString()} ${currentYear}`,
    name: `${getMonthName(index)} ${currentYear}`,
}));

const getSortedDays = (year: number, month: number) => {
    const dayIndex = new Date(year, month + 1, 0).getDate();
    const firstHalf = DayName.slice(dayIndex);

    return [...firstHalf, ...DayName.slice(0, dayIndex)];
};

const getDateMinusOneDay = (filter: ManipulateType = DateFilter.DAY) => {
    if (filter === DateFilter.QUARTER as ManipulateType) {
        return dayjs().subtract(3, DateFilter.MONTH).format('YYYY-MM-DD HH:mm:ss');
    }
    return dayjs().subtract(1, filter).format('YYYY-MM-DD HH:mm:ss');
};

const getCurrentDate = () => dayjs().local().format('YYYY-MM-DD HH:mm:ss');

const getFormattedDateWithTime = (date: DateObject, time: { hours: number; minutes: number }) => {
    if (!date) return;
    const formattedDate = new DateObject(date).toDate();
    formattedDate.setHours(time.hours, time.minutes, 0, 0);
    return formattedDate.toString();
};

export {
    getFormatDate,
    getNextMonth,
    getNextDay,
    getDateSinceStartMonth,
    getCurrentDate,
    getMonthsList,
    getSortedDays,
    getDateMinusOneDay,
    getFormattedDateWithTime,
};
