import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    ChangeEvent, useEffect, useRef, useState,
} from 'react';
import usePagination from '@mui/material/usePagination';
import { SearchBar } from '../../components/SearchBar';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { routes } from '../../constants/RouterPath';
import { useTypeSelector } from '../../store';
import { Icon } from '../../components/Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { selectAllUsers } from '../../selectors/users';
import { getUsersWithParams } from '../../actions/users/thunks';
import { UsersTable } from '../../components/UsersTable';
import { FilterButton } from '../../components/Button/filterButton';
import { RadioButton } from '../../components/RadioButton';
import { StatusTypes } from '../../types/Models/Users/UsersStatus';
import { Pagination } from '../../components/Pagination';
import * as S from './styles';
import { PAGE_SIZE, Roles, START_PAGE } from './constants';
import { PageTitles } from '../../constants/PageTitles';

export const UsersContent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState<{current: number} | null>(null);
    const users = useTypeSelector(selectAllUsers);
    const filterWindowRef = useRef<HTMLDivElement | null>(null);

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState<string | undefined>();
    const [rolesFilter, setRolesFilter] = useState<string | undefined>();

    const { items } = usePagination({
        page: page?.current || START_PAGE,
        count: Math.ceil((users?.count || 0) / PAGE_SIZE),
        boundaryCount: 0,
        siblingCount: 2,
        onChange: (_event: ChangeEvent<unknown>, current: number) => setPage({ current }),
    });

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (filterWindowRef.current && !filterWindowRef.current.contains(event.target as Node)) {
                setIsFilterOpen(false);
            }
        };

        if (isFilterOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isFilterOpen]);

    const handleOpenFilter = () => {
        setIsFilterOpen(v => !v);
    };

    const resetFilters = () => {
        setStatusFilter(undefined);
        setRolesFilter(undefined);
    };

    useEffect(() => {
        setPage({ current: START_PAGE });
    }, [rolesFilter, statusFilter]);

    useEffect(() => {
        if (page) {
            dispatch(getUsersWithParams({
                page: page.current, size: PAGE_SIZE, role: rolesFilter, status: statusFilter,
            }));
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [page]);

    const usersData = users?.data;

    return (
        <S.Wrapper>
            <S.StyledPageTitle>{PageTitles.USERS}</S.StyledPageTitle>
            <S.PanelWrapper>
                <S.SearchBarWrapper>
                    <SearchBar />
                </S.SearchBarWrapper>
                <S.FilterWrapper>
                    <FilterButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOpenFilter}
                    />
                    {isFilterOpen && (
                        <S.FilterWindow ref={filterWindowRef}>
                            <S.CheckBoxWrapper>
                                <S.IdInfo>Роль</S.IdInfo>
                                <RadioButton
                                    name="roles"
                                    title="Все роли"
                                    isChecked={rolesFilter === undefined}
                                    onChange={() => setRolesFilter(undefined)}
                                />
                                <RadioButton
                                    name="roles"
                                    title="Администратор"
                                    isChecked={rolesFilter === Roles.ADMIN}
                                    onChange={() => setRolesFilter(Roles.ADMIN)}
                                />
                                <RadioButton
                                    name="roles"
                                    title="Маркетолог"
                                    isChecked={rolesFilter === Roles.MARKETING}
                                    onChange={() => setRolesFilter(Roles.MARKETING)}
                                />
                                <RadioButton
                                    name="roles"
                                    title="Отдел продаж"
                                    isChecked={rolesFilter === Roles.ESHOP}
                                    onChange={() => setRolesFilter(Roles.ESHOP)}
                                />
                            </S.CheckBoxWrapper>
                            <S.CheckBoxWrapper>
                                <S.IdInfo>Статус</S.IdInfo>
                                <RadioButton
                                    name="status"
                                    title="Все статусы"
                                    isChecked={statusFilter === undefined}
                                    onChange={() => setStatusFilter(undefined)}
                                />
                                <RadioButton
                                    name="status"
                                    title="Активный"
                                    isChecked={statusFilter === StatusTypes.ACTIVE}
                                    onChange={() => setStatusFilter(StatusTypes.ACTIVE)}
                                />
                                <RadioButton
                                    name="status"
                                    title="Отключен"
                                    isChecked={statusFilter === StatusTypes.DISABLED}
                                    onChange={() => setStatusFilter(StatusTypes.DISABLED)}
                                />
                            </S.CheckBoxWrapper>
                            <S.ButtonClear
                                onClick={resetFilters}
                                typeButton={TypesButton.TEXT}
                            >
                                Очистить фильтр
                            </S.ButtonClear>

                        </S.FilterWindow>
                    )}
                </S.FilterWrapper>
                <S.StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => history.push(routes.create_user)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </S.StyledButton>
            </S.PanelWrapper>
            <S.Root>
                <UsersTable users={usersData} />
                {users && !(users.isLast && users.page === START_PAGE) && <Pagination items={items} />}
            </S.Root>
        </S.Wrapper>
    );
};
