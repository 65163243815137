import { createAsyncThunk } from '@reduxjs/toolkit';
import { BlockItem, MobileBlockKey } from '../../../types/Models/MainPage/MainPageModel';
import { createRequest } from '../../../helpers/api/createRequest';
import * as mainPageApi from '../../../api/mainPage';

export const GET_MOBILE_BANNER = 'mainPage/getMobileBanners';

export const getMobileBanners = createAsyncThunk<BlockItem<MobileBlockKey>[]>(
    GET_MOBILE_BANNER,
    createRequest({
        type: GET_MOBILE_BANNER,
        loader: async () => mainPageApi.getMobileBanners(),
    }),
);
