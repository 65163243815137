import { createAsyncThunk } from '@reduxjs/toolkit';
import * as pagesApi from '../../../api/dinamicPages';
import { createRequest } from '../../../helpers/api/createRequest';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';

import { PagesModel } from '../../../api/dinamicPages/updatePage';
import { getPage } from './getPage';
import { DynamicPagesKeys } from '../../../types/Models/DinamicPages';
import { TypesIcon } from '../../../types/TypesIcon';

export const UPDATE_PAGE = 'pages/updateFooterPage';

const onSuccess = (): Thunk => async dispatch => {
    await dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));

    await dispatch(getPage(DynamicPagesKeys.FOOTER_CONTACTS));
};

const onFail = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.ERROR,
        message: {
            title: 'Ошибка',
        },
        icon: TypesIcon.SNACK_ERROR,
    }));
};

export const updateFooterPage = createAsyncThunk(
    UPDATE_PAGE,
    createRequest({
        type: UPDATE_PAGE,
        loader: async (data: PagesModel) => pagesApi.updatePage(data),
        showFailNotification: false,
        onSuccess,
        onFail,
    }),
);
