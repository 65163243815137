/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-shadow */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useHistory } from 'react-router-dom';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { units } from '../../helpers/styles/units';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { routes } from '../../constants/RouterPath';
import { CopyToClipboard } from '../CopyToClipboard';
import { config } from '../../config';
import { GetBlogContract } from '../../types/Endpoints/blog/BlogCRUDContracts';
import { EmptyRow } from '../EmptyRow';

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
            maxWidth: '224px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
    },
};

const StyledClipboardWrapper = styled.div`
  position: relative;
  top: ${units(3)};
  position: relative;
  display: inline-block;
  height: ${units(12)};
  width: ${units(12)};
  margin-right: ${units(5)};
`;

const StyledCell = styled(TableCell)`
  .status {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};
  }

  & .active {
    background-color: rgba(87, 198, 118, 0.05);

    & div {
      background-color: #6db180;
    }
  }

  & .end {
    background-color: rgba(231, 72, 72, 0.05);

    & div {
      background-color: #e74848;
    }
  }

  & .plan {
    background-color: rgba(215, 180, 106, 0.05);

    & div {
      background-color: #d7b46a;
    }
  }
`;

const StyledStatusInfo = styled.div`
  min-width: ${units(4)};
  min-height: ${units(4)};
`;

const StyledDiv = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
  width: ${units(42)};

  gap: ${units(4)};
`;

const StyledEditButton = styled(EditButton)`
  margin: 0 auto;
`;

export const StatusActiveComponent = () => (
    <StyledDiv className="status active">
        <StyledStatusInfo />
        <span>Опубликована</span>
    </StyledDiv>
);

export const StatusBlockedComponent = () => (
    <StyledDiv className="status plan">
        <StyledStatusInfo />
        <span>Запланирована</span>
    </StyledDiv>
);

export enum StatusBlog {
  ACTIVE = 'Published',
  PLANING = 'Planned',
}

const BlogList = {
    [StatusBlog.ACTIVE]: <StatusActiveComponent />,
    [StatusBlog.PLANING]: <StatusBlockedComponent />,
};

const getStatusComponent = (status: StatusBlog) => BlogList[status];

export const BlogTable = ({ blogs }: { blogs: GetBlogContract['responseBody'] }): JSX.Element => {
    const history = useHistory();
    const [rows, setRows] = useState(blogs);

    useEffect(() => {
        setRows(blogs);
    }, [blogs]);

    const parseDate = (date: string) => new Date(date).toLocaleDateString().replace(/\//gi, '.');

    const handleDragEnd = (e: any) => {
        if (!e.destination) return;
        let tempData = Array.from(rows);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setRows(tempData);
    };

    /* const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            rows.forEach((row, index) => {
                dispatch(
                    setSortNumber({
                        data: { sortNumber: index + 1 },
                        id: row.id,
                    }),
                );
            });
        }
    }, [rows]); */

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <TableContainer component={Paper} sx={styles.root}>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead sx={styles.root}>
                        <TableRow
                            sx={{
                                'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                            }}
                        >
                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell align="left">Заголовок</TableCell>
                            <TableCell align="left">Дата публикации</TableCell>
                            <TableCell align="left">Статус</TableCell>
                            <TableCell align="left">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <Droppable droppableId="droppable-1">
                        {provider => (
                            <TableBody
                                sx={[styles.root, styles.body]}
                                ref={provider.innerRef}
                                {...provider.droppableProps}
                            >
                                {rows
                  && rows.map((row, index) => (
                      <Draggable
                          key={`${row.id}`}
                          draggableId={`${row.id}`}
                          index={index}
                      >
                          {provider => (
                              <TableRow
                                  key={row.id}
                                  sx={{ 'td, th': { border: 0 } }}
                                  {...provider.draggableProps}
                                  ref={provider.innerRef}
                              >
                                  <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ maxWidth: '85px' }}
                                      {...provider.dragHandleProps}
                                  >
                                      <ContentWrapper>
                                          <Icon
                                              type={TypesIcon.DRAG_AND_DROP}
                                              viewBox="0 0 24 24"
                                              width="24px"
                                              height="24px"
                                          />
                                          <div> {index + 1} </div>
                                      </ContentWrapper>
                                  </TableCell>
                                  <TableCell
                                      component="th"
                                      scope="row"
                                      className="text-grey"
                                      sx={{ width: '25%' }}
                                  >
                                      <StyledClipboardWrapper>
                                          <CopyToClipboard copyText={`${config.frontStageUrl}blog/${row.urn}`} />
                                      </StyledClipboardWrapper>
                                      <span>{`${config.frontStageUrl}blog/${row.urn}`}</span>
                                  </TableCell>
                                  <TableCell align="left" sx={{ width: '25%' }}>
                                      {' '}
                                      {row.title}
                                  </TableCell>

                                  <TableCell align="left" sx={{ width: '15%' }}>
                                      {parseDate(row.publishDate)}
                                  </TableCell>

                                  <StyledCell align="left" sx={{ width: '15%' }}>
                                      {getStatusComponent(row.status as StatusBlog)}
                                  </StyledCell>
                                  <TableCell align="left" sx={{ width: '5%' }}>
                                      <StyledEditButton
                                          typeButton={TypesButton.SECONDARY}
                                          size={SizesButton.M}
                                          onClick={() => history.push(`${routes.edit_blog}/${row.id}`)}
                                      />
                                  </TableCell>
                              </TableRow>
                          )}
                      </Draggable>
                  ))}
                                {rows && rows.length === 0 && <EmptyRow colSpan={6} />}
                                {provider.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </Table>
            </TableContainer>
        </DragDropContext>
    );
};
