/* eslint-disable import/no-unresolved */
import { useDispatch } from 'react-redux';
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { YMaps, Map } from '@pbe/react-yandex-maps';
import ymaps from 'yandex-maps';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { removeModal } from '../../actions/modal';
import { Modal } from '../../types/Models/Modal/Modal';
import { config } from '../../config';
import { units } from '../../helpers/styles/units';
import { SelectOption } from '../../types/Select/SelectOption';
import { TypesSelect } from '../../types/TypesSelect';
import { Input } from '../Input';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { Select } from '../Select';
import { useTypeSelector } from '../../store';
import { selectCities } from '../../selectors/cities';
import iconMap from '../../assets/iconMap.png';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { invalidHouseNumber } from '../../constants/GeocodingErrors';
import { MapModel } from '../../pages/Shops/CreateShop';
import { useOutSideClick } from '../../hooks/useOutSideClick';
import { fontName } from '../GlobalStyle';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  
  padding: 54px;
  
  background: ${colors.light};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const StyledTitle = styled.p`
  font-weight: ${FontWeight.MEDIUM};
  ${typography(20)};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  display: flex;
  gap: 32px;
  
  width: ${units(510)};
`;

const StyledMapWrapper = styled.div`
  width: ${units(330)};
  height: 100%;

  div {
    width: 100%;
  }

  .ymaps-2-1-79-balloon-pane {
    display: none;
  }
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  
  max-width: 369px;
  width: 100%;
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${units(12)};
`;

const StyledSelect = styled(Select)`
  background-color: ${colors.light};

  & .label {
    color: ${colors.greyDark};
  }
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledSearchWrapper = styled.div`
  position: relative;
`;

const StyledOptions = styled.div`
  position: absolute;
  left: 0;
  top: ${units(25)};

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  max-height: ${units(96)};
  height: auto;
  width: 100%;

  background: ${colors.white};
  border-radius: 0 0 ${units(4)} ${units(4)};

  z-index: 20;
`;

const StyledOption = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  align-content: center;
  padding: ${units(6)} ${units(8)};

  color: ${colors.greyDark};
  cursor: pointer;
  font-family: ${fontName};

  :hover {
    background: ${colors.grey40};
  }

  ${typography(16)};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  width: 100%;
`;

export const ChooseAddressModal = ({
    id, onClose, onSuccess, data,
}: Modal) => {
    const dispatch = useDispatch();
    const map = useRef<ymaps.Map>();
    const ref = useRef<HTMLDivElement>(null);

    const [ymapsBuildRoute, setYmapsBuildRoute] = useState<typeof ymaps>();
    const [coordinates, setCoordinates] = useState<number[]>([]);
    const [title, setTitle] = useState('');
    const [searchResult, setSearchResult] = useState<ymaps.ISuggestResult[]>([]);
    const [searchResultVisible, setSearchResultVisible] = useState(false);
    const [prevPlacemark, setPrevPlacemark] = useState<ymaps.Placemark>();
    const cities = useTypeSelector(selectCities);

    const [markerCoordinates, setMarkerCoordinates] = useState<[number, number]>(
        data
            ? [data.latitude || 53.9, data.longitude || 27.56]
            : [53.9, 27.56],
    );

    const citiesOptions = cities?.map(city => ({
        id: city.id,
        name: city.name,
    })) || [];

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        dispatch(removeModal(id));
    }, [dispatch, id, onClose]);

    const handleOutSideClick = (event: Event) => {
        if (!ref.current?.contains(event.target as Node)) {
            setSearchResultVisible(false);
        }
    };

    useOutSideClick(ref, handleOutSideClick);

    const initialValue: MapModel = {
        address: data?.address || '',
        latitude: data?.latitude || undefined,
        longitude: data?.longitude || undefined,
        cityId: data?.cityId || undefined,
    };

    useEffect(() => {
        if (map?.current && ymapsBuildRoute) {
            const placemark = new ymapsBuildRoute.Placemark([...markerCoordinates], {}, {
                iconLayout: 'default#image',
                iconImageHref: iconMap,
                iconImageSize: [59, 42],
            });
            map.current.geoObjects.removeAll();
            map.current.geoObjects.add(placemark);
        }
    }, [map?.current, markerCoordinates]);

    const addressRegex = /^[^,]+(?:,[^,]*){1,}$/;

    const validationSchema = yup.object().shape({
        address: yup.string()
            .matches(addressRegex, 'Уточни адрес')
            .required('Поле является обязательным')
            .test(
                'exception',
                'Указан неверный адрес',
                // eslint-disable-next-line no-unsafe-optional-chaining
                value => (value?.trim().charAt(value?.trim().length - 1) !== ','),
            ),
        latitude: yup.string().required('Обязательное поле!'),
        longitude: yup.number().required('Обязательное поле!'),
        cityId: yup.number().required('Обязательное поле!'),
    });

    const handleSubmit = (values: MapModel) => {
        const mapData = {
            address: values.address,
            latitude: values.latitude,
            longitude: values.longitude,
            cityId: values.cityId,
        };

        if (onSuccess) {
            onSuccess(mapData);
        }

        handleClose();
    };

    const form = useFormik<MapModel>({
        initialValues: initialValue,
        onSubmit: handleSubmit,
        validationSchema,
    });

    // const handleSelectAddress = async (value: string) => {
    //     setSearchResultVisible(false);
    //     setTitle(value.split('улица').pop()?.trim() || '');
    // };

    const handleSearch = async (searchQuery: string | number[], zoom = 16, isSearchResult = false) => {
        if (ymapsBuildRoute) {
            const currentCity = cities?.find(city => city.id === form.values.cityId);
            const mySearchControl = ymapsBuildRoute.geocode(`Республика Беларусь, ${currentCity?.name}, ${searchQuery}`);

            await mySearchControl.then(async res => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (res.geoObjects.get(0).properties.get('metaDataProperty.GeocoderMetaData.precision') === 'exact') {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const markerCoordinate = res.geoObjects.get(0).geometry._coordinates;

                    map?.current?.setCenter(markerCoordinate, zoom);
                    setCoordinates(markerCoordinate);
                    await form.setFieldValue('latitude', markerCoordinate[0]);
                    await form.setFieldValue('longitude', markerCoordinate[1]);

                    if (!isSearchResult) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setTitle(res.geoObjects.get(0).properties._data.name);
                        setSearchResultVisible(false);
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        await form.setFieldValue('address', res.geoObjects.get(0).properties._data.name);
                    }
                }
                if (Array.isArray(searchQuery)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const markerCoordinate = res.geoObjects.get(0).geometry._coordinates;

                    map?.current?.setCenter(markerCoordinate, zoom);
                    setCoordinates(markerCoordinate);
                    await form.setFieldValue('latitude', markerCoordinate[0]);
                    await form.setFieldValue('longitude', markerCoordinate[1]);

                    if (!isSearchResult) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setTitle(res.geoObjects.get(0).properties._data.name);
                        setSearchResultVisible(false);
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        await form.setFieldValue('address', res.geoObjects.get(0).properties._data.name);
                    }
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (invalidHouseNumber.includes(res.geoObjects.get(0).properties.get('metaDataProperty.GeocoderMetaData.precision'))) {
                    form.setErrors({
                        address: 'Неточный адрес, уточните номер дома',
                    });
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (res.geoObjects.get(0).properties.get('metaDataProperty.GeocoderMetaData.precision') === 'other') {
                    form.setErrors({
                        address: 'Неточный адрес, требуется уточнение',
                    });
                }
            });
        }
    };

    useEffect(() => {
        if (map?.current) {
            map?.current?.events.add('click', async (e: { get: (arg0: string) => any; }) => {
                let coords = e.get('coords');
                await handleSearch(coords);
            });
        }
    }, [map?.current]);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (ymapsBuildRoute) {
                const currentCity = cities?.find(city => city.id === form.values.cityId);
                const search = ymapsBuildRoute.suggest(`Республика Беларусь, ${currentCity?.name}, ${title}`);
                await search.then(resp => {
                    const respResult = resp?.filter(({ value }) => !value.includes('подъезд')) ? resp?.filter(({ value }) => !value.includes('подъезд')) : [];

                    setSearchResult(respResult?.map(result => {
                        const newResult = result.value.split('улица').pop()?.trim();

                        if (!result.value.includes('улица')) return { ...result, value: result.value };

                        return { ...result, value: `улица ${newResult}` || result.value };
                    }).filter(result => !!result.value));
                });
                setSearchResultVisible(true);
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [title]);

    useEffect(() => {
        if (ymapsBuildRoute && coordinates[0]) {
            const placemark = new ymapsBuildRoute.Placemark([...coordinates], {}, {
                iconLayout: 'default#image',
                iconImageHref: iconMap,
                iconImageSize: [59, 42],
            });
            if (prevPlacemark) {
                map.current?.geoObjects.removeAll();
            }
            map.current?.geoObjects.add(placemark);
            setPrevPlacemark(placemark);
        }
    }, [searchResultVisible]);

    return (
        <StyledRoot>
            <StyledTitleWrapper>
                <StyledTitle>
                    Показать адрес на карте
                </StyledTitle>
                <StyledIcon
                    onClick={handleClose}
                    type={TypesIcon.CLOSE}
                    color={colors.grayscale80}
                />
            </StyledTitleWrapper>
            <StyledWrapper>
                <StyledMapWrapper>
                    <YMaps
                        version="2.1.79"
                        query={{ apikey: config.mapKey, suggest_apikey: config.geoMapKey }}
                    >
                        <Map
                            instanceRef={map}
                            width="100%"
                            height="387px"
                            options={{
                                suppressMapOpenBlock: true,
                                suppressObsoleteBrowserNotifier: true,
                            }}
                            onLoad={maps => setYmapsBuildRoute(maps)}
                            noSuggestPanel={false}
                            defaultState={{
                                center: markerCoordinates,
                                zoom: 12,
                            }}
                            controls={[]}
                            modules={[
                                'templateLayoutFactory',
                                'layout.ImageWithContent',
                                'multiRouter.MultiRoute',
                                'geocode',
                                'suggest',
                                'Placemark',
                            ]}
                        />
                    </YMaps>
                </StyledMapWrapper>
                <StyledForm>
                    <StyledMainWrapper>
                        <StyledSelect
                            name="cityId"
                            options={citiesOptions as unknown as SelectOption[]}
                            selectType={TypesSelect.OUTLINE}
                            value={form.values.cityId}
                            label="Город*"
                            onClick={form.setFieldValue}
                            title="Город*"
                            isTouched={form.touched.cityId}
                            error={form.errors.cityId}
                        />

                        {/* <StyledInput */}
                        {/*    name="address" */}
                        {/*    onChange={form.handleChange} */}
                        {/*    value={form.values.address} */}
                        {/*    placeholder="Адрес*" */}
                        {/*    typeInput="text" */}
                        {/*    maxLength={255} */}
                        {/*    title="Адрес*" */}
                        {/*    isTouched={form.touched.address} */}
                        {/*    error={form.errors.address} */}
                        {/* /> */}

                        <StyledSearchWrapper ref={ref}>
                            <StyledInput
                                name="address"
                                onChange={event => {
                                    form.handleChange(event);
                                    setTitle(event.target.value);
                                }}
                                value={form.values.address}
                                placeholder="Адрес*"
                                typeInput="text"
                                maxLength={255}
                                title="Адрес*"
                                isTouched={form.touched.address}
                                error={form.errors.address}
                                count={form.submitCount}
                            />
                            {(!!searchResult.length) && (
                                <StyledOptions style={{ display: searchResultVisible ? 'block' : 'none' }}>
                                    {searchResult.map(result => (
                                        <StyledOption
                                            className={searchResult.length > 3 ? 'multiple-choice' : ''}
                                            onClick={async () => {
                                                await handleSearch(result.value.trim(), 16);
                                            }}
                                            key={result.value + Math.random() * 1000}
                                        >
                                            {result.value}
                                        </StyledOption>
                                    ))}
                                </StyledOptions>
                            )}
                        </StyledSearchWrapper>

                        <StyledInput
                            name="longitude"
                            onChange={form.handleChange}
                            value={form.values.longitude}
                            placeholder="Долгота*"
                            typeInput="number"
                            maxLength={255}
                            title="Долгота*"
                            isTouched={form.touched.longitude}
                            error={form.errors.longitude}
                            count={form.submitCount}
                        />

                        <StyledInput
                            name="latitude"
                            onChange={form.handleChange}
                            value={form.values.latitude}
                            placeholder="Широта*"
                            typeInput="number"
                            maxLength={255}
                            title="Широта*"
                            isTouched={form.touched.latitude}
                            error={form.errors.latitude}
                            count={form.submitCount}
                        />
                    </StyledMainWrapper>
                    <StyledButton
                        typeButton={TypesButton.PRIMARY}
                        size={SizesButton.M}
                        onClick={form.submitForm}
                        /* disabled={!form.isValid} */
                    >
                        Сохранить изменения
                    </StyledButton>
                </StyledForm>
            </StyledWrapper>
        </StyledRoot>
    );
};
