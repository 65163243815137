import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Input } from '../../../../../components/Input';
import { colors } from '../../../../../constants/Colors';
import { typography } from '../../../../../helpers/styles/typography';
import { units } from '../../../../../helpers/styles/units';
import { FontWeight } from '../../../../../constants/Styles/fontWeight';
import { Button } from '../../../../../components/Button';
import { TypesButton } from '../../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../../constants/SizeButton';
import { PageTitle } from '../../../../../components/PageTitle';
import { UploadPhotoWithMiniature } from '../../../../../components/UploadPhotoWithMiniature';
import { PageStatus } from '../loaders';
import { useTypeSelector } from '../../../../../store';
import { selectMobileBodyBlock } from '../../../../../selectors/mainPage';
import {
    BannerItemForEdit,
    MobileBlockKey,
} from '../../../../../types/Models/MainPage/MainPageModel';
import {
    editBannerItem,
    editBannerItemsMobile,
} from '../../../../../actions/mainPage';
import { Icon } from '../../../../../components/Icon';
import { TypesIcon } from '../../../../../types/TypesIcon';
import { getShotPath } from '../../../../../helpers/images/getPath';
import { createMobileBannerBreadcrumbs } from '../../../../../constants/BreadCrumbs/createMobileBannerItem';
import { SubmitButtons } from '../../../../../components/StyledComponents';
import { RadioGroup } from '../../../../../components/RadioButtonGroup';
import { RadioOption } from '../../../../../types/form/RadioOption';
import { MobileMainBannerTypes } from '../../../../../types/Endpoints/mainPage/MainPageContracts';
import Switch from '../../../../../components/sw';
import { addModal } from '../../../../../actions/modal';
import { getId } from '../../../../../helpers/generateId';
import { TypesModal } from '../../../../../types/TypesModalComponent';
import { isEqualObject } from '../../../../../helpers/isEqual';
import { addNotification } from '../../../../../actions/notifications';
import { TypesSnackbars } from '../../../../../types/TypesSnackbars';
import { BreadcrumbsMainBanners } from '../../../../../components/BreadcrumbsMainBanners';

interface CreateProps {
    changePage: (key: PageStatus) => void;
    data: BannerItemForEdit;
}

const typeControls: RadioOption[] = [
    {
        value: MobileMainBannerTypes.APP_LINK,
        title: 'Ссылка на приложение',
    },
    {
        value: MobileMainBannerTypes.SITE_LINK,
        title: 'Ссылка на сайт',
    },
];

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${units(63)};
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledOrderInputWrapper = styled.div`
  width: ${units(76)};
`;

const StyledSubtitle = styled.span`
  font-weight: ${FontWeight.MEDIUM};

  ${typography(10)};
`;

const StyledSwitchWrapper = styled(StyledMainWrapper)`
  gap: ${units(8)};
`;

const StyledDeleteButton = styled(StyledButton)`
  width: ${units(104)};
  padding: ${units(0)} ${units(16)} ${units(0)} ${units(14)};
`;

const SubmitButtonsWrapper = styled.div`
  margin-left: -${units(12)};
`;

export const EditMainBanner = ({ changePage, data }: CreateProps) => {
    const dispatch = useDispatch();

    const siteBodyBlock = useTypeSelector(
        selectMobileBodyBlock(MobileBlockKey.MOBILE_MAIN),
    );

    const handleSubmit = (values: BannerItemForEdit) => {
        const requestData = {
            id: values.id,
            title: values.title || null,
            buttonText: values.buttonText || null,
            text: values.text || null,
            url: values.url || null,
            order: +values.order,
            status: values.status,
            endDate: values.startDate || null,
            startDate: values.endDate || null,
            fileId: values.fileImage?.id,
            linkType: values.linkType || null,
        };
        const fullData = requestData as BannerItemForEdit;

        dispatch(editBannerItem({ key: values.id, newData: fullData }));
    };

    const handleDeleteItem = () => {
        const items = siteBodyBlock?.bannerItems
            ? [...siteBodyBlock.bannerItems].filter(el => el.id !== data.id)
            : [];
        dispatch(
            editBannerItemsMobile({ key: MobileBlockKey.MOBILE_MAIN, newData: items }),
        );
    };

    const validationSchema = yup.object().shape({
        title: yup
            .string()
            .max(50, 'Не более 50 символов')
            .required('Поле является обязательным'),
        text: yup.string().max(40, 'Не более 40 символов'),
        url: yup.string().required('Поле является обязательным'),
    });

    const initialValue: BannerItemForEdit = {
        title: data?.title || '',
        id: data?.id || 0,
        text: data?.text || '',
        buttonText: data?.buttonText || '',
        url: data?.url || '',
        order: data?.order || 0,
        status: data?.status || 'ACTIVE',
        endDate: data?.endDate || null,
        startDate: data?.startDate || null,
        linkType: data?.linkType,
        fileImage: {
            id: data?.fileId || 0,
            path: data?.fileURL ? getShotPath(data.fileURL) : '',
        },
        mobileFileImage: {
            id: data?.mobileFileId || 0,
            path: data?.mobileFileURL ? getShotPath(data.mobileFileURL) : '',
        },
    };

    const form = useFormik<BannerItemForEdit>({
        enableReinitialize: true,
        onSubmit: handleSubmit,
        initialValues: initialValue as unknown as BannerItemForEdit,
        validationSchema,
    });

    const handleToggle = (value: boolean) => {
        const newStatus = value ? 'ACTIVE' : 'INACTIVE';
        form.setFieldValue('status', newStatus);
    };

    const handleDelete = () => {
        dispatch(
            addModal({
                id: getId()(),
                type: TypesModal.CONFIRM,
                message: { description: 'Вы действительно хотите удалить?' },
                onSuccessMessage: 'Удалить',
                onSuccess: handleDeleteItem,
                withCloseIcon: true,
            }),
        );
    };

    const handleResetForm = (values: BannerItemForEdit) => {
        changePage(PageStatus.MAIN);
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Body мобильного приложения</PageTitle>
                <BreadcrumbsMainBanners
                    breadcrumbs={createMobileBannerBreadcrumbs('Редактировать')}
                    onClick={() => changePage(PageStatus.MAIN)}
                />
                <StyledRoot>
                    <StyledMainWrapper>
                        <StyledInput
                            name="title"
                            onChange={form.handleChange}
                            value={form.values.title}
                            placeholder="Заголовок (не более 50 символов)*"
                            typeInput="text"
                            maxLength={255}
                            title="Заголовок (не более 50 символов)*"
                            isTouched={form.touched.title}
                            error={form.errors.title}
                            count={form.submitCount}
                        />
                        <StyledInput
                            name="text"
                            onChange={form.handleChange}
                            value={form.values.text}
                            placeholder="Описание (не более 40 символов)"
                            typeInput="text"
                            maxLength={255}
                            title="Описание (не более 40 символов)"
                            isTouched={form.touched.text}
                            error={form.errors.text}
                            count={form.submitCount}
                        />
                        <StyledInput
                            name="id"
                            onChange={form.handleChange}
                            value={form.values.id}
                            placeholder="ID"
                            typeInput="text"
                            maxLength={255}
                            title="ID"
                            disabled
                        />
                        <StyledOrderInputWrapper>
                            <StyledInput
                                name="order"
                                onChange={form.handleChange}
                                value={form.values.order}
                                placeholder="Сортировка"
                                typeInput="number"
                                title="Сортировка"
                                isTouched={form.touched.order}
                                error={form.errors.order}
                            />
                        </StyledOrderInputWrapper>
                    </StyledMainWrapper>
                    <StyledSwitchWrapper>
                        <RadioGroup
                            name="linkType"
                            controls={typeControls}
                            onChange={form.setFieldValue}
                            fieldValue={form.values.linkType}
                            blockTitle="Вариант ссылки"
                        />
                        <StyledInput
                            name="url"
                            onChange={form.handleChange}
                            value={form.values.url}
                            placeholder="Ссылка*"
                            typeInput="text"
                            title="Ссылка*"
                            isTouched={form.touched.url}
                            error={form.errors.url}
                            count={form.submitCount}
                        />
                    </StyledSwitchWrapper>

                    <StyledSwitchWrapper>
                        <StyledSubtitle>Статус</StyledSubtitle>
                        <Switch
                            name="status"
                            value={form.values.status === 'ACTIVE'}
                            onChange={handleToggle}
                            type="checkbox"
                            inactiveTitle="Неактивный"
                            title
                        />
                    </StyledSwitchWrapper>
                    <StyledMainWrapper>
                        <UploadPhotoWithMiniature
                            name="fileImage"
                            title="Изображение"
                            onChange={form.setFieldValue}
                            value={form.values.fileImage}
                            format="3.6:3"
                            maxSize={3}
                        />
                    </StyledMainWrapper>

                    <StyledDeleteButton
                        onClick={handleDelete}
                        typeButton={TypesButton.DELETE}
                        size={SizesButton.M}
                    >
                        <Icon
                            type={TypesIcon.TRASH_BIG}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                        />
                        Удалить
                    </StyledDeleteButton>
                </StyledRoot>
            </StyledWrapper>
            <SubmitButtonsWrapper>
                <SubmitButtons
                    reset={() => handleResetForm(form.values)}
                    submit={form.submitForm}
                />
            </SubmitButtonsWrapper>
        </StyledRootWrapper>
    );
};
