import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as searchSettingsApi from '../../../api/landings';
import { SearchSettingLiked } from '../../../types/Endpoints/SearchSettings/SearchSettingsContracts';

export const GET_SEARCH_SETTINGS_LIKED = 'searchSettings/getSearchSettingsLiked';

export const getSearchSettingsLiked = createAsyncThunk<SearchSettingLiked[]>(
    GET_SEARCH_SETTINGS_LIKED,
    createRequest({
        type: GET_SEARCH_SETTINGS_LIKED,
        loader: async () => searchSettingsApi.getSearchSettingsLiked(),
    }),
);
