/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-use-before-define */
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Input } from '../../../components/Input';
import { colors } from '../../../constants/Colors';
import { typography } from '../../../helpers/styles/typography';
import { units } from '../../../helpers/styles/units';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { routes } from '../../../constants/RouterPath';
import { PageTitle } from '../../../components/PageTitle';
import { Textarea } from '../../../components/TextArea';
import { SearchSettingItem } from '../../../types/Endpoints/SearchSettings/SearchSettingsContracts';
import { addSearchSettings } from '../../../actions/landings';
import { SubmitButtons } from '../../../components/StyledComponents';
import { config } from '../../../config';
import { getCategoryFromUrl } from '../../../helpers/getCategory';
import Switch from '../../../components/sw';
import { createLandingBreadcrumbs } from '../../../constants/BreadCrumbs/createSearchBreadcrumbs';
import { useTypeSelector } from '../../../store';
import { selectCategories } from '../../../selectors/catalog';
import { CopyToClipboard } from '../../../components/CopyToClipboard';

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(30)};
  padding-top: ${units(20)};
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

`;

const StyledSubtitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 75%;
  gap: ${units(30)};
  max-width: ${units(334)};
  padding-bottom: ${units(48)};
`;
const StyledSubmitButtons = styled.div`
  margin-left: -${units(12)};
`;

const StyledBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
`;

const StyledGeneralWrapper = styled(StyledBlockWrapper)`
gap: ${units(24)};
`;

const StyledInputReadonly = styled(StyledInput)`
  color: ${colors.grey20};
`;

const StyledClipboardWrapper = styled.div`
  position: absolute;
  bottom: ${units(3)};
  right: 0;
  z-index: 100;
  display: inline-block;
  height: ${units(12)};
  width: ${units(12)};
`;

const UrlInputWrapper = styled.div`
  position: relative;

  & input[type='text'] {
    padding-right: ${units(18)};
  }
`;

export const CreateLanding = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const categories = useTypeSelector(selectCategories);

    const [isActive, setIsActive] = useState(false);

    const handleSubmit = async (values: SearchSettingItem) => {
        const req = {
            order: isActive ? values.order : null,
            originalUrl: values.originalUrl,
            url: values.url.split('/').at(-1) as string,
            string: values.string,
            urlMobile: values.urlMobile,
            seoH1: values.seoH1,
            seoTitle: values.seoTitle,
            seoDescription: values.seoDescription,
            isShowForSearch: values.isShowForSearch,
            categoryUrn: getCategoryFromUrl(values?.originalUrl || ''),
        };
        const res = await dispatch(addSearchSettings(req)) as any;

        if (res && res.error) {
            const { message, status } = res.payload.data;

            if (message.includes('original URL') && status === 400) {
                form.setFieldError('originalUrl', 'Данный URL уже существует');
            }

            if (message.includes('url') && status === 400) {
                form.setFieldError('url', 'Данная посадочная страница уже существует');
            }

            return;
        }
        history.push(routes.search_settings);
    };

    const validationSchema = yup.object().shape({
        string: yup.string().required('Поле является обязательным'),
        originalUrl: yup.string().required('Поле является обязательным'),
        urlMobile: yup.string().required('Поле является обязательным'),
        url: yup.string().required('Поле является обязательным').test('is-category-url', 'URL для сайта совпадает с URL категории', value => {
            let result = true;
            categories?.forEach(category => {
                if (category.urn === getCategoryFromUrl(value || '')) {
                    result = false;
                }
            });
            return result;
        }).test('is-valid-url', 'Разрешены символы “a-z”, “0-9”, “-“, “_”', value => {
            const urlParts = value?.split('catalog/');
            if (urlParts && urlParts.length > 1) {
                const partToCheck = urlParts[1];
                return /^[a-zA-Z0-9\-_]+$/.test(partToCheck);
            }
            return true;
        }),
        order: yup.number().when([], {
            is: () => isActive,
            then: yup.number().required('Поле является обязательным'),
            otherwise: yup.number().nullable(),
        }),
    });

    const form = useFormik<SearchSettingItem>({
        initialValues: { isShowForSearch: false, order: 500 } as SearchSettingItem,
        onSubmit: handleSubmit,
        validationSchema,
    });

    const handleChangeUrlSite = (e: { target: { value: string; }; }) => {
        const urlData = e.target.value.split('catalog/');
        const isValid = !urlData[1] || /^[a-zA-Z0-9\-_]+$/.test(urlData[1] || '');
        if (!isValid) return;

        if (urlData && urlData[1]) {
            form.setFieldValue('url', `${config.frontStageUrl}catalog/${urlData[1]}`);
        } else {
            form.setFieldValue('url', `${config.frontStageUrl}catalog/`);
        }
    };

    const handleChangeUrl = (e: { target: { value: string; }; }) => {
        const category = getCategoryFromUrl(e.target.value || '');
        const urlData = e.target.value.split('catalog/');

        form.setFieldValue('url', `${config.frontStageUrl}catalog/${category}`);
        form.setFieldValue('urlMobile', `zikoby://catalog/${urlData[1]}`);
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Посадочные страницы</PageTitle>
                <Breadcrumbs breadcrumbs={createLandingBreadcrumbs('Создать')} />
                <StyledRoot>
                    <StyledMainWrapper>
                        <StyledBlockWrapper>
                            <StyledSubtitle>SEO</StyledSubtitle>
                            <Textarea
                                name="seoH1"
                                value={form.values.seoH1}
                                onChange={form.handleChange}
                                placeholder="H1"
                                title="H1"
                                isTouched={form.touched.seoH1}
                                error={form.errors.seoH1}
                            />
                            <Textarea
                                name="seoTitle"
                                value={form.values.seoTitle}
                                onChange={form.handleChange}
                                placeholder="Title"
                                maxLength={255}
                                title="Title"
                                isTouched={form.touched.seoTitle}
                                error={form.errors.seoTitle}
                            />
                            <Textarea
                                name="seoDescription"
                                value={form.values.seoDescription}
                                onChange={form.handleChange}
                                placeholder="Description"
                                maxLength={255}
                                title="Description"
                                isTouched={form.touched.seoDescription}
                                error={form.errors.seoDescription}
                            />
                        </StyledBlockWrapper>

                        <StyledGeneralWrapper>
                            <StyledBlockWrapper>
                                <StyledSubtitle>Основное</StyledSubtitle>

                                <Textarea
                                    name="originalUrl"
                                    value={form.values.originalUrl}
                                    onChange={e => {
                                        form.handleChange(e);
                                        handleChangeUrl(e);
                                    }}
                                    placeholder="Исходный URL*"
                                    title="Исходный URL*"
                                    maxLength={255}
                                    isTouched={form.touched.originalUrl}
                                    error={form.errors.originalUrl}
                                    count={form.submitCount}
                                />

                                <UrlInputWrapper>
                                    <StyledInput
                                        name="url"
                                        value={form.values.url}
                                        onChange={handleChangeUrlSite}
                                        placeholder="URL для сайта*"
                                        typeInput="text"
                                        title="URL для сайта*"
                                        isTouched={form.touched.url}
                                        error={form.errors.url}
                                        count={form.submitCount}
                                    />
                                    <StyledClipboardWrapper>
                                        <CopyToClipboard copyText={form.values.url} />
                                    </StyledClipboardWrapper>
                                </UrlInputWrapper>

                                <StyledInputReadonly
                                    name="urlMobile"
                                    value={form.values.urlMobile}
                                    onChange={() => {}}
                                    placeholder="URL для мобильного приложения*"
                                    typeInput="text"
                                    title="URL для мобильного приложения*"
                                    isTouched={form.touched.urlMobile}
                                    error={form.errors.urlMobile}
                                    count={form.submitCount}
                                    readonly
                                />

                                <StyledInput
                                    name="string"
                                    value={form.values.string}
                                    onChange={form.handleChange}
                                    placeholder="Название пользовательское*"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Название пользовательское*"
                                    isTouched={form.touched.string}
                                    error={form.errors.string}
                                    count={form.submitCount}
                                />

                            </StyledBlockWrapper>

                            <Switch
                                name="isShowForSearch"
                                value={form.values.isShowForSearch}
                                onChange={() => {
                                    form.setFieldValue(
                                        'isShowForSearch',
                                        !form.values?.isShowForSearch,
                                    );
                                    setIsActive(v => !v);
                                }}
                                type="checkbox"
                                title
                                inactiveTitle="Не отображать для поиска"
                                activeTitle="Отображать для поиска"
                            />

                            {isActive && (
                                <StyledInput
                                    name="order"
                                    value={form.values.order ? form.values.order.toString() : ''}
                                    onChange={form.handleChange}
                                    placeholder="Сортировка*"
                                    typeInput="number"
                                    // maxLength={255}
                                    title="Сортировка*"
                                    isTouched={form.touched.order}
                                    error={form.errors.order}
                                    count={form.submitCount}
                                />
                            )}

                        </StyledGeneralWrapper>

                        <StyledSubmitButtons>
                            <SubmitButtons
                                reset={() => history.push(routes.search_settings)}
                                submit={form.submitForm}
                            />
                        </StyledSubmitButtons>
                    </StyledMainWrapper>

                </StyledRoot>

            </StyledWrapper>

        </StyledRootWrapper>
    );
};
