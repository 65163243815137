import { createAsyncThunk } from '@reduxjs/toolkit';
import { WebBlockKey } from '../../../types/Models/MainPage/MainPageModel';
import { createRequest } from '../../../helpers/api/createRequest';
import * as mainPageApi from '../../../api/mainPage';
import { EditMainPageWebContract } from '../../../types/Endpoints/mainPage/MainPageContracts';
import { getWebBanners } from './getWebBanners';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const EDIT_WEB_BANNER = 'mainPage/getWebBanners';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(getWebBanners());

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editWebBanners = createAsyncThunk(
    EDIT_WEB_BANNER,
    createRequest({
        type: EDIT_WEB_BANNER,
        loader: async (data: {key: WebBlockKey, newData: EditMainPageWebContract['requestBody']}) => mainPageApi.editWebBanners(data),
        onSuccess,
    }),
);
