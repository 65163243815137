import { useSelector } from 'react-redux';
import { selectRoles } from '../selectors/users';
import { routes } from '../constants/RouterPath';
import { Roles } from '../constants/Roles';
import { RedirectWithQuery } from '../components/RedirectWithQuery';
import { selectBreakingPath, selectInitialPath } from '../selectors/systems';

export const RootRouter = () => {
    const roles = useSelector(selectRoles) as Roles;

    const breakingPath = useSelector(selectBreakingPath);
    const initialPath = useSelector(selectInitialPath(roles));

    const toProfileRoute = breakingPath || initialPath || `${routes.site_body}`;

    switch (true) {
        case roles?.includes(Roles.ADMIN):
            return <RedirectWithQuery to={toProfileRoute} />;
        case roles?.includes(Roles.USER):
            return <RedirectWithQuery to={toProfileRoute} />;
        default:
            return <RedirectWithQuery to={routes.login} />;
    }
};
