import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';
import { ButtonProps } from './index';

const hoverByType: Record<string, string> = {
    [TypesButton.SECONDARY]: colors.gold,
    [TypesButton.PRIMARY]: colors.gold,
    [TypesButton.DELETE]: colors.vine,
};

export const applyHoverBorderColor = ({ typeButton, disabled }: Pick<ButtonProps, 'typeButton' | 'disabled'>) => (
    !disabled
        ? `
          &:hover {
            border: 1px solid ${hoverByType[typeButton]};
          }
        `
        : '');
