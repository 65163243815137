import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';
import { SearchBar } from '../../../components/SearchBar';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { routes } from '../../../constants/RouterPath';
import { units } from '../../../helpers/styles/units';
import { Button } from '../../../components/Button';
import { PageTitle } from '../../../components/PageTitle';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../../store';
import { Icon } from '../../../components/Icon';
import { TypesIcon } from '../../../types/TypesIcon';
import { colors } from '../../../constants/Colors';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { BrandsBreadcrumbs } from '../../../constants/BreadCrumbs/brandsBreadcrumbs';
import { selectBrands, selectGetCatalogBrandsLoader } from '../../../selectors/brands';
import { BrandsTable } from '../../../components/Table/BrandsTable';

const StyledRoot = styled.div`
  display: flex;
  padding-top: ${units(20)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

const StyledPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;

  padding-top: ${units(11)};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${units(3)};
  max-width: ${units(137)};
  width: 100%;
  height: ${units(24)};
`;

const StyledSearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM};
  margin-bottom: ${units(8)};
`;

const BrandsContent = () => {
    const history = useHistory();
    const brands = useTypeSelector(selectBrands);

    return (
        <StyledWrapper>
            <StyledPageTitle>Бренды</StyledPageTitle>
            <Breadcrumbs breadcrumbs={BrandsBreadcrumbs()} />
            <StyledPanelWrapper>
                <StyledSearchBarWrapper>
                    <SearchBar />
                </StyledSearchBarWrapper>
                <StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => history.push(routes.create_brand)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </StyledButton>
            </StyledPanelWrapper>
            <StyledRoot>
                {brands && <BrandsTable brands={brands} />}
            </StyledRoot>
        </StyledWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetCatalogBrandsLoader,
});

export const BrandsgWithLoader = loader(BrandsContent);
