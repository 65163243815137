import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { SearchSettingItem } from '../../types/Endpoints/SearchSettings/SearchSettingsContracts';
import { LoaderState } from '../../types/Loaders/LoaderState';
import { selectGetSearchSettingsLoader } from '../../selectors/landings';
import { Preloader } from '../Preloader';
import { EmptyRow } from '../EmptyRow';
import { routes } from '../../constants/RouterPath';
import { units } from '../../helpers/styles/units';
import { typography } from '../../helpers/styles/typography';
import { fontName } from '../GlobalStyle';
import { config } from '../../config';

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: '#F7F7F7',
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: '#939393',
            maxWidth: '224px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
    },
};

const StyledCell = styled(TableCell)`
  .status {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};
  }

  & .active {
    background-color: rgba(87, 198, 118, 0.05);

    & div {
      background-color: #6db180;
    }
  }

  & .end {
    background-color: rgba(231, 72, 72, 0.05);

    & div {
      background-color: #e74848;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
  width: ${units(58)};

  font-family: ${fontName};

  ${typography(1)};
`;

const StyledStatusInfo = styled.div`
  min-width: ${units(4)};
  min-height: ${units(4)};
`;

export const StatusActiveComponent = () => (
    <StyledDiv className="status active">
        <StyledStatusInfo />
        <span>Активный</span>
    </StyledDiv>
);

export const StatusDisabledComponent = () => (
    <StyledDiv className="status end">
        <StyledStatusInfo />
        <span>Неактивный</span>
    </StyledDiv>
);

const getStatusComponent = (status: boolean) => (status ? <StatusActiveComponent /> : <StatusDisabledComponent />);

const StyledEditButton = styled(EditButton)`
  margin: 0 auto;
`;

export const SearchTable = ({
    items,
}: {
  items: SearchSettingItem[];
}): JSX.Element => {
    const history = useHistory();
    const searchLoader = useSelector(selectGetSearchSettingsLoader);

    if (searchLoader?.state === LoaderState.PENDING) {
        return <Preloader />;
    }

    return (
        <TableContainer component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell align="left">&nbsp;</TableCell>
                        <TableCell align="left">Посадочная страница</TableCell>
                        <TableCell>URL для сайта</TableCell>
                        <TableCell>Статус в поиске</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={[styles.root, styles.body]}>
                    {items
                    && items.map(row => (
                        <TableRow key={row.id} sx={{ 'td, th': { border: 0 } }}>
                            <TableCell component="th" scope="row" sx={{ width: '5%' }} align="center">
                                <span>{row.order || '-'}</span>
                            </TableCell>

                            <TableCell align="left" sx={{ width: '35%' }}>
                                {row.string}
                            </TableCell>
                            <TableCell
                                component="th"
                                scope="row"
                                className="text-grey"
                                sx={{ width: '40%' }}
                            >
                                <span>{`${config.frontStageUrl}catalog/${row.url}`}</span>
                            </TableCell>
                            <StyledCell
                                component="th"
                                scope="row"
                                align="left"
                                sx={{ width: '15%' }}
                            >
                                {getStatusComponent(row.isShowForSearch)}
                            </StyledCell>

                            <TableCell align="left" sx={{ width: '5%' }}>
                                <StyledEditButton
                                    typeButton={TypesButton.SECONDARY}
                                    size={SizesButton.M}
                                    onClick={() => {
                                        history.push(`${routes.search_settings_edit}/${row.id}`);
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                    {!items.length && <EmptyRow colSpan={5} />}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
