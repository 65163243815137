export enum ShopRegions {
  MINSK = 'Минская',
  GOMEL = 'Гомельская',
  VITEBSK = 'Витебская',
  GRODNO = 'Гродненская',
  MOGILEV = 'Могилевская',
  BREST = 'Брестская'
}

export const getShopRegion = (region: keyof typeof ShopRegions): string => ShopRegions[region];
