import styled from 'styled-components';
import TableCell from '@mui/material/TableCell';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { EditButton } from '../../../components/Button/editButton';

export const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: `${colors.grey60}`,
            borderRadius: '0px',
        },
        '& .MuiTableContainer-root': {
            borderRadius: '0px',
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: '0',
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: `${colors.white}`,
        },
        '& .text-grey': {
            color: `${colors.grey20}`,
            maxWidth: `${units(112)}`,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
    },
};

export const StyledCell = styled(TableCell)`
  .status {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};
  }

  & .active {
    background-color: rgba(87, 198, 118, 0.05);

    & div {
      background-color: ${colors.green};
    }
  }

  & .end {
    background-color: rgba(231, 72, 72, 0.05);

    & div {
      background-color: ${colors.red};
    }
  }

  & .plan {
    background-color: rgba(215, 180, 106, 0.05);

    & div {
      background-color: ${colors.gold};
    }
  }
`;

export const StyledStatusInfo = styled.div`
  min-width: ${units(4)};
  min-height: ${units(4)};
`;

export const StyledDiv = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
`;

export const StyledEditButton = styled(EditButton)`
  margin: 0 auto;
`;
