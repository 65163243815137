import { useState } from 'react';
import { Tabs } from '../../../components/SiteBody/SiteBodyTabs';
import { Wrapper } from './styles';
import { TABS_DATA } from './constants';

export const SiteBodyContent = () => {
    const [active, setActive] = useState(0);

    return (
        <Wrapper>
            <Tabs data={TABS_DATA} active={active} setActive={setActive} />
        </Wrapper>
    );
};
