import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as orderSettingsApi from '../../../api/orderSettings';
import { OrderSettings } from '../../../types/Models/OrderSettings/OrderSettings';

export const GET_ORDER_SETTINGS = 'orderSettings/getOrderSettings';

export const getOrderSettings = createAsyncThunk<OrderSettings[]>(
    GET_ORDER_SETTINGS,
    createRequest({
        type: GET_ORDER_SETTINGS,
        loader: async () => orderSettingsApi.getOrderSettings(),
        showFailNotification: false,
    }),
);
