import { request } from '../../helpers/api/request';
import { EditMainPageWebContract } from '../../types/Endpoints/mainPage/MainPageContracts';
import { WebBlockKey } from '../../types/Models/MainPage/MainPageModel';

export const editWebBanners = async ({ key, newData }:{key: WebBlockKey, newData: EditMainPageWebContract['requestBody']}) => {
    const { data } = await request<EditMainPageWebContract>({
        url: `admin/banners/${key}`,
        method: 'PATCH',
        data: newData,
    });

    return data;
};
