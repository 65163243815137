import { createAsyncThunk } from '@reduxjs/toolkit';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { createRequest } from '../../../helpers/api/createRequest';
import { CreateProductContract, ProductItemContract } from '../../../types/Endpoints/goods/ProductContracts';
import { addNotification } from '../../notifications';
import { TypesIcon } from '../../../types/TypesIcon';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Thunk } from '../../../helpers/redux/Thunk';
import * as productApi from '../../../api/goods';

const CREATE_PRODUCT = 'goods/updateProduct';

const MESSAGE = 'Продукт создан';

const onSuccess = (result: CreateProductContract): Thunk => dispatch => {
    history.replace(`${routes.goods_edit}/${result.id}`);

    dispatch(addNotification({
        type: TypesSnackbars.INFO,
        icon: TypesIcon.SNACK_DIAMOND,
        message: {
            title: MESSAGE,
        },
    }));
};

export const createProduct = createAsyncThunk<ProductItemContract, CreateProductContract>(
    CREATE_PRODUCT,
    createRequest({
        type: CREATE_PRODUCT,
        onSuccess,
        loader: async (data: CreateProductContract) => productApi.createProduct(data),
    }),
);
