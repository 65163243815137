import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as goodsApi from '../../../api/goods';

export const GET_PROPERTIES = 'goods/getProperties';

export const getPropertiesThink = createAsyncThunk(
    GET_PROPERTIES,
    createRequest({
        type: GET_PROPERTIES,
        loader: async (id: string) => goodsApi.getProperties(id),
    }),
);
