import { createReducer } from '@reduxjs/toolkit';
import { getDocuments } from '../../actions/docs';
import { DocumentItem } from '../../types/Endpoints/documents/DocumentsContracts';

export interface DocumentsModel {
  documents?: DocumentItem[];
}

export const documents = createReducer<DocumentsModel>({ }, builder => {
    builder.addCase(getDocuments.fulfilled, (state, { payload }) => ({
        ...state,
        documents: payload,
    }));
});
