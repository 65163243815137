import { request } from '../../helpers/api/request';

const getUrl = (id: number): string => `admin/products/${id}/goods`;

// FIXME: await fix backend, fix types
export const updateProductGoods = async (updatedData: any, id: any) => {
    console.log(updatedData, 'updatedData');
    const { data } = await request({
        method: 'PATCH',
        url: getUrl(id),
        data: updatedData,
    });

    return data;
};
