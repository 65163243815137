import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EditBrandWithLoader } from './loaders';
import { getCatalogBrand } from '../../../../actions/brands/thunks';

export const EditBrand = () => {
    const dispatch = useDispatch();
    const { brandId } = useParams<{brandId: string}>();

    useEffect(() => {
        dispatch(getCatalogBrand(brandId));
    }, [brandId]);

    return <EditBrandWithLoader />;
};
