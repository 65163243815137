import { request } from '../../helpers/api/request';
import { GetCategoriesContract } from '../../types/Endpoints/categories/CategoriesContracts';

export const getCategories = async () => {
    const { data } = await request<GetCategoriesContract>({
        url: 'admin/categories',
    });

    return data;
};
