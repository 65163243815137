import { MainBanner } from './MainBanner';
import { BannerRidgepole } from './BannerRidgepole';
import { BannerCategories } from './CategoriesBanner';
import { BannerCollections } from './BannerCollection';
import { FirstBlock } from './FirstBlock';
// import { SecondBlock } from './SecondBlock';
import { OurServices } from './OurServices';
import { BannerInstagram } from './BannerInstagram';

export const TABS_DATA = [
    { name: 'Заглавный баннер', content: <MainBanner /> },
    { name: 'Баннер растяжка', content: <BannerRidgepole /> },
    { name: 'Баннеры категорий', content: <BannerCategories /> },
    { name: 'Коллекции', content: <BannerCollections /> },
    { name: 'Блок 1', content: <FirstBlock /> },
    /* { name: 'Блок 2', content: <SecondBlock /> }, */
    { name: 'Наши услуги', content: <OurServices /> },
    { name: 'Мы в Instagram ', content: <BannerInstagram /> },
];
