import { memo } from 'react';
import styled, { keyframes } from 'styled-components';
import { units } from '../../../helpers/styles/units';
import { colors } from '../../../constants/Colors';

const LoadBase = styled.div`
  display: flex;
  align-items: center;
`;

const rotation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
`;

const Form = styled.svg`
  overflow: hidden;
  height: ${units(16)};
  width: ${units(16)};
  animation: ${rotation} 1.35s linear infinite;
  fill: none;
`;

const circleAnimation = keyframes`
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
`;

const Circle = styled.circle`
  stroke: ${colors.gold};
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  stroke-width: 6;
  stroke-linecap: round;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  animation: ${circleAnimation} 1.35s ease-in-out infinite;
`;

// eslint-disable-next-line react/display-name
export const Load = memo(() => (
    <LoadBase>
        <Form xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
            <Circle cx="33" cy="33" r="30" />
        </Form>
    </LoadBase>
));
