import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { MenuEditContent } from './loaders';
import { getCatalogCategory } from '../../../../actions/category';

export const MenuEdit = () => {
    const dispatch = useDispatch();
    const { menuId } = useParams<{menuId: string}>();

    useEffect(() => {
        dispatch(getCatalogCategory(menuId));
    }, [menuId]);
    return <MenuEditContent />;
};
