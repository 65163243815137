import { request } from '../../helpers/api/request';
import { CreateProductContract, GetProductItemContract } from '../../types/Endpoints/goods/ProductContracts';

const URL = 'admin/products/';

export const updateProduct = async (updatedData: CreateProductContract) => {
    const { data } = await request<GetProductItemContract>({
        method: 'PATCH',
        url: URL + updatedData.id,
        data: updatedData,
    });

    return data;
};
