import { TypesButton } from '../../constants/ButtonTypes';
import { colors } from '../../constants/Colors';

const colorByType: Record<string, string> = {
    [TypesButton.PRIMARY]: colors.white,
    [TypesButton.SECONDARY]: colors.greyDark,
    [TypesButton.DELETE]: colors.red,
    [TypesButton.TEXT]: colors.greyDark,
    [TypesButton.ICON]: colors.white,
    [TypesButton.LIGHT]: colors.primary,
    [TypesButton.GRAY]: colors.grayscale40,
};

export const applyColor = ({
    typeButton,
}: {
  typeButton: TypesButton;
}) => `color: ${colorByType[typeButton]};           & svg {
    filter: ${
    typeButton === TypesButton.DELETE
        ? 'brightness(0) saturate(100%) invert(38%) sepia(37%) saturate(2327%) hue-rotate(331deg) brightness(98%) contrast(85%);'
        : ''
};
  }`;
