export enum Roles {
  ADMIN = 'ADMIN',
  MARKETING = 'MARKETING',
  ESHOP = 'ESHOP',
  USER = 'USER',
}

export const roleOptions = [
    { name: 'Администратор', id: Roles.ADMIN },
    { name: 'Маркетолог', id: Roles.MARKETING },
    { name: 'ESHOP', id: Roles.ESHOP },
];
