import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { units } from '../../helpers/styles/units';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { typography } from '../../helpers/styles/typography';
import { RadioButton } from '../RadioButton';
import { colors } from '../../constants/Colors';
import { RadioOption } from '../../types/form/RadioOption';

interface RadioGroupProps {
  controls: RadioOption[];
  fieldValue: string;
  name: string;
  blockTitle: string;
  onChange?: (field: string, value?: string, shouldValidate?: boolean) => void;
}

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(8)};

  max-width: ${units(164)};
  width: 100%;
`;

const StyledTitleBlock = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledCheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(8)};
`;

export const RadioGroup = ({
    controls,
    fieldValue,
    onChange,
    name,
    blockTitle,
}: PropsWithChildren<RadioGroupProps>) => {
    const checkboxControl = controls?.map(({ title, value }: RadioOption) => (
        <RadioButton
            key={value}
            title={title}
            name={name}
            onChange={onChange}
            value={value}
            isChecked={value === fieldValue}
        />
    ));

    return (
        <StyledMainWrapper>
            <StyledTitleBlock>{blockTitle}</StyledTitleBlock>
            <StyledCheckboxGroup>
                {checkboxControl}
            </StyledCheckboxGroup>
        </StyledMainWrapper>
    );
};
