import GothamPro from '../../styles/fonts/gothampro.ttf';
import GothamProMedium from '../../styles/fonts/gothampro_medium.ttf';
import GothamProBold from '../../styles/fonts/gothampro_bold.ttf';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { fontFace } from '../../helpers/styles/fontFace';
import { fontName } from './index';

const fontStyles = `
  ${fontFace(fontName, GothamPro, FontWeight.REGULAR)}
  ${fontFace(fontName, GothamProMedium, FontWeight.MEDIUM)}
  ${fontFace(fontName, GothamProBold, FontWeight.BOLD)}
`;

export const GlobalFontStyle = () => (
    <style>{fontStyles}</style>
);
