import { createReducer } from '@reduxjs/toolkit';
import { City, CityItem } from '../../types/Models/City/City';
import { getCities, getCitiesByRegion } from '../../actions/city/thunks';

export interface CitiesState {
  activeCity?: City | null;
  cities?: City[]| null;
  citiesByRegion?: CityItem[] | [] | null;
}

export const cities = createReducer<CitiesState>({ }, builder => {
    builder.addCase(getCities.fulfilled, (state, { payload }) => ({
        ...state,
        cities: payload,
    }));
    builder.addCase(getCitiesByRegion.fulfilled, (state, { payload }) => ({
        ...state,
        citiesByRegion: payload,
    }));
});
