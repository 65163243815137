import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_NOTES } from '../../actions/notes';

const selectRoot = ({ notes }: RootState) => notes;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetNotesLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_NOTES],
);

export const selectNotes = createSelector(
    selectRoot,
    ({ notes }) => notes,
);
