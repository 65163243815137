/* eslint-disable max-len */
import { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../../../components/PageTitle';
import { units } from '../../../../helpers/styles/units';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { useTypeSelector } from '../../../../store';
import { selectDinamicPage } from '../../../../selectors/dinamicPages';
import { DynamicPagesKeys } from '../../../../types/Models/DinamicPages';
import { getPage } from '../../../../actions/dinamicPages';
import { Preloader } from '../../../../components/Preloader';
import { updateFooterPage } from '../../../../actions/dinamicPages/thunks/updateFooterInfo';
import { FooterBreadcrumbs } from '../../../../constants/BreadCrumbs/footerBreadcrumbs';
import { FooterForm } from '../../../../components/FooterForm';

const StyledRoot = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${units(20)};
    padding-top: ${units(20)};
`;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: ${units(50)};
`;

const StyledPreloader = styled(Preloader)`
    position: absolute;
    left: 50%;
    top: ${units(150)};
`;

export const FooterInfo = () => {
    const dispatch = useDispatch();
    const dinamicPage = useTypeSelector(selectDinamicPage);
    const [state, setState] = useState(false);

    useLayoutEffect(() => {
        dispatch(getPage(DynamicPagesKeys.FOOTER_INFO));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setState(true);
        }, 300);
    });

    if (!state) {
        return <StyledPreloader />;
    }

    return (
        <StyledWrapper>
            <PageTitle>Footer</PageTitle>
            <Breadcrumbs breadcrumbs={FooterBreadcrumbs()} />
            <StyledRoot>
                {!!dinamicPage && (
                    <FooterForm
                        pagesData={dinamicPage}
                        pageKey={DynamicPagesKeys.FOOTER_INFO}
                        updatePage={updateFooterPage}
                    />
                )}
            </StyledRoot>
        </StyledWrapper>
    );
};
