import { createReducer } from '@reduxjs/toolkit';
import { getLogs } from '../../actions/logs';
import { GetLogsContract } from '../../types/Endpoints/logs/LogsContracts';

export interface LogsModel {
  logs?: GetLogsContract['requestBody'];
}

export const logs = createReducer<LogsModel>({

}, builder => {
    builder.addCase(getLogs.fulfilled, (state, { payload }) => ({
        ...state,
        logs: payload,
    }));
});
