import { request } from '../../helpers/api/request';
import { GetCatalogCategoryContract } from '../../types/Endpoints/categories/CategoriesContracts';

export const getCategory = async (id: string) => {
    const { data } = await request<GetCatalogCategoryContract>({
        url: `admin/categories/${id}`,
    });

    return data;
};
