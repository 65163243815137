import { request } from '../../helpers/api/request';
import { EditOrderSettingsItem, GetOrderSettingsContract } from '../../types/Endpoints/orderSettings/GetOrderSettingsContract';

export const editOrderSettings = async (requestData: EditOrderSettingsItem[]) => {
    const { data } = await request<GetOrderSettingsContract>({
        url: 'admin/delivery-methods/',
        method: 'PUT',
        data: requestData,
    });

    return data;
};
