import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { Button } from '../../../../components/Button';
import { SubmitButtons } from '../../../../components/StyledComponents';
import { Input } from '../../../../components/Input';
import { FontWeight } from '../../../../constants/Styles/fontWeight.ts';
import { units } from '../../../../helpers/styles/units';
import { typography } from '../../../../helpers/styles/typography.ts';
import { colors } from '../../../../constants/Colors';
import { fontName } from '../../../../components/GlobalStyle/index.ts';

export const StyledRootWrapper = styled.div`
  position: relative;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

export const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(40)};
  padding: ${units(20)} 0 ${units(10)} 0;
`;

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};
  max-width: ${units(322)};
  width: 100%;
`;

export const CheckboxWrapper = styled(StyledMainWrapper)`
  gap: ${units(8)};
`;

export const SubSectionWrapper = styled(StyledMainWrapper)`
  margin-bottom: ${units(12)};
`;

export const StyledSection = styled(StyledMainWrapper)`
  gap: ${units(20)};
`;

export const StyledAddButtonInline = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  border: 0;
  font-style: inherit;
  background-color: transparent;
  cursor: pointer;
  gap: ${units(8)};
  ${typography(10)};
  font-family: ${fontName};
  font-weight: ${FontWeight.MEDIUM}; 
`;

export const RowInputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: ${units(16)};

  &:nth-child(3) {
    margin-bottom: ${units(20)};
  }
`;

export const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;
  background-color: ${colors.light};
  ${typography(10)};

  & ::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledDrawer = styled(Drawer)`
    & .MuiPaper-root {
    width: ${units(337)};
    padding: ${units(27)};
    box-shadow: none;
    overflow: hidden;
  }

  & .MuiBackdrop-root.MuiModal-backdrop {
    background: ${colors.grey10};
    opacity: 0.4 !important;
  }
`;

export const StyledHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${units(8)};
`;

export const StyledModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(8)};
  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 1px;
  ${typography(20)};
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${units(8)};
  padding: ${units(12)} ${units(27)};
  width: ${units(337)};
  background-color: ${colors.light};
  border-top: 1px solid ${colors.grey40};
  position: fixed;
  bottom: 0;
  right: 0;
`;

export const StyledSubtitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  ${typography(10)};
`;

export const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(76%) saturate(6644%) hue-rotate(332deg) brightness(91%) contrast(98%);
  }

  &:active svg {
    filter: brightness(0) saturate(100%) invert(31%) sepia(43%) saturate(1816%) hue-rotate(333deg) brightness(96%) contrast(89%);
  }
`;

export const StyledDrawerRoot = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units(21)};
  overflow: hidden;
  height: 100%;
`;

export const StyledAddButton = styled(StyledButton)`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: ${units(8)};
  font-weight: ${FontWeight.MEDIUM};
`;

export const StyledCountInfo = styled.div`
  color: ${colors.grey20};
  ${typography(10)};
`;

export const StyledDeleteButton = styled(Button)`
  width: fit-content;
  font-weight: ${FontWeight.MEDIUM};
`;

export const StyledBoxShops = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: ${units(10)};

  &::-webkit-scrollbar {
    width: ${units(3)};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grayscale05};
    border-radius: ${units(2)};
  }
`;

export const StyledBoxShop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${units(8)} 0;
`;

export const StyledLoadShops = styled.div`
  margin: ${units(20)} 0 ${units(12)} 0;
  color: ${colors.blue};
  font-weight: ${FontWeight.MEDIUM};
  cursor: pointer;
  width: fit-content;
`;

export const StyledShopName = styled.div`
  ${typography(10)};
  font-weight: ${FontWeight.REGULAR};
`;

export const StyledFilter = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${units(8)};
  margin: ${units(20)} 0;
  padding-right: ${units(4)};
`;

export const StyledFilterWrapper = styled.div`
  position: relative;
`;

export const StyledFilterWindow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(16)};
  position: absolute;
  top: ${units(30)};
  right: 0;
  box-shadow: 0 0 ${units(10)} 0 rgba(57, 56, 54, 0.20);
  border-radius: 0;
  background-color: ${colors.grey60};
  padding: ${units(16)} ${units(20)};
  z-index: 1;
  width: max-content;
`;

export const StyledCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(8)};
`;

export const StyledFilterTitle = styled.div`
  font-weight: ${FontWeight.MEDIUM};
  color: ${colors.greyDark};
  ${typography(10)};
`;

export const StyledSubmitButtons = styled(SubmitButtons)`
  left: ${units(124)};
`;

export const StyledButtonClear = styled(Button)`
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin-top: ${units(8)};
`;
