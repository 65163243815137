import styled from 'styled-components';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { units } from '../../helpers/styles/units';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { CreateBlocksModel } from '../../api/dinamicPages/updatePage';
import { TypesIcon } from '../../types/TypesIcon';
import { isEqualObject } from '../../helpers/isEqual';
import { addNotification } from '../../actions/notifications/addNotification';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { SubmitButtons } from '../StyledComponents';
import { UploadFiles } from '../UploadFiles';
import { DocumentItem, UpdateDocumnets } from '../../types/Endpoints/documents/DocumentsContracts';
import { updateDocuments } from '../../actions/docs';
import { colors } from '../../constants/Colors';
import { CopyToClipboard } from '../CopyToClipboard';

interface pageProps {
  pagesData: DocumentItem[];
}

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 75%;
  gap: ${units(30)};
  max-width: ${units(334)};
  padding-bottom: ${units(48)};
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledSubmitButtons = styled.div`
  margin-left: -${units(12)};
`;

const StyledFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(8)};
`;

const StyledSubTitle = styled.h3`
  font-weight: ${FontWeight.MEDIUM};
  letter-spacing: 0.8px;

  ${typography(10)};
`;

const StyledUrl = styled.h3`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  color: ${colors.grey20};

  ${typography(5)};
`;

const CopyToClipboardWrapper = styled.div`
  position: relative;
  display: inline-block;
  height: ${units(12)};
  width: ${units(12)};
`;

const StyledRowFlex = styled.div`
  display: flex;
  gap: ${units(8)};
  align-items: center;
`;

export const DocsForm = ({ pagesData }: pageProps) => {
    const dispatch = useDispatch();

    const handleSubmit = (props: any) => {
        let body: UpdateDocumnets = {
            data: props.values.blocks.map((block: DocumentItem) => ({
                title: block.title,
                id: block.id,
                fileId: block.file?.id || null,
            })),
        };

        dispatch(updateDocuments(body));
    };

    const validationSchema = yup.object().shape({
        blocks: yup.array().of(
            yup.object().shape({
                title: yup.string(),
                url: yup.string(),
                file: yup.object().shape({
                    id: yup.number(),
                    path: yup.string(),
                    size: yup.number(),
                }),
            }),
        ),
    });

    const initialValue = { blocks: pagesData };

    const handleResetForm = (clb: () => void, values: CreateBlocksModel) => {
        clb();
        const equal = isEqualObject(initialValue, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <Formik<any>
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {props => (
                <StyledForm onSubmit={handleSubmit}>
                    <FieldArray name="blocks">
                        {() => (
                            <StyledMainWrapper>
                                {props.values.blocks?.map((block: DocumentItem, index: number) => (
                                    <StyledFlexWrapper key={`block-${block.id}`}>
                                        <StyledSubTitle>
                                            {props.values?.blocks[index].title || ''}
                                        </StyledSubTitle>
                                        <StyledRowFlex>
                                            <CopyToClipboardWrapper>
                                                <CopyToClipboard copyText={props.values?.blocks[index].url} />
                                            </CopyToClipboardWrapper>
                                            <StyledUrl>
                                                {props.values?.blocks[index].url || ''}
                                            </StyledUrl>
                                        </StyledRowFlex>

                                        <UploadFiles
                                            name={`blocks[${index}].file`}
                                            onChange={props.setFieldValue}
                                            value={props.values.blocks[index].file}
                                        />
                                    </StyledFlexWrapper>
                                ))}
                            </StyledMainWrapper>
                        )}
                    </FieldArray>
                    <StyledSubmitButtons>
                        <SubmitButtons
                            reset={() => handleResetForm(props.resetForm, props.values)}
                            submit={() => handleSubmit(props)}
                        />
                    </StyledSubmitButtons>
                </StyledForm>
            )}
        </Formik>
    );
};
