import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as shopsApi from '../../../api/shops';
import { Shop } from '../../../types/Models/Shops/shop';

export const GET_SHOPS = 'shops/getShops';

export const getShops = createAsyncThunk<Shop[]>(
    GET_SHOPS,
    createRequest({
        type: GET_SHOPS,
        loader: async () => shopsApi.getShops(),
        showFailNotification: false,
    }),
);
