import { createReducer } from '@reduxjs/toolkit';
import { setSearchData } from '../../actions/search/thunks/setSearchData';
import { Shop } from '../../types/Models/Shops/shop';
import { User } from '../../types/Models/Users/User';
import { getShopsWithParams } from '../../actions/shops/thunks';
/* import { getUsersWithParams } from '../../actions/users/thunks'; */

interface SearchState {
    searchString?: string;
    searchResults?: Shop[]| User[];
}

export const search = createReducer<SearchState>({}, builder => {
    builder.addCase(setSearchData, (state, { payload }) => ({
        ...state,
        searchString: payload,
    }));
    builder.addCase(getShopsWithParams.fulfilled, (state, { payload }) => ({
        ...state,
        searchResults: payload,
    }));
    /* TODO */
    /* another action for search */
    /* builder.addCase(getUsersWithParams.fulfilled, (state, { payload }) => ({
        ...state,
        searchResults: payload,
    })); */
});
