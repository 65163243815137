import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import { CreateProductContract, ProductItemContract } from '../../../types/Endpoints/goods/ProductContracts';
import { addNotification } from '../../notifications';
import { TypesIcon } from '../../../types/TypesIcon';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Thunk } from '../../../helpers/redux/Thunk';
import * as productApi from '../../../api/goods';

const UPDATE_PRODUCT = 'goods/updateProduct';

const MESSAGE = 'Продукт обновлен';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.INFO,
        icon: TypesIcon.SNACK_DIAMOND,
        message: {
            title: MESSAGE,
        },
    }));
};

export const updateProduct = createAsyncThunk<ProductItemContract, CreateProductContract>(
    UPDATE_PRODUCT,
    createRequest({
        type: UPDATE_PRODUCT,
        onSuccess,
        loader: async (data: CreateProductContract) => productApi.updateProduct(data),
    }),
);
