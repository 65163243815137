import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as blogApi from '../../../api/blog';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { UpdateBlog } from '../../../types/Endpoints/blog/BlogCRUDContracts';

export const UPDATE_BLOG = 'blog/updateBlog';

const onSuccess = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Новость обновлена успешно',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));

    history.push(routes.blog);
};

export const updateBlogThunk = createAsyncThunk(
    UPDATE_BLOG,
    createRequest({
        type: UPDATE_BLOG,
        loader: async ({ data, blogId }: { data: UpdateBlog['requestBody'], blogId: string }) => blogApi.updateBlog(data, blogId),
        onSuccess,
    }),
);
