import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { GET_SEARCH_SETTINGS, GET_SEARCH_SETTINGS_BY_ID, GET_SEARCH_SETTINGS_LIKED } from '../../actions/landings/thunks';

const selectRoot = ({ searchSettings }: RootState) => searchSettings;

const selectRootLoader = ({ loaders }: RootState) => loaders;

export const selectGetSearchSettingsLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_SEARCH_SETTINGS],
);

export const selectGetSearchSettingsLikedLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_SEARCH_SETTINGS_LIKED],
);

export const selectGetSearchSettingsByIdLoader = createSelector(
    selectRootLoader,
    loaders => loaders[GET_SEARCH_SETTINGS_BY_ID],
);

export const selectSearchSettings = createSelector(
    selectRoot,
    ({ searchSettings, searchLikedSettings }) => ({
        searchSettings,
        searchLikedSettings,
    }),
);

export const selectedSearchSettingsItem = createSelector(
    selectRoot,
    ({ selectedSearchSettings }) => selectedSearchSettings,
);
