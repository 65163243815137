import { request } from '../../helpers/api/request';
import { GetActiveUserContract } from '../../types/Endpoints/users/GetActiveUserContract';

export const getUser = async (id?: string) => {
    const { data } = await request<GetActiveUserContract>({
        url: `admin/users/${id}/`,
    });

    return data;
};
