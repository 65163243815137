import { request } from '../../helpers/api/request';
import { GetRobotsContract, Robots } from '../../types/Endpoints/robots/RobotsContract';

export const editRobots = async (requestData: Robots) => {
    const { data } = await request<GetRobotsContract>({
        url: 'admin/seo/robots',
        method: 'PUT',
        data: requestData,
    });

    return data;
};
