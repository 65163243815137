import { request } from '../../helpers/api/request';
import { EditCatalogBrandContract, EditCatalogBrandItemModel } from '../../types/Endpoints/brands/BrandsContracts';

export const editBrand = async ({ id, ...requestData }: EditCatalogBrandItemModel) => {
    const { data } = await request<EditCatalogBrandContract>({
        url: `admin/brands/${id}`,
        method: 'PATCH',
        data: requestData,
    });

    return data;
};
