import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const selectRoot = ({ search }: RootState) => search;

export const selectSearchData = createSelector(
    selectRoot,
    search => search.searchString,
);

export const selectSearchResults = createSelector(
    selectRoot,
    search => search.searchResults,
);
