import { createReducer } from '@reduxjs/toolkit';
import { OrderSettings } from '../../types/Models/OrderSettings/OrderSettings';
import { getOrderSettings } from '../../actions/orderSettings';

export interface OrderSettingsModel {
  orderSettings?: OrderSettings[];
}

export const orderSettings = createReducer<OrderSettingsModel>({ }, builder => {
    builder.addCase(getOrderSettings.fulfilled, (state, { payload }) => ({
        ...state,
        orderSettings: payload,
    }));
});
