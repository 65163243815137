import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as categoriesApi from '../../../api/categories';
import { EditCatalogSubCategoryItem } from '../../../types/Endpoints/categories/CategoriesContracts';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const EDIT_CATALOG_SUB_CATEGORY = 'catalog/editCatalogSubCategory';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const editCatalogSubCategory = createAsyncThunk(
    EDIT_CATALOG_SUB_CATEGORY,
    createRequest({
        type: EDIT_CATALOG_SUB_CATEGORY,
        loader: async (requestData: EditCatalogSubCategoryItem) => categoriesApi.editSubCategory(requestData),
        onSuccess,
    }),
);
