/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/Colors';
import { units } from '../../helpers/styles/units';
import { typography } from '../../helpers/styles/typography';

interface SwProps {
  id?: string;
  onChange: Function;
  name?: string;
  type: string;
  value: boolean;
  title?: boolean;
  inactiveTitle?: string;
  activeTitle?: string;
}

const StyledLabel = styled.label<{ checked: boolean }>`
  cursor: pointer;
  text-indent: -9999px;
  width: ${units(22)};
  height: ${units(12)};
  background: ${({ checked }) => (checked ? colors.gold : colors.grey30)};
  display: block;
  border-radius: ${units(50)};
  position: relative;
  &:after {
  content: "";
  position: absolute;
  right: ${({ checked }) => (checked ? '2px' : '22px')};
  top: ${units(1)};
  width: ${units(10)};
  height: ${units(10)};
  background: ${colors.white};
  border-radius: ${units(45)};
  transition: 0.3s;
}`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${units(8)};
`;

const StyledStatusLabel = styled.div`
${typography(10)};
`;

export const Switch = ({
    onChange, type, name, value, title, inactiveTitle, activeTitle,
}: SwProps) => {
    const [switchState, setSwitchState] = useState(value);
    const handleOnChange = (e: { target: { checked: any; }; }) => {
        onChange(e.target.checked);
    };
    useEffect(() => {
        setSwitchState(value);
    }, [value]);
    return (
        <SwitchWrapper>
            <StyledLabel htmlFor={`checkbox-${name}`} checked={switchState}>
                <input id={`checkbox-${name}`} type={type} checked={switchState} onChange={handleOnChange} name={name} />
            </StyledLabel>
            {title && <StyledStatusLabel>{switchState ? `${activeTitle || 'Активный'}` : `${inactiveTitle || 'Отключен'}`}</StyledStatusLabel>}
        </SwitchWrapper>
    );
};

export default Switch;
