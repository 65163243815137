import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as filtersApi from '../../../api/filters';

export const GET_FILTER = 'catalog/getFilter';

export const getFilter = createAsyncThunk(
    GET_FILTER,
    createRequest({
        type: GET_FILTER,
        loader: async (filterId: string) => filtersApi.getFilter(filterId),
    }),
);
