import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as searchSettingsApi from '../../../api/landings';
import { SearchSettingItem } from '../../../types/Endpoints/SearchSettings/SearchSettingsContracts';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

const onSuccess = (): Thunk => async dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Изменения сохранены',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const SET_SEARCH_SETTINGS = 'searchSettings/setSearchSettings';
export const setSearchSettings = createAsyncThunk(
    SET_SEARCH_SETTINGS,
    createRequest({
        type: SET_SEARCH_SETTINGS,
        loader: async (requestData: SearchSettingItem) => searchSettingsApi.setSearchSettings(requestData),
        onSuccess,
    }),
);
