import {
    useEffect, useMemo, useState, FC,
} from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { routes } from '../../../constants/RouterPath';
import { Checkbox } from '../../../components/Checkbox';
import { useTypeSelector } from '../../../store';
import { selectCategories } from '../../../selectors/catalog';
import { titlesTable, StatusesProduct } from './constants';
import { IGoodsTableProps } from './interface';
import {
    styles,
    StyledCell,
    StyledStatusInfo,
    StyledDiv,
    StyledEditButton,
} from './styles';

export const StatusActiveComponent = () => (
    <StyledDiv className="status active">
        <StyledStatusInfo />
        <span>{StatusesProduct.activated}</span>
    </StyledDiv>
);

export const StatusBlockedComponent = () => (
    <StyledDiv className="status end">
        <StyledStatusInfo />
        <span>{StatusesProduct.deactivated}</span>
    </StyledDiv>
);

const getStatusComponent = (isActive: boolean) => (isActive ? <StatusActiveComponent /> : <StatusBlockedComponent />);

export const GoodsTable: FC<IGoodsTableProps> = ({
    goodsList,
    selectedGoodsForDisabled,
    setSelectedGoodsForDisabled,
}) => {
    const history = useHistory();
    const categories = useTypeSelector(selectCategories);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

    const goodsListIDs = useMemo(() => goodsList.map(item => item.id), [goodsList]);

    const onChangeCheck = (id: number) => {
        setSelectedGoodsForDisabled(selectedGoodsForDisabled.includes(id)
            ? selectedGoodsForDisabled.filter(oldID => oldID !== id)
            : [...selectedGoodsForDisabled, id]);
    };

    const onChangeCheckAll = () => {
        setSelectedGoodsForDisabled(isAllChecked
            ? Array.from(new Set(selectedGoodsForDisabled.concat(goodsListIDs)))
            : selectedGoodsForDisabled.filter(id => !goodsListIDs.includes(id)));
    };

    useEffect(() => {
        const isNotAll = goodsList.some(item => !selectedGoodsForDisabled.includes(item.id));
        setIsAllChecked(isNotAll);
    }, [goodsList, selectedGoodsForDisabled]);

    return (
        <TableContainer component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell align="left">
                            <Checkbox
                                name="selectAllCheckbox"
                                checked={!isAllChecked}
                                onChange={onChangeCheckAll}
                            />
                        </TableCell>
                        {titlesTable.map(item => (
                            <TableCell key={`${item}`}>
                                {item}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody sx={[styles.root, styles.body]}>
                    {goodsList && goodsList.map(row => (
                        <TableRow
                            key={row.id}
                            sx={{ 'td, th': { border: 0 } }}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{ maxWidth: '85px' }}
                            >
                                <Checkbox
                                    name="selectAllCheckbox"
                                    checked={selectedGoodsForDisabled.includes(row.id)}
                                    onChange={() => onChangeCheck(row.id)}
                                />
                            </TableCell>

                            <TableCell
                                component="th"
                                scope="row"
                                className="text-grey"
                                sx={{ width: '25%' }}
                            >
                                <span>{row.id}</span>
                            </TableCell>

                            <TableCell align="left" sx={{ width: '25%' }}>
                                {row.fullName}
                            </TableCell>
                            {/* TODO: await backend */}
                            <TableCell align="center" sx={{ width: '15%' }}>
                                &mdash;
                            </TableCell>

                            <StyledCell align="left" sx={{ width: '15%' }}>
                                {getStatusComponent(row.isActive)}
                            </StyledCell>

                            <TableCell align="left" sx={{ width: '5%' }}>
                                <StyledEditButton
                                    typeButton={TypesButton.SECONDARY}
                                    size={SizesButton.M}
                                    onClick={() => history.push(`${routes.goods_edit}/${row.id}`)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
