import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const filterCloudTableBreadcrumbs = () : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Каталог',
        url: '',
    },
    {
        key: '2',
        value: 'Облако фильтров',
        url: '',
    },
]);
