import { request } from '../../helpers/api/request';
import { Calculators } from './getCalculator';

export const updateSamples = async (newData: Calculators) => {
    const { data } = await request({
        url: `admin/calculator/${newData.id}`,
        method: 'PATCH',
        data: newData,
    });

    return data;
};
