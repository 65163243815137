/* eslint-disable no-unsafe-optional-chaining */
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useDispatch } from 'react-redux';
import { SearchBar } from '../../components/SearchBar';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { routes } from '../../constants/RouterPath';
import { useTypeSelector } from '../../store';
import { Icon } from '../../components/Icon';
import { TypesIcon } from '../../types/TypesIcon';
import { colors } from '../../constants/Colors';
import { FilterButton } from '../../components/Button/filterButton';
import { CalendarButton } from '../../components/Button/calendarButtom';
import { SalesTable, StatusSales } from '../../components/SalesTable';
import { selectPromotionsList } from '../../selectors/sales';
import { getPromotionsThink } from '../../actions/sales';
import { Checkbox } from '../../components/Checkbox';
import { selectSearchData } from '../../selectors/search';
import { CalendarContent } from '../../components/Calendar';
import * as S from './styles';
import { PageTitles } from '../../constants/PageTitles';
import { getFormattedDateWithTime } from '../../helpers/date/getDate';

export const SalesContent = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const promotionsList = useTypeSelector(selectPromotionsList);
    const searchString = useTypeSelector(selectSearchData);

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

    const [dateValue, setDateValue] = useState<DateObject[]>([]);

    const [values, setValues] = useState<DateObject>(new DateObject());

    const [rangeValues, setRangeValues] = useState<DateObject[]>();

    const [year, setYear] = useState(new Date().getFullYear());

    const handleOpenFilter = () => {
        setIsFilterOpen(v => !v);
        if (isCalendarOpen) setIsCalendarOpen(false);
    };

    const handleOpenPicker = () => {
        setIsCalendarOpen(v => !v);
        if (isFilterOpen) setIsFilterOpen(false);
    };

    const handleSetDateValues = (data: DateObject[]) => {
        setIsCalendarOpen(false);
        setDateValue(data);
    };

    const handleStatusChange = (status: string) => {
        const isSelected = selectedStatuses.includes(status);

        const data = isSelected ? selectedStatuses.filter(prevStatus => prevStatus !== status) : [...selectedStatuses, status];

        setSelectedStatuses(data);
    };

    useEffect(() => {
        dispatch(
            getPromotionsThink({
                fromDate: getFormattedDateWithTime(dateValue && dateValue[0], { hours: 0, minutes: 0 }),
                toDate: getFormattedDateWithTime(dateValue && dateValue[1], { hours: 23, minutes: 59 }),
                status: selectedStatuses,
                search: searchString,
            }),
        );
    }, [dateValue, selectedStatuses, searchString]);

    return (
        <S.Wrapper>
            <S.StyledPageTitle>{PageTitles.SALES}</S.StyledPageTitle>
            <S.PanelWrapper>
                <S.SearchBarWrapper>
                    <SearchBar />
                </S.SearchBarWrapper>
                <S.CalendarWrapper>
                    <CalendarButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOpenPicker}
                    />
                    {isCalendarOpen && (
                        <CalendarContent
                            setDate={handleSetDateValues}
                            values={values}
                            rangeValues={rangeValues}
                            year={year}
                            setValues={setValues}
                            setRangeValues={setRangeValues}
                            setYear={setYear}
                        />
                    )}
                </S.CalendarWrapper>

                <S.FilterWrapper>
                    <FilterButton
                        typeButton={TypesButton.SECONDARY}
                        size={SizesButton.M}
                        onClick={handleOpenFilter}
                    />
                    {isFilterOpen && (
                        <S.FilterWindow>
                            <S.CheckBoxWrapper>
                                <S.IdInfo>Статус</S.IdInfo>
                                <Checkbox
                                    name="isShowOnSite"
                                    title="Завершена"
                                    checked={selectedStatuses.includes(StatusSales.END)}
                                    onChange={() => handleStatusChange(StatusSales.END)}
                                />
                                <Checkbox
                                    name="isShowOnSite"
                                    title="Запланирована"
                                    checked={selectedStatuses.includes(StatusSales.PLANING)}
                                    onChange={() => handleStatusChange(StatusSales.PLANING)}
                                />
                                <Checkbox
                                    name="isShowOnSite"
                                    title="Опубликована"
                                    checked={selectedStatuses.includes(StatusSales.ACTIVE)}
                                    onChange={() => handleStatusChange(StatusSales.ACTIVE)}
                                />
                            </S.CheckBoxWrapper>

                            <S.ButtonClear
                                onClick={() => setSelectedStatuses([])}
                                typeButton={TypesButton.TEXT}
                            >
                                Очистить фильтр
                            </S.ButtonClear>
                        </S.FilterWindow>
                    )}
                </S.FilterWrapper>
                <S.StyledButton
                    typeButton={TypesButton.PRIMARY}
                    size={SizesButton.S}
                    onClick={() => history.push(routes.create_sale)}
                >
                    <Icon
                        type={TypesIcon.PLUS}
                        color={colors.white}
                        width="16px"
                        height="16px"
                        viewBox="0 0 16 16"
                    />{' '}
                    Добавить
                </S.StyledButton>
            </S.PanelWrapper>
            <S.Root>

                {promotionsList && <SalesTable promotionsList={promotionsList} />}
            </S.Root>
        </S.Wrapper>
    );
};

/* const loader = createLoadHoc({
    selectLoader: selectGetPromotionsLoader,
});

export const SalesWithLoader = loader(SalesContent); */
