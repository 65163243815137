import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as brandsApi from '../../../api/brands';
import { history } from '../../../store/history';
import { routes } from '../../../constants/RouterPath';
import { Thunk } from '../../../helpers/redux/Thunk';
import { EditCatalogBrandItem } from '../../../types/Endpoints/brands/BrandsContracts';
import { addNotification } from '../../notifications/addNotification';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { TypesIcon } from '../../../types/TypesIcon';

export const CREATE_CATALOG_BRAND = 'brands/createBrands';
const onSuccess = (): Thunk => async dispatch => {
    history.push(routes.brands);

    dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Бренд добавлен',
            description: '',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));
};

export const createCatalogBrands = createAsyncThunk(
    CREATE_CATALOG_BRAND,
    createRequest({
        type: CREATE_CATALOG_BRAND,
        loader: async (requestData: EditCatalogBrandItem) => brandsApi.createBrand(requestData),
        onSuccess,
    }),
);
