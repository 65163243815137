export const getIsoDate = (isStartDate: boolean, dateString?: Date | string): string | undefined => {
    if (dateString) {
        const date = new Date(dateString);
        date.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());

        if (isStartDate) {
            date.setUTCHours(0, 0, 0, 0);
        } else {
            date.setUTCHours(23, 59, 59, 999);
        }

        return date.toISOString();
    }
};
