import styled from 'styled-components';
import {
    ChangeEvent, ReactNode, useEffect, useState,
} from 'react';
import { Title } from '../Title';
import { units } from '../../helpers/styles/units';
import { BreakPoints } from '../../constants/Styles/breakPoints';
import { colors } from '../../constants/Colors';
import { typography } from '../../helpers/styles/typography';
import { FontWeight } from '../../constants/Styles/fontWeight';

interface TextAreaProps {
  rows?: number;
  title?: string;
  placeholder?: string;
  titleElement?: ReactNode;
  className?: string;
  name?: string;
  maxLength?: number;
  value?: string;
  error?: string;
  isTouched?: boolean;
  readonly?: boolean;
  count?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const HoveredTitle = styled(Title)`
  position: absolute;
  top: 0;
  z-index: 10;
  color: transparent;
  font-weight: ${FontWeight.REGULAR};

  &:hover {
    color: transparent !important;
  }

  ${typography(5)};
`;

const StyledWrapper = styled.div<{ withoutTitle?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: ${units(3)};
  border-bottom: 1px solid ${colors.grey30};
  width: 100%;
  height: clamp(56px, 56px, auto);

  @media screen and (max-width: ${BreakPoints.MD}) {
    flex-wrap: wrap;
  }

  &:hover ${HoveredTitle} {
    color: ${colors.grey20};
  }
`;

const StyledError = styled.div`
  position: absolute;
  bottom: -16px;
  z-index: 100;

  display: flex;

  font-size: ${units(7)};

  color: ${colors.red};

  min-height: ${units(6)};
  min-width: ${units(6)};
}
`;

const StyledTitle = styled(Title)`
  position: absolute;
  top: 0;
  z-index: 10;
  color: ${colors.grey20};
  font-weight: ${FontWeight.REGULAR};
  width: 100%;
  background-color: ${colors.light};

  ${typography(5)};
`;

const StyledInput = styled.textarea<{ error?: boolean; value?: string }>`
  padding: ${units(11)} ${units(0)} ${units(0)} 0;

  width: 100%;

  resize: vertical;

  border: none;
  color: ${({ error }) => (error ? colors.red : colors.grayscale80)};
  border-radius: 0;
  background: none;
  outline: none;
  min-height: 50px;
  height: ${({ value }) => (value && value.length > 46 ? 'auto' : '50px')};
  ${typography(10)};

  ::placeholder {
    position: absolute;
    top: 18px;
    letter-spacing: 0px;
    color: ${colors.greyDark};
    ${typography(10)};
  }

  &:active,
  &:focus {
    outline: none;
  }

  :focus {
    ::placeholder {
      color: transparent;
    }
  }

  :hover {
    ::placeholder {
      color: transparent;
    }
  }

  ~ ${HoveredTitle} {
    color: transparent;
  }

  ${StyledWrapper}:hover & ~ ${HoveredTitle} {
    color: ${colors.grey20};
  }
`;

const StyledFieldContainer = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: ${units(3)};

  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${BreakPoints.MD}) {
    margin-top: ${units(3)};
  }

  :hover {
    ${StyledInput}::placeholder {
      color: transparent;
    }
  }
`;

export const Textarea = ({
    rows,
    title,
    placeholder,
    titleElement,
    className,
    name,
    maxLength,
    value,
    isTouched,
    error,
    count,
    readonly,
    onChange,
    ...props
}: TextAreaProps) => {
    const [isError, setError] = useState(isTouched && Boolean(error));

    useEffect(() => {
        if (count) {
            setError(Boolean(error));
        }
    }, [count, error]);

    const currentTitle = title ? (
        <StyledTitle>{title}</StyledTitle>
    ) : (
        titleElement
    );

    const hoverTitle = title ? <HoveredTitle>{title}</HoveredTitle> : null;
    return (
        <StyledWrapper>
            {!value ? hoverTitle : currentTitle}
            <StyledFieldContainer>
                <StyledInput
                    name={name}
                    placeholder={placeholder}
                    className={className}
                    onChange={onChange}
                    value={value}
                    maxLength={maxLength}
                    rows={rows}
                    readOnly={readonly}
                    error={isError}
                    {...props}
                />
                {isError && <StyledError>{error}</StyledError>}
            </StyledFieldContainer>
        </StyledWrapper>
    );
};
