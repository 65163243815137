/* eslint-disable no-unsafe-optional-chaining */
import styled from 'styled-components';
import { SearchBar } from '../../../components/SearchBar';
import { units } from '../../../helpers/styles/units';
import { PageTitle } from '../../../components/PageTitle';
import { FontWeight } from '../../../constants/Styles/fontWeight';
import { useTypeSelector } from '../../../store';
import { selectGetCatalogCategoriesLoader, selectSearchCategories } from '../../../selectors/catalog';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { MenuTable } from '../../../components/MenuTable';
import { menuBreadcrumbs } from '../../../constants/BreadCrumbs/menuBreadcrumbs';
import { createLoadHoc } from '../../../helpers/hocs/createLoadHoc';

const StyledRoot = styled.div`
  display: flex;
  padding-top: ${units(20)};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(12)};
`;

const StyledPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${units(8)};
  width: 100%;
  padding-top: ${units(14)};
`;

const StyledSearchBarWrapper = styled.div`
  max-width: ${units(322)};
  flex: 1 1 auto;
`;

const StyledPageTitle = styled(PageTitle)`
  font-weight: ${FontWeight.MEDIUM}; 
`;

export const MenuContent = () => {
    const categories = useTypeSelector(selectSearchCategories);

    return (
        <StyledWrapper>
            <StyledPageTitle>Меню</StyledPageTitle>
            <Breadcrumbs breadcrumbs={menuBreadcrumbs()} />
            <StyledPanelWrapper>
                <StyledSearchBarWrapper>
                    <SearchBar />
                </StyledSearchBarWrapper>

            </StyledPanelWrapper>
            <StyledRoot>

                {categories && <MenuTable categories={categories} />}
            </StyledRoot>
        </StyledWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetCatalogCategoriesLoader,
});

export const MenuWithLoader = loader(MenuContent);
