import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { Checkbox } from '../../../../components/Checkbox';
import { Icon } from '../../../../components/Icon';
import { TypesIcon } from '../../../../types/TypesIcon';
import { Input } from '../../../../components/Input';
import { colors } from '../../../../constants/Colors';
import { typography } from '../../../../helpers/styles/typography';
import { units } from '../../../../helpers/styles/units';
import { FontWeight } from '../../../../constants/Styles/fontWeight';
import { Button } from '../../../../components/Button';
import { useTypeSelector } from '../../../../store';
import { TypesButton } from '../../../../constants/ButtonTypes';
import { SizesButton } from '../../../../constants/SizeButton';
import { routes } from '../../../../constants/RouterPath';
import { createLoadHoc } from '../../../../helpers/hocs/createLoadHoc';
import { PageTitle } from '../../../../components/PageTitle';
import { UploadPhotoWithMiniature } from '../../../../components/UploadPhotoWithMiniature';
import { UploadFile } from '../../../../types/Endpoints/files/FilesContract';
import {
    selectGetCollectionLoader,
    selectSelectedCollection,
} from '../../../../selectors/collections';
import { EditCatalogCollectionItem } from '../../../../types/Endpoints/collections/GetCollectionsContract';
import { editCollectionBreadcrumbs } from '../../../../constants/BreadCrumbs/editCollectionbreadcrumbs';
import { editCatalogCollection } from '../../../../actions/collections';
import Switch from '../../../../components/sw';
import { deleteCatalogCollection } from '../../../../actions/collections/thunks/deleteCatalogCollection';
import { ButtonRoot, SubmitButtons } from '../../../../components/StyledComponents';
import { addModal } from '../../../../actions/modal';
import { getId } from '../../../../helpers/generateId';
import { TypesModal } from '../../../../types/TypesModalComponent';
import { isEqualObject } from '../../../../helpers/isEqual';
import { addNotification } from '../../../../actions/notifications';
import { TypesSnackbars } from '../../../../types/TypesSnackbars';
import { Textarea } from '../../../../components/TextArea';

const StyledRootWrapper = styled.div`
  position: relative;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${units(12)};
  margin-bottom: ${units(63)};
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(20)};
  padding-top: ${units(20)};
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(12)};

  max-width: ${units(322)};
  width: 100%;
`;

const SeoFieldsWrapper = styled(StyledMainWrapper)`
    margin-bottom: ${units(10)};
`;

const StyledIdInfo = styled.div`
  font-weight: ${FontWeight.MEDIUM};

  color: ${colors.greyDark};
  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledInput = styled(Input)`
  font-weight: ${FontWeight.REGULAR};
  letter-spacing: 0.8px;

  background-color: ${colors.light};

  ${typography(10)};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${units(8)};

  padding: ${units(12)};

  max-width: ${units(322)};
`;

const DeleteWrapper = styled(ButtonWrapper)`
  padding: ${units(0)};
  padding-right: ${units(24)};
`;

const StyledSortWrapper = styled.div`
  width: ${units(78)};
`;

const StyledSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUploader = styled(UploadPhotoWithMiniature)`
  width: 100%;
`;

// const StyledAddButton = styled(StyledButton)`
//   max-width: ${units(137)};
// `;

interface EditCollectionModel {
    id: number;
    seoH1: string;
    seoDescription: string;
    seoTitle: string;
    name: string;
    externalId: string;
    urn: string;
    sortNumber: number;
    isShowOnSite: boolean;
    isShowOnMobile: boolean;
    collectionSiteFileId: UploadFile;
    collectionMobileFileId: UploadFile;
    collectionSiteDesktopFileId: UploadFile;
    collectionSiteMobileFileId: UploadFile;
    collectionCatalogSiteFileId: UploadFile;
    collectionCatalogMobileFileId: UploadFile;
    isShowCollectionBanner: boolean;
    collectionBannerTitle: string;
    collectionBannerDescription: string;
    collectionBannerButtonText: string;
    collectionShowBannerSortNumber: number;
}

const EditCollectionContent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedCollection = useTypeSelector(selectSelectedCollection);
    const [openFields, setOpenFields] = useState(
        selectedCollection?.isShowCollectionBanner,
    );

    const handleSubmit = (values: EditCollectionModel) => {
        const requestData: EditCatalogCollectionItem = {
            id: selectedCollection?.id || 0,
            seoH1: values.seoH1,
            seoDescription: values.seoDescription,
            seoTitle: values.seoTitle,
            name: values.name,
            externalId: values.externalId,
            urn: values.urn,
            sortNumber: +values.sortNumber,
            isShowOnSite: values.isShowOnSite,
            isShowOnMobile: values.isShowOnMobile,
            collectionMobileFileId: values.collectionMobileFileId?.id || null,
            collectionSiteFileId: values.collectionSiteFileId?.id || null,
            collectionSiteDesktopFileId: values.collectionSiteDesktopFileId?.id || null,
            collectionSiteMobileFileId: values.collectionSiteMobileFileId?.id || null,
            collectionCatalogSiteFileId: values.collectionCatalogSiteFileId?.id || null,
            collectionCatalogMobileFileId:
                values.collectionCatalogMobileFileId?.id || null,
            isShowCollectionBanner: values.isShowCollectionBanner,
            collectionBannerTitle: values.collectionBannerTitle,
            collectionBannerDescription: values.collectionBannerDescription,
            collectionBannerButtonText: values.collectionBannerButtonText,
            collectionShowBannerSortNumber: +values.collectionShowBannerSortNumber,
        };

        dispatch(editCatalogCollection(requestData));
    };

    const handleDeleteCategory = () => {
        if (selectedCollection?.id) {
            dispatch(deleteCatalogCollection(selectedCollection.id));
        }
    };

    const initialValues: EditCollectionModel = {
        id: selectedCollection?.id || 0,
        seoH1: selectedCollection?.seoH1 || '',
        seoDescription: selectedCollection?.seoDescription || '',
        seoTitle: selectedCollection?.seoTitle || '',
        externalId: selectedCollection?.externalId || '',
        sortNumber: selectedCollection?.sortNumber || 0,
        name: selectedCollection?.name || '',
        urn: selectedCollection?.urn || '',
        isShowOnSite: !!selectedCollection?.isShowOnSite,
        isShowOnMobile: !!selectedCollection?.isShowOnMobile,
        collectionSiteFileId: {
            id: selectedCollection?.collectionSiteFileId || 0,
            path: selectedCollection?.collectionSiteFile?.path || '',
        },
        collectionMobileFileId: {
            id: selectedCollection?.collectionMobileFileId || 0,
            path: selectedCollection?.collectionMobileFile?.path || '',
        },
        collectionSiteDesktopFileId: {
            id: selectedCollection?.collectionSiteDesktopFileId || 0,
            path: selectedCollection?.collectionSiteDesktopFile?.path || '',
        },
        collectionSiteMobileFileId: {
            id: selectedCollection?.collectionSiteMobileFileId || 0,
            path: selectedCollection?.collectionSiteMobileFile?.path || '',
        },

        collectionCatalogSiteFileId: {
            id: selectedCollection?.collectionCatalogSiteFileId || 0,
            path: selectedCollection?.collectionCatalogSiteFile?.path || '',
        },
        collectionCatalogMobileFileId: {
            id: selectedCollection?.collectionCatalogMobileFileId || 0,
            path: selectedCollection?.collectionCatalogMobileFile?.path || '',
        },
        isShowCollectionBanner: selectedCollection?.isShowCollectionBanner || false,
        collectionBannerTitle: selectedCollection?.collectionBannerTitle || '',
        collectionBannerDescription:
            selectedCollection?.collectionBannerDescription || '',
        collectionBannerButtonText:
            selectedCollection?.collectionBannerButtonText || '',
        collectionShowBannerSortNumber:
            selectedCollection?.collectionShowBannerSortNumber || 0,
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required('Поле является обязательным'),
        urn: yup.string().required('Поле является обязательным'),
        collectionBannerTitle: yup.string()
            .when('isShowCollectionBanner', {
                is: true,
                then: yup.string().max(30, 'Не более 30 символов').required('Поле является обязательным'),
                otherwise: yup.string().max(30, 'Не более 30 символов'),
            }),
        collectionBannerDescription: yup.string().max(60, 'Не более 60 символов'),
        collectionBannerButtonText: yup.string()
            .when('isShowCollectionBanner', {
                is: true,
                then: yup.string().required('Поле является обязательным'),
                otherwise: yup.string(),
            }),
    });

    const form = useFormik<EditCollectionModel>({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema,
    });

    const handleToggle = (value: boolean) => {
        form.values.isShowCollectionBanner = value;
        setOpenFields(value);
    };

    const handleResetForm = (values: EditCollectionModel) => {
        history.push(routes.collections);
        const equal = isEqualObject(initialValues, values);

        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <StyledRootWrapper>
            <StyledWrapper>
                <PageTitle>Коллекции</PageTitle>
                <Breadcrumbs
                    breadcrumbs={editCollectionBreadcrumbs(selectedCollection?.name)}
                />
                <StyledRoot>
                    <SeoFieldsWrapper>
                        <StyledIdInfo>SEO</StyledIdInfo>
                        <Textarea
                            name="seoH1"
                            onChange={form.handleChange}
                            value={form.values.seoH1}
                            placeholder="H1"
                            maxLength={255}
                            title="H1"
                            isTouched={form.touched.seoH1}
                            error={form.errors.seoH1}
                            count={form.submitCount}
                        />
                        <Textarea
                            name="seoTitle"
                            onChange={form.handleChange}
                            value={form.values.seoTitle}
                            placeholder="Title"
                            maxLength={255}
                            title="Title"
                            isTouched={form.touched.seoTitle}
                            error={form.errors.seoTitle}
                            count={form.submitCount}
                        />
                        <Textarea
                            name="seoDescription"
                            onChange={form.handleChange}
                            value={form.values.seoDescription}
                            placeholder="Description"
                            maxLength={255}
                            title="Description"
                            isTouched={form.touched.seoDescription}
                            error={form.errors.seoDescription}
                            count={form.submitCount}
                        />
                    </SeoFieldsWrapper>
                    <StyledMainWrapper>
                        <StyledIdInfo>Основное</StyledIdInfo>
                        <StyledInput
                            name="name"
                            onChange={form.handleChange}
                            value={form.values.name}
                            placeholder="Название*"
                            typeInput="text"
                            maxLength={255}
                            title="Название*"
                            isTouched={form.touched.name}
                            error={form.errors.name}
                            count={form.submitCount}
                        />

                        <StyledInput
                            name="urn"
                            onChange={form.handleChange}
                            value={form.values.urn}
                            placeholder="URL*"
                            typeInput="text"
                            title="URL*"
                            isTouched={form.touched.urn}
                            error={form.errors.urn}
                            count={form.submitCount}
                        />

                        {/* <StyledInput
                            name="externalId"
                            onChange={form.handleChange}
                            value={form.values.externalId}
                            placeholder="Элемент выгрузки"
                            typeInput="text"
                            maxLength={255}
                            title="Элемент выгрузки"
                            isTouched={form.touched.externalId}
                            error={form.errors.externalId}
                            count={form.submitCount}
                        /> */}

                        <StyledSortWrapper>
                            <StyledInput
                                name="sortNumber"
                                onChange={form.handleChange}
                                value={form.values.sortNumber}
                                placeholder="Номер при показе"
                                typeInput="number"
                                title="Номер при показе"
                                isTouched={form.touched.sortNumber}
                                error={form.errors.sortNumber}
                                count={form.submitCount}
                            />
                        </StyledSortWrapper>
                    </StyledMainWrapper>
                    {/* <StyledMainWrapper>
                        <StyledAddButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                        >
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                color={colors.greyDark}
                                width="25px"
                                height="24px"
                                viewBox="0 0 25 24"
                            />{' '}
                            Добавить
                        </StyledAddButton>
                    </StyledMainWrapper> */}

                    <StyledMainWrapper>
                        <StyledIdInfo>Отображение</StyledIdInfo>
                        <Checkbox
                            name="isShowOnSite"
                            title="Отображать на сайте"
                            checked={form.values.isShowOnSite}
                            onChange={form.handleChange}
                        />

                        <Checkbox
                            name="isShowOnMobile"
                            title="Отображать в мобильном приложении"
                            checked={form.values.isShowOnMobile}
                            onChange={form.handleChange}
                        />
                    </StyledMainWrapper>
                    <StyledMainWrapper>
                        <StyledUploader
                            name="collectionSiteFileId"
                            title="Баннер в каталог на сайте"
                            onChange={form.setFieldValue}
                            value={form.values.collectionSiteFileId}
                            format="7:1"
                            maxSize={4}
                        />
                        <StyledUploader
                            name="collectionMobileFileId"
                            title="Баннер на страницу «Коллекции» для мобильного приложения"
                            onChange={form.setFieldValue}
                            value={form.values.collectionMobileFileId}
                            format="1:1"
                            maxSize={3}
                        />

                        <StyledUploader
                            name="collectionSiteDesktopFileId"
                            title="Баннер на страницу «Коллекции» для desktop версии сайта"
                            onChange={form.setFieldValue}
                            value={form.values.collectionSiteDesktopFileId}
                            format="5.3:4"
                            maxSize={4}
                        />
                        <StyledUploader
                            name="collectionSiteMobileFileId"
                            title="Баннер на страницу «Коллекции» для мобильной версии сайта"
                            onChange={form.setFieldValue}
                            value={form.values.collectionSiteMobileFileId}
                            format="4:3.5"
                            maxSize={3}
                        />
                    </StyledMainWrapper>

                    <StyledMainWrapper>
                        <StyledIdInfo>Статус отображения в баннере</StyledIdInfo>
                        <StyledSwitchWrapper>
                            <Switch
                                name="isShowCollectionBanner"
                                value={form.values.isShowCollectionBanner}
                                onChange={handleToggle}
                                type="checkbox"
                                title
                                inactiveTitle="Выключено"
                            />
                        </StyledSwitchWrapper>
                    </StyledMainWrapper>

                    {openFields && (
                        <StyledMainWrapper>
                            <StyledInput
                                name="collectionBannerTitle"
                                onChange={form.handleChange}
                                value={form.values.collectionBannerTitle}
                                placeholder="Заголовок баннера (не более 30 символов)*"
                                typeInput="text"
                                maxLength={255}
                                title="Заголовок баннера (не более 30 символов)*"
                                isTouched={form.touched.collectionBannerTitle}
                                error={form.errors.collectionBannerTitle}
                                count={form.submitCount}
                            />
                            <StyledInput
                                name="collectionBannerDescription"
                                onChange={form.handleChange}
                                value={form.values.collectionBannerDescription}
                                placeholder="Описание баннера (не более 60 символов)"
                                typeInput="text"
                                maxLength={255}
                                title="Описание баннера (не более 60 символов)"
                                isTouched={form.touched.collectionBannerDescription}
                                error={form.errors.collectionBannerDescription}
                                count={form.submitCount}
                            />
                            <StyledInput
                                name="collectionBannerButtonText"
                                onChange={form.handleChange}
                                value={form.values.collectionBannerButtonText}
                                placeholder="Текст кнопки*"
                                typeInput="text"
                                maxLength={255}
                                title="Текст кнопки*"
                                isTouched={form.touched.collectionBannerButtonText}
                                error={form.errors.collectionBannerButtonText}
                                count={form.submitCount}
                            />
                            <StyledSortWrapper>
                                <StyledInput
                                    name="collectionShowBannerSortNumber"
                                    onChange={form.handleChange}
                                    value={form.values.collectionShowBannerSortNumber}
                                    placeholder="Номер при показе"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Номер при показе"
                                    isTouched={form.touched.collectionShowBannerSortNumber}
                                    error={form.errors.collectionShowBannerSortNumber}
                                />
                            </StyledSortWrapper>

                            <StyledUploader
                                name="collectionCatalogSiteFileId"
                                title="Изображение для desktop версии сайта"
                                onChange={form.setFieldValue}
                                value={form.values.collectionCatalogSiteFileId}
                                format="9.5:4"
                                maxSize={4}
                            />
                            <StyledUploader
                                name="collectionCatalogMobileFileId"
                                title="Изображение для мобильной версии сайта"
                                onChange={form.setFieldValue}
                                value={form.values.collectionCatalogMobileFileId}
                                format="2:1"
                                maxSize={4}
                            />
                        </StyledMainWrapper>
                    )}

                    <DeleteWrapper>
                        <StyledButton
                            typeButton={TypesButton.DELETE}
                            size={SizesButton.M}
                            onClick={() => {
                                dispatch(
                                    addModal({
                                        id: getId()(),
                                        type: TypesModal.CONFIRM,
                                        message: {
                                            description:
                                                'Вы действительно хотите удалить?',
                                        },
                                        onSuccessMessage: 'Удалить',
                                        onSuccess: handleDeleteCategory,
                                        withCloseIcon: true,
                                    }),
                                );
                            }}
                        >
                            <Icon
                                type={TypesIcon.TRASH_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                            />
                            Удалить
                        </StyledButton>
                        <div />
                    </DeleteWrapper>
                </StyledRoot>
            </StyledWrapper>
            <ButtonRoot>
                <SubmitButtons
                    reset={() => handleResetForm(form.values)}
                    submit={form.submitForm}
                />
            </ButtonRoot>
        </StyledRootWrapper>
    );
};

const loader = createLoadHoc({
    selectLoader: selectGetCollectionLoader,
});

export const EditCollectionWithLoader = loader(EditCollectionContent);
