import { createReducer } from '@reduxjs/toolkit';
import { getNotes, getNotesWithoutLoader } from '../../actions/notes';
import { GetNotesContract } from '../../types/Endpoints/notes/NotesContracts';

export interface NotesModel {
  notes?: GetNotesContract['requestBody'];
}

export const notes = createReducer<NotesModel>({

}, builder => {
    builder.addCase(getNotes.fulfilled, (state, { payload }) => ({
        ...state,
        notes: payload,
    }));
    builder.addCase(getNotesWithoutLoader.fulfilled, (state, { payload }) => ({
        ...state,
        notes: payload,
    }));
});
