import { request } from '../../helpers/api/request';
import {
    CreatePromotionsContract,
    GetAllProductsContract, GetPromotionContract,
    GetPromotionsContract, UpdatePromotionsContract,
} from '../../types/Endpoints/sales/PromitionsContracts';

export const getPromotions = async (params: GetPromotionsContract['pathParams']) => {
    const { data } = await request<GetPromotionsContract>({
        url: 'admin/promotions',
        params,
    });

    return data;
};

export const createPromotion = async (createData: CreatePromotionsContract<number>['requestBody']) => {
    const { data } = await request<CreatePromotionsContract<number>>({
        method: 'POST',
        url: 'admin/promotions',
        data: createData,
    });

    return data;
};

export const getAllProducts = async (params: GetAllProductsContract['pathParams']) => {
    const { data } = await request<GetAllProductsContract>({
        url: 'admin/promotions/products',
        params,
    });

    return data;
};

export const getPromotion = async (id: string) => {
    const { data } = await request<GetPromotionContract>({
        url: `admin/promotions/${id}`,
    });

    return data;
};

export const updatePromotion = async (updateData: UpdatePromotionsContract['requestBody'], saleId: string) => {
    const { data } = await request<UpdatePromotionsContract>({
        method: 'PATCH',
        url: `admin/promotions/${saleId}`,
        data: updateData,
    });

    return data;
};

export const deletePromotion = async (saleId: string) => {
    const { data } = await request({
        method: 'DELETE',
        url: `admin/promotions/${saleId}`,
    });

    return data;
};
