import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as usersApi from '../../../api/users';

export const GET_USER = 'users/getUser';

export const getUser = createAsyncThunk(
    GET_USER,
    createRequest({
        type: GET_USER,
        loader: async (id: string) => usersApi.getUser(id),
    }),
);
