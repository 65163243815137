import { FilesDocsContract } from '../../types/Endpoints/files/FilesContract';
import { request } from '../../helpers/api/request';

export const uploadDocsFile = async (file: FormData) => {
    const { data } = await request<FilesDocsContract>({
        url: 'files/save-original',
        headers: {
            'Content-Type': 'multipart/form-data;',
        },
        method: 'POST',
        data: file,
    });

    return data;
};
