import { css } from 'styled-components';
import { TypesSnackbars } from '../../types/TypesSnackbars';
import { colors } from '../../constants/Colors';

const borderColor: Record<TypesSnackbars, string> = {
    [TypesSnackbars.SUCCESS]: `${colors.green}`,
    [TypesSnackbars.INFO]: `${colors.gold}`,
    [TypesSnackbars.ERROR]: `${colors.red}`,

    [TypesSnackbars.WARNING]: `${colors.secondary}`,
};

const backgroundColor: Record<TypesSnackbars, string> = {
    [TypesSnackbars.SUCCESS]: `${colors.success}`,
    [TypesSnackbars.ERROR]: `${colors.error}`,
    [TypesSnackbars.INFO]: `${colors.info}`,

    [TypesSnackbars.WARNING]: `${colors.secondaryLight}`,

};

export const applySnackbarColor = ({ snackbarType }: { snackbarType: TypesSnackbars }) => (
    css`
      border: 1px solid ${borderColor[snackbarType]};
      border-left: 6px solid ${borderColor[snackbarType]};
      background-color: ${backgroundColor[snackbarType]};
    `
);
