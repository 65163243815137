import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRequest } from '../../../helpers/api/createRequest';
import * as goodsApi from '../../../api/goods';

export const GET_PHOTOS_GOODS = 'goods/getPhotosGoods';

export const getPhotosThink = createAsyncThunk(
    GET_PHOTOS_GOODS,
    createRequest({
        type: GET_PHOTOS_GOODS,
        loader: async (id: string) => goodsApi.getGoodsPhotos(id),
    }),
);
