import { createAsyncThunk } from '@reduxjs/toolkit';
import * as jewelryExchangesApi from '../../../api/jewelryЕxchanges';
import { createRequest } from '../../../helpers/api/createRequest';
import { Thunk } from '../../../helpers/redux/Thunk';
import { addNotification } from '../../notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { getJewelryExchanges } from './getJewelryExchanges';
import { TypesIcon } from '../../../types/TypesIcon';
import { EditJewelryExchanges } from '../../../api/jewelryЕxchanges/updateJewelryExchanges';

export const UPDATE_JEWELRY_EXCHANGES = 'jewelryExchanges/updateJewelryExchanges';

const onSuccess = (): Thunk => async dispatch => {
    await dispatch(addNotification({
        type: TypesSnackbars.SUCCESS,
        message: {
            title: 'Таблица обновлена',
        },
        icon: TypesIcon.SNACK_SUCCES,
    }));

    dispatch(getJewelryExchanges());
};

const onFail = (): Thunk => dispatch => {
    dispatch(addNotification({
        type: TypesSnackbars.ERROR,
        message: {
            title: 'Ошибка, попробуйте позже',
        },
        icon: TypesIcon.SNACK_ERROR,
    }));
};

export const updateJewelryExchanges = createAsyncThunk(
    UPDATE_JEWELRY_EXCHANGES,
    createRequest({
        type: UPDATE_JEWELRY_EXCHANGES,
        loader: async (data: EditJewelryExchanges) => jewelryExchangesApi.updateJewelryExchanges(data),
        showFailNotification: false,
        onSuccess,
        onFail,
    }),
);
