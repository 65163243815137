import { useState } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { TypesIcon } from '../../types/TypesIcon';
import { Icon } from '../Icon';
import { colors } from '../../constants/Colors';
import { addNotification } from '../../actions/notifications';
import { TypesSnackbars } from '../../types/TypesSnackbars';

const StyledIcon = styled(Icon)`
  stroke: ${colors.grayscale20};
  transition: stroke .4s ease;

  cursor: pointer;

  :hover {
    stroke: ${colors.grayscale40};
  }
`;

const StyledTickIcon = styled(StyledIcon)`
  stroke: ${colors.grayscale80};

  :hover {
    stroke: ${colors.grayscale80};
  }
`;

export const CopyToClipboard = ({ copyText }: { copyText?: string }) => {
    const [isCopied, setIsCopied] = useState(false);
    const dispatch = useDispatch();

    const handleCopyClick = () => {
        if (copyText) {
            copy(copyText);
            setIsCopied(true);

            dispatch(addNotification({
                type: TypesSnackbars.SUCCESS,
                message: {
                    title: 'Текст скопирован',
                },
                icon: TypesIcon.SNACK_SUCCES,
            }));

            setTimeout(() => {
                setIsCopied(false);
            }, 5000);
        }
    };

    if (isCopied) {
        return (
            <StyledTickIcon
                type={TypesIcon.CHECK}
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
            />
        );
    }

    return (
        <StyledIcon
            type={TypesIcon.COPY}
            onClick={handleCopyClick}
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
        />
    );
};
